import { PlannedTask, PlannerDate, Task, User } from "../types";
import moment from "../utilities/Moment";
import Utils from "../utilities/Utils";

class PlannerHelper {
  static getAvailableTime(plannerDate: PlannerDate) {
    return plannerDate.availability;
  }

  static getScheduledTime(plannerDate: PlannerDate) {
    return (
      plannerDate?.items?.reduce((total, item) => total + item.duration, 0) || 0
    );
  }

  static getRemainingTime(plannerDate: PlannerDate) {
    return (
      this.getAvailableTime(plannerDate) - this.getScheduledTime(plannerDate)
    );
  }

  static isOverbooked(plannerDate: PlannerDate) {
    return this.getRemainingTime(plannerDate) <= 0;
  }

  static getTaskSchedulingTime(plannerDate: PlannerDate, task: Task) {
    return Math.min(this.getRemainingTime(plannerDate), task.time_remaining);
  }

  static getTaskOverschedulingTime(task: Task) {
    return Math.min(3600, task.time_remaining);
  }

  static planTaskForUser(
    task: Task,
    user: User,
    plannerDate: PlannerDate
  ): PlannedTask[] {
    const {
      day,
      items,
      availability,
      start_time,
      end_time,
      break_enabled,
      break_start_time,
      break_end_time,
    } = plannerDate;

    const remainingTimeInDay = PlannerHelper.getRemainingTime(plannerDate);
    const taskTimeRemaining = task.time_remaining;
    const schedulingTime =
      remainingTimeInDay > 0
        ? Math.min(remainingTimeInDay, taskTimeRemaining)
        : PlannerHelper.getTaskOverschedulingTime(task);

    const dayMoment = moment(day);

    // Currently we are not going to account for lunches (improvement to be made later)
    if (false) {
      let lunchMomentRange = null;
      if (break_enabled) {
        const [breakStartHour, breakStartMinutes] = break_start_time.split(":");
        const [breakEndHour, breakEndMinutes] = break_end_time.split(":");
        const lunchMomentStart = moment(dayMoment).set({
          hour: Number(breakStartHour),
          minute: Number(breakStartMinutes),
        });
        const lunchMomentEnd = moment(dayMoment).set({
          hour: Number(breakEndHour),
          minute: Number(breakEndMinutes),
        });

        // @ts-ignore
        lunchMomentRange = moment.range(lunchMomentStart, lunchMomentEnd);
      }
    }

    const startMoment = moment(start_time);
    const endMoment = moment(end_time);
    const [startHour, startMinutes] = start_time.split(":");
    const [endHour, endMinutes] = end_time.split(":");

    const latestItem = items[items.length - 1];

    // If there are no items, we can start at the start time
    let startAtMoment = moment(dayMoment).set({
      hour: Number(startHour),
      minute: Number(startMinutes),
    });
    let endAtMoment = moment(startAtMoment).add(schedulingTime, "second");

    if (latestItem) {
      startAtMoment = moment(latestItem.end_at);
      endAtMoment = moment(startAtMoment).add(schedulingTime, "second");
    }

    const plannedTask: PlannedTask = {
      id: Utils.uuid(),
      start_at: startAtMoment.toISOString(),
      end_at: endAtMoment.toISOString(),
      duration: endAtMoment.diff(startAtMoment, "second"),
      task: task,
      task_id: task.id,
      user: user,
      user_id: user.id,
    };

    return [plannedTask];
  }
}

export default PlannerHelper;
