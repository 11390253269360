import styled from 'styled-components'

const PageHeaderNavigation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    
    &:first-child {
      flex: 1;  
    }

    > * {
      margin: 4px 4px;
    }
  }
`

export default PageHeaderNavigation