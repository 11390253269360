import styled, { css } from "styled-components";

const TaskIndicatorsContainer = styled.div<{ noWrap?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;

  ${props => props.noWrap && css`
    flex-wrap: nowrap;
  `}
`

export default TaskIndicatorsContainer