import * as React from 'react'
import styled, { css } from 'styled-components';
import Icon, { IconTypes } from '../Icons/Icon';
import moment from '../../utilities/Moment';
import { Style } from '../../styles';
import { LedgerItem } from '../../types';

interface IProps {
	ledgerItem?: LedgerItem
	rounded?: boolean
	width?: number
	onClick: () => void
}

interface ContainerProps {
	rounded?: boolean
	width: number
	backgroundColor: string
	onClick?: () => void
}

const Container = styled.div`
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
	${(props: ContainerProps) => css`
		width: ${props.width}px;
		height: ${props.width}px;
		background-color: ${props.backgroundColor};
	`}
	border: 2px solid transparent;
	transition: border-color .3s;
	border-radius: 7px;
	overflow: hidden;

	${(props: ContainerProps) => props.rounded && css`
		border-radius: 50%;
	`}

	${(props: ContainerProps) => props.onClick && css`
		cursor: pointer;
	`}
`

const StyledIcon = styled(Icon)`
	color: white;
	fill: white;
`

export default class OrderFormAvatar extends React.PureComponent<IProps> {
	static defaultProps = {
		width: 40
	}

	render() {
		const { ledgerItem, rounded, width, onClick } = this.props

		let backgroundColor = '#ccc'
		let icon: IconTypes = 'question-mark'

		const isDelivered = ledgerItem.delivered_on !== null
		const isCancelled = ledgerItem.cancelled_on !== null
		const isOverdue = moment(ledgerItem.due_on).isSameOrBefore(moment(), 'day') && !isDelivered

		if (isDelivered) {
			backgroundColor = Style.color.brandSuccess
			icon = 'check'
		} else if (isCancelled) {
			backgroundColor = Style.color.brandDanger
			icon = 'close'
		} else if (isOverdue) {
			backgroundColor = Style.color.brandDanger
			icon = 'exclamation-triangle'
		} else if (ledgerItem.issued_on && ledgerItem.due_on) {
			backgroundColor = Style.color.brandWarning
			icon = 'hourglass-half'
		} else if (!ledgerItem.issued_on) {
			backgroundColor = Style.color.brandPrimary
			icon = 'arrow-right'
		}

		return (
			<Container
				rounded={rounded}
				width={width}
				backgroundColor={backgroundColor}
				onClick={onClick}
			>
				<StyledIcon icon={icon} />
			</Container>
		)

	}
}