import * as React from 'react'
import { connect } from 'react-redux'
import copy from 'copy-to-clipboard';
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import { Helmet } from 'react-helmet';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Style } from '../../styles';
import CheckboxInput from '../../components/Form/CheckboxInput';
import Notification from '../../utilities/Notification';
import { CalendarResourceType, CurrentUser } from '../../types';
import Title from '../../components/Settings/Title';

const CalendarEvents = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Style.spacing.x2};

  > *:not(:last-child) {
    margin-bottom: ${Style.spacing.x1};
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}



interface IDispatchToProps { }

type IProps = IStateToProps & IDispatchToProps & WithTranslation

const Calendar = (props: IProps) => {
  const { t, currentUser } = props
  const workspace = currentUser.workspace

  const [activeTypes, setActiveTypes] = React.useState<CalendarResourceType[]>([
    CalendarResourceType.PROJECT_EVENT_TYPE,
    CalendarResourceType.QUOTATION_EVENT_TYPE,
    CalendarResourceType.INVOICE_EVENT_TYPE,
    CalendarResourceType.TASK_EVENT_TYPE,
    CalendarResourceType.CALENDAR_EVENT_TYPE,
    CalendarResourceType.DEAL_EVENT_TYPE
  ])

  const toggleType = (calendarType: CalendarResourceType) => {
    if (activeTypes.includes(calendarType)) {
      setActiveTypes(activeTypes.filter(activeType => activeType !== calendarType))
    } else {
      setActiveTypes([...activeTypes, calendarType])
    }
  }

  let webcalUrl = `webcal://${location.host}/ical/${workspace.id}.ics?locale=${currentUser.locale}`

  if (activeTypes.length > 0) {
    webcalUrl += `&type=${activeTypes.join(',')}`
  }

  const onCopyWebcalToClipboard = () => {
    copy(webcalUrl)
    Notification.notifySuccess(t('Calendar::Copied to clipboard'))
  }

  return (
    <>
      <Helmet>
        <title>{t('Calendar::{{__appName}} | Calendar')}</title>
      </Helmet>
      <ScrollToTopOnMount />

      <Title>{t('Calendar::Calendar feed')}</Title>
      <Panel>
        <Trans t={t}>
          <p style={{ marginBottom: Style.spacing.x2 }}>Import your in-app calendar into third-party calendars like Google, Apple and Microsoft. Each provider has their own way of importing third party calendars, here's instruction links to the three most popular services;<br /><br /><a href='https://support.google.com/calendar/answer/37100?hl=en' target="_blank">Google</a>, <a href='https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac' target="_blank">Apple</a> and <a href='https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-on-the-web-503ffaf6-7b86-44fe-8dd6-8099d95f38df' target="_blank">Microsoft</a></p>
        </Trans>

        <textarea value={webcalUrl} readOnly onClick={onCopyWebcalToClipboard} />

        <CalendarEvents>
          <CheckboxInput
            onChange={() => toggleType(CalendarResourceType.PROJECT_EVENT_TYPE)}
            checked={activeTypes.includes(CalendarResourceType.PROJECT_EVENT_TYPE)}
            label={t('Calendar::Projects')}
          />
          <CheckboxInput
            onChange={() => toggleType(CalendarResourceType.QUOTATION_EVENT_TYPE)}
            checked={activeTypes.includes(CalendarResourceType.QUOTATION_EVENT_TYPE)}
            label={t('Calendar::Quotations')}
          />
          <CheckboxInput
            onChange={() => toggleType(CalendarResourceType.INVOICE_EVENT_TYPE)}
            checked={activeTypes.includes(CalendarResourceType.INVOICE_EVENT_TYPE)}
            label={t('Calendar::Invoices')}
          />
          <CheckboxInput
            onChange={() => toggleType(CalendarResourceType.TASK_EVENT_TYPE)}
            checked={activeTypes.includes(CalendarResourceType.TASK_EVENT_TYPE)}
            label={t('Calendar::Tasks')}
          />
          <CheckboxInput
            onChange={() => toggleType(CalendarResourceType.CALENDAR_EVENT_TYPE)}
            checked={activeTypes.includes(CalendarResourceType.CALENDAR_EVENT_TYPE)}
            label={t('Calendar::Events')}
          />
          <CheckboxInput
            onChange={() => toggleType(CalendarResourceType.DEAL_EVENT_TYPE)}
            checked={activeTypes.includes(CalendarResourceType.DEAL_EVENT_TYPE)}
            label={t('Calendar::Deals')}
          />
        </CalendarEvents>
      </Panel >
    </>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Calendar))