import * as React from 'react'
import copy from 'copy-to-clipboard';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { AppState } from '../../store'
import { showConfirmModal, showSendContractModal, showShareLinksModal } from '../../store/modals/actions'
import { ContractsController } from '../../controllers';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import ContractHelper from '../../helpers/Contracthelper';
import Notification from '../../utilities/Notification';
import PageLoader from '../Page/PageLoader';
import ResourceTable from '../Resource/ResourceTable';
import { IActionListItem } from '../ActionList/ActionList';
import ResourceTableRow from '../Resource/ResourceTableRow';
import ResourceTableRowData from '../Resource/ResourceTableRowData';
import moment from '../../utilities/Moment';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import ResourceTableRowActions from '../Resource/ResourceTableRowActions';
import { ShareableLink } from '../Modals/ShareLinksModal';
import ButtonPanel from '../Button/ButtonPanel';
import CurrentUserHelper from '../../helpers/CurrentUserHelper';
import Panel from '../Panel/Panel';
import Images from '../../images';
import { Contract, ContractStatus, CurrentUser, ResourceListFilterType } from '../../types';
import { useDebouncedCallback } from 'use-debounce';

interface IState {
	contracts: Contract[],
	currentPage: number,
	totalPages: number
	didInitialLoad: boolean
	isFetching: boolean
	sortValue: string
	filters: any
	searchValue: string
}


interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showConfirmModal: typeof showConfirmModal
	showSendContractModal: typeof showSendContractModal
	showShareLinksModal: typeof showShareLinksModal
}

type IProps = {
	contactId?: string,
	projectId?: string,
	primaryActionEnabled?: boolean
} & IStateToProps & IDispatchToProps & RouteComponentProps<any>

const ContractsTable = (props: IProps) => {
	const { showConfirmModal, primaryActionEnabled } = props
	const { t } = useTranslation()
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
	const { workspace: { setting } } = currentUser

	const [state, setState] = React.useState<IState>({
		contracts: [],
		currentPage: 0,
		totalPages: 0,
		didInitialLoad: false,
		isFetching: false,
		sortValue: LocalStorage.get(LocalStorageKey.CONTRACT_SORT_VALUE, '-'),
		filters: {},
		searchValue: '',
	})

	const {
		contracts,
		currentPage,
		totalPages,
		didInitialLoad,
		filters,
		isFetching,
		sortValue,
		searchValue
	} = state

	const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

	React.useEffect(() => {
		debouncedFetchContracts(1)
	}, [filters, sortValue, searchValue])

	const fetchContracts = async (page: number) => {
		try {
			let params: any = {
				page: page,
				search: searchValue,
				order: `${sortValue}`,
				...filters
			}

			setState({ ...state, isFetching: true })

			if (props.contactId) params = { ...params, contact_id: props.contactId }
			if (props.projectId) params = { ...params, project_id: props.projectId }

			const response = await ContractsController.getContracts(params)

			const { contracts: responseContracts, current_page, total_pages, total_entries } = response;

			setState({
				...state,
				contracts: [...responseContracts],
				currentPage: current_page,
				totalPages: total_pages,
				didInitialLoad: true,
				isFetching: false
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	const debouncedFetchContracts = useDebouncedCallback((page) => fetchContracts(page), 250);

	const onNewContractClick = async () => {
		const params = {}
		if (props.contactId) params['contact_id'] = props.contactId
		if (props.projectId) params['project_id'] = props.projectId
		props.history.push(RouteHelper.process(ERoute.PATH_CONTRACTS_CREATE, params))
	}

	const onFiltersChange = (newFilters: any) => {
		setState({ ...state, filters: newFilters })
	}

	const onSortChange = (newSortValue: string) => {
		LocalStorage.set(LocalStorageKey.CONTRACT_SORT_VALUE, newSortValue)
		setState({ ...state, sortValue: newSortValue })
	}

	const onRowClick = (contract: Contract) => {
		props.history.push(RouteHelper.process(ERoute.PATH_CONTRACT, { id: contract.id }))
	}

	const onRowActionClick = (key: string, contract: Contract) => {
		switch (key) {
			case 'send-contract':
				onSendContractClick(contract)
				break
			case 'copy-link':
				onCopyLinksClick(contract)
				break
			case 'download':
				ContractHelper.download(contract.id)
				break
			case 'edit':
				props.history.push(RouteHelper.process(ERoute.PATH_CONTRACT, { id: contract.id }))
				break
			case 'duplicate':
				onDuplicateClick(contract)
				break
			case 'delete':
				showConfirmModal({
					title: t('Contract::Delete contract'),
					description: t('Contract::You are about to delete contract <b>{{name}}</b>. Are you sure?', { name: contract.name }),
					action: { label: t('Contract::Delete'), isDestructive: true },
					onConfirm: async () => {
						try {
							const response = await ContractsController.delete(contract.id)

							if (response.errors) { }
							else {
								const contractIndex = contracts.findIndex(c => c.id === contract.id);

								contracts.splice(contractIndex, 1);

								setState({ ...state, contracts: [...contracts] })

								Notification.notifySuccess(t('Contract::Contract successfully deleted'))
							}
						} catch (ex) {
							console.error(ex)
						}
					}
				})
		}
	}

	const onSendContractClick = (contract: Contract) => {
		requestAnimationFrame(() => {
			props.showSendContractModal({
				id: contract.id,
			})
		})
	}

	const onCopyLinksClick = async (contract: Contract) => {
		try {
			const response = await ContractsController.getSigneeLinks(contract.id)

			const shareableLinks: ShareableLink[] = response.signee_links.map(signeeLink => {
				return {
					content: signeeLink.signee.name,
					url: signeeLink.url
				}
			})

			if (shareableLinks.length > 1) {
				requestAnimationFrame(() => {
					props.showShareLinksModal({
						title: t('Contract::Share contract'),
						shareableLinks: shareableLinks
					})
				})
			} else if (shareableLinks.length === 1) {
				const shareableLink = shareableLinks[0]
				copy(shareableLink.url)
				Notification.notifySuccess(t('ShareLinksModal::Copied to clipboard'))
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	const onDuplicateClick = async (contract: Contract) => {
		try {
			const response = await ContractsController.duplicate(contract.id)
			const duplicatedContract = response

			props.history.push(RouteHelper.process(ERoute.PATH_CONTRACT, { id: duplicatedContract.id }))
		} catch (ex) {
			console.error(ex)
		}
	}

	const onSearchChange = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onSearchSubmit = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onClearFilters = () => {
		setState({ ...state, searchValue: '', filters: {} })
	}

	return (
		<>

			{!didInitialLoad && <PageLoader />}
			{didInitialLoad && <ResourceTable
				style={{ marginBottom: 75 }}
				data={contracts}
				actionsLeft={primaryActionEnabled ? [
					<ButtonPanel
						icon='plus'
						text={t('Contracts::New contract')}
						onClick={onNewContractClick}
					/>
				] : null}
				headers={[
					{ title: t('Contracts::Name'), colSpan: 2 },
					{ title: t('Contracts::Contact') },
					{ title: t('Contracts::Project') },
					{ title: t('Contracts::Status'), align: 'right' },
					{ title: t('Contracts::Creation date'), align: 'right' },
					{ title: '', stickyRight: '0px' },
				]}
				renderRow={(contract: Contract) => {
					const sendable = ContractHelper.sendable(contract)

					let actions: IActionListItem[] = [
						{ key: 'send-contract', icon: 'send', content: t('Contract::Send contract'), visible: sendable },
						{ key: 'copy-link', icon: 'link', content: t('Contract::Copy link'), visible: sendable },
						{ key: 'download', icon: 'download-circle', content: t('Contracts::Download') },
						{ key: 'duplicate', icon: 'duplicate', content: t('Contracts::Duplicate') },
						{ key: 'edit', icon: 'edit-solid', content: t('Contracts::Edit') },
						{ key: 'delete', icon: 'trash-alt-solid', content: t('Contracts::Delete'), destructive: true },
					]

					return (
						<ResourceTableRow key={contract.id}>
							<ResourceTableRowData onClick={(): void => onRowClick(contract)} colSpan={2} maxWidth='150px' ellipse>
								<b>{contract.name ? contract.name : '-'}</b>
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(contract)} maxWidth='150px' ellipse>
								{contract.contact ? contract.contact.name : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(contract)} maxWidth='150px' ellipse>
								{contract.project ? contract.project.name : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(contract)} textAlign='right'>
								{ContractHelper.getBadge(contract)}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(contract)} textAlign='right'>
								{moment(contract.created_at).format(setting.date_format)}
							</ResourceTableRowData>
							<ResourceTableRowActions
								actions={actions}
								onActionClick={(key) => onRowActionClick(key, contract)}
								sticky={true}
								stickyRight='0px'
							/>
						</ResourceTableRow>
					)
				}}
				renderEmpty={<CardEmptyInfo
					icon={filtersActive ? 'search' : 'file-contract'}
					description={filtersActive ? t('Contracts::No contracts found') : t('Contracts::No contracts have been created yet')}
					descriptionActionText={filtersActive ? t('Contracts::Clear filters') : t('Contracts::Add contract')}
					onDescriptionActionClick={filtersActive ? onClearFilters : onNewContractClick}
				/>}
				filters={[
					{ name: 'name', label: t('Contracts::Name'), type: ResourceListFilterType.STRING },
					{ name: 'contact_id', label: t('Contracts::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
					{ name: 'project_id', label: t('Contracts::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
					{ name: 'status[in]', label: t('Contracts::Status'), type: ResourceListFilterType.MULTIPLE_OPTION, options: [{ label: t(`ContractStatus::${ContractStatus.DRAFT}`), value: ContractStatus.DRAFT }, { label: t(`ContractStatus::${ContractStatus.PENDING}`), value: ContractStatus.PENDING }, { label: t(`ContractStatus::${ContractStatus.SIGNED}`), value: ContractStatus.SIGNED }, { label: t(`ContractStatus::${ContractStatus.REJECTED}`), value: ContractStatus.REJECTED }] },
					{ name: 'created_at', label: t('Contracts::Created date'), type: ResourceListFilterType.DATE },
				]}
				onFiltersChange={onFiltersChange}
				sortOptions={[
					{ label: '-', value: '-' },
					{ label: t('Contracts::Name (A-Z)'), value: 'name_asc' },
					{ label: t('Contracts::Name (Z-A)'), value: 'name_desc' },
					{ label: t('Contracts::Created at ↑'), value: 'created_at_asc' },
					{ label: t('Contracts::Created at ↓'), value: 'created_at_desc' },
				]}
				sortValue={sortValue}
				onSortChange={onSortChange}
				pagination={{ page: currentPage, pageCount: totalPages }}
				onPageChange={(page) => fetchContracts(page)}
				isLoading={isFetching}
				stickyHeader={true}
				searchValue={searchValue}
				onSearchChange={onSearchChange}
				onSearchSubmit={onSearchSubmit}
				maxHeight='65vh'
			/>}
		</>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showConfirmModal,
	showSendContractModal,
	showShareLinksModal
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractsTable))