import * as React from 'react'
import BaseTooltip from './BaseTooltip'

interface IProps {
	tooltip: any
}

export default class TrackReportTooltip extends React.PureComponent<IProps> {
	render () {
		const { tooltip } = this.props

		const title = tooltip.title[0];
		const bodyLines = tooltip.body.map(item => item.lines);

		return (
			<BaseTooltip className='is-subtle'>
				<div dangerouslySetInnerHTML={{ __html: bodyLines }} />
			</BaseTooltip>
		)
	}
}