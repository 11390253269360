import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import PopoverDivider from '../Popover/PopoverDivider'
import PopoverItem from '../Popover/PopoverItem'
import DealPopoverClose from '../TaskModal/TaskPopoverClose'
import DealPopoverContainer from '../TaskModal/TaskPopoverContainer'
import DealPopoverContent from '../TaskModal/TaskPopoverContent'
import DealPopoverHeader from '../TaskModal/TaskPopoverHeader'
import DealPopoverTitle from '../TaskModal/TaskPopoverTitle'

interface IProps {
	activator: JSX.Element
	onAddDealClick: () => void
	onMoveDealsClick: () => void
	onDeleteDealsClick: () => void
	onEditPhaseClick: () => void
	onDeletePhaseClick: () => void
}

const DealStageActionsPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator } = props


	const [popoverActive, setPopoverActive] = React.useState(false)

	const onTogglePopover = () => {
		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onAddDealClick = () => {
		props.onAddDealClick()
		setPopoverActive(false)
	}

	const onMoveDealsClick = () => {
		props.onMoveDealsClick()
		setPopoverActive(false)
	}

	const onDeleteDealsClick = () => {
		props.onDeleteDealsClick()
		setPopoverActive(false)
	}

	const onEditClick = () => {
		setPopoverActive(false)

		props.onEditPhaseClick()
	}

	const onDeleteClick = () => {
		setPopoverActive(false)

		props.onDeletePhaseClick()
	}


	return (
		<Popover
			active={popoverActive}
			activator={<div onClick={onTogglePopover}>
				{activator}
			</div>}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<DealPopoverContainer>
				<DealPopoverHeader>
					<div />
					<DealPopoverTitle>{t('DealStageActionsPopover::Actions')}</DealPopoverTitle>
					<DealPopoverClose onClick={onPopoverClose} />
				</DealPopoverHeader>
				<DealPopoverContent>
					<PopoverItem onClick={onAddDealClick}>
						{t('DealStageActionsPopover::Add deal...')}
					</PopoverItem>
					{false && <PopoverItem>
						Copy list...
					</PopoverItem>}
					{false && <PopoverItem>
						Move list...
					</PopoverItem>}

					<PopoverDivider />

					<PopoverItem onClick={onMoveDealsClick}>
						{t('DealStageActionsPopover::Move all deals in this stage')}
					</PopoverItem>

					<PopoverItem onClick={onDeleteDealsClick}>
						{t('DealStageActionsPopover::Delete all deals in this stage')}
					</PopoverItem>

					<PopoverDivider />

					<PopoverItem onClick={onEditClick}>
						{t('DealStageActionsPopover::Edit stage')}
					</PopoverItem>

					<PopoverItem onClick={onDeleteClick}>
						{t('DealStageActionsPopover::Delete this stage')}
					</PopoverItem>
				</DealPopoverContent>
			</DealPopoverContainer>
		</Popover >
	)
}

export default DealStageActionsPopover