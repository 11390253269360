import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Icon from '../../components/Icons/Icon'
import ConversationListEmptyDescription from '../../components/Conversation/ConversationListEmptyDescription'
import ConversationListEmptyTitle from '../../components/Conversation/ConversationListEmptyTitle'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	svg {
		width: 100%;
		margin-bottom: 20px;
		width: 250px;
	}

	&::-webkit-scrollbar {
    background: white;
		height: 8px;
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 4px;
	}
`

const EmptyFormPlaceholder = () => {
	const { t } = useTranslation()

	return (
		<Container>
			<Icon icon='message-bubble-illustration' />

			<ConversationListEmptyTitle>
				{t('EmptyFormPlaceholder::No response selected')}
			</ConversationListEmptyTitle>

			<ConversationListEmptyDescription>
				{t('EmptyFormPlaceholder::Select a response to view the results')}
			</ConversationListEmptyDescription>
		</Container>
	)
}

export default EmptyFormPlaceholder