import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { WithTranslation, withTranslation } from 'react-i18next'
import IntegrationFormTitle from '../IntegrationFormTitle'
import { YukiIntegrationConfig } from '../../../types'

type IProps = {
	config: YukiIntegrationConfig
	formData: any
	onConfigChange: (config: YukiIntegrationConfig) => void
} & WithTranslation

interface IState { }

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		color: white;
		margin-bottom: ${Style.spacing.x2};
		font-weight: bold;
	}
	
	label {
		color: white;
	}
`

class YukiForm extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.onAccessTokenChange = this.onAccessTokenChange.bind(this)
		this.onAdministrationChange = this.onAdministrationChange.bind(this)
	}

	onAccessTokenChange(e) {
		const { config, onConfigChange } = this.props


		onConfigChange({
			...config,
			access_token: e.currentTarget.value,
		})
	}

	onAdministrationChange(e) {
		const { config, onConfigChange } = this.props


		onConfigChange({
			...config,
			administration_id: e.currentTarget.value,
		})
	}

	render() {
		const { config, t } = this.props

		return (
			<Container>
				<IntegrationFormTitle>
					{t('YukiForm::Settings')}
				</IntegrationFormTitle>

				<div className='form-item'>
					<label>{t('YukiForm::Access token')}</label>
					<input
						type='text'
						value={config.access_token}
						placeholder='9341f1f2-28z3-42eb-1833-db3a04a219a3'
						onChange={this.onAccessTokenChange}
					/>
				</div>

				<div className='form-item'>
					<label>{t('YukiForm::Administration ID')}</label>
					<input
						type='text'
						value={config.administration_id}
						placeholder='2d72ea26-1e1d-41c8-9dd1-b30bb57a1535'
						onChange={this.onAdministrationChange}
					/>
				</div>
			</Container>
		)
	}
}

export default withTranslation()(YukiForm)