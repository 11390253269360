import * as React from 'react'
import { Dispatch } from 'redux'
import { AppState } from '../../store'
import TopNavigation from '../../components/Navigation/TopNavigation'
import { connect } from 'react-redux'
import { LedgerConditionsController, SettingsController } from '../../controllers'
import Notification from '../../utilities/Notification';
import CardEmptyInfo from '../../components/Card/CardEmptyInfo'
import Icon from '../../components/Icons/Icon'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import { IActionListItem } from '../../components/ActionList/ActionList'
import Card from '../../components/Card/Card'
import { updateSettings } from '../../store/authentication/actions'
import PageHeader from '../../components/Page/PageHeader'
import { RouteComponentProps } from 'react-router-dom'
import PageContent from '../../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import ResourceTable from '../../components/Resource/ResourceTable'
import ResourceTableRow from '../../components/Resource/ResourceTableRow'
import ResourceTableRowData from '../../components/Resource/ResourceTableRowData'
import Badge from '../../components/Badge/Badge'
import ResourceTableRowActions from '../../components/Resource/ResourceTableRowActions'
import RouteHelper from '../../helpers/RouteHelper'
import ERoute from '../../ERoute'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import moment from '../../utilities/Moment'
import { CurrentUser, DisplayableError, LedgerCondition, ResourceListFilterType, Settings } from '../../types'

interface IStateProps {
  currentUser: CurrentUser
}

interface IDispatchProps {
  updateSettings: typeof updateSettings
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<{}> & WithTranslation

interface IState {
  conditions: LedgerCondition[]
  currentPage: number
  totalPages: number
  reachedEnd: boolean
  didInitialLoad: boolean
  isFetching: boolean
  errors: DisplayableError[]
  sortValue: string
  filters: any
}

class LedgerConditions extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      conditions: [],
      currentPage: 1,
      totalPages: 1,
      reachedEnd: false,
      didInitialLoad: false,
      isFetching: false,
      errors: [],
      sortValue: LocalStorage.get(LocalStorageKey.LEDGER_CONDITION_SORT_VALUE, '-'),
      filters: {}
    }

    this.onAddNewConditionClick = this.onAddNewConditionClick.bind(this)
    this.onEditConditionClick = this.onEditConditionClick.bind(this)
    this.onSetAsDefaultClick = this.onSetAsDefaultClick.bind(this)
    this.onDuplicateConditionClick = this.onDuplicateConditionClick.bind(this)
    this.onDeleteConditionClick = this.onDeleteConditionClick.bind(this)
    this.onFetchMoreClick = this.onFetchMoreClick.bind(this)
    this.onTableLedgerConditionActionClick = this.onTableLedgerConditionActionClick.bind(this)
    this.onBreadCrumbBackPress = this.onBreadCrumbBackPress.bind(this)
    this.onFiltersChange = this.onFiltersChange.bind(this)
    this.onLedgerConditionSortValueChange = this.onLedgerConditionSortValueChange.bind(this)
  }

  componentDidMount() {
    this.fetchConditions(1)
  }

  fetchConditions(page = 1) {
    const { sortValue, filters } = this.state
    this.setState({ isFetching: true })

    LedgerConditionsController
      .get({ page: page, order: `${sortValue}`, ...filters })
      .then(response => {
        const { ledger_conditions, current_page, total_pages } = response

        this.setState({
          conditions: [...ledger_conditions],
          didInitialLoad: true,
          reachedEnd: current_page === total_pages,
          currentPage: current_page,
          totalPages: total_pages,
          isFetching: false
        });
      })
      .catch(console.error)
  }

  createCondition(condition, callback) {
    const { t } = this.props
    LedgerConditionsController
      .create(condition)
      .then(response => {
        if (response.errors) {
          const errors = response.errors;

          this.setState({
            errors: errors
          });

          Notification.notifyError(t('LedgerCondition::Oops something went wrong'))
        }
        else {
          if (callback) callback(response)
          Notification.notifySuccess(t('LedgerCondition::Conditions succesfully duplicated'))
        }
      })
      .catch(console.error)
  }

  deleteCondition(id: string, callback) {
    LedgerConditionsController
      .delete(id)
      .then(response => {
        if (callback) callback(response);
      })
      .catch(console.error)
  }

  onAddNewConditionClick() {
    this.props.history.push(RouteHelper.process(ERoute.PATH_LEDGER_CONDITION_NEW))
  }

  onEditConditionClick(index) {
    const { conditions } = this.state

    const condition = conditions[index]

    this.props.history.push(RouteHelper.process(ERoute.PATH_LEDGER_CONDITION, { id: condition.id }))
  }

  onDuplicateConditionClick(index) {
    const { conditions } = this.state

    const condition = Object.assign({}, conditions[index])
    condition.id = null

    this.createCondition(condition, (createdCondition) => {
      this.setState({
        conditions: [createdCondition, ...conditions]
      }, () => {
        this.onEditConditionClick(0)
      })
    })
  }

  onDeleteConditionClick(index) {
    const { t } = this.props
    const { conditions } = this.state

    const condition = conditions[index]

    this.deleteCondition(condition.id, (response) => {
      if (response.success) {
        Notification.notifySuccess(t('LedgerCondition::Conditions successfully deleted.'))

        conditions.splice(index, 1);

        this.setState({
          conditions
        })
      } else {
        Notification.notifyError(t('LedgerCondition::Oops something went wrong'))
      }
    })
  }

  onSetAsDefaultClick(index) {
    const { currentUser: { workspace: { setting } }, updateSettings, t } = this.props

    const { conditions } = this.state

    const selectedConditionId = conditions[index].id

    const newSetting: Settings = {
      ...setting,
      default_ledger_condition_id: selectedConditionId,
    }

    SettingsController
      .update(newSetting)
      .then(setting => {

        this.forceUpdate()

        updateSettings(setting)

        Notification.notifySuccess(t('LedgerCondition::Condition successfully set as default.'))
      })
      .catch(error => console.error(error))
  }

  onFetchMoreClick(e) {
    e.preventDefault()

    const currentPage = this.state.currentPage
    this.fetchConditions(currentPage + 1)
  }

  onTableLedgerConditionActionClick(action: string, index: number) {
    switch (action) {
      case 'set_default': this.onSetAsDefaultClick(index)
        break
      case 'edit': this.onEditConditionClick(index)
        break
      case 'duplicate': this.onDuplicateConditionClick(index)
        break
      case 'delete': this.onDeleteConditionClick(index)
        break
    }
  }

  onBreadCrumbBackPress(e) {
    e.preventDefault()

    this.props.history.goBack()
  }

  onFiltersChange(filters) {
    this.setState({ filters: filters }, () => {
      this.fetchConditions(1)
    })
  }

  onLedgerConditionSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.LEDGER_CONDITION_SORT_VALUE, value)
    this.setState({ sortValue: value }, () => { this.fetchConditions(1) })
  }


  render() {
    const {
      conditions,
      currentPage,
      totalPages,
      didInitialLoad,
      isFetching,
      filters,
      sortValue,
    } = this.state

    const { currentUser: { workspace }, t } = this.props
    const { setting } = workspace

    const filtersActive = Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{t('LedgerCondition::{{__appName}} | Terms & Conditions')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='expense'
          title={t('LedgerCondition::Conditions')}
          action={
            <a key="new-expense" href="javascript://" className="button button-primary page-action" onClick={this.onAddNewConditionClick}>
              <Icon icon='plus' />
              {t('LedgerCondition::New conditions')}
            </a>
          }
        />

        <PageContent>
          <PageHeader
            title={t('LedgerCondition::Conditions')}
            breadcrumbs={[
              { content: t('LedgerCondition::Back'), url: 'javascript://', onClick: this.onBreadCrumbBackPress },
            ]}
          />
          <div className='invoice-conditions animated'>
            {didInitialLoad && conditions.length === 0 && <Card>
              <CardEmptyInfo
                icon='expense'
                description={t('LedgerCondition::You don\'t have any conditions yet.')}
                descriptionActionText={t('LedgerCondition::Create conditions')}
                onDescriptionActionClick={() => this.onAddNewConditionClick()}
              />
            </Card>}
            {didInitialLoad && conditions.length > 0 && <ResourceTable
              headers={[
                { title: t('LedgerCondition::Name') },
                { title: t('LedgerCondition::Default') },
                { title: t('LedgerCondition::Created on'), align: 'right' },
                { title: '', stickyRight: '0px' },
              ]}
              data={conditions}
              renderRow={(ledgerCondition: LedgerCondition, index) => {
                let actions: IActionListItem[] = [
                  { key: 'set_default', icon: 'star', content: t('LedgerCondition::Set as default') },
                  { key: 'edit', icon: 'edit-solid', content: t('LedgerCondition::Edit') },
                  { key: 'duplicate', icon: 'duplicate', content: t('LedgerCondition::Duplicate') },
                  { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerCondition::Delete'), destructive: true },
                ]

                const isDefault = ledgerCondition.id === setting.default_ledger_condition_id

                return (
                  <ResourceTableRow key={ledgerCondition.id}>
                    <ResourceTableRowData onClick={() => this.onEditConditionClick(index)}>
                      {ledgerCondition.name}
                    </ResourceTableRowData>
                    <ResourceTableRowData onClick={() => this.onEditConditionClick(index)}>
                      {isDefault && <Badge type='grey' text={t('LedgerCondition::Default')} />}
                    </ResourceTableRowData>
                    <ResourceTableRowData textAlign='right' onClick={() => this.onEditConditionClick(index)}>
                      {moment(ledgerCondition.created_at).format(setting.date_format)}
                    </ResourceTableRowData>
                    <ResourceTableRowActions
                      actions={actions}
                      onActionClick={(key) => this.onTableLedgerConditionActionClick(key, index)}
                      sticky={true}
                      stickyRight='0px'
                    />
                  </ResourceTableRow>
                )
              }}
              renderEmpty={<CardEmptyInfo
                icon={filtersActive ? 'search' : 'invoice'}
                description={filtersActive ? t('LedgerCondition::No conditions found') : t('LedgerCondition::No conditions have been created yet')}
                descriptionActionText={filtersActive ? t('LedgerCondition::Clear filters') : t('LedgerCondition::Add new condition')}
                onDescriptionActionClick={filtersActive ? () => this.onFiltersChange({}) : this.onAddNewConditionClick}
              />}
              filters={[
                { name: 'name', label: t('LedgerCondition::Name'), type: ResourceListFilterType.STRING },
              ]}
              onFiltersChange={this.onFiltersChange}
              sortOptions={[
                { label: '-', value: '-' },
                { label: t('LedgerCondition::Name (A-Z)'), value: 'name_asc' },
                { label: t('LedgerCondition::Name (Z-A)'), value: 'name_desc' },
                { label: t('LedgerCondition::Created at ↑'), value: 'created_at_asc' },
                { label: t('LedgerCondition::Created at ↓'), value: 'created_at_desc' },
                { label: t('LedgerCondition::Updated at ↑'), value: 'updated_at_asc' },
                { label: t('LedgerCondition::Updated at ↓'), value: 'updated_desc' },
              ]}
              sortValue={sortValue}
              onSortChange={this.onLedgerConditionSortValueChange}
              pagination={{ page: currentPage, pageCount: totalPages }}
              onPageChange={(page) => this.fetchConditions(page)}
              isLoading={isFetching}
              stickyHeader={true}
              maxHeight='65vh'
            />}
          </div>
        </PageContent>
      </>
    )
  }
}


const mapStateToProps = (state: AppState): IStateProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LedgerConditions))