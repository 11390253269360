import * as React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { showConfirmModal, showTaskModal, showBoardModal } from '../store/modals/actions'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import { AppState } from '../store'
import { WithTranslation, withTranslation } from 'react-i18next'
import { matchPath, Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import ERoute from '../ERoute'
import FullPageContent from '../components/Page/FullPageContent'
import { CurrentUser, Board, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../types'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import Icon from '../components/Icons/Icon'
import BoardComponent from '../components/Board/Board'
import { BoardsController } from '../controllers'
import RouteHelper from '../helpers/RouteHelper'
import TaskView, { TaskViewMode } from '../components/Tasks/TaskView'
import ActionCableConsumer from '../consumers/ActionCableConsumer'
import TasksNavigationToggle from '../components/Tasks/TasksNavigationToggle'
import UrlHelper from '../helpers/UrlHelper'
import Planner from './Planner'

const TasksOverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	position: relative;
`

const TasksNavigationSidebar = styled.div<{ active?: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	flex: 1;
	background: white;
	border-right: 1px solid ${Style.color.border};
	max-width: 230px;
	min-width: 230px;
	padding: 12px;
	z-index: 7;
	
	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		left: -230px;
		border-right: none
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		${props => props.active && css`
			left: 0;
			right: 0;
			max-width: 100%;
			min-width: 100%;
		`}
	}
`

const TaskNavigationItem = styled.div<{ active?: boolean }>`
	border-radius: 5px;
	border: 1px solid transparent;
	padding: 4px;
	margin-bottom: 4px;
	transition: none;
	min-height: 31px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

  &:hover {
		border-color: ${Style.color.border};
		border-style: dashed;
	}

	${props => props.active && css`
		background-color: hsl(0,0%,95%);
		border-color: ${Style.color.border};
		border-style: solid;

		&:hover {
			border-color: ${Style.color.border};
			border-style: solid;
		}
	`}
`

const TaskNavigationItemIcon = styled.div`
  margin-left: ${Style.spacing.x1};
  margin-right: ${Style.spacing.x1_5};
  background: #f7f7fa;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;

  svg {
    width: 16px;
    height: 16px;
		fill: black;
  }

	i {
		color: black;
	}
`

const TaskNavigationItemContent = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
`

const TaskNavigationItemGroup = styled.div`
	padding: 4px;
	margin-bottom: 4px;
	min-height: 31px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: bold;
	justify-content: space-between;
`

const TaskNavigationItemGroupActions = styled.div`
`

const TaskNavigationItemGroupAction = styled.div`
  background: #f7f7fa;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
	cursor: pointer;
	border: 1px solid transparent;

	&:hover {
		border-color: grey;
	}

  svg {
    width: 16px;
    height: 16px;
  }
`

const TasksNavigationContent = styled.div`
	height: 100%;
	flex: 1;
	background: #f7f7fa;
	width: 100%;
	margin-left: 230px;
	overflow: hidden;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-left: 0;
	}
`

const TaskViewContainer = styled.div`
	margin: ${Style.spacing.x2} ${Style.spacing.x2} 0 ${Style.spacing.x2};
`

interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showTaskModal: typeof showTaskModal
	showConfirmModal: typeof showConfirmModal
	showBoardModal: typeof showBoardModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
	boards: Board[]
	navigationActive: boolean
}

class Tasks extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			boards: [],
			navigationActive: false
		}

		this.fetchBoards = this.fetchBoards.bind(this)
		this.onToggleNavigationSidebar = this.onToggleNavigationSidebar.bind(this)
		this.onNewBoardClick = this.onNewBoardClick.bind(this)
		this.onBoardSubmit = this.onBoardSubmit.bind(this)
		this.onNavigateItemClick = this.onNavigateItemClick.bind(this)
		this.onActionCableConnected = this.onActionCableConnected.bind(this)
		this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
		this.onActionCableReceived = this.onActionCableReceived.bind(this)

	}

	componentDidMount(): void {
		this.fetchBoards()
		this.parseQueryParams()
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (prevProps.location.search !== this.props.location.search) {
			this.parseQueryParams()
		}
	}

	parseQueryParams() {
		const { showTaskModal, location: { search } } = this.props
		const params = UrlHelper.getParams(search)

		if (params.taskId) {
			showTaskModal({
				task: {
					id: params.taskId
				},
			})
		}
	}

	async fetchBoards() {
		try {
			const { boards } = await BoardsController.getBoards({ per_page: 1000, order: 'created_at_asc' })

			this.setState({ boards: boards })
		} catch (ex) {
			console.error(ex)
		}
	}


	onToggleNavigationSidebar() {
		this.setState({ navigationActive: !this.state.navigationActive })
	}

	onNewBoardClick() {
		this.props.showBoardModal({
			onSubmit: this.onBoardSubmit,
		})
	}

	onBoardSubmit(newBoard: Board) {
		const { boards } = this.state

		const boardIndex = boards.findIndex((board) => board.id === newBoard.id)

		// If the board doesn't exist, add it
		if (boardIndex === -1) {
			this.setState({ boards: [...boards, newBoard] })
		}
	}

	onNavigateItemClick(path: string) {
		const { navigationActive } = this.state

		if (navigationActive) this.setState({ navigationActive: false })
		this.props.history.replace(path)
	}

	onActionCableConnected() {
		console.log('[WorkspaceChannel - Tasks] connected')
	}

	onActionCableDisconnected() {
		console.log('[WorkspaceChannel - Tasks] Disconnected')
	}

	onActionCableReceived(event: WorkspaceChannelEvent) {
		const { boards } = this.state
		console.log('[WorkspaceChannel - Tasks] received event', event)

		switch (event.type) {
			case WorkspaceChannelEventType.BOARD_CREATE:
				const boardCreateIndex = boards.findIndex((board) => board.id === event.data.board.id)

				// If the board doesn't exist, add it
				if (boardCreateIndex === -1) {
					this.setState({
						boards: [...boards, event.data.board]
					})
				}
				break
			case WorkspaceChannelEventType.BOARD_UPDATE:
				const boardUpdateIndex = boards.findIndex((board) => board.id === event.data.board.id)

				// If the board exists, update it
				if (boardUpdateIndex !== -1) {
					boards[boardUpdateIndex] = event.data.board

					this.setState({ boards: [...boards] })
				}
				break
			case WorkspaceChannelEventType.BOARD_DELETE:
				const boardIndex = boards.findIndex((board) => board.id === event.data.board_id)

				// If the board exists, delete it
				if (boardIndex !== -1) {
					boards.splice(boardIndex, 1)
					this.setState({ boards: [...boards] })
				}
				break
		}
	}

	render() {
		const { t, location, currentUser } = this.props
		const { boards, navigationActive } = this.state
		const activePath = location.pathname

		return (
			<>
				<Helmet>
					<title>{t('Tasks::{{__appName}} | Tasks')}</title>
				</Helmet>

				<ScrollToTopOnMount />

				<TopNavigation
					icon='tasks'
					title={t('Tasks::Tasks')}
				/>

				<ActionCableConsumer
					channel={{ channel: 'WorkspaceChannel', id: currentUser.workspace.id }}
					onConnected={this.onActionCableConnected}
					onDisconnected={this.onActionCableDisconnected}
					onReceived={this.onActionCableReceived}
				>
					<FullPageContent>
						<TasksOverviewContainer>
							<TasksNavigationToggle onClick={this.onToggleNavigationSidebar} />
							<TasksNavigationSidebar active={navigationActive}>
								<TaskNavigationItem
									active={!!matchPath(activePath, { path: ERoute.PATH_TASKS_OVERVIEW, exact: true })}
									onClick={() => this.onNavigateItemClick(ERoute.PATH_TASKS_OVERVIEW)}
								>
									<TaskNavigationItemIcon>
										<Icon icon='tasks' />
									</TaskNavigationItemIcon>

									<TaskNavigationItemContent>
										{t('Tasks::Overview')}
									</TaskNavigationItemContent>
								</TaskNavigationItem>

								<TaskNavigationItem
									active={!!matchPath(activePath, { path: ERoute.PATH_TASKS_MY_TASKS, exact: true })}
									onClick={() => this.onNavigateItemClick(ERoute.PATH_TASKS_MY_TASKS)}
								>
									<TaskNavigationItemIcon>
										<Icon icon='user' />
									</TaskNavigationItemIcon>

									<TaskNavigationItemContent>
										{t('Tasks::My tasks')}
									</TaskNavigationItemContent>
								</TaskNavigationItem>

								<TaskNavigationItem
									active={!!matchPath(activePath, { path: ERoute.PATH_TASKS_TODAY, exact: true })}
									onClick={() => this.onNavigateItemClick(ERoute.PATH_TASKS_TODAY)}
								>
									<TaskNavigationItemIcon>
										<Icon icon='calendar-star' />
									</TaskNavigationItemIcon>

									<TaskNavigationItemContent>
										{t('Tasks::Today')}
									</TaskNavigationItemContent>
								</TaskNavigationItem>

								<TaskNavigationItem
									active={!!matchPath(activePath, { path: ERoute.PATH_TASKS_UPCOMING, exact: true })}
									onClick={() => this.onNavigateItemClick(ERoute.PATH_TASKS_UPCOMING)}
								>
									<TaskNavigationItemIcon>
										<Icon icon='calendar-clock' />
									</TaskNavigationItemIcon>

									<TaskNavigationItemContent>
										{t('Tasks::Upcoming')}
									</TaskNavigationItemContent>
								</TaskNavigationItem>

								<TaskNavigationItem
									active={!!matchPath(activePath, { path: ERoute.PATH_TASKS_PLANNER, exact: true })}
									onClick={() => this.onNavigateItemClick(ERoute.PATH_TASKS_PLANNER)}
								>
									<TaskNavigationItemIcon>
										<Icon icon='calendar-check' />
									</TaskNavigationItemIcon>

									<TaskNavigationItemContent>
										{t('Tasks::Planner')}
									</TaskNavigationItemContent>
								</TaskNavigationItem>

								<TaskNavigationItemGroup>
									{t('Tasks::Boards')}

									<TaskNavigationItemGroupActions>
										<TaskNavigationItemGroupAction onClick={this.onNewBoardClick}>
											<Icon icon='plus' />
										</TaskNavigationItemGroupAction>
									</TaskNavigationItemGroupActions>
								</TaskNavigationItemGroup>

								{boards.map(board => {
									return (
										<TaskNavigationItem
											key={board.id}
											active={!!matchPath(activePath, { path: RouteHelper.process(ERoute.PATH_TASKS_BOARD, { id: board.id }), exact: true })}
											onClick={() => this.onNavigateItemClick(RouteHelper.process(ERoute.PATH_TASKS_BOARD, { id: board.id }))}
											title={board.name}
										>
											<TaskNavigationItemIcon>
												<Icon icon='boards' />
											</TaskNavigationItemIcon>

											<TaskNavigationItemContent>
												{board.name}
											</TaskNavigationItemContent>
										</TaskNavigationItem>
									)
								})}
							</TasksNavigationSidebar>

							<TasksNavigationContent>
								<Switch>
									<Route path={ERoute.PATH_TASKS_OVERVIEW}>
										<TaskViewContainer>
											<TaskView mode={TaskViewMode.OVERVIEW} />
										</TaskViewContainer>
									</Route>
									<Route path={ERoute.PATH_TASKS_MY_TASKS}>
										<TaskViewContainer>
											<TaskView mode={TaskViewMode.MY_TASKS} assigneeId={currentUser.id} />
										</TaskViewContainer>
									</Route>
									<Route path={ERoute.PATH_TASKS_TODAY}>
										<TaskViewContainer>
											<TaskView mode={TaskViewMode.TODAY} />
										</TaskViewContainer>
									</Route>
									<Route path={ERoute.PATH_TASKS_UPCOMING}>
										<TaskViewContainer>
											<TaskView mode={TaskViewMode.UPCOMING} />
										</TaskViewContainer>
									</Route>
									<Route path={ERoute.PATH_TASKS_PLANNER} component={Planner} />
									<Route path={ERoute.PATH_TASKS_BOARD} component={BoardComponent} />
									<Redirect from='/' to={ERoute.PATH_TASKS_OVERVIEW} />
								</Switch>
							</TasksNavigationContent>
						</TasksOverviewContainer>
					</FullPageContent>
				</ActionCableConsumer>
			</>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showTaskModal,
	showBoardModal,
	showConfirmModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Tasks))