import * as React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { DealStage } from '../../types';
import Icon from '../Icons/Icon';
import DealStageActionsPopover from './DealStageActionsPopover';
import MoveDealsPopover from './MoveDealsPopover';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	flex: 0 0 auto;
	position: relative;
	min-height: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  align-items: center;
  display: flex;
  color: #65686f;
  font-size: 14px;
  font-weight: 600;
`

const InputContainer = styled.form`
	position: relative;
	width: 100%;
	font-weight: 600;

	input {
		min-height: 28px;
		max-height: 28px;
    padding-left: 0 !important;

		&:focus {
			background: white;
			border-width: 2px;
		}

		&:disabled {
			background: transparent !important;
			border-color: transparent !important;
			border-radius: 3px;
			box-shadow: none;
			resize: none;
			resize: none;
			overflow: hidden;
			overflow-wrap: break-word;
			outline: none;
			color: black !important;
			line-height: normal;

			&:focus {
				box-shadow: none !important;
			}
		}
	}
`

const InputWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

const ActivatorContainer = styled.div``
const PopoverContainer = styled.div``

const Options = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 3px;
	padding: 6px;
	color: #6b778c;
	cursor: pointer;
	margin-top: -5px;
`

interface IProps {
  dealStage: DealStage
  name: string
  onNameChange: (name: string) => void
  onAddDealClick: () => void
  onMoveDealsClick: (dealStage: DealStage) => void
  onDeleteDealsClick: () => void
  onEditClick: () => void
  onDeleteClick: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps;
  setDragBlocking: React.Dispatch<React.SetStateAction<boolean>>
  dealsCount: number
  dealsAmount: string
}

const DealStageTitle = (props: IProps) => {
  const { t } = useTranslation()
  const { dealStage, name, dragHandleProps, setDragBlocking, dealsAmount, dealsCount } = props
  const input = React.useRef<HTMLInputElement>(null)
  const [editModeActive, setEditModeActive] = React.useState(false);
  const [nameValue, setNameValue] = React.useState(name);
  const [moveDealsPopoverActive, setMoveDealsPopoverActive] = React.useState<boolean>(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
  });

  React.useEffect(() => {
    // If user is not editing set the value of the new name
    if (!editModeActive) {
      setNameValue(props.name)
    }
  }, [name])

  const onNameClick = () => {
    if (!editModeActive) {
      // Disable dragging
      setDragBlocking(true);
      // Set form value to prop value
      setNameValue(props.name)
      // Enable edit mode
      setEditModeActive(true);

      setTimeout(() => {
        if (input && input.current) {
          input.current.select()
        }
      }, 50)
    }
  };

  const onNameChange = (e) => {
    setNameValue(e.currentTarget.value)
  }

  const onNameBlur = () => {

    setDragBlocking(false)
    setEditModeActive(false)

    // Trigger update only if values are different
    if (nameValue !== '' && name !== nameValue) {
      props.onNameChange(nameValue)
    }
  }

  const onNameSubmit = (e) => {
    e.preventDefault()

    setDragBlocking(false)
    setEditModeActive(false)

    // Trigger update only if values are different
    if (name !== nameValue) {
      props.onNameChange(nameValue)
    }
  }

  const onAddDealClick = () => {
    props.onAddDealClick()
  }

  const onMoveDealsClick = () => {
    setMoveDealsPopoverActive(true)
  }

  const onDeleteDealsClick = () => {
    props.onDeleteDealsClick()
  }

  const onEditClick = () => {
    props.onEditClick()
  }

  const onDeleteClick = () => {
    props.onDeleteClick()
  };

  const onCloseMoveDealPopover = () => {
    setMoveDealsPopoverActive(false)
  }

  const onMoveDeals = (dealStage: DealStage) => {
    // Trigger move tasks
    props.onMoveDealsClick(dealStage)

    // Close popover
    setMoveDealsPopoverActive(false)
  }

  return (
    <Container {...dragHandleProps}>
      <TopContainer>
        <InputContainer onSubmit={onNameSubmit}>
          {!editModeActive && <InputWrapper onClick={onNameClick} />}
          <input
            ref={input}
            type='text'
            value={nameValue}
            disabled={!editModeActive}
            onChange={onNameChange}
            onBlur={onNameBlur}
          />
          <input type='submit' style={{ display: 'none' }} />
        </InputContainer>
        <ActivatorContainer ref={setReferenceElement}>
          <DealStageActionsPopover
            activator={
              <>
                <ActivatorContainer>
                  <Options>
                    <Icon icon='solaris-dots' />
                  </Options>
                </ActivatorContainer>
              </>
            }
            onAddDealClick={onAddDealClick}
            onMoveDealsClick={onMoveDealsClick}
            onDeleteDealsClick={onDeleteDealsClick}
            onEditPhaseClick={onEditClick}
            onDeletePhaseClick={onDeleteClick}
          />
        </ActivatorContainer>

        <PopoverContainer ref={setPopperElement} style={{ ...styles.popper, zIndex: 7, cursor: 'initial' }} {...attributes.popper}>
          {moveDealsPopoverActive && <MoveDealsPopover
            dealStage={dealStage}
            onSubmit={onMoveDeals}
            onClose={onCloseMoveDealPopover}
          />}
        </PopoverContainer>
      </TopContainer>
      <BottomContainer>
        <span>{dealsAmount}</span>
        <span style={{ marginLeft: 4, marginRight: 4 }}>·</span>
        <span>{t('DealStageTitle::{{count}} deals', { count: dealsCount })}</span>
      </BottomContainer>
    </Container>
  )
}

export default DealStageTitle