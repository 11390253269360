import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import styled from 'styled-components'
import FlyoutHeader from '../Flyout/FlyoutHeader'
import { useTranslation } from 'react-i18next'
import ERoute from '../../ERoute';
import { Board, CurrentUser, Task } from '../../types';
import SidebarFlyoutContainer from './SidebarFlyoutContainer';
import FlyoutContent from '../Flyout/FlyoutContent';
import TasksSimplebar from './SidebarTask/TasksSimplebar';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { showTaskModal, showBoardModal } from '../../store/modals/actions';
import BoardsSimplebar from './SidebarTask/BoardsSimplebar';
import RouteHelper from '../../helpers/RouteHelper';
import SidebarFlyoutWrapper from './SidebarFlyoutWrapper';

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: rgba(238,238,238,.8);
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showTaskModal: typeof showTaskModal
  showBoardModal: typeof showBoardModal
}

type IProps = {
  active: boolean
  onClose: () => void
} & IStateToProps & IDispatchToProps & RouteComponentProps

interface IState {
  tasks: Task[]
}

const SidebarTask = (props: IProps) => {
  const { t } = useTranslation()
  const { active, history, onClose } = props

  const onNavigateToTasks = () => {
    history.push(ERoute.PATH_TASKS)
    onClose()
  }

  const onTaskItemClick = (task: Task) => {
    props.showTaskModal({
      task: { id: task.id }
    })
    onClose()
  }

  const onAddTaskClick = () => {
    props.showTaskModal({
      task: {
        assignee_ids: [props.currentUser.id],
        assignees: [props.currentUser]
      }
    })
    onClose()
  }

  const onBoardItemClick = (board: Board) => {
    history.push(RouteHelper.process(ERoute.PATH_TASKS_BOARD, { id: board.id }))
    onClose()
  }

  const onAddBoardClick = () => {
    props.showBoardModal({
      board: {},
      onSubmit: (board) => {
        onBoardItemClick(board)
      }
    })
    onClose()
  }

  return (
    <SidebarFlyoutContainer active={active}>
      <SidebarFlyoutWrapper>
        <HeaderContainer>
          <FlyoutHeader
            icon='tasks'
            title={t('SidebarTask::Tasks')}
            action={{ label: t('SidebarTask::View tasks'), onClick: onNavigateToTasks }}
          />
        </HeaderContainer>

        <FlyoutContent>
          <div className='grid'>
            <div className='grid-cell with-6col'>
              <TasksSimplebar
                active={active}
                onItemClick={onTaskItemClick}
                onAddTaskClick={onAddTaskClick}
              />
            </div>
            <div className='grid-cell with-6col'>
              <BoardsSimplebar
                active={active}
                onItemClick={onBoardItemClick}
                onAddBoardClick={onAddBoardClick}
              />
            </div>
          </div>
        </FlyoutContent>
      </SidebarFlyoutWrapper>
    </SidebarFlyoutContainer>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  showTaskModal,
  showBoardModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarTask))
