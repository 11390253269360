import { initializeApp } from "firebase/app";
import { NextFn, Observer, getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { FIREBASE_CONFIG, FIREBASE_MESSAGING_CONFIG } from "./Constants";

// Firebase cloud messaging
const app = initializeApp(FIREBASE_CONFIG)


export default class PushNotification {
  static async requestPermission(): Promise<NotificationPermission | 'unsupported'> {
    if (await isSupported() === false) return 'unsupported'
    return Notification.requestPermission()
  }

  static async getToken(): Promise<string | null> {
    if (await isSupported()) {
      try {
        const messaging = getMessaging(app)
        const currentToken = await getToken(messaging, FIREBASE_MESSAGING_CONFIG)

        if (currentToken) return currentToken
      } catch (ex) {
        console.error(ex)
        return null
      }
    }

    return null
  }

  static async onMessage(callback: NextFn<any> | Observer<any>) {
    if (await isSupported()) {
      const messaging = getMessaging(app)
      return onMessage(messaging, callback)
    } else {
      return () => { }
    }
  }
}