import * as React from 'react'
import Icon from '../components/Icons/Icon'
import moment from '../utilities/Moment'
import { Moment } from 'moment'
import { TimeEntriesController } from '../controllers'
import EntriesSummary from '../components/Track/EntriesSummary'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import { showBulkTimeEntryModal, showConfirmModal, showTimeEntryExportModal, showTimeEntryModal } from '../store/modals/actions'
import TrackNavigator from '../components/Track/TrackNavigator'
import TrackForm from '../components/Track/TrackForm'
import TimeHelper from '../helpers/TimeHelper'
import { IActionListItem } from '../components/ActionList/ActionList'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import WorkspaceChannelHelper from '../helpers/WorkspaceChannelHelper'
import { Style } from '../styles'
import Card from '../components/Card/Card'
import ListLoader from '../components/Loaders/ListLoader'
import TrackGraph from '../components/Track/Graph/TrackGraph'
import PageContent from '../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper'
import styled, { css } from 'styled-components'
import Button from '../components/Button/Button'
import LocalStorage, { LocalStorageKey } from '../LocalStorage'
import Badge from '../components/Badge/Badge'
import ButtonPanel from '../components/Button/ButtonPanel'
import { CalendarEvent, CreateTimeEntryEvent, CurrentUser, DeleteTimeEntryEvent, TimeEntry, UserWorkspaceSettingScope, WorkspaceCableEventType } from '../types'
import ResourceTable from '../components/Resource/ResourceTable'
import ResourceTableRow from '../components/Resource/ResourceTableRow'
import ResourceTableRowData from '../components/Resource/ResourceTableRowData'
import ResourceTableRowActions from '../components/Resource/ResourceTableRowActions'
import TimeFormatter from '../utilities/TimeFormatter'
import TimeEntryBillableIndicator from '../components/TimeEntry/TimeEntryBillableIndicator'
import ReactTooltip from 'react-tooltip'
import MaskedInput from 'react-text-mask'

const TrackNavigatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
  width: 100%;
  justify-content: flex-start;
`

const TrackNavigatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex: 1;
    flex-direction: column;

    > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

const EmptyContainer = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding: ${Style.spacing.x2};
  color: #82828c;
`

const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;
  max-height: 50vh;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-height: 80vh;
  }
`

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    tr {
      th {
        &.total {
          @media screen and (max-width: ${Style.breakpoints.SMALL}) {
            display: none;
          }
        }
      }
    }
  }

  tbody {
    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    tr {
      td {
        &:last-child {
        }
        &.total {
          @media screen and (max-width: ${Style.breakpoints.SMALL}) {
            display: none;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        &.grand-total {
          @media screen and (max-width: ${Style.breakpoints.SMALL}) {
            display: none;
          }
        }
      }
    }
  }
`

const TableHeader = styled.th<{ active?: boolean }>`
  position: sticky;
  top: 0px;
  background: #eee;
  padding: ${Style.spacing.x1};
  text-align: right;
  width: 64px;
  max-width: 64px;
  min-width: 64px;
  z-index: 1;
  box-shadow: inset 0 -1px 0 ${Style.color.border};

  &:first-child {
    padding-left: ${Style.spacing.x2};
    max-width: 280px;
    width: 280px;
    min-width: 280px;
    width: 100%;
    text-align: left;
    
    @media screen and (min-width: ${Style.breakpoints.MEDIUM}) {
      position: sticky;
      top: 0;
      left: 0;
      background: rgba(238, 238, 238, .8);
      z-index: 2;
    }
  }

  &.day {
    white-space: nowrap;
    color: rgba(29, 30, 28, 0.7);
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }

    ${props => props.active && css`
      color: ${Style.color.brandPrimary};
    `}
  }

  &.total {
    position: sticky;
    right: 64px;
    font-weight: bold;
    box-shadow: rgba(black, 0.4) 0 4px $blur;
    background: rgba(238, 238, 238, .8);
  }

  &.action {
    position: sticky;
    right: 0;
    box-shadow: rgba(black, 0.4) 0 4px $blur;
    background: rgba(238, 238, 238, .8);
  }
`

const TableCell = styled.td<{ active?: boolean }>`
  height: 30px;
  border-bottom: 1px solid ${Style.color.border};
  padding: 16px 4px;  
  text-align: right;
  background: white;
  width: 64px;
  max-width: 64px;
  min-width: 64px;

  &:first-child {
    padding: ${Style.spacing.x2};
    padding-right: ${Style.spacing.x0_5};
    text-align: left;
    width: initial;
    max-width: 280px;
    width: 280px;

    @media screen and (min-width: ${Style.breakpoints.MEDIUM}) {
      position: sticky;
      left: 0;
      background: rgba(255, 255, 255, .8);
    }
  }

  &:last-child {
    padding-right: ${Style.spacing.x2};
  }

  ${props => props.active && css`
    background: rgb(234, 244, 251);
  `}

  input {
    text-align: right;
    max-width: 54px;
    width: 54px;
  }

  &.input {
    text-align: center;
  }

  &.total {
    position: sticky;
    right: 64px;
    font-weight: bold;
    background: rgba(255, 255, 255, .8);

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      right: initial;
    }
  }

  &.action {
    position: sticky;
    right: 0;
  }
`

const TableFooter = styled.tfoot`
  ${TableCell} {
    position: sticky;
    bottom: 0px;
    border-bottom: none;
    box-shadow: inset 0 1px 0 ${Style.color.border};

    &:first-child {
      @media screen and (min-width: ${Style.breakpoints.MEDIUM}) {
        position: sticky;
        left: 0;
        background: rgba(255, 255, 255, .8);
        z-index: 2;
      }
    }

    &.total, &.action {
      background: rgba(255, 255, 255, .8);
    }

    &.grand-total {
      font-weight: bold;
      background: rgba(255, 255, 255, .8);
      right: 64px;
    }
  }
`

const TableCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const TableCellInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`
const TableCellContact = styled.div`
  font-weight: bold;
  margin-right: ${Style.spacing.x1};
`
const TableCellProject = styled.div``
const TableCellDescription = styled.div``
const TableCellIcons = styled.div``
const TableCellAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
const TableDeleteButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Style.variables.baseBorderRadius};
  border: 1px solid ${Style.color.border};
  cursor: pointer;

  &:hover {
    border-color: black;
  }
`

const FooterActions = styled.div`
  width: fit-content;
`

const TimesheetButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: -3px;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  activeEntry: TimeEntry | null
}
interface IDispatchToProps {
  showTimeEntryExportModal: typeof showTimeEntryExportModal
  showTimeEntryModal: typeof showTimeEntryModal
  showBulkTimeEntryModal: typeof showBulkTimeEntryModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

export enum TrackViewLayout {
  TABLE = 'table',
  TIMESHEET = 'timesheet'
}

export enum TrackViewMode {
  WEEK = 'week',
  MONTH = 'month'
}

interface IState {
  viewLayout: TrackViewLayout
  viewMode: TrackViewMode
  start: Moment
  end: Moment
  time_entries: TimeEntry[]
  leave: CalendarEvent[]
  didInitialLoad: boolean
  selectedUserId: string
}

export type TrackTableRowData = TimeEntry & {
  startedAtMoment: Moment
  entries: {
    [key: string]: TimeEntry[]
  }
}

class TimeTrackingOverview extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const viewMode: TrackViewMode = LocalStorage.get(LocalStorageKey.TRACK_VIEW_MODE, TrackViewMode.WEEK)

    this.state = {
      viewLayout: LocalStorage.get(LocalStorageKey.TRACK_VIEW_LAYOUT, TrackViewLayout.TABLE),
      viewMode: LocalStorage.get(LocalStorageKey.TRACK_VIEW_MODE, viewMode),
      start: moment().startOf(viewMode),
      end: moment().endOf(viewMode),
      time_entries: [],
      leave: [],
      didInitialLoad: false,
      selectedUserId: props.currentUser.id
    }

    // Navigation
    this.onNavigateToCurrentDay = this.onNavigateToCurrentDay.bind(this)
    this.onDateSelect = this.onDateSelect.bind(this)
    this.onNavigateBack = this.onNavigateBack.bind(this)
    this.onNavigateForward = this.onNavigateForward.bind(this)
    this.onBulkEntriesClick = this.onBulkEntriesClick.bind(this)
    this.onExportEntriesClick = this.onExportEntriesClick.bind(this)
    this.onToggleViewLayout = this.onToggleViewLayout.bind(this)
    this.onViewModeChange = this.onViewModeChange.bind(this)

    // Table
    this.onTableEntryClick = this.onTableEntryClick.bind(this);
    this.onTableEntryDeleteClick = this.onTableEntryDeleteClick.bind(this);
    this.onTableEntryActionClick = this.onTableEntryActionClick.bind(this)

    // Form
    this.onTrackFormSubmit = this.onTrackFormSubmit.bind(this)
    this.onTimeEntryCreateFormSubmit = this.onTimeEntryCreateFormSubmit.bind(this)
    this.onTimeEntryUpdateFormSubmit = this.onTimeEntryUpdateFormSubmit.bind(this)

    this.onTimerPauseEvent = this.onTimerPauseEvent.bind(this)
    this.onTimerStopEvent = this.onTimerStopEvent.bind(this)
    this.onCreateTimeEntryEvent = this.onCreateTimeEntryEvent.bind(this)
    this.onUpdateTimeEntryEvent = this.onUpdateTimeEntryEvent.bind(this)
    this.onDeleteTimeEntryEvent = this.onDeleteTimeEntryEvent.bind(this)

    this.onGraphItemTimerStartClick = this.onGraphItemTimerStartClick.bind(this)
    this.onGraphItemAddTimeClick = this.onGraphItemAddTimeClick.bind(this)

    this.onSelectedUserChange = this.onSelectedUserChange.bind(this)
    this.onAddDayTimeEntryClick = this.onAddDayTimeEntryClick.bind(this)
    this.onAddTimesheetRowClick = this.onAddTimesheetRowClick.bind(this)
    this.onTimesheetUpsertDelete = this.onTimesheetUpsertDelete.bind(this)
    this.onDeleteTimesheetRowClick = this.onDeleteTimesheetRowClick.bind(this)
    this.onTimesheetHeaderClick = this.onTimesheetHeaderClick.bind(this)
    this.onTimesheetHourBlur = this.onTimesheetHourBlur.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  componentDidMount() {
    this.fetchEntries()

    document.addEventListener(WorkspaceCableEventType.PAUSE_TIMER, this.onTimerPauseEvent)
    document.addEventListener(WorkspaceCableEventType.STOP_TIMER, this.onTimerStopEvent)
    document.addEventListener(WorkspaceCableEventType.CREATE_TIME_ENTRY, this.onCreateTimeEntryEvent)
    document.addEventListener(WorkspaceCableEventType.UPDATE_TIME_ENTRY, this.onUpdateTimeEntryEvent)
    document.addEventListener(WorkspaceCableEventType.DELETE_TIME_ENTRY, this.onDeleteTimeEntryEvent)
  }

  componentWillUnmount() {
    document.removeEventListener(WorkspaceCableEventType.PAUSE_TIMER, this.onTimerPauseEvent)
    document.removeEventListener(WorkspaceCableEventType.STOP_TIMER, this.onTimerStopEvent)
    document.removeEventListener(WorkspaceCableEventType.CREATE_TIME_ENTRY, this.onCreateTimeEntryEvent)
    document.removeEventListener(WorkspaceCableEventType.UPDATE_TIME_ENTRY, this.onUpdateTimeEntryEvent)
    document.removeEventListener(WorkspaceCableEventType.DELETE_TIME_ENTRY, this.onDeleteTimeEntryEvent)
  }

  onTimerPauseEvent() {
    this.fetchEntries()
  }

  onTimerStopEvent() {
    this.fetchEntries()
  }

  onCreateTimeEntryEvent(e: CustomEvent) {
    const { currentUser } = this.props
    const { time_entries: entries } = this.state
    const event: CreateTimeEntryEvent = e.detail
    const dataEntry = event.data.entry

    // 1. As for now tracking page is personal to the user.
    // Check the entry for given user id before applying logic
    if (dataEntry.user_id === currentUser.id) {
      // 2. Check if entry not already in state
      const entryIndex = entries.findIndex(entry => entry.id === dataEntry.id)

      // 3. If not in state yet append it
      if (entryIndex === -1) {
        this.setState({
          time_entries: [dataEntry, ...entries]
        }, this.syncTableHourInputs)
      }
    }
  }

  onUpdateTimeEntryEvent(e: CustomEvent) {
    const { currentUser } = this.props
    const { time_entries: entries } = this.state
    const event: CreateTimeEntryEvent = e.detail
    const dataEntry = event.data.entry

    // 1. As for now tracking page is personal to the user.
    // Check the entry for given user id before applying logic
    if (dataEntry.user_id === currentUser.id) {
      // 2. Check if entry in state
      const index = entries.findIndex(entry => entry.id === dataEntry.id)

      // 3. If entry is in state update the entry and the given index
      if (index !== -1) {
        entries[index] = dataEntry

        this.setState({
          time_entries: [
            ...entries
          ]
        }, this.syncTableHourInputs)
      }
    }
  }

  onDeleteTimeEntryEvent(e: CustomEvent) {
    const { currentUser } = this.props
    const event: DeleteTimeEntryEvent = e.detail
    const dataEntry = event.data.entry

    // 1. As for now tracking page is personal to the user.
    // Check the entry for given user id before applying logic
    if (dataEntry.user_id === currentUser.id) {
      const { time_entries: entries } = this.state

      this.setState({
        time_entries: entries.filter(entry => entry.id !== dataEntry.id)
      }, this.syncTableHourInputs)
    }
  }

  onGraphItemTimerStartClick(startDate: Moment) {
    const { showTimeEntryModal } = this.props

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: {
          started_at: startDate.utc().toString(),
          active: true,
        },
        onSubmit: (activeTimeEntry: TimeEntry) => {
          WorkspaceChannelHelper.send({
            type: WorkspaceCableEventType.START_TIMER,
            data: {
              entry: activeTimeEntry,
            }
          })
        }
      })
    })
  }

  onGraphItemAddTimeClick(startDate: Moment) {
    const { showTimeEntryModal } = this.props

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: {
          started_at: startDate.utc().toString(),
          ended_at: startDate.utc().toString(),
        },
        onSubmit: this.onTimeEntryCreateFormSubmit,
      })
    })
  }

  onSelectedUserChange(userId?: string) {
    this.setState({ selectedUserId: userId }, () => {
      this.fetchEntries()
    })
  }

  onAddDayTimeEntryClick(day: Moment) {
    const now = moment()
    const startedAt = moment(day)
      .set('hour', now.hour())
      .set('minute', now.minute())

    this.props.showTimeEntryModal({
      timeEntry: {
        started_at: startedAt.toISOString(),
        ended_at: moment(startedAt).toISOString(),
      },
      onSubmit: this.onTimeEntryCreateFormSubmit,
    })
  }

  getSelectedDateRange(): { start: string, end: string } {
    const {
      viewMode,
      start: startRange,
      end: endRange,
    } = this.state

    const start = moment(startRange).startOf(viewMode).format('YYYY-MM-DD');
    const end = moment(endRange).endOf(viewMode).format('YYYY-MM-DD');

    return {
      start: start,
      end: end,
    }
  }

  getDateRange() {
    const { start, end } = this.state
    return TimeHelper.getDateRange(start, end)
  }

  getTimesheetData() {
    const { time_entries } = this.state

    const entriesToProcess = [...time_entries].filter(entry => !entry.active)

    const data: TrackTableRowData[] = []

    entriesToProcess.forEach((entry, index) => {
      const entryStartMoment = moment(entry.started_at)
      // Delete the entry so it cannot be reused
      delete entriesToProcess[index]

      const rowIndex = data.findIndex((dataItem) => {
        return (
          dataItem.description === entry.description &&
          dataItem.contact_id === entry.contact_id &&
          dataItem.project_id === entry.project_id &&
          dataItem.billable === entry.billable &&
          dataItem.fixed_rate === entry.fixed_rate &&
          dataItem.work_type_id === entry.work_type_id
        )
      })

      if (rowIndex === -1) {
        data.push({
          ...entry,
          startedAtMoment: entryStartMoment,
          entries: {
            [entryStartMoment.format('YYYY-MM-DD')]: [entry]
          }
        })
      } else {
        // Add the entry to the row
        data[rowIndex].entries[entryStartMoment.format('YYYY-MM-DD')] = [
          ...data[rowIndex].entries[entryStartMoment.format('YYYY-MM-DD')] || [],
          entry
        ]
      }
    })

    return data
  }

  getTableHourRefKey(row: number, key: string) {
    return `R${row}-${key}`
  }

  async fetchEntries() {
    const { selectedUserId } = this.state
    const { start, end } = this.getSelectedDateRange()

    try {
      const { time_entries, leave } = await TimeEntriesController.getView({
        range: `${start}..${end}`,
        user_id: selectedUserId
      })

      this.setState({
        time_entries: time_entries,
        leave: leave,
        didInitialLoad: true,
      }, this.syncTableHourInputs);
    } catch (ex) {
      console.error(ex)
    }
  }

  onNavigateToCurrentDay(e) {
    e.preventDefault();

    const { viewMode } = this.state

    this.setState({
      start: moment().startOf(viewMode),
      end: moment().endOf(viewMode),
    }, () => {
      this.fetchEntries();
    });
  }

  onDateSelect(selectedDate: Moment) {
    const { viewMode } = this.state
    this.setState({
      start: moment(selectedDate).startOf(viewMode),
      end: moment(selectedDate).endOf(viewMode),
    }, () => {
      this.fetchEntries()
    })
  }

  onNavigateBack(e) {
    e.preventDefault();

    const { start, viewMode } = this.state;

    const newStart = moment(start)
      .startOf(viewMode)
      .subtract(1, viewMode)

    this.setState({
      start: newStart,
      end: moment(newStart).endOf(viewMode)
    }, () => {
      this.fetchEntries();
    })
  }

  onNavigateForward(e) {
    e.preventDefault();

    const { start, viewMode } = this.state;

    const newStart = moment(start)
      .startOf(viewMode)
      .add(1, viewMode)

    this.setState({
      start: newStart,
      end: moment(newStart).endOf(viewMode)
    }, () => {
      this.fetchEntries();
    })
  }

  onBulkEntriesClick(e) {
    e.preventDefault()

    requestAnimationFrame(() => {
      this.props.showBulkTimeEntryModal({
        onSubmit: () => {
          this.fetchEntries()
        }
      })
    })
  }

  onExportEntriesClick(e) {
    e.preventDefault()

    const { start: startRange, end: endRange, viewMode } = this.state

    const start = moment(startRange).startOf(viewMode)
    const end = moment(endRange).endOf(viewMode)

    this.props.showTimeEntryExportModal({ start: start, end: end })
  }

  onToggleViewLayout() {
    const { viewLayout } = this.state
    let newViewLayout = viewLayout === TrackViewLayout.TABLE ? TrackViewLayout.TIMESHEET : TrackViewLayout.TABLE
    LocalStorage.set(LocalStorageKey.TRACK_VIEW_LAYOUT, newViewLayout)
    this.setState({ viewLayout: newViewLayout })
  }

  onViewModeChange(viewMode: TrackViewMode) {
    const { start, end } = this.state

    // Save the view mode to local storage
    LocalStorage.set(LocalStorageKey.TRACK_VIEW_MODE, viewMode)

    const newStart = moment(start).startOf(viewMode)
    const newEnd = moment(newStart).endOf(viewMode)


    this.setState({
      time_entries: [],
      start: newStart,
      end: newEnd,
      didInitialLoad: false,
      viewMode: viewMode
    }, this.fetchEntries)
  }

  onTableEntryClick(timeEntry: TimeEntry) {
    const { showTimeEntryModal } = this.props

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: { id: timeEntry.id },
        onSubmit: this.onTimeEntryUpdateFormSubmit
      })
    })
  }

  onTableEntryUpdateClick(timeEntry: TimeEntry) {
    const { showTimeEntryModal } = this.props

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: { id: timeEntry.id },
        onSubmit: this.onTimeEntryUpdateFormSubmit
      })
    })
  }

  async onTableEntryDeleteClick(timeEntry: TimeEntry) {
    const { time_entries: entries } = this.state;

    try {
      this.setState({
        time_entries: entries.filter(entry => entry.id !== timeEntry.id)
      }, this.syncTableHourInputs)

      await TimeEntriesController.delete(timeEntry.id)
    } catch (ex) {
      console.error(ex)
    }
  }

  onTableEntryActionClick(key: string, entry: TimeEntry) {
    switch (key) {
      case 'update': this.onTableEntryUpdateClick(entry)
        break
      case 'delete': this.onTableEntryDeleteClick(entry)
        break
      default:
        throw Error('[Track] Unimplemented onTableEntryActionClick')
    }
  }

  onTrackFormSubmit(timeEntry: TimeEntry) {
    const { time_entries: entries } = this.state

    const { start, end } = this.getSelectedDateRange()

    const startRangeDate = moment(start, 'YYYY-MM-DDD').startOf('day')
    const endRangeDate = moment(end, 'YYYY-MM-DD').endOf('day')

    if (!timeEntry.active && moment(timeEntry.started_at).isBetween(startRangeDate, endRangeDate)) {
      const entryIndex = entries.findIndex(entry => entry.id === timeEntry.id)

      if (entryIndex === -1) {
        this.setState({
          time_entries: [
            timeEntry,
            ...entries,
          ]
        }, this.syncTableHourInputs)
      }
    }
  }

  onTimeEntryCreateFormSubmit(timeEntry: TimeEntry) {
    const { time_entries: entries, start, end } = this.state

    const foundEntry = entries.find(entry => entry.id === timeEntry.id)
    const inRange = moment(timeEntry.started_at).isBetween(start, end, 'day', '[]')

    if (!foundEntry && inRange) {
      this.setState({
        time_entries: [
          timeEntry,
          ...entries,
        ]
      }, this.syncTableHourInputs)
    }
  }

  onTimeEntryUpdateFormSubmit(updatedEntry: TimeEntry) {
    const { time_entries: entries } = this.state

    const index = entries.findIndex(entry => entry.id === updatedEntry.id)

    if (index !== -1) {
      entries[index] = updatedEntry

      WorkspaceChannelHelper.send({
        type: WorkspaceCableEventType.UPDATE_TIME_ENTRY,
        data: {
          entry: {
            entry: updatedEntry,
          }
        }
      })

      this.setState({
        time_entries: [
          ...entries
        ]
      }, this.syncTableHourInputs)
    }
  }

  onAddTimesheetRowClick() {
    const { showTimeEntryModal } = this.props
    const { start } = this.state

    const now = moment()
    const startedAt = moment(start)
      .set('hour', now.hour())
      .set('minute', now.minute())

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: {
          started_at: moment(startedAt).toISOString(),
          ended_at: moment(startedAt).toISOString()
        },
        onSubmit: this.onTimeEntryCreateFormSubmit
      })
    })
  }

  onDeleteTimesheetRowClick(rowData: TrackTableRowData) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('TimeTrackingOverview::Delete entries'),
      description: t('TimeTrackingOverview::You are about to delete the selected time entries. These time entries will be permanently deleted along with all their associated data. Are you sure?'),
      action: { label: t('TimeTrackingOverview::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const entryIds = Object
            .values(rowData?.entries || {})
            .map(entries => entries.map(entry => entry.id))
            .flat()

          this.setState({
            time_entries: this.state.time_entries.filter(entry => !entryIds.includes(entry.id))
          }, this.syncTableHourInputs)

          await TimeEntriesController.deleteAll(entryIds)
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  async onTimesheetUpsertDelete(upsertEntries: TimeEntry[], deleteEntries: TimeEntry[]) {
    const { time_entries } = this.state
    try {
      const createEntries = upsertEntries.filter(entry => !entry.id)
      const updateEntries = upsertEntries.filter(entry => entry.id)

      let updatedEntries = [...time_entries]

      const deletionEntryIds = deleteEntries.map(entry => entry.id)
      if (deletionEntryIds.length > 0) {
        await TimeEntriesController.deleteAll(deletionEntryIds)
        updatedEntries = updatedEntries.filter(entry => !deletionEntryIds.includes(entry.id))
      }

      for (const entry of createEntries) {
        const createEntry = await TimeEntriesController.create(entry)
        updatedEntries.push(createEntry)
      }

      for (const entry of updateEntries) {
        const updatedEntry = await TimeEntriesController.update(entry)
        const entryIndex = updatedEntries.findIndex(entry => entry.id === updatedEntry.id)
        updatedEntries[entryIndex] = updatedEntry
      }

      this.setState({ time_entries: updatedEntries }, this.syncTableHourInputs)
    } catch (ex) {
      console.error(ex)
    }
  }

  syncTableHourInputs() {
    if (this.state.viewLayout !== TrackViewLayout.TIMESHEET) return

    const data = this.getTimesheetData()

    const dateRange = this.getDateRange()

    data.forEach((rowData, rowIndex) => {
      dateRange.forEach((date, columnIndex) => {
        const dateKey = date.format('YYYY-MM-DD')
        const hourInput = this[this.getTableHourRefKey(rowIndex, dateKey)]
        const entries = rowData?.entries[dateKey] || []

        const entriesDurationInSeconds = entries.reduce((total, entry) => total + Number(entry.duration), 0)

        let inputElementValue = ''

        if (entriesDurationInSeconds > 0 && hourInput) {
          inputElementValue = moment.duration(entriesDurationInSeconds, 'seconds').format('HH:mm', { trim: false })
        }

        if (hourInput) {
          hourInput.inputElement.value = inputElementValue
        }
      })
    })
  }

  onTimesheetHeaderClick(dateMoment: Moment) {
    const { showTimeEntryModal } = this.props

    const now = moment()
    const startedAt = moment(dateMoment)
      .set('hour', now.hour())
      .set('minute', now.minute())

    requestAnimationFrame(() => {
      showTimeEntryModal({
        timeEntry: {
          started_at: moment(startedAt).toISOString(),
          ended_at: moment(startedAt).toISOString()
        },
        onSubmit: this.onTimeEntryCreateFormSubmit
      })
    })
  }

  onTimesheetHourBlur(rowIndex: number, dateMoment: Moment, event) {
    const data = this.getTimesheetData()
    const dateKey = dateMoment.format('YYYY-MM-DD')
    const value = event.target.value
    const rootEntry = data[rowIndex]
    const entries = rootEntry.entries[dateKey] || []

    if (value.length === 0 && entries.length > 0) {
      // Delete entries if the value is empty
      const entriesToBeDeleted = entries

      this.onTimesheetUpsertDelete([], entriesToBeDeleted)
    } else if (value.length >= 1) {
      let [hour, minutes] = value.split(":")

      if (!minutes || minutes.length === '') minutes = 0
      if (minutes.length === 1) minutes = Number(minutes) * 10

      const startDate = moment(dateMoment).set({ 'hour': 8, 'minute': 30 }).startOf('minute')
      const endDate = moment(startDate).add({ hour: hour, minute: minutes || 0 }).startOf('minute')

      if (entries.length > 0) {
        // Merge entries into a single entry (keep the first and delete the rest)
        const [firstEntry, ...entriesToDelete] = entries

        const updateEntry: TimeEntry = {
          ...firstEntry,
          started_at: startDate.toISOString(),
          ended_at: endDate.toISOString()
        }

        this.onTimesheetUpsertDelete([updateEntry], entriesToDelete)
      } else {
        // Create a new entry (without entries in object)
        const clonedRootEntry = { ...rootEntry }
        // Remove entries from root entry (we use this to create new entries)
        delete clonedRootEntry.entries

        const newEntry: TimeEntry = {
          ...clonedRootEntry,
          id: null,
          started_at: startDate.toISOString(),
          ended_at: endDate.toISOString(),
        }

        this.onTimesheetUpsertDelete([newEntry], [])
      }
    }
  }

  renderListEntries() {
    const {
      start,
      end,
      time_entries: entries,
    } = this.state;

    const today = moment()
    const { currentUser, t } = this.props
    const { workspace: { setting } } = currentUser

    const dateRange = TimeHelper.getDateRange(start, end)
    const hasWorkType = entries.some(entry => Boolean(entry.work_type_id))

    const dateWithEntries: { date: Moment, entries: TimeEntry[] }[] = dateRange.map(dateString => {
      const momentDate = moment(dateString, 'YYYY-MM-DD')
      const dateEntries = entries.filter(timeEntry => momentDate.isSame(moment(timeEntry.started_at), 'day') && !timeEntry.active)

      return {
        date: momentDate,
        entries: dateEntries
      }
    })

    if (dateWithEntries.length === 0) {
      return (
        <Card>
          <div className='card-content'>
            <CardEmptyInfo
              icon='stopwatch'
              description={t('Track::You haven\'t created any time entries yet.')}
            />
          </div>
        </Card>
      )
    }

    return dateWithEntries.map((item, index) => {
      return (
        <ResourceTable
          key={item.date.format('YYYY-MM-DD') + '-' + index}
          headers={[
            { title: '', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE), align: 'right' },
            { title: t('TimeTrackingOverview::Description') },
            { title: t('TimeTrackingOverview::Type'), visible: hasWorkType },
            { title: t('TimeTrackingOverview::Contact') },
            { title: t('TimeTrackingOverview::Project') },
            { title: t('TimeTrackingOverview::Start'), align: 'right' },
            { title: t('TimeTrackingOverview::End'), align: 'right' },
            { title: t('TimeTrackingOverview::Duration'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          style={{ marginBottom: Style.spacing.x2 }}
          actionsRight={[
            <ButtonPanel icon='plus' onClick={() => this.onAddDayTimeEntryClick(item.date)}
            />
          ]}
          resourceLabel={() => <>
            {`${item.date.format('dddd, D MMM YYYY')}`} {item.date.isSame(today, 'day') && <Badge text={t('Track::Today')} type='grey' />}
          </>}
          resourceLabelStyle={{ fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize' }}
          data={item.entries}
          renderRow={(timeEntry: TimeEntry) => {
            let actions: IActionListItem[] = [
              { key: 'update', icon: 'edit-solid', content: t('Track::Edit') },
              { key: 'delete', icon: 'trash-alt-solid', content: t('Track::Delete'), destructive: true }
            ]

            const durationInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')

            return (
              <ResourceTableRow key={timeEntry.id}>
                {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <ResourceTableRowData
                  onClick={() => this.onTableEntryClick(timeEntry)}
                  textAlign='right'
                  maxWidth={20}
                  width={20}
                >
                  <TimeEntryBillableIndicator timeEntry={timeEntry} />
                </ResourceTableRowData>}
                <ResourceTableRowData onClick={() => this.onTableEntryClick(timeEntry)} maxWidth='150px' ellipse title={timeEntry?.description?.length > 0 ? timeEntry.description : '-'}>
                  {timeEntry?.description?.length > 0 ? timeEntry.description : '-'}
                </ResourceTableRowData>
                {hasWorkType && <ResourceTableRowData onClick={() => this.onTableEntryClick(timeEntry)} maxWidth='150px' ellipse title={timeEntry?.work_type ? timeEntry?.work_type?.name : '-'}>
                  {timeEntry?.work_type ? timeEntry?.work_type?.name : '-'}
                </ResourceTableRowData>}
                <ResourceTableRowData onClick={() => this.onTableEntryClick(timeEntry)} maxWidth='150px' ellipse title={timeEntry?.contact?.name?.length > 0 ? timeEntry.contact.name : '-'}>
                  {timeEntry?.contact?.name?.length > 0 ? timeEntry.contact.name : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableEntryClick(timeEntry)} maxWidth='150px' ellipse title={timeEntry?.project?.name?.length > 0 ? timeEntry.project.name : '-'}>
                  {timeEntry?.project?.name?.length > 0 ? timeEntry.project.name : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData textAlign='right' onClick={() => this.onTableEntryClick(timeEntry)}>
                  {moment(timeEntry.started_at).format('H:mm')}
                </ResourceTableRowData>
                <ResourceTableRowData textAlign='right' onClick={() => this.onTableEntryClick(timeEntry)}>
                  {moment(timeEntry.ended_at).format('H:mm')}
                </ResourceTableRowData>
                <ResourceTableRowData
                  textAlign='right'
                  onClick={() => this.onTableEntryClick(timeEntry)}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: TimeFormatter.durationFormatHighlighted(durationInSeconds, setting.time_format)
                    }}
                  />
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableEntryActionClick(key, timeEntry)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<EmptyContainer>
            {t('Track::You have not yet added any time entries to this day.')}
          </EmptyContainer>}
        />
      )
    })
  }

  renderTableView() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { time_entries } = this.state
    const dateRange = this.getDateRange()
    const data = this.getTimesheetData()

    return (
      <>
        <EntriesSummary
          entries={time_entries}
          numberFormat={setting.number_format}
          timeFormat={setting.time_format}
          currency={setting.default_currency}
        />

        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader className='metadata'>
                  {t('TrackTable::Description')}
                </TableHeader>
                {dateRange.map((date, index) => (
                  <TableHeader
                    key={index}
                    active={moment().isSame(date, 'day')}
                    className='day'
                    onClick={() => this.onTimesheetHeaderClick(date)}
                  >
                    {date.format('ddd')}
                    <div>{date.format('D  MMM')}</div>
                  </TableHeader>
                ))}
                <TableHeader className='total' />
                <TableHeader className='action' />
              </tr>
            </thead>
            <tbody>
              {time_entries?.length === 0 && (
                <tr>
                  <TableCell>
                    {t('TrackTable::No entries present')}
                  </TableCell>
                  {dateRange.map((date, index) => <TableCell key={date.format('YYYY-MM-DD')} />)}
                  <TableCell />
                  <TableCell />
                </tr>
              )}
              {time_entries?.length > 0 && data.map((rowData, rowIndex) => {
                const totalEntriesDurationInSeconds = Object
                  .values(rowData?.entries || {})
                  .reduce((total, timeEntries) => (total + timeEntries.reduce((total, entry) => total + entry.duration, 0)), 0)

                return (
                  <tr key={rowIndex}>
                    <TableCell>
                      <TableCellWrapper>
                        <TableCellInfo>
                          {rowData.contact?.name && <TableCellContact>{rowData.contact.name}</TableCellContact>}
                          {rowData.project?.name && <TableCellProject>({rowData?.project?.name})</TableCellProject>}
                        </TableCellInfo>
                        <TableCellDescription>{rowData.description || '-'}</TableCellDescription>
                        <TableCellIcons></TableCellIcons>
                      </TableCellWrapper>
                    </TableCell>

                    {dateRange.map((date, columnIndex) => {
                      const dateMoment = moment(date)
                      const dateKey = dateMoment.format('YYYY-MM-DD')
                      const dateEntries = rowData.entries[dateKey] || []
                      const dateDurationInSeconds = dateEntries.reduce((total, entry) => (total + entry.duration), 0)

                      return (
                        <TableCell key={`${dateKey}-${columnIndex}`} active={moment().isSame(date, 'day')} className='input'>
                          <MaskedInput
                            ref={hourInput => this[this.getTableHourRefKey(rowIndex, dateKey)] = hourInput}
                            type='text'
                            defaultValue={dateDurationInSeconds > 0 ? moment.duration(dateDurationInSeconds, 'seconds').format('HH:mm', { trim: false }) : ''}
                            guide={false}
                            showMask={true}
                            mask={[/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                            onBlur={() => this.onTimesheetHourBlur(rowIndex, dateMoment, event)}
                          />
                        </TableCell>
                      )
                    })}
                    <TableCell className='total'>
                      {moment.duration(totalEntriesDurationInSeconds, 'seconds').format('HH:mm', { trim: false })}
                    </TableCell>
                    <TableCell className='action'>
                      <TableCellAction>
                        <TableDeleteButton onClick={() => this.onDeleteTimesheetRowClick(rowData)}>
                          <Icon icon='close' />
                        </TableDeleteButton>
                      </TableCellAction>
                    </TableCell>
                  </tr>
                )
              })}
            </tbody>
            <TableFooter>
              <tr>
                <TableCell>
                  <FooterActions>
                    <ButtonPanel
                      icon='plus'
                      text={t('TrackTable::Add row')}
                      onClick={this.onAddTimesheetRowClick}
                    />
                  </FooterActions>
                </TableCell>
                {dateRange.map((date, columnIndex) => {
                  const rowData = Object.values(data || {})
                  const columnDurationInSeconds = rowData
                    .map(rowData => rowData.entries[date.format('YYYY-MM-DD')] || [])
                    .reduce((total, entries) => total + entries.reduce((total, entry) => total + entry.duration, 0), 0)

                  return (
                    <TableCell key={columnIndex} active={moment().isSame(date, 'day')} className='total'>
                      {moment.duration(columnDurationInSeconds, 'seconds').format('HH:mm', { trim: false })}
                    </TableCell>
                  )
                })}
                <TableCell className='grand-total'>
                  {moment.duration(dateRange.reduce((total, date) => {
                    const rowData = Object.values(data || {})
                    const columnDurationInSeconds = rowData
                      .map(rowData => rowData.entries[date.format('YYYY-MM-DD')] || [])
                      .reduce((total, entries) => total + entries.reduce((total, entry) => total + entry.duration, 0), 0)
                    return total + columnDurationInSeconds
                  }, 0), 'seconds').format('HH:mm', { trim: false })}
                </TableCell>
                <TableCell className='action' />
              </tr>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    )
  }

  render() {
    const {
      currentUser,
      activeEntry,
      t
    } = this.props

    const { workspace } = currentUser
    const { setting } = workspace
    const {
      viewLayout,
      viewMode,
      start,
      end,
      time_entries,
      leave,
      didInitialLoad,
      selectedUserId
    } = this.state;

    return (
      <>
        <Helmet>
          <title>{t('Track::{{__appName}} | Track')}</title>
        </Helmet>
        <ScrollToTopOnMount />

        <PageContent className='is-track'>
          <div className='grid'>
            <div className='grid-cell with-12col'>
              <TrackForm
                onSubmit={this.onTrackFormSubmit}
              />
            </div>
          </div>

          <TrackNavigatorContainer>
            <TrackNavigatorWrapper>
              <TrackNavigator
                viewLayout={viewLayout}
                viewMode={viewMode}
                viewLayouts={[
                  {
                    element: <TimesheetButtonContainer data-tip={t('TimeTrackingOverview::Timesheet mode')}><Icon icon='calendar-clock' /></TimesheetButtonContainer>,
                    onClick: this.onToggleViewLayout, active: viewLayout === TrackViewLayout.TIMESHEET
                  }
                ]}
                viewItems={[
                  { element: t('Track::Week'), onClick: () => this.onViewModeChange(TrackViewMode.WEEK), active: viewMode === TrackViewMode.WEEK },
                  { element: t('Track::Month'), onClick: () => this.onViewModeChange(TrackViewMode.MONTH), active: viewMode === TrackViewMode.MONTH }
                ]}
                start={start}
                end={end}
                selectedUserId={selectedUserId}
                usersCount={workspace.users_count}
                onDateSelect={this.onDateSelect}
                onNavigateBack={this.onNavigateBack}
                onNavigateForward={this.onNavigateForward}
                onNavigateToCurrentDay={this.onNavigateToCurrentDay}
                onViewModeChange={this.onViewModeChange}
                onSelectedUserChange={this.onSelectedUserChange}
              />
            </TrackNavigatorWrapper>
          </TrackNavigatorContainer>

          <div className='page-navigation'>
            <div className='page-actions'>
              <Button
                type='default'
                icon='stopwatch'
                text={t('Track::Add bulk entries')}
                onClick={this.onBulkEntriesClick}
              />

              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING_EXPORT) && <a href='javascript://' className='button button-default' onClick={this.onExportEntriesClick}>
                <Icon icon='download' />
                {t('Track::Export')}
              </a>}
            </div>
          </div>

          {viewLayout === TrackViewLayout.TIMESHEET && this.renderTableView()}

          {viewLayout === TrackViewLayout.TABLE && <>
            <TrackGraph
              entries={time_entries}
              leave={leave}
              start={start}
              end={end}
              onTimerStartClick={this.onGraphItemTimerStartClick}
              onAddTimeClick={this.onGraphItemAddTimeClick}
              activeTimeEntry={activeEntry}
              setting={setting}
            />

            <EntriesSummary
              entries={time_entries}
              numberFormat={setting.number_format}
              timeFormat={setting.time_format}
              currency={setting.default_currency}
            />

            <div className='grid'>
              <div className='grid-cell with-12col'>
                {!didInitialLoad && <ListLoader />}
                {didInitialLoad && this.renderListEntries()}
              </div>
            </div>
          </>}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    timer: {
      entry
    },
  } = state

  return {
    currentUser: currentUser,
    activeEntry: entry,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showTimeEntryExportModal: (options) => dispatch(showTimeEntryExportModal(options)),
    showTimeEntryModal: (options) => dispatch(showTimeEntryModal(options)),
    showBulkTimeEntryModal: (options) => dispatch(showBulkTimeEntryModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimeTrackingOverview))