import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const StepContent = styled.div<{ backgroundOnHover?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  background: white;
  border-radius: ${Style.variables.baseBorderRadius};

  ${props => props.backgroundOnHover && css`
    &:hover {
      background: #f7f7f7;
    }
  `}
`

export default StepContent