import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { NumberInputBlock, SliderInputBlock } from '../../../../types'
import { EditBlockProps } from '../../Block'

type IProps = EditBlockProps<NumberInputBlock | SliderInputBlock>

const InputLimitSection = (props: IProps) => {
	const { t } = useTranslation()
	const { block, onChange } = props
	const [minimumLimit, setMinimumLimit] = React.useState(block.minimum_limit || 0)
	const [maximumLimit, setMaximumLimit] = React.useState(block.minimum_limit || 100)

	React.useEffect(() => {
		setMinimumLimit(block.minimum_limit)
		setMaximumLimit(block.maximum_limit)
	}, [block.minimum_limit, block.maximum_limit])

	const debouncedMinimumLimitChange = useDebouncedCallback(
		(newMinimumLimit) => onChange({ ...block, minimum_limit: newMinimumLimit }), 500
	);

	const debouncedMaximumLimitChange = useDebouncedCallback(
		(newMaximumLimit) => onChange({ ...block, maximum_limit: newMaximumLimit }), 500);

	const onMinimumLimitChange = (e) => {
		const newMinimumLimit = e.currentTarget.value

		setMinimumLimit(newMinimumLimit)

		debouncedMinimumLimitChange(newMinimumLimit)
	}

	const onMaximumLimitChange = (e) => {
		const newMaximumLimit = e.currentTarget.value

		setMaximumLimit(newMaximumLimit)

		debouncedMaximumLimitChange(newMaximumLimit)
	}

	return (
		<div className='grid'>
			<div className='grid-cell with-6col'>
				<div className='form-item'>
					<label>
						{t('InputBlock::Minimum limit')}
					</label>
					<input
						type='number'
						value={minimumLimit}
						onChange={onMinimumLimitChange}
						placeholder={t('InputBlock::Description')}
					/>
				</div>
			</div>
			<div className='grid-cell with-6col'>
				<div className='form-item'>
					<label>
						{t('InputBlock::Maximum limit')}
					</label>
					<input
						type='number'
						value={maximumLimit}
						onChange={onMaximumLimitChange}
						placeholder={t('InputBlock::Description')}
					/>
				</div>
			</div>
		</div>
	)

}

export default InputLimitSection