import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import PageHeader from '../components/Page/PageHeader'
import { RouterProps } from 'react-router-dom'
import styled from 'styled-components'
import PageContent from '../components/Page/PageContent'
import { Style } from '../styles'
import Images from '../images'
import { useSelector } from 'react-redux'
import { AppState } from '../store'
import { Workspace } from '../types'
import copy from 'copy-to-clipboard'
import Notification from '../utilities/Notification'

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

const Content = styled.div`
  p {
    font-size: 16px;
    margin-bottom: ${Style.spacing.x2};
  }
`

const SideContent = styled.div`
  background: white;
  border-radius: 10px;
  padding: ${Style.spacing.x2} ${Style.spacing.x2_5};
  margin-left: ${Style.spacing.x8};
  flex: initial;
  box-shadow: 0px 2px 6px rgba(0, 12, 20, 0.06);
  border: 1px solid ${Style.color.border};
  height: fit-content;

  a {
    margin-top: ${Style.spacing.x2};
    color: ${Style.color.link};
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    margin-left: 0;
    margin-top: ${Style.spacing.x3};
  }
`

const SideContentTitle = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
`

const SideContentParagraph = styled.div`
  font-size: 16px;
  margin-bottom: ${Style.spacing.x2};

  a {
    display: block;
    color: ${Style.color.link};
    text-decoration: underline;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${Style.spacing.x2};

  img {
    max-height: 120px;
    border-radius: 10px;
    margin-right: 16px;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      margin-bottom: ${Style.spacing.x4};
      margin-right: 0;
    }
  }

  p {
    font-weight: 800;
    font-size: 25px;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      font-size: 18px;
      margin-bottom: ${Style.spacing.x1};
    }
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const ReferralContainer = styled.div`
  background: white;
  border-radius: 50px;
  padding: 16px 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 12, 20, 0.06);
  border: 1px solid ${Style.color.border};
`

const ReferralTitle = styled.div`
  text-align: center;
  font-size: 18px;
`

const ReferralLink = styled.div`
  text-decoration: underline;
  color: ${Style.color.link};
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: ${Style.spacing.x3};
  word-wrap: break-word;
  white-space: pre-wrap;
  cursor: pointer;
`

const ReferralReward = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgb(213, 235, 214);
  border-radius: 10px;
  font-size: 17px;
  padding: ${Style.spacing.x1};
  position: relative;
  height: 153px;
  overflow: hidden;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    height: initial;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -16px;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const ReferralRewardIcon = styled.img`
  width: 100px;
  height: 100px;
  margin-right: ${Style.spacing.x2};
  width: 152px;
  height: 152px;
  margin-right: 16px;
  top: 0;
  position: absolute;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    position: relative;
    margin-bottom: ${Style.spacing.x2};
    margin-right: 0;
    margin-top: -${Style.spacing.x1};
  }
`
const ReferralRewardDescription = styled.div`
  padding-left: 152px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    padding-left: 0;
    text-align: center;
    padding-bottom: ${Style.spacing.x3};
  }
`

type IProps = RouterProps

const Referral = (props: IProps) => {
  const { t } = useTranslation()
  const currentUser = useSelector((state: AppState) => state.authentication.currentUser)

  const onBackPress = () => {
    props.history.goBack()
  }

  const onCopyLinkClick = () => {
    copy(currentUser.workspace.app_invite_url)
    Notification.notifySuccess(t('Referral::Link copied to clipboard'))
  }

  return (
    <>
      <Helmet>
        <title>{t('Contact::{{__appName}} | Contacts')}</title>
      </Helmet>
      <ScrollToTopOnMount />

      <TopNavigation
        icon='check-circle'
        title={t('Referral::Referral program')}
      />


      <PageContent>
        <PageHeader
          title={t('Referral::Refer others to Bizzey')}
          breadcrumbs={[
            { content: t('Contact::Back'), url: 'javascript://', onClick: onBackPress }
          ]}
        />

        <Container>
          <Content>
            <Header>
              <img src={Images.FOUNDER} />

              <p>
                {t('Referral::Hi! I’m Jens, founder of Bizzey. I can’t thank you enough for using Bizzey, but, there’s one other favor I have to ask...')}
              </p>
            </Header>

            <p>
              {t('Referral::We\'re a small business, and referrals are the lifeblood of our company. Since our product is so much cheaper than the big CRMs, there\'s no way for us to compete with their marketing budgets. That means our growth comes from people like you sharing Bizzey with their friends and colleagues.')}
            </p>

            <p>
              {t('Referral::If you\'ve been happy with your experience using Bizzey, it would mean so much to us if you could help spread the word. Just copy your personal referral link below and send it to anyone who you think could benefit from using a simple CRM. We\'ll give them top - notch service and make sure you look good for turning them on to Bizzey.')}
            </p>

            <p>
              {t('Referral::Thanks for supporting small businesses!')}
            </p>

            <p>
              <b>{t('Referral::-Jens Debergh, founder of Bizzey')}</b>
            </p>

            <ReferralContainer>
              <ReferralTitle>
                {t('Referral::Your personal referral link')}
              </ReferralTitle>

              <ReferralLink onClick={onCopyLinkClick}>
                {currentUser.workspace.app_invite_url}
              </ReferralLink>

              <ReferralReward>
                <ReferralRewardIcon src={Images.REFER_A_FRIEND_MONEY} />
                <ReferralRewardDescription dangerouslySetInnerHTML={{ __html: t('Referral::As our way of saying thanks, everyone you refer will get <b>an extra free month added to their trial</b>, and you\'ll get a <b>20€ credit</b> for each person that subscribes.') }}>

                </ReferralRewardDescription>
              </ReferralReward>
            </ReferralContainer>
          </Content>

          <SideContent>
            <SideContentTitle>
              {t('Referral::Do you have a big referral opportunity?')}
            </SideContentTitle>

            <SideContentParagraph>
              {t('Referral::We love working with larger referral networks such as franchise groups, business coaches, etc.')}
              <br /><br />
              {t('Referral::If you\'d like to discuss how we can work together to get Bizzey in front of a larger group of people, you can contact our business directly.')}

              <a href="mailto:affiliates@bizzey.com">affiliates@bizzey.com</a>
            </SideContentParagraph>
          </SideContent>
        </Container>
      </PageContent>
    </>
  )
}

export default Referral