import * as React from 'react'
import styled, { css } from 'styled-components'

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

export default class TableRow extends React.PureComponent {
	render() {
		return (
			<Row>
				{this.props.children}
			</Row>
		)
	}
}