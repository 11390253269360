import * as React from "react";
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Style } from "../../styles";
import { Contact, ContactType } from "../../types";
import Icon from "../Icons/Icon";
import ContactHelper from "../../helpers/ContactHelper";
import RouteHelper from "../../helpers/RouteHelper";
import ERoute from "../../ERoute";
import ContactTypeAvatar from "../Avatar/ContactTypeAvatar";
import Avatar from "../Avatar/Avatar";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};
  background: white;
  flex: 1;
  overflow: hidden;
`;

const ContactEdit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid ${Style.color.border};
  border-bottom: 1px solid ${Style.color.border};
  border-bottom-left-radius: ${Style.variables.baseBorderRadius};
  border-top-right-radius: ${Style.variables.baseBorderRadius};
  background: white;
  padding: ${Style.spacing.x0_5};
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    fill: #82828c;
  }

  i {
    color: #82828c;
  }

  &:hover {
    background: #f6f6f7;
  }
  
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Style.spacing.x1};
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${Style.color.border};
  flex: 1;

  &:last-child {
    border-bottom: none;
  }
`;

const AvatarContainer = styled.div`
  margin-bottom: ${Style.spacing.x1};
  cursor: pointer;
`

const ContactName = styled.div`
  display: flex;
  color: black;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ContactDescription = styled.div`
  text-align: center;
  margin-top: -4px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Action = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  flex: 1;

  &:not(:last-child) {
    border-right: 1px solid ${Style.color.border};
  }

  &:hover {
    background: #f6f6f7;
  }

  i,
  svg {
    margin-right: 8px;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #82828c;
  }

  i {
    color: #82828c;
  }
`;

type IProps = {
  contact: Contact;
  onEditClick: () => void
} & RouteComponentProps

const ContactCard = (props: IProps) => {
  const { t } = useTranslation();
  const { contact, onEditClick } = props;

  const fullName = [contact.name].join(" ");
  const email = ContactHelper.getPrimaryEmail(contact);
  const telephoneNumber = ContactHelper.getPrimaryTelephonenumber(contact);

  const onNavigateContact = () => {
    window.scrollTo(0, 0)
    props.history.push(RouteHelper.process(ERoute.PATH_CONTACT, { id: contact.id }))
  }

  return (
    <Container>
      <ContactEdit onClick={onEditClick}>
        <Icon icon='pen' />
      </ContactEdit>
      <ContactInfo>
        <AvatarContainer onClick={onNavigateContact}>
          <Avatar name={contact.name} width={65} rounded />
        </AvatarContainer>
        <ContactName onClick={onNavigateContact}>{fullName}</ContactName>
        {contact.type === ContactType.PERSON && <ContactDescription>{contact.job_title}</ContactDescription>}
      </ContactInfo>

      {(email || telephoneNumber) && <Actions>
        {email && <Action href={`mailto:${email.value}`}>
          <Icon icon="envelope" />
          {t("Contact::Email")}
        </Action>}

        {telephoneNumber && (
          <Action href={`tel:${telephoneNumber.value}`}>
            <Icon icon="phone" />
            {t("Contact::Call")}
          </Action>
        )}
      </Actions>}
    </Container>
  );
};

export default withRouter(ContactCard);
