import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { DealStatus, Deal as DealType } from '../../types'
import moment from '../../utilities/Moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import Avatar from '../Avatar/Avatar'
import Badge, { BadgeType } from '../Badge/Badge'

const Container = styled.div<{ rotting?: boolean, isDragging?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 4px;
	padding: 12px;
	margin-bottom: 12px;
	background: #fff;
	border: 1px solid #e4e4e6;
	box-shadow: 0 1px 0 rgb(9 30 66 / 25%);
	cursor: move;
	min-height: 100px;
	transition: 100ms ease-in-out all;
	z-index: 1;

	&:hover {
		background-color: #f6f6fa;
	}

	${props => props.rotting && css`
		background: #fef2f0;

		&:hover {
			background-color: #fef2f0;
		}
	`}

	${props => props.isDragging && css`
		opacity: .5;
		pointer-events: none;
	`}
`

const DealDetails = styled.div`
	display: flex;
	flex-direction: row;
`

const DealContactNameDetails = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const DealContact = styled.div`
`

const DealName = styled.div`
	color: black;
	font-size: 16px;
	font-weight: 700;
	flex: 1;
`

const DealAssignee = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-left: 4px;
	width: 42px;
	height: 42px;
`

const DealMetadata = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 12px;
`

const DealDeadline = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
`

const DealResult = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

const DealAmount = styled.div`
	font-size: 16px;
	font-weight: 700;
	color: black;
`

interface IProps {
	index: number
	deal: DealType
	numberFormat: string
	dateFormat: string
	onClick: (deal: DealType) => void
}

const Deal = (props: IProps) => {
	const { t } = useTranslation()
	const {
		index,
		deal,
		numberFormat,
		dateFormat,
		onClick,
	} = props

	let momentDueOn = null
	let dueOnLabel = null
	let dueOnBadgeType: BadgeType = 'grey'
	let rotting = false
	if (deal.due_on) {
		momentDueOn = moment(deal.due_on)

		if (moment().isSame(momentDueOn, 'day')) {
			dueOnLabel = t('Deal::Today')
			dueOnBadgeType = 'warning'
		} else if (moment().add(1, 'day').isSame(momentDueOn, 'day')) {
			dueOnLabel = t('Deal::Tomorrow')
			dueOnBadgeType = 'warning'
		} else {
			dueOnLabel = momentDueOn.format(dateFormat)
			if (moment().isAfter(momentDueOn, 'day')) dueOnBadgeType = 'danger'
		}
	}

	let assigneeName = ''

	if (deal.assignee) {
		if (deal?.assignee?.name) {
			assigneeName = [deal.assignee.name].filter(Boolean).join(' ')
		} else {
			assigneeName = deal.assignee.email
		}
	}

	if (deal?.deal_stage && deal?.deal_stage?.inactivity_alert > 0) {
		rotting = deal.status === DealStatus.OPEN && moment().diff(moment(deal.updated_at), 'day') >= deal.deal_stage.inactivity_alert
	}

	function getStyle(style, snapshot) {
		if (!snapshot.isDropAnimating) {
			return style;
		}
		return {
			...style,
			// cannot be 0, but make it super tiny
			transitionDuration: `0.000000000001s`,
		};
	}

	return (
		<Draggable
			key={deal.id}
			index={index}
			draggableId={deal.id}
		>
			{(draggableProvided, draggableSnapshot) => (
				<Container
					ref={draggableProvided.innerRef}
					{...draggableProvided.draggableProps}
					{...draggableProvided.dragHandleProps}
					style={getStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
					rotting={rotting}
					isDragging={draggableSnapshot.isDragging}
					onClick={() => onClick(deal)}
				>
					<DealDetails>
						<DealContactNameDetails>
							<DealName>
								{deal.name}
							</DealName>

							<DealContact>
								{deal?.contact?.name}
							</DealContact>
						</DealContactNameDetails>

						<DealAssignee>
							{deal?.assignee && <Avatar
								rounded={true}
								name={assigneeName}
								width={32}
							/>}
						</DealAssignee>
					</DealDetails>

					<DealMetadata>
						<DealDeadline>
							{momentDueOn && <Badge
								type={dueOnBadgeType}
								text={dueOnLabel}
							/>}
						</DealDeadline>

						<DealResult>
							{Number(deal?.amount) > 0 && <DealAmount>{NumberFormatter.formatCurrency(deal.currency, numberFormat, deal.amount)}</DealAmount>}
							{false && deal.success_rate && <Badge
								type='grey'

								text={`${Number(deal?.success_rate) * 100}%`}
							/>}
						</DealResult>
					</DealMetadata>
				</Container>)}
		</Draggable>
	)
}

export default Deal