import * as React from 'react'
import BaseTooltip from './BaseTooltip';

interface IProps {
	tooltip: any
}

export default class GenericTooltip extends React.PureComponent<IProps> {
	render () {
		const { tooltip } = this.props
		const bodyLines = tooltip.body.map(item => item.lines);

		return (
			<BaseTooltip className='is-subtle'>
				{bodyLines.map((item, i) => {
					return (
						<div
							key={i}
							dangerouslySetInnerHTML={{ __html: item }}
						/> 
					)
				})}
			</BaseTooltip>
		)
	}
}