import styled, { css } from 'styled-components'

const BlockCanvas = styled.div`
	position: relative;
	display: block;
	justify-content: center;
	flex: 1;
	background: #f7f7fa;
	width: 100%;
	overflow-y: auto;
`

export default BlockCanvas