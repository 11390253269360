import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Style } from '../../styles';
import Alert from '../../components/Alert/Alert';
import Icon from '../../components/Icons/Icon';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import CustomFieldModelContainer from './CustomFields/CustomFieldModel';
import { CurrentUser, CustomFieldModel, DisplayableError } from '../../types';
import Title from '../../components/Settings/Title';

const CustomFieldsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	
	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const CustomField = styled(NavLink)`
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	padding: 14px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	transition: all .1s ease;
	cursor: pointer;
	position: relative;
	margin-top: 4px;
	margin-bottom: 4px;
	flex: 50%;

	i {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 14px;
		color: #4389fc;
	}

	&:hover {
		color: ${Style.color.brandPrimary};
    background: rgba(67, 137, 252, 0.1);
	}

	&:nth-child(odd) {
		margin-right: 8px;
		flex: calc(50% - 8px);
	}
`

const CustomFieldEmpty = styled.div`
	flex: 50%;

	&:nth-child(odd) {
		margin-right: 8px;
	}
`

interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps { }

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
	didInitialLoad: boolean
	errors: DisplayableError[]
}

class CustomFields extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			didInitialLoad: false,
			errors: [],
		}

		this.renderCustomFields = this.renderCustomFields.bind(this)
	}

	renderCustomFields() {
		const { t } = this.props
		return (
			<>
				<Title>{t('CustomFields::Custom Fields')}</Title>

				<Alert
					type='info'
					text={t('CustomFields::Create custom fields to add more details to anything like contacts, projects, products, ... . For example add a text field to products to save the country where you purchase those products.')}
				/>

				<CustomFieldsContainer style={{ marginTop: 24 }}>
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.CLIENT })}>
						{t('CustomFields::Contacts')}
						<Icon icon='chevron-right' />
					</CustomField>
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.SUPPLIER })}>
						{t('CustomFields::Suppliers')}
						<Icon icon='chevron-right' />
					</CustomField>
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.PROJECT })}>
						{t('CustomFields::Project')}
						<Icon icon='chevron-right' />
					</CustomField>
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.TIME_ENTRY })}>
						{t('CustomFields::Time Entry')}
						<Icon icon='chevron-right' />
					</CustomField>
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.INVOICE })}>
						{t('CustomFields::Invoice')}
						<Icon icon='chevron-right' />
					</CustomField>
					{/* <CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.EXPENSE })}>
						{t('CustomFields::Expense')}
						<Icon icon='chevron-right' />
					</CustomField> */}
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.PRODUCT })}>
						{t('CustomFields::Product')}
						<Icon icon='chevron-right' />
					</CustomField>
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.TASK })}>
						{t('CustomFields::Task')}
						<Icon icon='chevron-right' />
					</CustomField>
					<CustomField to={RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: CustomFieldModel.CALENDAR_EVENT })}>
						{t('CustomFields::Calendar events')}
						<Icon icon='chevron-right' />
					</CustomField>
					{/* <CustomFieldEmpty /> */}
				</CustomFieldsContainer>
			</>
		)

	}

	render() {
		const { t } = this.props

		return (
			<Panel>
				<Helmet>
					<title>{t('CustomFields::{{__appName}} | Custom Fields')}</title>
				</Helmet>
				<ScrollToTopOnMount />

				<Switch>
					<Route path={ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL} component={CustomFieldModelContainer} />
					<Route path={ERoute.PATH_SETTINGS_CUSTOM_FIELDS} component={this.renderCustomFields} />
					<Redirect from='/' to={ERoute.PATH_SETTINGS_CUSTOM_FIELDS} />
				</Switch>
			</Panel>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomFields))