import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../../styles'
import moment from '../../../utilities/Moment'
import { ProjectsController } from '../../../controllers'
import Loader from '../../Loaders/Loader'
import ProjectMonthlyTimelineItem from './ProjectMonthlyTimelineItem'
import ProjectMonthlyTimelineItemTooltip from './ProjectMonthlyTimelineItemTooltip'
import LocalStorage, { LocalStorageKey } from '../../../LocalStorage'
import Icon from '../../Icons/Icon'
import TimelineHeaderButton from '../../Timeline/TimelineHeaderButton'
import { CalendarEvent, Project, Settings } from '../../../types'
import TimelineTooltip from '../../Tooltips/TimelineTooltip'
import ProjectMonthlyTimelineLeaveItem from './ProjectMonthlyTimelineLeaveItem'
import { WithTranslation, withTranslation } from 'react-i18next'

const TimelineHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -10px;
`

const TimelineContainer = styled.div<{ active?: boolean }>`
	padding-top: 10px;
  overflow: hidden;
  transition: max-height 250ms linear;
  max-height: 0;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		overflow-x: scroll;
    overflow-y: hidden;
		margin-bottom: ${Style.spacing.x2};
	}

  ${props => props.active && css`
    max-height: 100%;
    margin-top: 0;
  `}

  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }
`
const Container = styled.div`
	position: relative;
	height: 240px;
	width: 100%;
	background: white;
	margin: 50px 0;
  border-left: 1px dotted #DDD;
  border-right: 1px dotted #DDD;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 1100px;
	}
`

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`

const GraphContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

const BottomTickContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	color: #CCCCCC;
	font-weight: 600;
	position: absolute;
	bottom: -40px;
	right: 0;
	left: 0;
	border-right: 1px dotted #F4F6F8;

	&::after {
		width: 2px;
    height: 10px;
    background-color: #E6E6E6;
    content: "";
    position: absolute;
    top: 0;
	}
`


const Month = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	height: 100%;
	padding: 0 8px;
	align-items: flex-end;
  z-index: 1;
	
	&:not(:last-child) {
		border-right: 1px dotted #DDD;
	}
`

const GraphItems = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }
`

const NowIndicator = styled.div<{ left: string }>`
  position: absolute;
  top: -7px;
  left: ${props => props.left}%;
  height: 7px;
  width: 1px;
  border: 1px #DDD solid;
`

const NowIndicatorWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const NowIndicatorTick = styled.div`
  position: absolute;
  top: -30px;
  color: #887;
  font-weight: bold;
`

const LeaveItems = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

type IProps = {
  setting: Settings
  onItemClick: (id: string) => void
} & WithTranslation

interface IState {
  didInitialLoad: boolean
  isLoading: boolean
  dates: string[]
  projects: Project[]
  leave: CalendarEvent[]
  timelineActive: boolean
}

class ProjectMonthlyTimeline extends React.Component<IProps, IState> {
  private nowIndicator = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      isLoading: false,
      dates: this.getRange(),
      projects: [],
      leave: [],
      timelineActive: LocalStorage.get(LocalStorageKey.PROJECT_TIMELINE_ACTIVE, true)
    }

    this.onToggleTimelineActive = this.onToggleTimelineActive.bind(this)
  }

  componentDidMount() {
    this.fetchTimeline()
  }

  getRange() {
    const startOfYear = moment().startOf('year')
    return [...Array(12)].fill(0).map((month, index) => moment(startOfYear).add(index, 'month').format('YYYY-MM-DD'))
  }

  async fetchTimeline() {
    this.setState({ isLoading: true })

    try {
      const response = await ProjectsController.getTimeline('monthly')

      this.setState({
        didInitialLoad: true,
        isLoading: false,
        dates: this.getRange(),
        projects: response.projects,
        leave: response.leave,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onToggleTimelineActive() {
    const { timelineActive } = this.state

    const newActiveState = !timelineActive

    LocalStorage.set(LocalStorageKey.PROJECT_TIMELINE_ACTIVE, newActiveState)

    this.setState({ timelineActive: newActiveState })
  }

  refresh() {
    this.fetchTimeline()
  }

  render() {
    const { onItemClick, t } = this.props
    const { didInitialLoad, isLoading, dates, projects, leave, timelineActive } = this.state

    const now = moment()
    let todayLeft = null
    let todayTop = null

    if (this.nowIndicator && this.nowIndicator.current) {
      todayLeft = this.nowIndicator.current.getBoundingClientRect().left
      todayTop = this.nowIndicator.current.getBoundingClientRect().top
    }

    return (
      <>
        <TimelineHeader>
          <TimelineHeaderButton onClick={this.onToggleTimelineActive}>
            <Icon icon={timelineActive ? 'eye' : 'eye-closed'} />
          </TimelineHeaderButton>
        </TimelineHeader>

        <TimelineContainer
          active={this.state.timelineActive}
        >
          <Container>
            {(!didInitialLoad || isLoading) && <LoadingOverlay>
              <Loader size='medium' />
            </LoadingOverlay>}

            <GraphItems>
              {leave.map(leaveEvent => (
                <ProjectMonthlyTimelineLeaveItem
                  key={leaveEvent.id}
                  leave={leaveEvent}
                />)
              )}

              {projects.map((project, index) => {
                return (
                  <ProjectMonthlyTimelineItem
                    key={project.id}
                    index={index}
                    project={project}
                    onItemClick={onItemClick}
                  />
                )
              })}
            </GraphItems>


            <GraphContainer>
              {dates.map((date, index) => {
                const momentDate = moment(date, 'YYYY-MM-DD')
                const bottomTick = momentDate.format('MMM')

                return (
                  <Month key={index}>
                    <BottomTickContainer>{bottomTick}</BottomTickContainer>
                  </Month>
                )
              })}
            </GraphContainer>

            <NowIndicator
              ref={this.nowIndicator}
              left={Number(now.dayOfYear() / 365 * 100).toFixed(2)}
            >
              <NowIndicatorWrapper>
                <NowIndicatorTick
                  style={{ position: 'absolute', left: `${todayLeft ? todayLeft : -9999}`, top: `${todayTop ? - 24 : -9999}px` }}
                >
                  {t('ProjectMonthlyTimeline::Today')}
                </NowIndicatorTick>
              </NowIndicatorWrapper>
            </NowIndicator>
          </Container>
        </TimelineContainer>
        <ProjectMonthlyTimelineItemTooltip />
        <TimelineTooltip />
      </>
    )
  }
}

export { ProjectMonthlyTimeline }

export default withTranslation('translation', { withRef: true })(ProjectMonthlyTimeline)