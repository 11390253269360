import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import ActionList, { IActionListItem } from '../../components/ActionList/ActionList';
import BlockEditor from '../../components/BlockEditor/BlockEditor';
import BlockEditorSidebar from '../../components/BlockEditor/BlockEditorSidebar';
import BlockEditorSidebarSection from '../../components/BlockEditor/BlockEditorSidebarSection';
import Button from '../../components/Button/Button';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import TopNavigation from '../../components/Navigation/TopNavigation';
import FullPageContent from '../../components/Page/FullPageContent';
import PageContent from '../../components/Page/PageContent';
import PageLoader from '../../components/Page/PageLoader';
import Popover from '../../components/Popover/Popover';
import ActionCableConsumer from '../../consumers/ActionCableConsumer';
import PlaybooksController from '../../controllers/PlaybooksController';
import ERoute from '../../ERoute';
import { AppState } from '../../store';
import {
	createContentblock,
	deleteContentBlock,
	setInitialContentBlockEditorState,
	updateContentBlock
} from '../../store/content-blocks/actions';
import { Style } from '../../styles';
import Notification from '../../utilities/Notification';
import Utils from '../../utilities/Utils';
import { showConfirmModal } from '../../store/modals/actions';
import ReactTooltip from 'react-tooltip';
import { ContentBlock, ContentBlockResource, ContentBlockType, ContentBlockVariables, CurrentUser, DisplayableError, Playbook, PlaybookChannelEvent, PlaybookChannelEventType, WorkspaceTax } from '../../types';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column-reverse;
		height: auto;
	}
`

const BlockEditorSidebarAction = styled.div`
	position: relative;
	width: 100%;

	> * {
		width: 100%;
	}

	.button {
		width: 100%;
	}
`

interface IStateToProps {
	currentUser: CurrentUser
	contentBlocks: ContentBlock[]
}

interface IDispatchToProps {
	setInitialContentBlockEditorState: typeof setInitialContentBlockEditorState
	createContentblock: typeof createContentblock
	updateContentBlock: typeof updateContentBlock
	deleteContentBlock: typeof deleteContentBlock
	showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation

interface IState {
	didInitialLoad: boolean
	playbook: Playbook | null
	actionPopoverActive: boolean
	isSubmitting: boolean
	errors: DisplayableError[]
	previewModeEnabled: boolean
	variables: ContentBlockVariables
}

class PlaybookView extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			didInitialLoad: false,
			playbook: null,
			actionPopoverActive: false,
			isSubmitting: false,
			errors: [],
			previewModeEnabled: false,
			variables: {}
		}

		this.onNameChange = this.onNameChange.bind(this)
		this.onFormSubmit = this.onFormSubmit.bind(this)
		this.debouncedFormSubmit = Utils.debounce(this.onFormSubmit, 500, false)
		this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
		this.onActionsClick = this.onActionsClick.bind(this)
		this.onActionPopoverClose = this.onActionPopoverClose.bind(this)
		this.onActionPopoverClick = this.onActionPopoverClick.bind(this)
		this.onDeleteClick = this.onDeleteClick.bind(this)


		// Action cable
		this.onActionCableConnected = this.onActionCableConnected.bind(this)
		this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
		this.onActionCableReceived = this.onActionCableReceived.bind(this)


		this.onCreateBlock = this.onCreateBlock.bind(this)
		this.onUpdateBlock = this.onUpdateBlock.bind(this)
		this.onDeleteBlock = this.onDeleteBlock.bind(this)
	}

	componentDidMount() {
		this.fetchForm().catch(console.error)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.fetchForm().catch(console.error)
		}

		ReactTooltip.rebuild();
	}

	onActionCableConnected() {
		console.log('[PlaybookChannel] connected')
	}

	onActionCableDisconnected() {
		console.log('[PlaybookChannel] Disconnected')
	}

	onActionCableReceived(event: PlaybookChannelEvent) {
		const { t } = this.props

		console.log('[PlaybookChannel] received event', event)

		switch (event.type) {
			case PlaybookChannelEventType.PLAYBOOK_CREATE:
				// Not really fired since you can't subscribe on a playbook that hasn't been created yet
				break
			case PlaybookChannelEventType.PLAYBOOK_UPDATE:
				this.setState({ playbook: event.data.playbook })
				break
			case PlaybookChannelEventType.PLAYBOOK_DELETE:
				Notification.notifySuccess(t('Playbook::Playbook has been deleted'))
				this.props.history.replace(ERoute.PATH_PLAYBOOKS)
				break
			case PlaybookChannelEventType.CONTENT_BLOCK_CREATE:
				this.props.createContentblock(event.data.content_block)
				break
			case PlaybookChannelEventType.CONTENT_BLOCK_UPDATE:
				this.props.updateContentBlock(event.data.content_block)
				break
			case PlaybookChannelEventType.CONTENT_BLOCK_DELETE:
				this.props.deleteContentBlock(event.data.content_block_id)
				break
			default:
				throw Error('Invalid event type')
		}
	}

	onCreateBlock(contentBlock: ContentBlock) {
		this.props.createContentblock(contentBlock)
	}

	onUpdateBlock(contentBlock: ContentBlock) {
		this.props.updateContentBlock(contentBlock)
	}

	onDeleteBlock(contentBlock: ContentBlock) {
		this.props.deleteContentBlock(contentBlock.id)
	}

	async fetchForm() {
		try {
			const { playbook } = await PlaybooksController.getForm({ id: this.props.match.params.id })

			this.props.setInitialContentBlockEditorState({ contentBlocks: playbook.content_blocks })

			this.setState({
				didInitialLoad: true,
				playbook: playbook,
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	onNameChange(e) {
		const name = e.currentTarget.value

		this.setState({
			playbook: {
				...this.state.playbook,
				name: name,
			}
		}, this.debouncedFormSubmit)
	}

	debouncedFormSubmit() {
		this.onFormSubmit().catch(console.error)
	}

	async onFormSubmit(e?: any) {
		const { isSubmitting } = this.state
		if (e) e.preventDefault()

		if (!isSubmitting) {
			try {
				this.setState({ isSubmitting: true })

				const response = await PlaybooksController.update(this.state.playbook)

				if (response.errors) {
					this.setState({ errors: response.errors })
				} else {
					this.setState({ errors: [] })
				}

				await this.fetchForm()
			} catch (ex) {
				console.error(ex)
			} finally {
				this.setState({ isSubmitting: false })
			}
		}
	}

	onTogglePreviewClick() {
		this.setState({ previewModeEnabled: !this.state.previewModeEnabled })
	}

	onActionsClick() {
		this.setState({ actionPopoverActive: true })
	}

	onActionPopoverClose() {
		this.setState({ actionPopoverActive: false })
	}

	async onActionPopoverClick(key: string) {
		const { playbook } = this.state
		this.setState({ actionPopoverActive: false })

		switch (key) {
			case 'delete':
				this.onDeleteClick()
				break
		}
	}

	async onDeleteClick() {
		const { t, showConfirmModal } = this.props
		const { playbook } = this.state

		requestAnimationFrame(() => {
			showConfirmModal({
				title: t('Playbook::Delete playbook'),
				description: t('Playbook::You are about to delete this playbook. Deleting this playbook also deletes all its associated data. Are you sure?'),
				action: { label: t('Playbook::Delete'), isDestructive: true },
				onConfirm: async () => {
					try {
						await PlaybooksController.delete(playbook.id)
						this.props.history.replace(ERoute.PATH_PLAYBOOKS)
					} catch (ex) {
						console.error(ex)
					}
				}
			})
		})
	}

	render() {
		const { t, contentBlocks, currentUser: { workspace: { setting } } } = this.props
		const {
			didInitialLoad,
			playbook,
			variables,
			previewModeEnabled,
			actionPopoverActive
		} = this.state

		const actions: IActionListItem[] = [
			{ key: 'delete', icon: 'trash-alt-solid', content: t('Playbook::Delete'), destructive: true },
		]

		return (
			<>
				<Helmet>
					<title>{t('Playbook::{{__appName}} | Playbook')}</title>
				</Helmet>

				<ScrollToTopOnMount />

				<TopNavigation
					icon='book'
					title={t('Playbook::Playbook')}
				/>

				<FullPageContent>
					<Container>
						{!didInitialLoad && <PageContent><PageLoader /></PageContent>}

						{didInitialLoad && <>
							<ActionCableConsumer
								channel={{ channel: 'PlaybookChannel', id: playbook.id }}
								onConnected={this.onActionCableConnected}
								onDisconnected={this.onActionCableDisconnected}
								onReceived={this.onActionCableReceived}
							>
								<BlockEditor
									resource={ContentBlockResource.PLAYBOOK}
									resourceId={playbook.id}
									blockTypes={[
										ContentBlockType.TEXT,
										ContentBlockType.IMAGE,
										ContentBlockType.QUESTION_AND_ANSWER,
										ContentBlockType.PDF,
									]}
									variables={variables}
									currency=''
									taxes={[]}
									numberFormat={setting.number_format}
									blocks={contentBlocks}
									previewModeEnabled={previewModeEnabled}
									editable={true}
									onTogglePreviewClick={this.onTogglePreviewClick}
									onCreateBlock={this.onCreateBlock}
									onUpdateBlock={this.onUpdateBlock}
									onDeleteBlock={this.onDeleteBlock}
								/>

								<BlockEditorSidebar>
									<form onSubmit={this.onFormSubmit}>
										<BlockEditorSidebarSection>
											<div className='form-item'>
												<label>{t('Playbook::Name')}</label>
												<input
													type='text'
													value={playbook.name}
													onChange={this.onNameChange}
													placeholder={t('Playbook::Name')}
												/>
											</div>
										</BlockEditorSidebarSection>
									</form>

									<BlockEditorSidebarSection>
										<BlockEditorSidebarAction>
											<Popover
												activator={
													<div>
														<Button
															text={t('Playbook::Actions')}
															type='default'
															onClick={this.onActionsClick}
														/>
													</div>
												}
												active={actionPopoverActive}
												placement='top'
												onClose={this.onActionPopoverClose}
											>
												<ActionList
													actions={actions}
													onClick={(key) => this.onActionPopoverClick(key)}
													hasIndicator
												/>
											</Popover>
										</BlockEditorSidebarAction>
									</BlockEditorSidebarSection>
								</BlockEditorSidebar>
							</ActionCableConsumer>
						</>}
					</Container>
				</FullPageContent>
			</>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
		contentBlockEditor: {
			contentBlocks,
		}
	} = state

	return {
		currentUser: currentUser,
		contentBlocks: contentBlocks,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	setInitialContentBlockEditorState,
	createContentblock,
	updateContentBlock,
	deleteContentBlock,
	showConfirmModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PlaybookView))