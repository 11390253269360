import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import BlockCanvas from '../components/BlockEditor/BlockCanvas'
import BlockContainer from '../components/BlockEditor/BlockContainer'
import BlockEditorContainer from '../components/BlockEditor/BlockEditorContainer'
import BlocksWrapper from '../components/BlockEditor/BlocksWrapper'
import BlockWrapper from '../components/BlockEditor/BlockWrapper'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import FullPageContent from '../components/Page/FullPageContent'
import PageHeader from '../components/Page/PageHeader'
import PageLoader from '../components/Page/PageLoader'
import { LedgerConditionsController } from '../controllers'
import { Style } from '../styles'
import Button from '../components/Button/Button'
import Notification from '../utilities/Notification'
import TooltipError from '../components/Tooltips/ErrorTooltip'
import BlocksRenderer from '../components/BlockEditor/BlocksRenderer'
import Editor, { DEFAULT_EDITOR_CONFIG } from '../components/Editor/Editor'
import { useSelector } from 'react-redux'
import { AppState } from '../store'
import PowerSelect from '../components/Form/PowerSelect'
import LocaleHelper from '../helpers/LocaleHelper'
import { DEFAULT_LOCALE } from '../Constants'
import ReactSelectTheme from '../components/Form/ReactSelectTheme'
import MobilityHelper from '../helpers/MobilityHelper'
import { ContentBlockType, DisplayableError, LedgerCondition, Locale } from '../types'

const PreviewHeader = styled.div`
	position: sticky;
	top: 20px;
	right: ${Style.spacing.x2};
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: ${Style.spacing.x1};
	margin-right: ${Style.spacing.x2};
	z-index: 1;
`

const Actions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`

type IProps = RouteComponentProps<{ id: string }>

const LedgerItemCondition = (props: IProps) => {
	const { t } = useTranslation()
	const [didInitialLoad, setDidInitialLoad] = React.useState(false)
	const [previewModeEnabled, setPreviewModeEnabled] = React.useState(false)
	const [ledgerItemCondition, setLedgerItemCondition] = React.useState<LedgerCondition>()
	const [errors, setErrors] = React.useState<DisplayableError[]>([])
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
	const [activeTranslationsLocale, setActiveTranslationsLocale] = React.useState<Locale>(currentUser.locale)

	React.useEffect(() => {
		fetchLedgerCondition().catch(console.error)
	}, [])

	const onBreadCrumbBackPress = () => {
		props.history.goBack()
	}

	const fetchLedgerCondition = async () => {
		try {
			const ledgerCondition = await LedgerConditionsController.form(props.match.params.id)

			setLedgerItemCondition(ledgerCondition)
			setDidInitialLoad(true)
		} catch (ex) {
			console.error(ex)
		}
	}

	const onTogglePreviewClick = async () => {
		setPreviewModeEnabled(!previewModeEnabled)
	}

	const onNameChange = (e) => {
		const name = e.target.value

		setLedgerItemCondition({
			...ledgerItemCondition,
			name: name
		})
	}

	const onLocaleChange = (option) => {
		setActiveTranslationsLocale(option.value)
	}

	const onContentChange = (content: string) => {
		setLedgerItemCondition({
			...ledgerItemCondition,
			translations: MobilityHelper.updateTranslation(activeTranslationsLocale, ledgerItemCondition.translations, 'content', content)
		})
	}

	const onFormSubmit = async (e) => {
		e.preventDefault();

		try {
			let response

			if (ledgerItemCondition.id) {
				response = await LedgerConditionsController.update(ledgerItemCondition)
			}
			else {
				response = await LedgerConditionsController.create(ledgerItemCondition)
			}

			if (response.errors) {
				setErrors(response.errors)
				Notification.notifyError(t('LedgerItemCondition::Oops something went wrong'))
			}
			else {
				Notification.notifySuccess(t('LedgerConditionForm::Condition successfully saved.'))
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	const onErrorsDismiss = () => {
		setErrors([])
	}

	const localeOptions = LocaleHelper.getLocaleOptions()
	const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)
	let content = ''

	if (ledgerItemCondition) {
		content = MobilityHelper.getTranslation(activeTranslationsLocale, ledgerItemCondition.translations, 'content')
	}

	return (
		<>
			<Helmet>
				<title>{t('LedgerItemCondition::{{__appName}} | Condition')}</title>
			</Helmet>
			<ScrollToTopOnMount />

			<TopNavigation
				icon='invoice'
				title={t('LedgerItemCondition::Terms & Conditions')}
			/>

			<FullPageContent>
				<BlockCanvas>
					<BlocksWrapper className={previewModeEnabled ? 'preview' : ''}>
						{previewModeEnabled && <BlocksRenderer
							blocks={[{
								type: ContentBlockType.TEXT,
								content: content
							}]}
							currency={currentUser.workspace.setting.default_currency}
							numberFormat={currentUser.workspace.setting.number_format}
							variables={{}}
						/>}

						{!previewModeEnabled && <>
							<PageHeader
								title={t('LedgerItemCondition::Terms & Conditions')}
								breadcrumbs={[
									{ content: t('LedgerItemCondition::Back'), url: 'javascript://', onClick: onBreadCrumbBackPress }
								]}
							/>

							{!didInitialLoad && <PageLoader />}

							{didInitialLoad && <>

								<div className='form-item'>
									<label>{t('LedgerItemCondition::Language')}</label>
									<PowerSelect
										options={localeOptions}
										value={selectedLocaleOption}
										onChange={onLocaleChange}
										noOptionsMessage={(value) => t('LedgerItemCondition::No language found.')}
										theme={ReactSelectTheme}
									/>
								</div>

								<div className='form-item'>
									<label>{t('LedgerItemCondition::Name')}</label>
									<input
										type='text'
										value={ledgerItemCondition.name}
										onChange={onNameChange}
										disabled={previewModeEnabled}
										placeholder={t('LedgerItemCondition::Name')}
									/>
								</div>

								<div className='form-item'>
									<label>{t('LedgerItemCondition::Content')}</label>
									<BlockContainer>
										<BlockWrapper className='pt-0'>
											{!previewModeEnabled && <div style={{ background: 'white' }}>
												<BlockEditorContainer>
													<Editor
														model={content}
														onModelChange={onContentChange}
														config={{
															...DEFAULT_EDITOR_CONFIG,
															heightMax: '60vh',
														}}
													/>
												</BlockEditorContainer>
											</div>}
										</BlockWrapper>
									</BlockContainer>
								</div>

								<Actions>
									<input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />

									<div style={{ position: 'relative' }}>
										<TooltipError
											errors={errors}
											onDismiss={onErrorsDismiss}
										/>
										<Button
											type='success'
											text={t('LedgerItemCondition::Save')}
											onClick={onFormSubmit}
										/>
									</div>
								</Actions>
							</>}
						</>}
					</BlocksWrapper>
				</BlockCanvas>
			</FullPageContent>
		</>
	)
}

export default LedgerItemCondition