import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const ConversationContainer = styled.div<{ active?: boolean }>`
	width: 100%;
	height: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: none;

		${props => props.active && css`
			display: block;
		`}
	}
`

export default ConversationContainer