import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { useTranslation } from 'react-i18next'
import IntegrationFormTitle from '../IntegrationFormTitle'
import IntegrationFormLabel from '../IntegrationFormLabel'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ERoute from '../../../ERoute'
import Tooltip from '../../Tooltips/Tooltip'
import { IntegrationType, MailsyncFormData, MailsyncIntegrationConfig } from '../../../types'
import ActionButton from '../ActionButton'
import RouteHelper from '../../../helpers/RouteHelper'

const Actions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 12px;

	svg {
		max-width: 215px;
	}

	img {
		max-width: 215px;
	}
`

type IProps = {
	config: MailsyncIntegrationConfig
	formData: MailsyncFormData
	onConfigChange: (config: MailsyncIntegrationConfig) => void
} & RouteComponentProps

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		margin-bottom: ${Style.spacing.x2};
		font-weight: bold;
	}
`

const MailsyncForm = (props: IProps) => {
	const { config, onConfigChange, formData, history } = props
	const { t } = useTranslation()

	const onInvoiceEmailAddressChange = (e) => {
		onConfigChange({ ...config, invoice_mailbox_address: e.currentTarget.value })
	}

	const onInvoiceEmailSubjectChange = (e) => {
		onConfigChange({ ...config, invoice_mailbox_subject: e.currentTarget.value })
	}

	const onExpenseEmailAddressChange = (e) => {
		onConfigChange({ ...config, expense_mailbox_address: e.currentTarget.value })
	}

	const onExpenseEmailSubjectChange = (e) => {
		onConfigChange({ ...config, expense_mailbox_subject: e.currentTarget.value })
	}

	const onDocumentEmailAddressChange = (e) => {
		onConfigChange({ ...config, document_mailbox_address: e.currentTarget.value })
	}

	const onDocumentEmailSubjectChange = (e) => {
		onConfigChange({ ...config, document_mailbox_subject: e.currentTarget.value })
	}

	const onConfigureSmtpClick = () => {
		history.push(ERoute.PATH_SETTINGS_SMTP)
	}

	return (
		<Container>
			{!formData.smtp_configured && <>
				<Actions>
					<ActionButton onClick={() => history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: IntegrationType.OUTLOOK }))}>
						{t('MailSyncForm::Connect with Outlook')}
					</ActionButton>

					<ActionButton onClick={() => history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: IntegrationType.GMAIL }))} >
						{t('MailSyncForm::Connect with Gmail')}
					</ActionButton>

					<ActionButton onClick={onConfigureSmtpClick}>
						{t('MailSyncForm::Connect with SMTP')}
					</ActionButton>
				</Actions>
			</>}
			{
				formData.smtp_configured && <>
					<IntegrationFormTitle>
						{t('MailSyncForm::Settings')}
					</IntegrationFormTitle>

					<div className='form-item'>
						<IntegrationFormLabel>
							{t('MailSyncForm::Invoice mailbox address')}
						</IntegrationFormLabel>
						<input
							type='text'
							value={config.invoice_mailbox_address}
							placeholder='invoice-381@domain.com'
							onChange={onInvoiceEmailAddressChange}
						/>
					</div>

					<div className='form-item'>
						<IntegrationFormLabel>
							{t('MailSyncForm::Invoice mailbox subject (optional)')}
							<Tooltip
								content={t('MailSyncForm::Mailboxes sometimes require a specific subject to be set in order to process the email.')}
								containerStyle={{ marginLeft: 8 }}
							/>
						</IntegrationFormLabel>
						<input
							type='text'
							value={config.invoice_mailbox_subject}
							placeholder='<ID> or <ID>-<NUMBER>'
							onChange={onInvoiceEmailSubjectChange}
						/>
					</div>

					<div className='form-item'>
						<IntegrationFormLabel>
							{t('MailSyncForm::Expense mailbox address')}
						</IntegrationFormLabel>
						<input
							type='text'
							value={config.expense_mailbox_address}
							placeholder='expense-381@domain.com'
							onChange={onExpenseEmailAddressChange}
						/>
					</div>

					<div className='form-item'>
						<IntegrationFormLabel>
							{t('MailSyncForm::Expense mailbox subject (optional)')}
							<Tooltip
								content={t('MailSyncForm::Mailboxes sometimes require a specific subject to be set in order to process the email.')}
								containerStyle={{ marginLeft: 8 }}
							/>
						</IntegrationFormLabel>
						<input
							type='text'
							value={config.expense_mailbox_subject}
							placeholder='<ID> or <ID>-<NUMBER>'
							onChange={onExpenseEmailSubjectChange}
						/>
					</div>

					<div className='form-item'>
						<IntegrationFormLabel>
							{t('MailSyncForm::Document mailbox address')}
						</IntegrationFormLabel>
						<input
							type='text'
							value={config.document_mailbox_address}
							placeholder='expense-381@domain.com'
							onChange={onDocumentEmailAddressChange}
						/>
					</div>

					<div className='form-item'>
						<IntegrationFormLabel>
							{t('MailSyncForm::Document mailbox subject (optional)')}
							<Tooltip
								content={t('MailSyncForm::Mailboxes sometimes require a specific subject to be set in order to process the email.')}
								containerStyle={{ marginLeft: 8 }}
							/>
						</IntegrationFormLabel>
						<input
							type='text'
							value={config.document_mailbox_subject}
							placeholder='<ID> or <ID>-<NUMBER>'
							onChange={onDocumentEmailSubjectChange}
						/>
					</div>
				</>
			}
		</Container >
	)
}

export default withRouter(MailsyncForm)