import Images from "../images";
import i18n from "../translations";
import { ContentBlock, ContentBlockType, QuestionAndAnswerType } from "../types";
import ContentBlockHelper from "./ContentBlockHelper";

export interface PlaybookTemplate {
  icon: string
  title: string
  description: string
  contentBlocks: ContentBlock[]
}

export interface PlaybookTemplateCategory {
  title: string
  templates: PlaybookTemplate[]
}

export default class PlaybookHelper {
  static getPlaybookTemplateCategories(): PlaybookTemplateCategory[] {
    return [
      {
        title: i18n.t('PlaybookTemplate::Build your own'),
        templates: [
          {
            icon: Images.START_PLAYBOOK_TEMPLATE,
            title: i18n.t('PlaybookTemplate::Start from scratch'),
            description: i18n.t('PlaybookTemplate::Start with a blank playbook. Add your own instructions and questions.'),
            contentBlocks: [
              {
                type: ContentBlockType.TEXT,
                content: i18n.t('PlaybookTemplate::<h3>Add title</h3><p>Introduce the purpose of your playbook here. Include any relevant instructions for your reps.</p>'),
                position: ContentBlockHelper.POSITION_STEP_INDEX * 1
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Include a question you\'d like answered'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 2
              }
            ],
          }
        ]
      },
      {
        title: i18n.t('PlaybookTemplate::Sales playbook templates'),
        templates: [
          {
            icon: Images.CALL_PLAYBOOK_TEMPLATE,
            title: i18n.t('PlaybookTemplate::Discovery call playbook'),
            description: i18n.t('PlaybookTemplate::Create a script to lead successful and insightful conversations with a prospect or customer.'),
            contentBlocks: [
              {
                type: ContentBlockType.TEXT,
                content: i18n.t('PlaybookTemplate::<p>Use this playbook to help guide your first call with a prospect. During this call, you want to ask questions to uncover the prospects needs, challenges and goals as they relate to your product or service.</p><p><strong>Before the call, you should:<br></strong></p><ol><li>Research your prospect\'s business</li><li>Create an agenda and send it to your prospect</li><li>Set a time and date that works for both of you</li></ol><p>Below are some key questions to ask during your discovery call.</p>'),
                position: ContentBlockHelper.POSITION_STEP_INDEX * 1
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What are the top initiatives at your company right now?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 2
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Tell me about your role. What do you do day-to-day?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 3
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What are your goals for the year?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 4
              },
            ],
          },
          {
            icon: Images.QUALIFICATION_PLAYBOOK_TEMPLATE,
            title: i18n.t('PlaybookTemplate::Qualification playbook'),
            description: i18n.t('PlaybookTemplate::Build a playbook to qualify prospects based on budget, authority, needs and timeline.'),
            contentBlocks: [
              {
                type: ContentBlockType.TEXT,
                content: i18n.t('PlaybookTemplate::<h3>Add title</h3><p>Use the BANT sales qualification framework to find out:</p><ol><li>Budget: Is het prospect capable of buying?</li><li>Authority: Does your contact have adequate authority to sign off on a purchase?</li><li>Need: Does the prospect have a business pain you can solve?</li><li>Timeline: When is prospect planning to buy?</li></ol><p>Below are some key questions to ask:</p><p><br></p>'),
                position: ContentBlockHelper.POSITION_STEP_INDEX * 1
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Do you have a budget set aside for this purchase? What is it?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 2
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Who else will be involved in the purchasing decision?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 3
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What challenges are you struggling with?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 4
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::How quickly do you need to solve your problem?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 5
              }
            ],
          },
        ]
      },
      {
        title: i18n.t('PlaybookTemplate::Service playbook templates'),
        templates: [
          {
            icon: Images.BUSINESS_PLAYBOOK_TEMPLATE,
            title: i18n.t('PlaybookTemplate::Business review playbook'),
            description: i18n.t('PlaybookTemplate::Build a playbook to run recurring business reviews that deepen relationships with customers and uncover areas of opportunity.'),
            contentBlocks: [
              {
                type: ContentBlockType.TEXT,
                content: i18n.t('PlaybookTemplate::<h3>Add title</h3><p>Check-ins, such as a business review, can deepen your relationship with the customer and uncover areas of additional opportunity.</p><h3>Meeting prep</h3><p>In advance of your quarterly business review with your customer, you should complete the following:</p><ol><li>Prepare a summary showing customer usage of the product or benefits your service has provided</li><li>Measure customer progress against the previous QBR or their success metrics defined during the onboarding process</li><li>Review the customer\'s open tickets for discussion</li><li>Share an agenda with your customer in advance of the meeting</li></ol><h3>Questions to ask during meeting</h3><p><br></p>'),
                position: ContentBlockHelper.POSITION_STEP_INDEX * 1
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Has your team or team structure changed since our last meeting?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 2
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What are points of frustration with your current products/services?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 3
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What are your goals for the next business cycle (e.g., quarter) and how will you measure progress?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 4
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What are your goals for the next business cycle (e.g., quarter) and how will you measure progress?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 5
              },
              {
                type: ContentBlockType.TEXT,
                content: i18n.t('PlaybookTemplate::<h3>Fill After Meeting</h3>'),
                position: ContentBlockHelper.POSITION_STEP_INDEX * 6
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Is the customer a good fit for other products or services?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 7
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What is your perceived customer health score?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 8
              }
            ],
          },
          {
            icon: Images.CUSTOMER_PLAYBOOK_TEMPLATE,
            title: i18n.t('PlaybookTemplate::Customer onboarding playbook'),
            description: i18n.t('PlaybookTemplate::Build a playbook to kick-off a new customer relationship.'),
            contentBlocks: [
              {
                type: ContentBlockType.TEXT,
                content: i18n.t('PlaybookTemplate::<h3>Add title</h3><p>Use this playbook to help guide your first call with a newly acquired customer. During this call, you want to ask questions to uncover the prospect\'s reason for coming to your company and deepen your partnership</p><h3>Meeting prep</h3><ol><li>Review the customer\'s sales history and speak with the sales rep</li><li>Share a welcome packet with customer</li><li>Set a time and date that works for both of you</li><li>Set up onboarding-specific properties in appropriate objects to update during the call</li></ol > <p><br></p>'),
                position: ContentBlockHelper.POSITION_STEP_INDEX * 1
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Have you completed the onboarding guide we shared in advance of this call?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 2
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What are your overarching goals for this project/product? What needs to be done in the short-term vs. long-term?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 3
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Have you worked with a similar product/service provider in the past?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 4
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::Who are the main points of contact or agencies we will be working with?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 5
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What specific metrics can we use to measure success for this project?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 6
              },
              {
                type: ContentBlockType.QUESTION_AND_ANSWER,
                answer_type: QuestionAndAnswerType.OPEN,
                title: i18n.t('PlaybookTemplate::What are your challenges and roadblocks?'),
                options: [],
                position: ContentBlockHelper.POSITION_STEP_INDEX * 7
              }
            ],
          },
        ]
      }
    ]
  }
}