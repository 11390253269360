import * as React from 'react'
import TimeEntriesTable from '../TimeEntry/TimeEntriesTable'

type IProps = {
	contactId?: string
	projectId?: string
}

const ProjectTimesheets = (props: IProps) => {
	const { contactId, projectId } = props

	return (
		<TimeEntriesTable
			contactId={contactId}
			projectId={projectId}
			showDate={true}
		/>
	)
}

export default ProjectTimesheets