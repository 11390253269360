import * as React from 'react'

interface IProps {
	className?: string
	children: any
}

const BlockWrapper = (props: IProps) => {
	return (
		<div className={`block-wrapper ${props.className || ''}`}>
			{props.children}
		</div>
	)
}

export default BlockWrapper