import * as React from 'react'
import { WithTranslation, withTranslation } from "react-i18next";
import { ContactGroup, UserWorkspaceSettingScope } from "../../../types";
import { ContactGroupsController } from "../../../controllers";
import SimplebarHeader from "../../Simplebar/SimplebarHeader";
import SimplebarContainer from "../../Simplebar/SimplebarContainer";
import SimplebarContent from "../../Simplebar/SimplebarContent";
import SimplebarDescription from "../../Simplebar/SimplebarDescription";
import SimplebarItems from '../../Simplebar/SimplebarItems';
import SimplebarItem from '../../Simplebar/SimplebarItem';
import SimplebarLoader from '../../Simplebar/SimplebarLoader';
import SimplebarAction from '../../Simplebar/SimplebarAction';
import UserWorkspaceSettingHelper from '../../../helpers/UserWorkspaceSettingHelper';

type IProps = {
  active: boolean
  onItemClick: (contactGroup: ContactGroup) => void
  onManageGroupsClick: () => void
} & WithTranslation

interface IState {
  contactGroups: ContactGroup[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  reachedEnd: boolean
  isFetching: boolean
}

class GroupsSimplebar extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      contactGroups: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      reachedEnd: false
    }

    this.fetchGroups = this.fetchGroups.bind(this)
    this.onScrollChange = this.onScrollChange.bind(this)
    this.onItemClick = this.onItemClick.bind(this)
    this.onManageGroupsClick = this.onManageGroupsClick.bind(this)
  }

  componentDidMount() {
    this.fetchGroups(1)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.props.active && !prevProps.active) {
      this.container.current.scrollTop = 0
      this.fetchGroups(1)
    }
  }

  async fetchGroups(page: number) {
    const { contactGroups: stateContactGroups } = this.state
    this.setState({
      isFetching: true
    }, async () => {
      try {
        const response = await ContactGroupsController.getContactGroups({
          page: page,
          order: 'created_at_desc',
        })
        const { contact_groups, current_page, total_pages, total_entries } = response;

        this.setState({
          contactGroups: current_page === 1 ? [...contact_groups] : [...stateContactGroups, ...contact_groups],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
          reachedEnd: current_page === total_pages
        });
      } catch (ex) {
        console.error(ex)
      }
    });
  }

  onScrollChange(event) {
    const { currentPage, reachedEnd, isFetching } = this.state
    const node = event.target;
    const scrollListEndReached = node.scrollHeight - node.scrollTop <= node.clientHeight;

    if (scrollListEndReached && !reachedEnd && !isFetching) {
      this.fetchGroups(currentPage + 1)
    }
  }

  onItemClick(contactGroup: ContactGroup) {
    this.props.onItemClick(contactGroup)
  }

  onManageGroupsClick() {
    this.props.onManageGroupsClick()
  }

  render() {
    const { t } = this.props
    const { contactGroups, didInitialLoad } = this.state

    return (
      <SimplebarContainer>
        <SimplebarHeader>
          {t('SidebarContact::Groups')}
        </SimplebarHeader>

        <SimplebarContent ref={this.container} onScroll={this.onScrollChange}>
          {!didInitialLoad && <SimplebarLoader />}
          {didInitialLoad && <>
            {contactGroups?.length === 0 && <SimplebarDescription>
              {t('GroupsSimplebar::You have not created any groups yet. Click "Manage groups" below to set up groups which will help you organize your contacts.')}
            </SimplebarDescription>}
            {contactGroups?.length > 0 && <SimplebarItems>
              {contactGroups.map((contactGroup, index) => {
                return (
                  <SimplebarItem key={contactGroup.id} onClick={() => this.onItemClick(contactGroup)} title={contactGroup.name}>
                    <span>{contactGroup.name}</span>
                  </SimplebarItem>
                )
              })}
            </SimplebarItems>}
          </>}
        </SimplebarContent>
        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) && <SimplebarAction onClick={this.onManageGroupsClick}>
          {t('GroupsSimplebar::Manage groups')}
        </SimplebarAction>}
      </SimplebarContainer>
    )
  }
}

export default withTranslation()(GroupsSimplebar)