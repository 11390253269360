import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import FixedSidebarContainer from '../Sidebar/FixedSidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import SidebarSection from '../Sidebar/SidebarSection'
import { CalendarViewEventType, Contact, IntegrationType } from '../../types'
import { ContactsController, SequenceEnrollmentsController } from '../../controllers';
import Loader from '../Loaders/Loader';
import Utils from '../../utilities/Utils';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import ResourceCheckbox from '../Resource/ResourceCheckbox';
import SidebarFooter from '../Sidebar/SidebarFooter';
import Button from '../Button/Button';
import Icon from '../Icons/Icon';
import ReactTooltip from 'react-tooltip';
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect';
import Tooltip from '../Tooltips/Tooltip';
import Alert from '../Alert/Alert';
import EmailIntegrations from '../EmailModal/EmailIntegrations';
import EmailIntegration from '../EmailModal/EmailIntegration';
import Images from '../../images';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Contacts = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	background: white;
	overflow: hidden;
  margin-top: -16px;
`

const ContactItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Style.spacing.x3};
  height: 72px;
  border-bottom: 1px solid ${Style.color.border};
  padding: 0 8px;
  cursor: pointer;

  &:hover {
    background: #f7f7fa;
  }

  ${props => props.disabled && css`
    opacity: 0.5;
  `}
`

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 0;
`

const EmptyContainer = styled.div`
	.card-empty-info {
		padding: 40px;
	}
`

const ContactItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const ContactItemDanger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  i, svg {
    fill: ${Style.color.brandDanger};
    color: ${Style.color.brandDanger};
  }
`

const ButtonContainer = styled.div`
  > a {
    width: 100%;
  }
`

export interface ICalendarFilter {
  searchValue: string
  eventTypes: CalendarViewEventType[]
}

type IProps = {
  active: boolean
  sequenceId: string
  onCloseClick: () => void
  onEnrollSubmit: (contactIds: string[], groupIds: string[]) => void
  onCreateContactClick: () => void
} & WithTranslation & RouteComponentProps

interface IState {
  searchValue: string
  groupIds: string[]
  contacts: Contact[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  reachedEnd: boolean
  selectedContactIds: string[]
  enrolledContactIds: string[]
  emailIntegrationEnabled: boolean
}

const DEFAULT_STATE: IState = {
  contacts: [],
  groupIds: [],
  currentPage: 1,
  totalPages: 0,
  searchValue: '',
  didInitialLoad: false,
  isFetching: false,
  reachedEnd: false,
  emailIntegrationEnabled: false,
  selectedContactIds: [],
  enrolledContactIds: []
}


class SequenceEnrollmentSidebar extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()
  private input = React.createRef<HTMLInputElement>()

  constructor(props: IProps) {
    super(props)

    this.state = DEFAULT_STATE

    this.onOutsideClick = this.onOutsideClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onGroupsChange = this.onGroupsChange.bind(this)
    this.fetchContactsDebounced = Utils.debounce(this.fetchContacts, 250, false)
    this.onClearFiltersClick = this.onClearFiltersClick.bind(this)
    this.onCreateContactClick = this.onCreateContactClick.bind(this)
    this.onContactSelectionChange = this.onContactSelectionChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  fetchContactsDebounced() {
    this.fetchContacts().catch(console.error)
  }

  async fetchForm() {
    const { sequenceId } = this.props
    try {
      const response = await SequenceEnrollmentsController.getForm(sequenceId)
      this.setState({
        enrolledContactIds: response.enrolled_contact_ids,
        emailIntegrationEnabled: response.email_integration_enabled,
        didInitialLoad: true
      })
    } catch (ex) {
      console
    }
  }

  async fetchContacts(page: number = 1) {
    const { contacts: stateTasks, searchValue } = this.state
    try {
      this.setState({ isFetching: true })

      let params = {
        page: page,
        'email[present]': true,
        order: 'created_at_desc',
      }

      if (searchValue.length > 0) {
        params['search'] = searchValue
      }

      const response = await ContactsController.getContacts(params)

      const { contacts, current_page, total_pages, total_entries } = response;

      this.setState({
        contacts: current_page === 1 ? [...contacts] : [...stateTasks, ...contacts],
        currentPage: current_page,
        totalPages: total_pages,
        didInitialLoad: true,
        isFetching: false,
        reachedEnd: current_page === total_pages
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.onOutsideClick);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.onOutsideClick);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (!prevProps.active && this.props.active) {
      this.setState(DEFAULT_STATE, () => {
        this.fetchForm()
        this.fetchContacts()
      })
    }

    ReactTooltip.rebuild()
  }

  onOutsideClick(e) {
    const { active, onCloseClick } = this.props

    if (active && this.container && !this.container.current.contains(e.target)) {
      onCloseClick()
    }
  }

  onCloseClick() {
    this.props.onCloseClick()
  }

  onSearchChange(e) {
    this.setState({ searchValue: e.target.value }, this.fetchContactsDebounced)
  }

  onGroupsChange(groupIds: string[]) {
    this.setState({ groupIds: groupIds })
  }

  onClearFiltersClick() {
    this.setState({ didInitialLoad: false, searchValue: '' }, () => {
      this.fetchContacts()
    })
  }

  onCreateContactClick() {
    this.props.onCreateContactClick()
    this.props.onCloseClick()
  }

  onContactSelectionChange(contactId: string) {
    const { selectedContactIds } = this.state

    if (selectedContactIds.includes(contactId)) {
      this.setState({ selectedContactIds: selectedContactIds.filter(id => id !== contactId) })
    } else {
      this.setState({ selectedContactIds: [...selectedContactIds, contactId] })
    }
  }

  onFormSubmit() {
    const { selectedContactIds, groupIds } = this.state
    this.props.onEnrollSubmit(selectedContactIds, groupIds)
  }

  getEnrollmentWarning(contact: Contact) {
    const { t } = this.props

    if (!contact.marketing_consent) return t('SequenceEnrollmentSidebar::Contact is marked as "Do not contact for commercial purposes".')
  }

  render() {
    const { active, t } = this.props
    const {
      searchValue,
      contacts,
      didInitialLoad,
      selectedContactIds,
      enrolledContactIds,
      groupIds,
      emailIntegrationEnabled
    } = this.state

    const filtersActive = searchValue.length > 0
    const sendDisabled = !emailIntegrationEnabled
    let tooltipReason = null

    if (!emailIntegrationEnabled) {
      tooltipReason = t('SequenceEnrollmentSidebar::Please configure an email integration to be able to enroll contacts.')
    }

    return (
      <FixedSidebarContainer ref={this.container} active={active} style={{ zIndex: 8 }}>
        <SidebarHeader>
          {t('SequenceEnrollmentSidebar::Enroll contacts')}
          <SidebarClose onClick={this.onCloseClick} />
        </SidebarHeader>

        <SidebarDivider />

        <SidebarContent>
          {!didInitialLoad && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {didInitialLoad && <>
            {tooltipReason && <div style={{ marginBottom: 16 }}>
              <Alert type='warning' text={<>
                <span style={{ marginRight: 4 }}>
                  <Icon icon='exclamation-triangle' />
                </span>
                {tooltipReason}
              </>} />
            </div>}
            {!emailIntegrationEnabled && <>
              <EmailIntegrations>
                <EmailIntegration
                  logo={Images.GOOGLE_EMAIL_INTEGRATION_LOGO}
                  title={t('SendEmailModal::Google')}
                  description={t('SendEmailModal::Gmail / GSuite')}
                  onClick={() => {
                    this.onCloseClick()
                    this.props.history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: IntegrationType.GMAIL }))
                  }}
                />
                <EmailIntegration
                  logo={Images.OUTLOOK_EMAIL_INTEGRATION_LOGO}
                  title={t('SendEmailModal::Outlook')}
                  description={t('SendEmailModal::Hotmail, Live, MSN')}
                  onClick={() => {
                    this.onCloseClick()
                    this.props.history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: IntegrationType.OUTLOOK }))
                  }}
                />
              </EmailIntegrations>
            </>}

            {emailIntegrationEnabled && <>
              <div className='form-item'>
                <label>{t('SequenceEnrollmentSidebar::Search')}</label>
                <input
                  ref={this.input}
                  type='text'
                  value={searchValue}
                  onChange={this.onSearchChange}
                  style={{ marginBottom: Style.spacing.x1 }}
                  placeholder={t('SequenceEnrollmentSidebar::Search contacts...')}
                />
              </div>

              <div className='form-item'>
                <label>
                  {t('SequenceEnrollmentSidebar::Groups')}
                  <Tooltip
                    content={t('SequenceEnrollmentSidebar::Enroll all contacts in the selected groups.')}
                    containerStyle={{ marginLeft: 8 }}
                  />
                </label>
                <ResourceCreatablePowerSelect
                  type='contact_group'
                  value={groupIds}
                  onChange={this.onGroupsChange}
                  isClearable={true}
                  placeholder={t('ContactModal::Select contact groups...')}
                  isMulti
                />
              </div>

              <ButtonContainer>
                <Button
                  type='default'
                  icon='contacts'
                  text={t('SequenceEnrollmentSidebar::Create contact')}
                  onClick={this.onCreateContactClick}
                />
              </ButtonContainer>

              <SidebarDivider />



              {contacts.length === 0 && (
                <EmptyContainer>
                  <CardEmptyInfo
                    icon={filtersActive ? 'search' : 'contacts'}
                    description={filtersActive ? t('SequenceEnrollmentSidebar::No contacts found') : t('SequenceEnrollmentSidebar::No contacts created yet')}
                    descriptionActionText={filtersActive ? t('SequenceEnrollmentSidebar::Clear filters') : t('SequenceEnrollmentSidebar::Create a contact')}
                    onDescriptionActionClick={filtersActive ? this.onClearFiltersClick : this.onCreateContactClick}
                  />
                </EmptyContainer>)
              }
              {contacts.length > 0 && <Contacts>
                {contacts.map((contact, index) => {
                  const isAlreadyEnrolled = enrolledContactIds.includes(contact.id)
                  const enrollmentWarning = this.getEnrollmentWarning(contact)

                  return (
                    <ContactItem key={index} onClick={isAlreadyEnrolled ? null : () => this.onContactSelectionChange(contact.id)} disabled={isAlreadyEnrolled} data-tip={isAlreadyEnrolled ? t('SequenceEnrollmentSidebar::Contact is already enrolled in this sequence') : ''}>
                      <ResourceCheckbox
                        checked={selectedContactIds.includes(contact.id)}
                        onCheckedChange={() => { }}
                      />
                      <ContactItemContent>
                        {contact.name}
                        {!isAlreadyEnrolled && enrollmentWarning?.length > 0 && <ContactItemDanger data-tip={enrollmentWarning}>
                          <Icon icon='exclamation-triangle' />
                        </ContactItemDanger>}
                      </ContactItemContent>
                    </ContactItem>
                  )
                })}
              </Contacts>}
            </>}
          </>}
        </SidebarContent>
        {emailIntegrationEnabled && <SidebarFooter style={{ marginTop: Style.spacing.x1 }}>
          <Button
            type='success'
            text={t('SequenceEnrollmentSidebar::Enroll contacts')}
            onClick={this.onFormSubmit}
            disabled={(selectedContactIds.length === 0 && groupIds.length === 0)}
            style={{ width: '100%' }}
          />
        </SidebarFooter>}
      </FixedSidebarContainer>
    )
  }
}

export default withRouter(withTranslation()(SequenceEnrollmentSidebar))