import * as React from 'react'
import TimeFormatter from '../../utilities/TimeFormatter'
import NumberFormatter from '../../utilities/NumberFormatter'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import { Style } from '../../styles'
import { useTranslation } from 'react-i18next'
import { TimeEntry, UserWorkspaceSettingScope } from '../../types'

type IProps = {
  entries: TimeEntry[]
  numberFormat: string
  timeFormat: string
  currency: string
}

const EntriesSummary = (props: IProps) => {
  const { t } = useTranslation()
  const { entries, numberFormat, timeFormat, currency } = props

  let nonBillableHours = 0
  let billableHours = 0
  let billableAmount = 0

  entries.forEach(entry => {
    const duration = Number(entry.duration)
    const amount = Number(entry.amount)

    if (entry.billable) {
      billableHours += duration
      billableAmount += amount
    } else {
      nonBillableHours += duration
    }
  })

  return (
    <div style={{ marginTop: Style.spacing.x1, marginBottom: Style.spacing.x1 }}>
      <SummaryContainer>
        <SummaryItem
          title={t('EntriesSummary::Non-billable')}
          label={TimeFormatter.durationFormat(nonBillableHours, timeFormat)}
          labelBig={true}
        />
        <SummaryItem
          title={t('EntriesSummary::Billable')}
          label={TimeFormatter.durationFormat(billableHours, timeFormat)}
          labelBig={true}
        />
        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <SummaryItem
          title={t('EntriesSummary::Billable amount')}
          label={NumberFormatter.formatCurrency(currency, numberFormat, billableAmount)}
          labelBig={true}
        />}
        <SummaryItem
          title={t('EntriesSummary::Logged time')}
          label={TimeFormatter.durationFormat(nonBillableHours + billableHours, timeFormat)}
          labelBig={true}
        />
      </SummaryContainer>
    </div>
  )
}

export default EntriesSummary