import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { useTranslation } from 'react-i18next'
import PowerSelect from '../../Form/PowerSelect'
import ReactSelectTheme from '../../Form/ReactSelectTheme'
import { ClearfactsFormData, ClearfactsIntegrationConfig } from '../../../types'

type IProps = {
  config: ClearfactsIntegrationConfig
  formData: ClearfactsFormData
  onConfigChange: (config: ClearfactsIntegrationConfig) => void
}

interface IState { }

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		color: white;
		margin-bottom: ${Style.spacing.x2};
    font-weight: bold;
	}
	
	label {
		color: white;
	}
`

const ClearfactsForm = (props: IProps) => {
  const { t } = useTranslation()
  const { config, formData, onConfigChange } = props

  const administrationOptions: { label: string, value: string }[] = formData.administrations.map(adminstration => {
    return {
      label: `${adminstration.name} (${adminstration.company_number})`,
      value: adminstration.company_number
    }
  })
  const selectedAdministrationOption = administrationOptions.find(option => option.value === config.company_number)

  const onAdministrationChange = (option) => {
    onConfigChange({
      ...config,
      company_number: option.value,
    })
  }

  return (
    <Container>
      <h1>{t('ClearfactsForm::Settings')}</h1>

      <div className='form-item'>
        <label style={{ color: 'black' }}>
          {t('ClearfactsForm::Choose the administration you wish to synchronize to.')}
        </label>
        <PowerSelect
          options={administrationOptions}
          value={selectedAdministrationOption}
          onChange={onAdministrationChange}
          theme={ReactSelectTheme}
        />
      </div>
    </Container>
  )
}

export default ClearfactsForm