import styled from "styled-components"

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Style } from '../../../styles'
import NumberFormatter from '../../../utilities/NumberFormatter'
import moment from "../../../utilities/Moment"
import YearlyCollectedRevenueGoalTooltip, { MOUSELEAVE_COLLECTED_REVENUE_TIMELINE, MOUSEMOVE_COLLECTED_REVENUE_TIMELINE } from "./YearlyCollectedRevenueGoalTooltip"
import YearlyExpectedRevenueGoalTooltip, { MOUSELEAVE_EXPECTED_REVENUE_TIMELINE, MOUSEMOVE_EXPECTED_REVENUE_TIMELINE } from "./YearlyExpectedRevenueGoalTooltip"
import { FinancialYear } from "../../../types"

const Container = styled.div`
	position: relative;
	width: 100%;
	padding-bottom: 22px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		overflow-x: auto;
	}
`

const Wrapper = styled.div`
	position: relative;
	width: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		min-width: 1100px;
		padding-bottom: 16px;
	}
`

const ProgressbarTopInfo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`

const ProgressbarTopInfoText = styled.div`
	position: relative;
	z-index: 1;
	padding-right: 6px;
	font-size: 14px;
	margin-bottom: 4px;

	strong {
		color: ${Style.color.brandSuccess}
	}

	&::after {
    width: 1px;
    height: 70px;
    background-color: #222222;
    position: absolute;
    top: 6px;
    right: 0;
    content: "";
	}
`

const ProgressbarContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	background: #f4f6f8;
	height: 50px;
	border-radius: 50px;
	overflow: hidden;
	width: 100%;
`

const CollectedRevenue = styled.div`
	height: 100%;
	width: 100%;
	max-width: .5%;
	background-color: ${Style.color.brandSuccess};
	transition: max-width 200ms linear;
`

const ExpectedRevenue = styled.div`
	height: 100%;
	width: 100%;
	max-width: .5%;
	transition: max-width 200ms linear;
	background-image: url('app/images/revenue/bg-stripes@2x.png');
	background-size: 264px 68px;
	background-color: #b3f1d3;
`

const ProgressbarBottomInfo = styled.div``

const ProgressbarBottomInfoTextContainer = styled.div`
	position: absolute;
	z-index: 1;
	transition: left 250ms linear;

	&::before {
    width: 1px;
    height: 70px;
    background-color: #222222;
    position: absolute;
		left: 0;
		bottom: 5px;
    content: "";
		pointer-events: none;
	}
`
const ProgressbarBottomInfoTextWrapper = styled.div`
	position: relative;
	transition: left 250ms linear;
	margin-top: 4px;
	min-height: 22px;
	min-width: 500px;
`

const ProgressbarBottomInfoText = styled.div`
	position: absolute;
	font-size: 14px;
`

const Legend = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 50px;
	height: 100%;
	max-height: 0%;
	transition: 250ms max-height linear;
`

const LegendItem = styled.div`
	flex: 1;
	font-size: 12px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 8px;

	span:last-child {
		font-weight: bold;
	}
`

interface IProps {
	collectedRevenue: number
	correctionRevenue: number
	expectedRevenue: number
	expectedRevenueMetadata: any
	financialYear: FinancialYear
	revenueGoal: number
	currency: string
	numberFormat: string
}

const YearlyRevenueGoal = (props: IProps) => {
	const [animateIn, setAnimateIn] = React.useState(false)
	const collectedRevenueContainer = React.useRef<HTMLDivElement>(null)
	const expectedRevenueContainer = React.useRef<HTMLDivElement>(null)
	const progressbarBottomInfoText = React.useRef<HTMLDivElement>(null)
	const { financialYear, collectedRevenue, correctionRevenue, expectedRevenue, expectedRevenueMetadata, revenueGoal, currency, numberFormat } = props
	const { t } = useTranslation()

	React.useEffect(() => {
		setTimeout(() => {
			setAnimateIn(true)
		}, 250)
	}, [])

	const today = moment()
	const startFinancialYear = moment(financialYear.start_date)
	const endFinancialYear = moment(financialYear.end_date)
	const amountOfDaysInFinancialYear = endFinancialYear.diff(startFinancialYear, 'days')
	const collectedRevenueMaxWidth = (collectedRevenue - correctionRevenue) / revenueGoal * 100
	const expectedRevenueMaxWidth = expectedRevenue / revenueGoal * 100
	const revenuePerDay = revenueGoal / amountOfDaysInFinancialYear
	const amountOfDaysPassed = today.isAfter(endFinancialYear) ? amountOfDaysInFinancialYear : today.diff(startFinancialYear, 'days')
	const expectedEarnedRevenueByNow = amountOfDaysPassed * revenuePerDay
	const expectedEarnedLeftValue = amountOfDaysPassed / amountOfDaysInFinancialYear * 100

	let progressbarBottomInfoTextWidth = null
	if (progressbarBottomInfoText && progressbarBottomInfoText.current) {
		progressbarBottomInfoTextWidth = progressbarBottomInfoText.current.clientWidth
	}

	return (
		<Container>
			<Wrapper>
				<ProgressbarTopInfo>
					<ProgressbarTopInfoText
						dangerouslySetInnerHTML={{
							__html: t('YearlyRevenueGoal::Your revenue goal is <strong>{{revenueGoal}}</strong>', {
								year: new Date().getFullYear(),
								revenueGoal: NumberFormatter.formatCurrency(currency, numberFormat, revenueGoal)
							})
						}}
					/>
				</ProgressbarTopInfo>

				<ProgressbarContainer>
					<CollectedRevenue
						ref={collectedRevenueContainer}
						onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							const { clientX } = event

							if (collectedRevenueContainer.current) {
								const { top } = collectedRevenueContainer.current.getBoundingClientRect()

								document.dispatchEvent(new CustomEvent(MOUSEMOVE_COLLECTED_REVENUE_TIMELINE, {
									detail: {
										collectedRevenue: (collectedRevenue - correctionRevenue),
										x: clientX,
										y: top,
									}
								}))
							}
						}}
						onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							document.dispatchEvent(new CustomEvent(MOUSELEAVE_COLLECTED_REVENUE_TIMELINE, {
								detail: {
									collectedRevenue: null,
									x: null,
									y: null
								}
							}))
						}}
						style={{ maxWidth: `${animateIn ? collectedRevenueMaxWidth : 0}%` }}
					/>
					<ExpectedRevenue
						ref={expectedRevenueContainer}
						onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							const { clientX } = event

							if (expectedRevenueContainer.current) {
								const { top } = expectedRevenueContainer.current.getBoundingClientRect()

								document.dispatchEvent(new CustomEvent(MOUSEMOVE_EXPECTED_REVENUE_TIMELINE, {
									detail: {
										expectedRevenue: expectedRevenue,
										expectedRevenueMetadata: expectedRevenueMetadata,
										x: clientX,
										y: top,
									}
								}))
							}
						}}
						onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							document.dispatchEvent(new CustomEvent(MOUSELEAVE_EXPECTED_REVENUE_TIMELINE, {
								detail: {
									expectedRevenue: null,
									expectedRevenueMetadata: null,
									x: null,
									y: null
								}
							}))
						}}
						style={{ maxWidth: `${animateIn ? expectedRevenueMaxWidth : 0}%` }}
					/>
				</ProgressbarContainer>

				<ProgressbarBottomInfo>
					<ProgressbarBottomInfoTextContainer
						style={{ left: `${animateIn ? expectedEarnedLeftValue : 0}%` }}
					>
						<ProgressbarBottomInfoTextWrapper>
							<ProgressbarBottomInfoText
								ref={progressbarBottomInfoText}
								// 6px is the padding
								style={{ left: `${expectedEarnedLeftValue >= 50 && progressbarBottomInfoTextWidth !== null ? -(progressbarBottomInfoTextWidth + 6) : 6}px` }}
								dangerouslySetInnerHTML={{
									__html: t('YearlyRevenueGoal::Where you should be: <b>{{expectedEarnedRevenue}}</b>', {
										expectedEarnedRevenue: NumberFormatter.formatCurrency(currency, numberFormat, expectedEarnedRevenueByNow)
									})
								}}
							/>
						</ProgressbarBottomInfoTextWrapper>
					</ProgressbarBottomInfoTextContainer>
				</ProgressbarBottomInfo>

				<Legend>
					<LegendItem>
						<span>{t('YearlyRevenueGoal::Collected revenue')} </span>
						<span>{NumberFormatter.formatCurrency(currency, numberFormat, collectedRevenue)}</span>
					</LegendItem>
					{correctionRevenue > 0 && <LegendItem>
						<span>{t('YearlyRevenueGoal::Creditnote correction')} </span>
						<span>{NumberFormatter.formatCurrency(currency, numberFormat, -correctionRevenue)}</span>
					</LegendItem>}
					<LegendItem>
						<span>{t('YearlyRevenueGoal::Projects')}</span>
						<span>{NumberFormatter.formatCurrency(currency, numberFormat, expectedRevenueMetadata.projects)}</span>
					</LegendItem>
					<LegendItem>
						<span>{t('YearlyRevenueGoal::Unbilled time entries')}</span>
						<span>{NumberFormatter.formatCurrency(currency, numberFormat, expectedRevenueMetadata.unbilled_time_entries)}</span>
					</LegendItem>
					<LegendItem>
						<span>{t('YearlyRevenueGoal::Outstanding invoices')}</span>
						<span>{NumberFormatter.formatCurrency(currency, numberFormat, expectedRevenueMetadata.outstanding_invoices)}</span>
					</LegendItem>
					<LegendItem>
						<span>{t('YearlyRevenueGoal::Recurring invoices')}</span>
						<span>{NumberFormatter.formatCurrency(currency, numberFormat, expectedRevenueMetadata.recurring_invoices)}</span>
					</LegendItem>
				</Legend>

				<YearlyCollectedRevenueGoalTooltip
					currency={currency}
					numberFormat={numberFormat}
				/>
				<YearlyExpectedRevenueGoalTooltip
					currency={currency}
					numberFormat={numberFormat}
				/>
			</Wrapper>
		</Container>
	)
}

export default YearlyRevenueGoal