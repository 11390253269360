import * as React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import { Body } from '../Body/Body'
import { AppState } from '../../store'
import moment from '../../utilities/Moment'
import styled from 'styled-components'
import { Style } from '../../styles'
import { useTranslation } from 'react-i18next'
import { CurrentUser, SubscriptionStatus, UserWorkspaceSettingRole } from '../../types'

const BannerContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 5;
	padding: 5px 0;
	background-color: ${Style.color.brandWarning};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: none;
	}

	&.is-danger {
		background-color: ${Style.color.brandDanger};
	}
`

const BannerBody = styled.div`
	font-size: 1em;
	line-height: 30px;
	text-align: center;
	color: #fff;
	font-weight: 500;
`

const BannerAction = styled(Link)`
	position: relative;	
	display: inline-block;
	margin-left: 10px;
	padding: 8px 13px;
	min-width: 80px;
	font-size: 14px;
	font-weight: 600;
	line-height: 1em;
	text-align: center;
	vertical-align: top;
	border-radius: 4px;
	border: none;
	background-color: #f38300;
	color: #fff;
	cursor: pointer;
	transition: background ${Style.animations.baseTransitionSpeed} ease-in-out;

	&:hover {
		background-color: #ff9b27;

		.is-danger & {
			background-color: #a60000;
		}
	}

	.is-danger & {
		background-color: #d90000;
	}
`

interface IStateToProps {
  currentUser: CurrentUser
}

enum BannerType {
  TRIALING = 'TRIALING',
  PAYMENT_FAILED = 'PAYMENT_FAILED'
}

const Banner = (props: IStateToProps) => {
  const { t } = useTranslation()

  const getBannerType = (): BannerType | null => {
    const { currentUser: { workspace } } = props
    const { subscription, card } = workspace

    // Never show banner if user is VIP
    if (workspace.vip) return null

    if (subscription.status === SubscriptionStatus.UNPAID) {
      return BannerType.PAYMENT_FAILED
    } else if (
      subscription.status === SubscriptionStatus.TRIALING &&
      !card && moment().isSameOrBefore(moment.unix(subscription.trial_end))
    ) {
      return BannerType.TRIALING
    }


    return null
  }

  const getBannerClassName = (): string => {
    const bannerType = getBannerType()

    switch (bannerType) {
      case BannerType.PAYMENT_FAILED: return 'is-danger'
      default: return ''
    }
  }

  const getBannerDescription = () => {
    const {
      currentUser: {
        workspace: {
          subscription,
        }
      }
    } = props

    const bannerType = getBannerType()

    switch (bannerType) {
      case BannerType.PAYMENT_FAILED: return t('Banner::Psst... you have an outstanding payment.')
      case BannerType.TRIALING:
        const numberOfDaysDifference = Math.abs(moment().diff(moment.unix(subscription.trial_end), 'day'))
        const numberOfHoursDifference = Math.abs(moment().diff(moment.unix(subscription.trial_end), 'hours'))
        const numberOfMinutesDifference = Math.abs(moment().diff(moment.unix(subscription.trial_end), 'minutes'))
        const numberOfSecondsDifference = Math.abs(moment().diff(moment.unix(subscription.trial_end), 'seconds'))

        if (numberOfDaysDifference > 0) {
          return `${t('Banner::Psst... your trial period ends in')} ${numberOfDaysDifference === 1 ? t('Banner::1 day') : `${numberOfDaysDifference} ${t('Banner::days')}`}.`
        } else if (numberOfHoursDifference > 0) {
          return `${t('Banner::Psst... your trial period ends in')} ${numberOfDaysDifference === 1 ? t('Banner::1 hour') : `${numberOfHoursDifference} ${t('Banner::hours')}`}.`
        } else if (numberOfMinutesDifference > 0) {
          return `${t('Banner::Psst... your trial period ends in')} ${numberOfDaysDifference === 1 ? t('Banner::1 minute') : `${numberOfMinutesDifference} ${t('Banner::minutes')}`}.`
        } else if (numberOfSecondsDifference > 0) {
          return `${t('Banner::Psst... your trial period ends in')} ${numberOfDaysDifference === 1 ? t('Banner::1 second') : `${numberOfMinutesDifference} ${t('Banner::seconds')}`}.`
        }

      default: return ''
    }
  }

  const getBannerAction = () => {
    const {
      currentUser: {
        workspace: {
          subscription,
        }
      }
    } = props

    // Do not show action when not owner (only the owner can manage the subscription)
    if (!UserWorkspaceSettingHelper.hasRole(UserWorkspaceSettingRole.OWNER)) return null

    if (subscription.status === SubscriptionStatus.TRIALING) {
      return (
        <BannerAction to={RouteHelper.process(ERoute.PATH_ACCOUNT_SUBSCRIPTION, { enterPaymentDetails: true })}>
          {t('Banner::Subscribe now')}
        </BannerAction>
      )
    }

    return (
      <BannerAction to={RouteHelper.process(ERoute.PATH_ACCOUNT_SUBSCRIPTION, { enterPaymentDetails: true })}>
        {t('Banner::Update subscription')}
      </BannerAction>
    )

    return null
  }

  const bannerType = getBannerType()

  if (bannerType) {
    return (
      <>
        <Body className='has-banner' />
        <BannerContainer className={`${getBannerClassName()}`}>
          <BannerBody>
            {getBannerDescription()}
            {getBannerAction()}
          </BannerBody>
        </BannerContainer>
      </>
    )
  }

  return null
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

export default connect(mapStateToProps)(Banner)