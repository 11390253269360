import styled from 'styled-components'

const StepHeaderDragGrip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: #919191;

    path {
      fill: #919191;
    }
  }
`

export default StepHeaderDragGrip