import i18n from "../translations";
import { Activity, Contact, User } from "../types";

class ActivityHelper {
  static getName(property: "owner" | "recipient", activity: Activity): string {
    if (activity[property]) {
      if (activity[`${property}_type`] === "User") {
        const user = activity[property] as User;

        return user.name || user.email;
      } else if (activity[`${property}_type`] === "Contact") {
        const contact = activity[property] as Contact;

        return contact.name;
      }
    }

    return i18n.t("ContactHelper::Unknown");
  }
}

export default ActivityHelper;
