import styled from "styled-components";

const ConversationHeaderAction = styled.div`
	color: rgb(116, 121, 142);
	font-size: 24px;
	cursor: pointer;

	svg {
		width: 24px;
		height: 24px;
		fill: rgb(116, 121, 142);
	}

	&:hover {
		filter: brightness(50%);
	}
`

export default ConversationHeaderAction