import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Button from '../Button/Button'
import Icon from '../Icons/Icon'

const Container = styled.div<{ editing: boolean }>`
	background-color: #ebecf0;
	border-radius: 3px;
	height: auto;
	min-height: 32px;
	transition: background 85ms ease-in,opacity 40ms ease-in,border-color 85ms ease-in;
	margin-right: 8px;
	width: 272px;
	min-width: 272px;
	padding: ${Style.spacing.x0_5};

	&:first-child {
		margin-left: ${Style.spacing.x1};
	}

	input {
		height: 36px;
    min-height: 36px;
		margin-bottom: ${Style.spacing.x0_5};
	}

	&:hover {
    color: #172b4d;

		${props => !props.editing && css`
			background-color: rgba(9,30,66,.08);
		`}
  }
`

const Placeholder = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 6px 8px;
	transition: color 85ms ease-in;
	cursor: pointer;

	svg, i {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		color: #42526e;
		margin-right: 4px;
	}
`

const FormActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const ButtonCancel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	cursor: pointer;

	svg {
		width: 24px;
		height: 24px;
		color: #42526e;
		fill: #42526e;
	}
`

interface IProps {
	onSubmit: (name: string) => void
}

const AddBoardList = (props: IProps) => {
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>(null)
	const [editing, setEditing] = React.useState(false)
	const [name, setName] = React.useState('')

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideContainerClick)

		return () => {
			document.removeEventListener('mousedown', onOutsideContainerClick)
		}
	}, [])

	const reset = () => {
		setName('')
		setEditing(false)
	}

	const onOutsideContainerClick = (e) => {
		if (container.current && !container.current.contains(e.target)) {
			// If name has been entered submit
			if (name.length > 0) props.onSubmit(name)

			reset()
		}
	}

	const onPlaceHolderClick = () => {
		setEditing(true)
	}

	const onNameChange = (e) => {
		setName(e.currentTarget.value)
	}

	const onFormSubmit = (e) => {
		e.preventDefault()

		if (name.length > 0) {
			props.onSubmit(name)
			reset()
		}
	}

	const onCancelClick = () => {
		reset()
	}

	return (
		<Container ref={container} editing={editing}>
			{editing === false && <Placeholder onClick={onPlaceHolderClick}>
				<Icon icon='plus' />
				{t('AddBoardList::Add another list')}
			</Placeholder>}

			{editing === true && <form onSubmit={onFormSubmit}>
				<input
					type='text'
					name='name'
					value={name}
					placeholder={t('AddBoardList::Enter list title...')}
					onChange={onNameChange}
					autoComplete='off'
					autoFocus
				/>
				<FormActions>
					<Button
						type='success'
						text={t('AddBoardList::Add list')}
						onClick={onFormSubmit}
					/>

					<ButtonCancel onClick={onCancelClick}>
						<Icon icon='close' />
					</ButtonCancel>
				</FormActions>
			</form>}
		</Container>
	)
}

export default AddBoardList