import styled from "styled-components"

const BlockEditorContainer = styled.div`
	// Froala editor
	.fr-toolbar {
		border: none;
	}

	.fr-wrapper {
		border: none !important;
	}

	.fr-second-toolbar {
		display: none;
	}
`

export default BlockEditorContainer