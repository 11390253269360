import * as React from 'react'

interface IProps {
	progress?: number
	backgroundColor?: string
}

export default class Progressbar extends React.PureComponent<IProps> {
	static defaultProps = {
		progress: 0,
		backgroundColor: '#4bc800',
	}

	render() {
		const {
			progress,
			backgroundColor
		} = this.props

		const progressWidth = Math.min(progress, 100)

		const progressStyle = {
			width: `${progressWidth}%`,
			backgroundColor: backgroundColor
		}

		return (
			<div className={`progress-bar ${progressWidth === 100 ? 'is-completed' : ''}`}>
				<div style={progressStyle}></div>
				<div style={progressStyle}></div>
			</div>
		)
	}
}