import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
  position: relative;
  height: 5px;
  width: 100%;
  border-radius: ${Style.variables.baseBorderRadius};
  background: #e4e4e5;
  overflow: hidden;
`

const Bar = styled.div<{ percent: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: ${Style.variables.baseBorderRadius};
  width: 100%;
  transition: max-width 250ms ease-in-out;

  ${({ percent }) => css`
    max-width: ${percent}%;
  `}
`


const Progressbar = styled(Bar) <{ overbooked?: boolean }>`
  background-color: ${Style.color.brandSuccess};

  ${props => props.overbooked && css`
    background-color: ${Style.color.brandWarning};
  `}
`
const DangerousOverbookedBar = styled(Bar)`
  background-color: ${Style.color.brandDanger};
`


interface IProps {
  availableTime: number
  scheduledTime: number
}

const PlannerMeter = (props: IProps) => {
  const { availableTime, scheduledTime } = props

  let progressBarPercent = (scheduledTime / availableTime) * 100
  let overbookedBarPercent = 0

  if (availableTime === 0 && scheduledTime === 0) {
    progressBarPercent = 0
  }

  if (availableTime === 0 && scheduledTime > 0) {
    overbookedBarPercent = 100
  }
  else if (scheduledTime > availableTime) {
    overbookedBarPercent = ((scheduledTime - availableTime) / availableTime) * 100
  }

  return (
    <Container>
      <Progressbar percent={progressBarPercent} overbooked={overbookedBarPercent > 0} />
      <DangerousOverbookedBar percent={overbookedBarPercent} />
    </Container>
  )
}

export default PlannerMeter