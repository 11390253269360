import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { CalendarEvent } from '../../../types'
import moment from '../../../utilities/Moment'
import Icon from '../../Icons/Icon'
import { TimelineTooltipEvent, TimelineTooltipEventType } from '../../Tooltips/TimelineTooltip'

const LeaveItem = styled.div`
  position: sticky;
  top: 0;
  bottom: 0;
  background: ${Style.color.budgetTrail};
	z-index: 1;
	height: 100%;
`

const LeaveMetadata = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const LeaveType = styled.div``

const LeaveSchedule = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	i {
		font-size: 12px;
	}

	span {
		&:first-child {
			margin-right: 4px;
		}

		&:last-child {
			margin-left: 4px;
		}
	}
`

interface IProps {
	leave: CalendarEvent
}

const ProjectMonthlyTimelineLeaveItem = (props: IProps) => {
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>(null)
	const { leave } = props
	const leaveStartMoment = moment(leave.start)
	const leaveEndMoment = moment(leave.end)
	const left = Number(leaveStartMoment.dayOfYear() / 365 * 100)
	const width = Number(leaveEndMoment.diff(leaveStartMoment, 'days') / 365 * 100)

	return (
		<LeaveItem
			ref={container}
			style={{
				left: `${left}%`,
				width: `calc(${width}% + 1px)`,
			}}
			onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
				if (container.current) {
					const { top, left, width } = container.current.getBoundingClientRect()

					document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSEMOVE, {
						detail: {
							title: leave?.user?.name || leave?.user?.email,
							description: <LeaveMetadata>
								<LeaveType>{t('ProjectMonthlyTimelineLeaveItem::On Leave')}</LeaveType>
								<LeaveSchedule>
									<span>{leaveStartMoment.format('D MMM')}</span>
									<Icon icon='arrow-right' />
									<span>{leaveEndMoment.format('D MMM')}</span>
								</LeaveSchedule>
							</LeaveMetadata>,
							// Place in center
							x: left + width / 2,
							y: top,
							backgroundColor: '#CCC',
						}
					}))
				}
			}}
			onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
				document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSELEAVE, {
					detail: {
						title: null,
						description: null,
						backgroundColor: null,
						textColor: null,
						x: null,
						y: null
					}
				}))
			}}
		/>
	)
}

export default ProjectMonthlyTimelineLeaveItem