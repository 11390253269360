import * as React from 'react'
import { connect } from 'react-redux'
import { Body } from '../components/Body/Body'
import Button from '../components/Button/Button'
import { AppState } from '../store'
import { OnboardingController, SessionController, UserController } from '../controllers'
import Notification from '../utilities/Notification'
import { Dispatch } from 'redux'
import { setCurrentUser } from '../store/authentication/actions'
import { RouteComponentProps } from 'react-router';
import ERoute from '../ERoute';
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import { Helmet } from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'
import { CurrentUser } from '../types'

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  setCurrentUser: typeof setCurrentUser
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<any> & WithTranslation

class ConfirmEmail extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)

    this.onVerifyConfirmedClick = this.onVerifyConfirmedClick.bind(this)
    this.onResendEmailClick = this.onResendEmailClick.bind(this)
    this.navigateToOnboarding = this.navigateToOnboarding.bind(this)
    this.onLogoutClick = this.onLogoutClick.bind(this)
  }

  navigateToOnboarding() {
    const { history } = this.props
    history.push(ERoute.PATH_DASHBOARD)
  }

  componentWillMount() {
    const { currentUser } = this.props
    if (currentUser.email_confirmed_at) {
      this.navigateToOnboarding()
    }
  }

  onVerifyConfirmedClick(e) {
    e.preventDefault()

    const { setCurrentUser, t } = this.props

    UserController
      .me()
      .then(response => {
        const currentUser: CurrentUser = response

        setCurrentUser(currentUser)

        if (currentUser.email_confirmed_at) {
          this.navigateToOnboarding()
        } else {
          Notification.notifyError(t('ConfirmEmail::Account verification required'))
        }
      })
      .catch(console.error)
  }

  onResendEmailClick(e) {
    const { t } = this.props
    e.preventDefault()

    OnboardingController
      .resendConfirmationEmail()
      .then(response => {
        if (response.success) {
          Notification.notifySuccess(t('ConfirmEmail::Activation email send'))
        }
      })
      .catch(console.error)
  }

  onLogoutClick(e) {
    e.preventDefault()

    SessionController
      .logout()
      .then(() => {
        window.location.assign('/')
      })
      .catch(console.error)
  }

  render() {
    const { currentUser, t } = this.props
    return (
      <div className='onboarding-container animated fadeIn'>
        <Helmet>
          <title>{t('ConfirmEmail::{{__appName}} | Confirm email')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <Body className='is-onboarding' />

        <div className='onboarding is-email-confirmation'>
          <div className='onboarding-confirm-icon'>
            <svg width='74' height='49' viewBox='0 0 74 49'><g fill='none' fillRule='nonzero' transform='rotate(-15 -19.17 31.596)'><rect width='50' height='35.191' x='1.5' y='2.328' stroke='currentColor' strokeWidth='3' rx='4' transform='translate(17 21)'></rect><rect width='8' height='1' x='54.5' y='46.5' stroke='currentColor' rx='.5'></rect><rect width='4' height='1' x='58.5' y='50.5' stroke='currentColor' rx='.5'></rect><rect width='14' height='3' y='36' fill='currentColor' rx='1.5'></rect><path fill='currentColor' d='M6.5 42h6a1.5 1.5 0 0 1 0 3h-6a1.5 1.5 0 0 1 0-3z'></path><path stroke='currentColor' strokeWidth='3' d='M20.732 23.673a2.692 2.692 0 0 0 0 3.808l19.941 11.941c1.052 1.052 4.757 1.052 5.808 0l19.941-11.941a2.692 2.692 0 0 0 0-3.808'></path></g></svg>
          </div>

          <h3 className='onboarding-confirm-title'>
            {t('ConfirmEmail::Activation e-mail send')}
          </h3>
          <p className='onboard-confirm-description' dangerouslySetInnerHTML={{
            __html: `
            ${t('ConfirmEmail::To activate your account, press <b>"Verify my account"</b> in your inbox. Your e-mail is <b>{{email}}</b>', { email: currentUser.email })}
					`}}>
          </p>

          <Button
            type='primary'
            text={t('ConfirmEmail::I\'ve verified my account')}
            onClick={this.onVerifyConfirmedClick}
          />

          <a href='javascript://' className='onboarding-confirm-resend' onClick={this.onResendEmailClick}>
            {t('ConfirmEmail::Send again')}
          </a>

          <a href='javascript://' className='onboarding-confirm-resend' onClick={this.onLogoutClick}>
            {t('ConfirmEmail::Logout')}
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const { authentication: { currentUser } } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    setCurrentUser: (user: CurrentUser) => dispatch(setCurrentUser(user)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmEmail))
