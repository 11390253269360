import * as React from 'react'
import { GroupNavigationMenuItem, NavigationMenuItemType } from '../../types'
import Icon from '../Icons/Icon'
import SidebarNavigationItemDropdownIndicator from './SidebarNavigationItemDropdownIndicator'
import SidebarNavigationItemLink from './SidebarNavigationItemLink'
import SidebarNavigationSubItemLink from './SidebarNavigationSubItemLink'
import SidebarNavigationSubItems from './SidebarNavigationSubItems'

interface IProps {
	item: GroupNavigationMenuItem
}

const SidebarGroupNavigationItem = (props: IProps) => {
	const [active, setActive] = React.useState(false)
	const { item } = props

	const toggleActive = () => {
		setActive(!active)
	}

	return (
		<>
			<SidebarNavigationItemLink href='javascript://' onClick={toggleActive} className={active ? 'is-active' : ''}>
				<Icon icon='layer-group' />
				<span>{item.label}</span>
				<SidebarNavigationItemDropdownIndicator />
			</SidebarNavigationItemLink>

			{item.items && item.items.length > 0 && <SidebarNavigationSubItems>
				{item.items.map((subnavigationItem, subNavigationItemIndex) => {
					if (!(subnavigationItem.type === NavigationMenuItemType.LINK)) return null

					return (
						<li key={subNavigationItemIndex}>
							<SidebarNavigationSubItemLink href={subnavigationItem.value} target='_blank'>
								{subnavigationItem.label}
							</SidebarNavigationSubItemLink>
						</li>
					)
				})}
			</SidebarNavigationSubItems>}
		</>
	)
}

export default SidebarGroupNavigationItem