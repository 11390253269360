import * as React from 'react'
import styled, { css } from 'styled-components';
import { Contact, ContactType } from '../../types';
import ContactHelper from '../../helpers/ContactHelper';

interface IProps {
	contact?: Contact
	rounded?: boolean
	width?: number
	onClick?: () => void
}

interface ContainerProps {
	rounded?: boolean
	width: number
	onClick?: () => void
	type?: ContactType
}

const Container = styled.div`
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;

	${(props: ContainerProps) => css`
		width: ${props.width}px;
		min-width: ${props.width}px;
		height: ${props.width}px;
		min-height: ${props.width}px;
	`}
	border: 2px solid transparent;
	transition: border-color .3s;
	border-radius: 7px;
	overflow: hidden;

	${(props: ContainerProps) => props.rounded && css`
		border-radius: 50%;
	`}

	${(props: ContainerProps) => props.onClick && css`
		cursor: pointer;
	`}

	${props => props.type === ContactType.COMPANY && css`
		background-color: #FFD3BA;
		color: #C65D21;

		svg, i {
			fill: currentColor;
			color: currentColor;
		}
	`}

	${props => props.type === ContactType.PERSON && css`
	 background-color: #B6E0FE;
		svg, i {
			color: #2680C2;
		}
	`}
	
	svg {
		width: ${props => props.width / 2.3}px;
		height: ${props => props.width / 2.3}px;
	}

	i {
		font-size: ${props => props.width / 2.3}px;
	}
`

export default class ContactTypeAvatar extends React.PureComponent<IProps> {
	static defaultProps = {
		width: 40
	}

	render() {
		const { contact, rounded, width, onClick } = this.props

		const label = ContactHelper.getContactLabelType(contact.type)

		return (
			<Container
				rounded={rounded}
				width={width}
				onClick={onClick}
				data-tip={label}
				type={contact.type}
			>
				{ContactHelper.getContactTypeIcon(contact)}
			</Container>
		)

	}
}