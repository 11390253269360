import styled, { css } from 'styled-components';
import { Style } from '../../styles';

const ReportSummaryPercentIndicatorInfoColor = styled.div<{ color?: string }>`
  display: flex;
  width: 14px;
  height: 14px;
  margin-right: ${Style.spacing.x0_5};
  background: #ccc;
  border-radius: 3px;

  ${props => props.color && css`
    background: ${props.color};
  `}
`

export default ReportSummaryPercentIndicatorInfoColor