import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import moment from '../../../utilities/Moment'
import NumberFormatter from '../../../utilities/NumberFormatter'
import ExpenseMonthlyItem from './ExpenseMonthlyItem'
import { ExpensesController } from '../../../controllers'
import Loader from '../../Loaders/Loader'
import { Moment } from 'moment'
import { Settings } from '../../../types'

const ResponsiveContainer = styled.div`
	padding-top: 10px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		overflow-x: scroll;
		margin-bottom: ${Style.spacing.x2};
	}
`
const Container = styled.div`
	position: relative;
	height: 240px;
	width: 100%;
	background: white;
	margin: 50px 0;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 1100px;
	}
`

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const GraphContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

interface IProps {
  setting: Settings
  onAddExpenseClick: (date: Moment) => void
}

interface IState {
  didInitialLoad: boolean
  isLoading: boolean
  data: { date: string, values: { type: 'actual' | 'estimated', category: string, amount: number, color: string }[] }[]
}

export default class ExpenseMonthlyTimeline extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      isLoading: false,
      data: [],
    }
  }

  componentDidMount() {
    this.fetchTimeline()
  }

  fetchTimeline() {
    this.setState({ isLoading: true })

    ExpensesController
      .getTimeline('monthly')
      .then(response => {
        this.setState({
          didInitialLoad: true,
          isLoading: false,
          data: response.result.data,
        })
      })
      .catch(console.error)
  }

  refresh() {
    this.fetchTimeline()
  }

  render() {
    const { setting, onAddExpenseClick } = this.props
    const { didInitialLoad, isLoading, data } = this.state

    const values = data.map(d => d.values.reduce((total, value) => Number(total) + Number(value.amount), 0))
    let maxValue = Math.max(...values)
    maxValue += maxValue * 10 / 100

    return (
      <ResponsiveContainer>
        <Container>
          {(!didInitialLoad || isLoading) && <LoadingOverlay>
            <Loader size='medium' />
          </LoadingOverlay>}

          <GraphContainer>
            {data.map((dataItem, index) => {
              const total = dataItem.values.reduce((total, value) => Number(total) + Number(value.amount), 0)

              let topTick = ''
              if (total <= 1000) {
                topTick = `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, total, { forcePrecision: 2 })}`
              } else {
                topTick = `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, total / 1000, { forcePrecision: 2 })}K`
              }
              const height = total > 0 ? total / maxValue * 100 : 0
              const date = moment(dataItem.date, 'YYYY-MM-DD')
              const bottomTick = date.format('MMM')

              return (
                <ExpenseMonthlyItem
                  key={index}
                  topTick={topTick}
                  height={`${height}%`}
                  bottomTick={bottomTick}
                  total={total}
                  data={dataItem.values}
                  setting={setting}
                  onAddExpenseClick={() => onAddExpenseClick(date)}
                />
              )
            })}
          </GraphContainer>
        </Container>
      </ResponsiveContainer>
    )
  }
}