import * as React from 'react'
import Icon from '../Icons/Icon'
import ExpenseTicket from './ExpenseTicket'
import { ExpensesController } from '../../controllers'
import Notification from '../../utilities/Notification'
import Loader from '../Loaders/Loader'
import ExpenseTicketPlaceholder from './ExpenseTicketPlaceholder'
import { Trans, Translation } from 'react-i18next'
import ExpenseTicketDropzone from './ExpenseTicketDropzone'
import { Expense, MimeTypes, Settings } from '../../types'

interface IProps {
  onAddClick: (e: React.MouseEvent<HTMLDivElement>) => void
  onExpenseClick: (expense: Expense) => void
  setting: Settings
  allowedFileTypes: MimeTypes[]
  onFilesDropped: (files: any[]) => void
}

interface IState {
  expenses: Expense[]
  currentPage: number
  reachedEnd: boolean
  isLoading: boolean
  isUploading: boolean
}

export default class ExpensesLastUpdated extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      expenses: [],
      currentPage: 1,
      reachedEnd: false,
      isLoading: false,
      isUploading: false,
    }

    this.onDrop = this.onDrop.bind(this)
    this.onListScroll = this.onListScroll.bind(this)
  }

  componentDidMount() {
    this.fetchLastUpdatedExpenses(1)

    if (this.container.current) this.container.current.addEventListener('scroll', this.onListScroll)
  }

  componentWillUnmount() {
    if (this.container.current) this.container.current.removeEventListener('scroll', this.onListScroll)
  }

  setUploading(isUploading) {
    this.setState({
      isUploading: isUploading,
    })
  }

  processFiles(files) {
    return Object.values(files)
  }

  onDrop(acceptedFiles) {
    const { onFilesDropped } = this.props

    if (onFilesDropped) {
      onFilesDropped(acceptedFiles)
    }
  }

  onListScroll(e) {
    const { reachedEnd, isLoading } = this.state
    const container = this.container.current
    const scrollLeft = container.scrollLeft
    const scrollWidth = container.scrollWidth
    const clientWidth = container.clientWidth
    const scrollEndReached = Math.abs(scrollLeft) === scrollWidth - clientWidth

    if (scrollEndReached && !reachedEnd && !isLoading) {
      const { currentPage } = this.state
      this.fetchLastUpdatedExpenses(currentPage + 1)
    }
  }

  refresh() {
    this.fetchLastUpdatedExpenses(1)
  }

  fetchLastUpdatedExpenses(page = 1) {
    this.setState({ isLoading: true })

    ExpensesController
      .getExpenses({ page: page, order: 'updated_at_desc', per_page: 10 })
      .then((response) => {
        const { expenses: stateExpenses } = this.state;
        const { expenses, current_page, total_pages } = response

        this.setState({
          expenses: page > 1 ? [...stateExpenses, ...expenses] : expenses,
          reachedEnd: current_page === total_pages,
          currentPage: current_page,
          isLoading: false,
        });
      })
      .catch(console.error)
  }

  render() {
    const {
      setting,
      onAddClick,
      onExpenseClick,
    } = this.props
    const { expenses, isLoading, isUploading } = this.state

    return (
      <div>
        <Translation>
          {
            (t, { i18n }) => <>
              <h3 style={{ fontWeight: 500 }}>{t('ExpensesLastUpdated::Recently updated')}</h3>
              <div ref={this.container} className='expense-tickets'>
                <ExpenseTicketDropzone
                  uploading={isUploading}
                  onDrop={this.onDrop}
                />

                {(expenses.length === 0 || (expenses.length === 0 && isLoading)) && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(placeholder => {
                  return (
                    <ExpenseTicketPlaceholder
                      key={placeholder}
                    />
                  )
                })}

                {expenses.map(expense => {
                  return (
                    <ExpenseTicket
                      key={expense.id}
                      expense={expense}
                      setting={setting}
                      onClick={onExpenseClick}
                    />
                  )
                })}
              </div>
            </>
          }
        </Translation>

      </div>
    )
  }
}