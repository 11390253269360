import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import { ActiveStorageController, SettingsController, WorkspaceController } from '../../controllers'
import { ISettingsForm } from '../../controllers/SettingsController'
import Notification from '../../utilities/Notification'
import { updateSettings, updateWorkspace } from '../../store/authentication/actions'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { CurrentUser, DisplayableError, Settings, Workspace } from '../../types';
import { Style } from '../../styles';
import Title from '../../components/Settings/Title';
import Subtitle from '../../components/Settings/Subtitle';
import AvatarEditor from '../../components/Avatar/AvatarEditor';
import styled from 'styled-components';
import PowerSelect from '../../components/Form/PowerSelect';

const AvatarContainer = styled.div`
  width: 100%;
  justify-content: center;
  align: center;
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  updateWorkspace: typeof updateWorkspace
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  setting: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class CompanyBranding extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace } } = props
    const { setting } = workspace

    this.state = {
      setting: setting,
      errors: [],
      form: null
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onBrandColorChange = this.onBrandColorChange.bind(this)
    this.onLogoChange = this.onLogoChange.bind(this)
    this.onLogoDeleteClick = this.onLogoDeleteClick.bind(this)
  }

  componentDidMount(): void {
    this.fetchForm()
  }

  async fetchForm() {
    try {
      const form = await SettingsController.getForm()
      this.setState({ form: form })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e) {
    e.preventDefault()
    const { setting } = this.state
    const { updateWorkspace, updateSettings, t } = this.props

    try {
      let errors: DisplayableError[] = []

      const settingResponse = await SettingsController.update(setting)

      if (settingResponse.errors) {
        errors = [...settingResponse.errors]
      }
      else {
        const setting = settingResponse as Settings

        updateSettings(setting)

        this.setState({
          setting: setting
        });
      }

      if (errors.length > 0) {
        Notification.notifyError(t('CompanyBranding::Oops something went wrong'));
        this.setState({ errors: errors })
      } else {
        Notification.notifySuccess(t('CompanyBranding::Settings successfully updated'));
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onBrandColorChange(option) {
    const { setting } = this.state

    try {
      const updatedSetting: Settings = { ...setting, company_brand_color: option.value }
      const response = await SettingsController.update({ ...updatedSetting, })
      this.setState({ setting: response })
      this.props.updateSettings(response)
    } catch (ex) {
      console.error(ex)
    }
  }

  onLogoChange(acceptedFiles) {
    const { currentUser: { workspace } } = this.props

    const logo = acceptedFiles[0];

    try {
      ActiveStorageController.uploadPublic(logo, async (error, blob) => {
        if (error) { console.error(error) }
        if (blob) {
          const updatedWorkspace = await WorkspaceController.update({ ...workspace, business_logo: blob.signed_id })
          this.props.updateWorkspace(updatedWorkspace)
        }
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onLogoDeleteClick() {
    const { currentUser: { workspace } } = this.props;

    try {
      const updatedWorkspace = await WorkspaceController.update({ ...workspace, business_logo: null })
      this.props.updateWorkspace(updatedWorkspace)
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const { t, currentUser: { workspace } } = this.props
    const { form, setting } = this.state

    if (form) {
      const options: { label: string, value: string }[] = [
        { label: t('CompanyBranding::Default blue (recommended)'), value: '#4389fc' },
        { label: t('CompanyBranding::Green'), value: '#409142' },
        { label: t('CompanyBranding::Grey'), value: '#595959' },
        { label: t('CompanyBranding::Magenta'), value: '#A23DAD' },
        { label: t('CompanyBranding::Orange'), value: '#C65D21' },
        { label: t('CompanyBranding::Purple'), value: '#653CAD' },
        { label: t('CompanyBranding::Red'), value: '#BA2525' },
        { label: t('CompanyBranding::Teal'), value: '#17B897' },
        { label: t('CompanyBranding::Pink'), value: '#C42D78' },
      ]
      const selectedOption = options.find(option => option.value === setting.company_brand_color)
      return (
        <>
          <Helmet>
            <title>{t('CompanyBranding::{{__appName}} | Company branding')}</title>
          </Helmet>
          <ScrollToTopOnMount />
          <form onSubmit={this.onFormSubmit}>
            <Title>{t('CompanyBranding::Company branding')}</Title>
            <p style={{ marginBottom: Style.spacing.x2 }}>
              {t('CompanyBranding::You can use these tools to customize the colors and appearance of your account, including uploading a logo to display in your sidebar and on printed PDF reports from the CRM.')}
            </p>

            <Subtitle>{t('CompanyBranding::Colors')}</Subtitle>
            <Panel>
              <div className='form-item'>
                <label>{t('CompanyBranding::Color scheme')}</label>
                <div className='grid'>
                  <div className='grid-cell with-4col'>
                    <PowerSelect
                      options={options}
                      value={selectedOption}
                      onChange={this.onBrandColorChange}
                    />
                  </div>
                </div>
              </div>
            </Panel>

            <Subtitle>{t('CompanyBranding::Company logo')}</Subtitle>
            <Panel>
              <p style={{ marginBottom: 16 }}>
                <Trans>
                  This image will appear at the top left of the CRM instead. We’ll also include it when you print things like documents and reports.
                </Trans>
              </p>

              <AvatarContainer>
                <AvatarEditor
                  uri={workspace.business_logo_url}
                  onChange={this.onLogoChange}
                  onDelete={this.onLogoDeleteClick}
                />
              </AvatarContainer>
            </Panel>
          </form>
        </>
      )
    } else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    updateWorkspace: (workspace: Workspace) => dispatch(updateWorkspace(workspace)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyBranding))