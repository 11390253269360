import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'
import DocumentWrapper from '../LedgerItem/DocumentWrapper'
import DocumentPreview from '../LedgerItem/DocumentPreview'

interface IProps {
	source: string
	contentType: string
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px solid #DFE3E8;
	border-radius: 3px;
	background: #F5F6F8;
	background-size: contain;
	background-position: center;
	min-height: 300px;
	background-repeat: no-repeat;
	padding: ${Style.spacing.x2};
`

const PDFContainer = styled.div`
	canvas {
		width: 100% !important;
		height: 100% !important;
	}
`

export default class AttachmentPreview extends React.PureComponent<IProps> {
	render() {
		const { source, contentType } = this.props

		if (source) {
			if (['image/jpeg', 'image/png', 'image/jpg'].includes(contentType)) {
				return (
					<Container style={{ backgroundImage: `url(${source})` }} />
				)
			} else if (contentType === 'application/pdf') {
				return (
					<Container>
						<PDFContainer>
							<DocumentWrapper>
								{({ width, height }) => {
									return (
										<DocumentPreview
											file={source}
											currentPage={1}
											width={width}
											onLoadSuccess={() => { }}
										/>
									)
								}}
							</DocumentWrapper>
						</PDFContainer>
					</Container>
				)
			}
		}

		return null
	}
}