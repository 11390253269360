import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { MimeTypes } from '../../types'
import Icon from '../Icons/Icon'
import { Style } from '../../styles'

const Container = styled.div<{ centered: boolean }>`
	position: relative;
	display: flex;
	width: 100%;

	${props => props.centered && css`
		justify-content: center;
		align-items: center;
	`}
`

const UploadContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p {
		font-size: 11px;

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			text-align: center;
		}
	}
`

const UploadWrapper = styled.div`
	border: 2px dashed ${Style.color.border};
	background: #f4f4f8;
	width: 202.5px;
	height: 105px;
	border-radius: 6px;
	margin: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	text-decoration-line: underline;
	cursor: pointer;
`

const Preview = styled.img`
	max-width: 150px;
	margin-right: 20px;
	cursor: pointer;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-right: 0;
		margin-bottom: 20px;
	}
`

const PreviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

const PreviewActions = styled.div`
	display: flex;
	flex-direction: column;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		justify-content: center;
		align-items: center;
	}
`

const PreviewAction = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	margin-bottom: ${Style.spacing.x1};

	svg, i {
		color: #829AB1;
	}

	a {
		color: rgb(3,121,201);
		text-decoration: underline;
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		text-align: center;
	}
`

const PreviewActionImage = styled.div`
	margin-right: 10px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: none;
	}
`

interface IProps {
	className?: string
	uri?: string
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	onDelete: () => void
}

const AvatarEditor = (props: IProps) => {
	const { uri, onChange, onDelete } = props
	const { t } = useTranslation()

	const onDrop = (acceptedFiles) => {
		onChange(acceptedFiles)
	}

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop: onDrop,
		multiple: false,
		accept: [
			MimeTypes.JPG,
			MimeTypes.JPEG,
			MimeTypes.PNG,
			MimeTypes.SVG
		],
	})

	const onOpenFileDialogClick = () => {
		open()
	}

	const onDeleteClick = () => {
		onDelete()
	}

	return (
		<Container centered={!Boolean(uri)}>
			{!uri && <UploadContainer {...getRootProps()}>
				<UploadWrapper>
					{t('AvatarEditor::Add photo')}
				</UploadWrapper>
				<p>{t('AvatarEditor::Or drag-and-drop the image anywhere on this field')}</p>
			</UploadContainer>}
			{uri && <PreviewContainer>
				<Preview src={uri} {...getRootProps} onClick={onOpenFileDialogClick} />
				<PreviewActions>
					<PreviewAction onClick={onOpenFileDialogClick}>
						<PreviewActionImage>
							<Icon icon='image' />
						</PreviewActionImage>
						<Trans>
							<div><a>Select a new image</a> <span>(or drag-and-drop to update the image)</span></div>
						</Trans>
					</PreviewAction>
					<PreviewAction onClick={onDeleteClick}>
						<PreviewActionImage>
							<Icon icon='trash' />
						</PreviewActionImage>
						<Trans>
							<a>Remove this image</a>
						</Trans>
					</PreviewAction>
				</PreviewActions>
			</PreviewContainer>}
			<input {...getInputProps()} />
		</Container>
	)
}

export default AvatarEditor