
import styled from 'styled-components'
import BlockEditorSidebarSection from './BlockEditorSidebarSection'
import { Style } from '../../styles'

const BlockEditorSidebarActionSection = styled(BlockEditorSidebarSection)`
	position: sticky;
	bottom: 0;
	background: white;
	border-top: 1px solid ${Style.color.border};
`

export default BlockEditorSidebarActionSection