import { Board, Deal, DealStage, Task } from "../../types";
import {
  DealActionTypes,
  SET_INITIAL_DEALS_BOARD_STATE,
  DEAL_CREATE,
  DEAL_UPDATE,
  DEAL_DELETE,
  DEAL_STAGE_CREATE,
  DEAL_STAGE_UPDATE,
  DEAL_STAGE_DELETE,
  DealsBoardState,
} from "./types";

export function setInitialDealsState(state: DealsBoardState): DealActionTypes {
  return {
    type: SET_INITIAL_DEALS_BOARD_STATE,
    state: state,
  };
}

export function createDeal(deal: Deal): DealActionTypes {
  return {
    type: DEAL_CREATE,
    deal: deal,
  };
}

export function updateDeal(deal: Deal): DealActionTypes {
  return {
    type: DEAL_UPDATE,
    deal: deal,
  };
}

export function deleteDeal(id: string): DealActionTypes {
  return {
    type: DEAL_DELETE,
    id: id,
  };
}

export function createDealStage(dealStage: DealStage): DealActionTypes {
  return {
    type: DEAL_STAGE_CREATE,
    dealStage: dealStage,
  };
}

export function updateDealStage(dealStage: DealStage): DealActionTypes {
  return {
    type: DEAL_STAGE_UPDATE,
    dealStage: dealStage,
  };
}

export function deleteDealStage(id: string): DealActionTypes {
  return {
    type: DEAL_STAGE_DELETE,
    id: id,
  };
}
