import styled from "styled-components";
import { Style } from "../../styles";

const MenuEditorItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${Style.spacing.x1};
  border: 1px solid ${Style.color.border};
  background: white;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  width: 100%;

  &:last-child {
    margin-bottom: -1px;
  }
`

export default MenuEditorItemContainer