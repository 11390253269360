import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Style } from "../../styles"
import NumberFormatter from "../../utilities/NumberFormatter"
import TimelineTooltip, { TimelineTooltipEvent, TimelineTooltipEventType } from "../Tooltips/TimelineTooltip"
import Icon from '../Icons/Icon'
import { BudgetData, ProjectBudgetType } from '../../types'

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    overflow-x: auto;
    overflow-y: visible;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const BudgetIndicator = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 1px;
  height: 60px;
  background-color: #222222;
  z-index: 1;
  bottom: 0;
	pointer-events: none;
`

const AlertIndicator = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 1px;
  height: 60px;
  background-color: #222222;
  z-index: 1;
  bottom: top;
	pointer-events: none;
`

const IndicatorTextContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const BudgetIndicatorText = styled.div`
  position: absolute;
  top: -20px;
  font-size: 14px;
  white-space: nowrap;
`

const AlertIndicatorText = styled.div`
  position: absolute;
  bottom: -25px;
  font-size: 14px;
  white-space: nowrap;
`

const ProgressbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background: #f4f6f8;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  width: 100%;
`

const ProgressBarItem = styled.div`
  height: 100%;
  width: 100%;
  max-width: .5%;
  background-color: ${Style.color.brandSuccess};
  transition: max-width 200ms linear;
`

interface IProps {
	data: BudgetData
	budgetAlertTreshold?: number
	currency: string
	numberFormat: string
}

const ProjectBudget = (props: IProps) => {
	const MARGIN_MULTIPLIER = 1.3
	const [animateIn, setAnimateIn] = React.useState(false)
	const timeEntriesContainer = React.useRef<HTMLDivElement>(null)
	const expensesContainer = React.useRef<HTMLDivElement>(null)
	const hoursContainer = React.useRef<HTMLDivElement>(null)
	const { data, currency, numberFormat } = props
	const { t } = useTranslation()

	React.useEffect(() => {
		setTimeout(() => {
			setAnimateIn(true)
		}, 250)
	}, [])

	const { budget_alert_treshold: budgetAlertTreshold, remaining_budget, type: budgetType } = data

	const budget = Number(data.budget)

	const renderBudgetCosts = () => {
		if (budgetType !== ProjectBudgetType.TOTAL_COSTS) return null


		const labourCosts = Number(data.labour)
		const expenseCosts = Number(data.expenses)
		const budgetExceeded = budget < (labourCosts + expenseCosts)
		// If costs exceed the budget use the costs as base for the budget
		const budgetWithMargin = (budgetExceeded ? (labourCosts + expenseCosts) : budget) * MARGIN_MULTIPLIER

		const budgetIndicatorLeft = (Number(budget) / budgetWithMargin) * 100

		const timeEntriesWidth = (data.labour / budgetWithMargin) * 100
		const expenseWidth = (data.expenses / budgetWithMargin) * 100

		let alertIndicatorLeft = null

		if (budgetAlertTreshold) {
			alertIndicatorLeft = ((budget * budgetAlertTreshold) / budgetWithMargin) * 100
		}

		return (
			<>
				<BudgetIndicator
					style={{ left: `${budgetIndicatorLeft}%` }}
				>
					<IndicatorTextContainer>
						<BudgetIndicatorText>
							<strong>{NumberFormatter.formatCurrency(currency, numberFormat, budget)}</strong>
						</BudgetIndicatorText>
					</IndicatorTextContainer>
				</BudgetIndicator>
				{budgetAlertTreshold && <AlertIndicator
					style={{ left: `${alertIndicatorLeft}%` }}
				>
					<IndicatorTextContainer>
						<AlertIndicatorText>
							{t('ProjectBudget::Alert at')} <strong>{NumberFormatter.formatCurrency(currency, numberFormat, budget * budgetAlertTreshold)}</strong> <span><Icon icon='exclamation-triangle' /></span>
						</AlertIndicatorText>
					</IndicatorTextContainer>
				</AlertIndicator>}
				<ProgressbarContainer>
					{/* Employee cost */}
					<ProgressBarItem
						ref={timeEntriesContainer}
						onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							const { clientX } = event

							if (timeEntriesContainer.current) {
								const { top } = timeEntriesContainer.current.getBoundingClientRect()

								document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSEMOVE, {
									detail: {
										title: t('ProjectBudget::Labour'),
										description: NumberFormatter.formatCurrency(currency, numberFormat, data.labour),
										x: clientX,
										y: top,
										backgroundColor: '#4a9a9d',
									}
								}))
							}
						}}
						onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSELEAVE, {
								detail: {
									title: null,
									description: null,
									backgroundColor: null,
									textColor: null,
									x: null,
									y: null
								}
							}))
						}}
						style={{
							maxWidth: `${animateIn ? timeEntriesWidth : 0}%`,
							backgroundColor: '#4a9a9d'
						}}
					/>
					{/* Expenses */}
					<ProgressBarItem
						ref={expensesContainer}
						onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							const { clientX } = event

							if (expensesContainer.current) {
								const { top } = expensesContainer.current.getBoundingClientRect()

								document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSEMOVE, {
									detail: {
										title: t('ProjectBudget::Expenses'),
										description: NumberFormatter.formatCurrency(currency, numberFormat, data.expenses),
										x: clientX,
										y: top,
										backgroundColor: '#f7627c',
										textColor: 'white',
									}
								}))
							}
						}}
						onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSELEAVE, {
								detail: {
									title: null,
									description: null,
									backgroundColor: null,
									textColor: null,
									x: null,
									y: null
								}
							}))
						}}
						style={{
							maxWidth: `${animateIn ? expenseWidth : 0}%`,
							backgroundColor: '#f7627c'
						}}
					/>
				</ProgressbarContainer>
			</>
		)
	}

	const renderBudgetHours = () => {
		if (budgetType !== ProjectBudgetType.TOTAL_HOURS) return null

		const hours = Number(data.hours)
		const budgetExceeded = budget < hours
		// If hours exceed the budget use the hours as base for the budget
		const budgetWithMargin = (budgetExceeded ? hours : budget) * MARGIN_MULTIPLIER
		const budgetIndicatorLeft = (budget / budgetWithMargin) * 100

		const hoursWidth = (hours / budgetWithMargin) * 100

		let alertIndicatorLeft = null

		if (budgetAlertTreshold) {
			alertIndicatorLeft = ((budget * budgetAlertTreshold) / budgetWithMargin) * 100
		}

		return (
			<>
				<BudgetIndicator
					style={{ left: `${budgetIndicatorLeft}%` }}
				>
					<IndicatorTextContainer>
						<BudgetIndicatorText>
							<strong>{t('ProjectBudget::{{hours}} hrs', { hours: NumberFormatter.formatNumber(numberFormat, budget) })}</strong>
						</BudgetIndicatorText>
					</IndicatorTextContainer>
				</BudgetIndicator>
				{budgetAlertTreshold && <AlertIndicator
					style={{ left: `${alertIndicatorLeft}%` }}
				>
					<IndicatorTextContainer>
						<AlertIndicatorText>
							{t('ProjectBudget::Alert at')} <strong>{NumberFormatter.formatNumber(numberFormat, budget * budgetAlertTreshold)} {t('ProjectBudget::hrs')}</strong> <span><Icon icon='exclamation-triangle' /></span>
						</AlertIndicatorText>
					</IndicatorTextContainer>
				</AlertIndicator>}
				<ProgressbarContainer>
					{/* Hours */}
					<ProgressBarItem
						ref={hoursContainer}
						onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							const { clientX } = event

							if (hoursContainer.current) {
								const { top } = hoursContainer.current.getBoundingClientRect()

								document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSEMOVE, {
									detail: {
										title: t('ProjectBudget::Hours'),
										description: NumberFormatter.formatNumber(numberFormat, data.hours),
										x: clientX,
										y: top,
										backgroundColor: Style.color.brandSuccess,
									}
								}))
							}
						}}
						onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSELEAVE, {
								detail: {
									title: null,
									description: null,
									backgroundColor: null,
									textColor: null,
									x: null,
									y: null
								}
							}))
						}}
						style={{
							maxWidth: `${animateIn ? hoursWidth : 0}%`,
							backgroundColor: Style.color.brandSuccess
						}}
					/>
				</ProgressbarContainer>
			</>
		)
	}

	return (
		<Container>
			<Wrapper>
				{budgetType === ProjectBudgetType.TOTAL_COSTS && renderBudgetCosts()}
				{budgetType === ProjectBudgetType.TOTAL_HOURS && renderBudgetHours()}
				<TimelineTooltip />
			</Wrapper>
		</Container>
	)
}

export default ProjectBudget
