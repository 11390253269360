import * as React from 'react'
import { Style } from '../../styles'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MarketplaceIntegration } from '../../types'

const Container = styled.div<{ backgroundColor: string }>`
	flex: 1;
	min-width: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	cursor: pointer;
	transition: box-shadow 0.1s ease;
	position: relative;
	overflow: hidden;
	flex-direction: column;
	height: 100px;
	background-color: rgbstring(233, 229, 220);
	max-width: calc((100% - 48px) / 4);
	border: 1px solid #e1e1e1;

	@media screen and (max-width: ${Style.breakpoints.SMALL}){
		max-width: initial;
		width: 100%;
		min-height: 140px;
		margin-bottom: ${Style.spacing.x2};
	}

	${(props) => props.backgroundColor && css`
		background-color: ${props.backgroundColor};
	`}

	&:not(:last-child) {
		margin-right: ${Style.spacing.x2};

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			margin-right: 0;
		}
	}

	&:before {
		content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0);
    transition: background 0.1s ease;
	}

	&:hover {
		&:before {
			background: rgba(0, 0, 0, 0.2);
		}
	}
`

const Indicator = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	background: rgba(255, 255, 255, 0.7);
	border-radius: 0px 0px 8px 0px;
	color: #333;
	padding: 2px 5px;
	font-size: 11px;
	font-weight: 400;
	min-width: 75px;
	text-align: center;
`

const Logo = styled.div`
	width: 60%;
	height: 60%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	position: relative;

	@media screen and (max-width: ${Style.breakpoints.SMALL}){
		max-width: 200px;
		height: 60px;
	}
`

interface IProps {
	integration: MarketplaceIntegration
	onClick: () => void
}

const IntegrationCard = (props: IProps) => {
	const { integration, onClick } = props
	const { t } = useTranslation()

	const { theme: { cover }, connected } = integration

	let indicatorText = ''
	if (connected) {
		indicatorText = t('IntegrationCard::Connected')
	} else if (integration.new) {
		indicatorText = t('IntegrationCard::New')
	}

	return (
		<Container backgroundColor={cover} onClick={onClick}>
			{indicatorText && <Indicator>{indicatorText}</Indicator>}
			<Logo style={{ backgroundImage: `url('${integration.image}')` }} />
		</Container>
	)
}

export default IntegrationCard