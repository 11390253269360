import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import { DEFAULT_LOCALE, INVOICE_PAYMENT_DURATIONS } from '../../Constants'
import Panel from '../../components/Panel/Panel'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import Alert from '../../components/Alert/Alert'
import LedgerItemHelper from '../../helpers/LedgerItemHelper'
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import Tooltip from '../../components/Tooltips/Tooltip';
import CheckboxInput from '../../components/Form/CheckboxInput';
import EditorContainer from '../../components/Editor/EditorContainer';
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../components/Editor/Editor';
import LocaleHelper from '../../helpers/LocaleHelper';
import PowerSelect from '../../components/Form/PowerSelect';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import MobilityHelper from '../../helpers/MobilityHelper';
import { CurrentUser, DisplayableError, Locale, Settings } from '../../types';
import ResourceModalTable from '../../components/Resource/Modal/ResourceModalTable';
import ResourceModalTableRow from '../../components/Resource/Modal/ResourceModalTableRow';
import ResourceModalTableRowHeader from '../../components/Resource/Modal/ResourceModalTableRowHeader';
import ResourceModalTableRowData from '../../components/Resource/Modal/ResourceModalTableRowData';
import ResourceModalTableRowActions from '../../components/Resource/Modal/ResourceModalTableRowActions';
import moment from '../../utilities/Moment';
import { showConfirmModal, showFinancialYearModal } from '../../store/modals/actions';
import { FinancialYearController } from '../../controllers';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  showFinancialYearModal: typeof showFinancialYearModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  activeTranslationsLocale: Locale
  errors: DisplayableError[]
  form: ISettingsForm | null
  isUpdating: boolean
}

class Invoices extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      activeTranslationsLocale: DEFAULT_LOCALE,
      form: null,
      isUpdating: false,
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onDefaultLedgerItemLocaleChange = this.onDefaultLedgerItemLocaleChange.bind(this)
    this.onAddFinancialYearClick = this.onAddFinancialYearClick.bind(this)
    this.onEditFinancialYearClick = this.onEditFinancialYearClick.bind(this)
    this.onDeleteFinancialYearClick = this.onDeleteFinancialYearClick.bind(this)
    this.onTogglePoNumberEnabled = this.onTogglePoNumberEnabled.bind(this)
    this.onInvoicePrefixNumberChange = this.onInvoicePrefixNumberChange.bind(this)
    this.onInvoiceNumberChange = this.onInvoiceNumberChange.bind(this)
    this.onInvoiceSuffixNumberChange = this.onInvoiceSuffixNumberChange.bind(this)
    this.onInvoiceRemindersEnabledClick = this.onInvoiceRemindersEnabledClick.bind(this)
    this.onInvoiceReminderIntervalChange = this.onInvoiceReminderIntervalChange.bind(this)
    this.onInvoiceReminderLimitChange = this.onInvoiceReminderLimitChange.bind(this)
    this.onInvoicePaidConfirmationEnabledClick = this.onInvoicePaidConfirmationEnabledClick.bind(this)
    this.onInvoicePaymentDurationChange = this.onInvoicePaymentDurationChange.bind(this)
    this.onTranslationsLocaleChange = this.onTranslationsLocaleChange.bind(this)
    this.onInvoiceDetailsChange = this.onInvoiceDetailsChange.bind(this)
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    this.setState({ isUpdating: true })
    SettingsController
      .update(settings)
      .then(response => {
        // @ts-ignore
        if (response.errors) {
          Notification.notifyError(t('SettingInvoices::Oops something went wrong.'));

          this.setState({
            errors: response.errors
          })
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update local
          this.setState({ settings: settings })
          // Update global
          updateSettings(settings)

          Notification.notifySuccess(t('SettingInvoices::Settings updated successfully'));
        }
      })
      .catch(console.error)
      .finally(() => {
        this.setState({ isUpdating: false })
      })
  }

  onDefaultLedgerItemLocaleChange(e) {
    const newLocale = e.currentTarget.value

    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        default_ledger_item_locale: newLocale
      }
    })
  }

  onAddFinancialYearClick() {
    const { form } = this.state

    this.props.showFinancialYearModal({
      financialYear: {},
      onSubmit: (financialYear) => {
        const financialYears = [...form.financial_years]

        this.setState({
          form: {
            ...form,
            financial_years: [
              financialYear,
              ...financialYears
            ]
          }
        })
      }
    })
  }

  onEditFinancialYearClick(financialYear) {
    const { form } = this.state

    this.props.showFinancialYearModal({
      financialYear: { id: financialYear.id },
      onSubmit: (financialYear) => {
        const financialYears = [...form.financial_years]
        const financialYearIndex = financialYears.findIndex(t => t.id === financialYear.id);

        if (financialYearIndex !== -1) {
          financialYears[financialYearIndex] = financialYear

          this.setState({
            form: {
              ...form,
              financial_years: [...financialYears]
            }
          })
        }
      }
    })
  }

  onDeleteFinancialYearClick(financialYear) {
    const { t } = this.props
    const { form } = this.state

    this.props.showConfirmModal({
      title: t('SettingInvoices::Delete financial year'),
      description: t('SettingInvoices::You are about to delete this financial year. This financial year will be permanently deleted. Are you sure?'),
      action: { label: t('SettingInvoices::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          await FinancialYearController.delete(financialYear.id)

          const financialYears = [...form.financial_years]
          const taxIndex = financialYears.findIndex(t => t.id === financialYear.id);

          financialYears.splice(taxIndex, 1);

          this.setState({
            form: {
              ...form,
              financial_years: [...financialYears]
            }
          })
        } catch (ex) {
          console.error(ex)
        }
      }
    })

  }

  onTogglePoNumberEnabled() {
    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        po_number_enabled: !settings.po_number_enabled
      }
    })
  }

  onInvoicePrefixNumberChange(e) {
    const prefixInvoiceNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        prefix_invoice_number: prefixInvoiceNumber
      },
    });
  }

  onInvoiceNumberChange(e) {
    const quotationNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        invoice_number: quotationNumber,
      },
    });
  }

  onInvoiceSuffixNumberChange(e) {
    const suffixInvoiceNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        suffix_invoice_number: suffixInvoiceNumber,
      },
    });
  }

  onInvoiceRemindersEnabledClick() {
    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        invoice_reminders_enabled: !settings.invoice_reminders_enabled
      }
    })
  }

  onInvoicePaidConfirmationEnabledClick() {
    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        invoice_paid_confirmation_enabled: !settings.invoice_paid_confirmation_enabled
      }
    })
  }

  onInvoiceReminderIntervalChange(e) {
    const { settings } = this.state
    const interval = e.currentTarget.value

    this.setState({
      settings: {
        ...settings,
        invoice_reminders_interval: interval,
      }
    })
  }

  onInvoiceReminderLimitChange(e) {
    const { settings } = this.state
    const limit = e.currentTarget.value

    this.setState({
      settings: {
        ...settings,
        invoice_reminders_limit: limit,
      }
    })
  }

  onInvoicePaymentDurationChange(e) {
    const paymentDuration = e.currentTarget.value;

    const { settings } = this.state;

    settings.invoice_payment_duration = paymentDuration;

    this.setState({
      settings: settings
    });
  }

  onTranslationsLocaleChange(option) {
    this.setState({ activeTranslationsLocale: option.value });
  }

  onInvoiceDetailsChange(details: string) {
    const { settings, activeTranslationsLocale } = this.state;

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationsLocale, settings.translations, 'default_invoice_details', details)

    this.setState({
      settings: {
        ...settings,
        translations: { ...updatedTranslations }
      }
    });
  }

  render() {
    const { t } = this.props
    const { settings, errors, form, isUpdating, activeTranslationsLocale } = this.state

    if (form) {
      const localeOptions = LocaleHelper.getLocaleOptions()
      const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

      return (
        <>
          <Helmet>
            <title>{t('SettingInvoices::{{__appName}} | Invoices')}</title>
          </Helmet>
          <ScrollToTopOnMount />

          <form onSubmit={this.onFormSubmit}>
            <Title>{t('SettingInvoices::Invoices')}</Title>

            <Panel>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingInvoices::Default invoice language')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.default_ledger_item_locale} onChange={this.onDefaultLedgerItemLocaleChange}>
                        {localeOptions.map(locale => {
                          return (
                            <option key={locale.value} value={locale.value}>{locale.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingInvoices::Invoice number format')}</label>
                    <div style={{ marginBottom: 8 }}>
                      <Alert
                        type='info'
                        text={<Trans t={t}>
                          <div>The number of your next invoice or credit note is "<b>{{ prefix_invoice_number: settings.prefix_invoice_number }}{{ invoice_number: LedgerItemHelper.getSuccessiveLedgerNumber(settings.invoice_number) }}{{ suffix_invoice_number: settings.suffix_invoice_number }}</b>"</div>
                        </Trans>}
                      />
                    </div>

                    <div className='settings-numbering-format'>
                      <input
                        type='text'
                        name='prefix'
                        placeholder={t('SettingInvoices::Prefix')}
                        value={settings.prefix_invoice_number}
                        onChange={this.onInvoicePrefixNumberChange}
                      />
                      <input
                        type='number'
                        name='number'
                        placeholder='1'
                        value={settings.invoice_number}
                        onChange={this.onInvoiceNumberChange}
                        pattern='\d*'
                        min={0}
                        required
                      />
                      <input
                        type='text'
                        name='suffix'
                        placeholder={t('SettingInvoices::Suffix')}
                        value={settings.suffix_invoice_number}
                        onChange={this.onInvoiceSuffixNumberChange}
                      />
                    </div>
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='grid'>
                    <div className='grid-cell with-4col'>
                      <div className='form-item'>
                        <label>{t('SettingInvoices::PO number')}</label>
                        <CheckboxInput
                          onChange={this.onTogglePoNumberEnabled}
                          checked={settings.po_number_enabled}
                          label={t('SettingInvoices::Enable')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <div className='grid'>
                      <div className='grid-cell with-4col'>
                        <label>
                          {t('SettingInvoices::Invoice reminders')}
                          <Tooltip
                            content={t('SettingInvoices::With invoice reminders we\'ll automatically nudge your contacts about outstanding invoices so you don\'t have to.')}
                            containerStyle={{ marginLeft: 8 }}
                          />
                        </label>
                        <CheckboxInput
                          onChange={this.onInvoiceRemindersEnabledClick}
                          checked={settings.invoice_reminders_enabled}
                          label={t('SettingInvoices::Enabled')}
                        />
                      </div>
                      {settings.invoice_reminders_enabled && <>
                        <div className='grid-cell with-4col'>
                          <label>
                            {t('SettingInvoices::Send reminder every (days)')}
                          </label>
                          <input
                            type='number'
                            value={settings.invoice_reminders_interval}
                            min={1}
                            onChange={this.onInvoiceReminderIntervalChange}
                          />
                        </div>
                        <div className='grid-cell with-4col'>
                          <label>
                            {t('SettingInvoices::Limit')}
                            <Tooltip
                              content={t('SettingInvoices::Maximum {{count}} reminders', { count: 3 })}
                              containerStyle={{ marginLeft: 8 }}
                            />
                          </label>
                          <input
                            type='number'
                            value={settings.invoice_reminders_limit}
                            min={1}
                            max={3}
                            onChange={this.onInvoiceReminderLimitChange}
                          />
                        </div>
                      </>}
                      <div className='grid-cell with-3col'></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-4col'>
                  <div className='form-item'>
                    <label>
                      {t('SettingInvoices::Invoice payment confirmation')}
                      <Tooltip
                        content={t('SettingInvoices::With invoice payment confirmation enabled we\'ll automatically send a confirmation email to your contacts when they paid an invoice. Additionaly you can add assets to the confirmation mail which should only be released when the invoice is paid.')}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </label>
                    <CheckboxInput
                      onChange={this.onInvoicePaidConfirmationEnabledClick}
                      checked={settings.invoice_paid_confirmation_enabled}
                      label={t('SettingInvoices::Enabled')}
                    />
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingInvoices::Default payment terms')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.invoice_payment_duration} onChange={this.onInvoicePaymentDurationChange}>
                        {INVOICE_PAYMENT_DURATIONS.map(duration => {
                          return (
                            <option key={duration} value={duration}>{t(`InvoicePaymentDurations::${duration}`)}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingInvoices::Default invoice footnote')}</label>
                    <PowerSelect
                      options={localeOptions}
                      value={selectedLocaleOption}
                      onChange={this.onTranslationsLocaleChange}
                      noOptionsMessage={(value) => t('SettingsDeliveryNotes::No language found.')}
                      theme={ReactSelectTheme}
                    />

                    <EditorContainer style={{ marginTop: 8 }}>
                      <Editor
                        model={MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'default_invoice_details')}
                        onModelChange={this.onInvoiceDetailsChange}
                        config={{
                          ...MINIMAL_EDITOR_CONFIG,
                          heightMin: 120,
                          heightMax: 120
                        }}
                      />
                    </EditorContainer>
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingInvoices::Financial years')}</label>
                    {form.financial_years.length > 0 && <ResourceModalTable>
                      <ResourceModalTableRow header={true}>
                        <ResourceModalTableRowHeader>
                          {t('Invoices::Name')}
                        </ResourceModalTableRowHeader>
                        <ResourceModalTableRowHeader>
                          {t('Invoices::Start date')}
                        </ResourceModalTableRowHeader>
                        <ResourceModalTableRowHeader>
                          {t('Invoices::End date')}
                        </ResourceModalTableRowHeader>
                        <ResourceModalTableRowHeader style={{ width: 40 }} />
                      </ResourceModalTableRow>

                      {form?.financial_years.map((financialYear, index) => (
                        <ResourceModalTableRow key={index}>
                          <ResourceModalTableRowData>
                            {financialYear.name}
                          </ResourceModalTableRowData>
                          <ResourceModalTableRowData>
                            {moment(financialYear.start_date).format(settings.date_format)}
                          </ResourceModalTableRowData>
                          <ResourceModalTableRowData>
                            {moment(financialYear.end_date).format(settings.date_format)}
                          </ResourceModalTableRowData>
                          {financialYear.id && <ResourceModalTableRowActions
                            actions={[
                              { key: 'edit', icon: 'edit-solid', content: t('Invoices::Edit'), onClick: () => this.onEditFinancialYearClick(financialYear) },
                              { key: 'delete', icon: 'trash-alt-solid', content: t('Invoices::Delete'), destructive: true, onClick: () => this.onDeleteFinancialYearClick(financialYear) },
                            ]}
                          />}
                        </ResourceModalTableRow>
                      ))}
                    </ResourceModalTable>}

                    <div className='form-table-footer' style={{ border: 'none' }}>
                      <Button type='default' icon='plus' text={t('Invoices::Add financial year')} onClick={this.onAddFinancialYearClick} />
                    </div>
                  </div>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
              <div className='field-actions'>
                <input type='submit' style={{ display: 'none' }} />
                <div className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('SettingInvoices::Save')} isLoading={isUpdating} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>
      )
    }
    else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showFinancialYearModal: (options) => dispatch(showFinancialYearModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Invoices))