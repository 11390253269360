import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import Button from '../../components/Button/Button'
import Panel from '../../components/Panel/Panel'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ContactGroup, CurrentUser, DisplayableError, Locale, Settings } from '../../types';
import ResourceModalTable from '../../components/Resource/Modal/ResourceModalTable';
import ResourceModalTableRow from '../../components/Resource/Modal/ResourceModalTableRow';
import ResourceModalTableRowHeader from '../../components/Resource/Modal/ResourceModalTableRowHeader';
import ResourceModalTableRowData from '../../components/Resource/Modal/ResourceModalTableRowData';
import ResourceModalTableRowActions from '../../components/Resource/Modal/ResourceModalTableRowActions';
import { showConfirmModal, showContactGroupModal } from '../../store/modals/actions';
import Title from '../../components/Settings/Title';
import ContactGroupController from '../../controllers/ContactGroupsController';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  showContactGroupModal: typeof showContactGroupModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  contactGroups: ContactGroup[]
  settings: Settings
  errors: DisplayableError[]
}

class Contacts extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      contactGroups: [],
      errors: [],
    }

    this.onAddContactGroupClick = this.onAddContactGroupClick.bind(this)
    this.onEditContactGroupClick = this.onEditContactGroupClick.bind(this)
    this.onDeleteContactGroupClick = this.onDeleteContactGroupClick.bind(this)
  }

  componentWillMount() {
    this.fetchContactGroups().catch(console.error)
  }

  async fetchContactGroups() {
    try {
      const { contact_groups } = await ContactGroupController.getContactGroups({})

      this.setState({ contactGroups: contact_groups })
    } catch (ex) {
      console.error(ex)
    }
  }

  onAddContactGroupClick() {
    this.props.showContactGroupModal({
      contactGroup: {},
      onSubmit: (contactGroup) => {
        const contactGroups = [...this.state.contactGroups]
        this.setState({
          contactGroups: [
            contactGroup,
            ...contactGroups
          ]
        })
      }
    })
  }

  onEditContactGroupClick(contactGroup) {
    this.props.showContactGroupModal({
      contactGroup: { id: contactGroup.id },
      onSubmit: (contactGroup) => {
        const contactGroups = [...this.state.contactGroups]
        const contactGroupIndex = contactGroups.findIndex(t => t.id === contactGroup.id);

        if (contactGroupIndex !== -1) {
          contactGroups[contactGroupIndex] = contactGroup

          this.setState({
            contactGroups: [...contactGroups]
          })
        }
      }
    })
  }

  onDeleteContactGroupClick(contactGroup) {
    const { t } = this.props

    this.props.showConfirmModal({
      title: t('ContactGroupsModal::Delete contact group'),
      description: t('ContactGroupsModal::You are about to delete this contact group. Are you sure?'),
      action: { label: t('ContactGroupsModal::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          await ContactGroupController.delete(contactGroup.id)

          const contactGroups = [...this.state.contactGroups]
          const contactGroupIndex = contactGroups.findIndex(t => t.id === contactGroup.id);

          contactGroups.splice(contactGroupIndex, 1);

          this.setState({ contactGroups: [...contactGroups] })
        } catch (ex) {
          console.error(ex)
        }
      }
    })

  }

  render() {
    const { t } = this.props
    const { contactGroups, errors } = this.state

    if (contactGroups) {
      return (
        <>
          <Helmet>
            <title>{t('SettingContacts::{{__appName}} | Contacts')}</title>
          </Helmet>
          <ScrollToTopOnMount />

          <Title>{t('SettingContacts::Contacts')}</Title>

          <Panel>
            <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>{t('SettingContacts::Groups')}</label>
                {contactGroups.length > 0 && <ResourceModalTable>
                  <ResourceModalTableRow header={true}>
                    <ResourceModalTableRowHeader stickyTop='0px' sticky>
                      {t('Contacts::Name')}
                    </ResourceModalTableRowHeader>
                    <ResourceModalTableRowHeader stickyTop='0px' sticky>
                      {t('Contacts::Contact count')}
                    </ResourceModalTableRowHeader>
                    <ResourceModalTableRowHeader style={{ width: 40 }} stickyTop='0px' sticky />
                  </ResourceModalTableRow>

                  {contactGroups.map((contactGroup, index) => (
                    <ResourceModalTableRow key={index}>
                      <ResourceModalTableRowData>
                        {contactGroup.name}
                      </ResourceModalTableRowData>
                      <ResourceModalTableRowData>
                        {contactGroup.contact_count}
                      </ResourceModalTableRowData>
                      {contactGroup.id && <ResourceModalTableRowActions
                        actions={[
                          { key: 'edit', icon: 'edit-solid', content: t('Contacts::Edit'), onClick: () => this.onEditContactGroupClick(contactGroup) },
                          { key: 'delete', icon: 'trash-alt-solid', content: t('Contacts::Delete'), destructive: true, onClick: () => this.onDeleteContactGroupClick(contactGroup) },
                        ]}
                      />}
                    </ResourceModalTableRow>
                  ))}
                </ResourceModalTable>}

                <div className='form-table-footer' style={{ border: 'none' }}>
                  <Button type='default' icon='plus' text={t('Contacts::Add group')} onClick={this.onAddContactGroupClick} />
                </div>
              </div>
            </div>
          </Panel>
        </>
      )
    }
    else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showContactGroupModal: (options) => dispatch(showContactGroupModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Contacts))