import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ProposalsTable from '../Proposals/ProposalsTable'

type IProps = {
	contactId?: string
	projectId?: string
}

const ProjectProposals = (props: IProps) => {
	const { contactId, projectId } = props

	return (
		// @ts-ignore
		<ProposalsTable contactId={contactId} projectId={projectId} primaryActionEnabled />
	)
}

export default ProjectProposals