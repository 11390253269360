import '../translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { SENTRY_DSN } from '../Constants'
import { Helmet } from 'react-helmet'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import BlocksRenderer, { BlockRenderMode } from '../components/BlockEditor/BlocksRenderer'
import BlocksWrapper from '../components/BlockEditor/BlocksWrapper'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import AppLoader from '../components/Loaders/AppLoader'
import i18next from 'i18next'
import styled from 'styled-components'
import { Style } from '../styles'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { ContentBlockVariables, Form, Locale, NumberFormat } from '../types'

Sentry.init({ dsn: SENTRY_DSN })

const NoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 40px;
  font-weight: bold;
  font-size: 20px;
  height: 100vh;
  text-align: center;

  > a {
    display: block;
    color: ${Style.color.brandPrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
`

const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 230px;
  background: rgba(0,0,0,0) center center no-repeat;
  background-size: cover;
`
interface IProps {
  form: Form
  variables: ContentBlockVariables
  numberFormat: NumberFormat
  currency: string
  locale: Locale
  recaptcha: {
    siteKey: string,
    action: string
  }
}

const Application = (props: IProps) => {
  const { recaptcha } = props
  const [form, setForm] = React.useState(props.form)
  const formRef = React.useRef<HTMLFormElement>(null)
  const recaptchaTokenInput = React.useRef<HTMLInputElement>()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()

  React.useEffect(() => {
    i18next.changeLanguage(props.locale, (err, t) => { });
  }, [])

  const onFormSubmit = async (e) => {
    // Prevent default submission
    e.preventDefault()

    if (!isSubmitting) {
      setIsSubmitting(true)
      try {
        if (!executeRecaptcha) {
          console.log('Recaptcha not yet available');
          return;
        }

        const token = await executeRecaptcha(recaptcha.action);

        // Add token to form before submitting the form
        if (recaptchaTokenInput.current) recaptchaTokenInput.current.value = token

        // Submit after recaptcha token has been added to the form
        if (formRef.current) formRef.current.submit();
      } catch (ex) {
        console.error(ex)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{form && form.name ? `Bizzey | ${form.name}` : `Bizzey`}</title>
      </Helmet>

      <ScrollToTopOnMount />

      {!form.submittable && <NoticeContainer>
        {t('Forms::Form submission disabled. Contact the form owner.')}

        <a href='https://www.bizzey.com'>
          {t('Forms::Go back')}
        </a>
      </NoticeContainer>}

      {form.submittable && <Container>
        <BackgroundContainer />
        <BlocksWrapper className='preview form'>
          <form ref={formRef} method='POST' action={`/f/${form.id}`} onSubmit={onFormSubmit}>
            <input ref={recaptchaTokenInput} type='hidden' name='g-recaptcha-response' id='recaptcha-token' />
            <input type='hidden' name='g-recaptcha-action' value={recaptcha.action} />
            <input type='text' name='_gotcha' style={{ display: 'none' }} />

            <BlocksRenderer
              renderMode={BlockRenderMode.EDITOR}
              blocks={form.content_blocks}
              variables={props.variables}
              currency={props.currency}
              numberFormat={props.numberFormat}
              onItemsBlockItemSelectionChange={undefined}
            />
          </form>
        </BlocksWrapper>
      </Container >}
      <ReactTooltip effect='solid' />
    </>
  );
}


const ApplicationProvider = (props: IProps) => {
  const { recaptcha } = props
  return (
    <React.Suspense fallback={<AppLoader />}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptcha.siteKey}>
        <Application {...props} />
      </GoogleReCaptchaProvider>
    </React.Suspense>
  )
}

export default ApplicationProvider