import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import ReactTooltip from 'react-tooltip'
import { SequenceStep, SequenceStepType } from '../../types'
import AddSequenceStep from './AddSequenceStep'
import Stepheader from './StepHeader'
import SequenceHelper from '../../helpers/SequenceHelper'
import Email from './Steps/Email'
import ActionList, { IActionListItem } from '../ActionList/ActionList'
import Task from './Steps/Task'
import ResourceTableRowDataLink from '../Resource/ResourceTableRowDataLink'
import { Style } from '../../styles'
import Popover from '../Popover/Popover'
import DurationInput from '../Form/DurationInput'
import { useDebouncedCallback } from 'use-debounce'
import moment from '../../utilities/Moment'
import Icon from '../Icons/Icon'
import TaskButton from '../TaskModal/TaskButton'

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 1px solid #c4cdd5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-left: 1px solid #c4cdd5;
  border-right: 1px solid #c4cdd5;
  border-bottom: 1px solid #c4cdd5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: white;
`



export interface EditBlockProps<T> {
  step: T
  onChange: (step: T) => void
}

const StepContainerWrapper = styled.div`	`

const StepTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: ${Style.spacing.x1};
`

const Delay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Style.spacing.x1};

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ActionsContainer = styled.div`
  svg {
    width: 14px;
    height: 14px;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Style.spacing.x0_5};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const DelayForm = styled.form`
  padding: ${Style.spacing.x1};
  min-width: 280px;
  width: 280px;
  background: white;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};

  .form-item {
    margin-bottom: 0;
  }
`

interface IProps {
  index: number
  step: SequenceStep
  steps: SequenceStep[]
  editable: boolean
  onAddStepClick: (step: SequenceStep) => void
  onEditClick: (step: SequenceStep, itemIndex?: number) => void
  onDeleteClick: (step: SequenceStep) => void
  onStepChange: (step: SequenceStep) => void
}

interface IState {
  open: boolean
  showDelayPopover: boolean
  delayEstimate: number
  showActionsPopover: boolean

}

const Step = (props: IProps) => {
  const {
    index,
    step,
    steps,
    editable,
    onAddStepClick,
    onStepChange,
  } = props
  const { t } = useTranslation()
  const [state, setState] = React.useState<IState>({
    open: true,
    delayEstimate: step.delay,
    showDelayPopover: false,
    showActionsPopover: false,
  })
  const { open, showDelayPopover, delayEstimate, showActionsPopover } = state

  const stepInfo = SequenceHelper.getSequenceStepTypeInfos().find((info) => info.type === step.type)
  const executionDay = SequenceHelper.getSequenceStepExecutionDay(steps, index)

  React.useEffect(() => {
    setState({ ...state, delayEstimate: step.delay })
  }, [step.delay])

  const onToggleOpen = () => {
    setState({ ...state, open: !state.open })
  }

  const onDeleteClick = () => {
    ReactTooltip.hide()
    props.onDeleteClick(step)
  }

  const onEditClick = () => {
    props.onEditClick(step)
  }

  const onEmailEditClick = (itemIndex: number) => {
    props.onEditClick(step, itemIndex)
  }

  const onDelayPopoverClick = () => {
    if (!editable) return
    setState({ ...state, showDelayPopover: true })
  }

  const onDelayPopoverClose = () => {
    setState({ ...state, showDelayPopover: false })
  }

  const onDelayChange = (estimate: number) => {
    setState({ ...state, delayEstimate: estimate })
  }

  const onDelaySubmit = (e) => {
    e?.preventDefault()
    e.stopPropagation()

    onStepChange({ ...step, delay: delayEstimate || 0 })
    setState({ ...state, showDelayPopover: false })
  }

  const onDelayCancel = () => {
    setState({ ...state, delayEstimate: step.delay, showDelayPopover: false })
  }

  const onActionsPopoverClick = () => {
    if (!editable) return

    setState({ ...state, showActionsPopover: true })
  }

  const onActionsPopoverClose = () => {
    setState({ ...state, showActionsPopover: false })
  }

  const renderStepContent = () => {
    switch (step.type) {
      case SequenceStepType.AUTOMATED_EMAIL:
        return (
          <Email
            step={step}
            onEditClick={onEmailEditClick}
            onStepChange={onStepChange}
            editable={editable}
          />
        )
      case SequenceStepType.MANUAL_EMAIL:
      case SequenceStepType.CALL:
      case SequenceStepType.TASK:
        return (
          <Task
            step={step}
            onEditClick={onEditClick}
            editable={editable}
          />
        )
      default: return null
    }
  }

  const menuActions: IActionListItem[] = [
    {
      key: 'edit',
      icon: 'edit-solid',
      content: t('Step::Edit'),
      onClick: () => {
        setState({ ...state, showActionsPopover: false })
        onEditClick()
      },
      visible: step.type !== SequenceStepType.AUTOMATED_EMAIL,
    },
    {
      key: 'delete',
      icon: 'trash-alt-solid',
      content: t('Step::Delete'),
      destructive: true,
      onClick: () => {
        setState({ ...state, showActionsPopover: false })
        onDeleteClick()
      },
    }
  ]

  return (
    <>
      <Draggable
        key={step.id}
        draggableId={step.id}
        index={index}
      >
        {(draggableProvided, draggableSnapshot) => (
          <StepContainerWrapper
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            {<StepTopContainer>
              {index !== 0 && <Delay>
                {t('Step::Delay:')}
                <Popover
                  activator={
                    <ResourceTableRowDataLink onClick={onDelayPopoverClick}>
                      {step.delay > 0 && <>
                        {t('Step::{{count}} days', { count: moment.duration(step.delay, 'second').days() })}
                      </>}
                      {step.delay === 0 && t('Step::No delay')}
                    </ResourceTableRowDataLink>
                  }
                  active={showDelayPopover}
                  onClose={onDelayPopoverClose}
                  placement='bottom'
                >
                  <DelayForm onSubmit={onDelaySubmit}>
                    <div className='form-item'>
                      <label>{t('Step::Days')}</label>
                      <DurationInput
                        value={delayEstimate}
                        onChange={onDelayChange}
                        placeholder={t('Step::1 day / 5 days / 1 week')}
                      />
                      <TaskButton
                        onClick={onDelaySubmit}
                        center
                        success
                        style={{ marginTop: 12 }}
                      >
                        {t('Step::Submit')}
                      </TaskButton>
                      <TaskButton
                        onClick={onDelayCancel}
                        center
                      >
                        {t('Step::Cancel')}
                      </TaskButton>
                    </div>
                    <input type='submit' style={{ display: 'none' }} />
                  </DelayForm>
                </Popover>
              </Delay>}
              {index === 0 && <div />}

              {editable && <ActionsContainer>
                <Popover
                  activator={
                    <ResourceTableRowDataLink onClick={onActionsPopoverClick}>
                      {t('Step::Actions')} <Icon icon='chevron-down' />
                    </ResourceTableRowDataLink>
                  }
                  active={showActionsPopover}
                  onClose={onActionsPopoverClose}
                  placement='bottom-end'
                >
                  <ActionList actions={menuActions} />
                </Popover>
              </ActionsContainer>}
            </StepTopContainer>}
            <StepContainer>
              <Stepheader
                open={open}
                dragHandleProps={draggableProvided.dragHandleProps}
                icon={stepInfo?.icon}
                iconColor={stepInfo?.color}
                title={`${t('StepHeader::Day {{day}}', { day: executionDay })}: ${stepInfo?.title}`}
                onToggleOpen={onToggleOpen}
                editable={editable}
              />
              {open && renderStepContent()}
            </StepContainer>
            {editable && <AddSequenceStep
              index={index}
              steps={steps}
              onAddClick={onAddStepClick}
            />}
            {!editable && <div style={{ height: 10 }} />}
          </StepContainerWrapper>)}
      </Draggable >
    </>
  )
}

export default Step