import { Accountant, UserWorkspaceSetting } from '../types'
import Api from './Api'

export default class TeamController extends Api {
	static getTeam(): Promise<{ team: UserWorkspaceSetting[], accountants: Accountant[] }> {
		return fetch('/api/team', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static addAccountant(email: string, password: string) {
		return fetch('/api/team/add_accountant', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ email, password })
		})
			.then(res => res.json())
	}

	static removeAccountant() {
		return fetch('/api/team/remove_accountant', {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}
}