import * as React from 'react'
import { SubscriptionController } from '../../controllers'
import moment from '../../utilities/Moment'
import Badge from '../Badge/Badge'
import PageLoader from '../Page/PageLoader'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Settings, BizzeyInvoice } from '../../types'
import ResourceModalTable from '../Resource/Modal/ResourceModalTable'
import ResourceModalTableRow from '../Resource/Modal/ResourceModalTableRow'
import ResourceModalTableRowHeader from '../Resource/Modal/ResourceModalTableRowHeader'
import ResourceModalTableRowData from '../Resource/Modal/ResourceModalTableRowData'
import ResourceTableRowDataLink from '../Resource/ResourceTableRowDataLink'
import { saveAs } from 'file-saver'

type IProps = {
  settings: Settings
} & WithTranslation

interface IState {
  didInitialLoad: boolean
  invoices: BizzeyInvoice[],
  currentPage: number,
  hasMore: boolean
  isFetching: boolean
}

class SubscriptionInvoices extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      didInitialLoad: false,
      currentPage: 1,
      invoices: [],
      hasMore: true,
      isFetching: false,
    }

    this.onEndReached = this.onEndReached.bind(this)
    this.onDownloadInvoiceClick = this.onDownloadInvoiceClick.bind(this)
  }

  componentDidMount() {
    this.fetchInvoices(1)
  }


  async fetchInvoices(page: number = 1) {
    const { invoices: stateInvoices, isFetching, currentPage } = this.state

    if (!isFetching) {
      this.setState({ isFetching: true })
      try {
        const { bizzey_invoices, current_page, total_pages } = await SubscriptionController.getInvoices({ page: page })

        this.setState({
          didInitialLoad: true,
          hasMore: current_page < total_pages,
          currentPage: currentPage,
          invoices: [
            ...stateInvoices,
            ...bizzey_invoices,
          ],
        })
      } catch (ex) {
        console.error(ex)
      } finally {
        this.setState({ isFetching: false })
      }
    }
  }

  onEndReached() {
    const { hasMore, currentPage } = this.state

    if (hasMore) {
      this.fetchInvoices(currentPage + 1)
    }
  }

  async onDownloadInvoiceClick(id: string) {
    const result: any = await SubscriptionController.downloadInvoice(id)

    // Blog destructering
    const { blob, filename } = result

    // Use save as library
    saveAs(blob, filename)
  }

  render() {
    const { t } = this.props
    const { didInitialLoad, invoices } = this.state

    if (!didInitialLoad) {
      return (<PageLoader />)
    }

    if (invoices.length === 0) {
      return <div className='pricing-card-empty'>
        <svg width="167px" height="102px" viewBox="0 0 167 102" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Empty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Card---No-Documents" transform="translate(-122.000000, -100.000000)">
              <g id="NoDocuments" transform="translate(75.000000, 50.000000)">
                <path d="M176,135 L93,135 C89.1340068,135 86,131.865993 86,128 C86,124.134007 89.1340068,121 93,121 L54,121 C50.1340068,121 47,117.865993 47,114 C47,110.134007 50.1340068,107 54,107 L94,107 C97.8659932,107 101,103.865993 101,100 C101,96.1340068 97.8659932,93 94,93 L69,93 C65.1340068,93 62,89.8659932 62,86 C62,82.1340068 65.1340068,79 69,79 L109,79 C105.134007,79 102,75.8659932 102,72 C102,68.1340068 105.134007,65 109,65 L207,65 C210.865993,65 214,68.1340068 214,72 C214,75.8659932 210.865993,79 207,79 L167,79 C170.865993,79 174,82.1340068 174,86 C174,89.8659932 170.865993,93 167,93 L189,93 C192.865993,93 196,96.1340068 196,100 C196,103.865993 192.865993,107 189,107 L178.826087,107 C173.951574,107 170,110.134007 170,114 C170,116.577329 172,118.910662 176,121 C179.865993,121 183,124.134007 183,128 C183,131.865993 179.865993,135 176,135 Z M207,107 C203.134007,107 200,103.865993 200,100 C200,96.1340068 203.134007,93 207,93 C210.865993,93 214,96.1340068 214,100 C214,103.865993 210.865993,107 207,107 Z" id="Background" fill="#F3F7FF" fillRule="evenodd"></path>
                <path d="M158.708558,67.671844 L159.673157,136.1425 L159.673157,142.999922 C159.673157,145.209061 157.882296,146.999922 155.673157,146.999922 L96.6731572,146.999922 C94.4640182,146.999922 92.6731572,145.209061 92.6731572,142.999922 L92.6731572,69.0209641 C92.6731572,67.9163946 93.5685877,67.0209641 94.6731572,67.0209641 C94.6801346,67.0209641 94.687112,67.0210006 94.694089,67.0210736 L99.5826591,67.0722397 M103.535613,67.1136132 L108.151179,67.161922" id="Shape" stroke="#75A4FE" strokeWidth="2.5" fill="#FFFFFF" fillRule="evenodd" strokeLinecap="round" transform="translate(126.173157, 107.010443) rotate(-7.000000) translate(-126.173157, -107.010443) "></path>
                <path d="M155.700739,71.5543308 L156.564559,133.605863 L156.564559,139.820401 C156.564559,141.822433 154.960803,143.445401 152.98247,143.445401 L100.146649,143.445401 C98.1683153,143.445401 96.5645591,141.822433 96.5645591,139.820401 L96.5645591,72.9454011 C96.5645591,71.8408316 97.4599896,70.9454011 98.5645591,70.9454011 L105.100881,70.9454011" id="Rectangle" fill="#E8F0FE" fillRule="evenodd" transform="translate(126.564559, 107.195401) rotate(-7.000000) translate(-126.564559, -107.195401) "></path>
                <path d="M110.671875,51.25 C109.153092,51.25 107.921875,52.4812169 107.921875,54 L107.921875,130 C107.921875,131.518783 109.153092,132.75 110.671875,132.75 L169.671875,132.75 C171.190658,132.75 172.421875,131.518783 172.421875,130 L172.421875,67.434923 C172.421875,66.7052828 172.13191,66.0055517 171.615817,65.4897778 L158.172578,52.0548548 C157.656902,51.5394978 156.957687,51.25 156.228636,51.25 L110.671875,51.25 Z" id="Rectangle" stroke="#75A4FE" strokeWidth="2.5" fill="#FFFFFF" fillRule="evenodd"></path>
                <path d="M156.671875,52.402832 L156.671875,64 C156.671875,65.6568542 158.015021,67 159.671875,67 L167.605469,67" id="Shape" stroke="#75A4FE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M118.671875,67 L144.671875,67 L118.671875,67 Z M118.671875,79 L161.671875,79 L118.671875,79 Z M118.671875,92 L161.671875,92 L118.671875,92 Z M118.671875,105 L161.671875,105 L118.671875,105 Z M118.671875,118 L144.671875,118 L118.671875,118 Z" id="lines" stroke="#A4C3FE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </g>
            </g>
          </g>
        </svg>

        <p className='title'>{t('SubscriptionInvoices::No invoices available')}</p>
      </div>
    }

    return (
      <ResourceModalTable
        onEndReached={this.onEndReached}
      >
        <ResourceModalTableRow header={true}>
          <ResourceModalTableRowHeader sticky={true} stickyTop='0px' style={{ width: 'auto' }}>
            {t('SubscriptionInvoices::Date')}
          </ResourceModalTableRowHeader>
          <ResourceModalTableRowHeader sticky={true} stickyTop='0px' style={{ width: 'auto' }}>
            {t('SubscriptionInvoices::Status')}
          </ResourceModalTableRowHeader>
        </ResourceModalTableRow>

        {invoices.map(invoice => {
          return (
            <ResourceModalTableRow key={invoice.id}>
              <ResourceModalTableRowData style={{ width: 'auto' }}>
                <ResourceTableRowDataLink onClick={() => this.onDownloadInvoiceClick(invoice.id)}>
                  {moment(invoice.created_at).format('MMMM YYYY')}
                </ResourceTableRowDataLink>
              </ResourceModalTableRowData>

              <ResourceModalTableRowData style={{ width: 'auto' }}>
                <Badge type='success' text={t('SubscriptionInvoices::Paid')} />
              </ResourceModalTableRowData>
            </ResourceModalTableRow>
          )
        })}
      </ResourceModalTable>
    )
  }
}

export default withTranslation()(SubscriptionInvoices)