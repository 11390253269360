import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../Icons/Icon'
import Badge from '../Badge/Badge'
import styled from 'styled-components'
import { Style } from '../../styles'
import { UserWorkspaceSettingStatus } from '../../types'

const Subheading = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const SubheadingBadge = styled.div`
	margin-left: ${Style.spacing.x1};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-left: 0;
		margin-top: ${Style.spacing.x1};
	}
`

interface IProps {
	name?: string
	subheading?: string
	role?: string
	status?: UserWorkspaceSettingStatus
	onCredentialsClick?: () => void
	onEditClick?: () => void
	onDeleteClick?: () => void
}

const MemberItem = (props: IProps) => {
	const { t } = useTranslation()
	const {
		name,
		subheading,
		role,
		status,
		onCredentialsClick,
		onEditClick,
		onDeleteClick,
	} = props

	return (
		<div className='member-item'>
			<div className='member-item-wrapper'>
				<div className='member-item-avatar'>
					{name.substring(0, 1).toUpperCase()}
				</div>

				<div className='member-item-content'>

					<div className='member-item-content-heading'>
						{name && <div className='member-item-content-heading-name'>
							{name}
						</div>}

						{role && <div className='member-item-content-heading-role'>
							{role}
						</div>}
					</div>

					{subheading && <div className='member-item-content-subheading'>
						<Subheading>
							{subheading}

							{status && <SubheadingBadge><Badge
								type={status === UserWorkspaceSettingStatus.ACTIVE ? 'success' : 'warning'}
								text={t(`UserWorkspaceSettingStatus::${status}`)}
							/>
							</SubheadingBadge>}
						</Subheading>
					</div>}
				</div>

				<div className='member-item-action'>
					{onCredentialsClick && <a href='javascript://' className='member-item-credentials-action' onClick={onCredentialsClick}>
						<Icon icon='key' />
					</a>}

					{onEditClick && <a href='javascript://' className='member-item-edit-action' onClick={onEditClick}>
						<Icon icon='edit' />
					</a>}

					{onDeleteClick && <a href='javascript://' className='member-item-delete-action' onClick={onDeleteClick}>
						<Icon icon='minus-circle' />
					</a>}
				</div>
			</div>
		</div>
	)
}

export default MemberItem