import * as React from 'react'
import { Moment } from 'moment'
import Datetime from 'react-datetime'
import moment from '../../utilities/Moment'
import Icon from '../Icons/Icon'
import { WithTranslation, withTranslation } from 'react-i18next'
import { TrackViewLayout, TrackViewMode } from '../../containers/TimeTrackingOverview'
import ButtonGroup, { ButtonGroupItem } from '../Button/ButtonGroup'
import styled from 'styled-components'
import { Style } from '../../styles'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import { UserWorkspaceSettingRole } from '../../types'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
  }
`

const RangeNavigatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    width: 100%;
  }
`

const RangeNavigatorWrapper = styled.div`
  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    width: 100%;
  }
`

const RangeNavigator = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 36px;
  border: 1px solid #c4cdd5;
  border-radius: 4px;
`

const RangeNavigatorArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  text-align: center;
  background-color: white;
  fill: #36619a;
  color: #36619a;
  cursor: pointer;

  i {
    color: #36619a;
  }

  &.is-left {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid ${Style.color.border};
  }

  &.is-right {
    border-radius: 0 4px 4px 0;
    border-left: 1px solid ${Style.color.border};
  }
`

const RangePicker = styled.div`
  position: absolute;
  top: 32px;
  z-index: 5;
  min-width: 240px;

  .rdtPicker {
    border: 1px solid ${Style.color.border};
    border-radius: 4px;
  }
`

const NavigateToday = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 32px;
  background-color: white;
  fill: #5886c4;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  margin-left: 8px;
  border: 1px solid ${Style.color.border};

  &:hover {
		background: #f6f6f7;
	}
`

const TrackNavigatorRange = styled.div`
  position: relative;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 0 8px;
  min-width: 186px;
  cursor: pointer;
  width: 100%;
  text-align: center;

  a {
    width: 100%;
    text-align: center;
    font-weight: 500;
  }
`

const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0;

  > div {
    &:first-child {
      margin-right: ${Style.spacing.x1};
    }
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      flex-direction: column;

      > div {
        &:first-child {
          margin-right: 0;
          margin-bottom: ${Style.spacing.x1};
        }
      }
  }
`

const UserSelectContainer = styled.div`
  min-width: 292px;
  margin-top: 8px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    width: 100%;
  }
`

type IProps = {
  selectedUserId?: string
  usersCount: number
  start: Moment
  end: Moment
  viewLayout: TrackViewLayout
  viewMode: TrackViewMode
  viewLayouts: ButtonGroupItem[]
  viewItems: ButtonGroupItem[]
  onDateSelect: (selectedDate: Moment) => void
  onNavigateToCurrentDay: (e: React.MouseEvent<HTMLDivElement>) => void
  onNavigateBack: (e: React.MouseEvent<HTMLDivElement>) => void
  onNavigateForward: (e: React.MouseEvent<HTMLDivElement>) => void
  onViewModeChange: (viewMode: TrackViewMode) => void
  onSelectedUserChange: (option: any) => void
} & WithTranslation

interface IState {
  showDatePicker: boolean
}

class TrackNavigator extends React.Component<IProps, IState> {
  private dateContainer = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      showDatePicker: false
    }

    this.onOutsideDropdownClick = this.onOutsideDropdownClick.bind(this)
    this.onDateClick = this.onDateClick.bind(this)
    this.onDatePickerSelect = this.onDatePickerSelect.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onOutsideDropdownClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onOutsideDropdownClick);
  }

  onOutsideDropdownClick(e) {
    const {
      showDatePicker
    } = this.state

    if (showDatePicker && this.dateContainer && !this.dateContainer.current.contains(e.target)) {
      this.setState({ showDatePicker: false })
    }
  }

  onDateClick(e) {
    e.preventDefault()
    const { showDatePicker } = this.state

    this.setState({
      showDatePicker: !showDatePicker
    })
  }

  onDatePickerSelect(selectedDate: Moment) {
    const { onDateSelect } = this.props

    this.setState({ showDatePicker: false })

    onDateSelect(selectedDate)
  }

  getRangeText(): string {
    const { viewMode, start, end, t } = this.props

    if (moment().isBetween(start, end)) {
      if (viewMode === TrackViewMode.WEEK) return t('TrackNavigator::This week')
      if (viewMode === TrackViewMode.MONTH) return t('TrackNavigator::This month')
    }

    return `${start.format('D MMM')} - ${end.format('D MMM')}`
  }

  render() {
    const { showDatePicker } = this.state

    const {
      viewLayout,
      viewLayouts,
      viewMode,
      viewItems,
      selectedUserId,
      usersCount,
      start,
      end,
      onNavigateToCurrentDay,
      onNavigateBack,
      onNavigateForward,
      onSelectedUserChange,
    } = this.props

    return (
      <Container>
        <RangeNavigatorContainer>
          <RangeNavigatorWrapper>
            <RangeNavigator ref={this.dateContainer}>
              <RangeNavigatorArrow className='is-left' onClick={onNavigateBack}>
                <Icon icon='chevron-left' />
              </RangeNavigatorArrow>
              <TrackNavigatorRange>
                <a href='javascript://' onClick={this.onDateClick}>
                  <span>{this.getRangeText()}</span>
                </a>

                <RangePicker>
                  {showDatePicker && <Datetime
                    value={moment()}
                    onChange={this.onDatePickerSelect}
                    input={false}
                    timeFormat={false}
                    initialViewMode='days'
                  />}
                </RangePicker>
              </TrackNavigatorRange>
              <RangeNavigatorArrow className='is-right' onClick={onNavigateForward}>
                <Icon icon='chevron-right' />
              </RangeNavigatorArrow>
            </RangeNavigator>
            {usersCount > 1 && UserWorkspaceSettingHelper.hasRole(UserWorkspaceSettingRole.OWNER) && <UserSelectContainer>
              <ResourceCreatablePowerSelect
                type='user'
                value={selectedUserId}
                onChange={onSelectedUserChange}
                isClearable={true}
                isValidNewOption={() => false}
              />
            </UserSelectContainer>}
          </RangeNavigatorWrapper>

          {!moment().isBetween(start, end) && <NavigateToday onClick={onNavigateToCurrentDay}>
            <Icon icon='track' className='is-default' />
          </NavigateToday>}
        </RangeNavigatorContainer>

        <ButtonGroupWrapper>
          <ButtonGroup items={viewLayouts} />
          <ButtonGroup items={viewItems} />
        </ButtonGroupWrapper>
      </Container>
    )

  }
}

export default withTranslation()(TrackNavigator)