import styled from 'styled-components'
import { Style } from '../../styles'

const BlockHeader = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	border-bottom: 1px solid ${Style.color.border};
	overflow: hidden;
	overflow-x: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: white;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`

export default BlockHeader