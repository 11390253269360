import styled from "styled-components"
import { Style } from "../../styles"

const DealsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  padding-bottom: 5px;
  padding-left: 32px;
  padding-right: 120px;

  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }


  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    left: 0;
    padding-left: 0;
    padding-right: 0px;
    padding-bottom: 40px;
  }
`

export default DealsWrapper