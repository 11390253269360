import '../translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { SENTRY_DSN } from '../Constants'
import { Helmet } from 'react-helmet'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import { useTranslation } from 'react-i18next'
import AppLoader from '../components/Loaders/AppLoader'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ConfirmContainer from './ConfirmContainer'
import ConfirmWrapper from './ConfirmWrapper'
import ConfirmTitle from './ConfirmTitle'
import ConfirmDescription from './ConfirmDescription'
import Loader from '../components/Loaders/Loader'
import PoweredBy from './PoweredBy'
import PoweredByContainer from './PoweredByContainer'
import { Body } from '../components/Body/Body'
import { Form } from '../types'
import ConfirmAction from './ConfirmAction'

Sentry.init({ dsn: SENTRY_DSN })

interface IProps {
  form: Form
  formResponse: { [key: string]: string }
  recaptcha: {
    siteKey: string,
    action: string
  }
}

const Application = (props: IProps) => {
  const { form, formResponse, recaptcha } = props
  const formRef = React.useRef<HTMLFormElement>(null)
  const submitRef = React.useRef<HTMLInputElement>()
  const recaptchaTokenInput = React.useRef<HTMLInputElement>()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()

  const onFormSubmit = async (e) => {
    // Prevent default submission
    e.preventDefault()

    try {
      if (!executeRecaptcha) {
        console.log('Recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha(recaptcha.action);

      // Add token to form before submitting the form
      if (recaptchaTokenInput.current) recaptchaTokenInput.current.value = token

      // Submit after recaptcha token has been added to the form
      if (formRef.current) formRef.current.submit();
    } catch (ex) {
      console.error(ex)
    }
  }

  const onSubmitClick = () => {
    if (submitRef.current) submitRef.current.click()
  }

  return (
    <>
      <Helmet>
        <title>{`Bizzey`}</title>
      </Helmet>

      <ScrollToTopOnMount />

      <Body className='no-overflow' />

      <form ref={formRef} method='POST' action={form.url} onSubmit={onFormSubmit}>
        {/* LOOP OVER PARAMS AND CREATE HIDDEN INPUT FIELDS FOR THEM*/}
        {Object.entries(formResponse).map(([key, value], index) => {
          return (
            <input key={`${key}-${index}`} type='hidden' name={key} value={value} />
          )
        })}
        <input ref={recaptchaTokenInput} type='hidden' name='g-recaptcha-response' id='recaptcha-token' />
        <input type='hidden' name='g-recaptcha-action' value={recaptcha.action} />
        <input type='text' name='_gotcha' style={{ display: 'none' }} />

        <ConfirmContainer>
          <ConfirmWrapper>
            <ConfirmTitle>
              {t('ConfirmFormSubmission::Almost there')}
            </ConfirmTitle>
            <ConfirmDescription style={{ marginBottom: 20 }}>
              {t('ConfirmFormSubmission::Please help us fight spam by clicking the button below:')}
            </ConfirmDescription>

            <ConfirmAction onClick={onSubmitClick}>
              {t('ConfirmFormSubmission::I\'m not a robot')}
            </ConfirmAction>
          </ConfirmWrapper>
        </ConfirmContainer>

        <PoweredByContainer>
          <PoweredBy />
        </PoweredByContainer>

        <input ref={submitRef} type='submit' style={{ display: 'none' }} />
      </form>
    </>
  );
}


const ApplicationProvider = (props: IProps) => {
  const { recaptcha } = props
  return (
    <React.Suspense fallback={<AppLoader />}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptcha.siteKey}>
        <Application {...props} />
      </GoogleReCaptchaProvider>
    </React.Suspense>
  )
}

export default ApplicationProvider