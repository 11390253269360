import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../../styles'
import NumberFormatter from '../../../utilities/NumberFormatter'

const Tooltip = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	visibility: hidden;
	opacity: 0;
	background: ${Style.color.brandSuccess}; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: 5;
	width: max-content;
`

const TooltipWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
`

const TooltipArrow = styled.div<{ color: string }>`
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-width: 10px;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

	${(props) => css`
			border-top-color: ${props.color};
	`}
`

const Title = styled.div`
	font-weight: bold;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 300px;
	display: block;
	overflow: hidden;
	color: black;
`

const ExpectedRevenue = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: black;
`

export const MOUSEMOVE_EXPECTED_REVENUE_TIMELINE = 'mousemove-expected-revenue-timeline-item'
export const MOUSELEAVE_EXPECTED_REVENUE_TIMELINE = 'mouseleave-expected-revenue-timeline-item'

interface IProps {
	currency: string
	numberFormat: string
}

const YearlyExpectedRevenueGoalTooltip = (props: IProps) => {
	const { t } = useTranslation()
	const { currency, numberFormat } = props
	const tooltip = React.useRef<HTMLDivElement>()
	const [state, setState] = React.useState<{
		expectedRevenue: number, expectedRevenueMetadata: {
			unbilled_time_entries: number,
			outstanding_invoices: number,
			projects: number,
			recurring_invoices: number,
		}, x: number, y: number
	} | null>(null)

	const setTooltip = (data) => {
		const { detail: { expectedRevenue, expectedRevenueMetadata, x, y } } = data

		if (expectedRevenue && x && y) {
			setState({ expectedRevenue, expectedRevenueMetadata, x, y })
		} else {
			setState(null)
		}
	}

	React.useEffect(() => {
		document.addEventListener(MOUSEMOVE_EXPECTED_REVENUE_TIMELINE, setTooltip)
		document.addEventListener(MOUSELEAVE_EXPECTED_REVENUE_TIMELINE, setTooltip)

		return () => {
			document.removeEventListener(MOUSEMOVE_EXPECTED_REVENUE_TIMELINE, setTooltip)
			document.removeEventListener(MOUSELEAVE_EXPECTED_REVENUE_TIMELINE, setTooltip)
		}
	}, [state])

	if (!state) return null

	const { expectedRevenue, expectedRevenueMetadata, x, y } = state

	let tooltipWidth = null
	let tooltipHeight = null

	if (tooltip.current) {
		tooltipWidth = tooltip.current.clientWidth
		tooltipHeight = tooltip.current.clientHeight
	}

	return (
		<Tooltip
			ref={tooltip}
			style={{
				left: `calc(${x}px - ${tooltipWidth / 2}px)`,
				top: `calc(${y}px - ${tooltipHeight + 16}px)`,
				opacity: expectedRevenue && tooltipWidth ? 1 : 0,
				visibility: expectedRevenue && tooltipWidth ? 'visible' : 'hidden',
				background: '#b3f1d3',
			}}
		>
			<TooltipWrapper>
				<Title>{t('YearlyExpectedRevenueGoalTooltip::Expected revenue')}</Title>
				<ExpectedRevenue>
					{NumberFormatter.formatCurrency(currency, numberFormat, expectedRevenue)}
				</ExpectedRevenue>

				<TooltipArrow color='#b3f1d3' />
			</TooltipWrapper>
		</Tooltip>
	)
}

export default YearlyExpectedRevenueGoalTooltip