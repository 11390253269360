import styled from "styled-components";

const PoweredByContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 500px;
	margin: 0 auto;
`

export default PoweredByContainer