import styled from "styled-components";
import { Style } from "../../styles";

const TopSubnavigation = styled.div`
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	background-color: white;
	border-bottom: 1px solid rgb(211, 210, 211);
	overflow-x: auto;
	overflow-y: hidden;
	padding: 0 ${Style.spacing.x2};

	> * {
		margin-right: ${Style.spacing.x1};
	}
`

export default TopSubnavigation