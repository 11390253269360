import * as React from 'react'
import styled, { css } from 'styled-components';
import Icon from '../Icons/Icon';
import TimeEntryHelper from '../../helpers/TimeEntryHelper';
import { Style } from '../../styles';
import { TimeEntry } from '../../types';

interface IProps {
	timeEntry?: TimeEntry
	rounded?: boolean
	width?: number
	onClick: () => void
}

interface ContainerProps {
	rounded?: boolean
	width: number
	backgroundColor: string
	onClick?: () => void
}

const Container = styled.div<ContainerProps>`
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;

	${(props: ContainerProps) => css`
		width: ${props.width}px;
		height: ${props.width}px;
		background-color: ${props.backgroundColor};
	`}
	border: 2px solid transparent;
	transition: border-color .3s;
	border-radius: 7px;
	overflow: hidden;

	${(props: ContainerProps) => props.rounded && css`
		border-radius: 50%;
	`}

	${(props: ContainerProps) => props.onClick && css`
		cursor: pointer;
	`}
`

const StyledIcon = styled(Icon)`
	color: white;
	fill: white;
`

export default class TimeEntryAvatar extends React.PureComponent<IProps> {
	static defaultProps = {
		width: 40
	}

	render() {
		const { timeEntry, rounded, width, onClick } = this.props

		return (
			<Container
				rounded={rounded}
				width={width}
				backgroundColor={timeEntry.billable ? Style.color.brandSuccess : '#CCC'}
				onClick={onClick}
			>
				<StyledIcon icon='euro' />
			</Container>
		)

	}
}