import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
  width: 100%;
  margin-bottom: ${Style.spacing.x0_5};

  a {
    font-weight: 400;
    display: block;
    width: 100%;
    padding: 5px 16px;
    border-radius: 100px;
    font-size: 15px;
    color: rgb(72, 101, 129);
    white-space: nowrap;

    &.is-active, &:hover {
      color: #000C14;
      background: #edeff0;
    }
  }
`

interface IProps {
  to: string
  text: string

}

const MenuItem = (props: IProps) => {
  const { to, text } = props

  return (
    <Container>
      <NavLink to={to} activeClassName='is-active'>
        {text}
      </NavLink>
    </Container>
  )
}

export default MenuItem