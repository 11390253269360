import i18n from "../translations";
import { BillableRounding } from "../types";

export default class BillableRoundingHelper {
  static labelForValue(rounding: BillableRounding): string {
    switch (rounding) {
      case BillableRounding.NONE:
        return i18n.t("BillableRounding::No rounding");
      case BillableRounding.ROUND_NEAREST_MINUTE:
        return i18n.t("BillableRounding::Round to the nearest minute");
      case BillableRounding.ROUND_NEAREST_QUARTER_HOUR:
        return i18n.t("BillableRounding::Round to the nearest 15 minutes");
      case BillableRounding.ROUND_NEAREST_HALF_HOUR:
        return i18n.t("BillableRounding::Round to the nearest 30 minutes");
      case BillableRounding.ROUND_NEAREST_HOUR:
        return i18n.t("BillableRounding::Round to the nearest hour");
    }
  }
}
