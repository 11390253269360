import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import Button from '../components/Button/Button'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import PageContent from '../components/Page/PageContent'
import PageHeader from '../components/Page/PageHeader'
import ERoute from '../ERoute'
import RouteHelper from '../helpers/RouteHelper'
import SequencesTable from '../components/Sequences/SequencesTable'

type IProps = & RouteComponentProps<any>

const Sequences = (props: IProps) => {
	const { t } = useTranslation()
	const { history } = props

	const onNewSequenceClick = async () => {
		history.push(RouteHelper.process(ERoute.PATH_SEQUENCES_CREATE))
	}

	return (
		<>
			<Helmet>
				<title>{t('Sequences::{{__appName}} | Sequences')}</title>
			</Helmet>
			<ScrollToTopOnMount />

			<TopNavigation
				icon='paper-plane'
				title={t('Contacts::Sequences')}
				action={
					<Button
						type='primary'
						icon='plus'
						text={t('Sequences::New sequence')}
						onClick={onNewSequenceClick}
						extraClasses='page-action'
					/>
				}
			/>

			<PageContent>
				<PageHeader title={t('Sequences::Sequences')} />
				<SequencesTable />
			</PageContent>
		</>
	)
}

export default Sequences