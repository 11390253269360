import * as React from 'react'
import Icon from '../Icons/Icon'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #f0f0f2;
  width: 32px;
  height: 32px;
  border-radius: 8px;

  &:hover {
    background: #e3e3e5;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`

interface IProps {
  active: boolean
}

const StepHeaderToggle = (props: IProps) => {
  const { active } = props
  return (
    <Container>
      <Icon icon={active ? 'caret-down' : 'caret-up'} />
    </Container>
  )
}

export default StepHeaderToggle