import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, matchPath } from 'react-router-dom'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import ERoute from '../ERoute'
import FullPageContent from '../components/Page/FullPageContent'
import FormEditor from './Forms/FormEditor'
import TopSubnavigation from '../components/Navigation/TopSubnavigation'
import TabItem from '../components/Navigation/TabItem'
import styled from 'styled-components'
import { Style } from '../styles'
import RouteHelper from '../helpers/RouteHelper'
import { FormsController } from '../controllers'
import ActionCableConsumer from '../consumers/ActionCableConsumer'
import FormResponses from './Forms/FormResponses'
import Notification from '../utilities/Notification'
import { Form as FormType, FormChannelEvent, FormChannelEventType } from '../types'

type IProps = & RouteComponentProps<any>

const ContentContainer = styled.div`
	overflow: hidden;
	height: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		overflow-y: visible;
	}
`

const Form = (props: IProps) => {
	const { t } = useTranslation()
	const { history, match } = props
	const formId = match.params.id
	const activePath = props.location.pathname
	const [form, setForm] = React.useState<FormType>(null)

	const fetchForm = async () => {
		try {
			const { form } = await FormsController.getFormForm({ id: formId })
			setForm(form)
		} catch (ex) {
			console.error(ex)
		}
	}

	const onNavigateItemClick = (path: string) => {
		history.replace(path)
	}

	const onActionCableReceived = (event: FormChannelEvent) => {
		switch (event.type) {
			case FormChannelEventType.FORM_CREATE:
				setForm(event.data.form)
			case FormChannelEventType.FORM_UPDATE:
				setForm(event.data.form)
				break
			case FormChannelEventType.FORM_DELETE:
				Notification.notifySuccess(t('Form::Form has been deleted'))
				history.replace(ERoute.PATH_FORMS)
				break
			case FormChannelEventType.FORM_RESPONSE_CREATE:
				fetchForm().catch(console.error)
				break;
			case FormChannelEventType.FORM_RESPONSE_UPDATE:
				break;
			case FormChannelEventType.FORM_RESPONSE_DELETE:
				fetchForm().catch(console.error)
				break

		}
	}

	React.useEffect(() => {
		fetchForm().catch(console.error)
	}, [formId])

	return (
		<ActionCableConsumer
			channel={{ channel: 'FormChannel', id: formId }}
			onReceived={onActionCableReceived}
		>
			<Helmet>
				<title>{t('Forms::{{__appName}} | Form')}</title>
			</Helmet>

			<ScrollToTopOnMount />

			<TopNavigation
				icon='input-pipe'
				title={t('Forms::Form')}
			/>

			<FullPageContent>
				<TopSubnavigation>
					<TabItem
						active={!!matchPath(activePath, { path: ERoute.PATH_FORM, exact: true })}
						onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_FORM, { id: formId }))}
					>
						{t('Form::Form')}
					</TabItem>
					<TabItem
						active={!!matchPath(activePath, { path: ERoute.PATH_FORM_RESPONSES })}
						onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_FORM_RESPONSES, { id: formId }))}
					>
						{t('Form::Responses')} {form && <span>{form.responses_count}</span>}
					</TabItem>
				</TopSubnavigation>
				<ContentContainer>
					<Switch>
						<Route path={ERoute.PATH_FORM_RESPONSES} component={FormResponses} />
						<Route path={ERoute.PATH_FORM} component={FormEditor} />
						<Redirect from='/' to={ERoute.PATH_FORM} />
					</Switch>
				</ContentContainer>
			</FullPageContent>
		</ActionCableConsumer>
	)
}

export default Form