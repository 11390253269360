import * as React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { ContactType, CurrentUser, ResourceListFilterType, SequenceEnrollment } from '../../types'
import ResourceTableRow from '../../components/Resource/ResourceTableRow'
import TopNavigation from '../../components/Navigation/TopNavigation'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import ResourceTable, { ResourceTableAction } from '../../components/Resource/ResourceTable'
import PageContent from '../../components/Page/PageContent'
import PageLoader from '../../components/Page/PageLoader'
import ResourceTableRowData from '../../components/Resource/ResourceTableRowData'
import ResourceTableRowActions from '../../components/Resource/ResourceTableRowActions'
import CardEmptyInfo from '../../components/Card/CardEmptyInfo'
import { SequenceEnrollmentsController, SequencesController } from '../../controllers'
import { AppState } from '../../store'
import ButtonPanel from '../../components/Button/ButtonPanel'
import SequenceEnrollmentSidebar from '../../components/Sequences/SequenceEnrollmentSidebar'
import { showContactModal, showContactTypeModal } from '../../store/modals/actions'
import moment from '../../utilities/Moment'
import SequenceHelper from '../../helpers/SequenceHelper'


interface IStateProps extends RouteComponentProps<{ id?: string }> {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showContactTypeModal: typeof showContactTypeModal
  showContactModal: typeof showContactModal
}

interface IState {
  enrollments: SequenceEnrollment[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
  filters: any,
  selectedSequenceEnrollmentIds: string[]
  searchValue: string
  sequenceEnrollmentSidebarActive: boolean
}

type IProps = IStateProps & IDispatchToProps & WithTranslation

class SequenceEnrollments extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      enrollments: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.SEQUENCE_ENROLLMENT_SORT_VALUE, '-'),
      filters: {},
      selectedSequenceEnrollmentIds: [],
      searchValue: '',
      sequenceEnrollmentSidebarActive: false
    }

    this.onNewEnrollmentClick = this.onNewEnrollmentClick.bind(this);
    this.onEnrollmentSidebarClose = this.onEnrollmentSidebarClose.bind(this)
    this.onCreateContactClick = this.onCreateContactClick.bind(this)
    this.onTableActionClick = this.onTableActionClick.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onSequenceEnrollmentFiltersChange = this.onSequenceEnrollmentFiltersChange.bind(this)
    this.onSequenceEnrollmentSortValueChange = this.onSequenceEnrollmentSortValueChange.bind(this)
    this.onSequenceEnrollmentSearchChange = this.onSequenceEnrollmentSearchChange.bind(this)
    this.onSequenceEnrollmentSearchSubmit = this.onSequenceEnrollmentSearchSubmit.bind(this)
    this.onSequenceEnrollmentClearFilters = this.onSequenceEnrollmentClearFilters.bind(this)
    this.onEnrollSubmit = this.onEnrollSubmit.bind(this)
    this.onUnenrollClick = this.onUnenrollClick.bind(this)
  }

  componentWillMount() {
    this.fetchSequenceEnrollments(1)
  }


  async fetchSequenceEnrollments(page: number) {
    const { match: { params: { id } } } = this.props
    const { searchValue, sortValue, filters } = this.state

    this.setState({
      isFetching: true,
    }, async () => {
      try {
        const response = await SequenceEnrollmentsController.getSequenceEnrollments(id, {
          page: page,
          search: searchValue,
          order: `${sortValue}`,
          ...filters
        })
        const { sequence_enrollments, current_page, total_pages, total_entries } = response;

        this.setState({
          enrollments: [...sequence_enrollments],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false
        });
      } catch (ex) {
        console.error(ex)
      }
    });
  }

  onNewEnrollmentClick() {
    this.setState({ sequenceEnrollmentSidebarActive: true })
  }

  onEnrollmentSidebarClose() {
    this.setState({ sequenceEnrollmentSidebarActive: false })
  }

  onCreateContactClick() {
    this.props.showContactTypeModal({
      onSubmit: (contactType: ContactType) => {
        this.props.showContactModal({
          contact: { type: contactType },
          onSubmit: () => {
            this.setState({ sequenceEnrollmentSidebarActive: true })
          }
        })
      }
    })
  }

  async onEnrollSubmit(contactIds: string[], groupIds: string[]) {
    try {
      await SequencesController.enroll(this.props.match.params.id, contactIds, groupIds)
      this.setState({ sequenceEnrollmentSidebarActive: false }, () => {
        this.fetchSequenceEnrollments(1)
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onUnenrollClick() {
    const { selectedSequenceEnrollmentIds } = this.state

    this.unEnroll(selectedSequenceEnrollmentIds)
  }

  async unEnroll(sequenceEnrollmentIds: string[]) {
    try {
      await SequencesController.unenroll(this.props.match.params.id, sequenceEnrollmentIds)

      this.setState({ selectedSequenceEnrollmentIds: [] }, () => {
        this.fetchSequenceEnrollments(1)
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onTableActionClick(key: string, enrollment: SequenceEnrollment) {
    switch (key) {
      case 'unenroll':
        this.unEnroll([enrollment.id])
        break
      default:
        throw Error(`Invalid key ${key}`)
    }
  }

  onTableSelectionChange(selectedSequenceEnrollmentIds: string[]) {
    this.setState({ selectedSequenceEnrollmentIds: selectedSequenceEnrollmentIds })
  }

  onTableRowSelectionChange(selected: boolean, transactionId: string) {
    const { selectedSequenceEnrollmentIds } = this.state

    if (selected) {
      this.setState({ selectedSequenceEnrollmentIds: [...selectedSequenceEnrollmentIds, transactionId] })
    } else {
      this.setState({ selectedSequenceEnrollmentIds: selectedSequenceEnrollmentIds.filter(selectedTransactionId => selectedTransactionId !== transactionId) })
    }
  }

  onSequenceEnrollmentFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchSequenceEnrollments(1)
    })
  }

  onSequenceEnrollmentSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.SEQUENCE_ENROLLMENT_SORT_VALUE, value)
    this.setState({
      sortValue: value
    }, () => {
      this.fetchSequenceEnrollments(1)
    })
  }

  onSequenceEnrollmentSearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onSequenceEnrollmentSearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchSequenceEnrollments(1))
  }

  onSequenceEnrollmentClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchSequenceEnrollments(1))
  }

  render() {
    const { currentUser, t, match: { params: { id: sequenceId } } } = this.props
    const { enrollments, didInitialLoad, isFetching, filters, sortValue, searchValue, currentPage, totalPages, selectedSequenceEnrollmentIds, sequenceEnrollmentSidebarActive } = this.state
    const { workspace: { setting } } = currentUser

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0
    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'trash-alt-solid', content: t('SequenceEnrollment::Unenroll'), destructive: true, onAction: this.onUnenrollClick }
    ]
    const bulkActions: ResourceTableAction[] = []

    return (
      <>
        <Helmet>
          <title>{t('SequenceEnrollments::{{__appName}} | Enrollments')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='paper-plane'
          title={t('SequenceEnrollments::Enrollments')}
          action={null}
        />

        <PageContent className='is-sequence'>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ResourceTable
            data={enrollments}
            actionsLeft={[
              <ButtonPanel
                icon='plus'
                text={t('SequenceEnrollments::Enroll')}
                onClick={this.onNewEnrollmentClick}
              />
            ]}
            headers={[
              { title: t('SequenceEnrollments::Contact') },
              { title: t('SequenceEnrollments::Status') },
              { title: t('SequenceEnrollments::Enrolled on') },
              { title: '', stickyRight: '0px' },
            ]}
            renderRow={(enrollment: SequenceEnrollment) => {
              const selected = selectedSequenceEnrollmentIds.includes(enrollment.id)
              return (
                <ResourceTableRow
                  key={enrollment.id}
                  selected={selectedSequenceEnrollmentIds.includes(enrollment.id)}
                  onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, enrollment.id)}
                >
                  <ResourceTableRowData maxWidth='500px' ellipse onClick={() => this.onTableRowSelectionChange(!selected, enrollment.id)}>
                    {enrollment?.contact?.name}
                  </ResourceTableRowData>
                  <ResourceTableRowData ellipse onClick={() => this.onTableRowSelectionChange(!selected, enrollment.id)}>
                    {SequenceHelper.getSequenceEnrollmentStatus(enrollment.status)}
                  </ResourceTableRowData>
                  <ResourceTableRowData ellipse onClick={() => this.onTableRowSelectionChange(!selected, enrollment.id)}>
                    {moment(enrollment.created_at).format(setting.date_format)}
                  </ResourceTableRowData>
                  <ResourceTableRowActions
                    actions={[
                      { key: 'unenroll', icon: 'trash-alt-solid', content: t('SequenceEnrollment::Unenroll'), destructive: true }
                    ]}
                    onActionClick={(key) => this.onTableActionClick(key, enrollment)}
                    sticky={true}
                    stickyRight='0px'
                  />
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'user'}
              description={filtersActive ? t('SequenceEnrollment::No enrollments found') : t('SequenceEnrollment::No contacts have been enrolled yet.')}
              descriptionActionText={filtersActive ? t('SequenceEnrollment::Clear filters') : t('SequenceEnrollment::Enroll contacts')}
              onDescriptionActionClick={filtersActive ? this.onSequenceEnrollmentClearFilters : this.onNewEnrollmentClick}
            />}
            filters={[
              { name: 'name', label: t('SequenceEnrollment::Name'), type: ResourceListFilterType.STRING },
              { name: 'status', label: t('SequenceEnrollment::Status'), type: ResourceListFilterType.STRING },
              { name: 'created_at', label: t('SequenceEnrollment::Enrolled on'), type: ResourceListFilterType.DATE },
              { name: 'created_at', label: t('SequenceEnrollment::Created date'), type: ResourceListFilterType.DATE },
            ]}
            onFiltersChange={this.onSequenceEnrollmentFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('SequenceEnrollment::Created at ↑'), value: 'created_at_asc' },
              { label: t('SequenceEnrollment::Created at ↓'), value: 'created_at_desc' },
            ]}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            sortValue={sortValue}
            onSortChange={this.onSequenceEnrollmentSortValueChange}
            pagination={{ page: currentPage, pageCount: totalPages }}
            onPageChange={(page) => this.fetchSequenceEnrollments(page)}
            isLoading={isFetching}
            stickyHeader={true}
            selectedItems={selectedSequenceEnrollmentIds}
            onSelectionChange={this.onTableSelectionChange}
            searchValue={searchValue}
            onSearchChange={this.onSequenceEnrollmentSearchChange}
            onSearchSubmit={this.onSequenceEnrollmentSearchSubmit}
            maxHeight='60vh'
          />}
        </PageContent>

        <SequenceEnrollmentSidebar
          active={sequenceEnrollmentSidebarActive}
          sequenceId={sequenceId}
          onCloseClick={this.onEnrollmentSidebarClose}
          onCreateContactClick={this.onCreateContactClick}
          onEnrollSubmit={this.onEnrollSubmit}
        />
      </>
    )
  }
}

interface IStateToProps {
  currentUser: CurrentUser
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showContactTypeModal: (options) => dispatch(showContactTypeModal(options)),
    showContactModal: (options) => dispatch(showContactModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SequenceEnrollments))