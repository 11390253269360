import * as React from 'react'

const BlockContainer = (props) => {
	return (
		<div className='block-container'>
			{props.children}
		</div>
	)
}

export default BlockContainer;