import * as React from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from 'react-router'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import { setCurrentUser, updateSettings, updateWorkspace } from '../store/authentication/actions'
import { connect } from 'react-redux'
import { SettingsController, WorkspaceController, OnboardingController, UserController, VATController, ActiveStorageController } from '../controllers'
import Notification from '../utilities/Notification'
import MoneyInput from '../components/Form/MoneyInput'
import { ISettingsForm } from '../controllers/SettingsController'
import TimeFormatter from '../utilities/TimeFormatter'
import TimeHelper from '../helpers/TimeHelper'
import NumberFormatter from '../utilities/NumberFormatter'
import { Body } from '../components/Body/Body'
import ERoute from '../ERoute'
import AvatarEditor from '../components/Avatar/AvatarEditor'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import { withTranslation, WithTranslation } from 'react-i18next'
import { setLocale } from '../translations'
import LocaleHelper from '../helpers/LocaleHelper'
import DataLayerHelper, { DataLayerEventType } from '../helpers/DataLayerHelper'
import VATHelper from '../helpers/VatHelper'
import { CurrentUser, NavigationMenuItemPage, NavigationMenuItemType, PageNavigationMenuItem, Settings, Workspace } from '../types'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import Images from '../images'
import Select from '../components/Form/Select'
import Icon, { IconTypes } from '../components/Icons/Icon'
import ResourceCheckbox from '../components/Resource/ResourceCheckbox'
import Tooltip from '../components/Tooltips/Tooltip'

interface OnboardingFeature {
  pages: NavigationMenuItemPage[]
  label: string
  description: string
  icon: IconTypes
}

const Container = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
  overflow-x: hidden;
	overflow-y: auto;
	background: white;
	width: 100%;
`

const Wrapper = styled.div`
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	&.is-email-confirmation {
		justify-content: center;
		align-items: center;
		margin-top: 0;
		margin-bottom: 0;
		height: 100%;
	}
`

const OnboardingForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 24px 0;
  max-width: 500px;
`

const Title = styled.div`
	margin: 15px 0 10px;
	color: black;
	font-size: 28px;
  text-align: center;
  font-weight: 800;
  width: 100%;
`

const Description = styled.div`
	width: 28rem;
	text-align: center;
	margin: 0 0 30px;
	font-size: .875rem;
	line-height: 1.5;
	color: rgb(99, 115, 129);
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 100%;
	}
`

const Action = styled.div<{ type?: 'default' }>`
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  cursor: pointer;
  background-color: ${Style.color.brandSuccess};
  color: white;
  flex: 1;

  &:first-child {
    margin-left: 0;
    margin-right: 16px;
  }

  &:last-child {
    margin-right: 0;
  }

  ${props => props.type === 'default' && css`
    background-color: white;
    border: 1px solid ${Style.color.border};
    color: black;
  `}
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: flex;
    flex-direction: column-reverse;

    ${Action} {
      margin-bottom: ${Style.spacing.x1};
    }
  }
`

const Features = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${Style.spacing.x2};
`

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${Style.color.border};
  width: 100%;
  border-radius: ${Style.variables.baseBorderRadius};
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

const FeatureCheckbox = styled.div`
  display: flex;
  margin-top: 4px;
`

const FeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-right: 8px;
`

const FeatureTitle = styled.div`
	flex: 1;
  width: 100%;
  font-weight: bold;
  color: 500;
`

const FeatureDescription = styled.div`
`

const FeatureIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: currentColor;
  margin-right: 12px;
  padding: 6px;
  border-radius: 7px;
  background-color: #f1f1f9;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  
  svg {
    fill: currentColor;
    width: 16px;
    height: 16px;
  }

  i {
    font-size: 16px;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  setCurrentUser: typeof setCurrentUser
  updateSettings: typeof updateSettings
  updateWorkspace: typeof updateWorkspace
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<any> & WithTranslation

interface IWelcomeStep {
  render: () => JSX.Element
  completed: boolean
}

interface IState {
  currentUser: CurrentUser
  workspace: Workspace
  setting: Settings
  isSubmitting: boolean
  steps: IWelcomeStep[]
  form: ISettingsForm | null
  didInitialLoad: boolean
  errors: any
}

class Onboarding extends React.Component<IProps, IState> {
  private fileUpload = React.createRef<HTMLInputElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      currentUser: props.currentUser,
      workspace: props.currentUser.workspace,
      setting: props.currentUser.workspace.setting,
      isSubmitting: false,
      errors: {},
      steps: [
        { render: () => this.renderStep1(), completed: false },
        // { render: () => this.renderStep2(), completed: false },
        { render: () => this.renderStep3(), completed: false },
        { render: () => this.renderStep4(), completed: false },
        { render: () => this.renderStep5(), completed: false },
      ],
      form: null,
      didInitialLoad: false
    }

    this.getCompletedStepsCount = this.getCompletedStepsCount.bind(this)
    this.onFullNameChange = this.onFullNameChange.bind(this)
    this.onSubmitPress = this.onSubmitPress.bind(this)
    this.onLocaleChange = this.onLocaleChange.bind(this)
    this.onCountryChange = this.onCountryChange.bind(this)
    this.onTimezoneChange = this.onTimezoneChange.bind(this);
    this.onDateformatChange = this.onDateformatChange.bind(this);
    this.onTimeFormatChange = this.onTimeFormatChange.bind(this)
    this.onDefaultCurrencyChange = this.onDefaultCurrencyChange.bind(this);
    this.onNumberFormatChange = this.onNumberFormatChange.bind(this);
    this.onBusinessNameChange = this.onBusinessNameChange.bind(this);
    this.onBusinessVatNumberChange = this.onBusinessVatNumberChange.bind(this);
    this.onDefaultHourlyRateChange = this.onDefaultHourlyRateChange.bind(this);
    this.onDefaultDayRateChange = this.onDefaultDayRateChange.bind(this);
    this.onQuotationPrefixNumberChange = this.onQuotationPrefixNumberChange.bind(this)
    this.onQuotationNumberChange = this.onQuotationNumberChange.bind(this)
    this.onQuotationSuffixNumberChange = this.onQuotationSuffixNumberChange.bind(this)
    this.onQuotationValidityDurationChange = this.onQuotationValidityDurationChange.bind(this)
    this.onInvoicePrefixNumberChange = this.onInvoicePrefixNumberChange.bind(this)
    this.onInvoiceNumberChange = this.onInvoiceNumberChange.bind(this)
    this.onInvoiceSuffixNumberChange = this.onInvoiceSuffixNumberChange.bind(this)
    this.onInvoicePaymentDurationChange = this.onInvoicePaymentDurationChange.bind(this);
    this.onAddLogoClick = this.onAddLogoClick.bind(this)
    this.onDeleteLogoClick = this.onDeleteLogoClick.bind(this)
    this.onLogoChange = this.onLogoChange.bind(this)
    this.onStreetChange = this.onStreetChange.bind(this)
    this.onCityChange = this.onCityChange.bind(this)
    this.onZipcodeChange = this.onZipcodeChange.bind(this)
    this.onToggleFeatureClick = this.onToggleFeatureClick.bind(this)
    this.navigateBack = this.navigateBack.bind(this)
    this.navigateForward = this.navigateForward.bind(this)
    this.onBackPress = this.onBackPress.bind(this)
    this.finishSetup = this.finishSetup.bind(this)
  }

  componentWillMount() {
    const { currentUser, history } = this.props

    if (currentUser.workspace.did_onboarding) {
      history.push(ERoute.PATH_DASHBOARD)
    }
  }

  componentDidMount() {
    OnboardingController
      .getForm()
      .then((form) => {
        this.setState({
          form: form,
          didInitialLoad: true
        })
      })
      .catch(console.error)

    DataLayerHelper.push({ event: DataLayerEventType.SIGNUP })
  }

  updateCurrentUser(callback) {
    const { currentUser } = this.state
    const { setCurrentUser, t } = this.props

    UserController
      .updateUser(currentUser)
      .then(response => {
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });

          Notification.notifyError(t('Onboarding::Oops something went wrong!'));
        }
        else {
          this.setState({
            currentUser: response,
          });

          // Set current user state
          setCurrentUser(response)

          // Add new locale
          setLocale(response.locale)

          if (callback) callback()
        }
      })
      .catch(console.error)
  }

  updateSettings(callback) {
    const { setting } = this.state
    const { updateSettings, t } = this.props

    SettingsController
      .update(setting)
      .then(response => {
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });

          Notification.notifyError(t('Onboarding::Oops something went wrong!'));
        }
        else {
          this.setState({
            setting: response,
          });

          updateSettings(setting)

          if (callback) callback()
        }
      })
      .catch(console.error)
  }

  updateWorkspace(callback) {
    const { workspace } = this.state
    const { updateWorkspace } = this.props

    WorkspaceController
      .update(workspace)
      .then(response => {
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
        }
        else {
          this.setState({
            workspace: workspace
          });

          updateWorkspace(workspace)

          if (callback) callback()
        }
      })
      .catch(console.error)
  }

  getCurrentStepIndex() {
    const { steps } = this.state

    const index = steps.findIndex(step => !step.completed)

    if (index === -1) {
      return steps.length - 1
    }

    return index
  }

  getCompletedStepsCount() {
    const { steps } = this.state

    return steps.filter(step => step.completed).length
  }

  onSubmitPress(e) {
    e.preventDefault()

    this.updateCurrentUser(() => {
      this.updateSettings(() => {
        this.updateWorkspace(() => {
          this.navigateForward()
        })
      })
    })
  }

  onBackPress(e) {
    e.preventDefault()
    this.navigateBack()
  }

  onFullNameChange(e) {
    const { currentUser } = this.state

    this.setState({
      currentUser: {
        ...currentUser,
        name: e.currentTarget.value
      }
    })
  }

  onLocaleChange(e) {
    const { currentUser } = this.state;

    this.setState({
      currentUser: {
        ...currentUser,
        locale: e.currentTarget.value,
      }
    }, () => {
      setLocale(this.state.currentUser.locale)
    });
  }

  onCountryChange(e) {
    const countryCode = e.currentTarget.value;

    const { workspace } = this.state;

    this.setState({
      workspace: {
        ...workspace,
        country_code: countryCode,
      }
    })
  }

  onTimezoneChange(e) {
    const timezone = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.timezone = timezone;

    this.setState({
      workspace: workspace
    });
  }

  onDateformatChange(e) {
    const dateFormat = e.currentTarget.value;

    const { setting } = this.state;

    setting.date_format = dateFormat;

    this.setState({
      setting: setting
    });
  }

  onTimeFormatChange(e) {
    const timeFormat = e.currentTarget.value;

    const { setting } = this.state;

    setting.time_format = timeFormat;

    this.setState({
      setting: setting
    });
  }

  onDefaultCurrencyChange(e) {
    const currency = e.currentTarget.value;

    const { setting } = this.state;

    setting.default_currency = currency;

    this.setState({
      setting: setting,
    });
  }

  onNumberFormatChange(e) {
    const numberFormat = e.currentTarget.value;

    const { setting } = this.state;

    setting.number_format = numberFormat;

    this.setState({
      setting: setting
    });
  }

  onBusinessNameChange(e) {
    const businessName = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.business_name = businessName;

    this.setState({
      workspace: workspace
    });
  }

  onBusinessVatNumberChange(e) {
    e.preventDefault()
    const { workspace } = this.state;

    const vatNumber = e.currentTarget.value;


    this.setState({
      workspace: {
        ...workspace,
        vat_number: vatNumber
      }
    }, async () => {

      if (VATHelper.isValidEUVAT(vatNumber)) {
        try {
          const response = await VATController.getDetails(vatNumber)

          if (!response.errors) {
            const { name, city, country, postal, street, street_number } = response

            this.setState({
              workspace: {
                ...this.state.workspace,
                business_name: name,
                street: street ? `${street} ${street_number}` : '',
                country_code: country,
                zip_code: postal,
                city: city,
              }
            })
          }
        } catch (ex) {
          console.error(ex)
        }
      }

    });
  }

  onQuotationPrefixNumberChange(e) {
    const prefixQuotationNumber = e.currentTarget.value;

    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        prefix_quotation_number: prefixQuotationNumber
      },
    });
  }

  onQuotationNumberChange(e) {
    const quotationNumber = e.currentTarget.value;

    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        quotation_number: quotationNumber,
      },
    });
  }

  onQuotationSuffixNumberChange(e) {
    const suffixQuotationNumber = e.currentTarget.value;

    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        suffix_quotation_number: suffixQuotationNumber,
      },
    });
  }

  onDefaultHourlyRateChange(value) {
    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        default_hourly_rate: value
      }
    });
  }

  onDefaultDayRateChange(value) {
    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        default_day_rate: value
      }
    });
  }

  onQuotationValidityDurationChange(e) {
    const { setting } = this.state;

    const validityDuration = e.currentTarget.value;

    setting.quotation_validity_duration = validityDuration;

    this.setState({
      setting: setting
    });
  }

  onInvoicePrefixNumberChange(e) {
    const prefixInvoiceNumber = e.currentTarget.value;

    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        prefix_invoice_number: prefixInvoiceNumber
      },
    });
  }

  onInvoiceNumberChange(e) {
    const quotationNumber = e.currentTarget.value;

    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        invoice_number: quotationNumber,
      },
    });
  }

  onInvoiceSuffixNumberChange(e) {
    const suffixInvoiceNumber = e.currentTarget.value;

    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        suffix_invoice_number: suffixInvoiceNumber,
      },
    });
  }


  onInvoicePaymentDurationChange(e) {
    const paymentDuration = e.currentTarget.value;

    const { setting } = this.state;

    setting.invoice_payment_duration = paymentDuration;

    this.setState({
      setting: setting
    });
  }

  onAddLogoClick(e) {
    e.preventDefault();
    this.fileUpload.current.click();
  }

  async onDeleteLogoClick() {
    const { workspace } = this.state
    const { updateWorkspace } = this.props

    try {
      const updatedWorkspace = await WorkspaceController.update({
        ...workspace,
        business_logo: null
      })

      this.setState({ workspace: updatedWorkspace })
      updateWorkspace(updatedWorkspace)
    } catch (ex) {
      console.error(ex)
    }
  }

  onLogoChange(acceptedFiles) {
    const { updateWorkspace } = this.props
    const logo = acceptedFiles[0];

    try {
      ActiveStorageController.uploadPublic(logo, async (error, blob) => {
        if (error) { console.error(error) }
        if (blob) {
          const { url } = await ActiveStorageController.getBlobUrl(blob)

          const updatedWorkspace = await WorkspaceController.update({ ...this.state.workspace, business_logo: blob.signed_id })

          updateWorkspace(updatedWorkspace)

          this.setState({ workspace: updatedWorkspace })
        }
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onStreetChange(e) {
    const street = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.street = street;

    this.setState({
      workspace: workspace
    });
  }

  onCityChange(e) {
    const city = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.city = city;

    this.setState({
      workspace: workspace
    });
  }

  onZipcodeChange(e) {
    const { workspace } = this.state;
    const zipCode = e.currentTarget.value;

    workspace.zip_code = zipCode;

    this.setState({
      workspace: workspace
    });
  }

  onToggleFeatureClick(feature: OnboardingFeature) {
    const { setting } = this.state

    const activePageMenuItems = setting
      .default_menu
      .filter(menuItem => menuItem.type === NavigationMenuItemType.PAGE)
      .map((menuItem) => (menuItem as PageNavigationMenuItem).page)

    if (feature.pages.every(page => activePageMenuItems.includes(page))) {
      // Disable
      setting.default_menu = setting.default_menu
        .filter(menuItem => {
          return !(menuItem.type === NavigationMenuItemType.PAGE && feature.pages.includes(menuItem.page))
        })
    } else {
      // Add
      feature.pages.forEach(page => {
        setting.default_menu.push({ type: NavigationMenuItemType.PAGE, page: page })
      })
    }

    this.setState({ setting: { ...setting, } })
  }

  finishSetup() {
    const { history, updateWorkspace } = this.props

    OnboardingController
      .finish()
      .then(response => {
        updateWorkspace(response)
        history.push(ERoute.PATH_DASHBOARD)
      })
  }

  navigateBack() {
    const { steps } = this.state
    const currentStepIndex = this.getCurrentStepIndex()

    if (currentStepIndex - 1 >= 0) {
      steps[currentStepIndex - 1].completed = false
      this.setState({ steps })
    }
  }

  navigateForward() {
    const { steps } = this.state
    const beforeStepIndex = this.getCurrentStepIndex()

    if (beforeStepIndex <= steps.length - 1) {

      steps[beforeStepIndex].completed = true

      this.setState({ steps: [...steps] }, () => {
        const newStepIndex = this.getCurrentStepIndex()

        if (beforeStepIndex === newStepIndex) {
          this.finishSetup()
        }
      })
    }
  }

  renderStep1() {
    const { t } = this.props
    const { currentUser, workspace, setting, form } = this.state

    const localeOptions = LocaleHelper.getLocaleOptions()

    return (
      <OnboardingForm>
        <Title>
          {t('Onboarding::Tell us who you are')}
        </Title>

        <Description>
          {t('Onboarding::Configure how colleagues will see your profile.')}
        </Description>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Full name')}</label>
              <input
                type='text'
                value={currentUser.name}
                onChange={this.onFullNameChange}
                placeholder={t('Onboarding::Full name')}
              />
            </div>
          </div>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Language')}</label>
              <div className='select-wrapper'>
                <select value={currentUser.locale} onChange={this.onLocaleChange}>
                  {localeOptions.map((locale, index) => {
                    return (
                      <option value={locale.value} key={locale.value}>{locale.label}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Country')}</label>
              <Select
                name='country_code'
                value={workspace.country_code}
                options={form.countries.map(country => {
                  return {
                    key: country.value, label: country.label, value: country.value
                  }
                })}
                onChange={this.onCountryChange}
              />
            </div>
          </div>
        </div>

        <Actions>
          <Action onClick={this.onSubmitPress}>
            {t('Onboarding::Next')}
          </Action>
        </Actions>
      </OnboardingForm>
    )
  }

  renderStep2() {
    const { t } = this.props
    const { setting, form, workspace } = this.state

    return (
      <OnboardingForm>
        <Title>
          {t('Onboarding::There\'s a time and place for everything')}
        </Title>

        <Description>
          {t('Onboarding::Configure how you want to format your calendar, planning and documents')}
        </Description>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Timezone')}</label>
              <div className='select-wrapper'>
                <select value={workspace.timezone} onChange={this.onTimezoneChange}>
                  {form.time_zones.map((timezone, index) => {
                    return (
                      <option value={timezone.value} key={`${timezone.value}-${index}`}>{timezone.label}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Currency')}</label>
              <div className='select-wrapper'>
                <select value={setting.default_currency} onChange={this.onDefaultCurrencyChange}>
                  {form.currencies.map((currency, index) => {
                    return (
                      <option value={currency.value} key={`${currency.value}-${index}`}>{currency.label}</option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Date format')}</label>
              <div className='select-wrapper'>
                <select value={setting.date_format} onChange={this.onDateformatChange}>
                  {form.date_formats.map((format, index) => {
                    return (
                      <option value={format} key={`${format}-${index}`}>{format}</option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Time format')}</label>
              <div className='select-wrapper'>
                <select value={setting.time_format} onChange={this.onTimeFormatChange}>
                  {form.time_formats.map((format, index) => {
                    return (
                      <option value={format} key={`${format}-${index}`}>{TimeFormatter.durationFormat(TimeHelper.getCurrentTimeInSeconds(), format)}</option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Number format')}</label>
              <div className='select-wrapper'>
                <select value={setting.number_format} onChange={this.onNumberFormatChange}>
                  {form.currency_formats.map((format, index) => {
                    return (
                      <option value={format} key={format}>{NumberFormatter.formatLabels[format]}</option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <Actions>
          <Action type='default' onClick={this.onBackPress}>
            {t('Onboarding::Previous')}
          </Action>
          <Action onClick={this.onSubmitPress}>
            {t('Onboarding::Next')}
          </Action>
        </Actions>
      </OnboardingForm>
    )
  }

  renderStep3() {
    const { t } = this.props
    const { workspace, setting } = this.state

    return (
      <OnboardingForm>
        <Title>
          {t('Onboarding::A business needs business details')}
        </Title>

        <Description>
          {t('Onboarding::Provide your VAT number and we\'ll fetch the required information for your business.')}
        </Description>

        <div className='grid'>
          <div className='grid'>
            <div className='grid-cell with-12col' style={{ marginBottom: 30 }}>
              <AvatarEditor
                uri={workspace.business_logo_url}
                onChange={this.onLogoChange}
                onDelete={this.onDeleteLogoClick}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('Onboarding::VAT number')}
              </label>
              <input
                type='text'
                name='vat_number'
                value={workspace.vat_number}
                placeholder={t('Onboarding::VAT number')}
                onChange={this.onBusinessVatNumberChange}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Company name')}</label>
              <input
                type='text'
                name='business_name'
                value={workspace.business_name}
                placeholder={t('Onboarding::Company name')} onChange={this.onBusinessNameChange} />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('CompanyInfo::Street')}</label>
              <input
                type='text'
                name='street'
                value={workspace.street}
                placeholder={t('CompanyInfo::Street')}
                onChange={this.onStreetChange}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('CompanyInfo::City')}</label>
              <input
                type='text'
                name='city'
                value={workspace.city}
                placeholder={t('CompanyInfo::City')}
                onChange={this.onCityChange}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('CompanyInfo::Postal')}</label>
              <input
                type='text'
                name='city'
                value={workspace.zip_code}
                placeholder={t('CompanyInfo::Postal')}
                onChange={this.onZipcodeChange}
              />
            </div>
          </div>
        </div>

        <Actions>
          <Action type='default' onClick={this.onBackPress}>
            {t('Onboarding::Previous')}
          </Action>
          <Action onClick={this.onSubmitPress}>
            {t('Onboarding::Next')}
          </Action>
        </Actions>
      </OnboardingForm>
    )
  }

  renderStep4() {
    const { t } = this.props
    const { form, setting } = this.state

    return (
      <OnboardingForm>
        <Title>
          {t('Onboarding::Numbers rule the universe')}
        </Title>

        <Description>
          {t('Onboarding::Configure your rates and how you want your documents to be numbered.')}
        </Description>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('Onboarding::Hourly rate')}
                <Tooltip
                  content={t('Onboarding::We use this rate when you start tracking working hours in {{__appName}}')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <MoneyInput
                name='default_hourly_rate'
                currency={setting.default_currency}
                numberFormat={setting.number_format}
                placeholderValue={40}
                value={setting.default_hourly_rate}
                onBlur={this.onDefaultHourlyRateChange}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('Onboarding::Day rate')}

                <Tooltip
                  content={t('Onboarding::We use this rate when you start tracking working days in {{__appName}}')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <MoneyInput
                name='default_day_rate'
                currency={setting.default_currency}
                numberFormat={setting.number_format}
                placeholderValue={450}
                value={setting.default_day_rate}
                onBlur={this.onDefaultDayRateChange}
              />
            </div>
          </div>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Quotation number format')}</label>

              <div className='settings-numbering-format'>
                <input
                  type='text'
                  name='prefix'
                  placeholder={t('Onboarding::Prefix')}
                  value={setting.prefix_quotation_number}
                  onChange={this.onQuotationPrefixNumberChange}
                />
                <input
                  type='number'
                  name='number'
                  placeholder='1'
                  value={setting.quotation_number}
                  onChange={this.onQuotationNumberChange}
                  pattern='\d*'
                  required
                />
                <input
                  type='text'
                  name='suffix'
                  placeholder={t('Onboarding::Suffix')}
                  value={setting.suffix_quotation_number}
                  onChange={this.onQuotationSuffixNumberChange}
                />
              </div>
            </div>
          </div>

          {/* <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Default quotation expiration')}</label>
              <div className='select-wrapper'>
                <select value={setting.quotation_validity_duration} onChange={this.onQuotationValidityDurationChange}>
                  {QUOTATION_VALIDITY_DURATIONS.map(duration => {
                    return (
                      <option key={duration} value={duration}>{t(`QuotationValidityDurations::${duration}`)}</option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div> */}

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Invoice number format')}</label>

              <div className='settings-numbering-format'>
                <input
                  type='text'
                  name='prefix'
                  placeholder={t('Onboarding::Prefix')}
                  value={setting.prefix_invoice_number}
                  onChange={this.onInvoicePrefixNumberChange}
                />
                <input
                  type='number'
                  name='number'
                  placeholder='1'
                  value={setting.invoice_number}
                  onChange={this.onInvoiceNumberChange}
                  pattern='\d*'
                  required
                />
                <input
                  type='text'
                  name='suffix'
                  placeholder={t('Onboarding::Suffix')}
                  value={setting.suffix_invoice_number}
                  onChange={this.onInvoiceSuffixNumberChange}
                />
              </div>
            </div>
          </div>

          {/* <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('Onboarding::Default payment terms')}</label>
              <div className='select-wrapper'>
                <select value={setting.invoice_payment_duration} onChange={this.onInvoicePaymentDurationChange}>
                  {INVOICE_PAYMENT_DURATIONS.map(duration => {
                    return (
                      <option key={duration} value={duration}>
                        {t(`InvoicePaymentDurations::${duration}`)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div> */}
        </div>

        <Actions>
          <Action type='default' onClick={this.onBackPress}>
            {t('Onboarding::Previous')}
          </Action>
          <Action onClick={this.onSubmitPress}>
            {t('Onboarding::Next')}
          </Action>
        </Actions>
      </OnboardingForm>
    )
  }

  renderStep5() {
    const { t } = this.props
    const { setting } = this.state

    const activePageMenuItems = setting
      .default_menu
      .filter(menuItem => menuItem.type === NavigationMenuItemType.PAGE)
      .map((menuItem) => (menuItem as PageNavigationMenuItem).page)

    const features: OnboardingFeature[] = [
      { pages: [NavigationMenuItemPage.INVOICES, NavigationMenuItemPage.BANK], label: t('Onboarding::Invoice and payments'), description: t('Onboarding::Create professional looking invoices in seconds and let users pay online.'), icon: 'invoice' },
      { pages: [NavigationMenuItemPage.DEALS], label: t('Onboarding::Deals'), description: t('Onboarding::Manage and visualize your sales cycle from prospect to sale with deals.'), icon: 'check-circle' },
      { pages: [NavigationMenuItemPage.EXPENSES], label: t('Onboarding::Expense management'), description: t('Onboarding::Track and categorize your expenses automatically using our AI powered assistant.'), icon: 'receipt' },
      { pages: [NavigationMenuItemPage.TASKS], label: t('Onboarding::Task management'), description: t('Onboarding::Create and delegate todo\'s, link them to contacts in our collaborative online workspace.'), icon: 'boards' },
      { pages: [NavigationMenuItemPage.PROJECTS], label: t('Onboarding::Project management'), icon: 'project', description: t('Onboarding::Manage simple to complex projects and everything in between with our interactive project management tool.') },
      { pages: [NavigationMenuItemPage.TIME_TRACKING], label: t('Onboarding::Timesheets'), description: t('Onboarding::Track time from anywhere and visualise time entries in a powerful timesheet.'), icon: 'stopwatch' },
      { pages: [NavigationMenuItemPage.PRODUCTS], label: t('Onboarding::Products and materials'), description: t('Onboarding::Manage a list of products with corresponding stock and easily invoice them to contacts.'), icon: 'product' },
      { pages: [NavigationMenuItemPage.FILES], label: t('Onboarding::File management'), description: t('Onboarding::Easy to use, private, and secure file management for all your documents.'), icon: 'folder' },
      { pages: [NavigationMenuItemPage.PROPOSALS], label: t('Onboarding::Proposal builder'), description: t('Onboarding::Create eye-catching dynamic proposals. Track when they are seen and receive instant notifications when they are approved.'), icon: 'proposal' },
      { pages: [NavigationMenuItemPage.CONTRACTS], label: t('Onboarding::Contract builder'), description: t('Onboarding::Send and track contracts or legal documents. Get them signed by one or multiple parties.'), icon: 'file-contract' },
      { pages: [NavigationMenuItemPage.FORMS], label: t('Onboarding::Form builder'), description: t('Onboarding::Collect information through beautiful and responsive forms that can be shared with a link or embedded on your website.'), icon: 'input-pipe' },
    ]

    return (
      <OnboardingForm>
        <Title>
          {t('Onboarding::What brings you to {{__appName}}?')}
        </Title>

        <Description>
          {t('Onboarding::Choose the features you want to test. Don\'t worry, you can always change them later.')}
        </Description>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <Features>
              {features.map(feature => {
                const checked = feature.pages.every(page => activePageMenuItems.includes(page))

                return (
                  <Feature onClick={() => this.onToggleFeatureClick(feature)}>
                    <FeatureIcon>
                      <Icon icon={feature.icon} />
                    </FeatureIcon>


                    <FeatureInfo>
                      <FeatureTitle>
                        {feature.label}
                      </FeatureTitle>
                      <FeatureDescription>
                        {feature.description}
                      </FeatureDescription>
                    </FeatureInfo>

                    <FeatureCheckbox>
                      <ResourceCheckbox checked={checked} />
                    </FeatureCheckbox>
                  </Feature>
                )
              })}

            </Features>
          </div>
        </div>

        <Actions>
          <Action type='default' onClick={this.onBackPress}>
            {t('Onboarding::Previous')}
          </Action>
          <Action onClick={this.onSubmitPress}>
            {t('Onboarding::Finish')}
          </Action>
        </Actions>
      </OnboardingForm>
    )
  }

  render() {
    const { t } = this.props
    const { steps, didInitialLoad } = this.state
    const currentStepIndex = this.getCurrentStepIndex()

    return (
      <Container>
        <Helmet>
          <title>{t('Onboarding::{{__appName}} | Onboarding')}</title>
        </Helmet>

        <ScrollToTopOnMount />
        <Body className='is-onboarding' />

        <Wrapper>
          <a className='onboarding-company-logo' href='/'>
            <img src={Images.LOGO_TEXT} className='onboarding-logo' />
          </a>

          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <>
            <div className={`onboarding-progress animated ${this.getCompletedStepsCount() / steps.length === 1 ? 'fadeOut' : 'fadeIn'}`} >
              <div style={{ height: '100%', width: `${(this.getCompletedStepsCount() / steps.length) * 100}%` }}></div>
            </div>

            {steps[currentStepIndex].render()}
          </>}
        </Wrapper>
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    setCurrentUser: (user: CurrentUser) => dispatch(setCurrentUser(user)),
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    updateWorkspace: (workspace: Workspace) => dispatch(updateWorkspace(workspace)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Onboarding))