import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Sequence, SequenceMetadata } from '../../types'
import { Style } from '../../styles'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  background: white;
  grid-gap: 16px 1px;
  gap: 16px 1px;
  padding: 16px 0px;
  overflow: hidden;
  width: 100%;
  border: 1px solid ${Style.color.border};
  margin-bottom: 8px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

const Statistic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 0 16px;
  flex: 1;
  position: relative;

  &::after {
    content: "";
    background: ${Style.color.border};
    position: absolute;
    top: 0px;
    right: -1px;
    height: 100%;
    width: 1px;
  }

  &:last-child::after {
    display: none;
  }
`

const StatisticLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: black;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
`

const StatisticValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: black;
`

interface IProps {
  data: SequenceMetadata
}

const SequenceAnalytics = (props: IProps) => {
  const { t } = useTranslation()
  const { data: { scheduled, active, paused, finished } } = props

  return (
    <Container>
      <Statistic>
        <StatisticLabel>
          {t('SequenceAnalytics::Scheduled')}
        </StatisticLabel>

        <StatisticValue>
          {scheduled}
        </StatisticValue>
      </Statistic>
      <Statistic>
        <StatisticLabel>
          {t('SequenceAnalytics::Active')}
        </StatisticLabel>

        <StatisticValue>
          {active}
        </StatisticValue>
      </Statistic>
      <Statistic>
        <StatisticLabel>
          {t('SequenceAnalytics::Paused')}
        </StatisticLabel>

        <StatisticValue>
          {paused}
        </StatisticValue>
      </Statistic>
      <Statistic>
        <StatisticLabel>
          {t('SequenceAnalytics::Finished')}
        </StatisticLabel>

        <StatisticValue>
          {finished}
        </StatisticValue>
      </Statistic>
    </Container>
  )
}

export default SequenceAnalytics