import styled, { css } from 'styled-components'

import * as React from 'react'
import moment from '../../../utilities/Moment'
import { MOUSELEAVE_PROJECT_TIMELINE_ITEM, MOUSEMOVE_PROJECT_TIMELINE_ITEM } from './ProjectMonthlyTimelineItemTooltip'
import { Project } from '../../../types'

const Container = styled.div<{ index: number, left: number, containerWidth: number }>`
    top: ${props => props.index * 26 + 10}px;
    position: absolute;
    cursor: pointer;
    height: 20px;
    width: ${props => props.containerWidth}%;
    z-index: 1;

    ${props => props.containerWidth === 0 && css`
        width: 0;
    `}

    ${props => props.left >= 0 && css`
        left: ${props.left}%;
    `}

    &:hover, &:focus {
        outline: none;
    }
`

const Wrapper = styled.div`
    position: relative;
    height: 4px;
    outline: none;

    &:hover, &:focus {
        outline: none;
    }
`

const ProgressDone = styled.div<{ width: number, color: string, roundedEnd: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${props => props.width}%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    ${props => props.roundedEnd && css`
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    `}
`

const ProgressDoneWrapper = styled.div`
    position: relative;
`

const Background = styled.div<{ roundedEnd: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${props => props.color};
    opacity: .2;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    ${props => props.roundedEnd && css`
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    `}
`

const Dot = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    top: 2px;
    transition: all 100ms linear;

    &:hover {
        transform: translateY(-50%) scale(1.5);
    }
`

const DotBorder = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: white;
    border: 3px solid currentColor;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    top: 2px;
    transition: all 100ms linear;

    &:hover {
        transform: translateY(-50%) scale(1.5);
    }
`

const Arrow = styled.div`
    position: absolute;
    border-top: 0.375em solid transparent;
    border-bottom: 0.375em solid transparent;
    border-left: 0.5em solid currentColor;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    top: 2px;
    transition: all 100ms linear;

    &:hover {
        transform: translateY(-50%) scale(1.5);
    }
`

const Cross = styled.div`
    position: absolute;
    filter: drop-shadow(-0.125em 0 0 #fff);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    top: 2px;
    transition: all 100ms linear;

    &:hover {
        transform: translateY(-50%) scale(1.5);
    }

    &:before, &:after {
        display: block;
        width: 14px;
        height: 3px;
        content: "";
        background-color: currentColor;

    }

    &:before {
        transform: translateY(50%) rotate(45deg);
    }

    &:after {
        transform: translateY(-50%) rotate(-45deg);
    }
`

interface IProps {
    project: Project
    index: number
    onItemClick: (id: string) => void
}

const ProjectMonthlyTimelineItem = (props: IProps) => {
    const container = React.useRef<HTMLDivElement>(null)
    const {
        project,
        index,
        onItemClick,
    } = props

    const now = moment()

    // Calculate starting position on graph
    let left = -9999999
    if (project.start_date) {
        if (moment(project.start_date).isBefore(moment().startOf('year'), 'year')) {
            left = 0
        } else {
            left = moment(project.start_date).dayOfYear() / 365 * 100
        }
    } else if (project.estimated_start_date) {
        left = moment(project.estimated_start_date).dayOfYear() / 365 * 100
    }

    // Calculate width of item
    let containerWidth = 0
    if (project.start_date && project.end_date) {
        const diffInDays = moment(project.end_date).diff(moment(project.start_date), 'day')
        containerWidth = diffInDays / 365 * 100
    } else if (project.start_date && project.cancel_date) {
        const diffInDays = moment(project.cancel_date).diff(moment(project.start_date), 'day')
        containerWidth = diffInDays / 365 * 100
    } else if (project.start_date) {
        if (project.estimated_end_date) {
            const diffInDays = moment(project.estimated_end_date).diff(moment(project.start_date), 'day')
            containerWidth = diffInDays / 365 * 100
        } else {
            const startedOn = moment(project.start_date)
            if (now.isAfter(startedOn)) {
                if (moment(project.start_date).isBefore(moment().startOf('year'), 'year')) {
                    const diffInDays = moment().diff(moment().startOf('year'), 'day')
                    containerWidth = diffInDays / 365 * 100
                } else {
                    const diffInDays = moment().diff(startedOn, 'day')
                    containerWidth = diffInDays / 365 * 100
                }
            }
        }
    } else if (project.estimated_start_date && project.estimated_end_date) {
        const diffInDays = moment(project.estimated_end_date).diff(moment(project.estimated_start_date), 'day')
        containerWidth = diffInDays / 365 * 100
    }

    // Calculate progress
    let progressWidth = 0
    let roundedEnd = true
    if (project.start_date && project.end_date) {
        progressWidth = 100
    } else if (project.start_date && !project.end_date) {
        if (project.estimated_end_date) {
            const startedOn = moment(project.start_date)
            const estimatedOn = moment(project.estimated_end_date)

            if (estimatedOn.isAfter(startedOn) && now.isAfter(startedOn)) {
                const diffInDaysEstimate = estimatedOn.diff(startedOn, 'day')
                const diffInDaysWithNow = now.diff(startedOn, 'day')

                progressWidth = diffInDaysWithNow / diffInDaysEstimate * 100
            }
        } else {
            progressWidth = 100
        }

        roundedEnd = false
    }

    if (project.cancel_date) {
        roundedEnd = true
    }

    return (
        <Container
            index={index}
            left={left}
            containerWidth={containerWidth}
            ref={container}
        >
            <Wrapper
                onClick={() => onItemClick(project.id)}
                onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    const { clientX } = event

                    if (container.current) {
                        const { top } = container.current.getBoundingClientRect()

                        const projectTooltipEvent = new CustomEvent(MOUSEMOVE_PROJECT_TIMELINE_ITEM, {
                            detail: {
                                project: project,
                                x: clientX,
                                y: top,
                            }
                        })

                        document.dispatchEvent(projectTooltipEvent)
                    }
                }}
                onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    const projectTooltipEvent = new CustomEvent(MOUSELEAVE_PROJECT_TIMELINE_ITEM, {
                        detail: {
                            project: null,
                            x: null,
                            y: null
                        }
                    })
                    document.dispatchEvent(projectTooltipEvent)
                }}
            >
                <ProgressDone
                    width={progressWidth}
                    color={project.color}
                    roundedEnd={roundedEnd}
                    style={{ backgroundColor: project.color, width: `${progressWidth}%` }}
                >
                    <ProgressDoneWrapper>

                        {left > 0 && project.start_date && <Dot style={{ backgroundColor: project.color, left: -5 }} />}
                        {!project.cancel_date && project.end_date && <Dot style={{ backgroundColor: project.color, right: -5 }} />}
                        {!project.cancel_date && !project.end_date && project.start_date && <Arrow style={{ color: project.color, right: -5 }} />}
                        {project.cancel_date && <Cross style={{ color: project.color, right: -5 }} />}
                    </ProgressDoneWrapper>
                </ProgressDone>
                <Background
                    roundedEnd={roundedEnd}
                    style={{ backgroundColor: project.color }}
                >
                    {left > 0 && !project.start_date && project.estimated_start_date && <DotBorder style={{ color: project.color, left: -5 }} />}
                    {!project.end_date && project.estimated_end_date && <DotBorder style={{ color: project.color, right: -5 }} />}
                </Background>
            </Wrapper>
        </Container>
    )
}

export default ProjectMonthlyTimelineItem