import styled, { css } from "styled-components"

const TaskLabel = styled.div<{ labelsExpanded?: boolean, background: string }>`
  font-size: 12px;
  font-weight: 700;
  height: 8px;
  line-height: 100px;
  margin: 0 4px 4px 0;
  max-width: 40px;
  min-width: 40px;
  padding: 0;
  text-shadow: none;
  width: auto;
  color: white;
  background: ${props => props.background};
  border-radius: 4px;
  font-weight: 600;

  span {
      display: ${props => props.labelsExpanded ? 'block' : 'none'};
  }

  ${props => props.labelsExpanded && css`
      height: 16px;
      line-height: 16px;
      padding: 0 8px;
      max-width: 198px;

      span {
          display: block;
      }
  `}
`

export default TaskLabel