import styled from 'styled-components'

const ConversationItemIndicator = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 4px;
	min-width: 8px;
	min-height: 8px;
	background: #14b29f;
	margin-top: 4px;
	margin-left: 4px;
`

export default ConversationItemIndicator