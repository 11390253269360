import * as React from 'react'
import { AutomatedEmailSequenceStep, SequenceStep } from "../../../types"
import StepContent from '../StepContent'
import StepFooter from '../StepFooter'
import { useTranslation } from 'react-i18next'
import StepHeaderMetadata from '../StepHeaderMetadata'
import StepHeaderMedataItem from '../StepHeaderMedataItem'
import styled, { css } from 'styled-components'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { Style } from '../../../styles'
import Switch from '../../Form/Switch'
import Icon from '../../Icons/Icon'
import ResourceTableRowDataLink from '../../Resource/ResourceTableRowDataLink'
import StepHeaderActions from '../StepHeaderActions'
import StepName from '../Parts/StepName'
import StepInfo from '../Parts/StepInfo'
import Utils from '../../../utilities/Utils'

const Items = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled.div<{ editable: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  gap: ${Style.spacing.x2};
  padding: 14px 16px;

  ${props => props.editable && css`
    &:hover {
      background: #f7f7f7;
    }
  `}
`

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Style.spacing.x1};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${Style.spacing.x2};
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column-reverse;
    
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Style.spacing.x1};

  ${ResourceTableRowDataLink} {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    gap: ${Style.spacing.x1};

    &:hover {
      text-decoration: underline;
    }

    span {
      text-decoration: none;
    }
  }
`

const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${Style.spacing.x1};
`

interface IProps {
  step: AutomatedEmailSequenceStep
  onEditClick: (index: number) => void
  onStepChange: (step: SequenceStep) => void
  editable: boolean
}
const Email = (props: IProps) => {
  const { t } = useTranslation()
  const { step, editable, onEditClick, onStepChange } = props

  const onItemEditClick = (e?, index?: number) => {
    if (e) e.preventDefault()

    onEditClick(index)
  }

  const onToggleActiveClick = (e, index: number) => {
    e.stopPropagation()

    const email = step.emails[index]

    step.emails[index] = { ...email, active: !email.active }

    onStepChange({
      ...step,
      emails: [...step.emails]
    })
  }

  const onAddEmailClick = () => {
    onStepChange({
      ...step,
      emails: [
        ...step.emails,
        {
          id: Utils.uuid(),
          active: false,
          subject: '',
          body: ''
        }
      ]
    })
  }

  const onDeleteEmailClick = (index: number) => {
    step.emails.splice(index, 1)

    onStepChange({ ...step, })
  }

  const indexToAlphabet = (number = 1) => {
    const A = 'A'.charCodeAt(0);
    return String.fromCharCode(A + number);
  };

  return (
    <>
      <StepContent style={{ padding: 0 }}>
        <Items>
          {step?.emails?.map((item, index) => {
            const sendCounter = Number(item.send_counter) || 0
            const openCounter = Number(item.open_counter) || 0
            const clickCounter = Number(item.click_counter) || 0

            return (
              <Item key={index} onClick={editable ? (e) => onItemEditClick(e, index) : () => { }} editable>
                <HeaderTop>
                  <SwitchContainer>
                    <Switch
                      name='active'
                      checked={item.active}
                      onClick={(e) => onToggleActiveClick(e, index)}
                    />
                    <p>{t('Email::Version {{version}}', { version: indexToAlphabet(index) })}</p>
                  </SwitchContainer>
                  <StepHeaderActions
                    actions={[
                      { key: 'edit', icon: 'edit-solid', content: t('Email::Edit'), onClick: () => onItemEditClick(null, index) },
                      { key: 'delete', icon: 'trash-alt-solid', content: t('Email::Delete'), destructive: true, onClick: () => onDeleteEmailClick(index) }
                    ]}
                  />
                </HeaderTop>

                <Header>
                  <StepName>{item.subject || t('Email::No subject')}</StepName>
                  <StepHeaderMetadata>
                    <StepHeaderMedataItem
                      label={t('Step::Sends')}
                      value={String(sendCounter)}
                    />
                    {/* <StepHeaderMedataItem
                      label={t('Step::Opens')}
                      value={sendCounter > 0 ? `${openCounter / sendCounter * 100}%` : '0%'}
                    />
                    <StepHeaderMedataItem
                      label={t('Step::Clicks')}
                      value={sendCounter > 0 ? `${clickCounter / sendCounter * 100}%` : '0%'}
                    /> */}
                  </StepHeaderMetadata>
                </Header>

                <StepInfo>
                  <FroalaEditorView model={item.body || t('Email::No content')} />
                </StepInfo>
              </Item>
            )
          })}
        </Items>
      </StepContent>

      {editable && <StepFooter>
        <div />
        <LinkContainer>
          <ResourceTableRowDataLink onClick={onAddEmailClick}>
            <span>
              <Icon icon='plus' />
            </span>
            {t('Email::Add A/B test')}
          </ResourceTableRowDataLink>
        </LinkContainer>
      </StepFooter>}
    </>
  )

}

export default Email