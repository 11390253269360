import styled from 'styled-components'

const PageHeaderNavigationRight = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
`

export default PageHeaderNavigationRight