import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LedgerItemHelper from '../../helpers/LedgerItemHelper'
import { Style } from '../../styles'
import { LedgerItem, LedgerItemStatus } from '../../types'

interface IProps {
  ledgerItem: LedgerItem
}

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 115px;
  height: 115px;
  overflow: hidden;
  z-index: 1;

  @media print {
    display: none;
  }
`

const Badge = styled.div`
  padding: 4px 0;
  width: 180px;
  transform: rotate(45deg) translateZ(0);
  transform-origin: 42px 6px;
  font-size: 14px;
  text-align: center;
  background-color: ${Style.color.brandSuccess};
  color: #fff;
  font-weight: 700;

  &.is-warning {
    background-color: ${Style.color.brandWarning};
  }

  &.is-primary {
    background-color: ${Style.color.brandPrimary};
  }

  &.is-success {
    background-color: ${Style.color.brandSuccess};
	}
	
	&.is-danger {
    background-color: ${Style.color.brandDanger};
	}
`


const StatusBadge = (props: IProps) => {
  const { ledgerItem } = props
  const { t } = useTranslation()

  const status = LedgerItemHelper.getLedgerItemStatus(ledgerItem)


  let badgeText = '';
  let badgeClass = '';

  switch (status) {
    case LedgerItemStatus.DRAFT:
      badgeText = t('StatusBadge::Draft')
      badgeClass = 'is-warning';
      break
    case LedgerItemStatus.OVERDUE:
      badgeClass = 'is-danger'
      badgeText = t('StatusBadge::Overdue')
      break
    case LedgerItemStatus.APPROVED:
      badgeClass = 'is-success'
      badgeText = t('StatusBadge::Approved')
    case LedgerItemStatus.REJECTED:
      badgeClass = 'is-danger'
      badgeText = t('StatusBadge::Rejected')
      break
    case LedgerItemStatus.INVOICED:
      badgeClass = 'is-success'
      badgeText = t('StatusBadge::Invoiced')
      break
    case LedgerItemStatus.OUTSTANDING:
      badgeText = t('StatusBadge::Outstanding')
      badgeClass = 'is-warning'
      break
    case LedgerItemStatus.PAID:
      badgeClass = 'is-success'
      badgeText = t('StatusBadge::Paid')
      break
    case LedgerItemStatus.DELIVERED:
      badgeClass = 'is-success'
      badgeText = t('StatusBadge::Delivered')
      break
    case LedgerItemStatus.CANCELLED:
      badgeClass = 'is-danger'
      badgeText = t('StatusBadge::Cancelled')
      break
    case LedgerItemStatus.EXPIRED:
      badgeClass = 'is-danger'
      badgeText = t('StatusBadge::Expired')
      break
    case LedgerItemStatus.CREDITED:
      badgeClass = 'is-success'
      badgeText = t('StatusBadge::Credited')
      break
    case LedgerItemStatus.SCHEDULED:
      badgeClass = 'is-success'
      badgeText = t('StatusBadge::Scheduled')
      break
  }

  return (
    <Container>
      <Badge className={badgeClass}>
        {badgeText}
      </Badge>
    </Container>
  );
}

export default StatusBadge