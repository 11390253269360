import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { RouteComponentProps } from 'react-router'
import ProjectTable, { IComponentProps as ProjectTableProps } from '../Projects/ProjectTable'
import { CurrentUser } from '../../types'

interface IStateToProps {
  currentUser: CurrentUser
}
type IProps = {
  contactId: string
} & IStateToProps & RouteComponentProps<any>

const ContactProjects = (props: IProps) => {
  const { currentUser, contactId } = props

  const projectTableProps: ProjectTableProps = {
    onItemDeleted: () => { },
    onItemUpdated: () => { },
    title: '',
    contactId: contactId,
    primaryActionEnabled: true
  }

  return (
    <>
      <ProjectTable {...projectTableProps} />
    </>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

export default connect(mapStateToProps)(ContactProjects)