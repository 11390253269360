import * as React from 'react'
import { Task } from '../../types'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { useTranslation } from 'react-i18next'
import { Draggable } from 'react-beautiful-dnd'
import moment from '../../utilities/Moment'

const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: column;
	min-height: 100px;
	height: 100px;
	width: 100%;
	border-radius: ${Style.variables.baseBorderRadius};
	border: 1px solid ${Style.color.border};
  padding: 8px;
  background: white;

  ${props => props.isDragging && css`
		opacity: .5;
		pointer-events: none;
    width: 50px;
	`}
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: black;
`

const Project = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-bottom: 12px;
`

const Metadata = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 100%;
`

const EndDate = styled.div`
  font-size: 12px;
  font-weight: 500;
`

const PlannableTime = styled.div`
  font-size: 16px;
  font-weight: 500;
`

interface IProps {
  index: number
  task: Task
}

const PlannerSidebarTask = (props: IProps) => {
  const { t } = useTranslation()
  const { index, task } = props

  const getStyle = (style, snapshot) => {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.000000000001s`,
    };
  }

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => {
        const isDragging = snapshot.isDragging

        return (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={isDragging}
            style={getStyle(provided.draggableProps.style, snapshot)}
          >
            <Title>{task.name}</Title>
            <Project>{task.project ? task.project.name : t('PlannerSidebarTask::No project')}</Project>
            <Metadata>
              <EndDate>
                {task.due_on ? moment(task.due_on).format('DD-MM-YYYY') : t('PlannerSidebarTask::No end date')}
              </EndDate>

              <PlannableTime>
                {moment.duration(task.time_remaining, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small' })}
              </PlannableTime>
            </Metadata>
          </Container>
        )
      }}
    </Draggable>
  )
}

export default PlannerSidebarTask