import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import Alert from '../../components/Alert/Alert'
import LedgerItemHelper from '../../helpers/LedgerItemHelper';
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../components/Editor/Editor';
import EditorContainer from '../../components/Editor/EditorContainer';
import LocaleHelper from '../../helpers/LocaleHelper';
import PowerSelect from '../../components/Form/PowerSelect';
import { DEFAULT_LOCALE } from '../../Constants';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import MobilityHelper from '../../helpers/MobilityHelper';
import { CurrentUser, DisplayableError, Locale, Settings } from '../../types';
import CheckboxInput from '../../components/Form/CheckboxInput';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  activeTranslationsLocale: Locale
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class DeliveryNotes extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      activeTranslationsLocale: DEFAULT_LOCALE,
      form: null,
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onToggleEnableDeliveryNotes = this.onToggleEnableDeliveryNotes.bind(this)
    this.onDefaultLedgerItemLocaleChange = this.onDefaultLedgerItemLocaleChange.bind(this)
    this.onTranslationsLocaleChange = this.onTranslationsLocaleChange.bind(this)
    this.onDeliveryNotePrefixNumberChange = this.onDeliveryNotePrefixNumberChange.bind(this)
    this.onDeliveryNoteNumberChange = this.onDeliveryNoteNumberChange.bind(this)
    this.onDeliveryNoteSuffixNumberChange = this.onDeliveryNoteSuffixNumberChange.bind(this)
    this.onDeliveryNoteDetailsChange = this.onDeliveryNoteDetailsChange.bind(this)
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    SettingsController
      .update(settings)
      .then(response => {
        if (response.errors) {
          Notification.notifyError(t('SettingsDeliveryNotes::Oops something went wrong'));
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update local
          this.setState({ settings: settings })
          // Update global
          updateSettings(settings)

          Notification.notifySuccess(t('SettingsDeliveryNotes::Settings successfully updated'));
        }
      })
      .catch(console.error)
  }

  onToggleEnableDeliveryNotes() {
    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        delivery_notes_enabled: !settings.delivery_notes_enabled
      }
    })
  }

  onDefaultLedgerItemLocaleChange(e) {
    const newLocale = e.currentTarget.value

    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        default_ledger_item_locale: newLocale
      }
    })
  }

  onDeliveryNotePrefixNumberChange(e) {
    const prefixDeliveryNoteNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        prefix_delivery_note_number: prefixDeliveryNoteNumber
      },
    });
  }

  onDeliveryNoteNumberChange(e) {
    const deliveryNoteNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        delivery_note_number: deliveryNoteNumber,
      },
    });
  }

  onDeliveryNoteSuffixNumberChange(e) {
    const suffixDeliveryNoteNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        suffix_delivery_note_number: suffixDeliveryNoteNumber,
      },
    });
  }


  onTranslationsLocaleChange(option) {
    this.setState({ activeTranslationsLocale: option.value });
  }

  onDeliveryNoteDetailsChange(details: string) {
    const { settings, activeTranslationsLocale } = this.state;

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationsLocale, settings.translations, 'default_delivery_note_details', details)

    this.setState({
      settings: {
        ...settings,
        translations: { ...updatedTranslations }
      }
    });
  }


  render() {
    const { t } = this.props
    const { settings, errors, form, activeTranslationsLocale } = this.state

    if (form) {
      const localeOptions = LocaleHelper.getLocaleOptions()
      const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

      return (
        <>
          <Helmet>
            <title>{t('SettingsDeliveryNotes::{{__appName}} | Delivery notes')} </title>
          </Helmet>
          <ScrollToTopOnMount />
          <form onSubmit={this.onFormSubmit}>
            <Title>{t('SettingsDeliveryNotes::Delivery notes')}</Title>

            <Panel>
              <div className='grid'>
                <div className='grid-cell with-4col'>
                  <div className='form-item'>
                    <CheckboxInput
                      onChange={this.onToggleEnableDeliveryNotes}
                      checked={settings.delivery_notes_enabled}
                      label={t('SettingsDeliveryNotes::Enabled')}
                    />
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsDeliveryNotes::Default delivery note language')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.default_ledger_item_locale} onChange={this.onDefaultLedgerItemLocaleChange}>
                        {localeOptions.map(locale => {
                          return (
                            <option key={locale.value} value={locale.value}>{locale.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsDeliveryNotes::Delivery note number format')}</label>
                    <div style={{ marginBottom: 8 }}>
                      <Alert
                        type='info'
                        text={
                          <Trans t={t}>
                            <div>The number of your next delivery note is "<b>{{ prefix_delivery_note_number: settings.prefix_delivery_note_number }}{{ next_delivery_note_number: LedgerItemHelper.getSuccessiveLedgerNumber(settings.delivery_note_number) }}{{ suffixed_delivery_note_number: settings.suffix_delivery_note_number }}</b>"</div>
                          </Trans>
                        }
                      />
                    </div>

                    <div className='settings-numbering-format'>
                      <input
                        type='text'
                        name='prefix'
                        placeholder={t('SettingsDeliveryNotes::Prefix')}
                        value={settings.prefix_delivery_note_number}
                        onChange={this.onDeliveryNotePrefixNumberChange}
                      />
                      <input
                        type='number'
                        name='number'
                        placeholder='1'
                        value={settings.delivery_note_number}
                        onChange={this.onDeliveryNoteNumberChange}
                        pattern='\d*'
                        min={0}
                        required
                      />
                      <input
                        type='text'
                        name='suffix'
                        placeholder={t('SettingsDeliveryNotes::Suffix')}
                        value={settings.suffix_delivery_note_number}
                        onChange={this.onDeliveryNoteSuffixNumberChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsDeliveryNotes::Default delivery note footnote')}</label>

                    <PowerSelect
                      options={localeOptions}
                      value={selectedLocaleOption}
                      onChange={this.onTranslationsLocaleChange}
                      noOptionsMessage={(value) => t('SettingsDeliveryNotes::No language found.')}
                      theme={ReactSelectTheme}
                    />

                    <EditorContainer style={{ marginTop: 8 }}>
                      <Editor
                        model={MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'default_delivery_note_details')}
                        onModelChange={this.onDeliveryNoteDetailsChange}
                        config={{
                          ...MINIMAL_EDITOR_CONFIG,
                          heightMin: 120,
                          heightMax: 120
                        }}
                      />
                    </EditorContainer>
                  </div>
                </div>
              </div>
              <div className='field-actions'>
                <input type='submit' style={{ display: 'none' }} />
                <div className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('SettingsDeliveryNotes::Save')} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>
      )
    }
    else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeliveryNotes))