import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Panel from '../Panel/Panel'

const ItemContainer = styled.div<{ active: boolean }>`
	display: flex;
	flex-direction: row;
	overflow-y: hidden;
	overflow-x: auto;
	
	${props => props.active && css`
		border-bottom: 1px solid ${Style.color.border};
	`}

	&::-webkit-scrollbar {
    background: white;
		height: 8px;
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 4px;
	}
`

const Item = styled.div<{ active: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex: 1;
	padding: 18px;

	&:hover {
		background: #f7f7fa;;

		${props => props.active && css`
			background: #f2f8ff;
		`}
	}
	
	&:not(:first-child) {
		border-left: 1px solid ${Style.color.border};
	}

	${props => props.active && css`
		background: #f2f8ff;
	`}

	@media (max-width: ${Style.breakpoints.SMALL}) {
		min-width: 200px;
	}
`

const Title = styled.div`
	font-size: 15px;
	font-weight: 700;
`

const Value = styled.div`
	font-size: 16px;
`

interface IProps {
	activeKey?: string
	items: {
		key: 'pending' | 'outstanding' | 'overdue' | 'paid',
		label: string,
		labelClass?: string,
		value: string,
		valueClass?: string
		content: JSX.Element
	}[]
	onItemClick: (key: string) => void
}

const SummaryWithContent = (props: IProps) => {
	const { items, activeKey, onItemClick } = props
	const { t } = useTranslation()

	return (
		<Panel title={t('SummaryWithContent::Invoices')} contentClasses='no-padding'>
			<ItemContainer active={Boolean(activeKey)}>
				{items.map(item => {
					return (
						<Item key={item.key} active={activeKey === item.key} onClick={(e) => onItemClick(item.key)}>
							<Title>
								{item.label}
							</Title>

							<Value>
								{item.value}
							</Value>
						</Item>
					)
				})}
			</ItemContainer>

			{activeKey && <div key={activeKey}>
				{items.find(item => item.key === activeKey).content}
			</div>}
		</Panel>
	)
}

export default SummaryWithContent