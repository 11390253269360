import * as React from 'react'
import Icon from '../Icons/Icon'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

interface IProps {
  imageUrl: string
  title: string
  price: string
  period: string
  features: string[]
  active: boolean
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const PricingCard = (props: IProps) => {
  const {
    imageUrl,
    title,
    price,
    period,
    features,
    active,
    onClick,
  } = props
  const { t } = useTranslation()

  return (
    <div className={`pricing-card ${active ? 'is-active' : ''}`}>
      <div className='pricing-card-illustration' style={{ backgroundImage: `url(${imageUrl})` }} />

      <div className='pricing-card-title-content'>
        <span className='pricing-card-title'>{title}</span>

        <span className='pricing-card-tag'>
          <span className='price'>{price}</span>
          <span className='period'>{period}</span>
        </span>
      </div>

      <div className='pricing-features'>
        <ul>
          {features.map(feature => (<li key={feature}><Icon icon='list-check' /> <span dangerouslySetInnerHTML={{ __html: feature }} /></li>))}
        </ul>
      </div>

      <a href='javascript://' className={`pricing-card-action ${active ? 'is-active' : ''}`} onClick={onClick}>
        {active ? t('PricingCard::SUSPEND') : t('PricingCard::SUBSCRIBE')}
      </a>
    </div>
  )
}


export default PricingCard