import * as React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../../Icons/Icon'
import { useTranslation } from 'react-i18next'
import UserWorkspaceSettingHelper from '../../../helpers/UserWorkspaceSettingHelper'
import { UserWorkspaceSettingScope } from '../../../types'
import TimelineTooltip, { TimelineTooltipEvent, TimelineTooltipEventType } from '../../Tooltips/TimelineTooltip'

const GraphItemTickContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	color: #CCCCCC;
	font-weight: 600;
`


const TopActionsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	position: absolute;
	right: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	top: 0;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		height: 100%;
		cursor: pointer;
		font-size: 18px;

		&:first-child {
			background: #E6E6E6;
			color: #999999;
			border-top-left-radius: 5px;
		}

		&:last-child {
			border-top-right-radius: 5px;
			background: #6BBD2E;
			color: white;
		}
	}
`

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: flex-end;
	height: 100%;
	padding: 0 24px;
	
	&:not(:last-child) {
		border-right: 2px dotted #F4F6F8;
	}

	&:hover {
		background: #EAF4FB;

		${GraphItemTickContainer} {
			font-weight: bold;
			color: #999999;
		}

		${TopActionsContainer} {
			top: -40px;
			opacity: 1;
			visibility: visible;
		}
	}
`

const TopTickContainer = styled(GraphItemTickContainer)`
	position: absolute;
	top: -40px;
	right: 0;
	left: 0;

	&::after {
		width: 2px;
    height: 10px;
    background-color: #E6E6E6;
    content: "";
    position: absolute;
    bottom: 0;
	}
`

const BottomTickContainer = styled(GraphItemTickContainer)`
	position: absolute;
	bottom: -40px;
	right: 0;
	left: 0;
	border-right: 2px dotted #F4F6F8;

	&::after {
		width: 2px;
    height: 10px;
    background-color: #E6E6E6;
    content: "";
    position: absolute;
    top: 0;
	}
`

const ItemTooltip = styled.div<{ backgroundColor?: string, color?: string }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	left: 50%;
	top: -75px;
	transform: translateX(-50%);
	padding: 8px 16px;
	background: #6BBD2E; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: 5;
	width: max-content;

	${(props) => props.backgroundColor && css`
		background: ${props.backgroundColor};
	`}

	span {
		text-align: center;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			font-weight: normal;
		}
	}

	&:after {
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-width: 10px;

		${(props) => css`
			border-top-color: ${props.backgroundColor || '#6BBD2E'};
		`}
	}
`

const Item = styled.div<{ backgroundColor?: string }>`
	position: relative;
	display: flex;
	flex: 1;
	background: ${props => props.backgroundColor || '#6BBD2E'};
	height: 100%;
	transition: max-height 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
	width: 100%;


	&:hover {
		${ItemTooltip} {
			opacity: 1;
			visibility: visible;
		}
	}
`

interface IProps {
	topTick?: string
	timeHeight?: string
	timeTooltip?: string
	leaveHeight?: string
	leaveTooltip?: string
	bottomTick?: string
	onTimerStartClick: () => void
	onAddTimeClick: () => void
}

const TrackItem = (props: IProps) => {
	const { t } = useTranslation()
	const timeContainer = React.useRef<HTMLDivElement>(null)
	const leaveContainer = React.useRef<HTMLDivElement>(null)

	const {
		topTick,
		timeHeight,
		timeTooltip,
		leaveHeight,
		leaveTooltip,
		bottomTick,
		onTimerStartClick,
		onAddTimeClick,
	} = props


	return (
		<Container>
			{topTick && <TopTickContainer>
				{topTick}
			</TopTickContainer>}

			<TopActionsContainer>
				{onTimerStartClick && <div onClick={onTimerStartClick}>
					<Icon icon='stopwatch' />
				</div>}

				<div onClick={onAddTimeClick}>
					<Icon icon='plus' />
				</div>
			</TopActionsContainer>

			<Item
				style={{ maxHeight: timeHeight || '0%' }}
				ref={timeContainer}
				onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					const { clientX } = event

					if (UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && timeContainer.current) {
						const { top, left, width } = timeContainer.current.getBoundingClientRect()


						document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSEMOVE, {
							detail: {
								title: t('TrackItem::Amount (excl. VAT)'),
								description: timeTooltip,
								x: left + width / 2,
								y: top,
								backgroundColor: '#6BBD2E',
							}
						}))
					}
				}}
				onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSELEAVE, {
						detail: {
							title: null,
							description: null,
							backgroundColor: null,
							textColor: null,
							x: null,
							y: null
						}
					}))
				}}
			/>

			<Item
				style={{ maxHeight: leaveHeight || '0%' }}
				ref={leaveContainer}
				backgroundColor='#CCC'
				onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					const { clientX, } = event

					if (leaveContainer.current) {
						const { top, left, width } = leaveContainer.current.getBoundingClientRect()

						document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSEMOVE, {
							detail: {
								title: t('TrackItem::Leave'),
								description: leaveTooltip,
								// Place in center
								x: left + width / 2,
								y: top,
								backgroundColor: '#CCC',
							}
						}))
					}
				}}
				onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					document.dispatchEvent(new TimelineTooltipEvent(TimelineTooltipEventType.MOUSELEAVE, {
						detail: {
							title: null,
							description: null,
							backgroundColor: null,
							textColor: null,
							x: null,
							y: null
						}
					}))
				}}
			/>

			{bottomTick && <BottomTickContainer>
				{bottomTick}
			</BottomTickContainer>}

			<TimelineTooltip />
		</Container>
	)
}

export default TrackItem