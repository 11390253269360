import * as React from 'react'
import styled from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background: rgb(241, 241, 241);
	cursor: pointer;

	&:hover {
		filter: brightness(90%);
	}

	svg {
		fill: rgb(116, 121, 142);
	}

	i {
		color: rgb(116, 121, 142);
	}
`

interface IProps {
	icon: IconTypes
	onClick: () => void
}

const ConversationIconAction = (props: IProps) => {
	return (
		<Container onClick={props.onClick}>
			<Icon icon={props.icon} />
		</Container>
	)
}

export default ConversationIconAction