import * as React from 'react'
import styled, { css } from "styled-components"
import { Style } from "../../styles"
import Icon from '../Icons/Icon'

export const Container = styled.div<{ active?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 18px;
	min-height: 18px;
	width: 18px;
	height: 18px;
	color: #82828c;
	fill: #82828c;
	border-radius: ${Style.variables.baseBorderRadius};
	cursor: pointer;
	opacity: 0;

	${props => props.active && css`
		display: flex;
		opacity: 1;
		color black;
		fill: black;
	`}

	&:hover {
		background: #82828c2e;
	}

	i {
		font-size: 10px;
	}

	svg {
		width: 10px;
	}
`

interface IProps {
	property: string
	sortValue?: string
	onClick: (order: string) => void
}

const TableSortButton = (props: IProps) => {
	const { sortValue, property } = props

	const active = [`${props.property}_asc`, `${props.property}_desc`].includes(sortValue)

	const parts = sortValue?.split('_')
	let order = (parts || [])[parts?.length - 1]

	const onClick = () => {
		if (active) {
			switch (sortValue) {
				case undefined:
				case null:
				case '-':
					props.onClick(`${props.property}_asc`)
					break
				case `${property}_asc`:
					props.onClick(`${props.property}_desc`)
					break
				case `${property}_desc`:
					props.onClick(null)
					break
				default:
			}
		} else {
			props.onClick(`${props.property}_asc`)
		}
	}

	return (
		<Container active={active} onClick={onClick}>
			{active && <>
				{(order === null || order === undefined || order === 'asc' || order == '-') && <Icon icon='arrow-up' />}
				{order === 'desc' && <Icon icon='arrow-down' />}
			</>}
			{!active && <Icon icon='arrow-up' />}
		</Container>
	)
}

export default TableSortButton