import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
`

const ActivatorContainer = styled.div`

`

const ContentContainer = styled.div``

interface IProps {
  active: boolean
  activator: React.ReactNode
  children: React.ReactNode | React.ReactNode[]
  onActivatorEnter: () => void
  onActivatorClick: () => void
  onActiveChange: (active: boolean) => void
}

interface IState {
}

class SidebarNavigationFlyout extends React.Component<IProps, IState> {
  private flyoutTimeout: number | null | NodeJS.Timeout

  constructor(props: IProps) {
    super(props)
  }

  closeFlyout = () => {
    const { active, onActiveChange } = this.props
    if (active) onActiveChange(false)
  }

  onActivatorMouseEnter = () => {
    const { onActivatorEnter, active, onActiveChange } = this.props
    // Used for direct feedback when hovering an activator sibling
    onActivatorEnter()

    if (this.flyoutTimeout) {
      clearTimeout(this.flyoutTimeout)
      this.flyoutTimeout = null
    }

    if (active) return

    onActiveChange(true)
  }

  onActivatorMouseLeave = () => {
    if (!this.flyoutTimeout) {
      this.flyoutTimeout = setTimeout(this.closeFlyout, 500)
    }
  }

  onContentMouseEnter = () => {
    if (this.flyoutTimeout) {
      clearTimeout(this.flyoutTimeout)
      this.flyoutTimeout = null
    }
  }

  onContentMouseLeave = () => {
    const { active, onActiveChange } = this.props
    if (active) onActiveChange(false)
  }

  render() {
    const { active, activator, children, onActivatorClick } = this.props

    return (
      <Container>
        <ActivatorContainer
          onClick={onActivatorClick}
          onMouseEnter={this.onActivatorMouseEnter}
          onMouseLeave={this.onActivatorMouseLeave}
        >
          {activator}
        </ActivatorContainer>

        <ContentContainer
          onMouseEnter={this.onContentMouseEnter}
          onMouseLeave={this.onContentMouseLeave}
        >
          {active && children}
        </ContentContainer>
      </Container>
    )
  }
}

export default SidebarNavigationFlyout