import moment from 'moment'
import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import FileHelper from '../../helpers/FileHelper'
import TaskHelper from '../../helpers/TaskHelper'
import { Style } from '../../styles'
import { BoardLabel, Task, TaskChecklistItem } from '../../types'
import Avatar from '../Avatar/Avatar'
import Icon from '../Icons/Icon'
import ResourceCheckbox from '../Resource/ResourceCheckbox'
import TaskLabel from '../Tasks/TaskLabel'
import CardCover from './CardCover'
import AvatarStack from '../Avatar/AvatarStack'
import TimeFormatter from '../../utilities/TimeFormatter'
import TaskIndicators from '../Tasks/TaskIndicators'
import TaskIndicatorsContainer from '../Tasks/TaskIndicatorsContainer'

const Container = styled.a`
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 0 rgba(9,30,66,.25);
	cursor: pointer !important;
	display: block;
	margin-bottom: 8px;
	max-width: 300px;
	min-height: 20px;
	position: relative;
	text-decoration: none;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const DraggingFilesOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: hsla(0,0%,100%,.7);
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  z-index: 11;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DraggingFilesOverlayContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: grabbing;
`

const Details = styled.div`
  overflow: hidden;
  padding: 6px 8px 2px;
  position: relative;
`

const Labels = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
`

const TitleAvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${Style.spacing.x1};
  gap: 8px;
`

const Title = styled.div`
    display: block;
    margin: 0 0 4px;
    overflow: hidden;
    text-decoration: none;
    word-wrap: break-word;
    color: #172b4d;
`

const BadgeCheckbox = styled.div`
  margin-bottom: 2px;
`

interface IProps {
  index: number
  task: Task
  labels: BoardLabel[]
  labelsExpanded: boolean
  onClick: (task: Task) => void
  onCompletedToggle: (task: Task) => void
  onToggleLabels: () => void
  onFilesDropped: (taskId: string, files: File[]) => void
}

const Card = (props: IProps) => {
  const { t } = useTranslation()
  const { index, task, labels, labelsExpanded, onClick, onToggleLabels } = props
  const {
    name,
    due_on,
    completed,
  } = task

  const [editMode, setEditMode] = React.useState(false);
  const [isDraggingFiles, setIsDraggingFiles] = React.useState(false);
  const taskLabels = labels.filter(label => task.label_ids.includes(label.id))

  const onLabelClick = (e) => {
    // Stop default action
    e.preventDefault()
    // Stop event bubbling
    e.stopPropagation()

    onToggleLabels()
  }

  const onAttachmentsDragEnter = (e) => {
    e.preventDefault()

    if (!isDraggingFiles) setIsDraggingFiles(true)
  }

  const onAttachmentDragOver = (e) => {
    e.preventDefault()

    if (!isDraggingFiles) setIsDraggingFiles(true)
  }

  const onAttachmentsDragLeave = (e) => {
    if (isDraggingFiles) setIsDraggingFiles(false)
  }

  const onAttachmentsDrop = (e) => {
    e.preventDefault()

    // Get dropped attachments
    const files: File[] = Object.values(e.dataTransfer.files)

    // Upload attachments
    props.onFilesDropped(task.id, files)

    // Reset overlay
    setIsDraggingFiles(false)
  }

  const onCompletedToggle = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.onCompletedToggle(task)
  }

  return (
    <Draggable
      key={task.id}
      draggableId={task.id}
      index={index}
      disableInteractiveElementBlocking={!editMode}
    >
      {(draggableProvided, draggableSnapshot) => (
        <Container
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          onClick={() => onClick(task)}
        >
          <Wrapper
            onDragEnter={onAttachmentsDragEnter}
            onDragOver={onAttachmentDragOver}
            onDrop={onAttachmentsDrop}
          >
            {isDraggingFiles && <DraggingFilesOverlay onDragLeave={onAttachmentsDragLeave}>
              <DraggingFilesOverlayContent>
                {t('Card::Drop files to upload')}
              </DraggingFilesOverlayContent>
            </DraggingFilesOverlay>}
            <Details>
              {task.cover && FileHelper.displayable(task.cover) && <CardCover cover={task.cover.attachment_url} />}

              {taskLabels.length > 0 && <Labels onClick={onLabelClick}>
                {taskLabels.map((label) => {
                  return (
                    <TaskLabel
                      key={label.id}
                      labelsExpanded={labelsExpanded}
                      background={label.color}
                    >
                      <span>{label.name}</span>
                    </TaskLabel>
                  )
                })}
              </Labels>}

              <TitleAvatarContainer>
                <Title>
                  {name}
                </Title>

                {task?.assignees?.length > 0 && <AvatarStack width={30}>
                  {task?.assignees?.map(assignee => (
                    <Avatar
                      rounded={true}
                      name={TaskHelper.getAssigneeName(assignee)}
                      width={30}
                    />
                  ))}
                </AvatarStack>}
              </TitleAvatarContainer>

              <TaskIndicatorsContainer>
                <BadgeCheckbox onClick={onCompletedToggle}>
                  <ResourceCheckbox
                    checked={Boolean(task.completed)}
                    disabled={false}
                    onCheckedChange={() => { }}
                  />
                </BadgeCheckbox>

                <TaskIndicators task={task} />
              </TaskIndicatorsContainer>
            </Details>
          </Wrapper>
        </Container>
      )}
    </Draggable>
  )
}

export default Card