import styled from 'styled-components'

export default styled.div`
position: absolute;
right: 20px;
top: 50%;
transform: translateY(-50%);
display: none;
justify-content: center;
align-items: center;

svg {
	width: 15px;
	height: 10px;
	fill: #1d3451;
}
`