import * as React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import TopNavigationButtonWithOptions from '../components/Navigation/TopNavigationButtonWithOptions'
import InvoiceSummary from '../components/Invoices/InvoiceSummary'
import { AppState } from '../store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ERoute from '../ERoute';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import LedgerItemsTable from '../components/Invoices/LedgerItemsTable'
import { NavLink } from 'react-router-dom'
import RouteHelper from '../helpers/RouteHelper'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageContent from '../components/Page/PageContent';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { showExportLedgerItemsModal } from '../store/modals/actions';
import PageHeader from '../components/Page/PageHeader';
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper';
import { CurrentUser, LedgerItemType, UserWorkspaceSettingScope } from '../types';

interface IContentItem {
  key: string
  title: string
  type: LedgerItemType
  to: ERoute
  component: React.ComponentClass<any, any>
  visible: boolean
}

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showExportLedgerItemsModal: typeof showExportLedgerItemsModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<any> & WithTranslation

interface IState {
  content: IContentItem[]
}

class Invoices extends React.Component<IProps, IState> {
  private invoiceSummary = React.createRef<InvoiceSummary>()
  constructor(props: IProps) {
    super(props);

    const { t, currentUser: { workspace: { setting } } } = props

    this.state = {
      content: [
        { key: LedgerItemType.ORDER_FORM, title: t('Invoices::Order forms'), type: LedgerItemType.ORDER_FORM, to: ERoute.PATH_ORDER_FORMS, component: LedgerItemsTable, visible: setting.order_forms_enabled },
        { key: LedgerItemType.DELIVERY_NOTE, title: t('Invoices::Delivery notes'), type: LedgerItemType.DELIVERY_NOTE, to: ERoute.PATH_DELIVERY_NOTES, component: LedgerItemsTable, visible: setting.delivery_notes_enabled },
        { key: LedgerItemType.QUOTATION, title: t('Invoices::Quotations'), type: LedgerItemType.QUOTATION, to: ERoute.PATH_QUOTATIONS, component: LedgerItemsTable, visible: true },
        { key: LedgerItemType.PRO_FORMA_INVOICE, title: t('Invoices::Proforma invoices'), type: LedgerItemType.PRO_FORMA_INVOICE, to: ERoute.PATH_PRO_FORMA_INVOICES, component: LedgerItemsTable, visible: setting.pro_forma_invoices_enabled },
        { key: LedgerItemType.INVOICE, title: t('Invoices::Invoices'), type: LedgerItemType.INVOICE, to: ERoute.PATH_INVOICES, component: LedgerItemsTable, visible: true },
        { key: LedgerItemType.CREDIT_NOTE, title: t('Invoices::Credit notes'), type: LedgerItemType.CREDIT_NOTE, to: ERoute.PATH_CREDIT_NOTES, component: LedgerItemsTable, visible: true },
        { key: LedgerItemType.RECURRING_INVOICE, title: t('Invoices::Recurring invoices'), type: LedgerItemType.RECURRING_INVOICE, to: ERoute.PATH_RECURRING_INVOICES, component: LedgerItemsTable, visible: true },
      ]
    }

    this.onTopNavigationActionClick = this.onTopNavigationActionClick.bind(this)
    this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)
    this.onExportLedgerItemsClick = this.onExportLedgerItemsClick.bind(this)
    this.onTableItemUpdated = this.onTableItemUpdated.bind(this)
    this.onTableItemDeleted = this.onTableItemDeleted.bind(this)
  }

  navigateTo(route: string) {
    const { history } = this.props

    history.push(route)
  }

  onTopNavigationActionClick(key: string) {
    switch (key) {
      case LedgerItemType.ORDER_FORM:
        this.navigateTo(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.ORDER_FORM }))
        break
      case LedgerItemType.DELIVERY_NOTE:
        this.navigateTo(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.DELIVERY_NOTE }))
        break
      case LedgerItemType.QUOTATION:
        this.navigateTo(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.QUOTATION }))
        break
      case LedgerItemType.PRO_FORMA_INVOICE:
        this.navigateTo(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.PRO_FORMA_INVOICE }))
        break
      case LedgerItemType.INVOICE:
        this.navigateTo(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.INVOICE }))
        break
      case LedgerItemType.CREDIT_NOTE:
        this.navigateTo(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.CREDIT_NOTE }))
        break
      case LedgerItemType.RECURRING_INVOICE:
        this.navigateTo(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.RECURRING_INVOICE }))
        break
      default:
        throw new Error(`[Invoices] action not implemented for key: ${key} `)
    }
  }

  onPageHeaderActionClick(key: string) {
    switch (key) {
      case 'export_ledger_items': this.onExportLedgerItemsClick()
        break
    }
  }

  onExportLedgerItemsClick() {
    this.props.showExportLedgerItemsModal({})
  }

  onTableItemUpdated() {
    this.invoiceSummary.current.fetchSummary()
  }

  onTableItemDeleted() {
    this.invoiceSummary.current.fetchSummary()
  }

  render() {
    const { content } = this.state
    const { location, currentUser, t } = this.props
    const { workspace: { setting } } = currentUser

    return (
      <>
        <Helmet>
          <title>{t('Invoices::{{__appName}} | Invoices')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='invoice'
          title={t('Invoices::Invoices')}
          action={
            <TopNavigationButtonWithOptions
              icon='plus'
              text={t('Invoices::New document')}
              actions={[
                { key: LedgerItemType.ORDER_FORM, content: t('Invoices::Order form'), visible: setting.order_forms_enabled },
                { key: LedgerItemType.DELIVERY_NOTE, content: t('Invoices::Delivery note'), visible: setting.delivery_notes_enabled },
                { key: LedgerItemType.QUOTATION, content: t('Invoices::Quotation') },
                { key: LedgerItemType.PRO_FORMA_INVOICE, content: t('Invoices::Proforma invoice'), visible: setting.pro_forma_invoices_enabled },
                { key: LedgerItemType.INVOICE, content: t('Invoices::Invoice') },
                { key: LedgerItemType.CREDIT_NOTE, content: t('Invoices::Credit note') },
                { key: LedgerItemType.RECURRING_INVOICE, content: t('Invoices::Recurring invoice') },
              ]}
              onActionClick={this.onTopNavigationActionClick}
            />
          }
        />

        <PageContent>
          <PageHeader
            title={t('Invoices::Invoices')}
            mainActions={UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE_EXPORT) ? [
              { key: 'export_ledger_items', content: t('Invoices::Export invoices'), icon: 'download-circle' },
            ] : []}
            onMainActionClick={this.onPageHeaderActionClick}
          />

          <InvoiceSummary
            ref={this.invoiceSummary}
            setting={setting}
          />

          <div className='content-tabs'>
            {content.filter(c => c.visible).map((contentItem, index) => {
              return (
                <NavLink
                  key={contentItem.key}
                  to={contentItem.to}
                  className='content-tab'
                  activeClassName='is-active'
                >
                  {contentItem.title}
                  <div className='content-tab-indicator' />
                </NavLink>
              )
            })}
          </div>


          <div className='content-tab-content'>
            <Switch location={location}>
              {content.map(contentItem => {
                return (
                  <Route
                    key={contentItem.key}
                    path={contentItem.to}
                    render={(routerProps) => {
                      return (
                        <contentItem.component
                          {...routerProps}
                          currentUser={currentUser}
                          title={contentItem.title}
                          type={contentItem.type}
                          onItemUpdated={this.onTableItemUpdated}
                          onItemDeleted={this.onTableItemDeleted}
                        />
                      )
                    }}
                  />
                )
              })}
              <Redirect from='/' to={ERoute.PATH_INVOICES} />
            </Switch>
          </div>
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showExportLedgerItemsModal: (options) => dispatch(showExportLedgerItemsModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Invoices))