import '../components/Editor/css'
import 'font-awesome/css/font-awesome.css';
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import BlocksRenderer, { BlockRenderMode } from "../components/BlockEditor/BlocksRenderer"
import BlocksWrapper from "../components/BlockEditor/BlocksWrapper"
import { DEFAULT_LOCALE, SENTRY_DSN } from '../Constants'
import { ContentBlock, ContentBlockVariables, NumberFormat, Signature } from '../types';
import i18next from 'i18next';

Sentry.init({ dsn: SENTRY_DSN, })

interface IProps {
	blocks: ContentBlock[]
	currency: string
	numberFormat: NumberFormat
	signatures: Signature[]
	variables: ContentBlockVariables
	locale: string
}

const Application = (props: IProps) => {
	const {
		blocks,
		currency,
		numberFormat,
		signatures,
		variables,
		locale
	} = props

	React.useEffect(() => {
		i18next.changeLanguage(locale || DEFAULT_LOCALE, (err, t) => { });
	}, [])

	return (
		<React.Suspense fallback='loading'>
			<BlocksWrapper className='preview renderer'>
				<BlocksRenderer
					blocks={blocks}
					signatures={signatures}
					currency={currency}
					numberFormat={numberFormat}
					variables={variables}
					renderMode={BlockRenderMode.RENDER}
				/>
			</BlocksWrapper>
		</React.Suspense>
	)
}

export default Application