import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Button from '../Button/Button'
import Icon from '../Icons/Icon'
import TaskCancelAction from '../TaskModal/TaskCancelAction'

const Container = styled.div<{ editing: boolean }>`
	padding: ${Style.spacing.x0_5};
	width: 100%;

	textarea {
		margin-bottom: ${Style.spacing.x0_5};
	}

	form {
		margin: 0 ${Style.spacing.x0_5};
	}
`

const CardComposer = styled.div`
	padding-bottom: 8px;
`

const CardComposerCard = styled.div`
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(9,30,66,.25);
    cursor: pointer;
    display: block;
    margin-bottom: 8px;
    max-width: 300px;
    min-height: 20px;
    position: relative;
    text-decoration: none;
    z-index: 0;
`

const CardComposerCardDetails = styled.div`
    overflow: hidden;
    padding: 6px 8px 2px;
		position: relative;
		
		textarea {
			overflow: hidden;
			overflow-wrap: break-word;
			resize: none;
			height: 54px;
			border: none;
			color: #172b4d;
			&:focus {
				border: none;
				box-shadow: none;
			}
		}
`

const FormActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const AddAction = styled.a`
  border-radius: 3px;
  color: #5e6c84;
  display: block;
  flex: 1 0 auto;
  margin: 2px 4px 2px 4px;
  padding: 4px 8px;
  position: relative;
  text-decoration: none;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(9,30,66,.08);
    color: #172b4d;
  }

  i, svg {
    color: #42526e;
    fill: #42526e;
    margin-right: 4px;
  }
`

interface IProps {
	onSubmit: (name: string) => void
	onFilesDropped: (files: File[]) => void
}

const AddCard = (props: IProps) => {
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>(null)
	const [editing, setEditing] = React.useState(false)
	const [name, setName] = React.useState('')

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideContainerClick)

		return () => {
			document.removeEventListener('mousedown', onOutsideContainerClick)
		}
	}, [])

	const reset = () => {
		setName('')
		setEditing(false)
	}

	const onOutsideContainerClick = (e) => {
		if (container.current && !container.current.contains(e.target)) {
			// If name has been entered submit
			if (name.length > 0) props.onSubmit(name)

			reset()
		}
	}

	const onDragOver = (e) => {
		e.preventDefault()
	}

	const onFilesDropped = (e) => {
		e.preventDefault()

		const dataTransfer: DataTransfer = e.dataTransfer
		const files = Object.values(dataTransfer.files)

		props.onFilesDropped(files)

		reset()
	}

	const onActionClick = () => {
		setEditing(true)
	}

	const onNameChange = (e) => {
		setName(e.currentTarget.value)
	}

	const onFormSubmit = (e) => {
		e.preventDefault()

		if (name !== '') {
			props.onSubmit(name)
		}

		reset()
	}

	const onCancelClick = () => {
		reset()
	}

	return (
		<Container
			ref={container}
			editing={editing}
			onDragOver={onDragOver}
			onDrop={onFilesDropped}
		>
			{editing === false && <AddAction onClick={onActionClick}>
				<Icon icon='plus' />
				{t('AddCard::Add another task')}
			</AddAction>}

			{editing === true && <form onSubmit={onFormSubmit}>
				<CardComposer>
					<CardComposerCard>
						<CardComposerCardDetails>
							<textarea
								placeholder={t('AddCard::Enter a title for this task')}
								onChange={onNameChange}
								autoFocus
							/>
						</CardComposerCardDetails>
					</CardComposerCard>

					<FormActions>
						<Button
							type='success'
							text={t('AddCard::Add task')}
							onClick={onFormSubmit}
						/>

						<TaskCancelAction onClick={onCancelClick} />
					</FormActions>
				</CardComposer>
			</form>}
		</Container>
	)
}

export default AddCard