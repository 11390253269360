import styled from 'styled-components'

const ActivityActions = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 8px;
  gap: 25px;
  visibility: hidden;
`

export default ActivityActions