import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'
import Badge from '../Badge/Badge'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	word-break: break-word;

	&:not(:last-child) {
		margin-bottom: 20px;
	}
`

const Label = styled.div`
	display: block;
	margin-bottom: 5px;
	color: #999;
	font-size: 15px;
	font-weight: 500;
`

const Value = styled.div`
	font-size: 16px;

	a {
		cursor: pointer;
		color: ${Style.color.brandPrimary};

		&:hover {
			text-decoration: underline;
		}
	}

	span {
		margin-right: 4px;
	}
`

interface IProps {
	label: any
	value: any
}

const ProfileDetailItem = (props: IProps) => {
	const { label, value } = props

	return (
		<Container>
			<Label>
				{label}
			</Label>

			<Value>
				{value}
			</Value>
		</Container>
	)
}

export default ProfileDetailItem