import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'
import Icon, { IconTypes } from '../Icons/Icon'
import ConversationListHeaderCheckbox from './ConversationListHeaderCheckbox'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 19px 12px;
	border-bottom: 2px solid rgb(234,237,245);
	height: 72.4px;
`

const Title = styled.div`
	font-weight: 600;
	padding-right: 6px;
	font-size: 16px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	margin-right: ${Style.spacing.x1};
`

const Action = styled.div`
	display: flex;
	justify-content: flex-end;
	cursor: pointer;

	svg {
		width: 24px;
		height: 24px;
	}

	i {
		font-size: 24px;
	}
`

interface IProps {
	title: string
	action?: { icon: IconTypes, onClick: () => void }
}

const ConversationListHeader = (props: IProps) => {
	const { title, action } = props
	return (
		<Container>
			{false && <ConversationListHeaderCheckbox active={false} />}

			<Title>{title}</Title>

			{action &&
				<Action onClick={action.onClick}>
					<Icon icon={action.icon} />
				</Action>
			}
		</Container>
	)
}

export default ConversationListHeader