import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Icon from '../Icons/Icon'

export const Container = styled.div<{ active?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center
	font-size: 12px;
	width: 32px;
	height: 32px;
	min-width: 32px;
	line-height: 32px;
	color: white;
	border-radius: 50%;
	border: 2px solid ${Style.color.border};
	cursor: pointer;
	margin-right: ${Style.spacing.x1};

	${props => props.active && css`
		background-color: ${Style.color.brandSuccess};
		border-color: ${Style.color.brandSuccess};
	`}
`

interface IProps {
	active?: boolean
}

const ConversationListHeaderCheckbox = (props: IProps) => {
	const { active } = props

	return (
		<Container active={active}>
			{active && <span><Icon icon='check' /></span>}
		</Container>
	)
}

export default ConversationListHeaderCheckbox