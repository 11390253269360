import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce';
import styled, { css } from 'styled-components'
import { ActiveStorageController } from '../../../controllers'
import ContentBlockHelper from '../../../helpers/ContentBlockHelper'
import { Style } from '../../../styles'
import NumberFormatter from '../../../utilities/NumberFormatter'
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../Editor/Editor'
import MoneyInput from '../../Form/MoneyInput'
import Icon from '../../Icons/Icon'
import { Draggable } from 'react-beautiful-dnd';
import { ItemBlock as ItemBlockType, MimeTypes, NumberFormat, Workspace, WorkspaceTax } from '../../../types';
import VATHelper from '../../../helpers/VatHelper';
import Utils from '../../../utilities/Utils';

const Item = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${Style.spacing.x0_5};
	
	&:not(:last-child) {
		margin-bottom: ${Style.spacing.x1_5};
	}

	&:last-child {
		margin-bottom: ${Style.spacing.x3};
	}
`

const ItemInfo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: ${Style.spacing.x1};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

const ItemInfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
`

const ItemInfoWrapper = styled.div`
	background: white;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	height: 100%;
	width: 100%;
`

const ItemInfoTop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid ${Style.color.border};
	padding: ${Style.spacing.x0_5} ${Style.spacing.x1};
	font-weight: bold;
`

const ItemTitleInput = styled.input`
	border: none !important;
	padding: 0 !important;
	margin-right: ${Style.spacing.x0_5};

	&:active, &:focus {
		box-shadow: none !important;
	}
`

const SelectionButton = styled.div<{ selected?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	cursor: pointer;
	border-radius: 5px;
	background: #f7f7fa;
	border: 1px solid ${Style.color.border};
	padding: 2px ${Style.spacing.x0_5};
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;

	${props => props.selected && css`
		color: #4ecf01;
		background: #d6eed1;
		border-color: #d6eed1;
	`}
`

const ItemInfoBottom = styled.div`
	padding: ${Style.spacing.x1};

	.fr-toolbar {
		border: none;
	}

	.fr-box.fr-basic .fr-wrapper {
		border: none;
	}

	.fr-second-toolbar {
		border: none;
	}

	.fr-toolbar .fr-btn-grp {
		margin: 0;
	}

	.fr-box.fr-basic .fr-element {
		padding-left: ${Style.spacing.x1};
		padding-right: ${Style.spacing.x1};
	}
`


const ItemCoverImageOverlay = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0 ,0, 0, 0.5);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	color: white;
	font-size: 20px;
`

const ItemCoverImage = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	min-width: 80px;
	min-height: 80px;
	border: 1px solid #c4cdd5;
	border-radius: 5px;
	color: black;
	font-size: 30px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	overflow: hidden;
	margin-right: ${Style.spacing.x1};
	cursor: pointer;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-bottom: ${Style.spacing.x1};
		margin-right: 0;
	}

	&:hover {
		${ItemCoverImageOverlay} {
			opacity: 1;
		}
	}
`

const ItemActions = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: -16px;
`

const ItemAction = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${Style.spacing.x1};
	border-radius: 3px;
	min-height: auto;
	width: 35px;
	height: 35px;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.6);
	cursor: pointer;

	svg {
		width: 14px;
		height: 14px;
		fill: rgba(0, 0, 0, 0.6);
	}

	&:hover {
		color: black;

		svg {
			fill: black;
		}
	}
`

const Billing = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;


	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const BillingProperty = styled.div<{ preview?: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: ${Style.spacing.x0_5} ${Style.spacing.x1};
	padding-bottom: ${Style.spacing.x1_5};
	width: 100%;

	&:not(:last-child) {
		margin-right: ${Style.spacing.x0_5};
	}

	input {
		text-align: left;
	}

	${props => props.preview && css`
		background: transparent;
		border-color: transparent;

		${BillingPropertyValue} {
			font-weight: bold;
			padding: 6px 0;
			font-size: 16px;
		}
	`}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		padding-left: 0;
		padding-right: 0;
	}


`

const BillingPropertyLabel = styled.div`
	font-weight: bold;
`

const BillingPropertyValue = styled.div`
`

type IProps = {
	index: number
	blockId: string
	item: ItemBlockType
	currency: string
	numberFormat: NumberFormat
	taxes: WorkspaceTax[]
	contactSelectionEnabled: boolean
	onChange: (item: ItemBlockType) => void
	onSettingsClick: (item: ItemBlockType) => void
	onDeleteClick: (item: ItemBlockType) => void
	onSelectionChange: (item: ItemBlockType) => void
} & WithTranslation

interface IState {
	item: ItemBlockType
}

class ItemBlock extends React.Component<IProps, IState> {
	constructor(props) {
		super(props)

		this.state = {
			item: props.item
		}

		this.onDeleteClick = this.onDeleteClick.bind(this)
		this.onCoverDeleteClick = this.onCoverDeleteClick.bind(this)
		this.onTitleChange = this.onTitleChange.bind(this)
		this.onSelectionChange = this.onSelectionChange.bind(this)
		this.onDescriptionChange = this.onDescriptionChange.bind(this)
		this.onQuantityChange = this.onQuantityChange.bind(this)
		this.onRateChange = this.onRateChange.bind(this)
		this.onDrop = this.onDrop.bind(this)
		this.onItemChange = Utils.debounce(this.onItemChange.bind(this), 500)
	}

	onDeleteClick() {
		const { item } = this.state
		ReactTooltip.hide()
		this.props.onDeleteClick(item)
	}

	onCoverDeleteClick() {
		const { item } = this.state
		this.setState({
			item: {
				...item,
				cover: null
			}
		}, this.onItemChange)
	}

	onTitleChange(e) {
		const { item } = this.state
		const newTitle = e.target.value

		this.setState({
			item: {
				...item,
				title: newTitle
			}
		}, this.onItemChange)
	}

	onSelectionChange() {
		const { item } = this.state

		this.props.onSelectionChange(item)
	}

	onDescriptionChange(descriptionModel: string) {
		const { item } = this.state

		this.setState({
			item: {
				...item,
				description: descriptionModel
			}
		}, this.onItemChange)
	}

	onQuantityChange(e) {
		const { item } = this.state
		const newQuantity = e.target.value

		this.setState({
			item: {
				...item,
				quantity: newQuantity
			}
		}, this.onItemChange)
	}

	onRateChange(rate) {
		const { item } = this.state
		this.setState({
			item: {
				...item,
				amount: rate
			}
		}, this.onItemChange)
	}

	onTaxChange(code) {
		const { taxes } = this.props
		const { item } = this.state
		const taxRate = VATHelper.getTaxRateFromCode(taxes, code)

		this.setState({
			item: {
				...item,
				tax_rate: Number(taxRate.rate),
				tax_code: taxRate.code
			}
		}, this.onItemChange)
	}

	onDrop(acceptedFiles) {
		const file = acceptedFiles[0]

		ActiveStorageController.uploadPublic(file, async (error, blob) => {
			if (error) { console.error(error) }
			if (blob) {
				const { url } = await ActiveStorageController.getBlobUrl(blob)

				this.setState({
					item: {
						...this.state.item,
						cover: url
					}
				}, this.onItemChange)
			}
		})
	}


	onItemChange() {
		const { item } = this.state
		const { onChange } = this.props

		onChange({ ...item })
	}

	render() {
		const { blockId, index, currency, numberFormat, contactSelectionEnabled, taxes, onChange, t, item: propItem } = this.props
		const { item } = this.state
		const uniqueKey = `${blockId}-${index}-title`
		const { title, description, quantity, amount: rate, tax_rate: taxRate, tax_code: taxCode } = item

		const total = ContentBlockHelper.getItemSummary({
			quantity: quantity,
			amount: rate,
			tax_rate: taxRate,
		}).total

		return (
			<Draggable
				key={item.id}
				draggableId={item.id}
				index={index}
			>
				{(draggableProvided, draggableSnapshot) => (
					<Item ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
						<ItemInfo>
							{!item.cover && <CoverImage onDrop={this.onDrop} />}
							{item.cover && <ItemCoverImage style={{ backgroundImage: `url(${item.cover})` }} onClick={this.onCoverDeleteClick}>
								<ItemCoverImageOverlay>
									<Icon icon='trash' />
								</ItemCoverImageOverlay>
							</ItemCoverImage>}
							<ItemInfoContainer>
								<ItemInfoWrapper>
									<ItemInfoTop>
										<ItemTitleInput
											type='text'
											placeholder={t('ItemsBlock::Title')}
											value={title}
											onChange={this.onTitleChange}
										/>

										{contactSelectionEnabled && <SelectionButton selected={propItem.selected} onClick={this.onSelectionChange}>
											{propItem.selected ? t('ItemsBlock::Selected') : t('ItemsBlock::Unselected')}
										</SelectionButton>}
									</ItemInfoTop>
									<ItemInfoBottom>
										<Editor
											model={description}
											onModelChange={this.onDescriptionChange}
											config={{
												...MINIMAL_EDITOR_CONFIG,
												toolbarInline: true,
												placeholderText: t('ItemBlock::Add a product description...')
											}}
										/>
									</ItemInfoBottom>
								</ItemInfoWrapper>
								<ItemActions>
									<ItemAction data-tip={t('ItemBlock::Reorder')} {...draggableProvided.dragHandleProps}>
										<Icon icon='grip-vertical' />
									</ItemAction>

									<ItemAction onClick={this.onDeleteClick} data-tip={t('ItemBlock::Delete')}>
										<Icon icon='trash' />
									</ItemAction>
								</ItemActions>
							</ItemInfoContainer>
						</ItemInfo>
						<Billing>
							<BillingProperty>
								<BillingPropertyLabel>
									{t('ItemsBlock::Quantity')}
								</BillingPropertyLabel>
								<BillingPropertyValue>
									<input
										type='number'
										min={0}
										step={1}
										value={quantity}
										onChange={this.onQuantityChange}
									/>
								</BillingPropertyValue>
							</BillingProperty>

							<BillingProperty>
								<BillingPropertyLabel>
									{t('ItemsBlock::Price')}
								</BillingPropertyLabel>
								<BillingPropertyValue>
									<MoneyInput
										name='rate'
										currency={currency}
										numberFormat={numberFormat}
										placeholderValue={0}
										value={rate}
										onBlur={this.onRateChange}
									/>
								</BillingPropertyValue>
							</BillingProperty>

							<BillingProperty style={{ maxWidth: 90 }}>
								<BillingPropertyLabel>
									{t('ItemsBlock::VAT')}
								</BillingPropertyLabel>
								<BillingPropertyValue>
									<select
										name='vat'
										onChange={(e) => this.onTaxChange(e.currentTarget.value)}
										value={taxCode}
									>
										<option value="">-</option>
										{taxes.map(tax => (<option key={tax.name} value={tax.code}>{tax.name}</option>))}
									</select>
								</BillingPropertyValue>
							</BillingProperty>

							<BillingProperty preview>
								<BillingPropertyLabel>
									{t('ItemsBlock::Total')}
								</BillingPropertyLabel>
								<BillingPropertyValue>
									{NumberFormatter.formatCurrency(currency, numberFormat, total)}
								</BillingPropertyValue>
							</BillingProperty>
						</Billing>
					</Item>
				)}
			</Draggable>
		)
	}
}

interface ICoverProps {
	onDrop: (acceptedFiles: any[], fileRejections: FileRejection[], event: DropEvent) => void
}

const CoverImage = (props: ICoverProps) => {
	const { onDrop } = props
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: onDrop,
		multiple: false,
		accept: [
			MimeTypes.JPG,
			MimeTypes.JPEG,
			MimeTypes.PNG,
			MimeTypes.GIF,
			MimeTypes.SVG
		],
	})

	return (
		<ItemCoverImage {...getRootProps()}>
			<input {...getInputProps()} />
			<Icon icon='image' />
		</ItemCoverImage>
	)
}

export default withTranslation()(ItemBlock)