import * as React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router'
import Company from './Settings/Company'
import DisplayPreferences from './Settings/DisplayPreferences'
import General from './Settings/General'
import Quotations from './Settings/Quotations'
import Invoices from './Settings/Invoices'
import CreditNotes from './Settings/CreditNotes'
import Email from './Settings/Email'
import ERoute from '../ERoute';
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import Smtp from './Settings/Smtp'
import Calendar from './Settings/Calendar'
import Data from './Settings/Data'
import OrderForms from './Settings/OrderForms'
import DeliveryNotes from './Settings/DeliveryNotes'
import MenuEditor from './Settings/MenuEditor'
import WorkTypes from './Settings/WorkTypes'
import ProFormaInvoices from './Settings/ProFormaInvoices'
import FullPageContent from '../components/Page/FullPageContent'
import MenuItem from '../components/Settings/MenuItem'
import MenuSection from '../components/Settings/MenuSection'
import MenuSectionHeader from '../components/Settings/MenuSectionHeader'
import SettingsPage from '../components/Settings/SettingsPage'
import SettingsMenu from '../components/Settings/SettingsMenu'
import SettingsContent from '../components/Settings/SettingsContent'
import CompanyBranding from './Settings/CompanyBranding'
import Contacts from './Settings/Contacts'

interface Props { }
type IProps = RouteComponentProps<Props> & WithTranslation

class Settings extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  render() {
    const { location, t } = this.props

    return (
      <>
        <Helmet>
          <title>{t('Settings::{{__appName}} | Settings')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='setting'
          title={t('Settings::Settings')}
        />

        <FullPageContent background='transparent'>
          <SettingsPage>
            <SettingsMenu>
              <MenuSection>
                <MenuSectionHeader>
                  {t('Settings::Company settings')}
                </MenuSectionHeader>

                <MenuItem to={ERoute.PATH_SETTINGS_GENERAL} text={t('Settings::General')} />
                <MenuItem to={ERoute.PATH_SETTINGS_COMPANY} text={t('Settings::Company details')} />
                <MenuItem to={ERoute.PATH_SETTINGS_COMPANY_BRANDING} text={t('Settings::Company branding')} />
                <MenuItem to={ERoute.PATH_SETTINGS_DISPLAY_PREFERENCES} text={t('Settings::Display preferences')} />
              </MenuSection>

              <MenuSection>
                <MenuSectionHeader>
                  {t('Settings::Feature settings')}
                </MenuSectionHeader>
                <MenuItem to={ERoute.PATH_SETTINGS_CALENDAR} text={t('Settings::Calendar')} />
                <MenuItem to={ERoute.PATH_SETTINGS_CONTACTS} text={t('Settings::Contacts')} />
                <MenuItem to={ERoute.PATH_SETTINGS_WORK_TYPES} text={t('Settings::Work Types')} />
                <MenuItem to={ERoute.PATH_SETTINGS_EMAIL} text={t('Settings::Email')} />
                <MenuItem to={ERoute.PATH_SETTINGS_ORDER_FORMS} text={t('Settings::Order forms')} />
                <MenuItem to={ERoute.PATH_SETTINGS_DELIVERY_NOTES} text={t('Settings::Delivery notes')} />
                <MenuItem to={ERoute.PATH_SETTINGS_QUOTATIONS} text={t('Settings::Quotations')} />
                <MenuItem to={ERoute.PATH_SETTINGS_PRO_FORMA_INVOICES} text={t('Settings::Proforma invoices')} />
                <MenuItem to={ERoute.PATH_SETTINGS_INVOICES} text={t('Settings::Invoices')} />
                <MenuItem to={ERoute.PATH_SETTINGS_CREDIT_NOTES} text={t('Settings::Credit notes')} />
              </MenuSection>

              <MenuSection>
                <MenuSectionHeader>
                  {t('Settings::Other tools')}
                </MenuSectionHeader>
                <MenuItem to={ERoute.PATH_SETTINGS_MENU_EDITOR} text={t('Settings::Menu editor')} />
                <MenuItem to={ERoute.PATH_SETTINGS_SMTP} text={t('Settings::Custom SMTP')} />
                <MenuItem to={ERoute.PATH_SETTINGS_DATA} text={t('Settings::Backup')} />
              </MenuSection>
            </SettingsMenu>

            <SettingsContent>
              <Switch location={location}>
                <Route path={ERoute.PATH_SETTINGS_GENERAL} component={General} />
                <Route path={ERoute.PATH_SETTINGS_COMPANY} component={Company} />
                <Route path={ERoute.PATH_SETTINGS_COMPANY_BRANDING} component={CompanyBranding} />
                <Route path={ERoute.PATH_SETTINGS_DISPLAY_PREFERENCES} component={DisplayPreferences} />
                <Route path={ERoute.PATH_SETTINGS_CALENDAR} component={Calendar} />
                <Route path={ERoute.PATH_SETTINGS_CONTACTS} component={Contacts} />
                <Route path={ERoute.PATH_SETTINGS_WORK_TYPES} component={WorkTypes} />
                <Route path={ERoute.PATH_SETTINGS_ORDER_FORMS} component={OrderForms} />
                <Route path={ERoute.PATH_SETTINGS_DELIVERY_NOTES} component={DeliveryNotes} />
                <Route path={ERoute.PATH_SETTINGS_QUOTATIONS} component={Quotations} />
                <Route path={ERoute.PATH_SETTINGS_PRO_FORMA_INVOICES} component={ProFormaInvoices} />
                <Route path={ERoute.PATH_SETTINGS_INVOICES} component={Invoices} />
                <Route path={ERoute.PATH_SETTINGS_CREDIT_NOTES} component={CreditNotes} />
                <Route path={ERoute.PATH_SETTINGS_EMAIL} component={Email} />
                <Route path={ERoute.PATH_SETTINGS_MENU_EDITOR} component={MenuEditor} />
                <Route path={ERoute.PATH_SETTINGS_SMTP} component={Smtp} />
                <Route path={ERoute.PATH_SETTINGS_DATA} component={Data} />
                <Redirect from='/' to={ERoute.PATH_SETTINGS_GENERAL} />
              </Switch>
            </SettingsContent>
          </SettingsPage>
        </FullPageContent>
      </>
    )
  }
}

export default withTranslation()(Settings)