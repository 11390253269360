import styled from 'styled-components'
import { Style } from '../../styles'

const Table = styled.div`
	display: table;
	width: 100%;
	min-width: 750px;
	table-layout: auto;
	border-collapse: seperate;
`

export default Table