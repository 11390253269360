import styled from 'styled-components'
import Card from '../Card/Card'
import { Style } from '../../styles'

export default styled(Card)`
	position: relative;
	padding: 20px;
	width: 100%;
	height: 500px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 1100px;
		height: 350px;
	}
`