import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import { DEFAULT_LOCALE } from '../../Constants'
import Panel from '../../components/Panel/Panel'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import CheckboxInput from '../../components/Form/CheckboxInput';
import EditorContainer from '../../components/Editor/EditorContainer';
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../components/Editor/Editor';
import LocaleHelper from '../../helpers/LocaleHelper';
import PowerSelect from '../../components/Form/PowerSelect';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import MobilityHelper from '../../helpers/MobilityHelper';
import { CurrentUser, DisplayableError, Locale, Settings } from '../../types';
import { showConfirmModal, showFinancialYearModal } from '../../store/modals/actions';
import Alert from '../../components/Alert/Alert';
import LedgerItemHelper from '../../helpers/LedgerItemHelper';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  showFinancialYearModal: typeof showFinancialYearModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  activeTranslationsLocale: Locale
  errors: DisplayableError[]
  form: ISettingsForm | null
  isUpdating: boolean
}

class ProFormaInvoices extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      activeTranslationsLocale: DEFAULT_LOCALE,
      form: null,
      isUpdating: false,
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onToggleEnableProFormaInvoices = this.onToggleEnableProFormaInvoices.bind(this)
    this.onProformaInvoicePrefixNumberChange = this.onProformaInvoicePrefixNumberChange.bind(this)
    this.onProformaInvoiceNumberChange = this.onProformaInvoiceNumberChange.bind(this)
    this.onProformaInvoiceSuffixNumberChange = this.onProformaInvoiceSuffixNumberChange.bind(this)
    this.onTranslationsLocaleChange = this.onTranslationsLocaleChange.bind(this)
    this.onProFormaInvoiceDetailsChange = this.onProFormaInvoiceDetailsChange.bind(this)
  }

  componentDidMount(): void {
    this.fetchSettings().catch(console.error)
  }

  async fetchSettings() {
    try {
      const form = await SettingsController.getForm()
      this.setState({ form: form })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { t } = this.props

    this.setState({ isUpdating: true })
    try {
      const response = await SettingsController.update(settings)
      if (response.errors) {
        Notification.notifyError(t('SettingProFormaInvoices::Oops something went wrong.'));

        this.setState({ errors: response.errors })
      } else {
        // Cast to settings
        const settings = response as Settings
        // Update local
        this.setState({ settings: settings })

        Notification.notifySuccess(t('SettingProFormaInvoices::Settings updated successfully'));
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isUpdating: false })
    }
  }

  onToggleEnableProFormaInvoices() {
    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        pro_forma_invoices_enabled: !settings.pro_forma_invoices_enabled
      }
    })
  }

  onProformaInvoicePrefixNumberChange(e) {
    const prefixProformaInvoiceNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        prefix_pro_forma_invoice_number: prefixProformaInvoiceNumber
      },
    });
  }

  onProformaInvoiceNumberChange(e) {
    const deliveryNoteNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        pro_forma_invoice_number: deliveryNoteNumber,
      },
    });
  }

  onProformaInvoiceSuffixNumberChange(e) {
    const suffixProformaInvoiceNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        suffix_pro_forma_invoice_number: suffixProformaInvoiceNumber,
      },
    });
  }

  onTranslationsLocaleChange(option) {
    this.setState({ activeTranslationsLocale: option.value });
  }

  onProFormaInvoiceDetailsChange(details: string) {
    const { settings, activeTranslationsLocale } = this.state;

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationsLocale, settings.translations, 'default_pro_forma_invoice_details', details)

    this.setState({
      settings: {
        ...settings,
        translations: { ...updatedTranslations }
      }
    });
  }

  render() {
    const { t } = this.props
    const { settings, errors, form, isUpdating, activeTranslationsLocale } = this.state

    if (form) {
      const localeOptions = LocaleHelper.getLocaleOptions()
      const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

      return (
        <>
          <Helmet>
            <title>{t('SettingProFormaInvoices::{{__appName}} | Proforma Invoices')}</title>
          </Helmet>
          <ScrollToTopOnMount />

          <form onSubmit={this.onFormSubmit}>
            <Title>{t('SettingProFormaInvoices::Proforma Invoices')}</Title>

            <Panel>
              <div className='grid'>
                <div className='grid-cell with-4col'>
                  <div className='form-item'>
                    <CheckboxInput
                      onChange={this.onToggleEnableProFormaInvoices}
                      checked={settings.pro_forma_invoices_enabled}
                      label={t('SettingProFormaInvoices::Enabled')}
                    />
                  </div>
                </div>
              </div>

              {settings.pro_forma_invoices_enabled && <>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SettingProFormaInvoices::Proforma invoice number format')}</label>
                      <div style={{ marginBottom: 8 }}>
                        <Alert
                          type='info'
                          text={
                            <Trans t={t}>
                              <div>The number of your next proforma invoice is "<b>{{ prefix_pro_forma_invoice_number: settings.prefix_pro_forma_invoice_number }}{{ next_pro_forma_invoice_number: LedgerItemHelper.getSuccessiveLedgerNumber(settings.pro_forma_invoice_number) }}{{ suffixed_pro_forma_invoice_number: settings.suffix_pro_forma_invoice_number }}</b>"</div>
                            </Trans>
                          }
                        />
                      </div>

                      <div className='settings-numbering-format'>
                        <input
                          type='text'
                          name='prefix'
                          placeholder={t('SettingProFormaInvoices::Prefix')}
                          value={settings.prefix_pro_forma_invoice_number}
                          onChange={this.onProformaInvoicePrefixNumberChange}
                        />
                        <input
                          type='number'
                          name='number'
                          placeholder='1'
                          value={settings.pro_forma_invoice_number}
                          onChange={this.onProformaInvoiceNumberChange}
                          pattern='\d*'
                          min={0}
                          required
                        />
                        <input
                          type='text'
                          name='suffix'
                          placeholder={t('SettingProFormaInvoices::Suffix')}
                          value={settings.suffix_pro_forma_invoice_number}
                          onChange={this.onProformaInvoiceSuffixNumberChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SettingProFormaInvoices::Default proforma invoice footnote')}</label>
                      <PowerSelect
                        options={localeOptions}
                        value={selectedLocaleOption}
                        onChange={this.onTranslationsLocaleChange}
                        noOptionsMessage={(value) => t('SettingProFormaInvoices::No language found.')}
                        theme={ReactSelectTheme}
                      />

                      <EditorContainer style={{ marginTop: 8 }}>
                        <Editor
                          model={MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'default_pro_forma_invoice_details')}
                          onModelChange={this.onProFormaInvoiceDetailsChange}
                          config={{
                            ...MINIMAL_EDITOR_CONFIG,
                            heightMin: 120,
                            heightMax: 120
                          }}
                        />
                      </EditorContainer>
                    </div>
                  </div>
                </div>
              </>}

              <input type='submit' style={{ display: 'none' }} />
              <div className='field-actions'>
                <input type='submit' style={{ display: 'none' }} />
                <div className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('SettingProFormaInvoices::Save')} isLoading={isUpdating} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>
      )
    }
    else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showFinancialYearModal: (options) => dispatch(showFinancialYearModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProFormaInvoices))