import styled, { css } from "styled-components";
import { Style } from "../../styles";

const TableContainer = styled.div<{ maxHeight?: string }>`
	height: fit-content;
	width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  border: 1px solid ${Style.color.border};

  ${props => props.maxHeight && css`
    max-height: ${props.maxHeight};
  `}

`

export default TableContainer