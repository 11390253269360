import styled from 'styled-components'

const PopoverDivider = styled.hr`
	background-color: rgba(9,30,66,.13);
	border: 0;
	height: 1px;
	margin: 8px 0;
	padding: 0;
	width: 100%;
`

export default PopoverDivider