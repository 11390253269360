import * as React from 'react'
import { DropEvent, FileRejection, useDropzone } from "react-dropzone"
import { Trans, useTranslation } from 'react-i18next'
import { MimeTypes } from '../../types'
import Icon from '../Icons/Icon'
import Loader from '../Loaders/Loader'
import ExpenseHelper from '../../helpers/ExpenseHelper'

interface IProps {
	uploading: boolean,
	onDrop: (acceptedFiles: any[], fileRejections: FileRejection[], event: DropEvent) => void
}
const ExpenseTicketDropzone = (props: IProps) => {
	const { uploading } = props
	const { t } = useTranslation()

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: props.onDrop,
		multiple: true,
		accept: ExpenseHelper.getExpenseDropzoneMimeTypes(),
	})

	return (
		<div className='expense-ticket is-new' {...getRootProps()}>
			<input {...getInputProps()} />
			{!uploading && <>
				<Icon icon='cloud-upload' />
				<Trans t={t} i18nKey="ExpenseDropzone::Upload drag & drop">
					<a href='javascript://'>Upload</a> drag & drop
				</Trans>
			</>}

			{uploading && <>
				<Loader />
			</>}
		</div>
	)
}

export default ExpenseTicketDropzone