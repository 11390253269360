import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux'
import { AppState } from '../../store'
import Panel from '../../components/Panel/Panel'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import { Helmet } from 'react-helmet'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import Alert from '../../components/Alert/Alert'
import styled from 'styled-components'
import { Style } from '../../styles'
import { Draggable } from 'react-beautiful-dnd'
import Icon from '../../components/Icons/Icon'
import DroppableHelper from '../../helpers/DroppableHelper';
import ReactTooltip from 'react-tooltip';
import Button from '../../components/Button/ButtonDefault';
import PowerSelect from '../../components/Form/PowerSelect';
import { useDebouncedCallback } from 'use-debounce';
import MenuEditorItems from '../../components/MenuEditor/MenuEditorItems';
import MenuEditorItem from '../../components/MenuEditor/MenuEditorItem';
import { CurrentUser, DisplayableError, GroupNavigationMenuItem, LinkNavigationMenuItem, NavigationMenuItem, NavigationMenuItemPage, NavigationMenuItemType, NavigationMenuListType, PageNavigationMenuItem, Settings } from '../../types';
import Title from '../../components/Settings/Title';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Style.spacing.x1};
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
}

const MenuEditor = (props: IProps) => {
  const { t } = useTranslation()
  const { currentUser: { workspace: { setting } } } = props
  const [navigationMenuItems, setNavigationMenuItems] = React.useState<NavigationMenuItem[]>(DroppableHelper.sort(setting.default_menu, 'ascending'))

  const debouncedUpdateMenuItems = useDebouncedCallback(
    (menuItems: NavigationMenuItem[]) => updateMenuItems(menuItems), 500
  );

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [navigationMenuItems])

  const updateMenuItems = async (menuItems: NavigationMenuItem[]) => {
    try {
      const updatedMenu: NavigationMenuItem[] = DroppableHelper.sort(menuItems, 'ascending')
      setNavigationMenuItems(updatedMenu)
      const response = await SettingsController.update({ ...setting, default_menu: updatedMenu })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onAddItemChange = (option) => {
    if (option.value) {
      const position = navigationMenuItems.length === 0 ? DroppableHelper.getCreatePosition(navigationMenuItems) : navigationMenuItems[0].position / 2
      let newItem: NavigationMenuItem = null
      switch (option.value) {
        case NavigationMenuItemType.PAGE:
          newItem = {
            type: NavigationMenuItemType.PAGE,
            home: false,
            position: position,
            page: NavigationMenuItemPage.DASHBOARD
          }
          break
        case NavigationMenuItemType.LINK:
          newItem = {
            type: NavigationMenuItemType.LINK,
            label: '',
            value: '',
            position: position
          }
          break
        case NavigationMenuItemType.DIVIDER:
          newItem = {
            type: NavigationMenuItemType.DIVIDER,
            position: position
          }
          break
        case NavigationMenuItemType.GROUP:
          newItem = {
            type: NavigationMenuItemType.GROUP,
            items: [],
            position: position
          }
      }

      const updatedNavigationItems = DroppableHelper.sort([...navigationMenuItems, newItem], 'ascending')
      setNavigationMenuItems(updatedNavigationItems)
      debouncedUpdateMenuItems(updatedNavigationItems)
    }
  }

  const onPageChange = (page: NavigationMenuItemPage, index: number, childIndex?: number) => {
    if (navigationMenuItems[index] && navigationMenuItems[index].type === NavigationMenuItemType.PAGE) {
      const updatedNavigationMenuItem = navigationMenuItems[index] as PageNavigationMenuItem

      updatedNavigationMenuItem.page = page

      navigationMenuItems[index] = updatedNavigationMenuItem
    }


    const updatedNavigationMenuItems = [...navigationMenuItems]
    setNavigationMenuItems(updatedNavigationMenuItems)
    debouncedUpdateMenuItems(updatedNavigationMenuItems)
  }

  const onLabelChange = (navigationItem: NavigationMenuItem, label: string, index: number, childIndex?: number) => {
    let updatedNavigationMenuItems: NavigationMenuItem[] = [...navigationMenuItems]

    if ([NavigationMenuItemType.GROUP, NavigationMenuItemType.LINK].includes(navigationItem.type)) {
      if (childIndex >= 0) {
        // Get parent item
        const groupNavigationMenuItem = updatedNavigationMenuItems[index] as GroupNavigationMenuItem

        // Get child item
        const childNavigationMenuItem = navigationItem as LinkNavigationMenuItem

        // Update child label
        childNavigationMenuItem.label = label

        // Update child from parent
        groupNavigationMenuItem.items[childIndex] = childNavigationMenuItem

        // Set parent
        updatedNavigationMenuItems[index] = groupNavigationMenuItem
      } else {
        const updatedNavigationMenuItem = navigationMenuItems[index] as LinkNavigationMenuItem

        updatedNavigationMenuItem.label = label

        updatedNavigationMenuItems[index] = updatedNavigationMenuItem
      }
    }

    setNavigationMenuItems(updatedNavigationMenuItems)
    debouncedUpdateMenuItems(updatedNavigationMenuItems)
  }

  const onValueChange = (navigationItem: NavigationMenuItem, value: string, index: number, childIndex?: number) => {
    const updatedNavigationMenuItems = [...navigationMenuItems]

    if (navigationItem.type === NavigationMenuItemType.LINK) {
      if (childIndex >= 0) {
        // Get parent item
        const groupNavigationMenuItem = updatedNavigationMenuItems[index] as GroupNavigationMenuItem

        // Get child item
        const childNavigationMenuItem = navigationItem as LinkNavigationMenuItem

        console.log(groupNavigationMenuItem, childNavigationMenuItem)
        // Update child label
        childNavigationMenuItem.value = value

        // Update child from parent
        groupNavigationMenuItem.items[childIndex] = childNavigationMenuItem

        // Set parent
        updatedNavigationMenuItems[index] = groupNavigationMenuItem
      } else {
        const updatedNavigationMenuItem = navigationMenuItems[index] as LinkNavigationMenuItem

        updatedNavigationMenuItem.value = value

        navigationMenuItems[index] = updatedNavigationMenuItem
      }
    }


    setNavigationMenuItems(updatedNavigationMenuItems)
    debouncedUpdateMenuItems(updatedNavigationMenuItems)
  }

  const onNavigationItemDragEnd = (dropResult: DropResult, provided: ResponderProvided) => {
    console.log(dropResult)
    const { destination, source, draggableId } = dropResult

    if (!destination) return

    if (
      destination.droppableId !== source.droppableId &&
      destination.index !== source.index
    ) {
      return
    }

    let newNavigationMenuItems: NavigationMenuItem[] = [...navigationMenuItems]

    // Calculate new position
    const newPosition = DroppableHelper.getNewPositionBasedOnDropResult(newNavigationMenuItems, dropResult)

    // Assign new position
    newNavigationMenuItems[source.index].position = newPosition

    newNavigationMenuItems = DroppableHelper.sort(newNavigationMenuItems, 'ascending')
    // Update position
    setNavigationMenuItems(newNavigationMenuItems)
    debouncedUpdateMenuItems(newNavigationMenuItems)

  }

  const onHomeClick = (navigationItem: PageNavigationMenuItem, index: number) => {
    let newNavigationMenuItems: NavigationMenuItem[] = [...navigationMenuItems]

    newNavigationMenuItems.forEach(navigationMenuItem => {
      if (navigationMenuItem.type === NavigationMenuItemType.PAGE) {
        navigationMenuItem.home = false
        if (navigationMenuItem.page === navigationItem.page) navigationItem.home = true
      }
    })

    setNavigationMenuItems(DroppableHelper.sort(newNavigationMenuItems, 'ascending'))
    debouncedUpdateMenuItems(newNavigationMenuItems)
  }

  const onDeleteClick = (navigationMenuItem: NavigationMenuItem, index: number, childIndex?: number) => {
    let newNavigationMenuItems: NavigationMenuItem[] = [...navigationMenuItems]

    if (childIndex >= 0) {
      const childNavigationMenuItem = newNavigationMenuItems[index] as GroupNavigationMenuItem
      childNavigationMenuItem.items.splice(childIndex, 1)

      newNavigationMenuItems[index] = childNavigationMenuItem
    } else {
      newNavigationMenuItems.splice(index, 1);

      if (navigationMenuItem.type === NavigationMenuItemType.PAGE && navigationMenuItem.home && newNavigationMenuItems.length > 0) {
        const itemIndex = newNavigationMenuItems.findIndex(item => item.type === NavigationMenuItemType.PAGE)

        if (newNavigationMenuItems[itemIndex] && newNavigationMenuItems[itemIndex].type === NavigationMenuItemType.PAGE) {
          const newHomeNavigationMenuItem: PageNavigationMenuItem = newNavigationMenuItems[itemIndex] as PageNavigationMenuItem

          newHomeNavigationMenuItem.home = true

          newNavigationMenuItems[itemIndex] = newHomeNavigationMenuItem
        }
      }
    }

    newNavigationMenuItems = DroppableHelper.sort(newNavigationMenuItems, 'ascending')

    setNavigationMenuItems(newNavigationMenuItems)
    debouncedUpdateMenuItems(newNavigationMenuItems)
  }

  const onAddGroupItemClick = (groupNavigationMenuItem: GroupNavigationMenuItem, index: number, childIndex?: number) => {
    let newNavigationMenuItems: NavigationMenuItem[] = [...navigationMenuItems]

    if (newNavigationMenuItems[index] && newNavigationMenuItems[index].type === NavigationMenuItemType.GROUP) {
      const itemPosition = DroppableHelper.getCreatePosition(groupNavigationMenuItem.items)

      groupNavigationMenuItem.items = DroppableHelper.sort([
        ...groupNavigationMenuItem.items,
        { type: NavigationMenuItemType.LINK, label: '', value: '', position: itemPosition },
      ], 'ascending')

      newNavigationMenuItems[index] = groupNavigationMenuItem
    }

    setNavigationMenuItems(newNavigationMenuItems)
    debouncedUpdateMenuItems(newNavigationMenuItems)
  }

  const onResetClick = async () => {
    try {
      const settings: Settings = await SettingsController.resetMenu()
      setNavigationMenuItems(settings.default_menu)
    } catch (ex) {
      console.error(ex)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('SettingEmail::{{__appName}} | Menu Editor')}</title>
      </Helmet>
      <ScrollToTopOnMount />

      <Title>{t('SettingEmail::Menu Editor')}</Title>

      <Panel>
        <div style={{ marginBottom: 16 }}>
          <Alert
            type='warning'
            text={t('MenuEditor::Customise the default menu. Changes made to this menu will be reflected on the menus of all users.')}
          />
        </div>

        <div className='form-item'>
          <PowerSelect
            options={[
              { label: t('MenuEditor::Page'), value: String(NavigationMenuItemType.PAGE) },
              { label: t('MenuEditor::Group'), value: String(NavigationMenuItemType.GROUP) },
              { label: t('MenuEditor::Link'), value: String(NavigationMenuItemType.LINK) },
              { label: t('MenuEditor::Divider'), value: String(NavigationMenuItemType.DIVIDER) },
            ]}
            placeholder={t('MenuEditor::Add a menu item')}
            value={null}
            onChange={onAddItemChange}
          />
        </div>

        <DragDropContext onDragEnd={onNavigationItemDragEnd}>
          <Droppable droppableId='navigation-menu-items' type={NavigationMenuListType.MAIN}>
            {(provided) => {
              return (
                <MenuEditorItems ref={provided.innerRef} {...provided.droppableProps}>
                  {navigationMenuItems.map((navigationMenuItem, index) => {
                    const key = `${navigationMenuItem.type}-${index}`

                    return (
                      <Draggable key={key} draggableId={key} index={index}>
                        {(provided) => {
                          return (
                            <MenuEditorItem
                              key={index}
                              index={index}
                              item={navigationMenuItem}
                              provided={provided}
                              onHomeClick={onHomeClick}
                              onDeleteClick={onDeleteClick}
                              onAddGroupItemClick={onAddGroupItemClick}
                              onPageChange={onPageChange}
                              onLabelChange={onLabelChange}
                              onValueChange={onValueChange}
                            />
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </MenuEditorItems>
              )
            }}
          </Droppable>
        </DragDropContext>

        <Actions>
          <Button onClick={onResetClick}>
            <Icon icon='redo' style={{ marginRight: 8 }} />
            {t('MenuEditor::Reset to default')}
          </Button>
        </Actions>
      </Panel>
    </>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MenuEditor))