export default class GraphHelper {
	static SECONDARY_COLORS = [
		'#dfe3e8',
		'#c5ccd5',
		'#abb6c3',
		'#92a0b0',
		'#798b9e',
		'#61768d',
		'#48627b',
		'#2f4f6b',
		'#113c5a',
	]

	static getSecondaryColor (index: number) {
		if (index >= this.SECONDARY_COLORS.length - 1) return this.SECONDARY_COLORS[this.SECONDARY_COLORS.length - 1]

		return this.SECONDARY_COLORS[index]
	}
}