import * as React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import IntegrationsController from '../controllers/IntegrationsController'
import Notification from '../utilities/Notification'
import { RouteComponentProps } from 'react-router'
import RouteHelper from '../helpers/RouteHelper'
import ERoute from '../ERoute'
import UrlHelper from '../helpers/UrlHelper'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import styled from 'styled-components'
import { Style } from '../styles';
import IntegrationCard from '../components/Integrations/IntegrationCard'
import Utils from '../utilities/Utils'
import PageContent from '../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import { MarketplaceIntegration } from '../types'

const IntegrationsTitle = styled.div`
	margin-bottom: 16px;
	padding-bottom: 4px;
	border-bottom: 1px solid #f1f1f1;
	font-weight: 600;
	font-size: 16px;
	font-size: #000;
`

const IntegrationsContainer = styled.div``

const IntegrationsRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: ${Style.spacing.x2};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
		margin-bottom: 0;
	}
`

type IProps = RouteComponentProps<any> & WithTranslation
interface IState {
  didInitialLoad: boolean
  integrations: MarketplaceIntegration[]
}

class Integrations extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      integrations: [],
    }

    this.onIntegrationClick = this.onIntegrationClick.bind(this)
  }

  componentDidMount() {
    this.fetchIntegrations()
    this.parseQueryParams()
  }

  parseQueryParams() {
    const { location: { search }, t } = this.props
    const params = UrlHelper.getParams(search)

    const providerConnected = params.connectSuccess

    if (providerConnected) {
      if (providerConnected === 'yuki') {
        Notification.notifySuccess(t('Integrations::Yuki successfully connected'))
      } else if (providerConnected === 'mollie') {
        Notification.notifySuccess(t('Integrations::Mollie successfully connected'))
      } else {
        console.error(`Unknown type: ${providerConnected}`)
      }
    }
  }

  fetchIntegrations() {
    IntegrationsController
      .getIntegrations()
      .then(integrations => {
        this.setState({
          didInitialLoad: true,
          integrations: integrations
        })
      })
      .catch(console.error)
  }

  onIntegrationClick(integration: MarketplaceIntegration) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: integration.id }))
  }

  render() {
    const { t } = this.props
    const { didInitialLoad, integrations } = this.state

    let chunkedIntegrations = []

    if (integrations) {
      chunkedIntegrations = Utils.chunk(integrations, 4)
    }

    return (
      <>
        <Helmet>
          <title>{t('Integrations::{{__appName}} | Integrations')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='bolt'
          title={t('Integrations::Integrations')}
        />

        <PageContent>
          {!didInitialLoad && <PageLoader />}

          {didInitialLoad && <>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <IntegrationsTitle>{t('Integrations::All integrations')}</IntegrationsTitle>

                <IntegrationsContainer>
                  {chunkedIntegrations.map((chunkedIntegrationArray, index) => {
                    return (
                      <IntegrationsRow key={index}>
                        {chunkedIntegrationArray.map(chunkedIntegration => {
                          const integration: MarketplaceIntegration = chunkedIntegration

                          return (
                            <IntegrationCard
                              key={integration.id}
                              integration={integration}
                              onClick={() => this.onIntegrationClick(integration)}
                            />
                          )
                        })}
                      </IntegrationsRow>
                    )
                  })}
                </IntegrationsContainer>
              </div>
            </div>
          </>}
        </PageContent>
      </>
    )
  }
}

export default withTranslation()(Integrations)