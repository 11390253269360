import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import PowerSelect from '../../components/Form/PowerSelect';
import PageLoader from '../../components/Page/PageLoader';
import { SmtpController } from '../../controllers';
import Notification from '../../utilities/Notification';
import Icon from '../../components/Icons/Icon';
import styled, { css } from 'styled-components';
import { Style } from '../../styles';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import { CurrentUser, DisplayableError, SMTP, SmtpAuthType, SmtpSSLType } from '../../types';
import Title from '../../components/Settings/Title';

const CredentialValidationText = styled.div<{ valid?: boolean }>`
  margin-top: ${Style.spacing.x2};

  ${props => props.valid && css`
    color: ${Style.color.brandSuccess};
  `}

  ${props => !props.valid && css`
    color: ${Style.color.brandDanger};
  `}
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps { }

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  didInitialLoad: boolean
  smtp: SMTP | null
  credentialsValid: boolean | null
  errors: DisplayableError[]
}

class Smtp extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      smtp: null,
      credentialsValid: null,
      errors: [],
    }

    this.onHeloDomainChange = this.onHeloDomainChange.bind(this)
    this.onDomainChange = this.onDomainChange.bind(this)
    this.onUsernameChange = this.onUsernameChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onAuthTypeOptionChange = this.onAuthTypeOptionChange.bind(this)
    this.onSslTypeOptionChange = this.onSslTypeOptionChange.bind(this)
    this.onPortChange = this.onPortChange.bind(this)
    this.onFromNameChange = this.onFromNameChange.bind(this)
    this.onFromEmailChange = this.onFromEmailChange.bind(this)
    this.onValidateCredentials = this.onValidateCredentials.bind(this)
    this.onResetSmtp = this.onResetSmtp.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidMount() {
    this.getForm().catch(console.error)
  }

  async getForm() {
    try {
      const smtp = await SmtpController.getForm()

      this.setState({
        didInitialLoad: true,
        smtp: smtp,
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onHeloDomainChange(e) {
    e.preventDefault()

    const { smtp } = this.state;

    this.setState({
      smtp: {
        ...smtp,
        helo_domain: e.currentTarget.value,
      }
    });
  }

  onDomainChange(e) {
    e.preventDefault()

    const { smtp } = this.state;

    this.setState({
      smtp: {
        ...smtp,
        domain: e.currentTarget.value,
      }
    });
  }

  onUsernameChange(e) {
    e.preventDefault()

    const { smtp } = this.state;

    this.setState({
      smtp: {
        ...smtp,
        username: e.currentTarget.value,
      }
    });

  }

  onPasswordChange(e) {
    e.preventDefault()

    const { smtp } = this.state;

    this.setState({
      smtp: {
        ...smtp,
        password: e.currentTarget.value,
      }
    });
  }

  onAuthTypeOptionChange(option) {
    const { smtp } = this.state

    this.setState({
      smtp: {
        ...smtp,
        auth_type: option.value
      }
    })
  }

  onSslTypeOptionChange(option) {
    const { smtp } = this.state

    this.setState({
      smtp: {
        ...smtp,
        ssl_type: option ? option.value : null
      }
    })
  }

  onPortChange(e) {
    e.preventDefault()

    const { smtp } = this.state;

    this.setState({
      smtp: {
        ...smtp,
        port: e.currentTarget.value,
      }
    });
  }

  onFromNameChange(e) {
    e.preventDefault()

    const { smtp } = this.state;

    this.setState({
      smtp: {
        ...smtp,
        from_name: e.currentTarget.value,
      }
    });

  }

  onFromEmailChange(e) {
    e.preventDefault()

    const { smtp } = this.state;

    this.setState({
      smtp: {
        ...smtp,
        from_email: e.currentTarget.value,
      }
    });
  }

  async onValidateCredentials() {
    try {
      this.setState({ credentialsValid: null })

      const response = await SmtpController.validate(this.state.smtp)

      if (response) {
        this.setState({ credentialsValid: Boolean(response.success) })

        if (Boolean(response.success)) {
          this.onFormSubmit()
        }
      } else {
        this.setState({ credentialsValid: false })
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onResetSmtp() {
    const { smtp } = this.state

    if (smtp.id) {
      try {
        await SmtpController.delete()
        await this.getForm()
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  async onFormSubmit(e?: any) {
    try {
      if (e) e.preventDefault()
      const { smtp } = this.state
      const { t } = this.props

      let response
      if (smtp.id) {
        response = await SmtpController.update(smtp)
      } else {
        response = await SmtpController.create(smtp)
      }

      if (response.errors) {
        Notification.notifyError(t('Smtp::Oops something went wrong!'))

        this.setState({
          errors: response.errors,
        })
      } else {
        Notification.notifySuccess(t('Smtp::STMP details successfully saved.'))
        this.setState({
          smtp: smtp,
          errors: []
        });
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const { t } = this.props
    const { didInitialLoad, smtp, errors, credentialsValid } = this.state

    const authTypeOptions = [SmtpAuthType.LOGIN, SmtpAuthType.PLAIN, SmtpAuthType.CRAM_MD5].map(authType => ({ label: t(`SmtpAuthType::${authType}`), value: authType }))
    const selectedAuthTypeOption = smtp ? authTypeOptions.find(option => option.value === smtp.auth_type) : null
    const sslTypeOptions = [SmtpSSLType.SSL, SmtpSSLType.TLS].map(sslType => ({ label: t(`SmtpSSLType::${sslType}`), value: sslType }))
    const selectedSslTypeOption = smtp ? sslTypeOptions.find(option => option.value === smtp.ssl_type) : null

    return (
      <>
        <Helmet>
          <title>{t('Smtp::{{__appName}} | Custom SMTP')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <form onSubmit={this.onFormSubmit}>
          <Title>{t('Smtp::Custom SMTP')}</Title>

          <Panel>
            {!didInitialLoad && <PageLoader />}
            {didInitialLoad && smtp && <>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::Address')}<span>*</span></label>
                    <input
                      type='text'
                      name='helo_domain'
                      value={smtp.helo_domain}
                      placeholder={t('Smtp::mywebsite.com')}
                      onChange={this.onHeloDomainChange}
                    />
                  </div>
                </div>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::Domain')}<span>*</span></label>
                    <input
                      type='text'
                      name='domain'
                      value={smtp.domain}
                      placeholder={t('Smtp::mail.mywebsite.com')}
                      onChange={this.onDomainChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::Username')}<span>*</span></label>
                    <input
                      type='text'
                      name='username'
                      value={smtp.username}
                      placeholder={t('Smtp::myemail@mywebsite.com')}
                      onChange={this.onUsernameChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::Password')}<span>*</span></label>
                    <input
                      type='password'
                      name='password'
                      value={smtp.password}
                      placeholder={t('Smtp::**************')}
                      onChange={this.onPasswordChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::Auth type')}<span>*</span></label>
                    <PowerSelect
                      options={authTypeOptions}
                      value={selectedAuthTypeOption}
                      onChange={this.onAuthTypeOptionChange}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::SSL type')}</label>
                    <PowerSelect
                      options={sslTypeOptions}
                      value={selectedSslTypeOption}
                      onChange={this.onSslTypeOptionChange}
                      isClearable={true}
                      placeholder={t('Smtp::none')}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::Port')}<span>*</span></label>
                    <input
                      type='number'
                      name='port'
                      value={smtp.port}
                      placeholder='25'
                      onChange={this.onPortChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::"From" name')}<span>*</span></label>
                    <input
                      type='text'
                      name='from_name'
                      value={smtp.from_name}
                      placeholder={t('Smtp::My Company Name')}
                      onChange={this.onFromNameChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('Smtp::"From" email')}<span>*</span></label>
                    <input
                      type='email'
                      name='from_name'
                      value={smtp.from_email}
                      placeholder={t('Smtp::info@mydomain.com')}
                      onChange={this.onFromEmailChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <Button
                      type='default'
                      text={t('Smtp::Test connection')}
                      onClick={this.onValidateCredentials}
                    />

                    {credentialsValid && <CredentialValidationText valid={credentialsValid}><Icon icon='check' /> {t('Smtp::SMTP credentials are valid')}</CredentialValidationText>}
                    {credentialsValid !== null && !credentialsValid && <CredentialValidationText valid={credentialsValid}><Icon icon='close' /> {t('Smtp::Invalid SMTP credentials!')}</CredentialValidationText>}
                  </div>
                </div>
              </div>

              <div className='field-actions'>
                <input type='submit' style={{ display: 'none' }} />
                {smtp.id && <div>
                  <Button
                    type='danger'
                    text={t('Smtp::Reset')}
                    onClick={this.onResetSmtp}
                  />
                </div>}
                <div className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('Smtp::Save')} onClick={this.onFormSubmit} />
                </div>
              </div>
            </>}
          </Panel>
        </form>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Smtp))