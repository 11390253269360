import * as React from 'react'

interface IProps {
	selected: boolean
	color: string
	onClick: (color: string) => void
}

export default class SwatchColor extends React.PureComponent<IProps> {
  render() {
		const { selected, color, onClick } = this.props

    return (
      <div
				className={`swatch-color ${selected ? 'is-selected' : ''}`}
				style={{ backgroundColor: `${color}` }}
				onClick={() => onClick(color)}
			/>
    );
  }
}
