import '../translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { SENTRY_DSN } from '../Constants'
import { Helmet } from 'react-helmet'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import { useTranslation } from 'react-i18next'
import AppLoader from '../components/Loaders/AppLoader'
import ConfirmContainer from './ConfirmContainer'
import ConfirmWrapper from './ConfirmWrapper'
import ConfirmTitle from './ConfirmTitle'
import ConfirmDescription from './ConfirmDescription'
import ConfirmAction from './ConfirmAction'
import PoweredBy from './PoweredBy'
import PoweredByContainer from './PoweredByContainer'
import { Body } from '../components/Body/Body'

Sentry.init({ dsn: SENTRY_DSN })

interface IProps { }

const Application = (props: IProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{`Bizzey`}</title>
      </Helmet>

      <ScrollToTopOnMount />
      <Body className='no-overflow' />

      <ConfirmContainer>
        <ConfirmWrapper>
          <ConfirmTitle>
            {t('FormThanks::Thanks')}
          </ConfirmTitle>
          <ConfirmDescription style={{ marginBottom: 20 }}>
            {t('FormThanks::The form was submitted successfully.')}
          </ConfirmDescription>

          <ConfirmAction href='https://www.bizzey.com'>
            {t('FormThanks::Go back')}
          </ConfirmAction>
        </ConfirmWrapper>
      </ConfirmContainer>

      <PoweredByContainer>
        <PoweredBy />
      </PoweredByContainer>
    </>
  );
}


const ApplicationProvider = (props: IProps) => {
  return (
    <React.Suspense fallback={<AppLoader />}>
      <Application {...props} />
    </React.Suspense>
  )
}

export default ApplicationProvider