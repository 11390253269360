import '@stripe/stripe-js';
import './translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import * as ActionCable from 'actioncable'

import { Provider } from 'react-redux'
import App from './containers/App'
import store from './store'
import ActionCableProvider from './providers/ActionCableProvider';
import { SENTRY_DSN, STRIPE_PUBLIC_KEY } from './Constants'
import { Elements } from '@stripe/react-stripe-js'

import { loadStripe } from '@stripe/stripe-js'
import AppLoader from './components/Loaders/AppLoader';

// Sentry initialization
Sentry.init({ dsn: SENTRY_DSN, })

// Stripe initialization
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

// Action cable consumer
const actionCable = ActionCable.createConsumer(`/cable?token=${store.getState().authentication.accessToken}`)

export default class Application extends React.Component {
  render() {
    return (
      <React.Suspense fallback={<AppLoader />}>
        <Provider store={store}>
          <ActionCableProvider cable={actionCable}>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </ActionCableProvider>
        </Provider>
      </React.Suspense>
    )
  }
}