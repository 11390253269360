
import * as React from 'react'
import randomColor from '../../utilities/randomColor';
import Icon from '../Icons/Icon';
import SwatchColor from './SwatchColor';

interface IProps {
  onChange: (color: string) => void,
  color: string,
  colors: string[]
}

export default class Swatch extends React.PureComponent<IProps> {
  constructor(props) {
    super(props)

    this.onColorChange = this.onColorChange.bind(this)
    this.onRandomColorClick = this.onRandomColorClick.bind(this)
  }

  render() {
    return (
      <div className='swatch'>
        {this.renderSwatchColors()}
        <div key='redo' onClick={this.onRandomColorClick} className='swatch-color' style={{ background: 'rgba(138, 93, 181, 0.76)' }}>
          <span>
            <Icon icon='redo' />
          </span>
        </div>
      </div>
    );
  }

  renderSwatchColors() {
    const { color: selectedColor, colors } = this.props

    return colors.map(color => {
      return (
        <SwatchColor
          key={color}
          selected={selectedColor == color}
					color={color}
          onClick={this.onColorChange}
        />
      );
    });
  }

  onColorChange(color) {
    const { onChange } = this.props;
		onChange(color);
  }


  onRandomColorClick() {
		this.onColorChange(randomColor())
  }
}