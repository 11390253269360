import * as React from 'react'
import { WithTranslation, withTranslation } from "react-i18next";
import { CurrentUser, Board } from "../../../types";
import { BoardsController } from "../../../controllers";
import SimplebarHeader from "../../Simplebar/SimplebarHeader";
import SimplebarContainer from "../../Simplebar/SimplebarContainer";
import SimplebarContent from "../../Simplebar/SimplebarContent";
import SimplebarDescription from "../../Simplebar/SimplebarDescription";
import SimplebarItems from '../../Simplebar/SimplebarItems';
import SimplebarItem from '../../Simplebar/SimplebarItem';
import SimplebarLoader from '../../Simplebar/SimplebarLoader';
import SimplebarAction from '../../Simplebar/SimplebarAction';
import ResourceCheckbox from '../../Resource/ResourceCheckbox';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import Icon from '../../Icons/Icon';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps { }

type IProps = {
  active: boolean
  onItemClick: (board: Board) => void
  onAddBoardClick: () => void
} & IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  boards: Board[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  reachedEnd: boolean
  isFetching: boolean
}

class BoardsSimplebar extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      boards: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      reachedEnd: false
    }

    this.fetchBoards = this.fetchBoards.bind(this)
    this.onScrollChange = this.onScrollChange.bind(this)
    this.onItemClick = this.onItemClick.bind(this)
    this.onAddBoardClick = this.onAddBoardClick.bind(this)
  }

  componentDidMount() {
    this.fetchBoards(1)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.props.active && !prevProps.active) {
      this.container.current.scrollTop = 0
      this.fetchBoards(1)
    }
  }

  async fetchBoards(page: number) {
    const { boards: stateBoards } = this.state
    this.setState({
      isFetching: true
    }, async () => {
      try {
        const response = await BoardsController.getBoards({
          page: page,
          order: 'created_at_desc',
        })
        const { boards, current_page, total_pages, total_entries } = response;

        this.setState({
          boards: current_page === 1 ? [...boards] : [...stateBoards, ...boards],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
          reachedEnd: current_page === total_pages
        });
      } catch (ex) {
        console.error(ex)
      }
    });
  }

  onScrollChange(event) {
    const { currentPage, reachedEnd, isFetching } = this.state
    const node = event.target;
    const scrollListEndReached = node.scrollHeight - node.scrollTop <= node.clientHeight;

    if (scrollListEndReached && !reachedEnd && !isFetching) {
      this.fetchBoards(currentPage + 1)
    }
  }

  onItemClick(board: Board) {
    this.props.onItemClick(board)
  }

  onAddBoardClick() {
    this.props.onAddBoardClick()
  }

  render() {
    const { t } = this.props
    const { boards, didInitialLoad } = this.state

    return (
      <SimplebarContainer>
        <SimplebarHeader>
          {t('BoardsSimplebar::Boards')}
        </SimplebarHeader>

        <SimplebarContent ref={this.container} onScroll={this.onScrollChange}>
          {!didInitialLoad && <SimplebarLoader />}
          {didInitialLoad && <>
            {boards?.length === 0 && <SimplebarDescription style={{ textAlign: 'center' }}>
              {t('BoardsSimplebar::No boards created yet.')}
            </SimplebarDescription>}
            {boards?.length > 0 && <SimplebarItems>
              {boards.map((board, index) => {
                return (
                  <SimplebarItem key={board.id} onClick={() => this.onItemClick(board)} title={board.name}>
                    <span>{board.name}</span>
                  </SimplebarItem>
                )
              })}
            </SimplebarItems>}
          </>}
        </SimplebarContent>
        <SimplebarAction onClick={this.onAddBoardClick}>
          {t('BoardsSimplebar::Add board')}
        </SimplebarAction>
      </SimplebarContainer>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps: IDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BoardsSimplebar))