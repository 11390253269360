import * as React from 'react'
import ItemContainer from './Parts/ItemContainer'
import EnterIcon from './Parts/EnterIcon'
import SearchIcon from './Parts/SearchIcon'
import Icon from '../Icons/Icon'
import styled from 'styled-components'

type IProps = {
	title: string
	type: SearchItemTypes
	isSelected: boolean
	onClick: (e: React.MouseEvent) => void
}

export enum SearchItemTypes {
	person = 'person',
	company = 'company',
	contact = 'contact',
	credit_note = 'credit_note',
	expense = 'expense',
	order_form = 'order_form',
	delivery_note = 'delivery_note',
	invoice = 'invoice',
	recurring_invoice = 'recurring_invoice',
	project = 'project',
	quotation = 'quotation',
	product = 'product',
	service = 'service',
	transaction = 'transaction',
	payment = 'payment',
	deal = 'deal',
	form = 'form',
	proposal = 'proposal',
	contract = 'contract',
	task = 'task',
	sequence = 'sequence'
}

const Title = styled.span`
	max-width: 100%;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export default class SearchItem extends React.PureComponent<IProps> {
	private container = React.createRef<HTMLDivElement>()

	scrollIntoView(alignTop) {
		this.container.current.scrollIntoView(alignTop)
	}

	getSearchTypeIcon(type: SearchItemTypes): JSX.Element {
		switch (type) {
			case SearchItemTypes.person: return <Icon icon='user' />
			case SearchItemTypes.company: return <Icon icon='company' />
			case SearchItemTypes.contact: return <Icon icon='user' />
			case SearchItemTypes.order_form: return <Icon icon='invoice' />
			case SearchItemTypes.delivery_note: return <Icon icon='invoice' />
			case SearchItemTypes.credit_note: return <Icon icon='invoice' />
			case SearchItemTypes.expense: return <Icon icon='receipt' />
			case SearchItemTypes.invoice: return <Icon icon='invoice' />
			case SearchItemTypes.recurring_invoice: return <Icon icon='invoice' />
			case SearchItemTypes.project: return <Icon icon='project' />
			case SearchItemTypes.quotation: return <Icon icon='invoice' />
			case SearchItemTypes.product: return <Icon icon='product-navigation' />
			case SearchItemTypes.transaction: return <Icon icon='arrow-right-arrow-left' />
			case SearchItemTypes.payment: return <Icon icon='money-bill' />
			case SearchItemTypes.deal: return <Icon icon='check-circle' />
			case SearchItemTypes.form: return <Icon icon='input-pipe' />
			case SearchItemTypes.proposal: return <Icon icon='proposal' />
			case SearchItemTypes.contract: return <Icon icon='file-contract' />
			case SearchItemTypes.task: return <Icon icon='tasks' />
			case SearchItemTypes.sequence: return <Icon icon='paper-plane' />
			default:
				throw '[SearchItem - getSearchTypeIcon] invalid type provided'
		}
	}

	render() {
		const { type, title, isSelected, onClick } = this.props

		return (
			<ItemContainer ref={this.container} className={`search-item is-${type} ${isSelected ? 'is-selected' : ''}`} onClick={onClick}>
				<SearchIcon>
					{this.getSearchTypeIcon(type)}
				</SearchIcon>

				<Title title={title}>
					{title}
				</Title>

				<EnterIcon>
					<Icon icon='enter-key' />
				</EnterIcon>
			</ItemContainer>
		)
	}
}