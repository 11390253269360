import * as React from 'react'
import chartjs from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import BalanceList from '../../components/Balance/BalanceList'
import BalanceListHeader from '../../components/Balance/BalanceListHeader'
import BalanceListContent from '../../components/Balance/BalanceListContent'
import BalanceListItem from '../../components/Balance/BalanceListItem'
import styled from 'styled-components'
import ButtonDefault from '../../components/Button/ButtonDefault'
import { Style } from '../../styles'
import DateInput from '../../components/Form/DateInput'
import moment from '../../utilities/Moment'
import { Manager, Reference, Popper } from 'react-popper'
import { Moment } from 'moment'
import { ReportsController } from '../../controllers'
import { ReportType } from '../../controllers/ReportsController'
import { AppState } from '../../store'
import { connect } from 'react-redux'
import NumberFormatter from '../../utilities/NumberFormatter'
import GraphEmpty from '../../components/Graph/GraphEmpty'
import GraphCard from '../../components/Graph/GraphCard'
import GraphOptions from '../../components/Graph/GraphOptions'
import GraphOption from '../../components/Graph/GraphOption'
import PowerSelect from '../../components/Form/PowerSelect'
import PageLoader from '../../components/Page/PageLoader'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactSelectTheme from '../../components/Form/ReactSelectTheme'
import { CurrentUser } from '../../types'

const GraphContainer = styled.div`
	overflow-x: auto;
`

const RangeDropdownContainer = styled.div`
	position: relative;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 100%;
	}

	${ButtonDefault} {
		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			width: 100%;
			justify-content: flex-start;
		}
	}
`

const RangeDropdownContent = styled.div`
	position: absolute;
	padding: 16px;
	background: white;
	border: 6px;
	box-shadow: 0 0 0 1px rgba(6,44,82,.1), 0 2px 16px rgba(33,43,54,.08);
	backface-visibility: hidden;
	will-change: opacity,left,top;
	transition: opacity .2s cubic-bezier(.36,0,1,1);
	margin-top: 10px;
	z-index: 1;
	width: 100%;
	min-width: 300px;
`


const ResetLink = styled.a`
	color: ${Style.color.brandPrimary};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin: 0 auto;
		text-align: center;
	}
`

// https://stackoverflow.com/questions/31604040/show-label-in-tooltip-but-not-in-x-axis-for-chartjs-line-chart

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps { }

type IProps = IDispatchToProps & IStateToProps & WithTranslation

enum GroupRevenueBy {
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

enum RevenueChartType {
  BAR = 'bar',
  LINE = 'line',
}

interface IState {
  data: any[]
  groupRevenueBy: GroupRevenueBy
  chartType: RevenueChartType
  compareYears: number[]
  start: Moment | null
  end: Moment | null
  formActive: boolean
}

const INITIAL_STATE: IState = {
  data: [[]],
  groupRevenueBy: GroupRevenueBy.MONTH,
  chartType: RevenueChartType.BAR,
  compareYears: [],
  start: moment().startOf('year'),
  end: moment().endOf('year'),
  formActive: false,
}

class RevenueReport extends React.Component<IProps, IState> {
  private rangeFormContainer = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = { ...INITIAL_STATE }

    this.getReport = this.getReport.bind(this)
    this.onClickOutsideComponent = this.onClickOutsideComponent.bind(this);
    this.onToggleFormClick = this.onToggleFormClick.bind(this)
    this.onStartChange = this.onStartChange.bind(this)
    this.onEndChange = this.onEndChange.bind(this)
    this.onCompareYearChange = this.onCompareYearChange.bind(this)
    this.onGroupByChange = this.onGroupByChange.bind(this)
    this.onChartTypeChange = this.onChartTypeChange.bind(this)
    this.onResetClick = this.onResetClick.bind(this)
  }

  componentDidMount() {
    this.getReport()
    document.addEventListener('mousedown', this.onClickOutsideComponent);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutsideComponent);
  }

  getReport() {
    const { start, end, groupRevenueBy, compareYears } = this.state

    const format = 'DD-MM-YYYY'
    const startMoment = moment(start)
    const endMoment = moment(end)

    const ranges = [
      ...compareYears.map(year => `${moment(startMoment).year(year).format(format)}..${moment(endMoment).year(year).format(format)}`),
      `${startMoment.format(format)}..${endMoment.format(format)}`,
    ]

    ReportsController
      .get(ReportType.REVENUE, {
        ranges: ranges,
        group_by: groupRevenueBy,
      }).then(response => {
        const { result: { data } } = response

        this.setState({
          data: [...data],
        })

      }).catch(console.error)
  }

  onClickOutsideComponent(e) {
    const { formActive } = this.state
    if (formActive && this.rangeFormContainer.current && !this.rangeFormContainer.current.contains(e.target)) {
      this.setState({
        formActive: false
      })
    }
  }

  onToggleFormClick() {
    const { formActive } = this.state

    this.setState({
      formActive: !formActive,
    })
  }

  onStartChange(start) {
    const { end, groupRevenueBy } = this.state

    start = moment(start)

    if (groupRevenueBy === GroupRevenueBy.MONTH) {
      start = start.startOf('month')
    } else if (groupRevenueBy === GroupRevenueBy.QUARTER) {
      start = start.startOf('quarter')
    } else if (groupRevenueBy === GroupRevenueBy.YEAR) {
      start = start.startOf('year')
    }

    if (moment(start).isBefore(moment(end))) {
      this.setState({ start: start }, this.getReport)
    }
  }

  onEndChange(end) {
    const { start, groupRevenueBy } = this.state

    end = moment(end)

    if (groupRevenueBy === GroupRevenueBy.MONTH) {
      end = end.endOf('month')
    } else if (groupRevenueBy === GroupRevenueBy.QUARTER) {
      end = end.endOf('quarter')
    } else if (groupRevenueBy === GroupRevenueBy.YEAR) {
      end = end.endOf('year')
    }

    if (moment(end).isSameOrAfter(start)) {
      this.setState({ end: end }, this.getReport)
    }
  }

  onCompareYearChange(options) {
    let compareYears = []

    if (options) {
      compareYears = options.map(o => o.value).sort()
    }

    this.setState({
      compareYears: compareYears,
    }, this.getReport)
  }

  onGroupByChange(option) {
    let { start, end, compareYears } = this.state
    const groupRevenueBy: GroupRevenueBy = option ? option.value : null

    let unitOfTime: any = 'month'

    if (groupRevenueBy === GroupRevenueBy.MONTH) {
      unitOfTime = 'month'
    } else if (groupRevenueBy === GroupRevenueBy.QUARTER) {
      unitOfTime = 'quarter'
    } else if (groupRevenueBy === GroupRevenueBy.YEAR) {
      unitOfTime = 'year'
      compareYears = []
    }

    start = start.startOf(unitOfTime)
    end = end.endOf(unitOfTime)

    // Add multiple years to display valuable data
    if (groupRevenueBy === GroupRevenueBy.YEAR && end.diff(start, 'year') < 1) {
      start.subtract(10, 'years')
    }

    this.setState({
      groupRevenueBy: groupRevenueBy,
      compareYears: compareYears,
      start: start,
      end: end,
    }, this.getReport)
  }

  onChartTypeChange(option) {
    this.setState({
      chartType: option.value,
    })
  }

  onResetClick() {
    this.setState({ ...INITIAL_STATE }, this.getReport)
  }

  renderRevenueGraph() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { data, groupRevenueBy, chartType } = this.state

    const defaultColor = '#9C6ADE'
    let chartData: any = {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: defaultColor,
          borderColor: defaultColor,
          borderWidth: 1,
          hoverBackgroundColor: defaultColor,
          hoverBorderColor: defaultColor,
          data: []
        }
      ]
    }

    const datasets: any[] = data.map((dataset, index) => {
      const datasetDates: string[] = dataset.map(d => d[0])
      const datasetvalues: number[] = dataset.map(d => d[1])
      const datasetColor = '#9C6ADE'

      return {
        label: `${t('RevenueReport::Revenue')}${index}`,
        backgroundColor: datasetColor,
        borderColor: datasetColor,
        hoverBackgroundColor: datasetColor,
        hoverBorderColor: datasetColor,
        dates: datasetDates,
        data: datasetvalues,
        // Line specific
        pointBackgroundColor: datasetColor,
        pointBorderColor: datasetColor,
        borderWidth: chartType === RevenueChartType.BAR ? 1 : 3,
        fill: chartType === RevenueChartType.BAR ? true : false,
      }
    })

    let labels = []
    // @ts-ignore
    let dataValues = datasets.map(dataset => dataset.data).flat()

    if (data.length > 0) {
      if (groupRevenueBy === GroupRevenueBy.MONTH) {
        labels = data[0].map(d => moment(d[0]).format('MMM'))
      } else if (groupRevenueBy === GroupRevenueBy.QUARTER) {
        labels = data[0].map(d => moment(d[0]).format('[Q]Q'))
      } else if (groupRevenueBy === GroupRevenueBy.YEAR) {
        labels = data[0].map(d => moment(d[0]).format('YYYY'))
      }
    }

    const maxDataValue = Math.max(...dataValues)
    const minDataValue = Math.min(...dataValues)
    const suggestedMaxValue = isFinite(maxDataValue) ? maxDataValue + (maxDataValue * 5 / 100) : 0
    const suggestedMinValue = isFinite(minDataValue) ? minDataValue - (minDataValue * 5 / 100) : 0

    if (data.length > 0) {
      chartData = {
        labels: labels,
        datasets: datasets
      }
    }

    const chartOptions: chartjs.ChartOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'point',
        backgroundColor: '#212B36',
        footerMarginTop: 100,
        callbacks: {
          title: (tooltipItem, data) => {
            const currentTooltipItem = tooltipItem[0]
            const date = data.datasets[currentTooltipItem.datasetIndex].dates[currentTooltipItem.index] || '';

            let title = ''
            if (groupRevenueBy === GroupRevenueBy.MONTH) {
              title = moment(date).format('MMM YYYY')
            } else if (groupRevenueBy === GroupRevenueBy.QUARTER) {
              title = moment(date).format('[Q]Q YYYY')
            } else if (groupRevenueBy === GroupRevenueBy.YEAR) {
              title = moment(date).format('YYYY YYYY')
            }

            return title
          },
          label: (tooltipItem, data) => {
            const label = data.datasets[tooltipItem.datasetIndex].label || '';
            const value = NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, tooltipItem.value)

            return `${label.replace(/[0-9]/g, '')}: ${value} (excl. btw)`
          }
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#F4F6F8',
            lineWidth: 2,
            borderDash: [8, 4],
          },
          ticks: {
            fontSize: 13,
            fontColor: '#637381',
            padding: 10,
          },
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
            color: '#F4F6F8',
            lineWidth: 2,
            drawTicks: false,
            zeroLineColor: '#DFE4E8',
            zeroLineWidth: 2,
          },
          ticks: {
            fontSize: 13,
            fontColor: '#637381',
            padding: 20,
            suggestedMin: suggestedMinValue,
            suggestedMax: suggestedMaxValue,
            callback: function (value, index, values) {
              return NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, value)
            }
          },
        }]
      }
    }

    if (chartType === RevenueChartType.BAR) {
      return (
        <Bar
          data={chartData}
          options={chartOptions}
        />
      )
    } else if (chartType === RevenueChartType.LINE) {
      return (
        <Line
          data={chartData}
          options={chartOptions}
        />
      )
    }

    return null
  }

  render() {
    const {
      currentUser: { workspace: { setting } },
      t
    } = this.props
    const {
      start,
      end,
      compareYears,
      groupRevenueBy,
      chartType,
      data,
      formActive,
    } = this.state


    const currentYear = new Date().getFullYear()
    const compareOptions = []

    for (let i = 1; i <= 10; i++) {
      const year = currentYear - i
      compareOptions.push({ label: year, value: year })
    }
    const selectedCompareOptions = compareOptions.filter(option => compareYears.includes(option.value))

    const groupByOptions = [
      { label: t('RevenueReport::Month'), value: GroupRevenueBy.MONTH },
      { label: t('RevenueReport::Quarter'), value: GroupRevenueBy.QUARTER },
      { label: t('RevenueReport::Year'), value: GroupRevenueBy.YEAR },
    ]

    const selectedGroupByOption = groupByOptions.find(option => option.value === groupRevenueBy)

    const chartTypeOptions = [
      { label: t('RevenueReport::Bar chart'), value: RevenueChartType.BAR },
      { label: t('RevenueReport::Line chart'), value: RevenueChartType.LINE },
    ]

    const selectedChartTypeOption = chartTypeOptions.find(option => option.value === chartType)

    return (
      <>
        <GraphOptions>
          <GraphOption>
            <span>{t('RevenueReport::Period')}</span>
            <RangeDropdownContainer ref={this.rangeFormContainer}>
              <Manager>
                <Reference>
                  {({ ref }) => (<ButtonDefault ref={ref} onClick={this.onToggleFormClick}>
                    <svg viewBox="0 0 20 20" className="v3ASA" focusable="false" aria-hidden="true"><path d="M4 8h12V6H4v2zm9 4h2v-2h-2v2zm-4 0h2v-2H9v2zm0 4h2v-2H9v2zm-4-4h2v-2H5v2zm0 4h2v-2H5v2zM17 4h-2V3a1 1 0 1 0-2 0v1H7V3a1 1 0 1 0-2 0v1H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" fillRule="evenodd"></path></svg>
                    <span>
                      {`${start.format('D MMMM YYYY')} - ${end.format('D MMMM YYYY')}`}
                    </span>
                  </ButtonDefault>
                  )}
                </Reference>

                <Popper placement='bottom'>
                  {({ ref, style, placement, arrowProps }) => {
                    if (!formActive) return null

                    return (
                      <RangeDropdownContent
                        ref={ref}
                        style={{ ...style, zIndex: 1 }}
                        data-placement={placement}
                      >
                        <div className="grid">
                          <div className="grid-cell with-6col">
                            <div className="form-item">
                              <label>{t('RevenueReport::Start')}</label>
                              <DateInput
                                name='start'
                                dateFormat={setting.date_format}
                                timeFormat={false}
                                initialValue={moment(start)}
                                inputProps={{ placeholder: setting.date_format }}
                                onChange={this.onStartChange}
                                closeOnSelect
                              />
                            </div>
                          </div>

                          <div className="grid-cell with-6col">
                            <div className="form-item">
                              <label>{t('RevenueReport::End')}</label>
                              <DateInput
                                name='end'
                                dateFormat={setting.date_format}
                                timeFormat={false}
                                initialValue={moment(end)}
                                inputProps={{ placeholder: setting.date_format }}
                                onChange={this.onEndChange}
                                closeOnSelect
                              />
                            </div>
                          </div>
                        </div>
                      </RangeDropdownContent>
                    )
                  }}
                </Popper>
              </Manager>
            </RangeDropdownContainer>
          </GraphOption>

          <GraphOption>
            <span>{t('RevenueReport::Group by')}</span>

            <div style={{ width: '100%', minWidth: 125 }}>
              <PowerSelect
                options={groupByOptions}
                value={selectedGroupByOption}
                onChange={this.onGroupByChange}
                theme={ReactSelectTheme}
              />
            </div>
          </GraphOption>

          {groupRevenueBy !== GroupRevenueBy.YEAR && <GraphOption>
            <span>{t('RevenueReport::Compare period with')}</span>

            <div style={{ width: '100%', minWidth: 125 }}>
              <PowerSelect
                options={compareOptions}
                value={selectedCompareOptions}
                onChange={this.onCompareYearChange}
                isClearable
                theme={ReactSelectTheme}
                // @ts-ignore
                isMulti
              />
            </div>
          </GraphOption>}

          <GraphOption>
            <span>{t('RevenueReport::Chart type')}</span>

            <div style={{ width: '100%', minWidth: 100 }}>
              <PowerSelect
                options={chartTypeOptions}
                value={selectedChartTypeOption}
                onChange={this.onChartTypeChange}
                theme={ReactSelectTheme}
              />
            </div>
          </GraphOption>

          <GraphOption>
            <ResetLink href='javascript://' onClick={this.onResetClick}>{t('RevenueReport::Reset')}</ResetLink>
          </GraphOption>
        </GraphOptions>
        <GraphContainer>
          <GraphCard>
            {data.length === 0 && <GraphEmpty
              title={t('RevenueReport::No invoices have been send yet.')}
              description={t('RevenueReport::Come back when you\'ve issued your first invoice')}
            />}

            {this.renderRevenueGraph()}
          </GraphCard>
        </GraphContainer>

        {data.length > 0 && [...data].reverse().map((d, index) => {
          const startMoment = moment(d[0], 'YYYY-MM-DD')
          const endMoment = moment(d[d.length - 1], 'YYYY-MM-DD')

          let rangeLabel = ''
          if (startMoment.isValid() && endMoment.isValid()) {
            rangeLabel = `${startMoment.format('D MMMM YYYY')} - ${endMoment.format('D MMMM YYYY')}`
          } else {
            return <PageLoader />
          }
          const balanceTotal = d.reduce((previousValue: number, currentValue: any) => previousValue + Number(currentValue[1]), 0)

          return (
            <div key={index} style={{ marginTop: 40 }}>
              <BalanceList>
                <BalanceListHeader title={rangeLabel} />
                <BalanceListContent>
                  {d.map((datapoint, dpIndex) => {
                    const date = datapoint[0]
                    const amount = datapoint[1]

                    if (groupRevenueBy === GroupRevenueBy.MONTH) {
                      const format = 'MMMM'

                      return (
                        <BalanceListItem
                          key={dpIndex}
                          label={moment(date).format(format)}
                          value={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, amount)}
                        />
                      )
                    } else if (groupRevenueBy === GroupRevenueBy.QUARTER) {
                      const format = `[${t('RevenueReport::Quarter')}] Q`

                      return (
                        <BalanceListItem
                          key={dpIndex}
                          label={moment(date).format(format)}
                          value={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, amount)}
                        />
                      )
                    } else if (groupRevenueBy === GroupRevenueBy.YEAR) {
                      return (
                        <BalanceListItem
                          key={dpIndex}
                          label={moment(date).format('YYYY')}
                          value={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, amount)}
                        />
                      )
                    }

                  })}

                  <BalanceListItem
                    label={t('RevenueReport::Total')}
                    labelStyles={{ fontWeight: 'bold', color: 'black' }}
                    value={`${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, balanceTotal)}`}
                    valueStyles={{ fontWeight: 'bold', color: 'black' }}
                  />
                </BalanceListContent>
              </BalanceList>
            </div>

          )
        })}
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

export default connect(mapStateToProps)(withTranslation()(RevenueReport))