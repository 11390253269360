import * as React from 'react'
import styled, { css } from 'styled-components';
import Icon from '../Icons/Icon';
import ExpenseHelper from '../../helpers/ExpenseHelper';
import ExpenseCategoryHelper from '../../helpers/ExpenseCategoryHelper';
import { Expense } from '../../types';

interface IProps {
	expense?: Expense
	rounded?: boolean
	width?: number
	onClick: () => void
}

interface ContainerProps {
	rounded?: boolean
	width: number
	backgroundColor: string
	onClick?: () => void
}

const Container = styled.div`
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
	${(props: ContainerProps) => css`
		width: ${props.width}px;
		height: ${props.width}px;
		background-color: ${props.backgroundColor};
	`}
	border: 2px solid transparent;
	transition: border-color .3s;
	border-radius: 7px;
	overflow: hidden;

	${(props: ContainerProps) => props.rounded && css`
		border-radius: 50%;
	`}

	${(props: ContainerProps) => props.onClick && css`
		cursor: pointer;
	`}

	svg {
		width: 20px;
		fill: currentColor;
	}
`

const StyledIcon = styled(Icon)`
	color: white;
	fill: white;
`

export default class ExpenseAvatar extends React.PureComponent<IProps> {
	static defaultProps = {
		width: 40
	}

	render() {
		const { expense, rounded, width, onClick } = this.props

		const expenseColor = ExpenseHelper.getColor(expense)

		return (
			<Container
				rounded={rounded}
				width={width}
				backgroundColor={expenseColor}
				onClick={onClick}
			>
				{expense.category ? ExpenseCategoryHelper.getIcon(expense.category, 'white') : <StyledIcon icon='question-mark' />}
			</Container>
		)

	}
}