import styled from 'styled-components'
import TableCell from './TableCell'

const TableHead = styled.div`
	position: sticky;
	z-index: 4;
	top: 0;
	display: table-header-group;
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
	border-color: inherit;
	background-color: white;
	padding: 4px 10px;
	font-weight: bold;

	${TableCell} {
		border-bottom: none;	
		box-shadow: inset 0 -1px 0 #c4cdd5;

		&:first-child {
			position: sticky;
			left: 0;
			z-index: 3;
		}
	}
`

export default TableHead