import * as React from 'react'
import styled from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

interface IProps {
	icon: IconTypes
	onClick?: (e: React.MouseEvent) => void
	className?: string
	style?: object
}

export default class IconButton extends React.PureComponent<IProps> {
	static defaultProps = {
		className: '',
		style: {}
	}

	render() {
		const { icon, className, style, onClick } = this.props;

		return (
			<a
				href='javascript://'
				className={`button-icon ${className}`}
				onClick={onClick}
				style={style}
			>
				<IconContainer>
					<Icon icon={icon} />
				</IconContainer>
			</a>
		);
	}
}