import styled from 'styled-components'
import { Style } from '../../styles'

const ConversationHeader = styled.div`
	position: sticky;
	top: 0;
	width: 100%;
	min-height: 46px;
	border-bottom: 2px solid rgb(234, 237, 245);
	padding: ${Style.spacing.x2};
	background: white;
	z-index: 1;
`

export default ConversationHeader