import * as React from 'react'
import ContractsTable from '../Contracts/ContractsTable'

interface IProps {
  contactId: string
}

const ContactContracts = (props: IProps) => {
  const { contactId } = props

  return (
    // @ts-ignore
    <ContractsTable
      contactId={contactId}
      primaryActionEnabled={true}
    />
  )
}

export default ContactContracts 