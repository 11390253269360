import * as React from 'react'
import { ContentBlock, ContentBlockType, ContentBlockVariables, NumberFormat, ItemsBlock as ItemsBlockType, WorkspaceTax } from '../../../types'
import ImageBlock from './ImageBlock'
import InputBlock from './InputBlock'
import IntroBlock from './IntroBlock'
import ItemsBlock from './ItemsBlock'
import PDFBlock from './PDFBlock'
import SignBlock from './SignBlock'
import SubmitBlock from './SubmitBlock'
import SummaryBlock from './SummaryBlock'
import TextBlock from './TextBlock'
import QuestionAndAnswerBlock from './QuestionAndAnswerBlock'

interface IProps {
	block: ContentBlock
	variables: ContentBlockVariables
	taxes: WorkspaceTax[]
	currency: string
	numberFormat: NumberFormat
	items: ItemsBlockType[]
	onBlockChange: (block: ContentBlock) => void
	onSettingsBlockClick: (block: ContentBlock) => void
	onImportProductsClick: (block: ContentBlock) => void
}

const BlockInputs = (props: IProps) => {
	const {
		block,
		variables,
		onBlockChange,
		currency,
		numberFormat,
		taxes,
		items,
	} = props

	return (
		<>
			{block.type === ContentBlockType.TEXT && <TextBlock
				block={block}
				variables={variables}
				onChange={onBlockChange}
			/>}
			{block.type === ContentBlockType.IMAGE && <ImageBlock
				block={block}
				onChange={onBlockChange}
			/>}
			{block.type === ContentBlockType.PDF && <PDFBlock
				block={block}
				onChange={onBlockChange}
			/>}
			{block.type === ContentBlockType.SIGN && <SignBlock
				block={block}
				onChange={onBlockChange}
			/>}
			{block.type === ContentBlockType.INTRO && <IntroBlock
				block={block}
				variables={variables}
				onChange={onBlockChange}
			/>}
			{block.type === ContentBlockType.ITEMS && <ItemsBlock
				droppableId={block.id}
				block={block}
				currency={currency}
				numberFormat={numberFormat}
				taxes={taxes}
				onChange={onBlockChange}
			/>}
			{block.type === ContentBlockType.SUMMARY && <SummaryBlock
				block={block}
				currency={currency}
				numberFormat={numberFormat}
				items={items}
				onChange={onBlockChange}
			/>}
			{block.type === ContentBlockType.QUESTION_AND_ANSWER && <QuestionAndAnswerBlock
				block={block}
				onChange={onBlockChange}
			/>}
			{/* INPUT BLOCKS */}
			{(
				block.type === ContentBlockType.ADDRESS_INPUT ||
				block.type === ContentBlockType.COUNTRIES_INPUT ||
				block.type === ContentBlockType.DATE_INPUT ||
				block.type === ContentBlockType.DROPDOWN_INPUT ||
				block.type === ContentBlockType.EMAIL_INPUT ||
				block.type === ContentBlockType.FILE_UPLOAD_INPUT ||
				block.type === ContentBlockType.LINK_INPUT ||
				block.type === ContentBlockType.LONG_TEXT_INPUT ||
				block.type === ContentBlockType.NUMBER_INPUT ||
				block.type === ContentBlockType.PHONE_NUMBER_INPUT ||
				block.type === ContentBlockType.TEXT_INPUT ||
				block.type === ContentBlockType.SLIDER_INPUT
			) && <InputBlock
					block={block}
					onChange={onBlockChange}
				/>}
			{block.type === ContentBlockType.SUBMIT && <SubmitBlock
				block={block}
				onChange={onBlockChange}
			/>}
		</>
	)
}

export default BlockInputs