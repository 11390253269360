import styled from 'styled-components'

const TimelineHeaderButton = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
  margin: 0;
  padding: 7px 8px;
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition-property: background,border,box-shadow;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.64,0,.35,1);
  background: white;
  border: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    background: #E0E3E7;
  }

  svg {
    width: 15px;
    height: 15px;
    fill: #637381;
  }

  i {
    font-size: 15px;
    color: #637381;
  }
`

export default TimelineHeaderButton