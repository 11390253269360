import * as React from 'react'
import { Task, TaskChecklistItem } from '../../types'
import Icon from '../Icons/Icon'
import moment from '../../utilities/Moment'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import TimeFormatter from '../../utilities/TimeFormatter'

const Indicator = styled.div<{ type?: 'danger' | 'warning' | 'success' }>`
  color: #5e6c84;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  min-height: 20px;
  overflow: hidden;
  position: relative;
  padding: 2px 4px;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: top;
  font-size: 12px;
  font-weight: 500;

  > svg, i {
      font-size: 12px;
      width: 12px;
      height: 12px;
      fill: currentColor;
      margin-right: 4px;
  }

  ${props => props.type === 'danger' && css`
    background-color: ${Style.color.brandDanger};
    border-radius: 3px;
    color: #fff;
  `}

  ${props => props.type === 'warning' && css`
    background-color: ${Style.color.brandWarning};
    border-radius: 3px;
    color: #fff;
  `}

  ${props => props.type === 'success' && css`
    background-color: ${Style.color.brandSuccess};
    border-radius: 3px;
    color: #fff;
  `}
`

interface IProps {
  task: Task
}

const TaskIndicators = (props: IProps) => {
  const {
    task: {
      description,
      attachments,
      checklists,
      start_on,
      due_on,
      time_estimate,
      time_spent,
      completed,
    }
  } = props

  const momentNow = moment()
  const momentDueOn = moment(due_on)
  const dateFormat = 'MMM DD'
  const isOverdue = momentDueOn.isValid() && momentDueOn.isBefore(momentNow, 'day')
  const isDueSoon = momentDueOn.isValid() && momentDueOn.diff(momentNow, 'day') <= 2
  let badgeType: 'danger' | 'warning' | 'success' = null

  if (completed) {
    badgeType = 'success'
  } else if (isOverdue) {
    badgeType = 'danger'
  } else if (isDueSoon) {
    badgeType = 'warning'
  }

  let subtasks: TaskChecklistItem[] = []
  if (checklists) {
    checklists.forEach(checkList => {
      subtasks = [...subtasks, ...checkList.items]
    })
  }

  const subtaskCount = subtasks.length
  const completedSubtasksCount = subtasks.filter(subtask => subtask.completed).length
  const subtasksCompleted = subtaskCount === completedSubtasksCount

  return (
    <>
      {/** Has description */}
      {description.length > 0 && <Indicator><Icon icon='align-left' /></Indicator>}
      {/** Attachment */}
      {attachments.length > 0 && <Indicator><Icon icon='attachment' />{attachments.length}</Indicator>}
      {/** Todos */}
      {checklists.length > 0 && subtaskCount > 0 && <Indicator type={subtasksCompleted ? 'success' : null}>
        <Icon icon='check' />
        {completedSubtasksCount}/{subtaskCount}</Indicator>
      }
      {time_estimate > 0 && <Indicator type={time_spent > time_estimate ? 'danger' : null}><Icon icon='hourglass' /> {TimeFormatter.formatToHumanReadableDuration(time_estimate)}</Indicator>}
      {/** Has start and end date */}
      {start_on && due_on && <Indicator type={badgeType}>
        <Icon icon='clock' />
        {moment(start_on).format(dateFormat)} - {moment(due_on).format(dateFormat)}
      </Indicator>}
      {/** Only has end date */}
      {!start_on && due_on && <Indicator type={badgeType}>
        <Icon icon='clock' />
        {moment(due_on).format(dateFormat)}
      </Indicator>}
    </>
  )
}

export default TaskIndicators