import styled from 'styled-components'
import { Style } from '../../styles'

const ReportSummary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: ${Style.spacing.x3};
  padding-bottom: ${Style.spacing.x3};
  gap: ${Style.spacing.x3};
`

export default ReportSummary