import { css } from "styled-components";
import SidebarNavigationSubItems from "./SidebarNavigationSubItems";

const sidebarNavigationItemStyles = css`
  position: relative;
	display: flex;
	align-items: center;
	color: hsla(0,0%,100%,.8);
	display: flex;
	font-size: 16px;
	line-height: 46px;
	padding: 0 10px 0 20px;
	text-decoration: none;
	-webkit-transition: all .1s ease-out;
	transition: all .1s ease-out;
	background-color: transparent;
	font-weight: 600;
	min-height: 46px;
	cursor: pointer;
	fill: white;

	&:hover {
		background-color: #333;
		color: white;

		span {
			opacity: 1;
			visibility: visible;
			color: white;
		}
	}

	&.is-active {
		background-color: #07f;

		&:not(:only-child) {
			background: #262626;
			box-shadow: inset 0 1px 0 0 #000;
		}

		svg, i {
			fill: white;
			color: white;
		}

		span {
			color: white;
		}

		&:hover {
			background: #005fcc;
		}

		& + ${SidebarNavigationSubItems} {
			max-height: 500px;
		}
	}

	svg, i {
		width: 16px;
		height: 16px;
		fill: var(--currentFillColor);
		margin-right: 12px;
	}

	i {
		font-size: 16px;
		color: var(--currentFillColor);
	}

  span {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
`

export default sidebarNavigationItemStyles