import * as React from 'react'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next';
import { showConfirmModal, showBoardModal } from '../../store/modals/actions';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import { BoardsController } from '../../controllers';
import Notification from '../../utilities/Notification';
import { Helmet } from 'react-helmet';
import ListLoader from '../Loaders/ListLoader';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import { AppState } from '../../store';
import ButtonPanel from '../Button/ButtonPanel';
import ResourceTable from '../Resource/ResourceTable';
import ResourceTableRow from '../Resource/ResourceTableRow';
import ResourceTableRowActions from '../Resource/ResourceTableRowActions';
import ResourceTableRowData from '../Resource/ResourceTableRowData';
import { Board, CurrentUser, ResourceListFilterType } from '../../types';


interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showBoardModal: typeof showBoardModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = {
  contactId?: string,
  projectId?: string,
  primaryActionEnabled?: boolean
} & IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation
interface IState {
  boards: Board[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  filters: any
  sortValue: string
  searchValue: string
  isFetching: boolean
}


class BoardsTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      boards: [],
      currentPage: 1,
      totalPages: 0,
      didInitialLoad: false,
      filters: {},
      sortValue: LocalStorage.get(LocalStorageKey.BOARD_SORT_VALUE, 'created_at_desc'),
      searchValue: '',
      isFetching: false,
    }

    this.onNewBoardsClick = this.onNewBoardsClick.bind(this);
    this.onTableBoardClick = this.onTableBoardClick.bind(this);
    this.onTableBoardEditClick = this.onTableBoardEditClick.bind(this);
    this.onTableBoardDeleteClick = this.onTableBoardDeleteClick.bind(this);
    this.onTableBoardActionClick = this.onTableBoardActionClick.bind(this)
    this.onBoardFiltersChange = this.onBoardFiltersChange.bind(this)
    this.onBoardSortValueChange = this.onBoardSortValueChange.bind(this)
    this.onBoardFormSubmit = this.onBoardFormSubmit.bind(this)
    this.onBoardSearchChange = this.onBoardSearchChange.bind(this)
    this.onBoardSearchSubmit = this.onBoardSearchSubmit.bind(this)
    this.onBoardClearFilters = this.onBoardClearFilters.bind(this)
  }

  componentDidMount() {
    this.fetchBoards(1);
  }

  fetchBoards(page = 1) {
    const { contactId, projectId } = this.props
    const { filters, searchValue, sortValue } = this.state

    let params = {
      page: page,
      search: searchValue,
      order: sortValue,
      ...filters,
    }

    if (contactId) params['contact_id'] = contactId
    if (projectId) params['project_id'] = projectId

    this.setState({ isFetching: true })

    BoardsController
      .getBoards(params)
      .then(response => {
        const { boards, current_page, total_pages } = response

        this.setState({
          boards: [...boards],
          didInitialLoad: true,
          currentPage: current_page,
          totalPages: total_pages,
          isFetching: false,
        });
      })
      .catch(console.error)
  }

  createBoard(board: Board) {
    return BoardsController
      .create(board)
      .catch(console.error)
  }

  updateBoard(board: Board, callback) {
    const { t } = this.props

    BoardsController
      .update(board)
      .then(response => {
        const { errors } = response;

        if (errors) {
          Notification.notifyError(t('BoardsTable::Oops something went wrong.'))
        }
        else {
          Notification.notifySuccess(t('BoardsTable::Board successfully updated.'))
          callback(response);
        }

      })
      .catch(console.error)
  }

  deleteBoard(board: Board) {
    const { t } = this.props
    BoardsController
      .delete(board.id)
      .then(_response => {
        const { boards: boards } = this.state;

        const expenseIndex = boards.findIndex(i => i.id === board.id);

        boards.splice(expenseIndex, 1);

        this.setState({
          boards: boards
        });

        Notification.notifySuccess(t('BoardsTable::Board successfully removed.'))
      })
  }

  onNewBoardsClick() {
    const { showBoardModal, contactId, projectId } = this.props

    showBoardModal({
      board: {
        contact_id: contactId,
        project_id: projectId,
      },
      contactDisabled: Boolean(contactId),
      projectDisabled: Boolean(projectId),
      onSubmit: this.onBoardFormSubmit
    })
  }

  onTableBoardClick(board: Board) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_TASKS_BOARD, { id: board.id }))
  }

  onTableBoardEditClick(board: Board) {
    const { showBoardModal } = this.props

    showBoardModal({
      board: {
        id: board.id
      },
      onSubmit: this.onBoardFormSubmit
    })
  }

  onTableBoardDeleteClick(board: Board) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('BoardsTable::Delete board'),
      description: t('BoardsTable::You are about to delete a space. This board will be permanently deleted. Are you sure?'),
      action: { label: t('BoardsTable::Delete'), isDestructive: true },
      onConfirm: () => {
        this.deleteBoard(board)
      }
    })
  }

  onTableBoardActionClick(key: string, board: Board) {
    switch (key) {
      case 'edit': this.onTableBoardEditClick(board)
        break
      case 'delete': this.onTableBoardDeleteClick(board)
        break
      default:
        throw Error('[Track] Unimplemented onTableEntryActionClick')
    }
  }

  onBoardFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchBoards(1)
    })
  }

  onBoardSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.BOARD_SORT_VALUE, value)

    this.setState({ sortValue: value }, () => { this.fetchBoards(1) })
  }

  onBoardFormSubmit(board: Board) {
    const { boards: boards } = this.state

    const expenseIndex = boards.findIndex(e => e.id === board.id)

    if (expenseIndex !== -1) {
      // Update board
      boards[expenseIndex] = board
      this.setState({
        boards: [
          ...boards,
        ]
      })
    } else {
      this.setState({
        boards: [
          board,
          ...boards,
        ]
      })
    }
  }

  onBoardSearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onBoardSearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchBoards(1))
  }

  onBoardClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchBoards(1))
  }

  render(): JSX.Element {
    const { currentUser: { workspace: { setting } }, t, primaryActionEnabled } = this.props
    const {
      boards,
      didInitialLoad,
      currentPage,
      totalPages,
      filters,
      sortValue,
      isFetching,
      searchValue
    } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{t('BoardsTable::{{__appName}} | Boards')}</title>
        </Helmet>

        {!didInitialLoad && <ListLoader />}
        {didInitialLoad && <ResourceTable
          data={boards}
          headers={[
            { title: t('Boards::Name') },
            { title: t('Boards::Contact') },
            { title: t('Boards::Project') },
            { title: '', stickyRight: '0px' },
          ]}
          renderRow={(board: Board) => {
            return (
              <ResourceTableRow key={board.id}>
                <ResourceTableRowData onClick={() => this.onTableBoardClick(board)} maxWidth='150px' ellipse>
                  {board.name}
                </ResourceTableRowData>

                <ResourceTableRowData onClick={() => this.onTableBoardClick(board)} maxWidth='150px' ellipse>
                  {board.contact ? board.contact.name : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={() => this.onTableBoardClick(board)} maxWidth='150px' ellipse>
                  {board.project ? board.project.name : '-'}
                </ResourceTableRowData>

                <ResourceTableRowActions
                  actions={[
                    { key: 'edit', icon: 'edit-solid', content: t('BoardsTable::Edit') },
                    { key: 'delete', icon: 'trash-alt-solid', content: t('BoardsTable::Delete'), destructive: true }
                  ]}
                  onActionClick={(key) => this.onTableBoardActionClick(key, board)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'boards'}
            description={filtersActive ? t('BoardsTable::No boards found') : t('BoardsTable::No boards have been created yet')}
            descriptionActionText={filtersActive ? t('BoardsTable::Clear filters') : t('BoardsTable::Add board')}
            onDescriptionActionClick={filtersActive ? this.onBoardClearFilters : this.onNewBoardsClick}
          />}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('BoardsTable::New board')}
              onClick={this.onNewBoardsClick}
            />
          ] : null}
          filters={[
            { name: 'name', label: t('BoardsTable::Name'), type: ResourceListFilterType.STRING },
            { name: 'contact_id', label: t('BoardsTable::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
            { name: 'project_id', label: t('BoardsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
            { name: 'created_at', label: t('BoardsTable::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onBoardFiltersChange}
          sortOptions={[
            { label: '-', value: '-' },
            { label: t('BoardsTable::Name (A-Z)'), value: 'name_asc' },
            { label: t('BoardsTable::Name (Z-A)'), value: 'name_desc' },
            { label: t('BoardsTable::Created at ↑'), value: 'created_at_asc' },
            { label: t('BoardsTable::Created at ↓'), value: 'created_at_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onBoardSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchBoards(page)}
          isLoading={isFetching}
          stickyHeader={true}
          searchValue={searchValue}
          onSearchChange={this.onBoardSearchChange}
          onSearchSubmit={this.onBoardSearchSubmit}
          maxHeight='65vh'
        />}
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showBoardModal: (options) => dispatch(showBoardModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BoardsTable))