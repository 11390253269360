import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { AppState } from '../store'
import TopNavigation from '../components/Navigation/TopNavigation'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageHeader from '../components/Page/PageHeader'
import { RouteComponentProps, Switch, Route, Redirect, NavLink } from 'react-router-dom'
import ERoute from '../ERoute'
import RevenueReport from './Reporting/RevenueReport'
import ExpenseReport from './Reporting/ExpenseReport'
import { Style } from '../styles'
import PageContent from '../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'
import { CurrentUser } from '../types'

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps { }

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{}> & WithTranslation

interface IStateToProps { }

interface IState { }

const PageHeaderNavigation = styled.div`
	margin-top: -20px;
	margin-bottom: ${Style.spacing.x2};

	.navigation-tabs {
		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			padding: 0;
		}
	}

	.navigation-tab {
		&.is-active {
			background: transparent;
		}

		&:hover {
			background: transparent;
		}
	}
`

class Reporting extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.onBreadCrumbBackPress = this.onBreadCrumbBackPress.bind(this)
  }

  onBreadCrumbBackPress() {
    this.props.history.goBack()
  }

  renderContent() {
    const { t } = this.props

    return (
      <>
        <PageHeaderNavigation>
          <div className='navigation-tabs'>
            <NavLink exact to={ERoute.PATH_REPORTING_REVENUE} className='navigation-tab' activeClassName='is-active' replace={true}>
              {t('Prospects::Turnover')}
              <div className='navigation-tab-indicator' />
            </NavLink>

            <NavLink exact to={ERoute.PATH_REPORTING_EXPENSES} className='navigation-tab' activeClassName='is-active' replace={true}>
              {t('Prospects::Expenses')}
              <div className='navigation-tab-indicator' />
            </NavLink>
          </div>
        </PageHeaderNavigation>

        <Switch>
          <Route path={ERoute.PATH_REPORTING_REVENUE} component={RevenueReport} />
          <Route path={ERoute.PATH_REPORTING_EXPENSES} component={ExpenseReport} />
          <Redirect to={ERoute.PATH_REPORTING_REVENUE} />
        </Switch>
      </>
    )
  }

  render() {
    const { t } = this.props

    return (
      <>
        <Helmet>
          <title>{t('Prospects::{{__appName}} | Reporting')}</title>
        </Helmet>
        <TopNavigation
          icon='reporting-navigation'
          title={t('Prospects::Reporting')}
        />

        <ScrollToTopOnMount />

        <PageContent>
          <PageHeader
            title={t('Prospects::Reporting')}
            breadcrumbs={[
              { content: t('Prospects::Back'), url: 'javascript://', onClick: this.onBreadCrumbBackPress }
            ]}
          />

          {this.renderContent()}
        </PageContent>
      </>
    )
  }
}


const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Reporting))