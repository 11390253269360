import * as React from 'react'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import { useTranslation } from 'react-i18next'
import FullPageContent from '../../components/Page/FullPageContent'
import TopSubnavigation from '../../components/Navigation/TopSubnavigation'
import TabItem from '../../components/Navigation/TabItem'
import TopNavigation from '../../components/Navigation/TopNavigation'
import { matchPath, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import SequenceEditor from './SequenceEditor'
import SequenceSettings from './SequenceSettings'
import SequenceEnrollments from './SequenceEnrollments'

type IProps = & RouteComponentProps<any>

const Sequence = (props: IProps) => {
  const { t } = useTranslation()
  const { history, match: { params: { id } } } = props
  const activePath = props.location.pathname


  const onNavigateItemClick = (path: string) => {
    history.replace(path)
  }

  return (
    <>
      <ScrollToTopOnMount />

      <TopNavigation
        icon='paper-plane'
        title={t('Sequence::Sequence')}
      />

      <FullPageContent style={{ background: 'transparent' }}>
        <TopSubnavigation>
          <TabItem
            active={!!matchPath(activePath, { path: ERoute.PATH_SEQUENCE_OVERVIEW, exact: true })}
            onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_SEQUENCE_OVERVIEW, { id: id }))}
          >
            {t('Sequence::Overview')}
          </TabItem>
          <TabItem
            active={!!matchPath(activePath, { path: ERoute.PATH_SEQUENCE_ENROLLMENTS, exact: true })}
            onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_SEQUENCE_ENROLLMENTS, { id: id }))}
          >
            {t('Sequence::Enrollments')}
          </TabItem>
          <TabItem
            active={!!matchPath(activePath, { path: ERoute.PATH_SEQUENCE_SETTINGS, exact: true })}
            onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_SEQUENCE_SETTINGS, { id: id }))}
          >
            {t('Sequence::Settings')}
          </TabItem>
        </TopSubnavigation>

        <Switch>
          <Route path={ERoute.PATH_SEQUENCE_OVERVIEW} component={SequenceEditor} />
          <Route path={ERoute.PATH_SEQUENCE_ENROLLMENTS} component={SequenceEnrollments} />
          <Route path={ERoute.PATH_SEQUENCE_SETTINGS} component={SequenceSettings} />
          <Redirect to={RouteHelper.process(ERoute.PATH_SEQUENCE_OVERVIEW, { id: id })} />
        </Switch>
      </FullPageContent>
    </>
  )
}

export default Sequence