import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import Icon, { IconTypes } from '../Icons/Icon'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-size: 15px;
  border: 1px solid ${Style.color.border};
  text-align: center;
  border-radius: 10px;
  padding: 5px 12px 5px 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  color: #486581;

  &:hover {
    background: #f6f6f7;
  }

  svg {
    margin-left: 4px;
    margin-top: 2px;
    width: 10px;
    height: 10px;
    fill: currentColor;
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid ${Style.color.border};
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.25);
  background: white;
  top: 40px;
  right: 0;
  z-index: 4;


  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    left: 50%;
    transform: translateX(-50%);
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${Style.color.border};
  padding: 5px 15px;
  cursor: pointer;

  &:hover {
    background: #f6f6f7;
  }

  &:first-child {
    border-radius: 10px 10px 0 0;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }
`

const IconContainer = styled.div<{ background: string, color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  margin-right: ${Style.spacing.x1_5};

  svg {
    fill: currentColor;
    width: 14px;
    height: 14px;
  }

  i {
    color: currentColor;
    font-size: 14px;
  }
`

export interface IAttachItem {
  icon: IconTypes
  iconBackground: string
  iconColor: string
  label: string
  onClick: () => void
  visible?: boolean
}

interface IProps {
  items: IAttachItem[]
}

interface IState {
  open: boolean
}

const AttachItem = (props: IProps) => {
  const { items } = props
  const container = React.useRef<HTMLDivElement>()
  const { t } = useTranslation()

  const [state, setState] = React.useState<IState>({
    open: false
  })
  const { open } = state

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (container.current && !container.current.contains(event.target)) {
        setState({ open: false })
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [container]);

  const onActionClick = () => {
    setState({ ...state, open: !state.open })
  }

  const onItemclick = (item: IAttachItem) => {
    setState({ ...state, open: false })

    item.onClick()
  }

  return (
    <Container ref={container}>
      <Action onClick={onActionClick}>
        {t('AttachItem::Attach an item')}
        <Icon icon='chevron-down' />
      </Action>

      {open && <Items>
        {items
          .filter(header => header.visible !== false)
          .map(item => {
            return (
              <Item onClick={() => onItemclick(item)}>
                <IconContainer background={item.iconBackground} color={item.iconColor}>
                  <Icon icon={item.icon} />
                </IconContainer>
                {item.label}
              </Item>
            )
          })}
      </Items>}
    </Container>
  )
}

export default AttachItem