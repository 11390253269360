import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import ActionCableConsumer from '../../consumers/ActionCableConsumer'
import { TasksController } from '../../controllers'
import { AppState } from '../../store'
import { showConfirmModal, showTaskModal } from '../../store/modals/actions'
import { Style } from '../../styles'
import { CurrentUser, ResourceListFilterType, Task, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../../types'
import moment from '../../utilities/Moment'
import ButtonPanel from '../Button/ButtonPanel'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import Icon from '../Icons/Icon'
import PageHeaderNavigation from '../Page/PageHeaderNavigation'
import PageHeaderNavigationLeft from '../Page/PageHeaderNavigationLeft'
import PageLoader from '../Page/PageLoader'
import ResourceFilterComponent from '../Resource/ResourceFilter'
import { ResourceSearch, ResourceSearchCloseIcon, ResourceSearchIcon } from '../Resource/ResourceHeader'
import TasksTable from './TasksTable'
import ReactTooltip from 'react-tooltip'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'


const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const TasksNavigationContainer = styled(PageHeaderNavigation)`
	margin-bottom: ${Style.spacing.x1_25};

	> div {
		> * {
			margin: 4px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
`

export enum TaskViewMode {
	OVERVIEW = 'overview',
	WORK_OVERVIEW = 'work-overview',
	MY_TASKS = 'my-tasks',
	TODAY = 'today',
	UPCOMING = 'upcoming',
}

interface IStateToProps {
	currentUser: CurrentUser
}
interface IDispatchToProps {
	showTaskModal: typeof showTaskModal
	showConfirmModal: typeof showConfirmModal
}

type IProps = {
	mode: TaskViewMode
	contactId?: string
	projectId?: string
	assigneeId?: string
} & IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
	tasks: Task[]
	currentPage: number,
	endReached: boolean
	didInitialLoad: boolean
	isFetching: boolean
	filters: any,
	searchValue: string
	sortValue: string
}

class TaskView extends React.Component<IProps, IState> {
	constructor(props) {
		super(props)

		this.state = {
			tasks: [],
			currentPage: 0,
			didInitialLoad: false,
			endReached: false,
			isFetching: false,
			filters: {},
			searchValue: '',
			sortValue: '-'
		}

		this.fetchTasks = this.fetchTasks.bind(this)
		this.onNewTaskClick = this.onNewTaskClick.bind(this)
		this.onTasksEndReached = this.onTasksEndReached.bind(this)
		this.onTaskClick = this.onTaskClick.bind(this)
		this.onTaskBoardClick = this.onTaskBoardClick.bind(this)
		this.onTaskModalSubmit = this.onTaskModalSubmit.bind(this)
		this.onTaskDelete = this.onTaskDelete.bind(this)
		this.onTaskChange = this.onTaskChange.bind(this)
		this.onTaskFiltersChange = this.onTaskFiltersChange.bind(this)
		this.onTaskSortValueChange = this.onTaskSortValueChange.bind(this)
		this.onTaskSearchChange = this.onTaskSearchChange.bind(this)
		this.onTaskSearchClear = this.onTaskSearchClear.bind(this)
		this.onTaskSearchKeyUp = this.onTaskSearchKeyUp.bind(this)
		this.onTaskClearFilters = this.onTaskClearFilters.bind(this)
		this.onActionCableConnected = this.onActionCableConnected.bind(this)
		this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
		this.onActionCableReceived = this.onActionCableReceived.bind(this)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		ReactTooltip.rebuild()
		if (prevProps.mode !== this.props.mode) {
			this.fetchTasks(1).catch(console.error)
		}
	}
	componentDidMount() {
		this.fetchTasks(1).catch(console.error)
	}

	async fetchTasks(page: number) {
		try {
			const { mode, currentUser, contactId, projectId } = this.props
			const { tasks: stateTasks, searchValue, sortValue, filters } = this.state;

			let parameters = {
				page: page,
				search: searchValue,
				...filters,
			}

			if (mode === TaskViewMode.WORK_OVERVIEW) {
				parameters = {
					per_page: 1000,
					...parameters,
				}
			} else {
				parameters = {
					completed: false,
					...parameters,
				}
			}

			if (contactId) parameters = { ...parameters, contact_id: contactId }
			if (projectId) parameters = { ...parameters, project_id: projectId }
			if (sortValue) parameters = { ...parameters, order: sortValue }

			switch (mode) {
				case TaskViewMode.OVERVIEW:
				case TaskViewMode.WORK_OVERVIEW:
					// No need to add any parameters
					break
				case TaskViewMode.MY_TASKS:
					parameters = {
						...parameters,
						assignee_ids: [currentUser.id]
					}
					break
				case TaskViewMode.TODAY:
					parameters = {
						...parameters,
						'due_on[gte]': moment().startOf('day').toISOString(),
						'due_on[lte]': moment().endOf('day').toISOString(),
					}
					break
				case TaskViewMode.UPCOMING:
					parameters = {
						'due_on[gte]': moment().add(1, 'day').startOf('day').toISOString(),
						'due_on[lte]': moment().add(7, 'day').endOf('day').toISOString(),
					}
					break
			}

			const response = await TasksController.getTasks(parameters)

			const { tasks, current_page, total_pages, total_entries } = response;

			this.setState({
				tasks: page === 1 ? [...tasks] : [...stateTasks, ...tasks],
				currentPage: current_page,
				endReached: current_page === total_pages,
				didInitialLoad: true,
				isFetching: false
			});
		} catch (ex) {
			console.error(ex)
		}
	}

	onNewTaskClick() {
		const { mode, currentUser, contactId, projectId } = this.props
		let task: Task = {}

		if (contactId) task = { ...task, contact_id: contactId }
		if (projectId) task = { ...task, project_id: projectId }

		switch (mode) {
			case TaskViewMode.OVERVIEW:
				// No need to add any parameters
				break
			case TaskViewMode.MY_TASKS:
				task = {
					assignee_ids: [currentUser.id],
					assignees: [currentUser],
				}
				break
			case TaskViewMode.TODAY:
				task = {
					due_on: moment().endOf('day').toISOString(),
				}
				break
			case TaskViewMode.UPCOMING:
				task = {
					due_on: moment().add(1, 'day').startOf('day').toISOString(),
				}
				break
		}

		requestAnimationFrame(() => {
			this.props.showTaskModal({
				task: {
					...task,
				},
				onSubmit: this.onTaskModalSubmit,
				onDelete: this.onTaskDelete
			})
		})
	}

	onTaskClick(task: Task) {
		requestAnimationFrame(() => {
			this.props.showTaskModal({
				task: {
					id: task.id,
				},
				onSubmit: this.onTaskModalSubmit,
				onDelete: this.onTaskDelete
			})
		})
	}

	onTaskModalSubmit(task: Task) {
		const { tasks } = this.state

		const taskIndex = tasks.findIndex(t => t.id === task.id)

		if (taskIndex !== -1) {
			tasks[taskIndex] = task

			this.setState({ tasks: [...tasks] })
		} else {
			this.setState({ tasks: [task, ...tasks] })
		}
	}

	onTaskDelete(task: Task) {
		const { tasks } = this.state

		const taskIndex = tasks.findIndex(t => t.id === task.id)

		if (taskIndex !== -1) {
			tasks.splice(taskIndex, 1)

			this.setState({
				tasks: [...tasks]
			})
		}
	}

	async onTaskChange(task: Task) {
		try {
			// Update locale cache
			this.onTaskModalSubmit(task)

			// Update remote
			const response = await TasksController.update(task)

			// Update locale cache (errors might have occured)
			this.onTaskModalSubmit(response)
		} catch (ex) {
			console.error(ex)
		}
	}

	onTaskBoardClick(task: Task) {
		this.props.history.push(RouteHelper.process(ERoute.PATH_TASKS_BOARD, { id: task.board_id }))
	}

	onTasksEndReached() {
		const { currentPage, endReached } = this.state
		if (!endReached) this.fetchTasks(currentPage + 1)
	}

	onTaskFiltersChange(filters: any) {
		this.setState({ filters: filters }, () => {
			this.fetchTasks(1)
		})
	}

	onTaskSortValueChange(value: string) {
		this.setState({ sortValue: value }, () => {
			this.fetchTasks(1)
		})
	}

	onTaskSearchChange(searchValue) {
		this.setState({ searchValue: searchValue })
	}

	onTaskSearchClear() {
		this.setState({ searchValue: '' }, () => this.fetchTasks(1))
	}

	onTaskSearchKeyUp(e) {
		if (e.key === 'Enter') {
			this.fetchTasks(1)
		}
	}

	onTaskClearFilters() {
		this.setState({
			searchValue: '',
			filters: {}
		}, () => this.fetchTasks(1))
	}

	onActionCableConnected() {
		console.log('[WorkspaceChannel] connected')
	}

	onActionCableDisconnected() {
		console.log('[WorkspaceChannel] Disconnected')
	}

	onActionCableReceived(event: WorkspaceChannelEvent) {
		console.log('[WorkspaceChannel] received event', event)
		const { tasks } = this.state

		switch (event.type) {
			case WorkspaceChannelEventType.TASK_CREATE:
				// Not implemented but should be and accounts for filters
				break
			case WorkspaceChannelEventType.TASK_UPDATE:
				const taskIndex = tasks.findIndex(task => task.id === event.data.task.id)

				if (taskIndex !== -1) {
					tasks[taskIndex] = event.data.task

					this.setState({ tasks: [...tasks] })
				}
				break
			case WorkspaceChannelEventType.TASK_DELETE:
				this.setState({ tasks: tasks.filter(task => task.id !== event.data.task_id) })
				break
		}
	}

	render() {
		const { currentUser, t, mode, contactId, projectId } = this.props
		const { workspace: { setting } } = currentUser
		const { didInitialLoad, tasks, searchValue, filters, sortValue } = this.state

		const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

		return (
			<Container>
				{!didInitialLoad && <PageLoader />}
				{didInitialLoad &&
					<ActionCableConsumer
						channel={{ channel: 'WorkspaceChannel', id: currentUser.workspace.id }}
						onConnected={this.onActionCableConnected}
						onDisconnected={this.onActionCableDisconnected}
						onReceived={this.onActionCableReceived}
					>
						<TasksNavigationContainer>
							<PageHeaderNavigationLeft>
								<ButtonPanel
									icon='plus'
									text={t('TaskView::New task')}
									onClick={this.onNewTaskClick}
								/>

								<ResourceSearch>
									<ResourceSearchIcon>
										<Icon icon='search' />
									</ResourceSearchIcon>
									<input
										type='text'
										placeholder={t('ResourceHeader::Search...')}
										value={searchValue}
										onChange={(e) => this.onTaskSearchChange(e.currentTarget.value)}
										onKeyUp={this.onTaskSearchKeyUp}
									/>
									{searchValue?.length > 0 && <ResourceSearchCloseIcon onClick={this.onTaskSearchClear}>
										<Icon icon='close' />
									</ResourceSearchCloseIcon>}
								</ResourceSearch>

								<ResourceFilterComponent
									filters={[
										{ name: 'completed', label: t('TaskView::Completed'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Contact::Yes'), value: String(true) }, { label: t('Contact::No'), value: String(false) }] },
										{ name: 'name', label: t('TaskView::Name'), type: ResourceListFilterType.STRING },
										{ name: 'description', label: t('TaskView::Description'), type: ResourceListFilterType.STRING },
										{ name: 'contact_id', label: t('TaskView::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
										{ name: 'project_id', label: t('TaskView::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(projectId) },
										{ name: 'assignee_ids', label: t('TaskView::Assignees'), type: ResourceListFilterType.MULTI_RESOURCE, resourceType: 'user', isValidNewOption: () => false },
										{ name: 'start_on', label: t('TaskView::Start date'), type: ResourceListFilterType.DATE },
										{ name: 'due_on', label: t('TaskView::Due date'), type: ResourceListFilterType.DATE },
									]}
									onSubmit={this.onTaskFiltersChange}
								/>
							</PageHeaderNavigationLeft>
						</TasksNavigationContainer>

						{tasks.length === 0 && <>
							<CardEmptyInfo
								icon={filtersActive ? 'search' : 'tasks'}
								description={filtersActive ? t('Task::No tasks found') : t('Task::No tasks have been created yet')}
								descriptionActionText={filtersActive ? t('Task::Clear filters') : t('Task::Add new task')}
								onDescriptionActionClick={filtersActive ? this.onTaskClearFilters : this.onNewTaskClick}
							/>
						</>}

						{tasks.length > 0 &&
							<TasksTable
								mode={mode}
								tasks={tasks}
								dateFormat={setting.date_format}
								onTaskClick={this.onTaskClick}
								onTaskChange={this.onTaskChange}
								onTaskBoardClick={this.onTaskBoardClick}
								maxHeight='calc(100vh - 168px)'
								sortValue={sortValue}
								onSortChange={this.onTaskSortValueChange}
								onEndReached={this.onTasksEndReached}
							/>}
					</ActionCableConsumer>}
			</Container>
		)
	}
}


const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {
		showTaskModal: (options) => dispatch(showTaskModal(options)),
		showConfirmModal: (options) => dispatch(showConfirmModal(options))
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TaskView)))