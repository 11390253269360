import * as React from 'react'
import ExpensesTable from '../Expenses/ExpensesTable'

interface IProps {
	supplierId: string
}

const ContactPurchaseInvoices = (props: IProps) => {
	const { supplierId } = props

	return (
		// @ts-ignore
		<ExpensesTable
			supplierId={supplierId}
			primaryActionEnabled
		/>
	)
}

export default ContactPurchaseInvoices