import * as React from 'react'
import { useTranslation } from 'react-i18next'
import FixedSidebarContainer from '../Sidebar/FixedSidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import styled from 'styled-components'
import { Style } from '../../styles'
import { CalendarsController } from '../../controllers'
import { CalendarIntegration, CalendarIntegrationType } from '../../types'
import Images from '../../images'

const CalendarIntegrationContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${Style.color.border};
	padding: 20px;
	background: white;
	border-radius: 6px;
`

const CalendarIntegrationImage = styled.div`
	width: 48px;
	height: 48px;
	margin-bottom: ${Style.spacing.x2};
`

const CalendarIntegrationTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	color: black;
`

const CalendarIntegrationDescription = styled.div`
	font-size: 14px;
	color: rgb(107, 114, 128);
	overflow: hidden;
	text-overflow: ellipsis;
`

const CalendarIntegrationAction = styled.div`
	color: black;
	padding: 10px 16px;
	text-align: center;
	border: 1px solid ${Style.color.border};
	border-radius: 6px;
	cursor: pointer;
	font-size: 14px;
	margin-top: ${Style.spacing.x1};

	&:hover {

		background: rgb(249, 250, 251);
		border: 1px solid black;
	}
`

interface IProps {
	active: boolean
	onCloseClick: () => void
}

interface IState {
	calendars: CalendarIntegration[]
}

const CalendarSidebarIntegrations = (props: IProps) => {
	const { active } = props
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>()
	const [state, setState] = React.useState<IState>({
		calendars: []
	})
	const { calendars } = state

	const calendarIntegrations: {
		icon: string
		type: CalendarIntegrationType
		title: string
		description: string
	}[] = [
			{
				icon: Images.OUTLOOK_CALENDAR,
				type: CalendarIntegrationType.MICROSOFT,
				title: t('CalendarSidebarIntegrations::Outlook Calendar'),
				description: t('CalendarSidebarIntegrations::Microsoft Office 365 is a suite of apps that helps you stay connected with others and get things done. It includes but is not limited to Microsoft Word, PowerPoint, Excel, Teams, OneNote and OneDrive. Office 365 allows you to work remotely with others on a team and collaborate in an online environment. Both web versions and desktop/mobile applications are available.'),
			},
			{
				icon: Images.GOOGLE_CALENDAR,
				type: CalendarIntegrationType.GOOGLE,
				title: t('CalendarSidebarIntegrations::Google Calendar'),
				description: t('CalendarSidebarIntegrations::Google Calendar is a time management and scheduling service developed by Google.  Allows users to create and edit events, with options available for type and time.  Available to anyone that has a Gmail account on both mobile and web versions.'),
			},
		]

	const fetchCalendars = async () => {
		try {
			const response = await CalendarsController.getCalendars({})

			setState({ calendars: response })

		} catch (ex) {
			console.error(ex)
		}
	}

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideClick);

		if (active) fetchCalendars().catch(console.error)

		return () => {
			document.removeEventListener('mousedown', onOutsideClick);
		}
	}, [active])

	const onOutsideClick = (e) => {
		if (active && container && !container.current.contains(e.target)) {
			props.onCloseClick()
		}
	}

	const onCloseClick = () => {
		props.onCloseClick()
	}

	const onConnectClick = (calendarType: CalendarIntegrationType) => {
		switch (calendarType) {
			case CalendarIntegrationType.GOOGLE:
				window.location.assign('/auth/google?action=calendar&scope=email,https://www.googleapis.com/auth/calendar.events')
				break
			case CalendarIntegrationType.MICROSOFT:
				window.location.assign('/auth/microsoft_office365?action=calendar&scope=openid User.Read Calendars.ReadWrite offline_access')
				break
		}
	}

	const onDisconnectClick = async (calendar: CalendarIntegration) => {
		try {
			const response = await CalendarsController.delete(calendar.id)

			setState({
				...state,
				calendars: calendars.filter(c => c.id !== calendar.id)
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	return (
		<FixedSidebarContainer ref={container} active={active} style={{ zIndex: 8 }}>
			<SidebarHeader>
				{t('CalendarSidebarIntegrations::Integrations')}
				<SidebarClose onClick={onCloseClick} />
			</SidebarHeader>

			<SidebarDivider />
			<SidebarContent>
				{calendarIntegrations.map((calendarIntegration, index) => {
					const calendar = calendars.find(calendar => calendar.type === calendarIntegration.type)

					return (
						<CalendarIntegrationContainer key={index}>
							<CalendarIntegrationImage>
								<img src={calendarIntegration.icon} />
							</CalendarIntegrationImage>

							<CalendarIntegrationTitle>
								{calendarIntegration.title}
							</CalendarIntegrationTitle>

							<CalendarIntegrationDescription title={calendarIntegration.description}>
								{calendarIntegration.description}
							</CalendarIntegrationDescription>

							{!calendar && <CalendarIntegrationAction onClick={() => onConnectClick(calendarIntegration.type)}>
								{t('CalendarSidebarIntegrations::Connect')}
							</CalendarIntegrationAction>}

							{calendar && <CalendarIntegrationAction onClick={() => onDisconnectClick(calendar)}>
								{t('CalendarSidebarIntegrations::Disconnect')}
							</CalendarIntegrationAction>}
						</CalendarIntegrationContainer>
					)
				})}
			</SidebarContent>
		</FixedSidebarContainer>
	)
}

export default CalendarSidebarIntegrations