import * as React from 'react'
import { WithTranslation, withTranslation } from "react-i18next"
import styled from 'styled-components'
import EditorHelper from '../../../helpers/EditorHelper'
import { Style } from '../../../styles'
import { ContentBlockVariables, TextBlock } from '../../../types'
import Editor, { BLOCK_EDITOR_CONFIG, DEFAULT_EDITOR_CONFIG } from '../../Editor/Editor'
import { EditBlockProps } from '../Block'
import BlockEditorContainer from "../BlockEditorContainer"

const Container = styled.div`
	background: white;
	border-bottom-left-radius: ${Style.variables.baseBorderRadius};
	border-bottom-right-radius: ${Style.variables.baseBorderRadius};
	padding-bottom: 1px;
`

type IProps = {
	variables: ContentBlockVariables
} & EditBlockProps<TextBlock> & WithTranslation

interface IState {
	model: object | string
}

class TextBlockComponent extends React.Component<IProps, IState> {
	private editor = React.createRef<Editor>()

	constructor(props: IProps) {
		super(props)

		this.state = {
			model: props.block.content,
		}

		this.onModelChange = this.onModelChange.bind(this)
		this.debouncedModelSubmit = this.debouncedModelSubmit.bind(this)
	}

	onModelChange(model: object) {
		this.setState({ model: model })

		this.debouncedModelSubmit()
	}

	debouncedModelSubmit() {
		const { block } = this.props

		if (this.editor.current) {
			const content = this.editor.current.getEditor().html.get()
			this.props.onChange({ ...block, content: content })
		}
	}

	render() {
		const { variables, t } = this.props
		const { model } = this.state

		const variableOptions = EditorHelper.getVariableOptions(variables)

		return (
			<Container>
				<BlockEditorContainer style={{ padding: 20 }}>
					<Editor
						key={String(variableOptions.length)}
						ref={this.editor}
						model={model}
						onModelChange={this.onModelChange}
						config={{
							...BLOCK_EDITOR_CONFIG,
							variableOptions: variableOptions,
						}}
					/>
				</BlockEditorContainer>
			</Container>
		)
	}
}

export default withTranslation()(TextBlockComponent)