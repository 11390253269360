import * as React from 'react'
import { CallSequenceStep, ManualEmailSequenceStep, SequenceStep, TaskSequenceStep } from "../../../types"
import StepContent from '../StepContent'
import { useTranslation } from 'react-i18next'
import StepHeaderMetadata from '../StepHeaderMetadata'
import StepHeaderMedataItem from '../StepHeaderMedataItem'
import styled from 'styled-components'
import { Style } from '../../../styles'
import StepInfo from '../Parts/StepInfo'
import StepName from '../Parts/StepName'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Style.spacing.x2};
  cursor: pointer;
  margin-bottom: 16px;

  p {
    width: 100%;
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column-reverse;
  }
`

interface IProps {
  step: ManualEmailSequenceStep | CallSequenceStep | TaskSequenceStep
  onEditClick: () => void
  editable: boolean
}
const Task = (props: IProps) => {
  const { t } = useTranslation()
  const { step, onEditClick, editable } = props

  const createdCounter = Number(step.created_counter) || 0
  const completedCounter = Number(step.completed_counter) || 0

  return (
    <StepContent onClick={editable ? onEditClick : () => { }} backgroundOnHover={true} style={{ cursor: editable ? 'pointer' : 'default' }}>
      <Wrapper>
        <StepName>
          {step.name}
        </StepName>

        <StepHeaderMetadata>
          <StepHeaderMedataItem
            label={t('Call::Created')}
            value={String(createdCounter)}
          />
          <StepHeaderMedataItem
            label={t('Call::Completed')}
            value={createdCounter > 0 ? `${Math.round(completedCounter / createdCounter * 100)}%` : '0%'}
          />
        </StepHeaderMetadata>
      </Wrapper>

      <StepInfo className='text-editor'>
        <FroalaEditorView
          model={step.description}
        />
      </StepInfo>
    </StepContent>
  )
}

export default Task