import styled from "styled-components";

const SimplebarContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 160px;
`

export default SimplebarContent