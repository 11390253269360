import styled from 'styled-components'

const ReportSummaryPercentIndicatorInfo = styled.div`
  padding-left: calc(80px + 16px);
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default ReportSummaryPercentIndicatorInfo