import styled from 'styled-components'

const TableCellContent = styled.div<{ justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' }>`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: calc(24px + 1px);
	word-break: break-word;
	width: 100%;
	grid-gap: 4px;

	${props => props.justifyContent && `
		justify-content: ${props.justifyContent};
	`}
`

export default TableCellContent