import styled from 'styled-components'
import { Style } from '../../styles'

const StepFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${Style.color.border};
  width: 100%;
  padding: 16px;
  min-height: 64px;
`

export default StepFooter