import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import Icon from '../Icons/Icon'

const Container = styled.div`
	position: relative;
  cursor: pointer;
`

const Wrapper = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;
	letter-spacing: normal;
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 5px 8px 5px 12px;
	min-height: 38px;
`

const FilterInlineLabel = styled.div`
	margin-right: 4px;
	white-space: nowrap;
	overflow: hidden;
`

const FilterInlineDivider = styled.div`
	width: 1px;
	height: 100%;
	background-color: ${Style.color.border};
	margin-left: ${Style.spacing.x0_5};
	margin-right: ${Style.spacing.x1};
	min-height: 25px;
`

const FilterInlineValue = styled.div`
	white-space: nowrap;
	overflow: hidden;
	font-weight: bolder;
  margin-right: ${Style.spacing.x0_5};
`

const WrapperIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: ${Style.spacing.x1};

	svg {
		width: 16px;
		height: 16px;
	}
`

const WrapperBackdrop = styled.div`
	background-color: #c4cdd5;
	border: 1px solid transparent;
	box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
	border-radius: 3px;
	transition-property: box-shadow,background-color;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.64,0,.35,1);
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&::after {
		content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background: linear-gradient(180deg, #fff, #f9fafb);
    border-radius: 2px;
	}
`


interface IProps {
	onClick?: () => void
	activeFilterCount?: number
}

const ButtonFilter = (props: IProps) => {
	const { activeFilterCount, onClick } = props
	const { t } = useTranslation()

	return (
		<Container onClick={onClick}>
			<Wrapper>
				<WrapperIcon>
					<Icon icon='filter' />
				</WrapperIcon>
				<FilterInlineLabel>{t('ButtonFilter::Filter')}</FilterInlineLabel>
				{activeFilterCount > 0 && <>
					<FilterInlineDivider></FilterInlineDivider>
					<FilterInlineValue>{t('ButtonFilter::{{count}}', { count: activeFilterCount })}</FilterInlineValue>
				</>}
			</Wrapper>
			<WrapperBackdrop />
		</Container>
	)
}

export default ButtonFilter