import styled from 'styled-components'

const ReportSummaryPercentIndicatorData = styled.div`
  line-height: 80px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 80px;
  font-size: 28px;
  font-weight: 600;

  span {
    font-size: 12px;
    line-height: 70px;
    vertical-align: top;
  }
`

export default ReportSummaryPercentIndicatorData