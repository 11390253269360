import styled from 'styled-components'
import { Style } from '../../styles'

const ReportTabNavigation = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
   overflow-x: auto;
   overflow-y: hidden;
   border-bottom: 1px solid ${Style.color.border};
   margin-bottom: ${Style.spacing.x3};
`

export default ReportTabNavigation