import * as React from 'react'
import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { showConfirmModal, showShareLinksModal } from '../../store/modals/actions';
import { AppState } from '../../store';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import { PlaybooksController } from '../../controllers';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import Notification from '../../utilities/Notification';
import { IActionListItem } from '../ActionList/ActionList';
import PageLoader from '../Page/PageLoader';
import ResourceTable from '../Resource/ResourceTable';
import ResourceTableRow from '../Resource/ResourceTableRow';
import ResourceTableRowData from '../Resource/ResourceTableRowData';
import moment from '../../utilities/Moment';
import ResourceTableRowActions from '../Resource/ResourceTableRowActions';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import ButtonPanel from '../Button/ButtonPanel';
import { CurrentUser, Playbook, PlaybookableType, ResourceListFilterType } from '../../types';
import { useDebouncedCallback } from 'use-debounce';

interface IState {
	playbooks: Playbook[],
	currentPage: number,
	totalPages: number
	didInitialLoad: boolean
	isFetching: boolean
	sortValue: string
	filters: any
	searchValue: string
}


interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showConfirmModal: typeof showConfirmModal
	showShareLinksModal: typeof showShareLinksModal
}

type IProps = {
	playbookableId?: string,
	playbookableType?: PlaybookableType,
	primaryActionEnabled?: boolean
	primaryActionMode?: 'create' | 'manage'
	onPlaybookClick?: (playbook: Playbook) => void
	maxHeight?: string
} & IStateToProps & IDispatchToProps & RouteComponentProps<any>

const PlaybooksTable = (props: IProps) => {
	const { showConfirmModal, primaryActionEnabled, primaryActionMode, playbookableId, playbookableType, onPlaybookClick, maxHeight } = props
	const { t } = useTranslation()
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
	const { workspace: { setting } } = currentUser

	const [state, setState] = React.useState<IState>({
		playbooks: [],
		currentPage: 0,
		totalPages: 0,
		didInitialLoad: false,
		isFetching: false,
		sortValue: LocalStorage.get(LocalStorageKey.PLAYBOOK_SORT_VALUE, 'created_at_desc'),
		filters: {},
		searchValue: ''
	})

	const {
		playbooks,
		currentPage,
		totalPages,
		didInitialLoad,
		filters,
		isFetching,
		sortValue,
		searchValue
	} = state

	const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

	React.useEffect(() => {
		debouncedFetchPlaybooks(1)
	}, [filters, sortValue, searchValue])

	const fetchPlaybooks = async (page: number) => {
		try {
			let params: any = {
				page: page,
				search: searchValue,
				order: `${sortValue}`,
				...filters
			}

			if (playbookableId) params.playbookable_id = playbookableId
			if (playbookableType) params.playbookable_type = playbookableType

			setState({ ...state, isFetching: true })
			const response = await PlaybooksController.getPlaybooks(params)

			const { playbooks: responsePlaybooks, current_page, total_pages, total_entries } = response;

			setState({
				...state,
				playbooks: [...responsePlaybooks],
				currentPage: current_page,
				totalPages: total_pages,
				didInitialLoad: true,
				isFetching: false
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	const debouncedFetchPlaybooks = useDebouncedCallback((page) => fetchPlaybooks(page), 250);

	const onMainActionClick = async () => {
		if (primaryActionMode === 'manage') {
			props.history.push(RouteHelper.process(ERoute.PATH_PLAYBOOKS))
		} else {
			props.history.push(RouteHelper.process(ERoute.PATH_PLAYBOOKS_CREATE))
		}
	}

	const onFiltersChange = (newFilters: any) => {
		setState({ ...state, filters: newFilters })
	}

	const onSortChange = (newSortValue: string) => {
		LocalStorage.set(LocalStorageKey.PLAYBOOK_SORT_VALUE, newSortValue)
		setState({ ...state, sortValue: newSortValue })
	}

	const onRowClick = (playbook: Playbook) => {
		if (onPlaybookClick) {
			onPlaybookClick(playbook)
		} else {
			props.history.push(RouteHelper.process(ERoute.PATH_PLAYBOOK, { id: playbook.id }))
		}
	}

	const onRowActionClick = (key: string, playbook: Playbook) => {
		switch (key) {
			case 'edit':
				props.history.push(RouteHelper.process(ERoute.PATH_PLAYBOOK, { id: playbook.id }))
				break
			case 'delete':
				showConfirmModal({
					title: t('Playbook::Delete playbook'),
					description: t('Playbook::You are about to delete playbook <b>{{name}}</b>. Are you sure?', { name: playbook.name }),
					action: { label: t('Playbook::Delete'), isDestructive: true },
					onConfirm: async () => {
						try {
							const response = await PlaybooksController.delete(playbook.id)

							if (response.errors) { }
							else {
								const playbookIndex = playbooks.findIndex(c => c.id === playbook.id);

								playbooks.splice(playbookIndex, 1);

								setState({ ...state, playbooks: [...playbooks] })

								Notification.notifySuccess(t('Playbook::Playbook successfully deleted'))
							}
						} catch (ex) {
							console.error(ex)
						}
					}
				})
		}
	}

	const onSearchChange = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onSearchSubmit = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onClearFilters = () => {
		setState({ ...state, searchValue: '', filters: {} })
	}


	return (
		<>
			{!didInitialLoad && <PageLoader />}
			{didInitialLoad && <ResourceTable
				data={playbooks}
				actionsLeft={primaryActionEnabled ? [
					<ButtonPanel
						icon={primaryActionMode === 'manage' ? 'pen' : 'plus'}
						text={primaryActionMode === 'manage' ? t('Playbooks::Manage playbooks') : t('Playbooks::New playbook')}
						onClick={onMainActionClick}
					/>
				] : null}
				headers={[
					{ title: t('Playbooks::Name'), colSpan: 2 },
					{ title: t('Playbooks::Creation date'), align: 'right' },
					{ title: '', stickyRight: '0px' },
				]}
				renderRow={(playbook: Playbook) => {
					let actions: IActionListItem[] = [
						{ key: 'edit', icon: 'edit-solid', content: t('Playbooks::Edit') },
						{ key: 'delete', icon: 'trash-alt-solid', content: t('Playbooks::Delete'), destructive: true },
					]

					return (
						<ResourceTableRow key={playbook.id}>
							<ResourceTableRowData onClick={(): void => onRowClick(playbook)} colSpan={2} maxWidth='150px' ellipse>
								<b>{playbook.name ? playbook.name : '-'}</b>
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(playbook)} textAlign='right'>
								{moment(playbook.created_at).format(setting.date_format)}
							</ResourceTableRowData>
							<ResourceTableRowActions
								actions={actions}
								onActionClick={(key) => onRowActionClick(key, playbook)}
								sticky={true}
								stickyRight='0px'
							/>
						</ResourceTableRow>
					)
				}}
				renderEmpty={<CardEmptyInfo
					icon={filtersActive ? 'search' : 'book'}
					description={filtersActive ? t('Playbooks::No playbooks found') : t('Playbooks::No playbooks have been created yet')}
					descriptionActionText={filtersActive ? t('Playbooks::Clear filters') : t('Playbooks::Add playbook')}
					onDescriptionActionClick={filtersActive ? onClearFilters : onMainActionClick}
				/>}
				filters={[
					{ name: 'name', label: t('Playbooks::Name'), type: ResourceListFilterType.STRING },
					{ name: 'created_at', label: t('Playbooks::Created date'), type: ResourceListFilterType.DATE },
				]}
				onFiltersChange={onFiltersChange}
				sortOptions={[
					{ label: '-', value: '-' },
					{ label: t('Playbooks::Name (A-Z)'), value: 'name_asc' },
					{ label: t('Playbooks::Name (Z-A)'), value: 'name_desc' },
					{ label: t('Playbooks::Created at ↑'), value: 'created_at_asc' },
					{ label: t('Playbooks::Created at ↓'), value: 'created_at_desc' },
				]}
				sortValue={sortValue}
				onSortChange={onSortChange}
				pagination={{ page: currentPage, pageCount: totalPages }}
				onPageChange={(page) => fetchPlaybooks(page)}
				isLoading={isFetching}
				stickyHeader={true}
				searchValue={searchValue}
				onSearchChange={onSearchChange}
				onSearchSubmit={onSearchSubmit}
				maxHeight={maxHeight ? maxHeight : '65vh'}
			/>}
		</>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showConfirmModal,
	showShareLinksModal
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaybooksTable))