import store from "../store";
import { PlanType, SubscriptionStatus } from "../types";

export default class CurrentUserHelper {
  static hasPlan(plan: PlanType) {
    const { authentication: { currentUser: { workspace } } } = store.getState();

    return workspace.subscription.plan.type === plan
  }

  static hasFreeSubscription() {
    const { authentication: { currentUser: { workspace } } } = store.getState();

    return this.hasPlan(PlanType.FREE)
  }

  static hasBusinessSubscription() {
    const { authentication: { currentUser: { workspace } } } = store.getState();

    return workspace.vip || this.hasPlan(PlanType.BUSINESS) || this.hasPlan(PlanType.BUSINESS_PRO)
  }

  static isSubscriptionActive() {
    const { authentication: { currentUser: { workspace } } } = store.getState();

    return workspace.vip || [SubscriptionStatus.TRIALING, SubscriptionStatus.ACTIVE].includes(workspace?.subscription?.status)
  }
}