import * as React from 'react'
import moment from '../../utilities/Moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import ExpenseHelper from '../../helpers/ExpenseHelper'
import ExpenseCategoryHelper from '../../helpers/ExpenseCategoryHelper'
import { Expense, Settings } from '../../types'

interface IProps {
  expense: Expense
  onClick: (expense: Expense) => void
  setting: Settings
}

export default class ExpenseTicket extends React.PureComponent<IProps> {
  render() {
    const {
      expense,
      setting,
      onClick,
    } = this.props

    const expenseColor = ExpenseHelper.getColor(expense)

    return (
      <div className='expense-ticket' onClick={() => onClick(expense)}>
        <div className='expense-ticket-content' style={{ borderTopColor: expenseColor }}>
          <div className='expense-ticket-category'>
            {expense.category && ExpenseCategoryHelper.getIcon(expense.category, expenseColor)}
            {expense.category && <span>
              {expense.category.name}
            </span>}
          </div>

          <div className='expense-ticket-supplier'>
            {expense.supplier ? expense.supplier.name : '-'}
          </div>

          <div className='expense-ticket-name'>
            {expense.name}
          </div>

          <div className='expense-ticket-date'>
            {moment(expense.invoiced_on).format(setting.date_format)}
          </div>

          <div className='expense-ticket-amount'>
            {NumberFormatter.formatCurrency(expense.currency, setting.number_format, expense.net_total)}
          </div>
        </div>
      </div>
    )
  }
}