import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import Button from '../components/Button/Button'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import FullPageContent from '../components/Page/FullPageContent'
import PageContent from '../components/Page/PageContent'
import PageHeader from '../components/Page/PageHeader'
import ProposalsTable from '../components/Proposals/ProposalsTable'
import { ProposalsController } from '../controllers'
import ERoute from '../ERoute'
import CurrentUserHelper from '../helpers/CurrentUserHelper'
import RouteHelper from '../helpers/RouteHelper'
import Images from '../images'

type IProps = & RouteComponentProps<any>

const Proposals = (props: IProps) => {
	const { t } = useTranslation()
	const { history } = props

	const onNewProposalClick = async () => {
		try {
			const proposal = await ProposalsController.create({ id: null })
			history.push(RouteHelper.process(ERoute.PATH_PROPOSAL, { id: proposal.id }))
		} catch (ex) {
			console.error(ex)
		}
	}

	return (
		<>
			<Helmet>
				<title>{t('Proposals::{{__appName}} | Proposals')}</title>
			</Helmet>
			<ScrollToTopOnMount />

			<TopNavigation
				icon='proposal'
				title={t('Contacts::Proposals')}
				action={
					<Button
						type='primary'
						icon='plus'
						text={t('Proposals::New Proposal')}
						onClick={onNewProposalClick}
						extraClasses='page-action'
					/>
				}
			/>

			<PageContent>
				<PageHeader title={t('Proposals::Proposals')} />
				<ProposalsTable />
			</PageContent>
		</>
	)
}

export default Proposals