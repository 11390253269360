import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import BoardList from './BoardList'
import ScrollToTopOnMount from '../Effects/ScrollToTopOnMount'
import PageLoader from '../Page/PageLoader'
import { AppState } from '../../store'
import { Style } from '../../styles'
import { showBoardModal, showConfirmModal, showShareLinksModal, showTaskModal } from '../../store/modals/actions'
import { BoardListsController, BoardsController, TasksController } from '../../controllers'
import { RouteComponentProps } from 'react-router'
import BoardHelper from '../../helpers/BoardHelper'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import AddBoardList from './AddBoardList'
import BoardSidebarFilter, { BoardFilterMatchLabelsAndMembers, IBoardFilter } from './BoardSidebarFilter'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { WithTranslation, withTranslation } from 'react-i18next'
import FileHelper from '../../helpers/FileHelper'
import ActionCableConsumer from '../../consumers/ActionCableConsumer'
import ERoute from '../../ERoute'
import Notification from '../../utilities/Notification'
import { createBoard, createBoardLabel, createBoardList, createBoardTask, deleteBoard, deleteBoardLabel, deleteBoardList, deleteBoardTask, setBoard, setInitialBoardState, updateBoard, updateBoardLabel, updateBoardList, updateBoardTask } from '../../store/board/actions'
import UrlHelper from '../../helpers/UrlHelper'
import ButtonFilter from '../Button/ButtonFilter'
import DroppableHelper from '../../helpers/DroppableHelper'
import { BizzeyFileType, Board, BoardLabel, BoardList as BoardListType, CurrentUser, Task, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../../types'
import { ResourceSearch, ResourceSearchCloseIcon, ResourceSearchIcon } from '../Resource/ResourceHeader'
import Icon from '../Icons/Icon'
import Popover from '../Popover/Popover'
import ActionList from '../ActionList/ActionList'
import ButtonGroup from '../Button/ButtonGroup'
import ReactTooltip from 'react-tooltip'
import TasksTable from '../Tasks/TasksTable'

const BoardHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Style.spacing.x1} ${Style.spacing.x1} ${Style.spacing.x1}  ${Style.spacing.x1};
  position: relative;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const BoardContent = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 60px);

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    height: calc(100% - 8px);
  }
`

const BoardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }


  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    left: 0;
  }
`

const TasksContainer = styled.div`
  margin: 0 8px;
`

interface IStateToProps {
  currentUser: CurrentUser
  board: Board | null
  labels: BoardLabel[]
  lists: BoardListType[]
  tasks: Task[]
}

interface IDispatchToProps {
  showBoardModal: typeof showBoardModal
  showTaskModal: typeof showTaskModal
  showConfirmModal: typeof showConfirmModal
  showShareLinksModal: typeof showShareLinksModal
  setInitialBoardState: typeof setInitialBoardState
  setBoard: typeof setBoard
  createBoard: typeof createBoard
  updateBoard: typeof updateBoard
  deleteBoard: typeof deleteBoard
  createBoardLabel: typeof createBoardLabel
  updateBoardLabel: typeof updateBoardLabel
  deleteBoardLabel: typeof deleteBoardLabel
  createBoardList: typeof createBoardList
  updateBoardList: typeof updateBoardList
  deleteBoardList: typeof deleteBoardList
  createBoardTask: typeof createBoardTask
  updateBoardTask: typeof updateBoardTask
  deleteBoardTask: typeof deleteBoardTask
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id: string }> & WithTranslation

interface IState {
  activeLayout: BoardLayout
  actionMenuActive: boolean
  labelsExpanded: boolean
  didInitialLoad: boolean
  sidebarFilterActive: boolean
  filter: IBoardFilter
}

export enum BoardLayout {
  BOARD = 'board',
  TABLE = 'table',
}

const enum BoardDroppableType {
  COLUMN = 'column',
}

class BoardPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      activeLayout: BoardLayout.BOARD,
      actionMenuActive: false,
      labelsExpanded: Boolean(LocalStorage.get(LocalStorageKey.BOARD_LABEL_EXPANSION)),
      didInitialLoad: false,
      sidebarFilterActive: false,
      filter: {
        searchValue: '',
        labelIds: [],
        assigneeIds: [],
        dueOrCompletionState: null,
        matchLabelsAndMembers: BoardFilterMatchLabelsAndMembers.ANY
      }
    }

    this.onBeforeDragStart = this.onBeforeDragStart.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onChangeListName = this.onChangeListName.bind(this)
    this.onDeleteList = this.onDeleteList.bind(this)
    this.onTaskClick = this.onTaskClick.bind(this)
    this.onTaskCompletedToggle = this.onTaskCompletedToggle.bind(this)
    this.onMoveTasksClick = this.onMoveTasksClick.bind(this)
    this.onDeleteTasksClick = this.onDeleteTasksClick.bind(this)
    this.onTaskToggleLabels = this.onTaskToggleLabels.bind(this)
    this.onTaskSubmit = this.onTaskSubmit.bind(this)
    this.onTaskBoardClick = this.onTaskBoardClick.bind(this)
    this.onAddTaskListClick = this.onAddTaskListClick.bind(this)
    this.onTaskDelete = this.onTaskDelete.bind(this)
    this.onAddBoardListSubmit = this.onAddBoardListSubmit.bind(this)
    this.onAddTaskSubmit = this.onAddTaskSubmit.bind(this)
    this.onAddTaskFilesDropped = this.onAddTaskFilesDropped.bind(this)
    this.onTaskFilesDropped = this.onTaskFilesDropped.bind(this)
    this.onToggleFilter = this.onToggleFilter.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onFilterCloseClick = this.onFilterCloseClick.bind(this)
    this.onLayoutChange = this.onLayoutChange.bind(this)
    this.onEmailTasksClick = this.onEmailTasksClick.bind(this)
    this.onEditBoardClick = this.onEditBoardClick.bind(this)
    this.onDeleteBoardClick = this.onDeleteBoardClick.bind(this)
    this.onToggleActionMenuClick = this.onToggleActionMenuClick.bind(this)
    this.closeActionMenu = this.closeActionMenu.bind(this)

    // Action cable
    this.onActionCableConnected = this.onActionCableConnected.bind(this)
    this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
    this.onActionCableReceived = this.onActionCableReceived.bind(this)
  }

  componentDidMount() {
    this.fetchBoard()
    this.parseQueryParams()
  }

  componentWillUnmount() {
    this.props.setInitialBoardState({ board: null, labels: [], lists: [], tasks: [] })
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    ReactTooltip.rebuild()

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchBoard().catch(console.error)
    }

    if (prevProps.location.search !== this.props.location.search) {
      this.parseQueryParams()
    }
  }

  parseQueryParams() {
    const queryParams = UrlHelper.getParams(this.props.location.search)
    const taskId = queryParams.taskId
    // Check task id
    if (taskId) {
      requestAnimationFrame(() => {
        this.props.showTaskModal({
          task: { id: taskId },
          onSubmit: this.onTaskSubmit,
          onDelete: this.onTaskDelete
        })
      })
    }
  }

  onEmailTasksClick() {
    const { showShareLinksModal, board, t } = this.props

    const boardEmail = BoardHelper.boardEmail(board.id)

    requestAnimationFrame(() => {
      showShareLinksModal({
        title: t('Board::Email tasks'),
        shareableLinks: [
          { content: boardEmail, url: boardEmail }
        ]
      })
    })
  }

  onEditBoardClick() {
    const { showBoardModal, board } = this.props

    requestAnimationFrame(() => {
      showBoardModal({
        board: { id: board?.id },
        onSubmit: (board) => {
          this.props.updateBoard(board)
        }
      })
    })
  }

  onDeleteBoardClick() {
    const { t, showConfirmModal } = this.props

    requestAnimationFrame(() => {
      showConfirmModal({
        title: t('Board::Delete board'),
        description: t('Board::You are about to delete this board. This board will be permanently deleted along with all the associated date. Are you sure?'),
        action: { label: t('Board::Delete'), isDestructive: true },
        onConfirm: async () => {
          try {
            await BoardsController.delete(this.props.board?.id)
            this.props.history.replace(ERoute.PATH_TASKS_OVERVIEW)
          } catch (ex) {
            console.error(ex)
          }
        }
      })
    })
  }

  onToggleActionMenuClick() {
    this.setState({ actionMenuActive: !this.state.actionMenuActive })
  }

  closeActionMenu() {
    this.setState({ actionMenuActive: false })
  }

  async fetchBoard() {
    const { match: { params: { id } } } = this.props

    try {
      this.setState({ didInitialLoad: false })

      const board = await BoardsController.get({ id: id })

      this.setState({
        filter: {
          searchValue: '',
          labelIds: [],
          assigneeIds: [],
          dueOrCompletionState: null,
          matchLabelsAndMembers: BoardFilterMatchLabelsAndMembers.ANY
        }
      })

      this.props.setInitialBoardState({
        board: board,
        lists: board.lists,
        labels: board.labels,
        tasks: board.tasks,
      })
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ didInitialLoad: true })
    }
  }

  getActiveFilterCount() {
    const { filter } = this.state

    let activeFilterCount = 0

    if (filter.searchValue !== '') activeFilterCount += 1
    if (filter.labelIds.length > 0) activeFilterCount += 1
    if (filter.assigneeIds.length > 0) activeFilterCount += 1
    if (filter.dueOrCompletionState !== null) activeFilterCount += 1

    return activeFilterCount
  }


  onBeforeDragStart() { }

  onDragStart() { }

  onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) return;

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Reordering a list
    if (result.type === BoardDroppableType.COLUMN) {
      this.reorderListPosition(result);
      return;
    }

    // Reordering a card
    this.reorderCardPosition(result);
  }


  reorderListPosition(result: DropResult) {
    const { source } = result
    const { board, lists } = this.props

    const newListPosition = DroppableHelper.getNewPositionBasedOnDropResult(lists, result)

    if (lists[source.index]) {
      lists[source.index].position = newListPosition

      // Get updated boardlist
      const updatedList: BoardListType = lists[source.index]

      // Updated remote boardlist async
      BoardListsController.update(board.id, updatedList).catch(console.error)

      // Set local lists
      this.props.updateBoardList(updatedList)
    }
  }

  reorderCardPosition(result: DropResult) {
    const { source, destination } = result
    const { lists, tasks } = this.props
    const { filter } = this.state

    // moving card within same list
    if (source.droppableId === destination.droppableId) {
      const selectedList = lists.find(list => list.id === destination.droppableId)

      if (selectedList) {
        // Get tasks from the selected list
        const listTasks = BoardHelper.getTasksFromList(selectedList, tasks)

        // Get moved taskId
        const movedTask = listTasks[source.index]
        const movedTaskId = movedTask.id

        // Calculate the new task position based on the tasks inside the list
        const newTaskPosition = DroppableHelper.getNewPositionBasedOnDropResult(listTasks, result)

        const taskIndex = tasks.findIndex(task => task.id === movedTaskId)

        if (taskIndex !== -1) {
          tasks[taskIndex].position = newTaskPosition

          // Get updated boardlist
          const updatedTask: Task = tasks[taskIndex]

          // TODO: let actioncable handle
          // Updated remote boardlist async
          TasksController.update(updatedTask).catch(console.error)

          // Set local tasks
          this.props.updateBoardTask(updatedTask)
        }
      }
    } else { // moving card between different lists
      const currentList = lists.find(list => list.id === source.droppableId)
      const movedList = lists.find(list => list.id === destination.droppableId)

      if (currentList && movedList) {
        // Get tasks from current list
        const currentListTasks = BoardHelper.getTasksFromList(currentList, tasks)

        // Get task that will be moved
        const movedTask = currentListTasks[source.index]
        const movedTaskId = movedTask.id

        // Get tasks from the selected list
        const movedListTasks = BoardHelper.getTasksFromList(movedList, tasks)

        // Calculate the new task position based on the tasks inside the list
        const newTaskPosition = DroppableHelper.getNewPositionBetweenListsBasedOnDropResult(movedListTasks, result)

        // Find the index of the task to be moved
        const taskIndex = tasks.findIndex(task => task.id === movedTaskId)

        if (taskIndex !== -1) {
          // Update task position at given index
          tasks[taskIndex] = {
            ...tasks[taskIndex],
            position: newTaskPosition,
            list_id: movedList.id,
          }

          // Get updated boardlist
          const updatedTask: Task = tasks[taskIndex]

          // TODO: let actioncable handle
          // Updated remote boardlist async
          TasksController.update(updatedTask).catch(console.error)

          // Set local tasks
          this.props.updateBoardTask(updatedTask)
        }
      }
    }
  }

  onToggleFilter() {
    this.setState({ sidebarFilterActive: !this.state.sidebarFilterActive })
  }

  onLayoutChange(activeLayout: BoardLayout) {
    this.setState({ activeLayout: activeLayout })
  }

  onSearchChange(searchValue: string) {
    const { filter } = this.state

    this.setState({
      filter: {
        ...filter,
        searchValue: searchValue,
      }
    })
  }

  onFilterChange(filter: IBoardFilter) {
    this.setState({ filter: filter })
  }

  onFilterCloseClick() {
    this.setState({ sidebarFilterActive: false })
  }

  onActionCableConnected() {
    console.log('[WorkspaceChannel] connected')
  }

  onActionCableDisconnected() {
    console.log('[WorkspaceChannel] Disconnected')
  }

  onActionCableReceived(event: WorkspaceChannelEvent) {
    const { t, board } = this.props
    console.log('[WorkspaceChannel] received event', event)

    switch (event.type) {
      case WorkspaceChannelEventType.BOARD_CREATE:
        // Not really fired since you can't subscribe on a board that hasn't been created yet
        break
      case WorkspaceChannelEventType.BOARD_UPDATE:
        if (board?.id === event.data.board.id) {
          this.props.updateBoard(event.data.board)
        }
        break
      case WorkspaceChannelEventType.BOARD_DELETE:
        if (board?.id === event.data.board_id) {
          Notification.notifyWarning(t('Board::Board has been deleted'))
          this.props.history.replace(ERoute.PATH_TASKS_OVERVIEW)
        }
        break
      case WorkspaceChannelEventType.BOARD_LABEL_CREATE:
        if (board?.id === event.data.board_label.board_id) {
          this.props.createBoardLabel(event.data.board_label)
        }
        break
      case WorkspaceChannelEventType.BOARD_LABEL_UPDATE:
        if (board?.id === event.data.board_label.board_id) {
          this.props.updateBoardLabel(event.data.board_label)
        }
        break
      case WorkspaceChannelEventType.BOARD_LABEL_DELETE:
        if (board?.id === event.data.board_id) {
          this.props.deleteBoardLabel(event.data.board_label_id)
        }
        break
      case WorkspaceChannelEventType.BOARD_LIST_CREATE:
        if (board?.id === event?.data?.board_list?.board_id) {
          this.props.createBoardList(event.data.board_list)
        }
        break
      case WorkspaceChannelEventType.BOARD_LIST_UPDATE:
        if (board?.id === event?.data?.board_list?.board_id) {
          this.props.updateBoardList(event.data.board_list)
        }
        break
      case WorkspaceChannelEventType.BOARD_LIST_DELETE:
        if (board?.id === event?.data?.board_id) {
          this.props.deleteBoardList(event.data.board_list_id)
        }
        break
      case WorkspaceChannelEventType.TASK_CREATE:
        if (board?.id === event?.data?.task?.board_id) {
          this.props.createBoardTask(event.data.task)
        } else {
          this.props.deleteBoardTask(event.data.task.id)
        }
        break
      case WorkspaceChannelEventType.TASK_UPDATE:
        if (board?.id === event?.data?.task?.board_id) {
          this.props.updateBoardTask(event.data.task)
        } else {
          this.props.deleteBoardTask(event.data.task.id)
        }
        break
      case WorkspaceChannelEventType.TASK_DELETE:
        if (board?.id === event?.data?.board_id) {
          this.props.deleteBoardTask(event.data.task_id)
        }
        break
    }
  }

  async onChangeListName(listId: string, name: string) {
    const { board, lists } = this.props

    try {
      const listIndex = lists.findIndex(l => l.id === listId)

      if (listIndex !== -1) {
        // Set new name
        lists[listIndex].name = name

        // Set local cache
        this.props.updateBoardList(lists[listIndex])

        // Update remote
        await BoardListsController.update(board.id, lists[listIndex])
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onAddTaskListClick(list: BoardListType) {
    requestAnimationFrame(() => {
      this.props.showTaskModal({
        task: { board_id: list.board_id, list_id: list.id },
        onSubmit: this.onTaskSubmit,
        onDelete: this.onTaskDelete
      })
    })
  }

  async onMoveTasksClick(sourceList: BoardListType, targetList: BoardListType) {
    const { tasks } = this.props

    try {
      const taskIds = tasks
        .filter(task => task.list_id === sourceList.id)
        .map(task => task.id)

      const response = await TasksController.bulkMove(taskIds, targetList.id)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDeleteTasksClick(list: BoardListType) {
    const { tasks, t } = this.props

    this.props.showConfirmModal({
      title: t('Board::Delete all tasks from this list'),
      description: t('Board::You are about to delete all tasks from this list. These tasks will be permanently deleted along with all the associated data. Are you sure?'),
      action: { label: t('Board::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const taskIds = tasks
            .filter(task => task.list_id === list.id)
            .map(task => task.id)

          await TasksController.bulkDelete(taskIds)
        } catch (ex) {
          console.error(ex)
        }
      }
    })


  }

  async onDeleteList(listId: string) {
    const { showConfirmModal, t } = this.props
    const { board, lists } = this.props

    requestAnimationFrame(() => {
      showConfirmModal({
        title: t('Board::Delete list'),
        description: t('Board::You are about to delete this list. This list will be permanently deleted along with all it\'s associated data. Are you sure?'),
        action: { label: t('Board::Delete'), isDestructive: true },
        onConfirm: async () => {
          try {
            const listIndex = lists.findIndex(l => l.id === listId)

            if (listIndex !== -1) {
              // Remove list from lists
              lists.splice(listIndex, 1);

              // Set local cache
              this.props.deleteBoardList(listId)

              // Update remote
              await BoardListsController.delete(board.id, listId)
            }
          } catch (ex) {
            console.error(ex)
          }
        }
      })
    })
  }

  onTaskClick(task: Task) {
    requestAnimationFrame(() => {
      this.props.showTaskModal({
        task: { id: task.id },
        onSubmit: this.onTaskSubmit,
        onDelete: this.onTaskDelete
      })
    })
  }

  async onTaskCompletedToggle(task: Task) {
    const { completed } = task

    try {
      const updatedTask: Task = { ...task, completed: !completed }
      this.props.updateBoardTask(updatedTask)
      await TasksController.update(updatedTask)
    } catch (ex) {
      console.error(ex)
    }
  }

  onTaskToggleLabels() {
    const { labelsExpanded } = this.state

    LocalStorage.set(LocalStorageKey.BOARD_LABEL_EXPANSION, Boolean(!labelsExpanded))

    this.setState({ labelsExpanded: !labelsExpanded })
  }

  onTaskSubmit(task: Task) {
    const { tasks } = this.props

    const taskIndex = tasks.findIndex(t => t.id === task.id)

    if (taskIndex !== -1) {
      this.props.updateBoardTask(task)
    } else {
      this.props.createBoardTask(task)
    }
  }

  onTaskBoardClick() { }

  onTaskDelete(task: Task) {
    this.props.deleteBoardTask(task.id)
  }

  /**
   * TODO: determine if we should add it localy based
   * on the response or just wait for the actioncable event
   */
  async onAddTaskSubmit(listId: string, name: string) {
    const { board, lists, tasks } = this.props
    const { filter } = this.state

    try {
      // Selected list
      const selectedList = lists.find(list => list.id === listId)

      if (selectedList) {
        // Get a list's tasks
        const listTasks = BoardHelper.getTasksFromList(selectedList, tasks)

        // Calculate new task position
        const newTaskPosition = BoardHelper.getCreatePosition(listTasks)

        // Create new task
        const newTask: Task = {
          name: name,
          board_id: board.id,
          contact_id: board.contact_id,
          project_id: board.project_id,
          list_id: listId,
          position: newTaskPosition,
        }

        // Add task to remote
        const task = await TasksController.create(newTask)

        this.props.createBoardTask(task)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onAddTaskFilesDropped(listId: string, files: File[]) {
    const { board, lists, tasks } = this.props

    try {
      // Selected list
      const selectedList = lists.find(list => list.id === listId)

      if (selectedList) {
        const [firstFile, ..._remainingFiles] = files
        // Get a list's tasks
        const listTasks = BoardHelper.getTasksFromList(selectedList, tasks)

        // Calculate new task position
        const newTaskPosition = BoardHelper.getCreatePosition(listTasks)

        // Create new task
        const newTask: Task = {
          name: firstFile.name,
          board_id: board.id,
          contact_id: board.contact_id,
          project_id: board.project_id,
          list_id: listId,
          position: newTaskPosition,
        }

        // Add task to remote
        const task = await TasksController.create(newTask)

        // Update local cache
        this.props.createBoardTask(task)

        // Add files to newly created task
        this.addTaskAttachments(task.id, files)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onTaskFilesDropped(taskId: string, files: File[]) {
    try {
      await this.addTaskAttachments(taskId, files)
    } catch (ex) {
      console.error(ex)
    }
  }

  async addTaskAttachments(taskId: string, files: File[]) {
    try {
      const { tasks } = this.props

      const taskIndex = tasks.findIndex(t => t.id === taskId)

      let selectedTask: Task

      if (taskIndex !== -1) selectedTask = tasks[taskIndex]

      let coverCallExecuted = false
      for (const file of files) {
        const attachment = await TasksController.createAttachment(taskId, { name: file.name, attachment: file, type: BizzeyFileType.FILE })

        // If no attachments present and uploaded attachment is displayable set it as cover
        if (!coverCallExecuted && selectedTask.attachments.length === 0 && FileHelper.displayable(attachment)) {
          // Do remote call to set cover in this scenario
          TasksController.update({ id: taskId, cover_id: attachment.id })

          // Ignore second run through
          coverCallExecuted = true
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  /**
   * TODO: determine if we should add it localy based
   * on the response or just wait for the actioncable event
   */
  async onAddBoardListSubmit(name: string) {
    const { board, lists } = this.props

    try {
      // Calculate new list position
      const listPosition = BoardHelper.getCreatePosition(lists)

      // Create new board list
      const newList: BoardListType = { name: name, board_id: board.id, position: listPosition }

      // Add list to remote
      const remoteList = await BoardListsController.create(board.id, newList)

      // TODO: let actioncable handle the adding of a list
      // Update list
      this.props.createBoardList(remoteList)
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const { lists, labels, tasks, t, currentUser } = this.props
    const { workspace: { setting } } = currentUser
    const { didInitialLoad, labelsExpanded, sidebarFilterActive, filter, actionMenuActive, activeLayout } = this.state

    return (
      <>
        <ScrollToTopOnMount />

        {!didInitialLoad && <PageLoader />}
        {didInitialLoad &&
          <ActionCableConsumer
            channel={{ channel: 'WorkspaceChannel', id: currentUser.workspace.id }}
            onConnected={this.onActionCableConnected}
            onDisconnected={this.onActionCableDisconnected}
            onReceived={this.onActionCableReceived}
          >
            <BoardHeader>
              <div>
                <ResourceSearch>
                  <ResourceSearchIcon>
                    <Icon icon='search' />
                  </ResourceSearchIcon>
                  <input
                    type='text'
                    style={{ maxWidth: 220, marginRight: 4 }}
                    placeholder={t('ResourceHeader::Search...')}
                    value={filter?.searchValue || ''}
                    onChange={(e) => this.onSearchChange(e.currentTarget.value)}
                  />
                  {filter?.searchValue?.length > 0 && <ResourceSearchCloseIcon onClick={() => this.onSearchChange('')}>
                    <Icon icon='close' />
                  </ResourceSearchCloseIcon>}
                </ResourceSearch>

                <div style={{ marginRight: 4 }}>
                  <ButtonFilter
                    activeFilterCount={this.getActiveFilterCount()}
                    onClick={this.onToggleFilter}
                  />
                </div>

                <ButtonGroup
                  items={[
                    { element: <span data-tip={t('Board::Board layout')}><Icon icon='column-layout' /></span>, onClick: () => this.onLayoutChange(BoardLayout.BOARD), active: activeLayout === BoardLayout.BOARD },
                    { element: <span data-tip={t('Board::Table layout')}><Icon icon='table-layout' /></span>, onClick: () => this.onLayoutChange(BoardLayout.TABLE), active: activeLayout === BoardLayout.TABLE },
                  ]}
                />
              </div>

              <div>
                <Popover
                  activator={
                    <a href='javascript://' className='page-action-menu-button' onClick={this.onToggleActionMenuClick}>
                      <Icon icon='solaris-dots' />
                    </a>
                  }
                  active={actionMenuActive}
                  placement='bottom-end'
                  onClose={this.closeActionMenu}
                >
                  <ActionList
                    actions={[
                      { key: 'email', icon: 'email', content: t('Board::Email tasks'), onClick: this.onEmailTasksClick },
                      { key: 'edit', icon: 'edit', content: t('Board::Edit board'), onClick: this.onEditBoardClick },
                      { key: 'delete', icon: 'trash', content: t('Board::Delete board'), destructive: true, onClick: this.onDeleteBoardClick },
                    ]}
                    hasIndicator
                    onClick={this.closeActionMenu}
                  />
                </Popover>
              </div>
            </BoardHeader>
            <BoardContent>
              {activeLayout === BoardLayout.BOARD && <DragDropContext
                onBeforeDragStart={this.onBeforeDragStart}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
              >
                <Droppable droppableId='board' type={BoardDroppableType.COLUMN} direction='horizontal'>
                  {provided => {
                    return (
                      <BoardContainer
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {lists.map((list, index) => {
                          const listTasks = BoardHelper.getTasksFromList(list, tasks, filter)

                          return (
                            <BoardList
                              key={list.id}
                              index={index}
                              list={list}
                              labels={labels}
                              labelsExpanded={labelsExpanded}
                              tasks={listTasks}
                              onChangeListName={this.onChangeListName}
                              onAddTaskClick={this.onAddTaskListClick}
                              onMoveTasksClick={this.onMoveTasksClick}
                              onDeleteTasksClick={this.onDeleteTasksClick}
                              onDeleteList={this.onDeleteList}
                              onTaskClick={this.onTaskClick}
                              onTaskCompletedToggle={this.onTaskCompletedToggle}
                              onAddTaskSubmit={this.onAddTaskSubmit}
                              onAddTaskFilesDropped={this.onAddTaskFilesDropped}
                              onTaskFilesDropped={this.onTaskFilesDropped}
                              onToggleLabels={this.onTaskToggleLabels}
                            />
                          )
                        })}
                        {provided.placeholder}

                        <AddBoardList
                          onSubmit={this.onAddBoardListSubmit}
                        />
                      </BoardContainer>
                    )
                  }}
                </Droppable>
              </DragDropContext>}

              {activeLayout === BoardLayout.TABLE && <TasksContainer>
                <TasksTable
                  tasks={BoardHelper.getTasksFromFilter(tasks, filter)}
                  dateFormat={setting.date_format}
                  onTaskClick={this.onTaskClick}
                  onTaskChange={this.onTaskSubmit}
                  onTaskBoardClick={this.onTaskBoardClick}
                  maxHeight='calc(100vh - 168px)'
                />
              </TasksContainer>}
            </BoardContent>

            <BoardSidebarFilter
              active={sidebarFilterActive}
              labels={labels}
              filter={filter}
              onFilterChange={this.onFilterChange}
              onCloseClick={this.onFilterCloseClick}
            />
          </ActionCableConsumer>
        }
      </>
    )

  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    board: {
      board,
      labels,
      lists,
      tasks,
    }
  } = state

  return {
    currentUser: currentUser,
    board: board,
    labels: labels,
    lists: lists,
    tasks: tasks,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  showBoardModal,
  showTaskModal,
  showConfirmModal,
  showShareLinksModal,
  setInitialBoardState,
  setBoard,
  createBoard,
  updateBoard,
  deleteBoard,
  createBoardLabel,
  updateBoardLabel,
  deleteBoardLabel,
  createBoardList,
  updateBoardList,
  deleteBoardList,
  createBoardTask,
  updateBoardTask,
  deleteBoardTask,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BoardPage))