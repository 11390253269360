import styled from 'styled-components'

const ConversationItemDescription = styled.div`
	font-size: 14px;
	color: rgb(116, 121, 142);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-right: 6px;
`

export default ConversationItemDescription