import * as React from 'react'
import NumberFormatter from '../../utilities/NumberFormatter'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'
import { Style } from '../../styles'
import { useTranslation } from 'react-i18next'
import { ProjectBudgetType, ProjectSummary as ProjectSummaryType } from '../../types'
import styled from 'styled-components'

const SummaryItemCaptionsList = styled.div`
  display: flex;
  flex-direction: column;
`

const SummaryItemCaptionLabelWithValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
`

type IProps = {
  summary?: ProjectSummaryType
  numberFormat: string
  timeFormat: string
  currency: string
}

const ProjectSummary = (props: IProps) => {
  const { t } = useTranslation()
  const { summary, currency, numberFormat } = props

  let budgetRemainingLabel = ''
  let usedBudgetLabel = ''
  let totalBudgetLabel = ''

  if (summary) {
    switch (summary?.budget?.type) {
      case ProjectBudgetType.NONE:
        budgetRemainingLabel = t('ProjectSummary::N/A')
        break
      case ProjectBudgetType.TOTAL_COSTS:
        budgetRemainingLabel = NumberFormatter.formatCurrency(currency, numberFormat, summary?.budget?.remaining || 0)
        totalBudgetLabel = NumberFormatter.formatCurrency(currency, numberFormat, summary?.budget?.total || 0)
        usedBudgetLabel = NumberFormatter.formatCurrency(currency, numberFormat, summary?.budget?.used || 0)
        break
      case ProjectBudgetType.TOTAL_HOURS:
        budgetRemainingLabel = t('ProjectSummary::{{hours}} hours', { hours: NumberFormatter.formatNumber(numberFormat, summary?.budget?.remaining || 0) })
        totalBudgetLabel = NumberFormatter.formatNumber(numberFormat, summary?.budget?.total || 0)
        usedBudgetLabel = NumberFormatter.formatNumber(numberFormat, summary?.budget?.used || 0)
        break
    }
  }


  return (
    <div style={{ marginBottom: Style.spacing.x2 }}>
      <SummaryContainer>
        <SummaryItem
          title={t('ProjectSummary::Total hours')}
          label={NumberFormatter.formatNumber(numberFormat, summary?.hours?.total || 0)}
          captionLabel={<SummaryItemCaptionsList>
            <SummaryItemCaptionLabelWithValue>
              <span>{t('ProjectSummary::Billable')}</span>
              <span>{NumberFormatter.formatNumber(numberFormat, summary?.hours?.billable || 0)}</span>
            </SummaryItemCaptionLabelWithValue>
            <SummaryItemCaptionLabelWithValue>
              <span>{t('ProjectSummary::Non-billable')}</span>
              <span>{NumberFormatter.formatNumber(numberFormat, summary?.hours?.non_billable || 0)}</span>
            </SummaryItemCaptionLabelWithValue>
          </SummaryItemCaptionsList>}
          isLoading={!Boolean(summary)}
        />
        <SummaryItem
          title={t('ProjectSummary::Budget remaining')}
          label={<span style={summary?.budget?.type !== ProjectBudgetType.NONE ? { color: summary?.budget?.remaining < 0 ? Style.color.brandDanger : Style.color.brandSuccess } : {}}>{budgetRemainingLabel}</span>}
          captionLabel={<SummaryItemCaptionsList>
            {totalBudgetLabel?.length > 0 && <SummaryItemCaptionLabelWithValue>
              <span>{t('ProjectSummary::Total budget')}</span>
              <span>{totalBudgetLabel}</span>
            </SummaryItemCaptionLabelWithValue>}
            {usedBudgetLabel?.length > 0 && <SummaryItemCaptionLabelWithValue>
              <span>{t('ProjectSummary::Used budget')}</span>
              <span>{usedBudgetLabel}</span>
            </SummaryItemCaptionLabelWithValue>}
          </SummaryItemCaptionsList>}
          isLoading={!Boolean(summary)}
        />
        <SummaryItem
          title={t('ProjectSummary::Internal costs')}
          label={NumberFormatter.formatCurrency(currency, numberFormat, summary?.total_costs?.total || 0)}
          captionLabel={
            <SummaryItemCaptionsList>
              <SummaryItemCaptionLabelWithValue>
                <span>{t('ProjectSummary::Labour')}</span>
                <span>{NumberFormatter.formatCurrency(currency, numberFormat, summary?.total_costs?.labour || 0)}</span>
              </SummaryItemCaptionLabelWithValue>
              <SummaryItemCaptionLabelWithValue>
                <span>{t('ProjectSummary::Expenses')}</span>
                <span>{NumberFormatter.formatCurrency(currency, numberFormat, summary?.total_costs?.expenses || 0)}</span>
              </SummaryItemCaptionLabelWithValue>
            </SummaryItemCaptionsList>
          }
          isLoading={!Boolean(summary)}
        />
        <SummaryItem
          title={t('ProjectSummary::Uninvoiced amount')}
          label={NumberFormatter.formatCurrency(currency, numberFormat, summary?.uninvoiced_amount?.total || 0)}
          captionLabel={
            <SummaryItemCaptionsList>
              <SummaryItemCaptionLabelWithValue>
                <span>{t('ProjectSummary::Budget')}</span>
                <span>{NumberFormatter.formatCurrency(currency, numberFormat, summary?.uninvoiced_amount?.project || 0)}</span>
              </SummaryItemCaptionLabelWithValue>
              <SummaryItemCaptionLabelWithValue>
                <span>{t('ProjectSummary::Labour / Expenses')}</span>
                <span>{NumberFormatter.formatCurrency(currency, numberFormat, summary?.uninvoiced_amount?.labour_and_expenses || 0)}</span>
              </SummaryItemCaptionLabelWithValue>
            </SummaryItemCaptionsList>
          }
          isLoading={!Boolean(summary)}
        />
      </SummaryContainer>
    </div>
  )
}

export default ProjectSummary