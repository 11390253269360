import * as React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import styled from 'styled-components';
import BoardListActionsPopover from '../BoardList/BoardListActionsPopover';
import Icon from '../Icons/Icon';
import { usePopper } from 'react-popper';
import MoveTasksPopover from './MoveTasksPopover';
import { BoardList } from '../../types';

const Container = styled.div`
	flex: 0 0 auto;
	padding: 10px 8px;
	position: relative;
	min-height: 20px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`

const InputContainer = styled.form`
	position: relative;
	width: 100%;
	font-weight: 600;

	input {
		min-height: 28px;
		max-height: 28px;

		&:focus {
			background: white;
			border-width: 2px;
		}

		&:disabled {
			background: transparent !important;
			border-color: transparent !important;
			border-radius: 3px;
			box-shadow: none;
			resize: none;
			resize: none;
			overflow: hidden;
			overflow-wrap: break-word;
			outline: none;
			color: black !important;
			line-height: normal;

			&:focus {
				box-shadow: none !important;
			}
		}
	}
`

const InputWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

const Options = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 3px;
	padding: 6px;
	color: #6b778c;
	cursor: pointer;
	margin-top: -5px;
`

const ActivatorContainer = styled.div``
const PopoverContainer = styled.div``

interface IProps {
	list: BoardList
	name: string
	onNameChange: (name: string) => void
	onAddTaskClick: () => void
	onMoveTasksClick: (list: BoardList) => void
	onDeleteTasksClick: () => void
	onDeleteClick: () => void
	dragHandleProps?: DraggableProvidedDragHandleProps;
	setDragBlocking: React.Dispatch<React.SetStateAction<boolean>>
}

const BoardListTitle = (props: IProps) => {
	const { list, name, dragHandleProps, setDragBlocking } = props
	const input = React.useRef<HTMLInputElement>(null)
	const [editModeActive, setEditModeActive] = React.useState(false);
	const [nameValue, setNameValue] = React.useState(name);
	const [moveTasksPopoverActive, setMoveTasksPopoverActive] = React.useState<boolean>(false);
	const [referenceElement, setReferenceElement] = React.useState(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement>(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-end',
		strategy: 'fixed',
	});

	React.useEffect(() => {
		// If user is not editing set the value of the new name
		if (!editModeActive) {
			setNameValue(props.name)
		}
	}, [name])

	const onNameClick = () => {
		if (!editModeActive) {
			// Disable dragging
			setDragBlocking(true);
			// Set form value to prop value
			setNameValue(props.name)
			// Enable edit mode
			setEditModeActive(true);

			setTimeout(() => {
				if (input && input.current) {
					input.current.select()
				}
			}, 50)
		}
	};

	const onNameChange = (e) => {
		setNameValue(e.currentTarget.value)
	}

	const onNameBlur = () => {

		setDragBlocking(false)
		setEditModeActive(false)

		// Trigger update only if values are different
		if (nameValue !== '' && name !== nameValue) {
			props.onNameChange(nameValue)
		}
	}

	const onNameSubmit = (e) => {
		e.preventDefault()

		setDragBlocking(false)
		setEditModeActive(false)

		// Trigger update only if values are different
		if (name !== nameValue) {
			props.onNameChange(nameValue)
		}
	}

	const onAddTaskClick = () => {
		props.onAddTaskClick()
	}

	const onMoveTasksClick = () => {
		setMoveTasksPopoverActive(true)
	}

	const onDeleteTasksClick = () => {
		props.onDeleteTasksClick()
	}

	const onDeleteClick = () => {
		props.onDeleteClick()
	};

	const onCloseMoveTaskPopover = () => {
		setMoveTasksPopoverActive(false)
	}

	const onMoveTasks = (list: BoardList) => {
		// Trigger move tasks
		props.onMoveTasksClick(list)

		// Close popover
		setMoveTasksPopoverActive(false)
	}

	return (
		<Container {...dragHandleProps}>
			<InputContainer onSubmit={onNameSubmit}>
				{!editModeActive && <InputWrapper onClick={onNameClick} />}
				<input
					ref={input}
					type='text'
					value={nameValue}
					disabled={!editModeActive}
					onChange={onNameChange}
					onBlur={onNameBlur}
				/>
				<input type='submit' style={{ display: 'none' }} />
			</InputContainer>
			<ActivatorContainer ref={setReferenceElement}>
				<BoardListActionsPopover
					activator={
						<>
							<ActivatorContainer>
								<Options>
									<Icon icon='solaris-dots' />
								</Options>
							</ActivatorContainer>
						</>
					}
					onAddTaskClick={onAddTaskClick}
					onMoveTasksClick={onMoveTasksClick}
					onDeleteTasksClick={onDeleteTasksClick}
					onDeleteListClick={onDeleteClick}
				/>
			</ActivatorContainer>

			<PopoverContainer ref={setPopperElement} style={{ ...styles.popper, zIndex: 7, cursor: 'initial' }} {...attributes.popper}>
				{moveTasksPopoverActive && <MoveTasksPopover
					list={list}
					onMoveTasks={onMoveTasks}
					onClose={onCloseMoveTaskPopover}
				/>}
			</PopoverContainer>
		</Container>
	)
}

export default BoardListTitle