import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TaskPopoverContainer from '../TaskModal/TaskPopoverContainer'
import TaskPopoverHeader from '../TaskModal/TaskPopoverHeader'
import TaskPopoverTitle from '../TaskModal/TaskPopoverTitle'
import TaskPopoverContent from '../TaskModal/TaskPopoverContent'
import TaskButton from '../TaskModal/TaskButton'
import TaskPopoverClose from '../TaskModal/TaskPopoverClose'
import { DealStage } from '../../types'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'

type IProps = {
	dealStage?: DealStage
	onSubmit: (dealStage: DealStage) => void
	onClose: () => void
}

const MoveDealsPopover = (props: IProps) => {
	const { dealStage, onClose } = props
	const { t } = useTranslation()
	const dealStages = useSelector((state: AppState) => state.dealsBoard.dealStages || [])

	const onDealStageClick = (dealStage: DealStage) => {
		props.onSubmit(dealStage)
	}

	return (
		<TaskPopoverContainer>
			<TaskPopoverHeader>
				<div />
				<TaskPopoverTitle>{t('DealModal::Move all deals to stage')}</TaskPopoverTitle>
				<TaskPopoverClose onClick={onClose} />
			</TaskPopoverHeader>
			<TaskPopoverContent>
				{dealStages
					.filter(l => l.id !== dealStage?.id)
					.map((dealStage, index) => (
						<TaskButton key={index} onClick={() => onDealStageClick(dealStage)}>
							{dealStage.name}
						</TaskButton>)
					)}
			</TaskPopoverContent>
		</TaskPopoverContainer>
	)
}

export default MoveDealsPopover