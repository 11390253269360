import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TaskPopoverContainer from '../TaskModal/TaskPopoverContainer'
import TaskPopoverHeader from '../TaskModal/TaskPopoverHeader'
import TaskPopoverTitle from '../TaskModal/TaskPopoverTitle'
import TaskPopoverContent from '../TaskModal/TaskPopoverContent'
import TaskPopoverClose from '../TaskModal/TaskPopoverClose'
import TaskPopoverFormActions from '../TaskModal/TaskPopoverFormActions'
import Button from '../Button/Button'
import TaskButton from '../TaskModal/TaskButton'

type IProps = {
	onSubmit: (lostReason: string, notes?: string) => void
	onClose: () => void
}

interface IState {
	lostReason: string
	notes: string
}

const LostDealsPopover = (props: IProps) => {
	const { t } = useTranslation()
	const [state, setState] = React.useState<IState>({
		lostReason: '',
		notes: ''
	})
	const { lostReason, notes } = state

	const onLostReasonChange = (e) => {
		setState({
			...state,
			lostReason: e.currentTarget.value
		})
	}

	const onNotesChanges = (e) => {
		setState({
			...state,
			notes: e.currentTarget.value
		})
	}

	const onClose = () => {
		props.onClose()
		setState({ lostReason: '', notes: '' })
	}

	const onFormSubmit = (e) => {
		e.preventDefault()

		props.onSubmit(lostReason, notes)
		setState({ lostReason: '', notes: '' })
	}

	return (
		<TaskPopoverContainer>
			<TaskPopoverHeader>
				<div />
				<TaskPopoverTitle>{t('LostDealsPopover::Mark as lost')}</TaskPopoverTitle>
				<TaskPopoverClose onClick={onClose} />
			</TaskPopoverHeader>
			<TaskPopoverContent>
				<form onSubmit={onFormSubmit}>
					<div className='form-item'>
						<label>{t('LostDealsPopover::Lost reason')} <span>*</span></label>
						<input
							type='text'
							value={lostReason}
							onChange={onLostReasonChange}
							placeholder={t('LostDealsPopover::The reason for losing the deal')}
							required
						/>
					</div>

					<div className='form-item'>
						<label>{t('LostDealsPopover::Comments')}</label>
						<textarea
							value={notes}
							onChange={onNotesChanges}
							placeholder={t('LostDealsPopover::Add a note')}
						/>
					</div>

					<TaskButton
						onClick={onFormSubmit}
						style={{ marginTop: 12 }}
						destructive
						colorPermanent
						center
					>
						{t('LostDealsPopover::Mark as lost')}
					</TaskButton>

					<TaskButton
						onClick={onClose}
						style={{ marginTop: 12 }}
						center
					>
						{t('LostDealsPopover::Cancel')}
					</TaskButton>

					<input type='submit' style={{ display: 'none' }} />
				</form>
			</TaskPopoverContent>

		</TaskPopoverContainer>
	)
}

export default LostDealsPopover