import * as React from 'react'
import Icon, { IconTypes } from '../Icons/Icon'
import Search from '../Search/Search'
import styled from 'styled-components'
import { Style } from '../../styles'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { AppState } from '../../store'
import { toggleNavigation } from '../../store/navigation/actions'
import { CurrentUser } from '../../types'

const TopNavigationContainer = styled.div`
  position: fixed;
  left: 250px;
  right: 0px;
  top: 0;
  display: flex;
  flex-direction: column;
  min-height: 52px;
  padding: 8px;
  justify-content: space-between;
  z-index: 5;
  transition: left ${Style.animations.baseTransitionSpeed} ease-in-out;
  box-shadow: inset 0 -1px 0 rgba(88,134,196,.2), 0 2px 10px rgba(34,61,97,.05);
  background: #fff;

  .has-banner & {
    top: 40px;

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
      top: 0;
    }
  }

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
    left: 0;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
`
const TopNavigationGroup = styled.div`
  display: flex;
  flex-direction: row;

  &.is-mobile {
    display: none;
    margin-right: 10px;
    font-size: 20px;

    .search-input {
      margin-right: 10px;
    }

    > a {
      color: #666;
    }
  }

  &.is-desktop {
    .search-input {
      .dropdown-menu {
        left: initial;
        transform: none;
        right: 0;
        min-width: 280px;
      }
    }
  }

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
    &.is-mobile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &:last-child {
      display: none;
    }
  }
`

const TopNavigationLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;

  svg, i {
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-right: 8px;
    fill: #36619a;
    color: #36619a;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: #36619a
  }
`

const TopNavigationVR = styled.div`
  align-self: stretch;
  margin-left: 8px;
  margin-right: 8px;
  border-left: 1px solid #ccc;
`

const NavigationMobileHamburger = styled.a`
	cursor: pointer;
  color: #36619a;
  
  i {
    color: #36619a;
  }
`

const TopNavigationMobileSearch = styled.div`
  display: none !important;
  margin: 10px 0;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: flex !important;

    .search {
      width: 100%;
    }
  }

  .search-input {
    width: 100%;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}
interface IDispatchToProps {
  toggleNavigation: typeof toggleNavigation
}

type IProps = IStateToProps & IDispatchToProps & {
  icon: IconTypes,
  title: string,
  action?: JSX.Element | undefined
} & WithTranslation

const TopNavigation = (props: IProps) => {
  const { icon, title, action, t, currentUser, } = props

  const onHamburgerToggle = () => {
    props.toggleNavigation()
  }

  return (
    <TopNavigationContainer>
      <div>
        <TopNavigationGroup>
          <TopNavigationLabel>
            {icon && <Icon icon={icon} />}

            <h1>{title}</h1>
          </TopNavigationLabel>
        </TopNavigationGroup>


        <TopNavigationGroup className='is-mobile'>
          <NavigationMobileHamburger onClick={onHamburgerToggle}>
            <i className='fas fa-bars'></i>
          </NavigationMobileHamburger>
          {action}
        </TopNavigationGroup>

        <TopNavigationGroup className='is-desktop'>
          <Search centered={action ? true : false} />

          {action && <TopNavigationVR />}

          {action}
        </TopNavigationGroup>
      </div>

      <TopNavigationMobileSearch>
        <Search />
      </TopNavigationMobileSearch>
    </TopNavigationContainer >
  );
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    toggleNavigation: () => dispatch(toggleNavigation()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopNavigation))