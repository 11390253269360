
import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const BlockEditorSidebar = styled.div`
	display: flex;
	flex-direction: column;
	border-left: 1px solid ${Style.color.border};
	width: 100%;
	max-width: 290px;
	background-color: white;
	box-shadow: 0 0 16px 0 #13304213;
	overflow-y: auto;
	overflow-x: hidden;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		box-shadow: none;
		border-left: none;
		max-width: initial;
	}

	form {
		flex: 1;
	}
`

export default BlockEditorSidebar