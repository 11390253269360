import * as React from 'react'
import styled, { css } from 'styled-components';
import Icon, { IconTypes } from '../Icons/Icon';
import { Style } from '../../styles';
import LedgerItemHelper from '../../helpers/LedgerItemHelper';
import { LedgerItem, LedgerItemStatus } from '../../types';
import { useTranslation } from 'react-i18next';

interface IProps {
	ledgerItem?: LedgerItem
	rounded?: boolean
	width?: number
	onClick: () => void
}

interface ContainerProps {
	rounded?: boolean
	width: number
	backgroundColor: string
	onClick?: () => void
}

const Container = styled.div`
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
	${(props: ContainerProps) => css`
		width: ${props.width}px;
		height: ${props.width}px;
		background-color: ${props.backgroundColor};
		color: white;
		fill: white;
	`}
	border: 2px solid transparent;
	transition: border-color .3s;
	border-radius: 7px;
	overflow: hidden;

	${(props: ContainerProps) => props.rounded && css`
		border-radius: 50%;
	`}

	${(props: ContainerProps) => props.onClick && css`
		cursor: pointer;
	`}

	svg {
		width: 15px;
	}
`

const StyledIcon = styled(Icon)`
	color: white;
	fill: white;
`

const LedgerItemAvatar = (props: IProps) => {
	const { t } = useTranslation()
	const { ledgerItem, rounded, width, onClick } = props

	let backgroundColor = '#ccc'
	let icon: IconTypes = 'question-mark'

	const status = LedgerItemHelper.getLedgerItemStatus(ledgerItem)
	let tooltip = LedgerItemHelper.getLedgerItemStatusLabel(status)

	switch (status) {
		case LedgerItemStatus.DRAFT:
			backgroundColor = Style.color.brandPrimary
			icon = 'arrow-right'
			break
		case LedgerItemStatus.OVERDUE:
			backgroundColor = Style.color.brandDanger
			icon = 'exclamation-triangle'
			break
		case LedgerItemStatus.APPROVED:
			backgroundColor = Style.color.brandSuccess
			icon = 'thumbs-up'
			break
		case LedgerItemStatus.REJECTED:
			backgroundColor = Style.color.brandDanger
			icon = 'thumbs-down'
			break
		case LedgerItemStatus.INVOICED:
			backgroundColor = Style.color.brandSuccess
			icon = 'check'
			break
		case LedgerItemStatus.OUTSTANDING:
			backgroundColor = Style.color.brandWarning
			icon = 'hourglass-half'
			break
		case LedgerItemStatus.PAID:
			backgroundColor = Style.color.brandSuccess
			icon = 'check'
			break
		case LedgerItemStatus.DELIVERED:
			backgroundColor = Style.color.brandSuccess
			icon = 'check'
			break
		case LedgerItemStatus.CANCELLED:
			backgroundColor = Style.color.brandDanger
			icon = 'close'
			break
		case LedgerItemStatus.EXPIRED:
			backgroundColor = Style.color.brandDanger
			icon = 'exclamation-circle'
			break
		case LedgerItemStatus.CREDITED:
			backgroundColor = Style.color.brandSuccess
			icon = 'check'
			break
		case LedgerItemStatus.SCHEDULED:
			backgroundColor = Style.color.brandSuccess
			icon = 'check'
			break
	}

	return (
		<Container
			rounded={rounded}
			width={width || 40}
			backgroundColor={backgroundColor}
			onClick={onClick}
			data-tip={tooltip}
		>
			<StyledIcon icon={icon} />
		</Container>
	)
}

export default LedgerItemAvatar