import * as React from 'react'
import styled, { css } from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'

const Container = styled.div<{
	onClick?: (e: React.MouseEvent) => void
	color?: string
	borderColor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 32px;
  width: 32px;
  padding: 0;
  border: none;
  outline: none;
  border-radius: $base-border-radius;
	border-radius: 50%;
	fill: #fff;
	color: #fff;
	transition: transform 150ms ease-in-out;

	${props => props.onClick && css`
		cursor: pointer;
		
		&:hover {
			transform: scale(1.1);
		}
	`}

	${props => props.color && css`
		color: ${props.color};
		fill: ${props.color};

		svg, i{
			color: currentColor;
			fill: currentColor;
		}
	`}

	${props => props.borderColor && css`
		border: 1px solid ${props.borderColor};
	`}

  svg {
    width: 14px;
    height: 14px;
		transition: transform 150ms ease-in-out;
  }

  i {
    font-size: 14px;
    color: currentColor;
		transition: transform 150ms ease-in-out;
  }
`

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

interface IProps {
	icon: IconTypes
	onClick?: (e: React.MouseEvent) => void
	style?: {
		backgroundColor?: string
		color?: string
		borderColor?: string
	}
}

const TimelineStep = (props: IProps) => {
	const { icon, style, onClick } = props;

	return (
		<Container onClick={onClick} style={style} color={style.color} borderColor={style.borderColor}>
			<IconContainer>
				<Icon icon={icon} />
			</IconContainer>
		</Container>
	);
}

export default TimelineStep