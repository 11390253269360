import styled from "styled-components";

const MobileNavigationButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	width: 32px;
	height: 32px;
	min-width: 32px;
	min-height: 32px;
	background-color: #eaf0f6;
	border: 1px solid #cbd6e2;
	color: #506e91;
	fill: #506e91;
	border-radius: 50%;
	cursor: pointer;
	z-index: 6;

	&:hover {
		background-color: #f5f8fa;
    color: #506e91;
		fill: #506e91;
	}
`

export default MobileNavigationButton