import styled from 'styled-components'

const StepHeaderIcon = styled.div<{ color: string }>`
  font-size: 20px;
  line-height: 1;
  color: ${(props) => props.color};

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
`

export default StepHeaderIcon