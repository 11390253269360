
import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	background: white;
	border-radius: 3px;
	box-shadow: 0 7px 14px 0 rgba(59,65,94,.1), 0 3px 6px 0 rgba(0,0,0,.07);
	border: 1px solid ${Style.color.border};
	max-width: 310px;
`

const Title = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #212B36;
	margin-bottom: ${Style.spacing.x1_25};
`

const Description = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #637381;
`

interface IProps {
	title: string
	description: string
}

export default class GraphEmpty extends React.PureComponent<IProps> {
	render () {
		const { title, description } = this.props

		return (
			<Container>
				<Title>{title}</Title>
				<Description>{description}</Description>
			</Container>
		)
	}
}