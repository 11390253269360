import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const ConversationList = styled.div<{ active?: boolean }>`
	width: 300px;
	min-width: 300px;
	border-right: 2px solid rgb(234,237,245);
	overflow-x: hidden;
	overflow-y: auto;
	background: white;
	transition: left 0.3s ease-in-out;

	&::-webkit-scrollbar {
    background: white;
		height: 8px;
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 4px;
	}


	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 100%;
		display: none;
		border-color: transparent;

		${props => props.active && css`
			display: block;
		`}
	}
`

export default ConversationList