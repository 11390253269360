import * as React from 'react'
import MainNavigation from '../components/Navigation/MainNavigation'
import Timer from '../components/TimeEntry/Timer'
import { Switch, Route, RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'
import ERoute from '../ERoute'
import Dashboard from '../containers/Dashboard'
import Contact from '../containers/Contacts/Contact'
import Project from '../containers/Projects/Project'
import Projects from '../containers/Projects'
import LedgerConditions from '../containers/Invoices/LedgerConditions'
import Invoices from '../containers/Invoices'
import InvoiceShow from '../containers/Invoices/InvoiceShow'
import Expenses from '../containers/Expenses'
import Integrations from '../containers/Integrations'
import Account from '../containers/Account'
import Settings from '../containers/Settings'
import NoMatch from '../containers/NoMatch'
import Banner from '../components/Banner/Banner'
import Team from '../containers/Team'
import Contacts from '../containers/Contacts'
import Products from '../components/Products/Products'
import Reporting from '../containers/Reporting'
import Board from '../components/Board/Board'
import Calendar from '../containers/Calendar'
import { TimeEntriesController } from '../controllers'
import WorkspaceChannelHelper from '../helpers/WorkspaceChannelHelper'
import { connect } from 'react-redux'
import { startTimer } from '../store/timer/actions'
import { Dispatch } from 'redux'
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper'
import LedgerItemCondition from '../containers/LedgerItemCondition'
import Proposal from '../containers/Proposals/Proposal'
import Proposals from '../containers/Proposals'
import Contract from '../containers/Contracts/Contract'
import Contracts from '../containers/Contracts'
import Forms from '../containers/Forms'
import Form from '../containers/Form'
import TimeTracking from '../containers/TimeTracking'
import Files from '../containers/Files'
import styled from 'styled-components'
import { Style } from '../styles'
import NavigationHelper from '../helpers/NavigationHelper'
import { UserWorkspaceSettingRole, UserWorkspaceSettingScope, WorkspaceCableEventType } from '../types'
import Bank from '../containers/Bank'
import Deals from '../containers/Deals'
import Deal from '../containers/Deal'
import Tasks from '../containers/Tasks'
import Transaction from '../containers/Transactions/Transaction'
import Playbooks from '../containers/Playbooks'
import Playbook from '../containers/Playbooks/Playbook'
import Referral from '../containers/Referral'
import Documents from '../containers/Documents'
import Accountant from '../containers/Accountant'
import Sequences from '../containers/Sequences'
import Sequence from '../containers/Sequences/Sequence'

interface IDispatchToProps {
  startTimer: typeof startTimer
}

const AppContainer = styled.div`
  height: 100%;
  display: flex;
  padding-left: 250px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
    padding-left: 0;
  }
`

type IProps = IDispatchToProps & RouteComponentProps<any>

const AppLayout = (props: IProps) => {
  React.useEffect(() => {
    fetchActiveTimer().catch(console.error)

    // Check if we need to move towards configure home page (only on first load)
    if (props.history.location.pathname === ERoute.PATH_APP_LAYOUT) props.history.replace(NavigationHelper.getHomePath())
  }, [])

  const fetchActiveTimer = async () => {
    try {
      const entry = await TimeEntriesController.getActiveTimer()
      const { startTimer } = props;

      if (entry) {
        if (entry.errors) return

        // Send event to workspace
        WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.START_TIMER, data: { entry: entry } })

        // Start timer
        startTimer(entry)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  return (
    <>
      <Banner />

      <MainNavigation />

      <Timer />

      <AppContainer>
        <Switch>
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DASHBOARD) && <Route path={ERoute.PATH_DASHBOARD} exact component={Dashboard} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CALENDAR) && <Route path={ERoute.PATH_CALENDAR} component={Calendar} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <Route path={ERoute.PATH_TASKS} component={Tasks} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <Route path={ERoute.PATH_TASKS_BOARD} component={Board} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTACT) && <Route path={ERoute.PATH_CONTACTS} component={Contacts} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL) && <Route path={ERoute.PATH_DEAL} component={Deal} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL) && <Route path={ERoute.PATH_DEALS} component={Deals} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SEQUENCE) && <Route path={ERoute.PATH_SEQUENCE} component={Sequence} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SEQUENCE) && <Route path={ERoute.PATH_SEQUENCES} component={Sequences} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTACT) && <Route path={ERoute.PATH_CONTACT} component={Contact} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) && <Route path={ERoute.PATH_PROJECT} component={Project} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) && <Route path={ERoute.PATH_PROJECTS} component={Projects} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING) && <Route path={ERoute.PATH_TIMESHEETS} component={TimeTracking} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={ERoute.PATH_LEDGER_CONDITION_NEW} component={LedgerItemCondition} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={ERoute.PATH_LEDGER_CONDITION} component={LedgerItemCondition} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={ERoute.PATH_LEDGER_CONDITIONS} component={LedgerConditions} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROPOSAL) && <Route path={ERoute.PATH_PROPOSAL} component={Proposal} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROPOSAL) && <Route path={ERoute.PATH_PROPOSALS} component={Proposals} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTRACT) && <Route path={ERoute.PATH_CONTRACT} component={Contract} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTRACT) && <Route path={ERoute.PATH_CONTRACTS} component={Contracts} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={ERoute.PATH_ORDERS} component={Invoices} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={ERoute.PATH_INVOICE} component={InvoiceShow} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PRODUCT) && <Route path={ERoute.PATH_PRODUCTS} component={Products} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FORM) && <Route path={ERoute.PATH_FORM} component={Form} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FORM) && <Route path={ERoute.PATH_FORMS} component={Forms} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) && <Route path={ERoute.PATH_FILES} component={Files} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) && <Route path={ERoute.PATH_EXPENSES} component={Expenses} />}

          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK) && <Route path={ERoute.PATH_TRANSACTION} component={Transaction} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK) && <Route path={ERoute.PATH_BANK} component={Bank} />}
          <Route path={ERoute.PATH_INTEGRATIONS} component={Integrations} />
          <Route path={ERoute.PATH_ACCOUNT} component={Account} />
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) && <Route path={ERoute.PATH_SETTINGS} component={Settings} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) && <Route path={ERoute.PATH_SETTINGS} component={Settings} />}
          {UserWorkspaceSettingHelper.hasRole(UserWorkspaceSettingRole.OWNER) && <Route path={ERoute.PATH_TEAM} component={Team} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.REPORTING) && <Route path={ERoute.PATH_REPORTING} component={Reporting} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK) && <Route path={ERoute.PATH_PLAYBOOK} component={Playbook} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK) && <Route path={ERoute.PATH_PLAYBOOKS} component={Playbooks} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.ACCOUNTANT) && <Route path={ERoute.PATH_ACCOUNTANT_SEND} component={Accountant} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DOCUMENT) && <Route path={ERoute.PATH_ACCOUNTANT_DOCUMENTS} component={Documents} />}
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.ACCOUNTANT) && <Redirect from={ERoute.PATH_ACCOUNTANT} to={ERoute.PATH_ACCOUNTANT_SEND} />}
          <Route path={ERoute.PATH_REFER_A_FRIEND} component={Referral} />
          <Route component={NoMatch} />
        </Switch>
      </AppContainer>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    startTimer: (entry) => dispatch(startTimer(entry)),
  }
}

export default connect(null, mapDispatchToProps)(AppLayout)