import * as React from 'react'
import PageContent from '../../components/Page/PageContent'
import Title from '../../components/Settings/Title'
import { useTranslation } from 'react-i18next'
import Panel from '../../components/Panel/Panel'
import styled from 'styled-components'
import Switch from '../../components/Form/Switch'
import { SequencesController } from '../../controllers'
import { Sequence } from '../../types'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import PageLoader from '../../components/Page/PageLoader'
import { useDebouncedCallback } from 'use-debounce'
import Button from '../../components/Button/Button'
import ERoute from '../../ERoute'
import { showConfirmModal } from '../../store/modals/actions'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import Notification from '../../utilities/Notification'

const HorizontalFormItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HorizontalFormItemLeft = styled.div`
  flex: 1;
`

const HorizontalFormItemRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`

type IProps = & RouteComponentProps<any> & IDispatchToProps

interface IState {
  didInitialLoad: boolean
  sequence: Sequence
}

interface IDispatchToProps {
  showConfirmModal: typeof showConfirmModal
}

const SequenceSettings = (props: IProps) => {
  const { t } = useTranslation()
  const { match: { params: { id } }, showConfirmModal } = props
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    sequence: null
  })
  const { sequence, didInitialLoad } = state
  const history = useHistory()

  React.useEffect(() => {
    fetchSequence()
  }, [])

  const fetchSequence = async () => {
    try {
      const { sequence } = await SequencesController.getSequenceForm({ id: id })

      setState({ ...state, sequence: sequence, didInitialLoad: true })
    } catch (ex) {
      console.error(ex)
    }
  }

  const updateSequence = async (updatedSequence: Sequence) => {
    try {
      setState({ ...state, sequence: updatedSequence })
      const sequence = await SequencesController.update(updatedSequence)

      setState({ ...state, sequence: sequence })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onToggleActive = () => {
    updateSequence({ ...sequence, active: !sequence.active })
  }

  const onToggleBusinessDaysOnly = () => {
    updateSequence({ ...sequence, business_days_only: !sequence.business_days_only })
  }

  const debouncedNameSave = useDebouncedCallback(
    (name) => updateSequence({ ...sequence, name: sequence.name }), 500
  );

  const onNameChange = (e) => {
    setState({ ...state, sequence: { ...sequence, name: e.currentTarget.value } })

    debouncedNameSave(e.currentTarget.value)
  }

  const onDeleteClick = async () => {
    showConfirmModal({
      title: t('SequenceSettings::Delete sequence'),
      description: t('SequenceSettings::You are about to delete this sequence. Are you sure?'),
      action: { label: t('SequenceSettings::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {

          const response = await SequencesController.delete(sequence.id)

          console.log(response)
          debugger
          if (response.success) {
            Notification.notifySuccess(t('SequenceSettings::Sequence successfully deleted'))
            history.push(ERoute.PATH_SEQUENCES)
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  return (
    <>
      <PageContent className='is-sequence'>
        <Title>{t('SequenceSettings::General')}</Title>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <>
          <Panel>
            <HorizontalFormItem>
              <HorizontalFormItemLeft>
                <div className='form-item'>
                  <label>{t('SequenceSettings::Name')}</label>
                  <input
                    type='text'
                    value={sequence.name}
                    onChange={onNameChange}
                  />
                </div>
              </HorizontalFormItemLeft>
            </HorizontalFormItem>
            <HorizontalFormItem>
              <HorizontalFormItemLeft>
                <div className='form-item'>
                  <label>{t('SequenceSettings::Sequence active')}</label>
                  <div>
                    {t('SequenceSettings::If enabled, the sequence will run automatically when new contacts are enrolled.')}
                  </div>
                </div>
              </HorizontalFormItemLeft>

              <HorizontalFormItemRight>
                <Switch
                  name='business_days_only'
                  checked={sequence.active}
                  onClick={onToggleActive}
                />
              </HorizontalFormItemRight>
            </HorizontalFormItem>
            <HorizontalFormItem>
              <HorizontalFormItemLeft>
                <div className='form-item'>
                  <label>{t('SequenceSettings::Execute steps on business days only')}</label>
                  <div>
                    {t('SequenceSettings::If enabled, emails will be sent and tasks will be created on business days only.')}
                  </div>
                </div>
              </HorizontalFormItemLeft>

              <HorizontalFormItemRight>
                <Switch
                  name='business_days_only'
                  checked={sequence.business_days_only}
                  onClick={onToggleBusinessDaysOnly}
                />
              </HorizontalFormItemRight>
            </HorizontalFormItem>
            <HorizontalFormItem>
              <div className='form-item'>
                <label>{t('SequenceSettings::Delete sequence')}</label>
                <div>
                  {t('SequenceSettings::Deleting a sequence will remove all associated steps and enrollments.')}
                </div>
              </div>

              <HorizontalFormItemRight>
                <Button
                  type='danger'
                  text={t('SequenceSettings::Delete')}
                  onClick={onDeleteClick}
                />
              </HorizontalFormItemRight>
            </HorizontalFormItem>
          </Panel>
        </>}
      </PageContent>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}

export default connect(null, mapDispatchToProps)(SequenceSettings)