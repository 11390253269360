import * as React from 'react'
import { useTranslation } from "react-i18next"
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { Style } from '../../../styles'
import { SubmitBlock as SubmitBlockType } from '../../../types'
import { EditBlockProps } from '../Block'

const SubmitInput = styled.input`
	display: block;
	justify-content: center;
	align-items: center;
	width: fit-content !important;
	color: white !important;
	padding: ${Style.spacing.x1};
	background-color: ${Style.color.brandSuccess} !important;
	border: none !important;
	font-weight: bold !important;
	text-align: center !important;
	width: 100% !important;

	&:active, &:focus {
		box-shadow: none !important;
	}
`

type IProps = {} & EditBlockProps<SubmitBlockType>

const SubmitBlock = (props: IProps) => {
	const { t } = useTranslation()
	const { block } = props
	const input = React.useRef<HTMLInputElement>()
	const debouncedDescriptionChange = useDebouncedCallback(
		() => props.onChange({ ...block, text: input.current.value }),
		500
	);

	return (
		<SubmitInput
			ref={input}
			type='text'
			defaultValue={block.text}
			onChange={debouncedDescriptionChange}
			placeholder={t('SubmitBlock::Submit')}
		/>
	)
}

export default SubmitBlock