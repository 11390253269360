import styled from "styled-components"

const Button = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: 36px;
	min-width: 36px;
	margin: 0;
	padding: 7px 16px;
	background: linear-gradient(180deg,#fff,#f9fafb);
	border: 1px solid #c4cdd5;
	box-shadow: 0 1px 0 0 rgba(22,29,37,.05);
	border-radius: 3px;
	line-height: 1;
	color: #212b36;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	transition-property: background,border,box-shadow;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.64,0,.35,1);

	&:hover {
		background: linear-gradient(180deg,#f9fafb,#f4f6f8);
	}

	svg {
		width: 20px;
		height: 20px;
		fill: #637381;
		margin-right: 4px;
	}
`
export default Button