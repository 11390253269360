import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Project } from '../../../types'
import moment from '../../../utilities/Moment'
import Icon from '../../Icons/Icon'

const Tooltip = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	visibility: hidden;
	opacity: 0;
	background: #6BBD2E; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: 17;
	width: max-content;
`

const TooltipWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
`

const TooltipArrow = styled.div<{ color: string }>`
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-width: 10px;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

	${(props) => css`
			border-top-color: ${props.color};
	`}
`

const ProjectName = styled.div`
	font-weight: bold;
	text-align: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    display: block;
    overflow: hidden;
`

const ProjectSchedule = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	i {
		font-size: 12px;
	}

	span {
		&:first-child {
			margin-right: 4px;
		}

		&:last-child {
			margin-left: 4px;
		}
	}
`

export const MOUSEMOVE_PROJECT_TIMELINE_ITEM = 'mousemove-project-timeline-item'
export const MOUSELEAVE_PROJECT_TIMELINE_ITEM = 'mouseleave-project-timeline-item'

interface IProps { }

const ProjectMonthlyTimelineItemTooltip = (props: IProps) => {
    const { t } = useTranslation()
    const tooltip = React.useRef<HTMLDivElement>()
    const [state, setState] = React.useState<{ project: Project, x: number, y: number } | null>(null)

    const setTooltip = (data) => {
        const { detail: { project, x, y } } = data

        if (project && x && y) {
            setState({ project, x, y })
        } else {
            setState(null)
        }
    }

    React.useEffect(() => {
        document.addEventListener(MOUSEMOVE_PROJECT_TIMELINE_ITEM, setTooltip)
        document.addEventListener(MOUSELEAVE_PROJECT_TIMELINE_ITEM, setTooltip)

        return () => {
            document.removeEventListener(MOUSEMOVE_PROJECT_TIMELINE_ITEM, setTooltip)
            document.removeEventListener(MOUSELEAVE_PROJECT_TIMELINE_ITEM, setTooltip)
        }
    }, [state])

    if (!state) return null

    const { project, x, y } = state

    let startDate = ''

    if (project.start_date) {
        startDate = moment(project.start_date).format('D MMM')
    } else if (project.estimated_start_date) {
        startDate = moment(project.estimated_start_date).format('D MMM')
    }

    let endDate = t('ProjectMonthlyTimelineItemTooltip::Ongoing')

    if (project.start_date && project.cancel_date) {
        endDate = moment(project.cancel_date).format('D MMM')
    }
    else if (project.start_date && project.end_date) {
        endDate = moment(project.end_date).format('D MMM')
    } else if (project.estimated_start_date && project.estimated_end_date) {
        endDate = moment(project.estimated_end_date).format('D MMM')
    }

    let tooltipWidth = null

    if (tooltip.current) {
        tooltipWidth = tooltip.current.clientWidth
    }

    return (
        <Tooltip
            ref={tooltip}
            style={{
                left: `calc(${x}px - ${tooltipWidth / 2}px)`,
                top: `calc(${y}px - 80px)`,
                opacity: project && tooltipWidth ? 1 : 0,
                visibility: project && tooltipWidth ? 'visible' : 'hidden',
                background: project.color,
            }}
        >
            <TooltipWrapper>
                <ProjectName>{project.name}</ProjectName>
                <ProjectSchedule>
                    <span>{startDate}</span>
                    <Icon icon='arrow-right' />
                    <span>{endDate}</span>
                </ProjectSchedule>

                <TooltipArrow color={project.color} />
            </TooltipWrapper>
        </Tooltip>
    )
}

export default ProjectMonthlyTimelineItemTooltip