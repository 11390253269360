import * as React from 'react'
import { WithTranslation, withTranslation } from "react-i18next";
import { Contact } from "../../../types";
import { ContactsController } from "../../../controllers";
import SimplebarHeader from "../../Simplebar/SimplebarHeader";
import SimplebarContainer from "../../Simplebar/SimplebarContainer";
import SimplebarContent from "../../Simplebar/SimplebarContent";
import SimplebarDescription from "../../Simplebar/SimplebarDescription";
import SimplebarItems from '../../Simplebar/SimplebarItems';
import SimplebarItem from '../../Simplebar/SimplebarItem';
import ContactTypeAvatar from '../../Avatar/ContactTypeAvatar';
import SimplebarLoader from '../../Simplebar/SimplebarLoader';

type IProps = {
  active: boolean
  onItemClick: (contact: Contact) => void
} & WithTranslation

interface IState {
  recentContactsSimplebar: Contact[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  reachedEnd: boolean
  isFetching: boolean
}

class RecentContactsSimplebar extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      recentContactsSimplebar: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      reachedEnd: false
    }

    this.fetchRecentContacts = this.fetchRecentContacts.bind(this)
    this.onScrollChange = this.onScrollChange.bind(this)
    this.onItemClick = this.onItemClick.bind(this)
  }

  componentDidMount() {
    this.fetchRecentContacts(1)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.props.active && !prevProps.active) {
      this.container.current.scrollTop = 0
      this.fetchRecentContacts(1)
    }
  }

  async fetchRecentContacts(page: number) {
    const { recentContactsSimplebar: stateContacts } = this.state
    this.setState({
      isFetching: true
    }, async () => {
      try {
        const response = await ContactsController.getContacts({
          page: page,
          order: 'updated_at_desc',
        })
        const { contacts, current_page, total_pages, total_entries } = response;

        this.setState({
          recentContactsSimplebar: current_page === 1 ? [...contacts] : [...stateContacts, ...contacts],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
          reachedEnd: current_page === total_pages
        });
      } catch (ex) {
        console.error(ex)
      }
    });
  }

  onScrollChange(event) {
    const { currentPage, reachedEnd, isFetching } = this.state
    const node = event.target;
    const scrollListEndReached = node.scrollHeight - node.scrollTop <= node.clientHeight;

    if (scrollListEndReached && !reachedEnd && !isFetching) {
      this.fetchRecentContacts(currentPage + 1)
    }
  }

  onItemClick(contact: Contact) {
    this.props.onItemClick(contact)
  }

  render() {
    const { t } = this.props
    const { recentContactsSimplebar, didInitialLoad } = this.state

    return (
      <SimplebarContainer>
        <SimplebarHeader>
          {t('RecentContactsSimplebar::Recent contacts')}
        </SimplebarHeader>

        <SimplebarContent ref={this.container} onScroll={this.onScrollChange}>
          {!didInitialLoad && <SimplebarLoader />}
          {didInitialLoad && <>
            {recentContactsSimplebar?.length === 0 && <SimplebarDescription>
              {t('RecentContactsSimplebar::Recent contacts will show up here.')}
            </SimplebarDescription>}
            {recentContactsSimplebar?.length > 0 && <SimplebarItems>
              {recentContactsSimplebar.map((contact, index) => {
                return (
                  <SimplebarItem key={contact.id} onClick={() => this.onItemClick(contact)} title={contact.name}>
                    <ContactTypeAvatar width={30} contact={contact} />
                    <span>{contact.name}</span>
                  </SimplebarItem>
                )
              })}
            </SimplebarItems>}
          </>}
        </SimplebarContent>
      </SimplebarContainer>
    )
  }
}

export default withTranslation()(RecentContactsSimplebar)