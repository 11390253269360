import * as React from 'react'
import Icon from '../Icons/Icon'
import styled from 'styled-components'
import Popover from '../Popover/Popover'
import ActionList, { IActionListItem } from '../ActionList/ActionList'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 8px;

  &:hover {
    background: #f0f0f2;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`

interface IProps {
  actions: IActionListItem[]
}
interface IState {
  actionMenuActive: boolean
}

const StepHeaderActions = (props: IProps) => {
  const [state, setState] = React.useState<IState>({
    actionMenuActive: false
  })

  const { actionMenuActive } = state

  const onToggleActionMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setState({ actionMenuActive: !actionMenuActive })
  }

  const onClosePopover = () => {
    setState({ actionMenuActive: false })
  }

  const actions: IActionListItem[] = props.actions.map((action) => {
    return (
      {
        ...action,
        onClick: () => {
          setState({ ...state, actionMenuActive: false })
          if (action.onClick) action.onClick()
        }
      }
    )
  })

  return (
    <>
      <Popover
        activator={
          <Container onClick={onToggleActionMenu}>
            <Icon icon='solaris-dots' />
          </Container>
        }
        active={actionMenuActive}
        placement='bottom-end'
        onClose={onClosePopover}
      >
        <ActionList
          actions={actions}
          hasIndicator
        />
      </Popover>
    </>
  )
}

export default StepHeaderActions