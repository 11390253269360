import styled, { css } from "styled-components";

const SimplebarItem = styled.div<{ mode?: 'input' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 24px;
  text-decoration: none;
  padding: 10px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  color: black;

  &:hover {
    background: #E3E3E3;
  }

  ${props => props.mode === 'input' && css`
    padding: 0;
    margin-right: 0;
  `}

  > *:first-child {
    margin-right: 8px;
  }

  span {
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
  }
`

export default SimplebarItem