import styled from 'styled-components'

const ConfirmWrapper = styled.div`
	max-width: 500px;
	margin: 88px auto 0px;
	padding: 44px;
	background: #f2f2ee;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export default ConfirmWrapper