import styled from 'styled-components'
import { Style } from '../../styles'

const MenuEditorItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Style.spacing.x2};
  overflow: hidden;
  border-radius: 4px;
  background: white;
  border: 1px solid ${Style.color.border};
`

export default MenuEditorItems