import * as React from 'react'
import { WithTranslation, withTranslation } from "react-i18next";
import { CurrentUser, Task } from "../../../types";
import { TasksController } from "../../../controllers";
import SimplebarHeader from "../../Simplebar/SimplebarHeader";
import SimplebarContainer from "../../Simplebar/SimplebarContainer";
import SimplebarContent from "../../Simplebar/SimplebarContent";
import SimplebarDescription from "../../Simplebar/SimplebarDescription";
import SimplebarItems from '../../Simplebar/SimplebarItems';
import SimplebarItem from '../../Simplebar/SimplebarItem';
import SimplebarLoader from '../../Simplebar/SimplebarLoader';
import SimplebarAction from '../../Simplebar/SimplebarAction';
import ResourceCheckbox from '../../Resource/ResourceCheckbox';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import styled from 'styled-components';

const TaskForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  margin-right: 0 !important;
  background: white;

  input {
    width: 100%;
    border: none !important;
    outline: none !important;
    margin-right: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps { }

type IProps = {
  active: boolean
  onItemClick: (task: Task) => void
  onAddTaskClick: () => void
} & IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  tasks: Task[],
  newTask?: Task,
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  reachedEnd: boolean
  isFetching: boolean
}

class MyTasksSimplebar extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      tasks: [],
      newTask: null,
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      reachedEnd: false
    }

    this.fetchTasks = this.fetchTasks.bind(this)
    this.onNewTaskSubmit = this.onNewTaskSubmit.bind(this)
    this.onNewTaskChange = this.onNewTaskChange.bind(this)
    this.onScrollChange = this.onScrollChange.bind(this)
    this.onItemClick = this.onItemClick.bind(this)
    this.onTaskCompletedChange = this.onTaskCompletedChange.bind(this)
    this.onAddTaskClick = this.onAddTaskClick.bind(this)
  }

  componentDidMount() {
    this.fetchTasks(1)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.props.active && !prevProps.active) {
      this.container.current.scrollTop = 0
      this.setState({ newTask: { name: '', assignee_ids: [this.props.currentUser?.id] } })
      this.fetchTasks(1)
    }
  }

  async fetchTasks(page: number) {
    const { currentUser } = this.props
    const { tasks: stateTasks } = this.state
    this.setState({
      isFetching: true
    }, async () => {
      try {
        const response = await TasksController.getTasks({
          page: page,
          assignee_ids: [currentUser.id],
          completed: false,
          order: 'created_at_desc',
        })
        const { tasks, current_page, total_pages, total_entries } = response;

        this.setState({
          tasks: current_page === 1 ? [...tasks] : [...stateTasks, ...tasks],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
          reachedEnd: current_page === total_pages
        });
      } catch (ex) {
        console.error(ex)
      }
    });
  }

  async onNewTaskSubmit(event) {
    const { tasks, newTask } = this.state
    event.preventDefault()

    try {
      const task = await TasksController.create({ ...newTask, assignee_ids: [this.props.currentUser.id] })

      this.setState({
        tasks: [
          task,
          ...tasks,
        ],
        newTask: { name: '' }
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onNewTaskChange(e) {
    const { newTask } = this.state

    this.setState({
      newTask: {
        ...newTask,
        name: e.target.value
      }
    })
  }

  onScrollChange(event) {
    const { currentPage, reachedEnd, isFetching } = this.state
    const node = event.target;
    const scrollListEndReached = node.scrollHeight - node.scrollTop <= node.clientHeight;

    if (scrollListEndReached && !reachedEnd && !isFetching) {
      this.fetchTasks(currentPage + 1)
    }
  }

  onItemClick(task: Task) {
    this.props.onItemClick(task)
  }

  async onTaskCompletedChange(index: number, completed: boolean) {
    const { tasks } = this.state

    // Updated task
    const task: Task = {
      ...tasks[index],
      completed: completed
    }

    // Set task on tasks
    tasks[index] = task

    // Update remote
    TasksController.update({ ...task }).catch(console.error)

    this.setState({ tasks: [...tasks] })
  }

  onAddTaskClick() {
    this.props.onAddTaskClick()
  }

  render() {
    const { t } = this.props
    const { tasks, newTask, didInitialLoad } = this.state

    return (
      <SimplebarContainer>
        <SimplebarHeader>
          {t('MyTasksSimplebar::My tasks')}
        </SimplebarHeader>

        <SimplebarContent ref={this.container} onScroll={this.onScrollChange}>
          {!didInitialLoad && <SimplebarLoader />}
          {didInitialLoad && <>
            {tasks?.length === 0 && <SimplebarDescription>
              {t('MyTasksSimplebar::Tasks assigned to you will show up here.')}
            </SimplebarDescription>}
            {tasks?.length > 0 && <SimplebarItems>
              <SimplebarItem key='new-task' mode='input'>
                <TaskForm onSubmit={this.onNewTaskSubmit}>
                  <ResourceCheckbox checked={false} onCheckedChange={(checked) => { }} />
                  <input
                    type='text'
                    placeholder={t('MyTasksSimplebar::Add task...')}
                    value={newTask?.name || ''}
                    onChange={this.onNewTaskChange}
                  />
                  <input type='submit' style={{ display: 'none' }} />
                </TaskForm>
              </SimplebarItem>
              {tasks.map((task, index) => {
                return (
                  <SimplebarItem key={task.id} onClick={() => this.onItemClick(task)} title={task.name}>
                    <div onClick={(e) => e.stopPropagation()}>
                      <ResourceCheckbox checked={task.completed} onCheckedChange={(checked) => this.onTaskCompletedChange(index, checked)} />
                    </div>
                    <span>{task.name}</span>
                  </SimplebarItem>
                )
              })}
            </SimplebarItems>}
          </>}
        </SimplebarContent>
        <SimplebarAction onClick={this.onAddTaskClick}>
          {t('MyTasksSimplebar::Add task')}
        </SimplebarAction>
      </SimplebarContainer>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps: IDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyTasksSimplebar))