import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ContentBlockHelper from '../../../helpers/ContentBlockHelper'
import { showItemBlockSettingModal } from '../../../store/modals/actions'
import { Style } from '../../../styles'
import { ItemBlock as ItemBlockType, ItemsBlock as ItemsBlockType, NumberFormat, WorkspaceTax } from '../../../types'
import Button from '../../Button/Button'
import { EditBlockProps } from '../Block'
import { BlockEditorDroppableType } from '../BlockEditor'
import ItemBlock from './ItemBlock'
import Utils from '../../../utilities/Utils'

const Container = styled.div`
	background: white;
	padding: 20px;
	padding-top: 16px;
	border-radius: 2px;
`

const TitleInput = styled.input`
	font-weight: 700;
	font-size: 18.75px;
	border: none !important;

	&:active, &:focus {
		box-shadow: none !important;
	}
`

const Items = styled.div`
	display: flex;
	flex-direction: column;
`

const AddItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-top: 2px dotted ${Style.color.border};
	padding-top: ${Style.spacing.x2};
`

interface IDispatchToProps {
	showItemBlockSettingModal: typeof showItemBlockSettingModal
}

type IProps = {
	droppableId: string
	currency: string
	numberFormat: NumberFormat
	taxes: WorkspaceTax[]
} & IDispatchToProps & EditBlockProps<ItemsBlockType>

const ItemsBlock = (props: IProps) => {
	const { droppableId, block, currency, numberFormat, taxes } = props
	const { t } = useTranslation()
	const [blockTitle, setBlockTitle] = React.useState(block.title)
	const [items, setItems] = React.useState(block.items)

	React.useEffect(() => {
		setItems(props.block.items)
	}, [block.items])

	const onBlockTitleChange = (e) => {
		const newTitle = e.target.value

		setBlockTitle(newTitle)

		props.onChange({ ...block, title: newTitle })
	}

	const onAddItemClick = () => {
		const selected = items.length === 0
		const newItems = [...items, {
			id: Utils.uuid(),
			title: '',
			description: '',
			quantity: 1,
			amount: 0,
			selected: selected
		}]

		setItems(newItems)

		props.onChange({ ...block, items: newItems })
	}

	const onItemChange = (item: ItemBlockType, index: number) => {
		// Get current items
		const newItems = [...items]

		// Update item
		newItems[index] = item

		// Set items locally
		setItems(newItems)

		// Update block
		props.onChange({ ...block, items: newItems })
	}

	const onSettingsClick = (item: ItemBlockType, index: number) => {
		props.showItemBlockSettingModal({
			itemBlock: item,
			onSubmit: (itemBlock: ItemBlockType) => {
				onItemChange(itemBlock, index)
			}
		})
	}

	const onDeleteClick = (item: ItemBlockType, index: number) => {
		items.splice(index, 1);
		props.onChange({
			...block,
			items: [...items]
		})
	}

	const onSelectionChange = (item: ItemBlockType, index: number) => {
		const updatedItems = ContentBlockHelper.getSelectionChangeItems(block, index)

		props.onChange({ ...block, items: updatedItems })
	}

	const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
		if (!result.destination) return;

		const source = result.source;
		const destination = result.destination;

		// did not move anywhere - can bail early
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		if (result.type === BlockEditorDroppableType.BLOCK_ITEM) { // Reordering an item
			const { source, destination } = result

			if (source.droppableId === destination.droppableId) {
				const blockItems = block.items
				const movedItem = blockItems[source.index]

				const updatedItems = [...blockItems]

				updatedItems.splice(source.index, 1)
				updatedItems.splice(destination.index, 0, movedItem)

				setItems(updatedItems)
				props.onChange({ ...block, items: updatedItems })
			}
		}
	}

	return (
		<Container>
			<TitleInput value={blockTitle} onChange={onBlockTitleChange} />

			<DragDropContext
				onDragEnd={onDragEnd}
			>
				<Droppable droppableId={droppableId} direction='vertical' type={BlockEditorDroppableType.BLOCK_ITEM}>
					{(droppableProvided, droppableSnapshot) => {
						return (
							<>
								<Items ref={droppableProvided.innerRef}>
									{items.map((item, index) => {
										return (
											<ItemBlock
												key={item.id}
												index={index}
												blockId={block.id}
												item={item}
												currency={currency}
												numberFormat={numberFormat}
												taxes={taxes}
												contactSelectionEnabled={block.contact_selection_enabled}
												onChange={(item) => onItemChange(item, index)}
												onSettingsClick={(item) => onSettingsClick(item, index)}
												onDeleteClick={(item) => onDeleteClick(item, index)}
												onSelectionChange={(item) => onSelectionChange(item, index)}
											/>
										)
									})}
								</Items>
								{droppableProvided.placeholder}
							</>
						)
					}}
				</Droppable>
			</DragDropContext>

			<AddItem>
				<Button
					text={t('ItemsBlock::Add item')}
					type='success'
					onClick={onAddItemClick}
				/>
			</AddItem>
		</Container>
	)
}

const mapDispatchToProps: IDispatchToProps = {
	showItemBlockSettingModal,
}

export default connect(null, mapDispatchToProps)(ItemsBlock)