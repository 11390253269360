import styled from 'styled-components'

const ConversationItemTitle = styled.div`
	font-weight: 600;
	padding-right: 6px;
	font-size: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export default ConversationItemTitle