import styled from 'styled-components'
import { Style } from "../../styles";

const BlockHeaderAction = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgba(0, 0, 0, 0.6);
	padding: ${Style.spacing.x0_5} ${Style.spacing.x1_5};
	border-left: 1px solid ${Style.color.border};
	cursor: pointer;

	i {
		font-size: 15px;
	}

	svg {
		width: 15px;
		height: 15px;
		fill: currentColor;
	}

	&:hover {
		color: black;

		svg {
			fill: black;
		}
	}
`

export default BlockHeaderAction