import * as React from 'react'
import { IconTypes } from '../Icons/Icon'
import TimelineTickpoint from './TimelineTickPoint'
import { Style } from '../../styles'
import styled from 'styled-components'
import TimelineStep from './TimelineStep'

const Container = styled.div`
  position: relative;
  padding: 20px 0;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    overflow-x: scroll;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }

  &::-webkit-scrollbar { 
    display: none; 
	}
`

const Wrapper = styled.div`
  position: relative;
  padding: 32px 0 70px 0;
  margin: 0 auto;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    width: 750px;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 100%;
    margin: 0;
  }
`

const GraphContainer = styled.div`
  position: relative;
`

const TimelineStepBackgrounds = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
`

const TimelineStepBackground = styled.div`
  width: 64px;
  height: 64px;
  background: #fff;
  border-radius: 50%;
`

const TimelineStepsProgress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 32px;
  margin: 16px 32px;
  background-color: #fff;
`

const TimelineStepProgress = styled.div`
  flex: 1 1 auto;
  position: relative;
  height: 32px;
  overflow: hidden;

  &.is-pending {
    color: ${Style.color.brandPrimary};
  }

  &.is-completed {
    color: ${Style.color.brandSuccess};
  }
`

const TimelineStepProgressBar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 10px;
  transform: translateY(-50%);
  color: inherit;
  background-color: currentColor;
  transition: width 250ms,color .35s;
`

const TimelineStepProgressBarArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-1px,-50%);
  height: 12px;
  color: inherit;
  border-top: 13px solid transparent;
  border-left: 17px solid currentColor;
  border-bottom: 13px solid transparent;
  transition: left 250ms,color .35s;
`

const TimelineSteps = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 16px;
`

const TimelineGraphTicks = styled.div`
	position: relative;
	width: 100%;

	&.is-up {
		bottom: 100%;
	}

	&.is-invoice {
		left: 32px;
		right: 32px;
		position: absolute;
		width: auto;
	}
`

export interface TimelineStep {
	key: string
	icon: IconTypes
	style: {
		backgroundColor: string,
		pointerEvents: string
		color?: string
	}
	tickTopLabel: string | JSX.Element
	tickBottomLabel: string | JSX.Element
	onClick?: (e: React.MouseEvent) => void
	complete: boolean
}

interface IProps {
	steps: TimelineStep[]
}

export default class Timeline extends React.PureComponent<IProps> {
	static COLOR_SUCCESS = '#4bc800';
	static COLOR_PROGRESS = '#4389fc';
	static COLOR_INACTIVE = 'rgb(187, 187, 187)';

	getSteps() {
		const { steps } = this.props
		return steps
	}

	getStepsProgress() {
		const steps = this.getSteps();
		const [_firstStep, ...restSteps] = steps

		const firstIncompleteIndex = steps.findIndex(step => !step.complete)

		return restSteps.map(({ complete }, index) => {
			let widthValue = '0%'
			let leftArrowValue = '0%'
			let transitionDelay = `${index * 150}ms`

			if (firstIncompleteIndex - 1 === index) {
				widthValue = '50%'
				leftArrowValue = '50%'
			} else if (firstIncompleteIndex > index && complete) {
				widthValue = '100%'
				leftArrowValue = '100%'
			} else if (complete) {
				widthValue = '100%'
				leftArrowValue = '100%'
			} else {
				widthValue = '0%'
				leftArrowValue = '0%'
			}

			return (
				<TimelineStepProgress key={index} className={`${complete ? 'is-completed' : 'is-pending'}`}>
					<TimelineStepProgressBar style={{ left: '0%', width: widthValue, transitionDelay: transitionDelay }} />
					<TimelineStepProgressBarArrow style={{ left: leftArrowValue, transitionDelay: transitionDelay }} />
				</TimelineStepProgress>
			);
		});
	}

	render() {
		const { steps } = this.props;

		if (steps.length > 0) {
			return (
				<Container>
					<Wrapper>
						<GraphContainer>
							<TimelineGraphTicks className='is-up is-invoice'>
								{steps.map(({ key, tickTopLabel }, index) => {
									if (!tickTopLabel) return null;

									return (
										<TimelineTickpoint
											key={key}
											left={index * (100 / (steps.length - 1))}
											label={tickTopLabel}
										/>
									)
								})}
							</TimelineGraphTicks>

							<TimelineStepBackgrounds>
								{steps.map(step => (<TimelineStepBackground key={step.key} />))}
							</TimelineStepBackgrounds>

							<TimelineStepsProgress>
								{this.getStepsProgress()}
							</TimelineStepsProgress>

							<TimelineSteps>
								{steps.map((step, index) => {
									return (
										<TimelineStep
											key={step.key}
											icon={step.icon}
											style={step.style}
											onClick={step.onClick ? step.onClick : undefined}
										/>
									)
								})}
							</TimelineSteps>

							<TimelineGraphTicks className='is-down is-invoice'>
								{steps.map(({ key, tickBottomLabel }, index) => {
									if (!tickBottomLabel) return null;

									return (
										<TimelineTickpoint
											key={key}
											left={index * (100 / (steps.length - 1))}
											label={tickBottomLabel}
										/>
									)
								})}
							</TimelineGraphTicks>
						</GraphContainer>
					</Wrapper>
				</Container>
			);
		} else {
			return null
		}
	}
}

