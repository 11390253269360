import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import NumberFormatter from '../../utilities/NumberFormatter';
import Notification from '../../utilities/Notification';
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import PageLoader from '../../components/Page/PageLoader';
import { Helmet } from 'react-helmet';
import { WithTranslation, withTranslation } from 'react-i18next';
import MoneyInput from '../../components/Form/MoneyInput';
import Tooltip from '../../components/Tooltips/Tooltip';
import { CurrentUser, DisplayableError, Settings } from '../../types';
import PowerSelect from '../../components/Form/PowerSelect';
import SettingHelper from '../../helpers/SettingHelper';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class General extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      form: null
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onYearlyRevenueGoalChange = this.onYearlyRevenueGoalChange.bind(this)
    this.onDefaultRatePricingTypeChange = this.onDefaultRatePricingTypeChange.bind(this)
    this.onDefaultRateChange = this.onDefaultRateChange.bind(this)
    this.onDefaultDayRateChange = this.onDefaultDayRateChange.bind(this)
    this.onTravelCostChange = this.onTravelCostChange.bind(this)
    this.onDefaultCurrencyChange = this.onDefaultCurrencyChange.bind(this);
    this.onNumberFormatChange = this.onNumberFormatChange.bind(this);
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    SettingsController
      .update(settings)
      .then(response => {
        // @ts-ignore
        if (response.errors) {
          Notification.notifyError(t('General::Oops something went wrong'));
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update local
          this.setState({ settings: settings })
          // Update global
          updateSettings(settings)

          Notification.notifySuccess(t('General::Settings successfully updated'));
        }
      })
      .catch(console.error)
  }

  onYearlyRevenueGoalChange(yearlyRevenueGoal: string) {
    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        yearly_revenue_goal: yearlyRevenueGoal,
      },
    });
  }

  onDefaultRatePricingTypeChange(option) {
    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        default_rate_type: option.value
      }
    });
  }

  onDefaultRateChange(value) {
    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        default_hourly_rate: value,
      }
    })
  }

  onDefaultDayRateChange(value) {
    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        default_day_rate: value,
      }
    })
  }

  onTravelCostChange(value) {
    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        travel_cost: value,
      }
    })
  }

  onDefaultCurrencyChange(e) {
    const currency = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        default_currency: currency,
      },
    });
  }

  onNumberFormatChange(e) {
    const numberFormat = e.currentTarget.value;

    const { settings } = this.state;

    settings.number_format = numberFormat;

    this.setState({
      settings: settings
    });
  }

  render() {
    const { t } = this.props
    const { settings, errors, form } = this.state

    if (form) {
      const {
        currencies,
        currency_formats: currencyFormats
      } = form

      const rateTypeOptions = SettingHelper.getRateTypeOptions()
      const selectedRateTypeOption = rateTypeOptions.find(option => option.value === settings?.default_rate_type)

      return (
        <>
          <Helmet>
            <title>{t('General::{{__appName}} | General')}</title>
          </Helmet>
          <ScrollToTopOnMount />
          <form onSubmit={this.onFormSubmit}>
            <Title>{t('General::General settings')}</Title>
            <Panel>
              <div className="grid">
                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>
                      {t('General::Revenue Goal')}
                      <Tooltip
                        content={t('General::{{__appName}} helps you target and track towards a revenue goal for your business. This goal should reflect the amount of money your business needs to generate each financial year in order to cover all expenses, pay salaries, and make a healthy profit.')}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </label>

                    <MoneyInput
                      name='yearly_revenue_goal'
                      currency={settings.default_currency}
                      numberFormat={settings.number_format}
                      value={settings.yearly_revenue_goal}
                      onBlur={this.onYearlyRevenueGoalChange}
                    />

                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('General::Default rate determined by')}</label>
                    <PowerSelect
                      options={rateTypeOptions}
                      value={selectedRateTypeOption}
                      onChange={this.onDefaultRatePricingTypeChange}
                      noOptionsMessage={(value) => t('General::No invoice pricing found.')}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('General::Hourly rate')}</label>
                    <MoneyInput
                      name='default_hourly_rate'
                      currency={settings.default_currency}
                      numberFormat={settings.number_format}
                      placeholderValue={65}
                      value={settings.default_hourly_rate}
                      onBlur={this.onDefaultRateChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('General::Day rate')}</label>
                    <MoneyInput
                      name='default_day_rate'
                      currency={settings.default_currency}
                      numberFormat={settings.number_format}
                      placeholderValue={450}
                      value={settings.default_day_rate}
                      onBlur={this.onDefaultDayRateChange}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('General::Mileage reimbursement (per km)')}</label>
                    <MoneyInput
                      name='default_day_rate'
                      currency={settings.default_currency}
                      numberFormat={settings.number_format}
                      placeholderValue={0.37}
                      value={settings.travel_cost}
                      onBlur={this.onTravelCostChange}
                    />
                  </div>
                </div>
              </div>



              <div className="grid">
                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('General::Default currency')}</label>
                    <div className="select-wrapper">
                      <select value={settings.default_currency} onChange={this.onDefaultCurrencyChange}>
                        {currencies.map((currency, index) => {
                          return (
                            <option value={currency.value} key={`${currency.value}-${index}`}>{currency.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <input type="submit" style={{ display: 'none' }} />
              <div className="field-actions">
                <input type="submit" style={{ display: 'none' }} />
                <div className="popover-wrapper">
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('General::Save')} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>

      )
    }
    else {
      return (
        <Panel>
          <PageLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(General))