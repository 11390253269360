import styled from 'styled-components'

const ReportSummaryPercent = styled.div`
  min-height: 80px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export default ReportSummaryPercent