import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import Panel from '../../components/Panel/Panel'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import PageLoader from '../../components/Page/PageLoader'
import { Helmet } from 'react-helmet';
import { WithTranslation, withTranslation } from 'react-i18next';
import EditorContainer from '../../components/Editor/EditorContainer';
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../components/Editor/Editor';
import LocaleHelper from '../../helpers/LocaleHelper';
import { DEFAULT_LOCALE } from '../../Constants';
import MobilityHelper from '../../helpers/MobilityHelper';
import PowerSelect from '../../components/Form/PowerSelect';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import { CurrentUser, DisplayableError, Locale, Settings } from '../../types';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  activeTranslationsLocale: Locale
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class CreditNotes extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      activeTranslationsLocale: DEFAULT_LOCALE,
      errors: [],
      form: null
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onDefaultLedgerItemLocaleChange = this.onDefaultLedgerItemLocaleChange.bind(this)
    this.onTranslationsLocaleChange = this.onTranslationsLocaleChange.bind(this)
    this.onCreditNoteDetailsChange = this.onCreditNoteDetailsChange.bind(this)
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    SettingsController
      .update(settings)
      .then(response => {
        // @ts-ignore
        if (response.errors) {
          Notification.notifyError(t('CreditNotes::Oops something went wrong'));
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update local
          this.setState({ settings: settings })
          // Update global
          updateSettings(settings)

          Notification.notifySuccess(t('CreditNotes::Settings successfully saved'));
        }
      })
      .catch(console.error)
  }

  onDefaultLedgerItemLocaleChange(e) {
    const newLocale = e.currentTarget.value

    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        default_ledger_item_locale: newLocale
      }
    })
  }

  onTranslationsLocaleChange(option) {
    this.setState({ activeTranslationsLocale: option.value });
  }

  onCreditNoteDetailsChange(details: string) {
    const { settings, activeTranslationsLocale } = this.state;

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationsLocale, settings.translations, 'default_credit_note_details', details)

    this.setState({
      settings: {
        ...settings,
        translations: { ...updatedTranslations }
      }
    });
  }

  render() {
    const { t } = this.props
    const { settings, errors, form, activeTranslationsLocale } = this.state

    if (form) {
      const localeOptions = LocaleHelper.getLocaleOptions()
      const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

      return (
        <>
          <Helmet>
            <title>{t('CreditNotes::{{__appName}} | Credit notes')}</title>
          </Helmet>

          <ScrollToTopOnMount />
          <form onSubmit={this.onFormSubmit}>
            <Title>{t('CreditNotes::Credit notes')}</Title>

            <Panel>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('CreditNotes::Default credit note language')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.default_ledger_item_locale} onChange={this.onDefaultLedgerItemLocaleChange}>
                        {localeOptions.map(locale => {
                          return (
                            <option key={locale.value} value={locale.value}>{locale.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid">
                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('CreditNotes::Default credit note footnote')}</label>
                    <PowerSelect
                      options={localeOptions}
                      value={selectedLocaleOption}
                      onChange={this.onTranslationsLocaleChange}
                      noOptionsMessage={(value) => t('CreditNotes::No language found.')}
                      theme={ReactSelectTheme}
                    />

                    <EditorContainer style={{ marginTop: 8 }}>
                      <Editor
                        model={MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'default_credit_note_details')}
                        onModelChange={this.onCreditNoteDetailsChange}
                        config={{
                          ...MINIMAL_EDITOR_CONFIG,
                          heightMin: 120,
                          heightMax: 120
                        }}
                      />
                    </EditorContainer>
                  </div>
                </div>
              </div>
              <div className="field-actions">
                <input type="submit" style={{ display: 'none' }} />
                <div className="popover-wrapper">
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('CreditNotes::Save')} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>
      )
    }
    else {
      return (
        <Panel>
          <PageLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreditNotes))