import * as React from 'react'
import PageLoader from '../../Page/PageLoader'

interface IProps {
	source: string
}

const GoogleEmbedPreview = (props: IProps) => {
	const iframe = React.useRef<HTMLIFrameElement>(null)
	const [isLoaded, setIsLoaded] = React.useState(false)

	React.useEffect(() => {
		let interval = null
		if (!isLoaded) {
			interval = setInterval(() => {
				console.log('Running through interval')
				try {
					if (iframe.current) {
						const iframeDocument = iframe.current.contentDocument || iframe.current.contentWindow.document;
						const documentLoaded = iframeDocument.body.classList.length > 0

						if (documentLoaded) {
							console.log('Document loaded')
						} else {
							iframe.current.src += ''
						}
					}
				} catch (ex) {
					clearInterval(interval)
					setIsLoaded(true)
				}
			}, 250)
		}

		return () => {
			if (interval) clearInterval(interval)
		}
	}, [isLoaded])

	return (
		<>
			{!isLoaded && <PageLoader />}
			<iframe ref={iframe} src={`https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(props.source)}`} style={{ display: isLoaded ? 'block' : 'none' }} />
		</>
	)
}

export default GoogleEmbedPreview