import styled from 'styled-components'

export default styled.div`
	color: #353f45;
	display: block;
	max-width: 270px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 16px;
	margin: 8px 0 0 0;
	font-weight: 700;
`