import * as React from 'react'
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next';
import { showConfirmModal, showExpenseModal, showPaymentModal } from '../../store/modals/actions';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import { ExpensesController } from '../../controllers';
import Notification from '../../utilities/Notification';
import { Helmet } from 'react-helmet';
import ListLoader from '../Loaders/ListLoader';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import { AppState } from '../../store';
import { Moment } from 'moment';
import ButtonPanel from '../Button/ButtonPanel';
import ExpenseResourceItemRow from './ExpenseResourceItemRow';
import ResourceTable from '../Resource/ResourceTable';
import { CurrentUser, Expense, ExpenseStatus, Payment, ResourceListFilterType } from '../../types';
import ExpenseHelper from '../../helpers/ExpenseHelper';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showPaymentModal: typeof showPaymentModal
  showExpenseModal: typeof showExpenseModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = {
  contactId?: string,
  projectId?: string,
  supplierId?: string
  primaryActionEnabled?: boolean
} & IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation
interface IState {
  expenses: Expense[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  filters: any
  sortValue: string
  isFetching: boolean
  searchValue: string
}


class Expenses extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expenses: [],
      currentPage: 1,
      totalPages: 1,
      didInitialLoad: false,
      filters: {},
      sortValue: LocalStorage.get(LocalStorageKey.EXPENSE_SORT_VALUE, 'invoiced_on_desc'),
      isFetching: false,
      searchValue: ''
    }

    this.onNewExpensesClick = this.onNewExpensesClick.bind(this);
    this.onTableExpenseClick = this.onTableExpenseClick.bind(this);
    this.onTableExpensePayClick = this.onTableExpensePayClick.bind(this);
    this.onTableExpenseEditClick = this.onTableExpenseEditClick.bind(this);
    this.onTableExpenseDeleteClick = this.onTableExpenseDeleteClick.bind(this);
    this.onTableExpenseUpdate = this.onTableExpenseUpdate.bind(this)
    this.onTableLoadMoreClick = this.onTableLoadMoreClick.bind(this);
    this.onTableExpenseActionClick = this.onTableExpenseActionClick.bind(this)
    this.onExpenseFiltersChange = this.onExpenseFiltersChange.bind(this)
    this.onExpenseSortValueChange = this.onExpenseSortValueChange.bind(this)
    this.onExpenseFormSubmit = this.onExpenseFormSubmit.bind(this)
    this.onExpenseSearchValueChange = this.onExpenseSearchValueChange.bind(this)
    this.onExpenseSearchSubmit = this.onExpenseSearchSubmit.bind(this)
    this.onExpenseClearFiltersChange = this.onExpenseClearFiltersChange.bind(this)
  }

  componentDidMount() {
    this.fetchExpenses(1);
  }

  fetchExpenses(page = 1) {
    const { contactId, projectId, supplierId } = this.props
    const { filters, sortValue, searchValue } = this.state

    let params = {
      page: page,
      search: searchValue,
      order: sortValue,
      ...filters,
    }

    if (contactId) params['contact_id'] = contactId
    if (projectId) params['project_id'] = projectId
    if (supplierId) params['supplier_id'] = supplierId

    this.setState({ isFetching: true })

    ExpensesController
      .getExpenses(params)
      .then(response => {
        const { expenses, current_page, total_pages } = response

        this.setState({
          expenses: [...expenses],
          didInitialLoad: true,
          currentPage: current_page,
          totalPages: total_pages,
          isFetching: false,
        });
      })
      .catch(console.error)
  }

  createExpense(expense: Expense) {
    return ExpensesController
      .create(expense)
      .catch(console.error)
  }

  updateExpense(expense: Expense, callback) {
    const { t } = this.props

    ExpensesController
      .update(expense)
      .then(response => {
        const { errors } = response;

        if (errors) {
          Notification.notifyError(t('Expenses::Oops something went wrong.'))
        }
        else {
          Notification.notifySuccess(t('Expenses::Expense successfully updated.'))
          callback(response);
        }

      })
      .catch(console.error)
  }

  deleteExpense(expense: Expense) {
    const { t } = this.props
    ExpensesController
      .delete(expense.id)
      .then(_response => {
        const { expenses } = this.state;

        const expenseIndex = expenses.findIndex(i => i.id === expense.id);

        expenses.splice(expenseIndex, 1);

        this.setState({
          expenses: expenses
        });

        Notification.notifySuccess(t('Expenses::Expense successfully removed.'))
      })
  }

  onNewExpensesClick() {
    const { showExpenseModal, contactId, projectId, supplierId } = this.props

    showExpenseModal({
      expense: {
        contact_id: contactId,
        project_id: projectId,
        supplier_id: supplierId,
      },
      contactDisabled: Boolean(contactId),
      projectDisabled: Boolean(projectId),
      supplierDisabled: Boolean(supplierId),
      onSubmit: this.onExpenseFormSubmit
    })
  }

  onTableExpenseClick(expense: Expense) {
    const { showExpenseModal } = this.props

    showExpenseModal({
      expense: {
        id: expense.id
      },
      onSubmit: this.onExpenseFormSubmit
    })
  }

  onTableExpensePayClick(expense: Expense) {
    const { t } = this.props

    let paymentProps: Payment = {}

    if (!expense.payment_id) paymentProps = ExpenseHelper.buildPaymentFromExpense(expense)

    this.props.showPaymentModal({
      payment: { id: expense.payment_id, ...paymentProps },
      onSubmit: async (payment) => {
        try {
          const expenseWithPayment = { ...expense, payment_id: payment.id }
          this.onExpenseFormSubmit(expenseWithPayment)
          await ExpensesController.update(expenseWithPayment)
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onTableExpenseEditClick(expense: Expense) {
    const { showExpenseModal } = this.props

    showExpenseModal({
      expense: {
        id: expense.id
      },
      onSubmit: this.onExpenseFormSubmit
    })
  }

  onTableExpenseDeleteClick(expense: Expense) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Expenses::Delete expense'),
      description: t('Expenses::You are about to delete an expense. This expense will be permanently deleted. Are you sure?'),
      action: { label: t('Expenses::Delete'), isDestructive: true },
      onConfirm: () => {
        this.deleteExpense(expense)
      }
    })
  }

  onTableExpenseUpdate(expense: Expense, index: number) {
    const { expenses } = this.state

    expenses[index] = expense

    this.setState({ expenses: [...expenses] })
  }

  onTableLoadMoreClick() {
    const { currentPage } = this.state;

    this.fetchExpenses(currentPage + 1);
  }

  onTableExpenseActionClick(key: string, expense: Expense) {
    switch (key) {
      case 'pay': this.onTableExpensePayClick(expense)
        break
      case 'edit': this.onTableExpenseEditClick(expense)
        break
      case 'delete': this.onTableExpenseDeleteClick(expense)
        break
      default:
        throw Error('[Track] Unimplemented onTableEntryActionClick')
    }
  }

  onExpenseFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchExpenses(1)
    })
  }

  onExpenseSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.EXPENSE_SORT_VALUE, value)

    this.setState({
      sortValue: value
    }, () => {
      this.fetchExpenses(1)
    })
  }

  onExpenseSearchValueChange(value: string) {
    this.setState({ searchValue: value })
  }

  onExpenseSearchSubmit(value: string) {
    this.setState({ searchValue: value }, () => this.fetchExpenses(1))
  }

  onExpenseClearFiltersChange() {
    this.setState({
      filters: {},
      searchValue: ''
    }, () => this.fetchExpenses(1))
  }

  isExpenseAllowed(expense: Expense) {
    const { contactId, projectId, supplierId } = this.props

    if (contactId && projectId) {
      return expense.project_id === projectId && expense.contact_id === contactId
    } else if (contactId) {
      return expense.contact_id === contactId
    } else if (supplierId) {
      return expense.supplier_id === supplierId
    } else {
      return true
    }
  }

  onExpenseFormSubmit(expense: Expense) {

    const { expenses } = this.state

    const expenseUpsertAllowed = this.isExpenseAllowed(expense)
    const expenseIndex = expenses.findIndex(e => e.id === expense.id)

    if (expenseIndex !== -1) {
      if (expenseUpsertAllowed) {
        // Update expense
        expenses[expenseIndex] = expense
        this.setState({
          expenses: [
            ...expenses,
          ]
        })
      } else {
        // Remove expense since search criteria has changed
        expenses.splice(expenseIndex, 1);

        this.setState({
          expenses: expenses
        });
      }
    } else if (expenseUpsertAllowed) {
      this.setState({
        expenses: [
          expense,
          ...expenses,
        ]
      })
    }
  }

  onAddExpenseTimelineClick(date: Moment) {
    const { showExpenseModal } = this.props

    showExpenseModal({
      expense: { invoiced_on: date },
      onSubmit: this.onExpenseFormSubmit
    })
  }

  render(): JSX.Element {
    const { currentUser: { workspace: { setting } }, t, contactId, projectId, supplierId, primaryActionEnabled } = this.props
    const { expenses, didInitialLoad, filters, sortValue, isFetching, searchValue, currentPage, totalPages } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{t('Expenses::{{__appName}} | Expenses')}</title>
        </Helmet>

        {!didInitialLoad && <ListLoader />}
        {didInitialLoad && <ResourceTable
          headers={[
            { title: t('ExpensesTable::Details') },
            { title: '' },
            { title: t('ExpensesTable::Supplier') },
            { title: t('ExpensesTable::Invoice date'), align: 'right' },
            { title: t('ExpensesTable::Due date'), align: 'right' },
            { title: t('ExpensesTable::Status'), align: 'right' },
            { title: t('ExpensesTable::Amount (excl. VAT)'), align: 'right' },
            { title: t('ExpensesTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('Expenses::New expense')}
              onClick={this.onNewExpensesClick}
            />
          ] : null}
          data={expenses}
          renderRow={(expense: Expense, index: number) => {
            return (
              <ExpenseResourceItemRow
                key={expense.id}
                expense={expense}
                onClick={this.onTableExpenseClick}
                dateFormat={setting.date_format}
                numberFormat={setting.number_format}
                onActionClick={this.onTableExpenseActionClick}
                onUpdate={(expense) => this.onTableExpenseUpdate(expense, index)}
              />
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'expense'}
            description={filtersActive ? t('Expenses::No expenses found') : t('Expenses::No expenses have been created yet')}
            descriptionActionText={filtersActive ? t('Expenses::Clear filters') : t('Expenses::Add expense')}
            onDescriptionActionClick={filtersActive ? this.onExpenseClearFiltersChange : this.onNewExpensesClick}
          />}

          filters={[
            { name: 'name', label: t('Expenses::Name'), type: ResourceListFilterType.STRING },
            {
              name: 'status',
              label: t('Expenses::Status'),
              type: ResourceListFilterType.SINGLE_OPTION,
              options: [
                { label: t('Expenses::Outstanding'), value: ExpenseStatus.OUTSTANDING },
                { label: t('Expenses::Overdue'), value: ExpenseStatus.OVERDUE },
                { label: t('Expenses::Paid'), value: ExpenseStatus.PAID },
              ]
            },
            { name: 'invoiced_on', label: t('Expenses::Invoice date'), type: ResourceListFilterType.DATE },
            { name: 'due_on', label: t('Expenses::Due date'), type: ResourceListFilterType.DATE },
            { name: 'net_total', label: t('Expenses::Amount (excl. VAT)'), type: ResourceListFilterType.NUMBER },
            { name: 'amount', label: t('Expenses::Amount'), type: ResourceListFilterType.NUMBER },
            { name: 'currency', label: t('Expenses::Currency'), type: ResourceListFilterType.STRING },
            { name: 'contact_id', label: t('Expenses::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', visible: !Boolean(contactId), isValidNewOption: () => false },
            { name: 'project_id', label: t('Expenses::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', visible: !Boolean(projectId), isValidNewOption: () => false },
            { name: 'supplier_id', label: t('Expenses::Supplier'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'company', visible: !Boolean(supplierId), isValidNewOption: () => false },
            { name: 'booked_on', label: t('Expenses::Booked on'), type: ResourceListFilterType.DATE },
            { name: 'paid_on', label: t('Expenses::Paid on'), type: ResourceListFilterType.DATE },
            { name: 'billable', label: t('Expenses::Billable'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Expenses::Yes'), value: String(true) }, { label: t('Expenses::No'), value: String(false) }] },
            { name: 'billed', label: t('Expenses::Billed'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Expenses::Yes'), value: String(true) }, { label: t('Expenses::No'), value: String(false) }] },
            { name: 'created_at', label: t('Expenses::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onExpenseFiltersChange}
          sortOptions={[
            { label: '-', value: '-' },
            { label: t('Expenses::Invoice date ↑'), value: 'invoiced_on_asc' },
            { label: t('Expenses::Invoice date ↓'), value: 'invoiced_on_desc' },
            { label: t('Expenses::Name (A-Z)'), value: 'name_asc' },
            { label: t('Expenses::Name (Z-A)'), value: 'name_desc' },
            { label: t('Expenses::Amount (excl. VAT) ↑'), value: 'net_total_asc' },
            { label: t('Expenses::Amount (excl. VAT) ↓'), value: 'net_total_desc' },
            { label: t('Expenses::Amount ↑'), value: 'amount_asc' },
            { label: t('Expenses::Amount ↓'), value: 'amount_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onExpenseSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchExpenses(page)}
          isLoading={isFetching}
          stickyHeader={true}
          searchValue={searchValue}
          onSearchChange={this.onExpenseSearchValueChange}
          onSearchSubmit={this.onExpenseSearchSubmit}
          maxHeight='65vh'
        />}
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showPaymentModal: (options) => dispatch(showPaymentModal(options)),
    showExpenseModal: (options) => dispatch(showExpenseModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Expenses))