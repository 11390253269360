import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { SignBlock as SignBlockType } from '../../../types'
import { EditBlockProps } from '../Block'
import SignatureCanvas from '../../Signature/SignatureCanvas'
import Icon from '../../Icons/Icon'

const Container = styled.div`
	background: white;
	padding: 30px;
	border-radius: ${Style.variables.baseBorderRadius};
`

const Title = styled.input`
	font-size: 22px;
	font-weight: 700;
	border: none !important;

	&:active, &:focus {
		box-shadow: none !important;
	}
`

const Description = styled.input`
	border: none !important;
	margin-bottom: ${Style.spacing.x1_5};

	&:active, &:focus {
		box-shadow: none !important;
	}
`

const ActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	svg {
		width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    left: 20px;
		fill: white;
		color: white;
	}
`

const Action = styled.input`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: fit-content !important;
	color: white !important;
	padding: ${Style.spacing.x1};
	background-color: ${Style.color.brandSuccess} !important;
	border: none !important;
	font-weight: bold !important;
	text-align: center !important;
	z-index: 1;
	padding-left: 40px !important;

	&:active, &:focus {
		box-shadow: none !important;
	}
`

type IProps = EditBlockProps<SignBlockType>

const SignBlock = (props: IProps) => {
	const { block } = props
	const [title, setTitle] = React.useState(block.title)
	const [description, setDescription] = React.useState(block.description)
	const [action, setAction] = React.useState(block.action)

	const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newTitle = e.target.value

		setTitle(newTitle)

		props.onChange({ ...block, title: e.target.value })
	}

	const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newDescription = e.target.value

		setDescription(newDescription)

		props.onChange({ ...block, description: e.target.value })
	}

	const onActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newAction = e.target.value

		setAction(newAction)

		props.onChange({ ...block, action: e.target.value })
	}

	return (
		<Container>
			<Title value={title} onChange={onTitleChange} />
			<Description value={description} onChange={onDescriptionChange} />
			<SignatureCanvas>
				<ActionContainer>
					<Icon icon='signature' />
					<Action value={action} onChange={onActionChange} />
				</ActionContainer>

			</SignatureCanvas>
		</Container>
	)
}

export default SignBlock