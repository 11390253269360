import * as React from 'react'
import Utils from '../../utilities/Utils'
import styled from 'styled-components'
import { Style } from '../../styles'


const Tooltip = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all ${Style.animations.baseTransitionSpeed} ease-in-out;
  opacity: 0;
  z-index: 1;
	border: 1px solid transparent;
`

const TooltipTitle = styled.span`
	font-weight: 600;
	font-size: 14px;
	color: white;
	margin-bottom: 4px;
	text-align: center;
	white-space: nowrap;
`

const TooltipDescription = styled.span`
	font-weight: 600;
	font-size: 13px;
	color: white;
	text-align: center;
	white-space: nowrap;
`

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
	transition: all ${Style.animations.baseTransitionSpeed} ease;

	&:hover, &.is-active {
    ${Tooltip} {
      display: flex;
      opacity: 1;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding: 0 8px;
`

const Title = styled.span`
	font-weight: 600;
	font-size: 14px;
	color: white;
	margin-bottom: 4px;
	text-align: center;
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
`

const Description = styled.span`
	font-weight: 600;
	font-size: 13px;
	color: white;
	text-align: center;
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
`

interface IProps {
	width: number
	color: string
	title: string
	description: string
}

interface IState {
	contentOutOfBounds: boolean
	tooltipActive: boolean
}

export default class ExpenseTimelineGraphItem extends React.Component<IProps, IState> {
	private container = React.createRef<HTMLDivElement>()
	private readonly MIN_WIDTH_TRESHHOLD = 4
	private title = React.createRef<HTMLDivElement>()
	private description = React.createRef<HTMLDivElement>()

	constructor(props: IProps) {
		super(props)

		this.state = {
			contentOutOfBounds: false,
			tooltipActive: false
		}

		this.onWindowResize = Utils.debounce(this.onWindowResize.bind(this), 250)
		this.onClickOutsideItem = this.onClickOutsideItem.bind(this);
		this.toggleTooltip = this.toggleTooltip.bind(this)
	}

	componentDidMount() {
		this.calculateDisplay()

		window.addEventListener('resize', this.onWindowResize)
		document.addEventListener('mousedown', this.onClickOutsideItem)

	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResize)
		document.removeEventListener('mousedown', this.onClickOutsideItem)
	}

	onWindowResize(e) {
		this.calculateDisplay()
	}

	onClickOutsideItem(e) {
		if (this.container.current && !this.container.current.contains(e.target)) {
			this.setState({ tooltipActive: false })
		}
	}

	toggleTooltip() {
		const { tooltipActive } = this.state
		this.setState({ tooltipActive: !tooltipActive })
	}

	isOverflown(element: HTMLDivElement) {
		return element.scrollWidth > element.clientWidth;
	}

	calculateDisplay() {
		const { width } = this.props

		if (this.title.current && this.description.current) {
			if (
				this.isOverflown(this.title.current) ||
				this.isOverflown(this.description.current) ||
				width < this.MIN_WIDTH_TRESHHOLD
			) {
				this.setState({ contentOutOfBounds: false })
			} else {
				this.setState({ contentOutOfBounds: true })
			}
		} else {
			this.setState({ contentOutOfBounds: false })
		}
	}

	render() {
		const {
			contentOutOfBounds,
			tooltipActive,
		} = this.state
		const {
			width,
			color,
			title,
			description,
		} = this.props

		return (
			<Container
				ref={this.container}
				className={`${tooltipActive ? 'is-active' : ''}`}
				style={{
					backgroundColor: color,
					maxWidth: `${width}%`,
				}}
				onClick={this.toggleTooltip}
			>
				{width >= this.MIN_WIDTH_TRESHHOLD && <Content>
					<Title ref={this.title} className='title'>{title}</Title>
					<Description ref={this.description} className='description'>{description}</Description>
				</Content>}

				{!contentOutOfBounds && <Tooltip style={{ backgroundColor: color }}>
					<TooltipTitle>{title}</TooltipTitle>
					<TooltipDescription>{description}</TooltipDescription>
				</Tooltip>}
			</Container>
		)
	}
}