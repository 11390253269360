import * as React from 'react'

interface IProps {
	className?: string
}

export default class BaseTooltip extends React.PureComponent<IProps> {
	static defaultProps = {
		className: ''
	}

	render () {
		const { className, children } = this.props

		return (
			<div className={`chart-tooltip-content ${className}`}>
				{children}
			</div>
		)
	}
}
