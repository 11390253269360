import * as React from 'react'
import { Switch, Redirect, Route } from 'react-router';
import ERoute from '../../ERoute';
import Info from './Company/CompanyInfo';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Company = () => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('Company::{{__appName}} | Company details')}</title>
      </Helmet>
      <ScrollToTopOnMount />

      <div className='content-tab-content'>
        <Switch>
          <Route path={ERoute.PATH_SETTINGS_COMPANY} component={Info} />
          <Redirect from='/' to={ERoute.PATH_SETTINGS_COMPANY} />
        </Switch>
      </div>
    </>
  )
}

export default Company