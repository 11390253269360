import styled from 'styled-components'
import EnterIcon from './EnterIcon'

export default styled.div`
	position: relative;
	display: flex;
	background: $white;
	cursor: pointer;
	padding: 10px 10px;
	flex-direction: row;
	align-items: center;
	border-bottom: 1px solid #EAEEF2;

	&:last-child {
		border-bottom: none;
	}

	i, svg {
		color: #36619a;
		fill: #36619a;
	}

	svg {
		width: 14px;
		height: 14px;
	}

	&:hover, &.is-selected {
		background: rgba(67,137,252,0.1);
		border-bottom-color: transparent;
	}

	&.is-selected {
		${EnterIcon} {
			display: flex;
		}
	}

	.highlight {
		font-weight: bold;
		color: black;
		border-radius: 3px;
	}
`