import * as React from 'react'
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'
import PinItem from './PinItem'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 284px;
	margin: 0 auto;
`

const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`



const Seperator = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 52px;
	max-height: 52px;
	margin-right: 16px;
	margin-left: 16px;

	&::after {
		position: absolute;
		width: 8px;
    height: 4px;
		border-radius: 4px;
		background-color: #e3e8ee;
		top: 50%;
		left: 50%;
		content: "";
		transform: translate(-50%, -50%);
	}
`

interface IProps {
	name: string
	focusOnMount?: boolean
}

interface IState {
	pin: string
}

export default class Pin extends React.Component<IProps, IState> {
	private pinItem0 = React.createRef<PinItem>()
	private pinItem1 = React.createRef<PinItem>()
	private pinItem2 = React.createRef<PinItem>()
	private pinItem3 = React.createRef<PinItem>()
	private pinItem4 = React.createRef<PinItem>()
	private pinItem5 = React.createRef<PinItem>()
	private submit = React.createRef<HTMLInputElement>()

	private values: string[]

	constructor(props) {
		super(props)

		this.state = {
			pin: ''
		}

		this.values = Array(6).fill('')

		this.onItemChange = this.onItemChange.bind(this)
		this.onBackspace = this.onBackspace.bind(this)
		this.onPaste = this.onPaste.bind(this)
	}

	componentDidMount () {
		const { focusOnMount } = this.props

		if (focusOnMount && this.pinItem1.current) {
			this.pinItem0.current.focus()
		}
	}

	onItemChange(value, index) {
    const nextIndex = index + 1

		this.values[index] = value
		
    if (value.length === 1 && nextIndex < 6) {
			const nextPinItem = this[`pinItem${nextIndex}`]

			if (nextPinItem.current) {
				nextPinItem.current.focus()
			}
    }

		const pin = this.values.join('')

		this.setState({ pin: pin }, () => {
			if (pin.length === 6) {
				this.submit.current.click()
			}
		})
  }

  onBackspace(index) {
    if (index > 0) {
			const previousPinItem = this[`pinItem${index-1}`]

      if (previousPinItem.current) {
				previousPinItem.current.focus()
			}
    }
	}

	onPaste (e) {
		const pasteValue = e.clipboardData.getData('text')
		const isNumber = /^\d+$/.test(pasteValue)

		if (pasteValue && pasteValue.length === 6 && isNumber) {
			pasteValue.trim().split('').forEach((value, index) => {
				const pinItem = this[`pinItem${index}`]

				if (pinItem.current) {
					pinItem.current.setState({ value: value })
				}
			})

			this.setState({
				pin: pasteValue,
			}, () => {
				this.submit.current.click()
			})
		}
	}

	render () {
		const { name } = this.props
		const { pin } = this.state

		return (
			<Container>
				<InputContainer>
					<PinItem
						ref={this.pinItem0}
						onChange={(v => this.onItemChange(v, 0))}
						onBackspace={() => this.onBackspace(0)}
						onPaste={this.onPaste}
					/>
					<PinItem
						ref={this.pinItem1}
						onChange={(v => this.onItemChange(v, 1))}
						onBackspace={() => this.onBackspace(1)}
						onPaste={this.onPaste}
					/>
					<PinItem
						ref={this.pinItem2}
						onChange={(v => this.onItemChange(v, 2))}
						onBackspace={() => this.onBackspace(2)}
						onPaste={this.onPaste}
					/>
				</InputContainer>

				<Seperator />

				<InputContainer>
					<PinItem
						ref={this.pinItem3}
						onChange={(v => this.onItemChange(v, 3))}
						onBackspace={() => this.onBackspace(3)}
						onPaste={this.onPaste}
					/>
					<PinItem
						ref={this.pinItem4}
						onChange={(v => this.onItemChange(v, 4))}
						onBackspace={() => this.onBackspace(4)}
						onPaste={this.onPaste}
					/>
					<PinItem
						ref={this.pinItem5}
						onChange={(v => this.onItemChange(v, 5))}
						onBackspace={() => this.onBackspace(5)}
						onPaste={this.onPaste}
					/>
				</InputContainer>

				<input name={name} style={{ display: 'none' }} value={pin} readOnly={true} />
				<input ref={this.submit} type='submit' style={{ display: 'none' }} />
			</Container>
		)
	}
}