import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../store';

import {
  createSequenceStep,
  deleteSequenceStep,
  setInitialSequenceStepEditorState,
  updateSequenceStep
} from '../../store/sequence-steps/actions';
import { showConfirmModal, showSequenceStepModal } from '../../store/modals/actions';
import { SequenceStep, CurrencyOption, CurrentUser, DisplayableError, Sequence, SequenceStepTypeInfo, SequenceChannelEvent, SequenceChannelEventType, SequenceStepType, SequenceMetadata } from '../../types';
import AddSequenceStep from '../../components/SequenceEditor/AddSequenceStep';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import DroppableHelper from '../../helpers/DroppableHelper';
import SequenceStepsController from '../../controllers/SequenceStepsController';
import Step from '../../components/SequenceEditor/Step';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-x: hidden;
`


interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  setInitialSequenceStepEditorState: typeof setInitialSequenceStepEditorState
  createSequenceStep: typeof createSequenceStep
  updateSequenceStep: typeof updateSequenceStep
  deleteSequenceStep: typeof deleteSequenceStep
  showConfirmModal: typeof showConfirmModal
  showSequenceStepModal: typeof showSequenceStepModal
}

type IProps = {
  sequenceId: string
  steps: SequenceStep[]
  editable: boolean
} & IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
}

class SequenceStepEditor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
    }

    this.onAddStepClick = this.onAddStepClick.bind(this)
    this.onEditStepClick = this.onEditStepClick.bind(this)
    this.onDeleteStepClick = this.onDeleteStepClick.bind(this)
    this.onStepChange = this.onStepChange.bind(this)

    this.onCreateSequenceStep = this.onCreateSequenceStep.bind(this)
    this.onUpdateSequenceStep = this.onUpdateSequenceStep.bind(this)
    this.onDeleteSequenceStep = this.onDeleteSequenceStep.bind(this)

    this.onBeforeDragStart = this.onBeforeDragStart.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.reorderSequenceStep = this.reorderSequenceStep.bind(this)
  }

  onCreateSequenceStep(step: SequenceStep) {
    this.props.createSequenceStep(step)
  }

  onUpdateSequenceStep(step: SequenceStep) {
    this.props.updateSequenceStep(step)
  }

  onDeleteSequenceStep(step: SequenceStep) {
    this.props.deleteSequenceStep(step.id)
  }

  onAddStepClick(step: SequenceStep) {
    const { sequenceId, steps } = this.props
    this.createStep({ ...step, sequence_id: sequenceId, delay: steps?.length === 0 ? 0 : 86400 })
  }

  onEditStepClick(step: SequenceStep, itemIndex?: number) {
    this.props.showSequenceStepModal({
      sequenceStep: step,
      index: itemIndex,
    })
  }

  onEditEmailStepClick(step: SequenceStep, emailIndex: number) {
    this.props.showSequenceStepModal({
      sequenceStep: step,
    })
  }

  onDeleteStepClick(step: SequenceStep) {
    this.deleteStep(step)
  }

  onStepChange(step: SequenceStep) {
    this.updateStep(step)
  }

  async createStep(step: SequenceStep) {
    const { sequenceId } = this.props
    try {
      const response = await SequenceStepsController.create(sequenceId, step)

      this.props.showSequenceStepModal({
        sequenceStep: { ...step, id: response.id },
        index: step.type === SequenceStepType.AUTOMATED_EMAIL ? 0 : undefined,
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  async updateStep(step: SequenceStep) {
    const { sequenceId } = this.props

    try {
      const response = await SequenceStepsController.update(sequenceId, step)
    } catch (ex) {
      console.error(ex)
    }
  }

  async deleteStep(step: SequenceStep) {
    const { sequenceId } = this.props
    try {
      const response = await SequenceStepsController.delete(sequenceId, step.id)
    } catch (ex) {
      console.error(ex)
    }
  }

  onBeforeDragStart() { }
  onDragStart() { }
  onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) return;

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    this.reorderSequenceStep(result)
  }

  reorderSequenceStep(result: DropResult) {
    const { sequenceId, steps } = this.props
    const { source, destination } = result
    // Get moved stepId
    const movedBlock = steps[source.index]
    const movedBlockId = movedBlock.id

    // Calculate the new step position based on the steps inside the list
    const newStepPosition = DroppableHelper.getNewPositionBasedOnDropResult(steps, result)
    const stepIndex = steps.findIndex(step => step.id === movedBlockId)

    if (stepIndex !== -1) {
      steps[stepIndex].position = newStepPosition

      // Get updated step
      const updatedSequenceStep: SequenceStep = steps[stepIndex]

      // Updated content step async
      SequenceStepsController.update(sequenceId, updatedSequenceStep).catch(console.error)

      // Set local tasks
      this.props.updateSequenceStep(updatedSequenceStep)
    }
  }


  render() {
    const { steps, editable } = this.props

    return (
      <Wrapper>
        <DragDropContext
          onBeforeDragStart={this.onBeforeDragStart}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <Droppable droppableId='steps' direction='vertical'>
            {(droppableProvided, droppableSnapshot) => {
              return (
                <div ref={droppableProvided.innerRef}>
                  {editable && steps?.length === 0 && <AddSequenceStep
                    key='add-step-top'
                    index={0}
                    steps={steps}
                    onAddClick={this.onAddStepClick}
                  />}

                  {steps?.map((step, index) => {
                    return (
                      <Step
                        key={step.id}
                        step={step}
                        steps={steps}
                        index={index}
                        editable={editable}
                        onAddStepClick={this.onAddStepClick}
                        onStepChange={this.onStepChange}
                        onEditClick={this.onEditStepClick}
                        onDeleteClick={this.onDeleteStepClick}
                      />
                    )
                  })}
                  {droppableProvided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  setInitialSequenceStepEditorState,
  createSequenceStep,
  updateSequenceStep,
  deleteSequenceStep,
  showConfirmModal,
  showSequenceStepModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SequenceStepEditor))