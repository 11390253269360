import * as React from 'react'
import TimeEntriesTable from '../TimeEntry/TimeEntriesTable'

interface IProps {
	contactId: string
}

const ContactTimesheets = (props: IProps) => {
	const { contactId } = props

	return (
		<TimeEntriesTable
			contactId={contactId}
			showDate={true}
		/>
	)
}

export default ContactTimesheets