import * as React from 'react'
import styled from 'styled-components'
import moment from '../../utilities/Moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import ResourceTableRow from '../Resource/ResourceTableRow'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import ExpenseAvatar from '../Avatar/ExpenseAvatar'
import ResourceTableRowActions from '../Resource/ResourceTableRowActions'
import { useTranslation } from 'react-i18next'
import ModalFooterActionIcon from '../Modals/Parts/ModalFooterAction'
import { Expense, MimeTypes } from '../../types'
import ExpenseHelper from '../../helpers/ExpenseHelper'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { ActiveStorageController, ExpensesController } from '../../controllers'
import Notification from '../../utilities/Notification'
import Badge from '../Badge/Badge'

interface IProps {
	expense: Expense
	dateFormat: string
	numberFormat: string
	onClick: (expense: Expense) => void
	onActionClick: (key: string, expense: Expense) => void
	onUpdate: (expense: Expense) => void
}

const CategoryContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	width: 100%;
`

const CategoryAvatarContainer = styled.div`
	margin-right: 8px;
`

const CategoryContent = styled.div`
	display: flex;
	flex-direction: column;
`

const ExpenseName = styled.div`
	font-size: 15px;
	color: #212b36;
	cursor: pointer;
	padding: 0 4px;

	max-width: 250px;
	text-overflow: ellipsis;
	overflow: hidden;
`

const CategoryName = styled.div`
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	flex: 1;
	flex-direction: row;
	padding: 0 4px;

	max-width: 265px;
	text-overflow: ellipsis;
	overflow: hidden;
`

const MetadataIcons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const ExpenseResourceItemRow = (props: IProps) => {
	const { t } = useTranslation()
	const { expense, dateFormat, numberFormat, onClick, onActionClick } = props

	const onAttachmentDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
		try {
			ActiveStorageController.upload(acceptedFiles[0], async (error, blob) => {
				if (error) { console.error(error) }
				if (blob) {
					const updatedExpense = await ExpensesController.update({ ...expense, attachment: blob.signed_id })

					Notification.notifySuccess(t('ExpenseResourceItemRow::Receipt successfully added.'))

					props.onUpdate(updatedExpense)
				}
			})
		} catch (ex) {
			console.error(ex)
		}

	}

	const { getRootProps } = useDropzone({
		onDrop: onAttachmentDrop,
		multiple: false,
		accept: ExpenseHelper.getExpenseDropzoneMimeTypes(),
	})

	let categoryStyles: { color?: string } = {}
	if (expense.category) {
		categoryStyles.color = expense.category.color
	}

	return (
		<ResourceTableRow key={expense.id}>
			<ResourceTableRowData {...getRootProps()} onClick={() => onClick(expense)}>
				<CategoryContainer>
					<CategoryAvatarContainer>
						<ExpenseAvatar expense={expense} rounded={true} onClick={() => onClick(expense)} />
					</CategoryAvatarContainer>

					<CategoryContent>
						{expense.category && <CategoryName style={categoryStyles}>
							{expense.category ? expense.category.name : ''}
						</CategoryName>}
						<ExpenseName title={expense.name}>
							{expense.name}
						</ExpenseName>
					</CategoryContent>
				</CategoryContainer>
			</ResourceTableRowData>

			<ResourceTableRowData {...getRootProps()} onClick={() => onClick(expense)}>
				<MetadataIcons>
					<ModalFooterActionIcon
						icon='attachment'
						tooltip={t('ExpenseResourceItemRow::Receipt attached')}
						active={Boolean(expense.attachment_url)}
						disabled={!Boolean(expense.attachment_url)}
						disabledTooltip={t('ExpenseResourceItemRow::Missing receipt')}
					/>
					{Boolean(expense.recurring_schedule) && <ModalFooterActionIcon
						icon='recurring'
						tooltip={t('ExpenseResourceItemRow::Recurring expense')}
						active={true}
					/>}
					{Boolean(expense.payment_id) && <ModalFooterActionIcon
						icon='money-bill'
						tooltip={t('ExpenseResourceItemRow::Payment scheduled')}
						active={true}
					/>}
					{expense.billable && <>
						<ModalFooterActionIcon
							icon='euro'
							tooltip={t('ExpenseResourceItemRow::Billable')}
							active={true}
						/>

						<ModalFooterActionIcon
							icon='invoice'
							tooltip={t('ExpenseResourceItemRow::Invoiced')}
							active={expense.billed}
							disabled={!expense.billed}
							disabledTooltip={t('ExpenseResourceItemRow::Waiting to be invoiced')}
						/>
					</>}
					{Boolean(expense.booked_on) && <ModalFooterActionIcon
						icon='book'
						tooltip={t('ExpenseResourceItemRow::Booked')}
						active={true}
					/>}
				</MetadataIcons>
			</ResourceTableRowData>

			<ResourceTableRowData {...getRootProps()} onClick={() => onClick(expense)} title={expense.supplier ? expense.supplier.name : '-'} maxWidth='200px' ellipse={true}>
				{expense.supplier ? expense.supplier.name : '-'}
			</ResourceTableRowData>
			<ResourceTableRowData textAlign='right' {...getRootProps()} onClick={() => onClick(expense)} >
				{moment(expense.invoiced_on).format(dateFormat)}
			</ResourceTableRowData>
			<ResourceTableRowData textAlign='right' {...getRootProps()} onClick={() => onClick(expense)} >
				{expense.due_on ? moment(expense.due_on).format(dateFormat) : '-'}
			</ResourceTableRowData>
			<ResourceTableRowData textAlign='right' {...getRootProps()} onClick={() => onClick(expense)} >
				{ExpenseHelper.getStatusBadge(expense)}
			</ResourceTableRowData>
			<ResourceTableRowData textAlign='right' {...getRootProps()} onClick={() => onClick(expense)} >
				{NumberFormatter.formatCurrency(expense.currency, numberFormat, expense.net_total)}
			</ResourceTableRowData>
			<ResourceTableRowData textAlign='right' {...getRootProps()} onClick={() => onClick(expense)} >
				{NumberFormatter.formatCurrency(expense.currency, numberFormat, expense.amount)}
			</ResourceTableRowData>

			<ResourceTableRowActions
				actions={[
					{ key: 'pay', icon: 'money-bill', content: expense?.payment_id ? t('Expenses::View payment') : t('Expenses::Queue payment') },
					{ key: 'edit', icon: 'edit-solid', content: t('Expenses::Edit') },
					{ key: 'delete', icon: 'trash-alt-solid', content: t('Expenses::Delete'), destructive: true }
				]}
				onActionClick={(key) => onActionClick(key, expense)}
				sticky={true}
				stickyRight='0px'
			/>
		</ResourceTableRow>
	)
}

export default ExpenseResourceItemRow