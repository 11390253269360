import * as React from 'react'
import LedgerItemsTable, { IComponentProps as LedgerItemsTableProps } from '../Invoices/LedgerItemsTable'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { RouteComponentProps } from 'react-router'
import { CurrentUser, LedgerItemType } from '../../types'

interface IStateToProps {
	currentUser: CurrentUser
}
type IProps = { type: LedgerItemType } & IStateToProps & RouteComponentProps<any>

class ContactLedgerItems extends React.Component<IProps> {
	render() {
		const { type } = this.props
		const { match: { params: { id } } } = this.props

		const ledgerItemsTableProps: LedgerItemsTableProps = {
			onItemDeleted: () => { },
			onItemUpdated: () => { },
			type: type,
			contactId: id,
			primaryActionEnabled: true
		}

		return (
			<LedgerItemsTable
				{...ledgerItemsTableProps}
			/>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

export default connect(mapStateToProps)(ContactLedgerItems)