import styled from 'styled-components'

const MenuEditorItemValue = styled.div`
  flex: 1;

  input {
    border: none !important;
    outline: none !important;
    border-radius: none !important;
    color: black;
    box-shadow: none !important;
    padding: 0 !important;
  }
`

export default MenuEditorItemValue