import styled from 'styled-components'

const MenuSectionHeader = styled.div`
  font-size: 16px;
  color: black;
  font-weight: bold;
  padding: 12px 16px 6px 16px;

  &:first-child {
    padding-top: 0;
  }
`

export default MenuSectionHeader