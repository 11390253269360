import * as React from 'react'
import moment from '../../utilities/Moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import ExpenseHelper from '../../helpers/ExpenseHelper'
import ExpenseCategoryHelper from '../../helpers/ExpenseCategoryHelper'

interface IProps {
}

export default class ExpenseTicketPlaceholder extends React.PureComponent<IProps> {
	render () {

		return (
			<div className='expense-ticket is-placeholder'>
				<div className='expense-ticket-content' style={{ borderTopColor: '#CCC' }}>
					<div className='expense-ticket-category'>
					</div>

					<div className='expense-ticket-supplier'>
					</div>

					<div className='expense-ticket-filler' />

					<div className='expense-ticket-amount'>
					</div>
				</div>
			</div>
		)
	}
}