import * as React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import CustomFieldHelper from '../../../../helpers/CustomFieldHelper'
import { Style } from '../../../../styles'
import { DropdownInputBlock } from '../../../../types'
import Button from '../../../Button/Button'
import CheckboxInput from '../../../Form/CheckboxInput'
import Icon from '../../../Icons/Icon'
import { EditBlockProps } from '../../Block'
import OptionsContainer from '../components/OptionsContainer'
import OptionInputContainer from '../components/OptionInputContainer'
import OptionInputDrag from '../components/OptionInputDrag'
import OptionInput from '../components/OptionInput'
import OptionRemove from '../components/OptionRemove'

type IProps = EditBlockProps<DropdownInputBlock>

const InputOptionsSection = (props: IProps) => {
	const { t } = useTranslation()
	const { block, onChange } = props
	const [allowMultipleSelection, setAllowMultipleSelection] = React.useState(block.allow_multiple_selection)
	const [options, setOptions] = React.useState(block.options || [])

	const debouncedOptionsChange = useDebouncedCallback(
		(newOptions) => onChange({ ...block, options: newOptions }), 250
	);

	React.useEffect(() => {
		setAllowMultipleSelection(block.allow_multiple_selection)
		setOptions(block.options)
	}, [block.allow_multiple_selection, block.options])

	const onAllowMultipleSelectionChange = () => {
		const newAllowMultipleSelection = !block.allow_multiple_selection

		setAllowMultipleSelection(newAllowMultipleSelection)

		onChange({ ...block, allow_multiple_selection: newAllowMultipleSelection })
	}

	const onOptionChange = (newOptionName: string, index: number) => {
		const newOptions = [...options]

		newOptions[index] = newOptionName

		// Set local
		setOptions(newOptions)

		// Update remote
		debouncedOptionsChange(newOptions)
	}

	const onOptionRemoveClick = (index: number) => {
		const newOptions = [...options]

		newOptions.splice(index, 1);

		// Set local
		setOptions(newOptions)

		// Update remote
		debouncedOptionsChange(newOptions)
	}

	const onAddOptionClick = () => {
		const newOptions = [...options, '']

		// Set local
		setOptions(newOptions)

		// Update remote
		debouncedOptionsChange(newOptions)
	}

	const onOptionsDragEnd = (dropResult: DropResult, provided: ResponderProvided) => {
		const { destination, source, draggableId } = dropResult

		if (!destination) return

		if (
			destination.droppableId !== source.droppableId &&
			destination.index !== source.index
		) {
			return
		}

		const updatedOptions = [...options]
		const movedOption = options[source.index]

		updatedOptions.splice(source.index, 1)
		updatedOptions.splice(destination.index, 0, movedOption)

		// Set local options
		setOptions(updatedOptions)

		// Update remote
		debouncedOptionsChange(updatedOptions)
	}

	return (
		<div className='grid'>
			<div className='grid-cell with-6col'>
				<div className='form-item'>
					<label>
						{t('InputBlock::Allow multiple')}
					</label>

					<CheckboxInput
						label={t('InputBlock::Multiple selection')}
						checked={allowMultipleSelection}
						onChange={onAllowMultipleSelectionChange}
					/>
				</div>
			</div>

			<div className='grid-cell with-12col'>
				<div className='form-item'>
					<label>{t('InputBlock::Options')}</label>
					<DragDropContext onDragEnd={onOptionsDragEnd}>
						<Droppable droppableId={`options-${block.name}`}>
							{(provided) => {
								return (
									<OptionsContainer ref={provided.innerRef} {...provided.droppableProps}>
										{options.map((option, index) => {
											return (
												<Draggable key={index} draggableId={String(index)} index={index}>
													{(provided) => {
														return (
															<OptionInputContainer {...provided.draggableProps} ref={provided.innerRef}>
																<OptionInputDrag {...provided.dragHandleProps}>
																	<Icon icon='grip-vertical' />
																</OptionInputDrag>
																<OptionInput
																	type='text'
																	value={option}
																	onChange={(e) => onOptionChange(e.currentTarget.value, index)}
																	placeholder={t('OptionsSection::Option {{index}}', { index: index + 1 })}
																/>
																<OptionRemove className='button-icon button-danger' onClick={() => onOptionRemoveClick(index)}>
																	<Icon icon='close' />
																</OptionRemove>
															</OptionInputContainer>
														)
													}}
												</Draggable>
											)
										})}
									</OptionsContainer>
								)
							}}
						</Droppable>
					</DragDropContext>
				</div>
			</div>

			<div className='grid-cell with-12col'>
				<Button
					type='success'
					icon='plus'
					onClick={onAddOptionClick}
					text={t('OptionsSection::Add option')}
				/>
			</div>
		</div>
	)

}

export default InputOptionsSection