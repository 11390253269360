import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const TaskTitle = styled.div<{ onClick?: () => void }>`
	width: 100%;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${props => props.onClick && css`
		cursor: pointer;
	`}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
	}
`

export default TaskTitle