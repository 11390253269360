import * as React from 'react'
import styled from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 30px;
	min-width: 30px;
	background: transparent;
	color: #82828c; 
	border-radius: 4px;
	transition: background-color 150ms ease-in-out;
	cursor: pointer;


	i {
		font-size: 18px;
		opacity: .83;
	}

	svg {
		fill: currentColor;
		width: 18px;
		opacity: .83;
	}

	&:hover {
		background-color: hsla(240,4%,53%,.18);
	}
`

interface IProps {
	icon: IconTypes
	onClick?: () => void
}

const PanelAction = React.forwardRef<HTMLDivElement, IProps>((props: IProps, ref) => {
	const { icon, onClick } = props

	return (
		<Container onClick={onClick} ref={ref}>
			<Icon icon={icon} />
		</Container>
	)
})

export default PanelAction