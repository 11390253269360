import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../../styles'
import NumberFormatter from '../../../utilities/NumberFormatter'

const Tooltip = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	visibility: hidden;
	opacity: 0;
	background: ${Style.color.brandSuccess}; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: 5;
`

const TooltipWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
`

const TooltipArrow = styled.div<{ color: string }>`
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-width: 10px;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

	${(props) => css`
			border-top-color: ${props.color};
	`}
`

const Title = styled.div`
	font-weight: bold;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 300px;
	display: block;
	overflow: hidden;
`

const CollectedRevenue = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	i {
		font-size: 12px;
	}
`

export const MOUSEMOVE_COLLECTED_REVENUE_TIMELINE = 'mousemove-collected-revenue-timeline-item'
export const MOUSELEAVE_COLLECTED_REVENUE_TIMELINE = 'mouseleave-collected-revenue-timeline-item'

interface IProps {
	currency: string
	numberFormat: string
}

const YearlyCollectedRevenueGoalTooltip = (props: IProps) => {
	const { t } = useTranslation()
	const { currency, numberFormat } = props
	const tooltip = React.useRef<HTMLDivElement>()
	const [state, setState] = React.useState<{ collectedRevenue: number, x: number, y: number } | null>(null)

	const setTooltip = (data) => {
		const { detail: { collectedRevenue, x, y } } = data

		if (collectedRevenue && x && y) {
			setState({ collectedRevenue, x, y })
		} else {
			setState(null)
		}
	}

	React.useEffect(() => {
		document.addEventListener(MOUSEMOVE_COLLECTED_REVENUE_TIMELINE, setTooltip)
		document.addEventListener(MOUSELEAVE_COLLECTED_REVENUE_TIMELINE, setTooltip)

		return () => {
			document.removeEventListener(MOUSEMOVE_COLLECTED_REVENUE_TIMELINE, setTooltip)
			document.removeEventListener(MOUSELEAVE_COLLECTED_REVENUE_TIMELINE, setTooltip)
		}
	}, [state])

	if (!state) return null

	const { collectedRevenue, x, y } = state

	let tooltipWidth = null

	if (tooltip.current) {
		tooltipWidth = tooltip.current.clientWidth
	}

	return (
		<Tooltip
			ref={tooltip}
			style={{
				left: `calc(${x}px - ${tooltipWidth / 2}px)`,
				top: `calc(${y}px - 80px)`,
				opacity: collectedRevenue && tooltipWidth ? 1 : 0,
				visibility: collectedRevenue && tooltipWidth ? 'visible' : 'hidden',
				background: Style.color.brandSuccess,
			}}
		>
			<TooltipWrapper>
				<Title>{t('YearlyCollectedRevenueGoalTooltip::Collected revenue')}</Title>
				<CollectedRevenue>
					{NumberFormatter.formatCurrency(currency, numberFormat, collectedRevenue)}
				</CollectedRevenue>

				<TooltipArrow color={Style.color.brandSuccess} />
			</TooltipWrapper>
		</Tooltip>
	)
}

export default YearlyCollectedRevenueGoalTooltip