import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import { useTranslation } from 'react-i18next'
import Icon from '../components/Icons/Icon'
import ContentableTemplateItem from '../components/ContentTemplate/ContentTemplateItem'
import TabItem from '../components/Navigation/TabItem'
import TabItems from '../components/Navigation/TabItems'
import BlocksRenderer from '../components/BlockEditor/BlocksRenderer'
import { useSelector } from 'react-redux'
import { AppState } from '../store'
import Button from '../components/Button/Button'
import { RouteComponentProps } from 'react-router-dom'
import { ContractsController, ResourceContentBlocksController } from '../controllers'
import { ContentBlockResource, ContentBlockType, Contract } from '../types'
import RouteHelper from '../helpers/RouteHelper'
import ERoute from '../ERoute'
import ContractHelper, { ContractTemplate } from '../helpers/Contracthelper'
import UrlHelper from '../helpers/UrlHelper'
import NavigationToggle from '../components/ContentTemplate/NavigationToggle'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  min-height: 56px;
  width: 100%;
  background: #1e3946;
  padding: 0 ${Style.spacing.x2_5};
`

const HeaderBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: white;
  fill: white;
  font-weight: bold;

  i {
    font-size: 20px;
    margin-right: ${Style.spacing.x1};
  }

  span {
    &:hover {
      text-decoration: underline;
    }
  }
`

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: calc(100% - 56px);
`

const ToggleSidebarContainer = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 85px;
  z-index: 2;
  right: 462px;
  display: none;
  left: 0;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: flex;

    ${props => props.active && css`
      top: 75px;
      left: initial;
      right: 25px;
  `}
  }
`

const Sidebar = styled.div<{ active?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid ${Style.color.border};
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 550px;
  max-width: 550px;
  width: 550px;
  background: white;  
  padding: ${Style.spacing.x2_5};
  z-index: 1;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    position: absolute;
    top: 0;
    left: -550px;
    bottom: 0;

    ${props => props.active && css`
      left: 0;
      width: 100%;
      min-width: initial;
      max-width: initial;
    `}
  }
`

const SidebarTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #1e3946;
  position: sticky;
  background: white;
  top: -20px;
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f7f7fa;
  padding: ${Style.spacing.x2_5};
`

const InfoContainer = styled.div`
  background: white;
  width: 100%;
  max-width: 800px;
`

const InfoContent = styled.div`
  padding: 32px 28px;
`

type IProps = RouteComponentProps<{ contact_id?: string, project_id?: string }>

interface IState {
  navigationActive: boolean
  activeContractTemplate: ContractTemplate
}

const ContractsCreate = (props: IProps) => {
  const { t } = useTranslation()
  const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
  const { workspace: { setting } } = currentUser
  const contractTemplateCategories = ContractHelper.getContractTemplateCategories()
  const [state, setState] = React.useState<IState>({
    navigationActive: false,
    activeContractTemplate: contractTemplateCategories[0].templates[0],
  })
  const { navigationActive, activeContractTemplate } = state

  const onBackClick = () => {
    props.history.goBack()
  }

  const onCreateContractClick = async () => {
    const queryParams = UrlHelper.getParams(props.location.search)

    try {
      const contract: Contract = await ContractsController.create({
        name: activeContractTemplate.title,
        contact_id: queryParams.contact_id,
        project_id: queryParams.project_id,
      })

      const signBlock = contract.content_blocks.find(block => block.type === ContentBlockType.SIGN)

      let index = 1
      for (const block of activeContractTemplate.contentBlocks.reverse()) {
        await ResourceContentBlocksController.create(ContentBlockResource.CONTRACT, contract.id, { ...block, position: signBlock.position / (index + 1) })
        index++
      }

      props.history.replace(RouteHelper.process(ERoute.PATH_CONTRACT, { id: contract.id }))
    } catch (ex) {
      console.error(ex)
    }
  }

  const onToggleTemplateClick = () => {
    setState({ ...state, navigationActive: !state.navigationActive })
  }

  const onContractTemplateClick = (contractTemplate: ContractTemplate) => {
    setState({ ...state, navigationActive: false, activeContractTemplate: contractTemplate })
  }

  return (
    <Container>
      <Header>
        <HeaderBackButton onClick={onBackClick}>
          <Icon icon='chevron-left' />
          <span>{t('ContractsCreate::Back')}</span>
        </HeaderBackButton>

        <Button
          text={t('ContractsCreate::Create contract')}
          onClick={onCreateContractClick}
          type='success'
        />
      </Header>
      <ToggleSidebarContainer active={navigationActive}>
        <NavigationToggle onClick={onToggleTemplateClick}>
          <Icon icon={navigationActive ? 'chevrons-left' : 'chevrons-right'} />
        </NavigationToggle>
      </ToggleSidebarContainer>
      <ContentWrapper>
        <Sidebar active={navigationActive}>
          {contractTemplateCategories.map((category, index) => {
            return (
              <>
                <SidebarTitle>
                  {category.title}
                </SidebarTitle>

                {category?.templates?.map((template, index) => {
                  return (
                    <ContentableTemplateItem
                      key={index}
                      icon={template.icon}
                      title={template.title}
                      description={template.description}
                      onClick={() => onContractTemplateClick(template)}
                      active={activeContractTemplate.title === template.title}
                    />
                  )
                })}
              </>
            )
          })}
        </Sidebar>
        <Content>
          <InfoContainer>
            <InfoContent>
              <BlocksRenderer
                blocks={activeContractTemplate?.contentBlocks || []}
                variables={{}}
                currency='EUR'
                numberFormat={setting.number_format}
              />
            </InfoContent>
          </InfoContainer>
        </Content>
      </ContentWrapper>
    </Container>
  )
}

export default ContractsCreate