import { Board, BoardLabel, BoardList, Task } from '../../types'
import {
	BoardActionTypes,
	SET_INITIAL_BOARD_STATE,
	BOARD_CREATE,
	BOARD_UPDATE,
	BOARD_DELETE,
	BOARD_LABEL_CREATE,
	BOARD_LABEL_UPDATE,
	BOARD_LABEL_DELETE,
	BOARD_LIST_CREATE,
	BOARD_LIST_UPDATE,
	BOARD_LIST_DELETE,
	TASK_CREATE,
	TASK_UPDATE,
	TASK_DELETE,
	BoardState,
	SET_BOARD,
} from './types'

export function setInitialBoardState(state: BoardState): BoardActionTypes {
	return {
		type: SET_INITIAL_BOARD_STATE,
		state: state,
	}
}

export function setBoard(board: Board): BoardActionTypes {
	return {
		type: SET_BOARD,
		board: board,
	}
}

export function createBoard(board: Board): BoardActionTypes {
	return {
		type: BOARD_CREATE,
		board: board,
	}
}

export function updateBoard(board: Board): BoardActionTypes {
	return {
		type: BOARD_UPDATE,
		board: board
	}
}

export function deleteBoard(id: string): BoardActionTypes {
	return {
		type: BOARD_DELETE,
		id: id,
	}
}

export function createBoardLabel(boardLabel: BoardLabel): BoardActionTypes {
	return {
		type: BOARD_LABEL_CREATE,
		boardLabel: boardLabel,
	}
}

export function updateBoardLabel(boardLabel: BoardLabel): BoardActionTypes {
	return {
		type: BOARD_LABEL_UPDATE,
		boardLabel: boardLabel,
	}
}

export function deleteBoardLabel(id: string): BoardActionTypes {
	return {
		type: BOARD_LABEL_DELETE,
		id: id,
	}
}

export function createBoardList(boardList: BoardList): BoardActionTypes {
	return {
		type: BOARD_LIST_CREATE,
		boardList: boardList,
	}
}

export function updateBoardList(boardList: BoardList): BoardActionTypes {
	return {
		type: BOARD_LIST_UPDATE,
		boardList: boardList,
	}
}

export function deleteBoardList(id: string): BoardActionTypes {
	return {
		type: BOARD_LIST_DELETE,
		id: id,
	}
}

export function createBoardTask(task: Task): BoardActionTypes {
	return {
		type: TASK_CREATE,
		task: task,
	}
}

export function updateBoardTask(task: Task): BoardActionTypes {
	return {
		type: TASK_UPDATE,
		task: task,
	}
}

export function deleteBoardTask(id: string): BoardActionTypes {
	return {
		type: TASK_DELETE,
		id: id,
	}
}