import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { Container } from './TableSortButton'

const TableCell = styled.div<{ onClick?: () => void }>`
	position: relative;
	display: table-cell;
	min-width: 120px;
	max-width: 500px;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid;
	border-right: 1px solid;
	border-color: ${Style.color.border};
	background: white;
	overflow: hidden;
	white-space: nowrap;
  text-overflow: ellipsis;

	${props => props.onClick && css`
		cursor: pointer;
	`}

	&:last-child {
		border-right: none;
	}

	&:hover {
		${Container} {
			opacity: 1;
			pointer-events: all;
		}
	}
`

export default TableCell