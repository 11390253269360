import * as React from 'react'
import NumberFormatter from '../../utilities/NumberFormatter'
import ExpenseTimelineGraphItem from './ExpenseTimelineGraphItem'
import { ExpensesController } from '../../controllers'
import Loader from '../Loaders/Loader'
import * as numeral from 'numeral'
import styled from 'styled-components'
import { Style } from '../../styles'
import { Settings } from '../../types'

interface IProps {
  setting: Settings
}

interface IState {
  didInitialLoad: boolean
  isLoading: boolean
  categories: { color: string, id: string, name: string, amount: string }[]
}

const Container = styled.div`
  position: relative;
  padding: 20px 0;
  margin: 20px 0 40px 0;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 25px 16px;
  }

  &::-webkit-scrollbar { 
    display: none; 
	}
`

const Wrapper = styled.div`
  position: relative;
  background: white;
  height: 90px;
  width: 100%;
	margin: 0 auto;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    width: 1100px;
  }
`

const Graph = styled.div`
  height: 100%;
`

const GraphItems = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
	flex-direction: row;
	z-index: 1;
	background: white;
`

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const GraphTicks = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`


const GraphTickLine = styled.div`
	height: 10px;
	width: 2px;
	background-color: #5886c469;
`

const GraphTick = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	pointer-events: none;
	flex-direction: column;
	width: 0;

	&:first-child, &:last-child {
		margin-top: -10px;

		${GraphTickLine} {
			height: 20px;
		}
	}

	&:first-child {
		${GraphTickLine} {
			margin-right: -1px;
		}
	}

	&:last-child {
		${GraphTickLine} {
			margin-left: -2px;
		}
	}
`

const GraphTickLabel = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	font-weight: 500;
`

const GraphTickLabelLine = styled.span`
	font-size: 14px;
	line-height: 20px;
	color: #5886c4;
	white-space: nowrap;
`

export default class ExpenseYearTimeline extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      isLoading: false,
      categories: [],
    }
  }

  componentDidMount() {
    this.fetchTimeline()
  }

  fetchTimeline() {
    this.setState({ isLoading: true })
    ExpensesController
      .getTimeline('yearly')
      .then(categories => {
        this.setState({
          didInitialLoad: true,
          isLoading: false,
          categories: categories,
        })
      })
      .catch(console.error)
  }

  refresh() {
    this.fetchTimeline()
  }

  renderGraphItems() {
    const { setting } = this.props
    const { categories } = this.state
    const maxTickCount = 10
    let totalExpenseAmount = categories.reduce((total, category) => Number(total) + Number(category.amount), 0)
    totalExpenseAmount = Math.max(10, totalExpenseAmount)

    const tickAmount = totalExpenseAmount / maxTickCount
    const ticks = Array(maxTickCount + 1).fill(0)

    return (
      <>
        <GraphItems>
          {categories.map((expenseCategory, index) => {
            const expenseCategoryWidth = Number(expenseCategory.amount) / totalExpenseAmount * 100

            return (
              <ExpenseTimelineGraphItem
                key={`${index}-${expenseCategory.name}`}
                color={expenseCategory.color}
                width={expenseCategoryWidth}
                title={expenseCategory.name}
                description={`betaald ${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, expenseCategory.amount)}`}
              />
            )
          })}
        </GraphItems>

        <GraphTicks>
          {ticks.map((_tick, index) => {
            const indexTickAmount = index * tickAmount

            let formattedCurrency = `${NumberFormatter.getCurrencySymbol(setting.default_currency)}${numeral(indexTickAmount).format('0.0a')}`

            return (
              <GraphTick key={index} style={{ left: `${(100 / maxTickCount) * index}%` }}>
                <GraphTickLine />
                <GraphTickLabel>
                  <GraphTickLabelLine>
                    {formattedCurrency}
                  </GraphTickLabelLine>
                </GraphTickLabel>
              </GraphTick>
            )
          })}
        </GraphTicks>
      </>
    )
  }

  render() {
    const {
      didInitialLoad,
      isLoading,
    } = this.state

    return (
      <Container>
        <Wrapper>
          <Graph>
            {(!didInitialLoad || isLoading) && <LoadingOverlay>
              <Loader size='medium' />
            </LoadingOverlay>}
            {didInitialLoad && this.renderGraphItems()}
          </Graph>
        </Wrapper>
      </Container>
    )
  }
}