import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import TopNavigation from '../../components/Navigation/TopNavigation'
import { ActiveStorageController, InvoicesController, SettingsController, WorkspaceController } from '../../controllers'
import ERoute from '../../ERoute'
import Timeline, { TimelineStep } from '../../components/Timeline/Timeline'
import moment from '../../utilities/Moment'
import Notification from '../../utilities/Notification'
import RouteHelper from '../../helpers/RouteHelper'
import Select from '../../components/Form/Select'
import NumberFormatter from '../../utilities/NumberFormatter'
import Button from '../../components/Button/Button'
import { Link } from 'react-router-dom'
import Sound, { Sounds } from '../../sounds'
import { showSendLedgerItemModal, showContactModal, showConfirmModal, showProjectStatusUpdateModal, showLedgerItemPaymentModal, showLedgerItemTransactionHistoryModal, showProjectModal, showledgerItemClaimModal, showAttachmentsViewerModal } from '../../store/modals/actions'
import { updateSettings } from '../../store/authentication/actions'
import PageHeader from '../../components/Page/PageHeader'
import Tooltip from '../../components/Tooltips/Tooltip'
import LedgerItemHelper from '../../helpers/LedgerItemHelper'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import UrlHelper from '../../helpers/UrlHelper'
import DocumentPreview from '../../components/LedgerItem/DocumentPreview'
import DocumentWrapper from '../../components/LedgerItem/DocumentWrapper'
import LedgerItemPreviewNavigation from '../../components/LedgerItem/LedgerItemPreviewNavigation'
import { Style } from '../../styles'
import styled, { css } from 'styled-components'
import Avatar from '../../components/Avatar/Avatar'
import PageLoader from '../../components/Page/PageLoader'
import RecurringScheduleHelper from '../../helpers/RecurringScheduleHelper'
import PageContent from '../../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { IActionListItem } from '../../components/ActionList/ActionList'
import Switch from '../../components/Form/Switch'
import ProjectHelper from '../../helpers/ProjectHelper'
import Icon from '../../components/Icons/Icon'
import MobilityHelper from '../../helpers/MobilityHelper'
import { CurrentUser, LedgerCondition, LedgerItem, LedgerItemData, LedgerItemPayment, LedgerItemType, PeppolWorkspaceStatus, PreviewAttachment, Project, ProjectStatus, Settings, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../../types'
import VATHelper from '../../helpers/VatHelper'
import PanelAction from '../../components/Panel/PanelAction'
import Alert from '../../components/Alert/Alert'
import Badge from '../../components/Badge/Badge'
import Dropzone from '../../components/Dropzone/Dropzone'
import { DropEvent, FileRejection } from 'react-dropzone'
import EmailAttachment, { EmailAttachmentContainer } from '../../components/EmailPreview/EmailAttachment'
import Utils from '../../utilities/Utils'
import ReactTooltip from 'react-tooltip'
import ButtonDropdown from '../../components/Button/ButtonDropdown'
import ButtonDropdownOption from '../../components/Button/ButtonDropdownOption'
import ActionCableConsumer from '../../consumers/ActionCableConsumer'
import { saveAs } from 'file-saver'
import PaymentHelper from '../../helpers/PaymentHelper'

const PeppolBadgeContainer = styled.div`
  cursor: pointer;
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchProps {
  showSendLedgerItemModal: typeof showSendLedgerItemModal
  showProjectStatusUpdateModal: typeof showProjectStatusUpdateModal
  updateSettings: typeof updateSettings
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showConfirmModal: typeof showConfirmModal
  showLedgerItemPaymentModal: typeof showLedgerItemPaymentModal
  showLedgerItemTransactionHistoryModal: typeof showLedgerItemTransactionHistoryModal
  showledgerItemClaimModal: typeof showledgerItemClaimModal
  showAttachmentsViewerModal: typeof showAttachmentsViewerModal
}

type IProps = IStateToProps & IDispatchProps & RouteComponentProps<any> & WithTranslation

interface IState {
  invoice: LedgerItem | null
  payments: LedgerItemPayment[]
  ledgerItemVariables: any | null
  conditions: LedgerCondition[]
  didInitialLoad: boolean
  isPreviewLoading: boolean
  currentPage: number
  pageCount: number
  orderFormMetadata?: {
    delivery_note?: LedgerItem,
    invoice?: LedgerItem
  }
  deliveryNoteMetadata?: {
    order_form?: LedgerItem,
    invoice?: LedgerItem
  }
  quotationMetadata?: {
    invoices: LedgerItem[],
    amount_invoiced: string,
    unbilled_amount: string
  },
  proFormaInvoiceMetadata?: {
    invoices: LedgerItem[],
    amount_invoiced: string,
    unbilled_amount: string
  },
  invoiceMetadata?: {
    order_form?: LedgerItem
    delivery_note?: LedgerItem
    quotation?: LedgerItem,
    pro_forma_invoice?: LedgerItem
    amount_invoiced: string,
    unbilled_amount: string
  }
  creditNoteMetadata?: { invoice?: LedgerItem }
  sendingPeppol: boolean
}

const PreviewContainer = styled.div`
	.grid {
		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			display: flex;
			flex-direction: column-reverse;
		}
	}
`

const InvoiceDetailsContainer = styled.div`
	background: white;
	border: 1px solid ${Style.color.border};
	border-radius: ${Style.variables.baseBorderRadius};
	margin-bottom: ${Style.spacing.x3};
`

const InvoiceDetailsHeader = styled.div`
	padding: ${Style.spacing.x2};
`

const InvoiceContactAndAmountDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${Style.spacing.x2};
	flex-wrap: wrap;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const InvoiceContactContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
  justify-content: center;
	flex: 1;
`

const InvoiceContactDetails = styled.div`
	display: flex;
	flex-direction: column;
  margin-top: ${Style.spacing.x1};
  justify-content: center;
  align-items: center;

	h3 {
		font-size: 16px;
		color: #040C3F;
		font-weight: bold;
		cursor: pointer;
    text-align: center;

		&:hover {
			text-decoration: underline;
		}
	}

	p {
		font-size: 14px;
		color: #A7ABB6;
    text-align: center;

		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
`

const InvoiceAmount = styled.div`
	font-size: 25px;
	font-weight: bold;
	flex: 1;
	text-align: right;
	white-space: nowrap;
`

const InvoiceDetailsActions = styled.div`
	display: flex;
	flex-direction: column;

	> * {
		width: 100%;
	}

	> *:not(:last-child) {
		margin-bottom: ${Style.spacing.x1};

		a {
			width: 100%;
		}
	}
`

const InvoiceDetailsContent = styled.div`
	position: relative;
	padding: ${Style.spacing.x2};

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 1px;
		background: #ebedf2;
	}
`

const InvoiceDetailsContentHeader = styled.div`
	font-weight: 600;
	color: #040C3F;
	margin-bottom: ${Style.spacing.x1};
`

const InvoiceDetailsList = styled.ul`
	display: flex;
	flex-direction: column;
`

const LedgerItemMetadata = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    margin-bottom: ${Style.spacing.x2};
  }
`

const LedgerItemMetadataItem = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  padding: ${Style.spacing.x0_5} ${Style.spacing.x2};
  border: 1px solid ${Style.color.border};
  margin-bottom: ${Style.spacing.x1};
  border-radius: 8px;
  transition: transform 50ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`

const LedgerItemMetadataItemNumber = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: ${Style.spacing.x1};
`

const LedgerItemMetadataAmount = styled.div`
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const LedgerItemMetadataAction = styled.div<{ type: 'warning' | 'danger' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${Style.spacing.x1};
  padding: ${Style.spacing.x1} ${Style.spacing.x1};
  border-radius: .25rem;
  border: 1px solid;
  cursor: pointer;
  transition: transform 50ms ease-in-out;

  svg, i {
    margin-right: ${Style.spacing.x1};
  }

  &:hover {
    transform: scale(1.05);
  }

  ${props => props.type === 'warning' && css`
    background: rgba(255, 179, 90, 0.19);
    color: ${Style.color.brandWarning};
    border-color: #ffecb5;
  `}

  ${props => props.type === 'danger' && css`
    background: #f8d7da;
    color: ${Style.color.brandDanger};
    border-color: #f5c2c7;

  `}
`

interface InvoiceDetailsListItemProps {
  vertical?: boolean
  valueLight?: boolean
}
const InvoiceDetailsListItem = styled.li<InvoiceDetailsListItemProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	label {
		font-size: 14px;
		text-transform: none;
	}

	label:first-child {
		color: #404653;
		font-weight: 500;
	}

	label:last-child {
		display: flex;
		justify-content: flex-end;
		color: #00022A;
		font-weight: bold;


		${(props) => props.valueLight && css`
			color: #637381;
			font-weight: normal;
			word-break: break-word;
			white-space: pre-wrap;
		`}
	}

	${(props) => props.vertical && css`
		flex-direction: column;
		align-items: flex-start;
	`}
`

const DocumentInnerWrapper = styled.div`
	position: relative;
	width: 100%;
`

const SidebarActions = styled.div`
  display: flex;
  flex-direction: column;
`

const SidebarAction = styled.a`
  color: ${Style.color.brandPrimary};
  font-weight: bold;
  padding: 4px 0;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid ${Style.color.border};
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid ${Style.color.border};
  }
`

const DropzoneContainer = styled.div`
  > div {
    margin-top: ${Style.spacing.x1};
    margin-left: 0;
    margin-right: 0;
  }
`

const Attachments = styled.div`
  ${EmailAttachmentContainer} {
    max-width: initial;
    width: 100%;
  }
`

const PeppolButtonContainer = styled.div`
  width: 100%;
  cursor: pointer !important;

  > * {
    width: 100%;
    cursor: pointer !important;
  }
`


class InvoiceShow extends React.Component<IProps, IState> {
  private document = React.createRef<DocumentPreview>()
  private debouncedUpdateInvoice: any

  constructor(props: IProps) {
    super(props)

    this.state = {
      conditions: [],
      invoice: null,
      payments: [],
      ledgerItemVariables: [],
      didInitialLoad: false,
      isPreviewLoading: true,
      currentPage: 1,
      pageCount: 1,
      sendingPeppol: false,
    }

    this.getTimelineSteps = this.getTimelineSteps.bind(this)
    this.getQuotationSteps = this.getQuotationSteps.bind(this)
    this.getProformaInvoiceSteps = this.getProformaInvoiceSteps.bind(this)
    this.getInvoiceSteps = this.getInvoiceSteps.bind(this)
    this.getCreditNoteSteps = this.getCreditNoteSteps.bind(this)
    this.debouncedUpdateInvoice = Utils.debounce(this.updateInvoice.bind(this), 800)

    this.onBreadCrumbBackPress = this.onBreadCrumbBackPress.bind(this)
    this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)

    // Timeline order form
    this.onTimeLineOrderFormDraftClick = this.onTimeLineOrderFormDraftClick.bind(this)
    this.onTimelineOrderFormIssueClick = this.onTimelineOrderFormIssueClick.bind(this)
    this.onTimelineOrderFormSendClick = this.onTimelineOrderFormSendClick.bind(this)
    this.onTimelineConvertOrderFormToDeliveryNoteClick = this.onTimelineConvertOrderFormToDeliveryNoteClick.bind(this)

    // Timeline delivery note
    this.onTimeLineDeliveryNoteDraftClick = this.onTimeLineDeliveryNoteDraftClick.bind(this)
    this.onTimelineDeliveryNoteIssueClick = this.onTimelineDeliveryNoteIssueClick.bind(this)
    this.onTimelineDeliveryNoteSendClick = this.onTimelineDeliveryNoteSendClick.bind(this)
    this.onTimelineConvertDeliveryNoteToInvoiceClick = this.onTimelineConvertDeliveryNoteToInvoiceClick.bind(this)

    // Timeline quotation
    this.onTimeLineQuotationDraftClick = this.onTimeLineQuotationDraftClick.bind(this)
    this.onTimelineQuotationIssueClick = this.onTimelineQuotationIssueClick.bind(this)
    this.onTimelineQuotationSendClick = this.onTimelineQuotationSendClick.bind(this)
    this.onTimelineQuotationApproveClick = this.onTimelineQuotationApproveClick.bind(this)
    this.onTimelineQuotationInvoiceClick = this.onTimelineQuotationInvoiceClick.bind(this)

    // Timeline proforma invoice
    this.onTimelineProformaInvoiceIssueClick = this.onTimelineProformaInvoiceIssueClick.bind(this)
    this.onTimelineProformaInvoiceInvoiceClick = this.onTimelineProformaInvoiceInvoiceClick.bind(this)

    // Timeline invoice
    this.onTimeLineInvoiceDraftClick = this.onTimeLineInvoiceDraftClick.bind(this)
    this.onTimelineInvoiceClick = this.onTimelineInvoiceClick.bind(this);
    this.onTimelineInvoiceSendClick = this.onTimelineInvoiceSendClick.bind(this);
    this.onTimelineInvoicePaidClick = this.onTimelineInvoicePaidClick.bind(this);

    // Timeline credit note
    this.onTimelineCreditnoteDraftClick = this.onTimelineCreditnoteDraftClick.bind(this)
    this.onTimelineCreditnoteSendClick = this.onTimelineCreditnoteSendClick.bind(this)
    this.onTimelineCreditnotePaidClick = this.onTimelineCreditnotePaidClick.bind(this)

    this.onEditClick = this.onEditClick.bind(this);

    this.onContactClick = this.onContactClick.bind(this)
    this.onProjectClick = this.onProjectClick.bind(this)
    // Download
    this.onDownloadClick = this.onDownloadClick.bind(this);

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onSendPaymentReminderClick = this.onSendPaymentReminderClick.bind(this)
    this.onConfigurePeppolClick = this.onConfigurePeppolClick.bind(this)
    this.onSendPeppolClick = this.onSendPeppolClick.bind(this)
    this.onPeppolContactLinkClick = this.onPeppolContactLinkClick.bind(this)
    this.onSendBaillifClick = this.onSendBaillifClick.bind(this)
    this.onInvoiceSendModalFormSubmit = this.onInvoiceSendModalFormSubmit.bind(this);
    this.onInvoiceConditionChange = this.onInvoiceConditionChange.bind(this)
    this.onLedgerItemRemindersEnabledClick = this.onLedgerItemRemindersEnabledClick.bind(this)
    this.onDocumentNavigateBack = this.onDocumentNavigateBack.bind(this)
    this.onDocumentNavigateForward = this.onDocumentNavigateForward.bind(this)
    this.onProjectStatusUpdateSubmit = this.onProjectStatusUpdateSubmit.bind(this)
    this.onViewTransactionHistory = this.onViewTransactionHistory.bind(this)
    this.onAddPaymentClick = this.onAddPaymentClick.bind(this)
    this.onToggleInvoicePaymentConfirmationEmail = this.onToggleInvoicePaymentConfirmationEmail.bind(this)
    this.onPaymentConfirmationMessageChange = this.onPaymentConfirmationMessageChange.bind(this)
    this.onAssetFileDrop = this.onAssetFileDrop.bind(this)
    this.onConfirmationAttachmentClick = this.onConfirmationAttachmentClick.bind(this)
    this.onDeleteConfirmationAttachmentClick = this.onDeleteConfirmationAttachmentClick.bind(this)

    // Action cable
    this.onActionCableConnected = this.onActionCableConnected.bind(this)
    this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
    this.onActionCableReceived = this.onActionCableReceived.bind(this)
  }

  fetchView() {
    const { id } = this.props.match.params

    InvoicesController
      .getShowView(id)
      .then(response => {
        const {
          invoice,
          payments,
          ledger_conditions,
          ledger_item_variables,
          order_form_metadata,
          delivery_note_metadata,
          quotation_metadata,
          pro_forma_invoice_metadata,
          invoice_metadata,
          credit_note_metadata,
        } = response

        this.setState({
          didInitialLoad: true,
          invoice: invoice,
          payments: payments,
          conditions: ledger_conditions,
          ledgerItemVariables: ledger_item_variables,
          orderFormMetadata: order_form_metadata,
          deliveryNoteMetadata: delivery_note_metadata,
          quotationMetadata: quotation_metadata,
          proFormaInvoiceMetadata: pro_forma_invoice_metadata,
          invoiceMetadata: invoice_metadata,
          creditNoteMetadata: credit_note_metadata,
        }, this.processParams)
      })
  }

  getTitle() {
    const { t } = this.props
    const { invoice } = this.state

    if (invoice) {
      if (invoice.type === LedgerItemType.RECURRING_INVOICE) {
        return t('InvoiceShow::{{__appName}} | Recurring invoice')
      } else {
        return t('InvoiceShow::{{__appName}} | {{number}}', { number: invoice.number })
      }
    } else {
      return t('InvoiceShow::{{__appName}} | Invoice')
    }
  }

  processParams() {
    const { invoice } = this.state
    const { location: { search }, showSendLedgerItemModal } = this.props
    const params = UrlHelper.getParams(search)

    if (params.email && invoice && showSendLedgerItemModal) {
      this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: invoice.id }))

      requestAnimationFrame(() => {
        showSendLedgerItemModal({
          ledgerItemId: invoice.id,
          onSubmit: this.onInvoiceSendModalFormSubmit
        })
      })
    }
  }

  componentWillMount() {
    this.fetchView()
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    ReactTooltip.rebuild()

    const { history } = this.props
    const params = UrlHelper.getParams(this.props.history.location.search)

    this.processParams()

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchView()
    }

    if (params.viewClaim === 'true') {
      // Remove viewClaim param
      history.replace(history.location.pathname)
      setTimeout(() => {
        this.onSendBaillifClick()
      }, 1000)
    }
  }

  getTimelineSteps() {
    const { invoice } = this.state;

    if (invoice.type === LedgerItemType.ORDER_FORM) {
      return this.getOrderFormSteps()
    } else if (invoice.type === LedgerItemType.DELIVERY_NOTE) {
      return this.getDeliveryNoteSteps()
    } else if (invoice.type === LedgerItemType.QUOTATION) {
      return this.getQuotationSteps()
    } else if (invoice.type === LedgerItemType.PRO_FORMA_INVOICE) {
      return this.getProformaInvoiceSteps()
    } else if (invoice.type === LedgerItemType.INVOICE) {
      return this.getInvoiceSteps()
    } else if (invoice.type === LedgerItemType.CREDIT_NOTE) {
      return this.getCreditNoteSteps()
    }
  }

  getOrderFormSteps(): TimelineStep[] {
    const { t } = this.props
    const { invoice, quotationMetadata } = this.state

    const step1: TimelineStep = {
      key: 'draft',
      icon: 'check',
      style: { backgroundColor: Timeline.COLOR_SUCCESS, pointerEvents: 'none' },
      tickTopLabel: t('InvoiceShow::Order form'),
      tickBottomLabel: moment(invoice.created_at).format('MMM D'),
      onClick: this.onTimeLineOrderFormDraftClick,
      complete: true,
    }

    const step2: TimelineStep = {
      key: 'issued',
      icon: invoice.issued_on ? 'check' : 'invoice',
      style: {
        backgroundColor: invoice.issued_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.issued_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Start'),
      tickBottomLabel: invoice.issued_on ? moment(invoice.issued_on).format('MMM D') : null,
      onClick: this.onTimelineOrderFormIssueClick,
      complete: step1.complete && invoice.issued_on
    }

    const step3: TimelineStep = {
      key: 'send',
      icon: invoice.sent_at || invoice.delivered_on || invoice.cancelled_on ? 'check' : 'send',
      style: {
        backgroundColor: invoice.sent_at || invoice.delivered_on || invoice.cancelled_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.sent_at || invoice.delivered_on || invoice.cancelled_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Sent'),
      tickBottomLabel: invoice.sent_at ? moment(invoice.sent_at).format('MMM D') : null,
      onClick: this.onTimelineOrderFormSendClick,
      complete: (step2.complete && invoice.sent_at) || invoice.delivered_on || invoice.cancelled_on
    }

    const step4Complete = ((invoice.viewed_at !== null) || step3.complete && invoice.viewed_at !== null) || invoice.invoiced_on || invoice.delivered_on || invoice.cancelled_on
    const step4: TimelineStep = {
      key: 'viewed',
      icon: step4Complete ? 'check' : 'eye',
      style: {
        backgroundColor: step4Complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_INACTIVE,
        pointerEvents: step4Complete ? 'none' : 'none'
      },
      tickTopLabel: t('InvoiceShow::Opened'),
      tickBottomLabel: invoice.viewed_at ? moment(invoice.viewed_at).format('MMM D') : null,
      complete: step4Complete
    }

    let step5: TimelineStep = {
      key: 'convert_to_delivery_note',
      icon: 'truck',
      style: {
        backgroundColor: invoice.delivered_on ? Timeline.COLOR_INACTIVE : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.delivered_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Delivered'),
      tickBottomLabel: null,
      onClick: invoice.delivered_on ? () => { } : this.onTimelineConvertOrderFormToDeliveryNoteClick,
      complete: step4Complete && invoice.delivered_on
    }

    if (invoice.delivered_on) {
      step5 = {
        key: 'convert_to_delivery_note',
        icon: 'check',
        style: {
          backgroundColor: Timeline.COLOR_SUCCESS,
          pointerEvents: 'none'
        },
        tickTopLabel: t('InvoiceShow::Delivered'),
        tickBottomLabel: invoice.delivered_on ? moment(invoice.delivered_on).format('MMM D') : null,
        onClick: () => { },
        complete: true
      }
    } else if (invoice.cancelled_on) {
      step5 = {
        key: 'approved',
        icon: 'close',
        style: {
          backgroundColor: Style.color.brandDanger,
          pointerEvents: 'none'
        },
        tickTopLabel: t('InvoiceShow::Cancelled'),
        tickBottomLabel: invoice.cancelled_on ? moment(invoice.cancelled_on).format('MMM D') : null,
        complete: true
      }
    }

    return [
      step1,
      step2,
      step3,
      step4,
      step5,
    ]
  }

  getDeliveryNoteSteps(): TimelineStep[] {
    const { t } = this.props
    const { invoice, quotationMetadata } = this.state

    const step1: TimelineStep = {
      key: 'draft',
      icon: 'check',
      style: { backgroundColor: Timeline.COLOR_SUCCESS, pointerEvents: 'none' },
      tickTopLabel: t('InvoiceShow::Delivery note'),
      tickBottomLabel: moment(invoice.created_at).format('MMM D'),
      onClick: this.onTimeLineQuotationDraftClick,
      complete: true,
    }

    const step2: TimelineStep = {
      key: 'issued',
      icon: invoice.issued_on ? 'check' : 'invoice',
      style: {
        backgroundColor: invoice.issued_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.issued_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Delivered'),
      tickBottomLabel: invoice.issued_on ? moment(invoice.issued_on).format('MMM D') : null,
      onClick: this.onTimelineQuotationIssueClick,
      complete: step1.complete && invoice.issued_on
    }

    const step3: TimelineStep = {
      key: 'send',
      icon: invoice.sent_at || invoice.invoiced_on ? 'check' : 'send',
      style: {
        backgroundColor: invoice.sent_at || invoice.invoiced_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.sent_at || invoice.invoiced_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Sent'),
      tickBottomLabel: invoice.sent_at ? moment(invoice.sent_at).format('MMM D') : null,
      onClick: this.onTimelineQuotationSendClick,
      complete: (step2.complete && invoice.sent_at) || invoice.approved_on || invoice.invoiced_on
    }

    const step4Complete = ((invoice.viewed_at !== null) || step3.complete && invoice.viewed_at !== null) || invoice.approved_on || invoice.invoiced_on
    const step4: TimelineStep = {
      key: 'viewed',
      icon: step4Complete ? 'check' : 'eye',
      style: {
        backgroundColor: step4Complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_INACTIVE,
        pointerEvents: step4Complete ? 'none' : 'none'
      },
      tickTopLabel: t('InvoiceShow::Opened'),
      tickBottomLabel: invoice.viewed_at ? moment(invoice.viewed_at).format('MMM D') : null,
      complete: step4Complete
    }

    let step5: TimelineStep = {
      key: 'convert_to_invoice',
      icon: 'banknote',
      style: {
        backgroundColor: Timeline.COLOR_PROGRESS,
        pointerEvents: 'auto'
      },
      tickTopLabel: t('InvoiceShow::Invoice'),
      tickBottomLabel: null,
      onClick: !invoice.invoiced_on ? this.onTimelineConvertDeliveryNoteToInvoiceClick : () => { },
      complete: false
    }

    if (invoice.invoiced_on) {
      step5 = {
        key: 'convert_to_invoice',
        icon: 'check',
        style: {
          backgroundColor: Timeline.COLOR_SUCCESS,
          pointerEvents: 'none'
        },
        tickTopLabel: t('InvoiceShow::Invoiced'),
        tickBottomLabel: invoice.invoiced_on ? moment(invoice.invoiced_on).format('MMM D') : null,
        onClick: () => { },
        complete: true
      }
    }

    return [
      step1,
      step2,
      step3,
      step4,
      step5,
    ]
  }

  getQuotationSteps(): TimelineStep[] {
    const { t } = this.props
    const { invoice, quotationMetadata } = this.state

    const step1: TimelineStep = {
      key: 'draft',
      icon: 'check',
      style: { backgroundColor: Timeline.COLOR_SUCCESS, pointerEvents: 'none' },
      tickTopLabel: t('InvoiceShow::Quotation'),
      tickBottomLabel: moment(invoice.created_at).format('MMM D'),
      onClick: this.onTimeLineQuotationDraftClick,
      complete: true,
    }

    const step2: TimelineStep = {
      key: 'issued',
      icon: invoice.issued_on ? 'check' : 'invoice',
      style: {
        backgroundColor: invoice.issued_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.issued_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Start'),
      tickBottomLabel: invoice.issued_on ? moment(invoice.issued_on).format('MMM D') : null,
      onClick: this.onTimelineQuotationIssueClick,
      complete: step1.complete && invoice.issued_on
    }

    const step3: TimelineStep = {
      key: 'send',
      icon: invoice.sent_at || invoice.approved_on || invoice.invoiced_on ? 'check' : 'send',
      style: {
        backgroundColor: invoice.sent_at || invoice.approved_on || invoice.invoiced_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.sent_at || invoice.approved_on || invoice.invoiced_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Sent'),
      tickBottomLabel: invoice.sent_at ? moment(invoice.sent_at).format('MMM D') : null,
      onClick: this.onTimelineQuotationSendClick,
      complete: (step2.complete && invoice.sent_at) || invoice.approved_on || invoice.invoiced_on
    }

    const step4Complete = ((invoice.viewed_at !== null) || step3.complete && invoice.viewed_at !== null) || invoice.approved_on || invoice.invoiced_on
    const step4: TimelineStep = {
      key: 'viewed',
      icon: step4Complete ? 'check' : 'eye',
      style: {
        backgroundColor: step4Complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_INACTIVE,
        pointerEvents: step4Complete ? 'none' : 'none'
      },
      tickTopLabel: t('InvoiceShow::Opened'),
      tickBottomLabel: invoice.viewed_at ? moment(invoice.viewed_at).format('MMM D') : null,
      complete: step4Complete
    }

    let step5: TimelineStep = {
      key: 'approved',
      icon: invoice.approved_on || invoice.invoiced_on ? 'check' : 'thumbs-up',
      style: {
        backgroundColor: invoice.approved_on || invoice.invoiced_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.approved_on || invoice.invoiced_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Approved'),
      tickBottomLabel: invoice.approved_on ? moment(invoice.approved_on).format('MMM D') : null,
      onClick: this.onTimelineQuotationApproveClick,
      complete: step4.complete && (invoice.approved_on || invoice.invoiced_on)
    }

    if (!invoice.approved_on && invoice.rejected_on) {
      step5 = {
        key: 'approved',
        icon: 'thumbs-down',
        style: {
          backgroundColor: Style.color.brandDanger,
          pointerEvents: 'none'
        },
        tickTopLabel: t('InvoiceShow::Rejected'),
        tickBottomLabel: invoice.rejected_on ? moment(invoice.rejected_on).format('MMM D') : null,
        // onClick: this.onTimelineQuotationApproveClick,
        complete: false
      }
    }

    let step6: TimelineStep = {
      key: 'convert_to_invoice',
      icon: 'banknote',
      style: {
        backgroundColor: !invoice.approved_on && invoice.rejected_on ? Timeline.COLOR_INACTIVE : Timeline.COLOR_PROGRESS,
        pointerEvents: !invoice.approved_on && invoice.rejected_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Invoice'),
      tickBottomLabel: null,
      onClick: !invoice.approved_on && invoice.rejected_on ? () => { } : this.onTimelineQuotationInvoiceClick,
      complete: false
    }

    if (invoice.invoiced_on) {
      step6 = {
        key: 'convert_to_invoice',
        icon: 'check',
        style: {
          backgroundColor: Timeline.COLOR_SUCCESS,
          pointerEvents: 'none'
        },
        tickTopLabel: t('InvoiceShow::Invoiced'),
        tickBottomLabel: invoice.invoiced_on ? moment(invoice.invoiced_on).format('MMM D') : null,
        onClick: () => { },
        complete: true
      }
    }

    return [
      step1,
      step2,
      step3,
      step4,
      step5,
      step6
    ]
  }

  getProformaInvoiceSteps(): TimelineStep[] {
    const { t } = this.props
    const { invoice } = this.state

    const step1: TimelineStep = {
      key: 'draft',
      icon: 'check',
      style: { backgroundColor: Timeline.COLOR_SUCCESS, pointerEvents: 'none' },
      tickTopLabel: t('InvoiceShow::Created'),
      tickBottomLabel: moment(invoice.created_at).format('MMM D'),
      onClick: this.onTimeLineInvoiceDraftClick,
      complete: true
    }

    const step1_1: TimelineStep = {
      key: 'issued',
      icon: invoice.issued_on ? 'check' : 'invoice',
      style: {
        backgroundColor: invoice.issued_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.issued_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Start'),
      tickBottomLabel: invoice.issued_on ? moment(invoice.issued_on).format('MMM D') : null,
      onClick: this.onTimelineProformaInvoiceIssueClick,
      complete: step1.complete && invoice.issued_on
    }

    const step2: TimelineStep = {
      key: 'send',
      icon: invoice.sent_at || invoice.invoiced_on ? 'check' : 'send',
      style: {
        backgroundColor: invoice.sent_at || invoice.invoiced_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.sent_at || invoice.invoiced_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Sent'),
      tickBottomLabel: invoice.sent_at ? moment(invoice.sent_at).format('MMM D') : null,
      onClick: this.onTimelineInvoiceSendClick,
      complete: (invoice.sent_at !== null || invoice.invoiced_on !== null)
    }

    const step3Complete = Boolean((step2.complete && invoice.viewed_at !== null) || invoice.invoiced_on)
    const step3: TimelineStep = {
      key: 'viewed',
      icon: step3Complete ? 'check' : 'eye',
      style: {
        backgroundColor: step3Complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_INACTIVE,
        pointerEvents: step3Complete ? 'none' : 'none'
      },
      tickTopLabel: t('InvoiceShow::Opened'),
      tickBottomLabel: invoice.viewed_at ? moment(invoice.viewed_at).format('MMM D') : null,
      complete: step3Complete
    }

    let step4: TimelineStep = {
      key: 'convert_to_invoice',
      icon: 'banknote',
      style: {
        backgroundColor: invoice.invoice_id ? Timeline.COLOR_INACTIVE : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.invoice_id ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Invoice'),
      tickBottomLabel: null,
      onClick: invoice.invoice_id ? () => { } : this.onTimelineProformaInvoiceInvoiceClick,
      complete: false
    }

    if (invoice.invoiced_on) {
      step4 = {
        key: 'convert_to_invoice',
        icon: 'check',
        style: {
          backgroundColor: Timeline.COLOR_SUCCESS,
          pointerEvents: 'none'
        },
        tickTopLabel: t('InvoiceShow::Invoiced'),
        tickBottomLabel: invoice.invoiced_on ? moment(invoice.invoiced_on).format('MMM D') : null,
        onClick: () => { },
        complete: true
      }
    }

    return [
      step1,
      step1_1,
      step2,
      step3,
      step4
    ]
  }

  getInvoiceSteps(): TimelineStep[] {
    const { t } = this.props
    const { invoice } = this.state

    const step1: TimelineStep = {
      key: 'draft',
      icon: 'check',
      style: { backgroundColor: Timeline.COLOR_SUCCESS, pointerEvents: 'none' },
      tickTopLabel: t('InvoiceShow::Invoice'),
      tickBottomLabel: moment(invoice.created_at).format('MMM D'),
      onClick: this.onTimeLineInvoiceDraftClick,
      complete: true
    }

    const step2: TimelineStep = {
      key: 'invoiced',
      icon: invoice.issued_on ? 'check' : 'invoice',
      style: {
        backgroundColor: invoice.issued_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.issued_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Invoiced'),
      tickBottomLabel: invoice.issued_on ? moment(invoice.issued_on).format('MMM D') : null,
      onClick: this.onTimelineInvoiceClick,
      complete: invoice.issued_on !== null
    }

    const step3: TimelineStep = {
      key: 'send',
      icon: invoice.sent_at || invoice.paid_on ? 'check' : 'send',
      style: {
        backgroundColor: invoice.sent_at || invoice.paid_on || invoice.credited_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.sent_at || invoice.paid_on || invoice.credited_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Sent'),
      tickBottomLabel: invoice.sent_at ? moment(invoice.sent_at).format('MMM D') : null,
      onClick: this.onTimelineInvoiceSendClick,
      complete: (step2.complete && invoice.sent_at !== null) || invoice.paid_on || invoice.credited_on
    }

    const step4Complete = ((invoice.approved_on !== null) || step3.complete && invoice.viewed_at !== null) || invoice.paid_on || invoice.credited_on
    const step4: TimelineStep = {
      key: 'viewed',
      icon: step4Complete ? 'check' : 'eye',
      style: {
        backgroundColor: step4Complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_INACTIVE,
        pointerEvents: step4Complete ? 'none' : 'none'
      },
      tickTopLabel: t('InvoiceShow::Opened'),
      tickBottomLabel: invoice.viewed_at ? moment(invoice.viewed_at).format('MMM D') : null,
      complete: step4Complete
    }

    const step5: TimelineStep = {
      key: 'paid',
      icon: invoice.paid_on || invoice.credited_on ? 'check' : 'banknote',
      style: {
        backgroundColor: invoice.paid_on || invoice.credited_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.paid_on || invoice.credited_on ? 'none' : 'auto'
      },
      tickTopLabel: invoice.credited_on ? t('InvoiceShow::Credited') : t('InvoiceShow::Paid'),
      tickBottomLabel: invoice.paid_on || invoice.credited_on ? moment(invoice.paid_on || invoice.credited_on).format('MMM D') : null,
      onClick: this.onTimelineInvoicePaidClick,
      complete: step4.complete && (invoice.paid_on || invoice.credited_on)
    }

    return [
      step1,
      step2,
      step3,
      step4,
      step5
    ]
  }

  getCreditNoteSteps(): TimelineStep[] {
    const { t } = this.props
    const { invoice } = this.state

    const step1: TimelineStep = {
      key: 'draft',
      icon: 'check',
      style: { backgroundColor: Timeline.COLOR_SUCCESS, pointerEvents: 'none' },
      tickTopLabel: t('InvoiceShow::Credit note'),
      tickBottomLabel: moment(invoice.created_at).format('MMM D'),
      onClick: this.onTimelineCreditnoteDraftClick,
      complete: true
    }

    const step2Complete = step1.complete && (invoice.sent_at || invoice.paid_on)
    const step2: TimelineStep = {
      key: 'send',
      icon: step2Complete ? 'check' : 'send',
      style: {
        backgroundColor: step2Complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.sent_at || invoice.paid_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Sent'),
      tickBottomLabel: invoice.sent_at ? moment(invoice.sent_at).format('MMM D') : null,
      onClick: this.onTimelineCreditnoteSendClick,
      complete: step2Complete
    }

    const step3Complete = (invoice.paid_on !== null) || step2.complete && invoice.viewed_at !== null
    const step3: TimelineStep = {
      key: 'viewed',
      icon: step3Complete ? 'check' : 'eye',
      style: {
        backgroundColor: step3Complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_INACTIVE,
        pointerEvents: step3Complete ? 'none' : 'none'
      },
      tickTopLabel: t('InvoiceShow::Opened'),
      tickBottomLabel: invoice.viewed_at ? moment(invoice.viewed_at).format('MMM D') : null,
      complete: step3Complete
    }

    const step4: TimelineStep = {
      key: 'paid',
      icon: invoice.paid_on ? 'check' : 'banknote',
      style: {
        backgroundColor: invoice.paid_on ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS,
        pointerEvents: invoice.paid_on ? 'none' : 'auto'
      },
      tickTopLabel: t('InvoiceShow::Paid'),
      tickBottomLabel: invoice.paid_on ? moment(invoice.paid_on).format('MMM D') : null,
      onClick: this.onTimelineCreditnotePaidClick,
      complete: step3.complete && invoice.paid_on !== null
    }

    return [
      step1,
      step2,
      step3,
      step4
    ]
  }

  createLedgerItem(invoice, callback?: () => void) {
    const { t } = this.props

    InvoicesController
      .create(invoice)
      .then(response => {
        const { errors } = response;

        if (errors) {
          if (errors.quota) {
            Notification.notifyError(t('InvoiceShow::Limit reached for current plan. Upgrade to continue'), () => {
              this.props.history.push(ERoute.PATH_ACCOUNT_SUBSCRIPTION)
            })
          } else {
            Notification.notifyError(t('InvoiceShow::Oops something went wrong!'))
          }
        }
        else {
          const invoice = response;
          const { type } = invoice

          this.setState({
            invoice: invoice
          });

          let notification = ''
          if (type === LedgerItemType.ORDER_FORM) {
            notification = t('InvoiceShow::Order form successfully created.')
          } else if (type === LedgerItemType.DELIVERY_NOTE) {
            notification = t('InvoiceShow::Delivery note successfully created.')
          } else if (type === LedgerItemType.QUOTATION) {
            notification = t('InvoiceShow::Quotation successfully created.')
          } else if (type === LedgerItemType.INVOICE) {
            notification = t('InvoiceShow::Invoice successfully created.')
          } else if (type === LedgerItemType.CREDIT_NOTE) {
            notification = t('InvoiceShow::Credit note successfully created.')
          }

          Notification.notifySuccess(notification);

          if (callback) callback()
        }
      })
      .catch(console.error)
  }

  updateInvoice(invoice, callback?, options: { reload?: boolean } = { reload: true }) {
    const { t } = this.props

    InvoicesController
      .update(invoice)
      .then(response => {
        const { errors } = response;

        if (errors) {
          Notification.notifyError(t('InvoiceShow::Oops something went wrong!'))
        }
        else {
          const invoice = response;
          const { type } = invoice

          this.setState({ invoice: invoice });

          let notification = ''
          if (type === LedgerItemType.ORDER_FORM) {
            notification = t('InvoiceShow::Order form successfully updated.')
          } else if (type === LedgerItemType.DELIVERY_NOTE) {
            notification = t('InvoiceShow::Delivery note successfully updated.')
          } else if (type === LedgerItemType.QUOTATION) {
            notification = t('InvoiceShow::Quotation successfully updated.')
          } else if (type === LedgerItemType.INVOICE) {
            notification = t('InvoiceShow::Invoice updated successfully.')
          } else if (type === LedgerItemType.CREDIT_NOTE) {
            notification = t('InvoiceShow::Credit note successfully updated')
          } else if (type === LedgerItemType.RECURRING_INVOICE) {
            notification = t('InvoiceShow::Recurring invoice successfully updated')
          }

          Notification.notifySuccess(notification);

          if (options.reload && this.document.current) {
            this.document.current.reload()
            this.setState({ isPreviewLoading: true, currentPage: 1 })
          }

          if (callback) callback()
        }
      })
      .catch(console.error)
  }

  onBreadCrumbBackPress(e) {
    e.preventDefault()

    // When coming through email linking to this page, history length is set to 2. Going back will take your to the overview page
    if (this.props.history.length === 2) {
      this.props.history.push(ERoute.PATH_INVOICES)
    } else {
      this.props.history.goBack()
    }

  }

  onPageHeaderActionClick(key: string) {
    const { t } = this.props
    const { invoice } = this.state

    switch (key) {
      case 'new':
        this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, {
          contact_id: invoice.contact ? invoice.contact.id : '',
          project_id: invoice.project ? invoice.project.id : '',
          type: invoice.type,
        }))
        break
      case 'new_project':
        this.props.showProjectModal({
          project: {
            contact_id: invoice.contact_id,
          },
          contactDisabled: Boolean(invoice.contact_id),
          onSubmit: (project: Project) => {
            const updatedInvoice = {
              ...invoice,
              contact_id: project.contact_id,
              contact: project.contact,
              project_id: project.id,
              project: project,
            }

            this.setState({ invoice: updatedInvoice, }, () => {
              this.updateInvoice(updatedInvoice)
            })
          }
        })
        break
      // Should only be executable on type === 'quotation'
      case 'new_advance_invoice':
        this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, {
          contact_id: invoice.contact ? invoice.contact.id : '',
          project_id: invoice.project ? invoice.project.id : '',
          quotation_id: invoice.id,
          type: 'invoice',
          invoice_type: 'advance',
        }))
        break
      // Should only be executable on type === 'quotation' or type === 'invoice' with quotation_id set
      case 'new_interim_invoice':
        this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, {
          contact_id: invoice.contact ? invoice.contact.id : '',
          project_id: invoice.project ? invoice.project.id : '',
          quotation_id: invoice.type === LedgerItemType.QUOTATION ? invoice.id : invoice.quotation_id,
          type: 'invoice',
          invoice_type: 'interim',
        }))
        break
      case 'new_final_invoice':
        this.props.showConfirmModal({
          title: t('InvoiceShow::Finalisation Invoice'),
          description: t('InvoiceShow::You are about to create a final invoice. Are you sure?'),
          action: {
            label: t('InvoiceShow::Confirm'),
          },
          onConfirm: () => {
            this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, {
              contact_id: invoice.contact ? invoice.contact.id : '',
              project_id: invoice.project ? invoice.project.id : '',
              quotation_id: invoice.type === LedgerItemType.QUOTATION ? invoice.id : invoice.quotation_id,
              type: 'invoice',
              invoice_type: 'final'
            }))
          }
        })
        break
      case 'duplicate':
        this.onDuplicateClick()
        break
      case 'create_credit_note': this.onCreateCreditNoteClick()
        break
      case 'edit': this.onEditClick()
        break
      case 'delete': this.onDeleteClick()
        break
    }
  }

  onTimeLineOrderFormDraftClick(e) {
    e.preventDefault()

    const { invoice } = this.state

    invoice.issued_on = null
    invoice.due_on = null
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.approved_on = null
    invoice.paid_on = null;

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineOrderFormIssueClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } } } = this.props

    const { invoice } = this.state

    const validity_duration = invoice.estimated_net_on ? invoice.estimated_net_on : setting.order_form_validity_duration;

    invoice.issued_on = moment().format('YYYY-MM-DD');
    invoice.due_on = moment().add(validity_duration, 'days').format('YYYY-MM-DD');
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.approved_on = null
    invoice.paid_on = null;

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineOrderFormSendClick(e) {
    e.preventDefault()
    const { invoice } = this.state

    this.props.showSendLedgerItemModal({
      ledgerItemId: invoice.id,
      onSubmit: this.onInvoiceSendModalFormSubmit
    })
  }

  async onTimelineConvertOrderFormToDeliveryNoteClick(e) {
    e.preventDefault()
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    try {
      const { invoice: orderForm } = this.state

      const locale = LedgerItemHelper.getLedgerItemLocale(orderForm)

      const deliveryNote: LedgerItem = {
        ...orderForm,
        id: null,
        identifier: PaymentHelper.generatePaymentReference(orderForm.identifier_type),
        type: LedgerItemType.DELIVERY_NOTE,
        issued_on: moment().format('YYYY-MM-DD'),
        due_on: null,
        sent_at: null,
        viewed_at: null,
        order_form_id: orderForm.id,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_delivery_note_details'),
      }

      // Create delivery note and navigate to the page
      this.createLedgerItem(deliveryNote, () => {
        Sound.play(Sounds.COMPLETE)

        const { invoice: updatedStateInvoice } = this.state

        const { history } = this.props

        history.replace(RouteHelper.process(ERoute.PATH_INVOICE, { id: updatedStateInvoice.id }))

        if (this.document.current) {
          this.document.current.reload()
          this.setState({ isPreviewLoading: true, currentPage: 1 })
        }

        // Set delivered on date on order form
        orderForm.delivered_on = orderForm.delivered_on ? moment(orderForm.delivered_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

        // Update order form
        InvoicesController.update(orderForm).catch(console.error)
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onTimeLineDeliveryNoteDraftClick(e) {
    e.preventDefault()

    const { invoice } = this.state

    const updatedDeliveryNote: LedgerItem = {
      ...invoice,
      issued_on: null,
      due_on: null,
      sent_at: null,
      viewed_at: null,
    }

    this.setState({ invoice: updatedDeliveryNote }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(updatedDeliveryNote);
  }

  onTimelineDeliveryNoteIssueClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } } } = this.props
    const { invoice } = this.state

    const updatedDeliveryNote: LedgerItem = {
      issued_on: moment().format('YYYY-MM-DD'),
      due_on: null,
      estimated_net_on: 0,
      sent_at: null,
      viewed_at: null,
    }

    this.setState({ invoice: updatedDeliveryNote }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(updatedDeliveryNote);

  }

  onTimelineDeliveryNoteSendClick(e) {
    e.preventDefault()
    const { invoice } = this.state

    this.props.showSendLedgerItemModal({
      ledgerItemId: invoice.id,
      onSubmit: this.onInvoiceSendModalFormSubmit
    })
  }

  onTimelineConvertDeliveryNoteToInvoiceClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } } } = this.props

    const { invoice: deliveryNote } = this.state

    const locale = LedgerItemHelper.getLedgerItemLocale(deliveryNote)

    const newInvoice: LedgerItem = {
      ...deliveryNote,
      // Creating new ledger item with type invoice
      id: null,
      identifier: PaymentHelper.generatePaymentReference(deliveryNote.identifier_type),
      type: LedgerItemType.INVOICE,
      issued_on: null,
      due_on: null,
      sent_at: null,
      viewed_at: null,
      paid_on: null,
      details: MobilityHelper.getTranslation(locale, setting.translations, 'default_invoice_details'),
      estimated_net_on: setting.invoice_payment_duration,
      // Link this invoice to order form if applicable
      order_form_id: deliveryNote.order_form_id,
      // Link this invoice to delivery note
      delivery_note_id: deliveryNote.id,
      po_number: deliveryNote.project && deliveryNote.project.po_number ? deliveryNote.project.po_number : null,
    }

    this.createLedgerItem(newInvoice, () => {
      Sound.play(Sounds.COMPLETE)

      const { invoice: updatedStateInvoice } = this.state

      const { history } = this.props

      history.replace(RouteHelper.process(ERoute.PATH_INVOICE, { id: updatedStateInvoice.id }))

      if (this.document.current) {
        this.document.current.reload()
        this.setState({ isPreviewLoading: true, currentPage: 1 })
      }

      // Update delivery note
      InvoicesController.update({
        ...deliveryNote,
        // Send details of newly created invoice on quotation
        invoiced_on: moment().format('YYYY-MM-DD'),
      }).catch(console.error)
    });
  }

  onTimeLineQuotationDraftClick(e) {
    e.preventDefault()

    const { invoice } = this.state

    invoice.issued_on = null
    invoice.due_on = null
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.approved_on = null
    invoice.paid_on = null;

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineQuotationIssueClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } } } = this.props

    const { invoice } = this.state

    const validity_duration = invoice.estimated_net_on ? invoice.estimated_net_on : setting.quotation_validity_duration;

    invoice.issued_on = moment().format('YYYY-MM-DD');
    invoice.due_on = moment().add(validity_duration, 'days').format('YYYY-MM-DD');
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.approved_on = null
    invoice.paid_on = null;

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineQuotationSendClick(e) {
    e.preventDefault()
    const { invoice } = this.state

    this.props.showSendLedgerItemModal({
      ledgerItemId: invoice.id,
      onSubmit: this.onInvoiceSendModalFormSubmit
    })
  }

  onTimelineQuotationApproveClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } }, t } = this.props

    const { invoice } = this.state

    const validity_duration = invoice.estimated_net_on ? invoice.estimated_net_on : setting.quotation_validity_duration;

    invoice.issued_on = invoice.issued_on ? moment(invoice.issued_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    invoice.due_on = invoice.due_on ? moment(invoice.due_on).format('YYYY-MM-DD') : moment().add(validity_duration, 'days').format('YYYY-MM-DD');
    invoice.approved_on = moment().format('YYYY-MM-DD');

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.COMPLETE)
    });

    this.updateInvoice(invoice);

    if (
      invoice.project &&
      ProjectHelper.hasActiveStatus(invoice.project)
    ) {
      const project = invoice.project
      const startDate = moment(project.start_date)

      this.props.showConfirmModal({
        title: t('InvoiceShow::Project status'),
        description: t('InvoiceShow::We noticed you just marked your quote as <b>approved</b> would you like to update the status of the project <b>"{{project}}"</b> as well?', { project: project.name }),
        action: { label: t('InvoiceShow::Update status') },
        onConfirm: () => {
          this.props.showProjectStatusUpdateModal({
            project: {
              id: invoice.project.id,
              status: ProjectStatus.ACTIVE,
              start_date: startDate.isValid() ? startDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            },
            onSubmit: this.onProjectStatusUpdateSubmit
          })
        }
      })
    }
  }

  onTimelineQuotationInvoiceClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } } } = this.props

    const { invoice } = this.state

    const quotation: LedgerItem = {
      ...invoice
    }

    const locale = LedgerItemHelper.getLedgerItemLocale(quotation)

    // Creating new ledger item with type invoice
    invoice.id = null
    invoice.type = LedgerItemType.INVOICE
    invoice.issued_on = null
    invoice.due_on = null
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.paid_on = null;
    invoice.details = MobilityHelper.getTranslation(locale, setting.translations, 'default_invoice_details')
    invoice.estimated_net_on = setting.invoice_payment_duration
    // Link this new invoice to quotation
    invoice.quotation_id = quotation.id
    invoice.po_number = quotation.project && quotation.project.po_number ? quotation.project.po_number : null

    this.createLedgerItem(invoice, () => {
      Sound.play(Sounds.COMPLETE)

      const { invoice: updatedStateInvoice } = this.state

      const { history } = this.props

      history.replace(RouteHelper.process(ERoute.PATH_INVOICE, { id: updatedStateInvoice.id }))

      if (this.document.current) {
        this.document.current.reload()
        this.setState({ isPreviewLoading: true, currentPage: 1 })
      }

      const validity_duration = quotation.estimated_net_on ? quotation.estimated_net_on : setting.quotation_validity_duration;
      // Send details of newly created invoice on quotation
      quotation.invoiced_on = moment().format('YYYY-MM-DD');
      quotation.issued_on = quotation.issued_on ? moment(quotation.issued_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      quotation.due_on = quotation.due_on ? moment(quotation.due_on).format('YYYY-MM-DD') : moment().add(validity_duration, 'days').format('YYYY-MM-DD')

      // Update quotation
      InvoicesController.update(quotation).catch(console.error)
    });
  }

  onTimeLineInvoiceDraftClick(e) {
    e.preventDefault()

    const { invoice } = this.state;

    invoice.issued_on = null
    invoice.due_on = null
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.paid_on = null;

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineInvoiceClick(e) {
    e.preventDefault()

    const { invoice } = this.state;
    const { currentUser: { workspace: { setting } } } = this.props

    const payment_duration = invoice.estimated_net_on ? invoice.estimated_net_on : setting.invoice_payment_duration;

    invoice.issued_on = moment().format('YYYY-MM-DD')
    invoice.due_on = moment().add(payment_duration, 'days').format('YYYY-MM-DD')
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.paid_on = null;

    this.setState({ invoice: invoice }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineInvoiceSendClick(e) {
    e.preventDefault();
    const { invoice } = this.state

    this.props.showSendLedgerItemModal({
      ledgerItemId: invoice.id,
      onSubmit: this.onInvoiceSendModalFormSubmit
    })
  }

  onTimelineProformaInvoiceIssueClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } } } = this.props

    const { invoice } = this.state

    const validity_duration = invoice.estimated_net_on ? invoice.estimated_net_on : setting.invoice_payment_duration;

    invoice.issued_on = moment().format('YYYY-MM-DD');
    invoice.due_on = moment().add(validity_duration, 'days').format('YYYY-MM-DD');
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.approved_on = null
    invoice.paid_on = null;

    this.setState({ invoice: invoice }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineProformaInvoiceInvoiceClick(e) {
    e.preventDefault()
    const { currentUser: { workspace: { setting } } } = this.props

    const { invoice } = this.state

    const proFormaInvoice: LedgerItem = {
      ...invoice
    }

    const locale = LedgerItemHelper.getLedgerItemLocale(proFormaInvoice)

    // Creating new ledger item with type invoice
    invoice.id = null
    invoice.type = LedgerItemType.INVOICE
    invoice.issued_on = null
    invoice.due_on = null
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.paid_on = null;
    invoice.details = MobilityHelper.getTranslation(locale, setting.translations, 'default_invoice_details')
    invoice.estimated_net_on = setting.invoice_payment_duration
    // Link this new invoice to quotation
    invoice.pro_forma_invoice_id = proFormaInvoice.id
    invoice.po_number = proFormaInvoice.project && proFormaInvoice.project.po_number ? proFormaInvoice.project.po_number : null

    this.createLedgerItem(invoice, () => {
      Sound.play(Sounds.COMPLETE)

      const { invoice: updatedStateInvoice } = this.state

      const { history } = this.props

      history.replace(RouteHelper.process(ERoute.PATH_INVOICE, { id: updatedStateInvoice.id }))

      if (this.document.current) {
        this.document.current.reload()
        this.setState({ isPreviewLoading: true, currentPage: 1 })
      }

      // Send details of newly created invoice on quotation
      proFormaInvoice.invoiced_on = moment().format('YYYY-MM-DD');

      // Update quotation
      InvoicesController.update(proFormaInvoice).catch(console.error)
    });
  }

  onTimelineInvoicePaidClick(e) {
    const { t } = this.props
    e.preventDefault()

    const { invoice } = this.state

    invoice.issued_on = invoice.issued_on ? moment(invoice.issued_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    invoice.due_on = invoice.due_on ? moment(invoice.due_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

    if (moment(invoice.issued_on).isAfter(moment())) {
      invoice.paid_on = moment(invoice.issued_on).format('YYYY-MM-DD')
    } else {
      invoice.paid_on = moment().format('YYYY-MM-DD')
    }

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.COMPLETE)
    });

    this.updateInvoice(invoice);

    if (invoice.project && ProjectHelper.hasActiveStatus(invoice.project)) {
      const project = invoice.project

      this.props.showConfirmModal({
        title: t('InvoiceShow::Project status'),
        description: t('InvoiceShow::We noticed you just marked your invoice as <b>paid</b> would you like to update the status of the project <b>"{{project}}"</b> as well?', { project: project.name }),
        action: { label: t('InvoiceShow::Update status') },
        onConfirm: () => {
          this.props.showProjectStatusUpdateModal({
            project: {
              id: invoice.project.id,
              status: ProjectStatus.COMPLETED,
              end_date: moment().format('YYYY-MM-DD')
            },
            onSubmit: this.onProjectStatusUpdateSubmit
          })
        }
      })
    }
  }

  onProjectStatusUpdateSubmit(project: Project) {
    const { invoice } = this.state

    this.setState({
      invoice: {
        ...invoice,
        project: project,
      }
    })
  }

  onTimelineCreditnoteDraftClick(e) {
    e.preventDefault()

    const { invoice } = this.state;

    invoice.issued_on = null
    invoice.due_on = null
    invoice.sent_at = null;
    invoice.viewed_at = null;
    invoice.paid_on = null;
    invoice.approved_on = null

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.STEP_COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  onTimelineCreditnoteSendClick(e) {
    e.preventDefault()

    const { invoice } = this.state

    this.props.showSendLedgerItemModal({
      ledgerItemId: invoice.id,
      onSubmit: this.onInvoiceSendModalFormSubmit
    })
  }

  onTimelineCreditnotePaidClick(e) {
    e.preventDefault()

    const { invoice } = this.state

    invoice.issued_on = invoice.issued_on ? moment(invoice.issued_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    invoice.due_on = null
    invoice.paid_on = moment().format('YYYY-MM-DD')

    this.setState({
      invoice
    }, () => {
      Sound.play(Sounds.COMPLETE)
    });

    this.updateInvoice(invoice);
  }

  async onDuplicateClick() {
    try {
      const { t } = this.props
      const { invoice } = this.state
      const response = await InvoicesController.duplicate(invoice.id)

      if (response) {
        if (response.errors) {
          if (response.errors.quota) {
            Notification.notifyError(t('InvoiceShow::Limit reached for current plan.'), () => this.props.history.push(ERoute.PATH_ACCOUNT_SUBSCRIPTION))
          }
        } else {
          this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: response.id }))
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onCreateCreditNoteClick() {
    const { t, showConfirmModal } = this.props
    const { invoice } = this.state

    try {
      showConfirmModal({
        title: t('LedgerItemsTable::Generate credit note'),
        description: t('LedgerItemsTable::You are about to generate a credit note for invoice <b>{{number}}</b>. Are you sure?', { number: invoice.number }),
        action: { label: t('LedgerItemsTable::Generate') },
        onConfirm: async () => {
          const response = await InvoicesController.createCreditNote(invoice.id)

          if (response) {
            if (response.errors) {
              if (response.errors.quota) {
                Notification.notifyError(t('LedgerItemsTable::Limit reached for current plan'), () => this.props.history.push(ERoute.PATH_ACCOUNT_SUBSCRIPTION))
              }
            } else {
              this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: response.id }))
            }
          }
        }
      })
    } catch (ex) {
      console.error(ex)
    }

  }

  onEditClick() {
    const { invoice } = this.state;
    const { history } = this.props

    history.push(RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: invoice.id }))
  }

  onContactClick() {
    const { invoice } = this.state

    if (invoice.contact_id) {
      this.props.history.push(RouteHelper.process(ERoute.PATH_CONTACT, { id: invoice.contact_id }))
    }
  }

  onProjectClick() {
    const { invoice } = this.state

    if (invoice.project_id) {
      this.props.history.push(RouteHelper.process(ERoute.PATH_PROJECT, { id: invoice.project_id }))
    }
  }

  onDownloadClick(type: 'pdf' | 'ubl' = 'pdf') {
    const { invoice } = this.state;

    LedgerItemHelper.download(invoice, type)
  }

  onDeleteClick() {
    const { invoice } = this.state;
    const { history, showConfirmModal, t } = this.props

    const typeLabel = LedgerItemHelper.getTypeLabel(invoice.type)

    showConfirmModal({
      title: t('InvoiceShow::Delete {{typeLabel}}', { typeLabel: typeLabel }),
      description: t('InvoiceShow::You are about to delete <b>{{number}}</b>. Deletion is permanent and irreversible. Are you sure?', { number: invoice.number }),
      action: { label: t('InvoiceShow::Delete'), isDestructive: true },
      onConfirm: () => {
        InvoicesController
          .delete(invoice.id)
          .then(response => {
            Notification.notifySuccess(`Succesvol verwijderd`);

            history.replace(ERoute.PATH_ORDERS)
          })
          .catch(console.error)
      }
    })

  }

  onActionCableConnected() {
    console.log('[WorkspaceChannel] connected')
  }

  onActionCableDisconnected() {
    console.log('[WorkspaceChannel] Disconnected')
  }

  onActionCableReceived(event: WorkspaceChannelEvent) {
    const { invoice } = this.state
    const { t } = this.props
    console.log('[WorkspaceChannel] received event', event)

    switch (event.type) {
      case WorkspaceChannelEventType.LEDGER_ITEM_ATTACHMENTS_READY:
        const {
          id,
          pdf_attachment_content_type,
          pdf_attachment_download_url,
          pdf_attachment_file_name,
          pdf_attachment_file_size,
          pdf_attachment_url,
        } = event.data.ledger_item

        if (id === this.state.invoice?.id) {
          this.setState({
            invoice: {
              ...this.state.invoice,
              pdf_attachment_content_type,
              pdf_attachment_download_url,
              pdf_attachment_file_name,
              pdf_attachment_file_size,
              pdf_attachment_url,
            }
          })
        }
        break
    }
  }

  onLinkClick(e) {
    e.preventDefault();
    const { invoice } = this.state;

    this.props.history.push(RouteHelper.process(ERoute.PATH_PREVIEW_LEDGER_ITEM, { id: invoice.id }))
  }

  onSendClick(e) {
    e.preventDefault();

    const { invoice } = this.state

    this.props.showSendLedgerItemModal({
      ledgerItemId: invoice.id,
      onSubmit: this.onInvoiceSendModalFormSubmit
    })
  }

  async onConfigurePeppolClick() {
    const { t } = this.props
    try {
      const response = await WorkspaceController.requestPeppolAccess()
      Notification.notifySuccess(t('InvoiceShow::Peppol access requested. You will be notified once your request has been processed.'))
    } catch (ex) {
      console.error(ex)
    }
  }

  onSendPeppolClick(e) {
    const { t } = this.props
    const { invoice } = this.state
    e.preventDefault()

    this.props.showConfirmModal({
      title: t('InvoiceShow::Send via Peppol'),
      description: t('InvoiceShow::You are about to send this {{ledgerItemType}} to <b>{{contact}}</b> via the Peppol network. Are you sure?', {
        ledgerItemType: LedgerItemHelper.getTypeLabel(invoice.type).toLowerCase(),
        contact: invoice?.contact?.name
      }),
      action: {
        label: t('InvoiceShow::Confirm'),
      },
      onConfirm: async () => {
        try {
          this.setState({ sendingPeppol: true })
          const ledgerItem = await InvoicesController.sendPeppol(invoice.id)

          if (ledgerItem?.peppol_id) {
            Notification.notifySuccess(t('InvoiceShow::{{ledgerItemType}} succesfully send.', { ledgerItemType: LedgerItemHelper.getTypeLabel(invoice.type) }))
          } else {
            Notification.notifyError(t('InvoiceShow::Failed to send via Peppol. Contact support for more information'))
          }
          this.fetchView()
        } catch (ex) {
          console.error(ex)
        } finally {
          this.setState({ sendingPeppol: false })
        }
      }
    })
  }

  onPeppolContactLinkClick() {
    const { invoice } = this.state

    this.props.showContactModal({
      activeTab: 'invoice',
      contact: {
        id: invoice?.contact?.id,
      },
      onSubmit: (contact) => {
        this.setState({
          invoice: {
            ...invoice,
            contact: contact,
            contact_id: contact.id,
          }
        })
      }
    })
  }

  onSendPaymentReminderClick(e) {
    e.preventDefault()

    const { invoice } = this.state

    this.props.showSendLedgerItemModal({
      ledgerItemId: invoice.id,
      reminder: true,
      onSubmit: this.onInvoiceSendModalFormSubmit
    })
  }

  onSendBaillifClick(e?) {
    if (e) e.preventDefault()

    const { invoice } = this.state

    if (invoice) this.props.showledgerItemClaimModal({ ledgerItemId: invoice.id })
  }

  onInvoiceSendModalFormSubmit(ledgerItem: LedgerItem) {
    Sound.play(Sounds.STEP_COMPLETE)

    this.setState({
      invoice: ledgerItem
    })
  }

  onInvoiceConditionChange(e) {
    e.preventDefault()

    const { invoice } = this.state

    const newInvoiceConditionId = e.currentTarget.value

    const newLedgerItem: LedgerItem = {
      ...invoice,
      ledger_condition_id: newInvoiceConditionId,
    }

    this.updateInvoice(newLedgerItem)
  }

  onLedgerItemRemindersEnabledClick(e) {
    e.preventDefault()

    const { invoice } = this.state

    const updatedInvoice: LedgerItem = {
      ...invoice,
      reminders_enabled: !invoice.reminders_enabled
    }

    // Update locale invoice
    this.setState({ invoice: updatedInvoice })

    // Update remote
    this.updateInvoice(updatedInvoice)
  }

  onDocumentNavigateBack() {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  onDocumentNavigateForward() {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  onViewTransactionHistory() {
    const { invoice } = this.state

    this.props.showLedgerItemTransactionHistoryModal({
      ledgerItemId: invoice.id,
      onSync: () => {
        this.fetchView()
      }
    })
  }

  onAddPaymentClick() {
    const { invoice } = this.state

    this.props.showLedgerItemPaymentModal({
      ledgerItemId: invoice.id,
      onSubmit: (payment) => {
        this.fetchView()
      }
    })
  }

  async onConfirmationAttachmentClick(index: number) {
    const { t } = this.props
    const { invoice } = this.state

    const attachments = invoice.data.payment_confirmation_attachments

    if (attachments?.length > 0) {

      const previewAttachments: PreviewAttachment[] = []

      for (const attachment of attachments) {
        previewAttachments.push({
          id: attachment.signed_id,
          name: attachment.filename,
          url: (await ActiveStorageController.getBlobUrl(attachment)).url,
          file_size: attachment.byte_size,
          content_type: attachment.content_type,
        })
      }
      this.props.showAttachmentsViewerModal({
        activeIndex: index,
        attachments: previewAttachments,
        actions: [{
          icon: 'external-link', text: t('ExpenseModal::Open in new tab'), onClick: async (selectedIndex: number) => {
            const selectedAttachment = attachments[selectedIndex]

            if (selectedAttachment) window.open((await ActiveStorageController.getBlobUrl(selectedAttachment)).url, "_blank");
          }
        }]
      })
    }
  }

  async onToggleInvoicePaymentConfirmationEmail() {
    const { currentUser: { workspace: { setting } } } = this.props

    try {
      await SettingsController.update({
        ...setting,
        invoice_paid_confirmation_enabled: !setting.invoice_paid_confirmation_enabled
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onPaymentConfirmationMessageChange(e) {
    const { invoice } = this.state
    const newMessage = e.currentTarget.value

    const updatedInvoice = {
      ...invoice,
      data: {
        ...invoice.data,
        payment_confirmation_message: newMessage
      }
    }
    this.setState({ invoice: updatedInvoice }, () => {
      this.debouncedUpdateInvoice(updatedInvoice, undefined, { reload: false })
    })
  }

  onDeleteConfirmationAttachmentClick(index: number) {
    const { invoice } = this.state

    const data = invoice.data

    // Remove attachment from array
    data?.payment_confirmation_attachments?.splice(index, 1);

    // Update ledger item
    this.updateInvoice({ ...invoice, data: { ...data } }, undefined, { reload: false })
  }

  async onAssetFileDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { invoice } = this.state
    const activeStorageBlobs: ActiveStorage.Blob[] = []

    for (const acceptedFile of acceptedFiles) {
      try {
        const blob = await ActiveStorageController.uploadPromise(acceptedFile)
        activeStorageBlobs.push(blob)
      } catch (ex) {
        console.error(ex)
      }
    }

    if (activeStorageBlobs.length > 0) {
      const paymentConfirmationAttachments = invoice.data.payment_confirmation_attachments || []

      const updatedData: LedgerItemData = {
        ...invoice.data,
        payment_confirmation_attachments: [
          ...paymentConfirmationAttachments,
          ...activeStorageBlobs,
        ]
      }

      this.updateInvoice({ ...invoice, data: updatedData }, undefined, { reload: false })
    }
  }

  renderDetailsList() {
    const { invoice } = this.state
    const {
      currentUser: {
        workspace,
      },
      t
    } = this.props

    const { setting } = workspace

    if (invoice.type === LedgerItemType.RECURRING_INVOICE) {
      const { recurring_schedule } = invoice
      return (
        <InvoiceDetailsList>
          <InvoiceDetailsListItem>
            <label>{t('InvoiceShow::Start from:')}</label>
            <label>{moment(recurring_schedule.start_date).format(setting.date_format)}</label>
          </InvoiceDetailsListItem>

          <InvoiceDetailsListItem>
            <label>{t('InvoiceShow::Ends on:')}</label>
            <label>{recurring_schedule.end_date ? moment(recurring_schedule.end_date).format(setting.date_format) : '-'}</label>
          </InvoiceDetailsListItem>

          {recurring_schedule.next_occurrence_on && <InvoiceDetailsListItem>
            <label>{t('InvoiceShow::Next invoice on:')}</label>
            <label>{recurring_schedule.next_occurrence_on ? moment(recurring_schedule.next_occurrence_on).format(setting.date_format) : '-'}</label>
          </InvoiceDetailsListItem>}

          {invoice.notes && invoice.notes.length > 0 && <InvoiceDetailsListItem vertical={true} valueLight={true}>
            <label>{t('InvoiceShow::Notes:')}</label>
            <label>
              {invoice.notes}
            </label>
          </InvoiceDetailsListItem>}

          <InvoiceDetailsListItem vertical={true} valueLight={true}>
            <label>{t('InvoiceShow::Planning:')}</label>
            <p
              dangerouslySetInnerHTML={{ __html: RecurringScheduleHelper.getScheduleText(invoice.recurring_schedule) }}
            />
          </InvoiceDetailsListItem>
        </InvoiceDetailsList>
      )
    }

    return (
      <InvoiceDetailsList>
        {invoice.type === LedgerItemType.INVOICE && invoice.po_number && <InvoiceDetailsListItem>
          <label>{t('InvoiceShow::PO number:')}</label>
          <label>
            {invoice.po_number}
          </label>
        </InvoiceDetailsListItem>}
        <InvoiceDetailsListItem>
          <label>{t('InvoiceShow::Issued on:')}</label>
          <label style={LedgerItemHelper.isOverdue(invoice) ? { color: '#ff4040' } : {}}>
            {invoice.issued_on ? (moment(invoice.issued_on).isSame(moment(), 'day') ? 'Vandaag' : moment(invoice.issued_on).format(setting.date_format)) : '-'}
          </label>
        </InvoiceDetailsListItem>

        {invoice.type !== LedgerItemType.DELIVERY_NOTE && <InvoiceDetailsListItem>
          <label>{t('InvoiceShow::Due on:')}</label>
          <label style={LedgerItemHelper.isOverdue(invoice) ? { color: '#ff4040' } : {}}>
            {invoice.due_on ? moment(invoice.due_on).format(setting.date_format) : '-'}
          </label>
        </InvoiceDetailsListItem>}

        {invoice.type === LedgerItemType.INVOICE && invoice.credited_on && <InvoiceDetailsListItem>
          <label>{t('InvoiceShow::Credited on:')}</label>
          <label>
            {moment(invoice.credited_on).format(setting.date_format)}
          </label>
        </InvoiceDetailsListItem>}

        <InvoiceDetailsListItem>
          <label>{t('InvoiceShow::Reference:')}</label>
          <label>
            {PaymentHelper.formatPaymentReference(invoice.identifier_type, invoice.identifier)}
          </label>
        </InvoiceDetailsListItem>
        {invoice.notes && invoice.notes.length > 0 && <InvoiceDetailsListItem vertical={true} valueLight={true}>
          <label>{t('InvoiceShow::Notes:')}</label>
          <label>
            {invoice.notes}
          </label>
        </InvoiceDetailsListItem>}

        {Object.keys(invoice.custom_fields).map(customFieldKey => {
          return (
            <InvoiceDetailsListItem>
              <label>{customFieldKey}</label>
              <label>
                {invoice.custom_fields[customFieldKey] || ''}
              </label>
            </InvoiceDetailsListItem>
          )
        })}
      </InvoiceDetailsList>
    )
  }

  renderPeppol() {
    const { invoice } = this.state
    const {
      currentUser: {
        workspace,
      },
      t
    } = this.props

    if (
      [LedgerItemType.INVOICE, LedgerItemType.CREDIT_NOTE].includes(invoice.type) &&
      (invoice.peppol_id || invoice.peppol_errors?.length > 0)
    ) {


      return (
        <>
          <InvoiceDetailsContentHeader>
            {t('InvoiceShow::Peppol')}
          </InvoiceDetailsContentHeader>

          <InvoiceDetailsList>
            <InvoiceDetailsListItem>
              <label>{t('InvoiceShow::Status')}</label>
              <label>
                {invoice?.peppol_id && <Badge type='success' text={t('InvoiceShow::Received')} />}
                {!invoice?.peppol_id && invoice.peppol_errors?.length > 0 &&
                  <PeppolBadgeContainer data-tip data-for='peppolError'>
                    <Badge type='danger' text={t('InvoiceShow::Failed')} />
                  </PeppolBadgeContainer>
                }
              </label>
            </InvoiceDetailsListItem>
          </InvoiceDetailsList>

          {invoice.peppol_errors?.length > 0 && <ReactTooltip id='peppolError' key={invoice.id} place='top' effect='solid'>
            {invoice.peppol_errors?.map((error, index) => {
              const isLast = index === invoice.peppol_errors.length - 1
              return (
                <div key={index}>
                  <p><b>{error.field}</b></p>
                  <p>{error.value}</p>
                  {!isLast && <br />}
                </div>
              )
            })}
          </ReactTooltip>}
        </>
      )

    }

    return null
  }

  renderPayments() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { invoice, payments } = this.state

    if (invoice.type !== LedgerItemType.INVOICE || !invoice.issued_on) return null

    let paidAmount = payments.reduce((value, payment) => Number(payment.amount) + value, 0)

    return (
      <>
        <InvoiceDetailsContentHeader>
          {t('InvoiceShow::Payments')}
        </InvoiceDetailsContentHeader>

        <InvoiceDetailsList>
          <InvoiceDetailsListItem>
            <label>{t('InvoiceShow::Total:')}</label>
            <label>
              {NumberFormatter.formatCurrency(invoice.currency, setting.number_format, invoice.amount)}
            </label>
          </InvoiceDetailsListItem>
          <InvoiceDetailsListItem>
            <label>{t('InvoiceShow::Paid:')}</label>
            <label>
              {NumberFormatter.formatCurrency(invoice.currency, setting.number_format, paidAmount)}
            </label>
          </InvoiceDetailsListItem>
          <InvoiceDetailsListItem>
            <label>{t('InvoiceShow::Balance:')}</label>
            <label>
              {NumberFormatter.formatCurrency(invoice.currency, setting.number_format, Number(invoice.amount) - paidAmount)}
            </label>
          </InvoiceDetailsListItem>

          <SidebarActions>
            <SidebarAction onClick={this.onViewTransactionHistory}>
              {t('InvoiceShow::View transaction history')}
            </SidebarAction>
            <SidebarAction onClick={this.onAddPaymentClick}>
              {t('InvoiceShow::Add payment')}
            </SidebarAction>
          </SidebarActions>
        </InvoiceDetailsList>
      </>
    )
  }

  renderAssets() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { invoice } = this.state

    if (invoice.type !== LedgerItemType.INVOICE) return null
    const featureEnabled = setting.invoice_paid_confirmation_enabled

    return (
      <>
        <InvoiceDetailsContentHeader style={{ marginTop: Style.spacing.x0_5 }} >
          {t('InvoiceShow::Payment confirmation')} {invoice?.data?.payment_confirmation_attachments.length > 0 ? `(${invoice?.data?.payment_confirmation_attachments.length})` : null}
          <span style={{ marginLeft: Style.spacing.x0_5 }}>
            <Tooltip
              content={t("InvoiceShow::Add a message or assets to the payment confirmation email. These will be sent to your contact when the invoice gets paid.")}
            />
          </span>
        </InvoiceDetailsContentHeader >

        <InvoiceDetailsList>
          <InvoiceDetailsListItem>
            <label>
              {t('InvoiceShow::Enabled')}
            </label>

            <label>
              <Switch
                name='reminders_enabled'
                onClick={this.onToggleInvoicePaymentConfirmationEmail}
                checked={setting.invoice_paid_confirmation_enabled}
              />
            </label>
          </InvoiceDetailsListItem>
        </InvoiceDetailsList>

        {featureEnabled && <>
          {!invoice.email_contact_id && <div style={{ marginBottom: 8 }}>
            <Alert
              type='warning'
              text={<Trans t={t}>No contact configured to send confirmation email. <Link to={RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: invoice.id })}>Configure</Link></Trans>}
            />
          </div>}
          <textarea
            placeholder={t('InvoiceShow::Add a message to the payment confirmation email.')}
            onChange={this.onPaymentConfirmationMessageChange}
            value={invoice?.data?.payment_confirmation_message || ''}
          />

          {invoice?.data?.payment_confirmation_attachments?.length > 0 && <>
            <Attachments>
              {invoice?.data?.payment_confirmation_attachments?.map((attachment, index) => {
                return (
                  <EmailAttachment
                    file={attachment}
                    onClick={() => this.onConfirmationAttachmentClick(index)}
                    onDeleteClick={() => this.onDeleteConfirmationAttachmentClick(index)}
                  />
                )
              })}
            </Attachments>
          </>}

          <DropzoneContainer>
            <Dropzone
              dropzoneProps={{
                onDrop: this.onAssetFileDrop,
                multiple: true,
              }}
            />
          </DropzoneContainer>
        </>
        }
      </>
    )
  }

  getMainActions(): IActionListItem[] {
    const { t } = this.props
    const { invoice, quotationMetadata, invoiceMetadata } = this.state

    if (invoice) {
      if (invoice.type === LedgerItemType.ORDER_FORM) {
        return [
          { key: 'new', icon: 'invoice', content: t('InvoiceShow::New {{ type }}', { type: invoice ? LedgerItemHelper.getTypeLabel(invoice.type).toLowerCase() : '' }) },
          { key: 'new_project', icon: 'project', content: t('InvoiceShow::New project'), visible: Boolean(invoice.contact_id) && !Boolean(invoice.project_id) },
          { key: 'duplicate', icon: 'duplicate', content: t('InvoiceShow::Duplicate') },
          { key: 'edit', icon: 'edit', content: t('InvoiceShow::Edit') },
          { key: 'delete', icon: 'trash-alt-solid', content: t('InvoiceShow::Delete'), destructive: true }
        ]
      }
      else if (invoice.type === LedgerItemType.DELIVERY_NOTE) {
        return [
          { key: 'new', icon: 'invoice', content: t('InvoiceShow::New {{ type }}', { type: invoice ? LedgerItemHelper.getTypeLabel(invoice.type).toLowerCase() : '' }) },
          { key: 'new_project', icon: 'project', content: t('InvoiceShow::New project'), visible: Boolean(invoice.contact_id) && !Boolean(invoice.project_id) },
          { key: 'duplicate', icon: 'duplicate', content: t('InvoiceShow::Duplicate') },
          { key: 'edit', icon: 'edit', content: t('InvoiceShow::Edit') },
          { key: 'delete', icon: 'trash-alt-solid', content: t('InvoiceShow::Delete'), destructive: true }
        ]
      }
      else if (invoice.type === LedgerItemType.QUOTATION) {
        return [
          { key: 'new', icon: 'invoice', content: t('InvoiceShow::New {{ type }}', { type: invoice ? LedgerItemHelper.getTypeLabel(invoice.type).toLowerCase() : '' }) },
          { key: 'new_project', icon: 'project', content: t('InvoiceShow::New project'), visible: Boolean(invoice.contact_id) && !Boolean(invoice.project_id) },
          { key: 'new_advance_invoice', icon: 'donate', content: t('InvoiceShow::Create Advance invoice'), visible: Boolean(quotationMetadata && Number(quotationMetadata.amount_invoiced) === 0 && quotationMetadata.invoices.length === 0) },
          { key: 'new_interim_invoice', icon: 'exchange-alt', content: t('InvoiceShow::Create interim invoice'), visible: Boolean(quotationMetadata && Number(quotationMetadata.unbilled_amount) > 0) },
          { key: 'new_final_invoice', icon: 'coins', content: t('InvoiceShow::Create finalisation invoice'), visible: Boolean(quotationMetadata && Number(quotationMetadata.unbilled_amount) > 0) },
          { key: 'duplicate', icon: 'duplicate', content: t('InvoiceShow::Duplicate') },
          { key: 'edit', icon: 'edit', content: t('InvoiceShow::Edit') },
          { key: 'delete', icon: 'trash-alt-solid', content: t('InvoiceShow::Delete'), destructive: true }
        ]
      } else if (invoice.type === LedgerItemType.PRO_FORMA_INVOICE) {
        return [
          { key: 'new', icon: 'invoice', content: t('InvoiceShow::New {{ type }}', { type: invoice ? LedgerItemHelper.getTypeLabel(invoice.type).toLowerCase() : '' }) },
          { key: 'new_project', icon: 'project', content: t('InvoiceShow::New project'), visible: Boolean(invoice.contact_id) && !Boolean(invoice.project_id) },
          { key: 'duplicate', icon: 'duplicate', content: t('InvoiceShow::Duplicate') },
          { key: 'edit', icon: 'edit', content: t('InvoiceShow::Edit') },
          { key: 'delete', icon: 'trash-alt-solid', content: t('InvoiceShow::Delete'), destructive: true }
        ]
      } else if (invoice.type === LedgerItemType.INVOICE) {
        return [
          { key: 'new', icon: 'invoice', content: t('InvoiceShow::New {{ type }}', { type: LedgerItemHelper.getTypeLabel(invoice.type).toLowerCase() }) },
          { key: 'new_interim_invoice', icon: 'exchange-alt', content: t('InvoiceShow::Create interim invoice'), visible: Boolean(invoice.quotation_id) && Boolean(invoiceMetadata && Number(invoiceMetadata.unbilled_amount) > 0) },
          { key: 'new_final_invoice', icon: 'coins', content: t('InvoiceShow::Create finalisation invoice'), visible: Boolean(invoice.quotation_id) && Boolean(invoiceMetadata && Number(invoiceMetadata.unbilled_amount) > 0) },
          { key: 'create_credit_note', icon: 'invoice', content: t('InvoiceShow::Create credit note') },
          { key: 'duplicate', icon: 'duplicate', content: t('InvoiceShow::Duplicate') },
          { key: 'edit', icon: 'edit', content: t('InvoiceShow::Edit') },
          { key: 'delete', icon: 'trash-alt-solid', content: t('InvoiceShow::Delete'), destructive: true }
        ]
      }
    }

    return [
      { key: 'edit', icon: 'edit', content: t('InvoiceShow::Edit') },
      { key: 'delete', icon: 'trash-alt-solid', content: t('InvoiceShow::Delete'), destructive: true }
    ]
  }

  renderMetadata() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { invoice, orderFormMetadata, deliveryNoteMetadata, quotationMetadata, proFormaInvoiceMetadata, invoiceMetadata, creditNoteMetadata } = this.state

    if (invoice.type === LedgerItemType.ORDER_FORM && orderFormMetadata) {
      const { delivery_note, invoice: metadataInvoice } = orderFormMetadata

      return (
        <>
          {delivery_note && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked delivery note')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem key={delivery_note.id} to={RouteHelper.process(ERoute.PATH_INVOICE, { id: delivery_note.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {delivery_note.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(delivery_note.currency, setting.number_format, delivery_note.amount)}
                {LedgerItemHelper.getLedgerItemBadge(delivery_note)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}

          {metadataInvoice && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked invoice')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem key={metadataInvoice.id} to={RouteHelper.process(ERoute.PATH_INVOICE, { id: metadataInvoice.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {metadataInvoice.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(metadataInvoice.currency, setting.number_format, metadataInvoice.amount)}
                {LedgerItemHelper.getLedgerItemBadge(metadataInvoice)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}
        </>
      )
    }
    else if (invoice.type === LedgerItemType.DELIVERY_NOTE && deliveryNoteMetadata) {
      const metadataPieces = []
      const { order_form, invoice: metadataInvoice, } = deliveryNoteMetadata

      return (
        <>
          {order_form && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked order form')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem key={order_form.id} to={RouteHelper.process(ERoute.PATH_INVOICE, { id: order_form.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {order_form.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(order_form.currency, setting.number_format, order_form.amount)}
                {LedgerItemHelper.getLedgerItemBadge(order_form)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}

          {metadataInvoice && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked invoice')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem key={metadataInvoice.id} to={RouteHelper.process(ERoute.PATH_INVOICE, { id: metadataInvoice.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {metadataInvoice.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(metadataInvoice.currency, setting.number_format, metadataInvoice.amount)}
                {LedgerItemHelper.getLedgerItemBadge(metadataInvoice)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}
        </>
      )
    }
    else if (invoice.type === LedgerItemType.QUOTATION && quotationMetadata) {
      const { invoices, unbilled_amount, amount_invoiced } = quotationMetadata

      if (invoices && invoices.length > 0) {
        const unbilledAmount = Number(unbilled_amount)

        return (
          <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked invoices')}
            </InvoiceDetailsContentHeader>
            {invoices && invoices.map(invoice => {
              return (
                <LedgerItemMetadataItem key={invoice.id} to={RouteHelper.process(ERoute.PATH_INVOICE, { id: invoice.id })} replace={true}>
                  <LedgerItemMetadataItemNumber>
                    {invoice.number}
                  </LedgerItemMetadataItemNumber>

                  <LedgerItemMetadataAmount>
                    {NumberFormatter.formatCurrency(invoice.currency, setting.number_format, invoice.amount)}
                    {LedgerItemHelper.getLedgerItemBadge(invoice)}
                  </LedgerItemMetadataAmount>
                </LedgerItemMetadataItem>
              )
            })}

            {unbilledAmount > 0 && <>
              <LedgerItemMetadataAction type='warning' onClick={() => {
                this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, {
                  contact_id: invoice.contact ? invoice.contact.id : '',
                  project_id: invoice.project ? invoice.project.id : '',
                  quotation_id: invoice.type === LedgerItemType.QUOTATION ? invoice.id : invoice.quotation_id,
                  type: 'invoice',
                  invoice_type: 'interim',
                }))
              }}>
                <Icon icon='exchange-alt' />
                {t('InvoiceShow::Create interim invoice')}
              </LedgerItemMetadataAction>
              <LedgerItemMetadataAction
                type='danger'
                onClick={() => {
                  this.props.showConfirmModal({
                    title: t('InvoiceShow::Finalisation Invoice'),
                    description: t('InvoiceShow::You are about to create a final invoice. Are you sure?'),
                    action: {
                      label: t('InvoiceShow::Confirm')
                    },
                    onConfirm: () => {
                      this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, {
                        contact_id: invoice.contact ? invoice.contact.id : '',
                        project_id: invoice.project ? invoice.project.id : '',
                        quotation_id: invoice.type === LedgerItemType.QUOTATION ? invoice.id : invoice.quotation_id,
                        type: 'invoice',
                        invoice_type: 'final'
                      }))
                    }
                  })
                }}>
                <Icon icon='coins' />
                {t('InvoiceShow::Create finalisation invoice')}
              </LedgerItemMetadataAction>
            </>}
          </LedgerItemMetadata>
        )
      }
    } else if (invoice.type === LedgerItemType.PRO_FORMA_INVOICE && proFormaInvoiceMetadata) {
      const { invoices } = proFormaInvoiceMetadata

      return (
        <LedgerItemMetadata>
          <InvoiceDetailsContentHeader>
            {t('InvoiceShow::Linked invoices')}
          </InvoiceDetailsContentHeader>
          {invoices && invoices.map(invoice => {
            return (
              <LedgerItemMetadataItem key={invoice.id} to={RouteHelper.process(ERoute.PATH_INVOICE, { id: invoice.id })} replace={true}>
                <LedgerItemMetadataItemNumber>
                  {invoice.number}
                </LedgerItemMetadataItemNumber>

                <LedgerItemMetadataAmount>
                  {NumberFormatter.formatCurrency(invoice.currency, setting.number_format, invoice.amount)}
                </LedgerItemMetadataAmount>
              </LedgerItemMetadataItem>
            )
          })}
        </LedgerItemMetadata>
      )
    }
    else if (invoice.type === LedgerItemType.INVOICE && invoiceMetadata) {
      const { order_form, delivery_note, quotation, pro_forma_invoice } = invoiceMetadata

      return (
        <>
          {order_form && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked order form')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem to={RouteHelper.process(ERoute.PATH_INVOICE, { id: order_form.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {order_form.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(order_form.currency, setting.number_format, order_form.amount)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}

          {delivery_note && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked delivery note')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem to={RouteHelper.process(ERoute.PATH_INVOICE, { id: delivery_note.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {delivery_note.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(delivery_note.currency, setting.number_format, delivery_note.amount)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}

          {quotation && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked quotation')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem to={RouteHelper.process(ERoute.PATH_INVOICE, { id: quotation.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {quotation.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(quotation.currency, setting.number_format, quotation.amount)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}
          {pro_forma_invoice && <LedgerItemMetadata>
            <InvoiceDetailsContentHeader>
              {t('InvoiceShow::Linked proforma invoice')}
            </InvoiceDetailsContentHeader>
            <LedgerItemMetadataItem to={RouteHelper.process(ERoute.PATH_INVOICE, { id: pro_forma_invoice.id })} replace={true}>
              <LedgerItemMetadataItemNumber>
                {pro_forma_invoice.number}
              </LedgerItemMetadataItemNumber>

              <LedgerItemMetadataAmount>
                {NumberFormatter.formatCurrency(pro_forma_invoice.currency, setting.number_format, pro_forma_invoice.amount)}
              </LedgerItemMetadataAmount>
            </LedgerItemMetadataItem>
          </LedgerItemMetadata>}
        </>
      )
    } else if (invoice.type === LedgerItemType.CREDIT_NOTE && creditNoteMetadata) {
      const { invoice } = creditNoteMetadata

      return (
        <LedgerItemMetadata>
          <InvoiceDetailsContentHeader>
            {t('InvoiceShow::Linked invoice')}
          </InvoiceDetailsContentHeader>
          {invoice && <LedgerItemMetadataItem to={RouteHelper.process(ERoute.PATH_INVOICE, { id: invoice.id })} replace={true}>
            <LedgerItemMetadataItemNumber>
              {invoice.number}
            </LedgerItemMetadataItemNumber>

            <LedgerItemMetadataAmount>
              {NumberFormatter.formatCurrency(invoice.currency, setting.number_format, invoice.amount)}
              {LedgerItemHelper.getLedgerItemBadge(invoice)}
            </LedgerItemMetadataAmount>
          </LedgerItemMetadataItem>}
        </LedgerItemMetadata>
      )
    }

    return null
  }

  render() {
    const {
      didInitialLoad,
      invoice,
      conditions,
      isPreviewLoading,
      currentPage,
      pageCount,
      sendingPeppol
    } = this.state

    const {
      currentUser: {
        workspace,
      },
      t
    } = this.props

    const { setting } = workspace

    const mainActions = this.getMainActions()

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        <ScrollToTopOnMount />

        <TopNavigation
          icon='invoice'
          title={invoice ? LedgerItemHelper.getTypeLabel(invoice.type) : t('InvoiceShow::Invoice')}
        />

        <PageContent>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <>
            <PageHeader
              breadcrumbs={[
                { content: t('InvoiceShow::Back'), url: 'javascript://', onClick: this.onBreadCrumbBackPress }
              ]}
              title={<Link to={RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: invoice.id })}>{`${invoice.type !== LedgerItemType.RECURRING_INVOICE ? invoice.number : t('InvoiceShow::Recurring invoice')}`}</Link>}
              mainActions={mainActions}
              onMainActionClick={this.onPageHeaderActionClick}
            />

            {invoice.type !== LedgerItemType.RECURRING_INVOICE && <Timeline steps={this.getTimelineSteps()} />}

            <ActionCableConsumer
              channel={{ channel: 'WorkspaceChannel', id: workspace.id }}
              onConnected={this.onActionCableConnected}
              onDisconnected={this.onActionCableDisconnected}
              onReceived={this.onActionCableReceived}
            >
              <PreviewContainer>
                <div className='grid'>
                  <div className='grid-cell with-8col'>
                    <div className='invoice-template-editor'>
                      <DocumentWrapper>
                        {({ width, height }) => {
                          return (
                            <DocumentInnerWrapper>
                              {!isPreviewLoading && <>
                                <div className='invoice-template-editor-page-selector'>
                                  <LedgerItemPreviewNavigation
                                    currentPage={currentPage}
                                    totalPageCount={pageCount}
                                    onNavigateBack={this.onDocumentNavigateBack}
                                    onNavigateForward={this.onDocumentNavigateForward}
                                  />
                                </div>
                              </>}
                              {invoice.id && <DocumentPreview
                                key={invoice?.pdf_attachment_url || 'ledger-item-preview'}
                                file={invoice.pdf_attachment_url}
                                ref={this.document}
                                currentPage={currentPage}
                                width={width}
                                onLoadSuccess={({ numPages }) => this.setState({ isPreviewLoading: false, pageCount: numPages })}
                              />}
                            </DocumentInnerWrapper>
                          )
                        }}
                      </DocumentWrapper>


                      {conditions.length === 0 && !invoice.ledger_condition && <Link to={ERoute.PATH_LEDGER_CONDITIONS} className='invoice-link is-condition'>
                        {t('InvoiceShow::Add terms & conditions')}
                      </Link>}

                      {conditions.length > 0 && <div>
                        <div className={`invoice-conditions-switcher ${invoice.ledger_condition_id ? '' : 'non-assigned'}`}>
                          <Select
                            name='invoice_condition'
                            value={invoice.ledger_condition_id ? invoice.ledger_condition_id : ''}
                            onChange={this.onInvoiceConditionChange}
                            options={conditions.map(condition => { return { key: condition.id, label: condition.name, value: condition.id } })}
                            allowEmpty
                          />

                          <div style={{ position: 'relative' }}>
                            <div className='invoice-conditions-switcher-actions'>
                              <PanelAction
                                icon='pen'
                                onClick={() => {
                                  this.props.history.replace(ERoute.PATH_LEDGER_CONDITIONS)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  </div>

                  <div className='grid-cell with-4col'>
                    <InvoiceDetailsContainer>
                      <InvoiceDetailsHeader>
                        <InvoiceContactAndAmountDetails>
                          <InvoiceContactContainer>
                            {invoice.contact && <>
                              <Avatar rounded={true} width={60} name={invoice?.contact?.name || ''} onClick={this.onContactClick} />
                              <InvoiceContactDetails>
                                <h3 onClick={this.onContactClick}>{invoice.contact.name}</h3>
                                {invoice.project && <p onClick={this.onProjectClick} style={{ color: '#64768d' }}>{invoice.project.name}</p>}
                              </InvoiceContactDetails>
                            </>}
                          </InvoiceContactContainer>

                          <InvoiceAmount>
                            {NumberFormatter.formatCurrency(invoice.currency, setting.number_format, invoice.amount)}
                          </InvoiceAmount>

                        </InvoiceContactAndAmountDetails>

                        {invoice.type !== LedgerItemType.RECURRING_INVOICE && LedgerItemHelper.getLedgerItemDetailStatus(invoice)}

                        {invoice.type !== LedgerItemType.RECURRING_INVOICE && <InvoiceDetailsActions>
                          {/* if not an invoice or a creditnote only show PDF download */}
                          {![LedgerItemType.INVOICE, LedgerItemType.CREDIT_NOTE].includes(invoice.type) && <Button
                            type='success'
                            text={t('InvoiceShow::Download')}
                            onClick={() => this.onDownloadClick('pdf')}
                            icon='download'
                            disabled={!Boolean(invoice.pdf_attachment_url)}
                            tooltip={!Boolean(invoice.pdf_attachment_url) ? t('InvoiceShow::PDF is being generated') : ''}
                          />}

                          {/* If an invoice or a creditnote show PDF & UBL download */}
                          {[LedgerItemType.INVOICE, LedgerItemType.CREDIT_NOTE].includes(invoice.type) && <ButtonDropdown
                            text={t('InvoiceShow::Download')}
                            buttonClasses='button button-success'
                            icon='download'
                            style={{ width: '100%' }}
                            disabled={!Boolean(invoice.pdf_attachment_url)}
                            dataTip={!Boolean(invoice.pdf_attachment_url) ? t('InvoiceShow::PDF is being generated') : ''}
                          >
                            <ButtonDropdownOption key='pdf' onClick={() => this.onDownloadClick('pdf')}>
                              <Icon icon='pdf' />
                              {t('LedgerItemEditor::PDF')}
                            </ButtonDropdownOption>
                            <ButtonDropdownOption key='ubl' onClick={() => this.onDownloadClick('ubl')}>
                              <Icon icon='xml' />
                              {t('LedgerItemEditor::UBL')}
                            </ButtonDropdownOption>
                          </ButtonDropdown>}

                          {invoice.contact && <Button
                            type='default'
                            text={invoice.sent_at ? t('InvoiceShow::Resend') : t('InvoiceShow::Send')}
                            onClick={this.onSendClick}
                            icon='send'
                            disabled={!Boolean(invoice.pdf_attachment_url)}
                            tooltip={!Boolean(invoice.pdf_attachment_url) ? t('InvoiceShow::PDF is being generated') : ''}
                          />}

                          {!workspace.peppol_enabled && <>
                            {(!Boolean(workspace.peppol_status) || workspace.peppol_status === PeppolWorkspaceStatus.REJECTED) && <PeppolButtonContainer onClick={this.onConfigurePeppolClick} style={{ width: '100%' }}>
                              <Button
                                type='default'
                                text={t('InvoiceShow::Request Peppol access')}
                                icon='send'
                                tooltip={t('InvoiceShow::Request access to the Peppol network')}
                                disabled={!Boolean(invoice.pdf_attachment_url)}
                              />
                            </PeppolButtonContainer>}

                            {workspace.peppol_status === PeppolWorkspaceStatus.PENDING && <PeppolButtonContainer style={{ width: '100%' }}>
                              <Button
                                type='default'
                                text={t('InvoiceShow::Peppol access requested')}
                                icon='hourglass-half'
                                tooltip={t('InvoiceShow::Peppol access request pending')}
                                disabled={true}
                              />
                            </PeppolButtonContainer>}
                          </>}

                          {workspace.peppol_enabled && invoice?.contact && <>
                            {Boolean(invoice?.contact?.peppol_id) && <Button
                              type='default'
                              text={invoice.peppol_id ? t("InvoiceShow::Resend via Peppol") : t('InvoiceShow::Send via Peppol')}
                              icon='send'
                              isLoading={sendingPeppol}
                              onClick={this.onSendPeppolClick}
                              disabled={!Boolean(invoice.pdf_attachment_url)}
                              tooltip={!Boolean(invoice.pdf_attachment_url) ? t('InvoiceShow::PDF is being generated') : ''}
                            />}
                            {!Boolean(invoice?.contact?.peppol_id) && <Button
                              type='default'
                              text={invoice.peppol_id ? t("InvoiceShow::Resend via Peppol") : t('InvoiceShow::Send via Peppol')}
                              icon='send'
                              tooltip={t('InvoiceShow::Contact is not linked to Peppol')}
                              isLoading={sendingPeppol}
                              onClick={this.onPeppolContactLinkClick}
                              disabled={!Boolean(invoice.pdf_attachment_url)}
                            />}
                          </>}

                          {
                            invoice.type === LedgerItemType.INVOICE &&
                            invoice.contact &&
                            invoice.due_on &&
                            !invoice.paid_on &&
                            invoice.credited_on === null &&
                            LedgerItemHelper.isOverdue(invoice)
                            && <>
                              <Button
                                text={t('InvoiceShow::Send payment reminder')}
                                type='warning'
                                icon='bell'
                                onClick={this.onSendPaymentReminderClick}
                              />

                              <Button
                                text={t('InvoiceShow::Bailiff')}
                                type='danger'
                                icon='bank'
                                onClick={this.onSendBaillifClick}
                              />
                            </>}
                        </InvoiceDetailsActions>}
                      </InvoiceDetailsHeader>

                      <InvoiceDetailsContent>
                        <InvoiceDetailsContentHeader>
                          {t('InvoiceShow::Details')}
                        </InvoiceDetailsContentHeader>

                        {this.renderDetailsList()}
                        {this.renderPeppol()}
                        {this.renderPayments()}
                        {this.renderAssets()}

                        {invoice.type === LedgerItemType.INVOICE && <>
                          <InvoiceDetailsContentHeader>
                            {t('InvoiceShow::Invoice reminders')}
                            <span style={{ marginLeft: Style.spacing.x0_5 }}>
                              <Tooltip
                                content={t("InvoiceShow::With invoice reminders we'll automatically nudge your contacts about their outstanding invoice.")}
                              />
                            </span>
                          </InvoiceDetailsContentHeader>

                          <InvoiceDetailsList>
                            <InvoiceDetailsListItem>
                              <label>
                                {t('InvoiceShow::Enabled')}
                              </label>

                              <label style={{ minWidth: 112 }}>
                                <Switch
                                  name='reminders_enabled'
                                  onClick={this.onLedgerItemRemindersEnabledClick}
                                  checked={invoice.reminders_enabled}
                                />
                              </label>
                            </InvoiceDetailsListItem>

                            {invoice.reminders_enabled && !invoice.email_contact_id && <InvoiceDetailsListItem style={{ marginBottom: 4 }}>
                              <Alert
                                type='warning'
                                text={<Trans t={t}>No contact configured to enable automatic reminders. <Link to={RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: invoice.id })}>Configure</Link></Trans>}
                              />
                            </InvoiceDetailsListItem>}

                            {invoice.reminders_count > 0 && <InvoiceDetailsListItem>
                              <label>{t('InvoiceShow::Reminder attempt:')}</label>
                              <label>
                                {`${Math.min(invoice.reminders_count, setting.invoice_reminders_limit)} / ${setting.invoice_reminders_limit}`}
                              </label>
                            </InvoiceDetailsListItem>}

                            {invoice.last_reminder_sent_at && <InvoiceDetailsListItem>
                              <label>{t('InvoiceShow::Last reminder sent at:')}</label>
                              <label>
                                {moment(invoice.last_reminder_sent_at).format(setting.date_format)}
                              </label>
                            </InvoiceDetailsListItem>}
                          </InvoiceDetailsList>
                        </>}
                      </InvoiceDetailsContent>

                    </InvoiceDetailsContainer>

                    {this.renderMetadata()}
                  </div>
                </div>
              </PreviewContainer>
            </ActionCableConsumer>
          </>}
        </PageContent>
      </>
    )
  }
}

interface IStateToProps {
  currentUser: CurrentUser
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    showSendLedgerItemModal: (options) => dispatch(showSendLedgerItemModal(options)),
    showProjectStatusUpdateModal: (options) => dispatch(showProjectStatusUpdateModal(options)),
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showLedgerItemPaymentModal: (options) => dispatch(showLedgerItemPaymentModal(options)),
    showLedgerItemTransactionHistoryModal: (options) => dispatch(showLedgerItemTransactionHistoryModal(options)),
    showledgerItemClaimModal: (options) => dispatch(showledgerItemClaimModal(options)),
    showAttachmentsViewerModal: (options) => dispatch(showAttachmentsViewerModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoiceShow))