import * as React from 'react'

interface IProps {
	title: string
	description: string
	onChange: (checked: boolean) => void
	checked: boolean
	mode?: 'radio' | null
	checkedHighlight: boolean
}

export default class Option extends React.PureComponent<IProps> {
	static defaultProps = {
		mode: 'radio',
		checkedHighlight: true
	}

	constructor(props) {
		super(props)

		this.onChange = this.onChange.bind(this)
	}

	onChange(e) {
		e.preventDefault()

		const { checked, onChange } = this.props

		onChange(!checked)
	}

	render () {
		const { mode, checked, checkedHighlight, title, description } = this.props

		return (
			<div className={`setting-option ${mode === 'radio' ? 'is-radio' : 'is-checkbox'} ${(checked && checkedHighlight) ? 'is-active' : ''}`} onClick={this.onChange}>
				<div className='setting-option-button'>
					<input type='radio' value='radio' checked={checked} readOnly />
				</div>

				<div className='setting-option-content'>
					<h3 className='setting-option-title'>{title}</h3>
					{description && <p className='setting-option-description'>
						{description}
					</p>}
				</div>
			</div>
		);
	}
}