import * as React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Style } from '../../styles'
import { Deal as DealType, DealStage as DealStageType } from '../../types'
import NumberFormatter from '../../utilities/NumberFormatter'
import Deal from './Deal'
import Icon from '../Icons/Icon'
import DealHelper from '../../helpers/DealHelper'
import DealStageTitle from './DealStageTitle'

const AddDeal = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${Style.variables.baseBorderRadius};
	padding: 4px;
	height: 32px;
	cursor: pointer;
	color: #21232cb8;
	opacity: 0;


	&:hover {
		border: 1px solid ${Style.color.border};
		background: #e3e3e3;
		color: black;
	}
`

const Container = styled.div`
	background-color: #ebecf0;
	display: flex;
	flex: 1 0 306px;
	max-width: 306px;
	height: 100%;
	flex-direction: column;
	overflow: hidden;
	padding: 12px;
	position: relative;
	word-break: break-word;
	border-radius: ${Style.variables.baseBorderRadius};
	border: 1px solid ${Style.color.border};

	&:not(:last-child) {
		margin-right: 6px;
	}

	:hover {
		${AddDeal} {
			opacity: 1;
		}
	}
`

const DealsContainer = styled.div`
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: ${Style.spacing.x1};
`

interface IProps {
	index: number
	dealStage: DealStageType
	deals: DealType[]
	currency: string
	numberFormat: string
	dateFormat: string
	onChangeDealStageName: (listId: string, name: string) => void
	onDealClick: (deal: DealType) => void
	onMoveDealsClick: (sourceList: DealStageType, destinationList: DealStageType) => void
	onDeleteDealsClick: (dealStage: DealStageType) => void
	onEditDealStageClick: (dealStage: DealStageType) => void
	onDeleteDealStageClick: (dealStage: DealStageType) => void
	onAddDealClick: (dealStage: DealStageType, position: number) => void
}

const DealStage = (props: IProps) => {
	const {
		index,
		dealStage,
		deals,
		currency,
		numberFormat,
		dateFormat,
		onDealClick,
	} = props
	const [dragBlocking, setDragBlocking] = React.useState(false);

	const dealsAmount = deals.reduce((amount, deal) => amount + (deal.amount >= 0 ? Number(deal.amount) : 0), 0)

	const onDealStageNameChange = (name: string) => {
		props.onChangeDealStageName(dealStage.id, name)
	}

	const onAddDealClick = () => {
		props.onAddDealClick(dealStage, (deals.length + 1) * DealHelper.POSITION_STEP_INDEX)
	}

	const onMoveDealsClick = (newDealStage: DealStageType) => {
		props.onMoveDealsClick(dealStage, newDealStage)
	}

	const onDeleteDealsClick = () => {
		props.onDeleteDealsClick(dealStage)
	}

	const onDealStageEditClick = () => {
		props.onEditDealStageClick(dealStage)
	}

	const onDealStageDeleteClick = () => {
		props.onDeleteDealStageClick(dealStage)
	}

	return (
		<Draggable
			key={dealStage.id}
			draggableId={dealStage.id}
			index={index}
			isDragDisabled={false}
			disableInteractiveElementBlocking={!dragBlocking}

		>
			{provided => (
				<Container
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<DealStageTitle
						dealStage={dealStage}
						name={dealStage.name}
						dragHandleProps={provided.dragHandleProps}
						onNameChange={onDealStageNameChange}
						onAddDealClick={onAddDealClick}
						onMoveDealsClick={onMoveDealsClick}
						onDeleteDealsClick={onDeleteDealsClick}
						onEditClick={onDealStageEditClick}
						onDeleteClick={onDealStageDeleteClick}
						setDragBlocking={setDragBlocking}
						dealsCount={deals.length}
						dealsAmount={NumberFormatter.formatCurrency(currency, numberFormat, dealsAmount)}
					/>
					<Droppable key={dealStage.id} droppableId={dealStage.id}>
						{(droppableProvided, droppableSnapshot) => {
							return (
								<DealsContainer ref={droppableProvided.innerRef}>
									{deals.map((deal, index) => {
										return (
											<Deal
												key={deal.id}
												index={index}
												deal={deal}
												numberFormat={numberFormat}
												dateFormat={dateFormat}
												onClick={onDealClick}
											/>
										)
									})}
									{droppableProvided.placeholder}
									<AddDeal onClick={onAddDealClick}>
										<Icon icon='plus' />
									</AddDeal>
								</DealsContainer>
							)
						}}
					</Droppable>
				</Container>
			)
			}
		</Draggable>
	)
}

export default DealStage