import * as React from 'react'
import styled from "styled-components";
import StepHeaderContent from "./StepHeaderContent";
import StepHeaderDragGrip from './StepHeaderDragHandle';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Icon, { IconTypes } from '../Icons/Icon';
import StepHeaderIcon from './StepHeaderIcon';
import { useTranslation } from 'react-i18next';
import StepHeaderTitle from './StepHeaderTitle';
import StepHeaderToggle from './StepHeaderToggle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 16px;
`



interface IProps {
  icon: IconTypes
  iconColor: string
  title: string
  open: boolean
  onToggleOpen: () => void
  children?: React.ReactNode | React.ReactNode[]
  dragHandleProps: DraggableProvidedDragHandleProps
  editable: boolean
}

const StepHeader = (props: IProps) => {
  const { t } = useTranslation()
  const {
    iconColor,
    icon,
    title,
    dragHandleProps,
    open,
    onToggleOpen,
    editable
  } = props
  return (
    <Container onClick={onToggleOpen}>
      <StepHeaderContent>
        <div>
          {editable && <StepHeaderDragGrip {...dragHandleProps} data-tip={t('Block::Reorder')}>
            <Icon icon='grip-vertical' />
          </StepHeaderDragGrip>}
          <StepHeaderIcon color={iconColor}>
            <Icon icon={icon} />
          </StepHeaderIcon>
          <StepHeaderTitle>
            {title}
          </StepHeaderTitle>
        </div>
        <div>
          <StepHeaderToggle active={!open} />
        </div>
      </StepHeaderContent>
    </Container>
  )
}
export default StepHeader