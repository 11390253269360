import styled from "styled-components";
import { Style } from "../../styles";

const SimplebarAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  text-align: center;
  border-top: 1px solid ${Style.color.border};

  &:hover {
    text-decoration: underline;
  }
`

export default SimplebarAction