import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import { CustomField } from '../../types'
import Icon from '../Icons/Icon'

const Drag = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
  cursor: ns-resize;
	margin-right: ${Style.spacing.x1};

  svg {
    width: 10px;
    color: #d6d6d6;
  }
`

const Delete = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: ${Style.spacing.x1};
	cursor: pointer;
	width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  min-height: 30px !important;

	i, svg {
		font-size: 15px !important;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const Wrapper = styled.div`
	flex: 1;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	padding: 14px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	transition: all .1s ease;
	cursor: pointer;
	position: relative;
	margin-top: 4px;
	margin-bottom: 4px;

	i {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 14px;
		color: #4389fc;
	}

	&:hover {
		color: ${Style.color.brandPrimary};
    background: rgba(67, 137, 252, 0.1);

		${Delete} {
			opacity: 1;
		}
	}

	&:nth-child(odd) {
		margin-right: 8px;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
`

const Name = styled.div`
	font-size: 14px;

`
const Type = styled.div`
	font-size: 12px;
	color: #5a6f90;
`


interface IProps {
	index: number
	customField: CustomField
	onClick: (customField: CustomField) => void
	onDeleteClick: (customField: CustomField) => void
}

const CustomFieldItem = (props: IProps) => {
	const { t } = useTranslation()
	const { index, customField, onClick, onDeleteClick } = props

	return (
		<Draggable key={String(customField.id)} draggableId={String(customField.id)} index={index}>
			{(provided) => {
				return (
					<Container
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
					>
						<Drag
							{...provided.dragHandleProps}
						>
							<Icon icon='grip-vertical' />
						</Drag>
						<Wrapper onClick={() => onClick(props.customField)}>
							<Content>
								<Name>{customField.name}</Name>
								<Type>{t(`CustomFieldType::${customField.type}`)}</Type>
							</Content>
						</Wrapper>
						<Delete className='button-icon button-danger' onClick={() => onDeleteClick(props.customField)}>
							<Icon icon='close' />
						</Delete>
					</Container>
				)
			}}
		</Draggable>
	)
}

export default CustomFieldItem