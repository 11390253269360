import * as React from 'react'
import SubscriptionController from '../../controllers/SubscriptionController'
import TopNavigation from '../../components/Navigation/TopNavigation'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import moment from '../../utilities/Moment'
import Icon from '../../components/Icons/Icon'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { Dispatch } from 'redux';
import { showPaymentDetailsModal } from '../../store/modals/actions'
import UrlHelper from '../../helpers/UrlHelper'
import Images from '../../images'
import PricingCard from '../../components/Pricing/PricingCard'
import Sound, { Sounds } from '../../sounds'
import Panel from '../../components/Panel/Panel'
import Button from '../../components/Button/Button';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import SubscriptionInvoices from '../../components/Subscription/SubscriptionInvoices'
import PageLoader from '../../components/Page/PageLoader'
import PageContent from '../../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import DataLayerHelper, { DataLayerEventType } from '../../helpers/DataLayerHelper'
import { CurrentUser, Plan, SubscriptionInterval, SubscriptionStatus, Workspace } from '../../types'
import CurrentUserHelper from '../../helpers/CurrentUserHelper'
import ERoute from '../../ERoute'
import Alert from '../../components/Alert/Alert'
import { updateWorkspace } from '../../store/authentication/actions'
import ChatWidgetHelper from '../../helpers/ChatWidgetHelper'

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateWorkspace: typeof updateWorkspace
  showPaymentDetailsModal: typeof showPaymentDetailsModal
}

type IProps = { paywall?: boolean } & IStateToProps & IDispatchToProps & RouteComponentProps<any> & WithTranslation


interface IState {
  selectedPlan: string
  selectedInterval: number
  plans: Plan[]
  intervals: SubscriptionInterval[]
  didInitialLoad: boolean
}

class Subscriptions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { workspace: { subscription } } = props.currentUser

    this.state = {
      selectedPlan: subscription.plan.id,
      selectedInterval: 1,
      plans: [],
      intervals: [],
      didInitialLoad: false
    }

    this.onSelectPlan = this.onSelectPlan.bind(this)
    this.onSelectInterval = this.onSelectInterval.bind(this)
    this.onUpdateCardDetails = this.onUpdateCardDetails.bind(this);
    this.onCancelSubscription = this.onCancelSubscription.bind(this);
    this.onPaymentDetailsFormSubmit = this.onPaymentDetailsFormSubmit.bind(this)
  }

  componentDidMount() {
    this.fetchForm();

    // Show chat widget when paywall is active
    if (this.props.paywall) {
      ChatWidgetHelper.onReady(() => {
        setTimeout(() => {
          ChatWidgetHelper.show()
        }, 500)
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.props.paywall && CurrentUserHelper.isSubscriptionActive() && CurrentUserHelper.hasBusinessSubscription()) {
      // When paid subscription is active again, hide the chat widget

      setTimeout(() => {
        ChatWidgetHelper.hide()
      }, 500)

      // Redirect & replace current path to dashboard path
      this.props.history.replace(ERoute.PATH_DASHBOARD)
    }
  }

  async fetchForm() {
    try {
      const response = await SubscriptionController.fetchForm()

      const { plans, intervals } = response

      this.setState({
        plans: plans,
        intervals: intervals,
        didInitialLoad: true,
      });

      if (this.props.location?.search) {
        const params = UrlHelper.getParams(this.props.location.search)

        if (params.enterPaymentDetails) {
          this.props.showPaymentDetailsModal(this.onPaymentDetailsFormSubmit)
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onCancelSubscription(e) {
    const { t } = this.props
    e.preventDefault();

    var confirmed = confirm(t('Subscription::Warning! Are you sure you want to suspend your subscription?'))

    if (confirmed) {
      try {
        const response = await SubscriptionController.deleteSubscription()

        if (response.errors) {
        }
        else {
          const subscription = response
          this.setState({ selectedPlan: subscription.plan.id, });
        }
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  onPaymentDetailsFormSubmit(workspace: Workspace) {
    this.props.updateWorkspace(workspace)
  }

  onUpdateCardDetails() {
    const { showPaymentDetailsModal } = this.props
    showPaymentDetailsModal(this.onPaymentDetailsFormSubmit)
  }

  onSelectPlan(plan: Plan) {
    const { currentUser } = this.props

    this.setState({
      selectedPlan: plan.id,
    }, async () => {
      try {
        const response = await SubscriptionController.updatePlan(plan.id)
        if (response.errors) {
          if (response?.errors?.payment_details_required)
            this.setState({
              selectedPlan: currentUser.workspace.subscription.plan.id,
            });

          this.onUpdateCardDetails()
        }
        else {
          DataLayerHelper.push({ event: DataLayerEventType.SUBSCRIBED, value: plan.price })

          Sound.play(Sounds.COMPLETE)
        }
      } catch (ex) {
        console.error(ex)
      }
    })
  }

  onSelectInterval(interval) {
    this.setState({
      selectedInterval: interval.id
    });
  }

  render() {
    const { currentUser: { workspace }, t, paywall } = this.props
    const { card, subscription } = workspace
    const { didInitialLoad, selectedPlan, plans } = this.state;

    return (
      <>
        <Helmet>
          <title>{t('Subscription::{{__appName}} | Subscription')}</title>
        </Helmet>

        {!paywall && <TopNavigation
          icon='user'
          title={t('Subscription::Subscription')}
        />}

        <ScrollToTopOnMount />

        <PageContent>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <>
            <div className='grid'>
              {paywall && <div className='grid-cell with-12col' style={{ marginBottom: 12 }}>
                <Alert
                  text={subscription?.status === SubscriptionStatus.UNPAID ? t('Subscription::You have an outstanding invoice that needs to be paid before you can access {{__appName}}.') : t('Subscription::You need to subscribe to a plan to access {{__appName}}.')}
                  type='warning'
                />
              </div>}

              <div className='grid-cell with-6col'>
                <div className='pricing-cards'>
                  {plans.map(plan => {
                    const active = plan.id === selectedPlan

                    if (plan.name === 'Business') {
                      return (
                        <PricingCard
                          key={plan.id}
                          imageUrl={Images.PRICING_BUSINESS_PRO}
                          title='Business'
                          price='€ 19,99'
                          period={t('Subscription::/ month')}
                          features={[
                            t('Subscription::Manage <b>unlimited</b> contacts'),
                            t('Subscription::Plan <b>unlimited</b> projects'),
                            t('Subscription::<b>Unlimited invoices</b> per month'),
                            t('Subscription::<b>Calendar view</b>'),
                            t('Subscription::<b>Financial reporting</b>'),
                            t('Subscription::<b>Payment reminders</b>'),
                            t('Subscription::Create and manage <b>tasks</b>'),
                            t('Subscription::Build eye-catching <b>proposals</b>'),
                            t('Subscription::Create & sign <b>contracts</b>'),
                            t('Subscription::Store your <b>files</b> in one place.'),
                            t('Subscription::Collect valuable information with <b>forms</b>'),
                          ]}
                          onClick={active ? (e) => this.onCancelSubscription(e) : () => this.onSelectPlan(plan)}
                          active={active}
                        />
                      )
                    }

                    return null
                  })}
                </div>
              </div>

              <div className='grid-cell with-6col'>
                {card && <Panel title={t('Subscription::Payment details')}>
                  <div className='form-item'>
                    <label>{t('Subscription::Payment method')}</label>

                    {card && <div className='payment-info-card'>
                      <div className='payment-info-card-icon-container'>
                        {card.brand === 'sepa_debit' && <img src={Images.PRICING_SEPA} alt='SEPA' />}
                        {card.brand !== 'sepa_debit' && <Icon icon='mastercard' />}
                      </div>
                      <div className='payment-info-card-description'>
                        {card.brand === 'sepa_debit' && <div
                          dangerouslySetInnerHTML={{
                            __html: t('Subscription::<b>{{brand}}</b> ends with <b>{{last_digits}}</b>', {
                              brand: 'SEPA Debit',
                              last_digits: card.last_digits,
                            })
                          }}
                        />}
                        {card.brand !== 'sepa_debit' && <div
                          dangerouslySetInnerHTML={{
                            __html: t('Subscription::<b>{{brand}}</b> ends with <b>{{last_digits}}</b> and expires on <b>{{expiry_date}}</b>', {
                              brand: card.brand,
                              last_digits: card.last_digits,
                              expiry_date: moment(card.expires_on).format('MMMM YYYY')
                            })
                          }}
                        />}
                      </div>
                    </div>}
                  </div>

                  <div className='form-item'>
                    <a href='javascript://' className='button button-default' onClick={this.onUpdateCardDetails}>
                      {card ? t('Subscription::Update payment method') : t('Subscription::Add payment method')}
                    </a>
                  </div>
                </Panel>}

                {!card && <Panel title={t('Subscription::Payment details')}>
                  <div className='pricing-card-empty'>
                    <svg viewBox="0 0 167 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <g id="Empty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Card---Credit-Card" transform="translate(-122.000000, -105.000000)">
                          <g id="NoCreditCard" transform="translate(75.000000, 50.000000)">
                            <path d="M176,135 L93,135 C89.1340068,135 86,131.865993 86,128 C86,124.134007 89.1340068,121 93,121 L54,121 C50.1340068,121 47,117.865993 47,114 C47,110.134007 50.1340068,107 54,107 L94,107 C97.8659932,107 101,103.865993 101,100 C101,96.1340068 97.8659932,93 94,93 L69,93 C65.1340068,93 62,89.8659932 62,86 C62,82.1340068 65.1340068,79 69,79 L109,79 C105.134007,79 102,75.8659932 102,72 C102,68.1340068 105.134007,65 109,65 L207,65 C210.865993,65 214,68.1340068 214,72 C214,75.8659932 210.865993,79 207,79 L167,79 C170.865993,79 174,82.1340068 174,86 C174,89.8659932 170.865993,93 167,93 L189,93 C192.865993,93 196,96.1340068 196,100 C196,103.865993 192.865993,107 189,107 L178.826087,107 C173.951574,107 170,110.134007 170,114 C170,116.577329 172,118.910662 176,121 C179.865993,121 183,124.134007 183,128 C183,131.865993 179.865993,135 176,135 Z M207,107 C203.134007,107 200,103.865993 200,100 C200,96.1340068 203.134007,93 207,93 C210.865993,93 214,96.1340068 214,100 C214,103.865993 210.865993,107 207,107 Z" id="Background" fill="#F3F7FF" fillRule="evenodd"></path>
                            <path d="M69.9524711,70.517534 L152.977279,57.2258164 C154.613301,56.9639003 156.151883,58.0778327 156.413799,59.7138541 C156.414323,59.7171287 156.414842,59.7204042 156.415356,59.7236806 L164.537123,111.555773 C164.793011,113.188817 163.679715,114.721164 162.047529,114.982466 L79.0227206,128.274184 C77.3866992,128.5361 75.8481169,127.422167 75.5862008,125.786146 C75.5856765,125.782871 75.5851577,125.779596 75.5846443,125.776319 L67.462877,73.9442273 C67.2069892,72.3111835 68.3202849,70.7788361 69.9524711,70.517534 Z" id="Rectangle" fill="#FFFFFF" fillRule="evenodd"></path>
                            <path d="M83.3448504,127.423358 L80.1614775,127.948032 C77.9748078,128.308433 75.9214022,126.755955 75.5750677,124.480476 L68.0499395,75.0390458 C67.7036051,72.7635668 69.1954916,70.6267643 71.3821613,70.2663639 L151.558306,57.0519678 C153.744976,56.6915673 155.798381,58.2440452 156.144716,60.5195242 C156.48216,62.7365966 156.75109,64.5035117 156.951504,65.8202695 M157.523095,69.575722 C157.704628,70.7684246 157.863596,71.8128747 158,72.7090721" id="Shape" stroke="#75A4FE" strokeWidth="2.5" strokeLinecap="round"></path>
                            <path d="M73.9770284,72.7919422 L149.737046,60.9608709 C151.369014,60.7060145 152.899988,61.8182984 153.161959,63.4491391 L160.522114,109.268177 C160.784895,110.90406 159.671776,112.443231 158.035893,112.706011 C158.031587,112.706703 158.02728,112.707385 158.022972,112.708058 L82.2629539,124.539129 C80.6309863,124.793986 79.1000118,123.681702 78.8380411,122.050861 L71.4778857,76.231823 C71.2151051,74.5959402 72.3282244,73.0567695 73.9641072,72.7939889 C73.9684128,72.7932973 73.9727199,72.7926151 73.9770284,72.7919422 Z" id="Rectangle" fill="#E8F0FE" fillRule="evenodd"></path>
                            <rect id="Rectangle" stroke="#75A4FE" strokeWidth="2.5" fill="#FFFFFF" fillRule="evenodd" x="88.25" y="77.25" width="86.5" height="56.5" rx="4"></rect>
                            <rect id="Rectangle" fill="#E8F0FE" fillRule="evenodd" x="89.5" y="89" width="84" height="14"></rect>
                            <path d="M174.171113,89 L108.330882,89 L174.171113,89 Z M168.171113,102 L89.3315548,102 L168.171113,102 Z M125.0625,121 L96.7546471,121 L125.0625,121 Z M103.289063,89 L96.0926339,89 L103.289063,89 Z" id="lines" stroke="#75A4FE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
                          </g>
                        </g>
                      </g>
                    </svg>

                    <p className='title'>{t('Subscription::No payment details available')}</p>
                    <p className='description'>{t('Subscription::Add payment details to your account')}</p>
                    <Button type='primary' text={t('Subscription::Add card details')} onClick={this.onUpdateCardDetails} />
                  </div>
                </Panel>}

                <Panel title={t('Subscription::Invoices')}>
                  <SubscriptionInvoices
                    settings={workspace.setting}
                  />
                </Panel>

              </div>
            </div>
          </>}
        </PageContent>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateWorkspace: (workspace) => dispatch(updateWorkspace(workspace)),
    showPaymentDetailsModal: (onSubmit?: (workspace: Workspace) => void) => dispatch(showPaymentDetailsModal(onSubmit)),
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Subscriptions)))