import styled from 'styled-components'
import { Style } from '../styles'

const ConfirmAction = styled.a`
	font-size: 16px;
	font-weight: bold;
	color: ${Style.color.brandPrimary};
	cursor: pointer;
`

export default ConfirmAction;