import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import { Dispatch } from 'redux'
import { AppState } from '../../store'
import Panel from '../../components/Panel/Panel'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import { Draggable } from 'react-beautiful-dnd'
import DroppableHelper from '../../helpers/DroppableHelper';
import ReactTooltip from 'react-tooltip';
import { CurrentUser, WorkType, DisplayableError, NavigationMenuListType, Settings } from '../../types';
import MenuEditorItems from '../../components/MenuEditor/MenuEditorItems';
import MenuEditorItemDrag from '../../components/MenuEditor/MenuEditorItemDrag';
import Icon from '../../components/Icons/Icon';
import MenuEditorItemIcon from '../../components/MenuEditor/MenuEditorItemIcon';
import MenuEditorItemLabel from '../../components/MenuEditor/MenuEditorItemLabel';
import MenuEditorItemActions from '../../components/MenuEditor/MenuEditorItemActions';
import MenuEditorItemAction from '../../components/MenuEditor/MenuEditorItemAction';
import { WorkTypesController } from '../../controllers';
import PageLoader from '../../components/Page/PageLoader';
import { showConfirmModal, showWorkTypeModal } from '../../store/modals/actions';
import Notification from '../../utilities/Notification';
import Button from '../../components/Button/ButtonDefault';
import Title from '../../components/Settings/Title';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Style.spacing.x1};
`

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${Style.spacing.x1};
  border: 1px solid ${Style.color.border};
  background: white;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  width: 100%;

  &:last-child {
    margin-bottom: -1px;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showWorkTypeModal: typeof showWorkTypeModal
  showConfirmModal: typeof showConfirmModal
  updateSettings: typeof updateSettings
}

type IProps = IStateToProps & IDispatchToProps
interface IState {
  didInitialLoad: boolean
  workTypes: WorkType[]
  errors: DisplayableError[]
}

const WorkTypes = (props: IProps) => {
  const { t } = useTranslation()
  const { currentUser: { workspace: { setting } }, showConfirmModal, showWorkTypeModal } = props
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    workTypes: [],
    errors: []
  })
  const { workTypes, didInitialLoad } = state

  React.useEffect(() => {
    fetchForm().catch(console.error)
  }, [])

  React.useEffect(() => {
    ReactTooltip.rebuild()
  })

  const fetchForm = async () => {
    try {
      const { work_types } = await WorkTypesController.getWorkTypes({})

      setState({ ...state, workTypes: work_types, didInitialLoad: true })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onAddWorkTypeClick = () => {
    const position = DroppableHelper.getCreatePosition(workTypes)

    showWorkTypeModal({
      workType: {
        position: position,
      },
      onSubmit: (workType) => {
        const updatedWorkTypes = DroppableHelper.sort([...workTypes, workType], 'ascending')
        setState({ ...state, workTypes: updatedWorkTypes })
      }
    })
  }

  const onWorkTypeDragEnd = (dropResult: DropResult, provided: ResponderProvided) => {
    const { destination, source, draggableId } = dropResult

    if (!destination) return

    if (
      destination.droppableId !== source.droppableId &&
      destination.index !== source.index
    ) {
      return
    }

    let newWorkTypes: WorkType[] = [...workTypes]

    // Calculate new position
    const newPosition = DroppableHelper.getNewPositionBasedOnDropResult(newWorkTypes, dropResult)

    // Remove custom field from position
    newWorkTypes[source.index].position = newPosition

    // Update workType work type position async
    WorkTypesController.update(newWorkTypes[source.index]).catch(console.error)

    // Sort workTypework type based on position
    newWorkTypes = DroppableHelper.sort(newWorkTypes, 'ascending')

    // Update local position
    setState({ ...state, workTypes: [...newWorkTypes] })

    Notification.notifySuccess(t('WorkTypes::Work type successfully updated'))
  }

  const onEditWorkTypeClick = (workType: WorkType, index: number) => {
    props.showWorkTypeModal({
      workType: { id: workType.id },
      onSubmit: (workType) => {
        workTypes[index] = workType
        setState({ ...state, workTypes: [...workTypes] })

        Notification.notifySuccess(t('WorkTypes::Work type successfully updated'))
      }
    })
  }

  const onDeleteClick = (workType: WorkType, index: number) => {
    showConfirmModal({
      title: t('WorkTypes::Delete work type'),
      description: t('WorkTypes::You are about to delete this work type. This work type will be permanently deleted. Are you sure?'),
      action: { label: t('WorkTypes::Delete'), isDestructive: true },
      onConfirm: async () => {
        let newWorkTypes: WorkType[] = [...workTypes]

        newWorkTypes.splice(index, 1);

        newWorkTypes = DroppableHelper.sort(newWorkTypes, 'ascending')

        setState({ ...state, workTypes: newWorkTypes })

        WorkTypesController.delete(workType.id).catch(console.error)

        Notification.notifySuccess(t('WorkTypes::Work type successfully deleted'))
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('SettingEmail::{{__appName}} | Work types')}</title>
      </Helmet>
      <ScrollToTopOnMount />

      <Title>{t('WorkTypes::Work types')}</Title>

      {!didInitialLoad && <PageLoader />}
      {didInitialLoad && <Panel>
        {workTypes?.length > 0 && <DragDropContext onDragEnd={onWorkTypeDragEnd}>
          <Droppable droppableId='navigation-menu-items' type={NavigationMenuListType.MAIN}>
            {(provided) => {
              return (
                <MenuEditorItems ref={provided.innerRef} {...provided.droppableProps}>
                  {workTypes.map((workType, index) => {
                    return (
                      <Draggable key={workType.id} draggableId={workType.id} index={index}>
                        {(provided) => {
                          return (
                            <MenuItemContainer key={index} {...provided.draggableProps} ref={provided.innerRef}>
                              <MenuEditorItemDrag {...provided.dragHandleProps}>
                                <Icon icon='grip-vertical' />
                              </MenuEditorItemDrag>

                              <MenuEditorItemIcon onClick={() => onEditWorkTypeClick(workType, index)} style={{ cursor: 'pointer' }}>
                                {index + 1}
                              </MenuEditorItemIcon>

                              <MenuEditorItemLabel onClick={() => onEditWorkTypeClick(workType, index)} style={{ cursor: 'pointer' }}>
                                {workType.name}
                              </MenuEditorItemLabel>

                              <MenuEditorItemActions>
                                <MenuEditorItemAction
                                  data-tip={t('WorkTypes::Edit')}
                                  data-place='top'
                                  onClick={() => onEditWorkTypeClick(workType, index)}
                                >
                                  <Icon icon='pen' />
                                </MenuEditorItemAction>

                                <MenuEditorItemAction
                                  type='danger'
                                  data-tip={t('WorkTypes::Delete')}
                                  data-place='top'
                                  onClick={() => onDeleteClick(workType, index)}
                                >
                                  <Icon icon='circle-xmark' />
                                </MenuEditorItemAction>
                              </MenuEditorItemActions>
                            </MenuItemContainer>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </MenuEditorItems>
              )
            }}
          </Droppable>
        </DragDropContext>}

        <Actions>
          <Button onClick={onAddWorkTypeClick}>
            <Icon icon='plus' style={{ marginRight: 8 }} />
            {t('WorkTypes::Add work type')}
          </Button>
        </Actions>
      </Panel>}
    </>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showWorkTypeModal: (options) => dispatch(showWorkTypeModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkTypes)