import * as React from 'react'

interface IProps {
	title?: string
	contentClasses?: string
	headerRight?: JSX.Element
	style?: React.CSSProperties
}

export default class Panel extends React.PureComponent<IProps> {
	static defaultProps = {
		title: '',
		contentClasses: ''
	}

	render() {
		const { title, children, contentClasses, headerRight, style } = this.props

		return (
			<div className='panel' style={style}>
				{(title || headerRight) && <div className='panel-header'>
					<div>
						{title}
					</div>

					{headerRight && <div>{headerRight}</div>}
				</div>}

				<div className={`panel-content ${contentClasses}`}>
					{children}
				</div>
			</div>
		)
	}
}