import '../translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { saveAs } from 'file-saver'
import { DEFAULT_LOCALE, SENTRY_DSN } from '../Constants'
import { useTranslation } from 'react-i18next'
import AppLoader from '../components/Loaders/AppLoader'
import { Transfer, Workspace } from '../types'
import styled from 'styled-components'
import { Style } from '../styles'
import prettyBytes from 'pretty-bytes'
import FileHelper from '../helpers/FileHelper'
import moment from '../utilities/Moment'
import i18next from 'i18next'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgb(23, 24, 26);
`

const TransferContainer = styled.div`
  height: 25.625em;
  border-radius: 10px;
  box-shadow: 0 0 12px 0 rgba(0,0,0,.1), 0 10px 30px 0 rgba(0,0,0,.2);
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 50%;
  width: 17.5em;
  transition: transform .5s cubic-bezier(.77,0,.175,1);
`

const TransferContent = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
`

const TransferHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: 18em;
  padding-top: 30px;
  padding-bottom: 12px;
`

const TransferHeadingIcon = styled.svg`
  display: block;
  margin: 0 auto 0.75em;
  height: 8.75em;
  width: 8.75em;
`

const TransferHeadingTitle = styled.div`
  margin: 0 0 0.0625em;
  padding: 0 0.9090909091em;
  font-weight: bolder;
  color: #17181a;
  line-height: 1.2;
  margin: 0;
  padding: 0 0.4545454545em;
  text-align: center;
  font-size: 22px;
`

const TransferHeadingDescription = styled.div`
  font-size: .8125em;
  line-height: 1.3846153846em;
  padding: 0 1.5384615385em;
  text-align: center;
  font-weight: 400;
  color: #6a6d70;
  font-size: 15px;
`

const TransferName = styled.div`
  margin: 20px 20px 0 20px;
  text-align: left;
  align-self: flex-start;
  width: 100%;
  color: rgb(72, 74, 77);
  font-size: 14px;
`

const TransferItems = styled.div`
  display: flex;
  flex-direction: column;
`

const TransferItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 8px 20px;
  position: relative;
  border-bottom: 1px solid #e8ebed;
  flex: 1;
  width: 100%;

  &:first-child {
    border-top: 1px solid #e8ebed;
  }

  &:last-child {
    border-bottom: none;
  }
`

const TransferItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TransferItemName = styled.div`
  font-size: 14px;
`

const TransferItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #6a6d70;
  font-size: 12px;
`

const TransferItemDetail = styled.div`
  &:not(:last-child) {
    &::after {
      content: "·";
      display: inline-block;
      padding: 0 0.375em;
      text-decoration: none;
    }
  }
`
const TransferItemAction = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const TransferItemActionIcon = styled.svg`
  width: 20px;
  height: 20px;
  fill: ${Style.color.brandPrimary};
`


const TransferFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d4d7d9;
  height: 4.5em;
  padding: 0 1.25em;
  position: relative;
`

const TransferButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${Style.color.brandPrimary};
  border: 1px solid ${Style.color.brandPrimary};
  border-radius: 1.25em;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  height: 2.5em;
  outline: 0;
  font-weight: bold;
  font-family: -apple-system,\.SFNSText-Regular,San Francisco,Roboto,Segoe UI,Helvetica Neue,Lucida Grande,sans-serif;
  font-family: Actief Grotesque Medium,-apple-system,\.SFNSText-Regular,San Francisco,Roboto,Segoe UI,Helvetica Neue,Lucida Grande,sans-serif;
  transition: border-color .2s cubic-bezier(.77,0,.175,1),background-color .2s cubic-bezier(.77,0,.175,1),color .2s cubic-bezier(.77,0,.175,1);
  width: 9.75em;
`

Sentry.init({ dsn: SENTRY_DSN })

interface IProps {
  transfer: Transfer
  workspace: Workspace
}

const Application = (props: IProps) => {
  const { transfer, workspace } = props
  const { t } = useTranslation()

  React.useEffect(() => {
    i18next.changeLanguage(DEFAULT_LOCALE, (err, t) => { });
  }, [])

  const onItemDownloadClick = async (file: ActiveStorage.Blob & { id: string }) => {
    const { url } = await fetch(`/t/${transfer.id}/download/${file.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(response => response.json())
    saveAs(url)
  }

  const onDownloadClick = () => {
    transfer.files.forEach((file) => {
      onItemDownloadClick(file as ActiveStorage.Blob & { id: string })
    })
  }

  return (
    <Container>
      <TransferContainer>
        <TransferContent>
          <TransferHeading>
            <TransferHeadingIcon viewBox="0 0 170 170"><g fill="#d4d7d9" fill-rule="evenodd"><path d="M145.104 24.896c33.195 33.194 33.195 87.014 0 120.208-33.194 33.195-87.014 33.195-120.208 0C-8.3 111.91-8.3 58.09 24.896 24.896 58.09-8.3 111.91-8.3 145.104 24.896zm-7.071 7.071c-29.29-29.29-76.777-29.29-106.066 0-29.29 29.29-29.29 76.777 0 106.066 29.29 29.29 76.777 29.29 106.066 0 29.29-29.29 29.29-76.777 0-106.066z"></path><path d="M82 100.843V59.007A4.006 4.006 0 0 1 86 55c2.21 0 4 1.794 4 4.007v41.777l15.956-15.956a4.003 4.003 0 0 1 5.657 0 4.004 4.004 0 0 1 0 5.657l-22.628 22.628a3.99 3.99 0 0 1-3.017 1.166 3.992 3.992 0 0 1-3.012-1.166L60.328 90.485a4.003 4.003 0 0 1 0-5.657 4.004 4.004 0 0 1 5.657 0L82 100.843z"></path></g></TransferHeadingIcon>

            <TransferHeadingTitle>
              Ready when you are
            </TransferHeadingTitle>

            <TransferHeadingDescription>
              {`Transfer expires ${moment(transfer.expires_at).fromNow()}`}
            </TransferHeadingDescription>

            <TransferName>
              {transfer.name}
            </TransferName>
          </TransferHeading>

          {transfer?.files?.length > 0 && <TransferItems>
            {transfer?.files.map(file => {
              return (
                <TransferItem key={file.signed_id}>
                  <TransferItemWrapper>
                    <TransferItemName>{file.filename}</TransferItemName>
                    <TransferItemDetails>
                      <TransferItemDetail>
                        {prettyBytes(file.byte_size)}
                      </TransferItemDetail>
                      <TransferItemDetail>
                        {FileHelper.getExtension(file.filename)}
                      </TransferItemDetail>
                    </TransferItemDetails>
                  </TransferItemWrapper>
                  <TransferItemAction onClick={() => onItemDownloadClick(file as ActiveStorage.Blob & { id: string })}>
                    <TransferItemActionIcon viewBox="0 0 24 24"><path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10zm-9 1.492V7.998C13 7.446 12.552 7 12 7c-.556 0-1 .447-1 .998v5.48l-2.53-2.53c-.385-.385-1.022-.39-1.413.002-.393.393-.39 1.022-.002 1.412l4.247 4.247c.192.19.447.288.702.288.26.003.514-.095.708-.29l4.247-4.246c.383-.385.387-1.022-.003-1.412-.394-.393-1.023-.392-1.413-.002L13 13.492zM0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z" fill-rule="evenodd"></path></TransferItemActionIcon>
                  </TransferItemAction>
                </TransferItem>
              )
            })}
          </TransferItems>}
        </TransferContent>

        <TransferFooter>
          <TransferButton onClick={onDownloadClick}>
            Download
          </TransferButton>
        </TransferFooter>
      </TransferContainer>
    </Container>
  );
}


const ApplicationProvider = (props: IProps) => {
  return (
    <React.Suspense fallback={<AppLoader />}>
      <Application {...props} />
    </React.Suspense>
  )
}

export default ApplicationProvider