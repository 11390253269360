import * as React from "react";
import { CurrentUser, Payment, ResourceListFilterType } from "../../types";
import PageLoader from "../Page/PageLoader";
import { WithTranslation, withTranslation } from "react-i18next";
import ResourceTable from "../Resource/ResourceTable";
import ResourceTableRow from "../Resource/ResourceTableRow";
import ResourceTableRowData from "../Resource/ResourceTableRowData";
import moment from "../../utilities/Moment";
import { connect } from "react-redux";
import { AppState } from "../../store";
import NumberFormatter from "../../utilities/NumberFormatter";
import CardEmptyInfo from "../Card/CardEmptyInfo";
import { PaymentsController } from "../../controllers";
import ResourceTableRowDataLink from "../Resource/ResourceTableRowDataLink";
import { withRouter, RouteComponentProps } from 'react-router';
import RouteHelper from "../../helpers/RouteHelper";
import ERoute from "../../ERoute";
import { Dispatch } from "redux";
import LocalStorage, { LocalStorageKey } from "../../LocalStorage";
import { Style } from "../../styles";
import { showPaymentModal } from "../../store/modals/actions";

interface IStateToProps {
    currentUser: CurrentUser
}

type IProps = {
    paymentIds: string[]
    onLinkClick: (paymentIds: string[]) => void
} & RouteComponentProps & WithTranslation & IStateToProps & IDispatchToProps

interface IDispatchToProps {
    showPaymentModal: typeof showPaymentModal
}

interface IState {
    payments: Payment[]
    currentPage: number
    totalPages: number
    filters: any
    didInitialLoad: boolean
    isFetching: boolean
    selectedPayments: string[]
    sortValue: string
    searchValue: string
}

class LinkPayments extends React.Component<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            payments: [],
            currentPage: 0,
            totalPages: 0,
            filters: {},
            didInitialLoad: false,
            searchValue: '',
            sortValue: LocalStorage.get(LocalStorageKey.PAYMENT_SORT_VALUE, '-'),
            isFetching: false,
            selectedPayments: []
        }

        this.fetchPayments = this.fetchPayments.bind(this)
        this.onPaymentClick = this.onPaymentClick.bind(this)
        this.onFiltersChange = this.onFiltersChange.bind(this)
        this.onSortValueChange = this.onSortValueChange.bind(this)
        this.onSelectionChange = this.onSelectionChange.bind(this)
        this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.onSearchSubmit = this.onSearchSubmit.bind(this)
        this.onClearFilter = this.onClearFilter.bind(this)
        this.onLinkClick = this.onLinkClick.bind(this)
    }

    componentDidMount(): void {
        this.fetchPayments(1)
    }

    async fetchPayments(page: number = 1) {
        const { searchValue, filters, sortValue } = this.state
        try {
            let parameters = {
                search: searchValue,
                order: sortValue,
                ...filters
            }

            this.setState({ isFetching: true })

            const { current_page, total_pages, payments } = await PaymentsController.getPayments({
                page: page,
                ...parameters
            })

            this.setState({
                ...this.state,
                payments: payments,
                currentPage: current_page,
                totalPages: total_pages,
                didInitialLoad: true,
                isFetching: false
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    onPaymentClick(payment) {
        this.props.showPaymentModal({
            payment: { id: payment.id },
            onSubmit: (payment) => {
                const { payments } = this.state

                const paymentIndex = payments.findIndex(e => e.id === payment.id)

                payments[paymentIndex] = payment

                this.setState({ payments: [...payments] })
            }
        })
    }

    onFiltersChange(filters: any) {
        this.setState({ ...this.state, filters: filters }, () => {
            this.fetchPayments(1)
        })
    }

    onSortValueChange(sortValue: string) {
        LocalStorage.set(LocalStorageKey.EXPENSE_SORT_VALUE, sortValue)

        this.setState({ sortValue: sortValue }, () => {
            this.fetchPayments(1)
        })
    }

    onSelectionChange(selectedPayments: string[]) {
        this.setState({ selectedPayments: selectedPayments })
    }

    onTableRowSelectionChange(selected: boolean, ledgerItemId: string) {
        const { selectedPayments } = this.state

        if (selected) {
            this.setState({ selectedPayments: [...selectedPayments, ledgerItemId] })
        } else {
            this.setState({ selectedPayments: selectedPayments.filter(selectedLedgerItemId => selectedLedgerItemId !== ledgerItemId) })
        }
    }

    onSearchChange(searchValue) {
        this.setState({ ...this.state, searchValue: searchValue })
    }

    onSearchSubmit(searchValue) {
        this.setState({ ...this.state, searchValue: searchValue }, () => {
            this.fetchPayments(1)
        })
    }

    onClearFilter() {
        this.setState({
            searchValue: '',
            filters: {}
        }, () => this.fetchPayments(1))
    }

    onLinkClick(paymentIds: string[]) {
        const { onLinkClick } = this.props

        // Clear the selected ledger item ids
        this.setState({ selectedPayments: [] })

        // Call the parent component's onLinkClick function
        onLinkClick(paymentIds)
    }

    render() {
        const { paymentIds, t, currentUser } = this.props
        const { didInitialLoad, payments, searchValue, filters, currentPage, totalPages, isFetching, selectedPayments, sortValue } = this.state
        const { workspace: { setting } } = currentUser

        const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

        return (
            <>
                {!didInitialLoad && <PageLoader />}
                {didInitialLoad && <ResourceTable
                    data={payments.filter(payment => !paymentIds.includes(payment.id))}
                    headers={[
                        { title: t('LinkPayments::Name') },
                        { title: t('LinkPayments::Remittance information'), align: 'right' },
                        { title: t('LinkPayments::Execution date'), align: 'right' },
                        { title: t('LinkPayments::Amount'), align: 'right' },
                        { title: '', align: 'right' },
                    ]}
                    renderRow={(payment: Payment) => {
                        return (
                            <ResourceTableRow
                                key={payment.id}
                                selected={selectedPayments.includes(payment.id)}
                                onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, payment.id)}
                            >
                                <ResourceTableRowData maxWidth='150px' ellipse onClick={() => this.onPaymentClick(payment)}>
                                    {payment.creditor_name}
                                </ResourceTableRowData>
                                <ResourceTableRowData maxWidth='150px' ellipse onClick={() => this.onPaymentClick(payment)} textAlign="right">
                                    {payment.remittance_information}
                                </ResourceTableRowData>
                                <ResourceTableRowData textAlign='right' onClick={() => this.onPaymentClick(payment)}>
                                    {payment.execution_date ? moment(payment.execution_date).format(setting.date_format) : '-'}
                                </ResourceTableRowData>
                                <ResourceTableRowData
                                    textAlign='right'
                                    onClick={() => this.onPaymentClick(payment)} style={{
                                        fontWeight: 'bold',
                                        color: Style.color.brandDanger
                                    }}>
                                    {NumberFormatter.formatCurrency(payment.currency, setting.number_format, -payment.amount)}
                                </ResourceTableRowData>
                                <ResourceTableRowData textAlign='right' maxWidth='150px' ellipse onClick={() => this.onLinkClick([payment.id])}>
                                    {!paymentIds.includes(payment.id) && <ResourceTableRowDataLink>
                                        {t('LinkPayments::Link')}
                                    </ResourceTableRowDataLink>}
                                </ResourceTableRowData>
                            </ResourceTableRow>
                        )
                    }}
                    renderEmpty={<CardEmptyInfo
                        icon={filtersActive ? 'search' : 'money-bill'}
                        description={t('LinkPayments::No payments found')}
                        descriptionActionText={filtersActive ? t('LinkPayments::Clear filters') : null}
                        onDescriptionActionClick={filtersActive ? this.onClearFilter : null}
                    />}
                    filters={[
                        { name: 'creditor_name', label: t('LinkPayments::Name'), type: ResourceListFilterType.STRING },
                        { name: 'creditor_account', label: t('LinkPayments::Account'), type: ResourceListFilterType.STRING },
                        { name: 'remittance_information', label: t('LinkPayments::Remittance information'), type: ResourceListFilterType.STRING },
                        { name: 'execution_date', label: t('LinkPayments::Execution date'), type: ResourceListFilterType.DATE },
                        { name: 'amount', label: t('LinkPayments::Amount'), type: ResourceListFilterType.NUMBER },
                        { name: 'created_at', label: t('LinkPayments::Created date'), type: ResourceListFilterType.DATE },
                    ]}
                    onFiltersChange={this.onFiltersChange}
                    sortOptions={[
                        { label: '-', value: '-' },
                        { label: t('LinkPayments::Name (A-Z)'), value: 'creditor_name_asc' },
                        { label: t('LinkPayments::Name (Z-A)'), value: 'creditor_name_desc' },
                        { label: t('LinkPayments::Execution date ↑'), value: 'payment_initiations.execution_date_asc' },
                        { label: t('LinkPayments::Execution date ↓'), value: 'payment_initiations.execution_date_desc' },
                        { label: t('LinkPayments::Created at ↑'), value: 'created_at_asc' },
                        { label: t('LinkPayments::Created at ↓'), value: 'created_at_desc' },
                    ]}
                    sortValue={sortValue}
                    onSortChange={this.onSortValueChange}
                    promotedBulkActions={[
                        { icon: 'link', content: t('LinkPayments::Link to transaction'), onAction: () => this.onLinkClick(selectedPayments) }
                    ]}
                    pagination={{
                        page: currentPage,
                        pageCount: totalPages
                    }}
                    onPageChange={(page) => this.fetchPayments(page)}
                    isLoading={isFetching}
                    stickyHeader={true}
                    selectedItems={selectedPayments}
                    onSelectionChange={this.onSelectionChange}
                    searchValue={searchValue}
                    onSearchChange={this.onSearchChange}
                    onSearchSubmit={this.onSearchSubmit}
                    maxHeight='25vh'
                />}
            </>
        )
    }
}

const mapStateToProps = (state: AppState): IStateToProps => {
    const {
        authentication: {
            currentUser,
        }
    } = state

    return {
        currentUser: currentUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
    return {
        showPaymentModal: (options) => dispatch(showPaymentModal(options))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LinkPayments)))
