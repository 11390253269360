import styled from 'styled-components'

export default styled.div`
	height: 100%;
	max-height: calc(100% - 240px);
	flex: 1;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 20px;
`