import * as React from 'react'
import * as ReactDOM from 'react-dom'
import moment from '../utilities/Moment'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import TopNavigation from '../components/Navigation/TopNavigation'
import { AppState } from '../store/index'
import NumberFormatter from '../utilities/NumberFormatter'
import { RouteComponentProps } from 'react-router-dom'
import AccountReceivableLegendItem from '../components/Dashboard/AccountReceivableLegendItem'
import GenericTooltip from '../components/Tooltips/GenericTooltip'
import ExpenseLegendItem from '../components/Dashboard/ExpenseLegendItem'
import ERoute from '../ERoute';
import { showBoardModal, showContactModal, showProjectModal, showExpenseModal, showProductModal } from '../store/modals/actions'
import RouteHelper from '../helpers/RouteHelper'
import { DashboardController } from '../controllers'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import styled from 'styled-components'
import { Bar, Doughnut } from 'react-chartjs-2'
import { Style } from '../styles'
import GraphEmpty from '../components/Graph/GraphEmpty'
import PageContent from '../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import Panel from '../components/Panel/Panel'
import YearlyRevenueGoal from '../components/Dashboard/YearlyRevenue/YearlyRevenueGoal'
import ButtonPanel from '../components/Button/ButtonPanel'
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper'
import pattern from 'patternomaly'
import { CurrentUser, UserWorkspaceSettingScope } from '../types'

const GraphContainer = styled.div`
	@media screen and (max-width: ${Style.breakpoints.SMALL}){
		overflow-x: auto;
	}

	&::-webkit-scrollbar {
		display: none;
	}
`

const GraphWrapper = styled.div`
	position: relative;
	height: 340px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}){
		height: 300px;
		width: 1400px;
	}
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showBoardModal: typeof showBoardModal
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showExpenseModal: typeof showExpenseModal
  showProductModal: typeof showProductModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  didInitialLoad: boolean
  yearly_revenue_goal: any | null
  account_receivables: any[] | null
  expenses: any[] | null
  monthly_revenue: any[] | null
  monthly_summaries: any[] | null
  setup: object | null
}

class Dashboard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { t } = props

    this.onCreateSpaceClick = this.onCreateSpaceClick.bind(this)

    this.state = {
      didInitialLoad: false,
      yearly_revenue_goal: null,
      account_receivables: null,
      expenses: null,
      monthly_revenue: null,
      monthly_summaries: null,
      setup: null,
    }
  }

  componentDidMount() {
    this.fetchDashboard()
  }

  fetchDashboard() {
    DashboardController
      .getDashboard()
      .then(response => {
        const {
          yearly_revenue_goal,
          account_receivables,
          expenses,
          monthly_revenue,
          monthly_summaries,
        } = response


        this.setState({
          didInitialLoad: true,
          yearly_revenue_goal: yearly_revenue_goal,
          account_receivables: account_receivables,
          expenses: expenses,
          monthly_revenue: monthly_revenue,
          monthly_summaries: monthly_summaries,
        })
      })
      .catch(console.error)
  }

  onCreateSpaceClick(e) {
    e.preventDefault()

    const { showBoardModal } = this.props

    showBoardModal({
      onSubmit: (board) => {
        this.props.history.push(RouteHelper.process(ERoute.PATH_TASKS_BOARD, { id: board.id }))
      }
    })
  }

  calculateSlicePercentage(total, amount) {
    return (amount.toFixed(1) / total.toFixed(1) * 100).toFixed(1)
  }

  renderFinancialPerformance() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { monthly_summaries } = this.state

    const labels = monthly_summaries.map(summary => summary.month)

    const revenueDataset = {
      type: 'bar',
      label: t('Dashboard::Cash in'),
      backgroundColor: monthly_summaries.map(_summary => '#5541F6'),
      borderSkipped: 'top',
      data: monthly_summaries.map(summary => Number(summary.revenue)),
      fill: false,
      stack: 1
    }
    const expectedRevenueDataset = {
      type: 'bar',
      label: t('Dashboard::Expected cash in'),
      backgroundColor: monthly_summaries.map(_summary => pattern.draw('diagonal', 'white', '#5541F6', 3)),
      borderSkipped: 'top',
      data: monthly_summaries.map(summary => Number(summary.expected_revenue)),
      fill: false,
      stack: 1
    }
    const expenseDataset = {
      type: 'bar',
      label: t('Dashboard::Cash out'),
      backgroundColor: monthly_summaries.map(_summary => '#21A1FF'),
      borderSkipped: 'top',
      data: monthly_summaries.map(summary => Number(summary.expenses)),
      fill: false,
      stack: 2,
    }
    const expectedExpenseDataset = {
      type: 'bar',
      label: t('Dashboard::Expected cash out'),
      backgroundColor: monthly_summaries.map(_summary => pattern.draw('diagonal', 'white', '#21A1FF', 3)),
      borderSkipped: 'top',
      data: monthly_summaries.map(summary => Number(summary.expected_expenses)),
      fill: false,
      stack: 2,
    }
    const netIncomeDataset = {
      type: 'line',
      label: t('Dashboard::Profit'),
      backgroundColor: monthly_summaries.map(summary => '#F14A86'),
      data: monthly_summaries.map(summary => Number(summary.net_income)),
      fill: false,
      borderColor: '#F14A86',
      stack: 3
    }
    const expectedNetIncomeDataset = {
      type: 'line',
      label: t('Dashboard::Expected profit'),
      backgroundColor: monthly_summaries.map(summary => '#F14A86'),
      data: monthly_summaries.map(summary => Number(summary.expected_net_income)),
      fill: false,
      borderColor: '#F14A86',
      borderDash: [8, 5],
      stack: 3
    }
    const placeholderNetincomeBarDataset = {
      type: 'bar',
      label: '',
      backgroundColor: monthly_summaries.map(summary => 'transparent'),
      data: monthly_summaries.map(summary => 0),
      fill: false,
      stack: 3
    }

    // Expected netIncome 

    const dataPoints = [...revenueDataset.data, ...expenseDataset.data, ...netIncomeDataset.data]

    const maxDataValue = Math.max(...dataPoints)
    const minDataValue = Math.min(...dataPoints)
    const suggestedMaxValue = isFinite(maxDataValue) ? maxDataValue + (maxDataValue * 5 / 100) : 0
    const suggestedMinValue = isFinite(minDataValue) ? minDataValue - (minDataValue * 5 / 100) : 0

    const data = {
      labels: labels,
      datasets: [
        netIncomeDataset,
        expectedNetIncomeDataset,
        revenueDataset,
        expectedRevenueDataset,
        placeholderNetincomeBarDataset,
        expenseDataset,
        expectedExpenseDataset,
      ],
    }

    const hasData = dataPoints.some(dataPoint => Math.abs(dataPoint) > 0)

    return (
      <div className='panel'>
        <div className='panel-header is-large space-between'>
          {t('Dashboard::Financial performance')}

          <div className='dashboard-financial-performance-legend'>
            <div className='legend-item'>
              <span className='legend-item-circle is-revenue'></span>
              {t('Dashboard::Cash in')}
            </div>

            <div className='legend-item'>
              <span className='legend-item-circle is-expense'></span>
              {t('Dashboard::Cash out')}
            </div>

            <div className='legend-item'>
              <span className='legend-item-circle is-net-total'></span>
              {`${t('Dashboard::Profit')} / ${t('Dashboard::Loss')}`}
            </div>
          </div>
        </div>

        <div className='panel-content is-large'>
          <GraphContainer>
            <GraphWrapper>
              {!hasData && <GraphEmpty
                title={t('Dashboard::No data available for current financial year.')}
                description={t('Dashboard::Come back later when you\'ve added data.')}
              />}
              <Bar
                data={data}
                options={{
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    mode: 'point',
                    backgroundColor: '#212B36',
                    footerMarginTop: 100,
                    callbacks: {
                      label: (tooltipItem, data) => {
                        const label = data.datasets[tooltipItem.datasetIndex].label || '';
                        const value = NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, Math.abs(tooltipItem.value))

                        if ([0, 1].includes(tooltipItem.datasetIndex) && Number(tooltipItem.value) < 0) {
                          if (tooltipItem.datasetIndex === 0) return `${t('Dashboard::Loss')}: ${value}`
                          if (tooltipItem.datasetIndex === 1) return `${t('Dashboard::Expected loss')}: ${value}`
                        }

                        return `${label}: ${value}`
                      }
                    }
                  },
                  scales: {
                    xAxes: [{
                      gridLines: {
                        color: '#F4F6F8',
                        lineWidth: 2,
                        borderDash: [8, 4],
                      },
                      ticks: {
                        fontSize: 13,
                        fontColor: '#637381',
                        padding: 10,
                        callback: function (value, index, values) {
                          return moment(value, 'YYYY-MM-DD').format('MMM')
                        }
                      },
                    }],
                    yAxes: [{
                      gridLines: {
                        drawBorder: false,
                        color: '#F4F6F8',
                        lineWidth: 2,
                        drawTicks: false,
                        zeroLineColor: '#DFE4E8',
                        zeroLineWidth: 2,
                      },
                      ticks: {
                        fontSize: 13,
                        fontColor: '#637381',
                        padding: 20,
                        suggestedMin: suggestedMinValue,
                        suggestedMax: suggestedMaxValue,
                        callback: (value, index, values) => {
                          return NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, value)
                        }
                      },
                    }]
                  }
                }}
              />
            </GraphWrapper>
          </GraphContainer>
        </div>
      </div>
    )
  }

  renderYearlyGoal() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { yearly_revenue_goal: { collected_revenue, correction_revenue, expected_revenue, expected_revenue_metadata, revenue_goal, financial_year } } = this.state


    return (
      <Panel
        title={t('General::Revenue Goal')}
        headerRight={UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) ? <ButtonPanel
          icon='pen'
          text={t('Dashboard::Edit revenue goal')}
          onClick={() => {
            this.props.history.push(ERoute.PATH_SETTINGS_GENERAL)
          }}
        /> : null}
      >
        <YearlyRevenueGoal
          financialYear={financial_year}
          collectedRevenue={Number(collected_revenue)}
          correctionRevenue={Number(correction_revenue)}
          expectedRevenue={Number(expected_revenue)}
          expectedRevenueMetadata={expected_revenue_metadata}
          revenueGoal={Number(revenue_goal)}
          currency={setting.default_currency}
          numberFormat={setting.number_format}
        />
      </Panel>
    )
  }

  renderAccountReceivables() {
    const { account_receivables } = this.state
    const { currentUser: { workspace: { setting } }, t } = this.props

    const labels = account_receivables.map(receivable => receivable.contact_name)
    const data = account_receivables.map(receivable => receivable.total)
    const backgroundColors = account_receivables.map(receivable => receivable.color)
    const accountReceivableTotal = account_receivables
      .map(receivable => receivable.total)
      .reduce((total, number) => Number(total) + Number(number), 0)


    return (
      <div className='grid-cell with-6col'>
        <div className='panel'>
          <div className='panel-header space-between'>
            {t('Dashboard::Turnover')}

            <span className='panel-header-subtext'>{t('Dashboard::This year')}</span>
          </div>

          <div className='panel-content is-large'>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                {labels.length === 0 && <Doughnut
                  data={{
                    labels: [],
                    datasets: [{
                      data: [100],
                      backgroundColor: ['#9b9b9b'],
                    }]
                  }}
                  height={250}
                  options={{
                    responsive: true,
                    cutoutPercentage: 56,
                    legend: {
                      display: false,
                    },
                    elements: {
                      arc: {
                        borderWidth: 0
                      }
                    },
                    tooltips: false
                  }}
                />}
                {labels.length > 0 && <Doughnut
                  height={250}
                  data={{
                    labels: labels,
                    datasets: [{
                      data: data,
                      backgroundColor: backgroundColors,
                    }]
                  }}
                  options={
                    {
                      responsive: true,
                      cutoutPercentage: 56,
                      legend: {
                        display: false,
                      },
                      elements: {
                        arc: {
                          borderWidth: 0
                        }
                      },
                      tooltips: {
                        mode: 'index',
                        enabled: false,
                        callbacks: {
                          label: (tooltipItem, data) => {
                            const { currentUser: { workspace: { setting } } } = this.props

                            const dataset = data.datasets[tooltipItem.datasetIndex]

                            const title = data.labels[tooltipItem.index]
                            const total = dataset.data[tooltipItem.index]
                            const backgroundColor = dataset.backgroundColor[tooltipItem.index]
                            const percentage = this.calculateSlicePercentage(accountReceivableTotal, parseFloat(total))

                            return `<div class='legend-item is-receivable' >
                            <div class='legend-item-circle' style='background-color: ${backgroundColor};'></div>
                            <div class='legend-item-content'>
                              <span class='legend-item-title'>${title}</span>
                              <div>
                                <span style="font-size: 12px; color: #424955;">
                                  ${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, total)}
                                </span>
                                <span style="font-size: 11px; color: #858C99; margin-left: 4px">
                                  ${percentage}%
                                </span>
                              </div>
                            </div>
                          </div>
                          `
                          }
                        },
                        custom: function (tooltipModel, data) {
                          const id = 'accountReceivableTooltip'
                          // Tooltip Element
                          var tooltipEl = document.getElementById(id);

                          // Create element on first render
                          if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = id;
                            tooltipEl.className = 'chart-tooltip'

                            document.body.appendChild(tooltipEl);
                          }

                          // Hide if no tooltip
                          if (tooltipModel.opacity === 0) {
                            // @ts-ignore
                            tooltipEl.style.opacity = 0;
                            return;
                          }

                          // Set caret Position
                          tooltipEl.classList.remove('above', 'below', 'no-transform');
                          if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                          } else {
                            tooltipEl.classList.add('no-transform');
                          }

                          // Add content
                          if (tooltipModel.body) {
                            ReactDOM.render(
                              <GenericTooltip
                                tooltip={tooltipModel}
                              />,
                              tooltipEl
                            )
                          }

                          // `this` will be the overall tooltip
                          var position = this._chart.canvas.getBoundingClientRect();

                          // Display, position, and set styles for font
                          // @ts-ignore
                          tooltipEl.style.opacity = 1;
                          tooltipEl.style.position = 'absolute';
                          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                          tooltipEl.style.pointerEvents = 'none';
                        }
                      }
                    }
                  }
                />}
              </div>

              <div className='grid-cell with-6col'>
                <div className='dashboard-expenses-legend'>
                  <h1 className='dashboard-expenses-total'
                    dangerouslySetInnerHTML={{
                      __html: `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, accountReceivableTotal)}`
                    }}
                  />
                  <div className='legend-items'>
                    {account_receivables.slice(0, 5).map((account_receivable, index) => {
                      const { color, contact_name, total } = account_receivable
                      const percentage = this.calculateSlicePercentage(accountReceivableTotal, parseFloat(total))

                      return (
                        <AccountReceivableLegendItem
                          key={`${name}-${index}`}
                          name={contact_name}
                          total={total}
                          color={color}
                          percentage={percentage}
                          currency={setting.default_currency}
                          numberFormat={setting.number_format}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderExpenses() {
    const { expenses } = this.state
    const { currentUser: { workspace: { setting } }, t } = this.props

    const labels = expenses.map(expense => expense.name)
    const data = expenses.map(expense => expense.total)
    const backgroundColors = expenses.map(expense => expense.color)

    const expenseTotal = expenses
      .map(expense => expense.total)
      .reduce((total, number) => Number(total) + Number(number), 0)

    return (
      <div className='grid-cell with-6col'>
        <div className='panel'>
          <div className='panel-header space-between'>
            {t('Dashboard::Expenses')}

            <span className='panel-header-subtext'>
              {t('Dashboard::This year')}
            </span>
          </div>

          <div className='panel-content is-large'>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                {labels.length === 0 && <Doughnut
                  data={{
                    labels: [],
                    datasets: [{
                      data: [100],
                      backgroundColor: ['#9b9b9b'],
                    }]
                  }}
                  height={250}
                  options={{
                    responsive: true,
                    cutoutPercentage: 56,
                    legend: {
                      display: false,
                    },
                    elements: {
                      arc: {
                        borderWidth: 0
                      }
                    },
                    tooltips: false
                  }}
                />}
                {labels.length > 0 && <Doughnut
                  height={250}
                  data={{
                    labels: labels,
                    datasets: [{
                      data: data,
                      backgroundColor: backgroundColors,
                    }]
                  }}
                  options={{
                    responsive: true,
                    cutoutPercentage: 56,
                    legend: {
                      display: false,
                    },
                    elements: {
                      arc: {
                        borderWidth: 0
                      }
                    },
                    tooltips: {
                      mode: 'index',
                      enabled: false,
                      callbacks: {
                        label: (tooltipItem, data) => {
                          const { currentUser: { workspace: { setting } } } = this.props

                          const dataset = data.datasets[tooltipItem.datasetIndex]
                          const expenseTotal = expenses
                            .map(expense => expense.total)
                            .reduce((total, number) => Number(total) + Number(number), 0)
                          const title = data.labels[tooltipItem.index]
                          const total = dataset.data[tooltipItem.index]
                          const backgroundColor = dataset.backgroundColor[tooltipItem.index]
                          const percentage = this.calculateSlicePercentage(expenseTotal, parseFloat(total))

                          return `<div class='legend-item is-expense' >
                          <div class='legend-item-circle' style='background-color: ${backgroundColor};'></div>
                          <div class='legend-item-content'>
                            <span class='legend-item-title'>${title}</span>
                            <div>
                              <span style="font-size: 12px; color: #424955;">
                                ${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, total)}
                              </span>
                              <span style="font-size: 11px; color: #858C99; margin-left: 4px">
                                ${percentage}%
                              </span>
                            </div>
                          </div>
                        </div>
                        `
                        }
                      },
                      custom: function (tooltipModel, data) {
                        const id = 'expensesTooltip'
                        // Tooltip Element
                        var tooltipEl = document.getElementById(id);

                        // Create element on first render
                        if (!tooltipEl) {
                          tooltipEl = document.createElement('div');
                          tooltipEl.id = id;
                          tooltipEl.className = 'chart-tooltip'

                          document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                          // @ts-ignore
                          tooltipEl.style.opacity = 0;
                          return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                          tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                          tooltipEl.classList.add('no-transform');
                        }

                        // Add content
                        if (tooltipModel.body) {
                          ReactDOM.render(
                            <GenericTooltip
                              tooltip={tooltipModel}
                            />,
                            tooltipEl
                          )
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        // @ts-ignore
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                      }
                    }
                  }}
                />}
              </div>

              <div className='grid-cell with-6col'>
                <div className='dashboard-expenses-legend'>
                  <h1 className='dashboard-expenses-total'
                    dangerouslySetInnerHTML={{
                      __html: `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, expenseTotal)} <span> ${t('Dashboard::total')}</span>`
                    }}
                  />
                  <div className='legend-items'>
                    {expenses.slice(0, 5).map((expense, index) => {
                      const { color, name, total } = expense
                      const percentage = this.calculateSlicePercentage(expenseTotal, parseFloat(total))

                      return (
                        <ExpenseLegendItem
                          key={`${name}-${index}`}
                          name={name}
                          total={total}
                          color={color}
                          percentage={percentage}
                          currency={setting.default_currency}
                          numberFormat={setting.number_format}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    const { didInitialLoad } = this.state

    return (
      <>
        <Helmet>
          <title>{t('Dashboard::{{__appName}} | Dashboard')}</title>
        </Helmet>
        <TopNavigation
          icon='dashboard-navigation'
          title={t('Dashboard::Dashboard')}
        />
        <ScrollToTopOnMount />

        <PageContent>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <div className='animated fadeIn'>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                {this.renderFinancialPerformance()}
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                {this.renderYearlyGoal()}
              </div>
            </div>

            <div className='grid'>
              {this.renderAccountReceivables()}
              {this.renderExpenses()}
            </div>
          </div>}
        </PageContent>
      </>
    )
  }
}


const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showBoardModal: (options) => dispatch(showBoardModal(options)),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showExpenseModal: (options) => dispatch(showExpenseModal(options)),
    showProductModal: (options) => dispatch(showProductModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard))