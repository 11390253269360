import styled from 'styled-components'
import { Style } from '../../styles'

const ConversationItemAvatar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center
	font-size: 12px;
	width: 32px;
	height: 32px;
	min-width: 32px;
	line-height: 32px;
	color: white;
	background: rgb(121, 85, 72);
	border-radius: 50%;
	font-weight: bold;
	margin-right: ${Style.spacing.x1};
`

export default ConversationItemAvatar