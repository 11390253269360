import styled from 'styled-components'

const ConversationListEmpty = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	svg {
		margin-bottom: 20px;
	}
`

export default ConversationListEmpty