import * as React from 'react'
import styled, { css } from 'styled-components'

const PinInput = styled.input<{ focused: boolean }>`
	appearance: none;
	outline: none;
	background-color: rgb(255, 255, 255);
	border-radius: 0 !important;
	box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
	border: none !important;
	flex: 1 1 auto;
	min-height: 52px;
	max-height: 52px;
	font-size: 32px;
	text-align: center;

	&:first-child {
		border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
	}

	&:last-child {
		border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
	}

	${props => props.focused && css`
		box-shadow: none;
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
		z-index: 1;
		box-shadow: 0 0 0 2px #4389fc !important;
	`}
`

interface IProps {
	onChange: (value) => void
	onBackspace: () => void
	onPaste: (e) => void
}

interface IState {
	value: string
	focused: boolean
}

export default class PinItem extends React.Component<IProps, IState> {
	private input  = React.createRef<HTMLInputElement>()

	constructor(props: IProps) {
		super(props)

		this.state = {
			value: '',
			focused: false,
		}

		this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);   
	}

	focus () {
		if (this.input.current) {
			this.input.current.focus()
		}
	}

	clear () {
		if (this.input.current) {
			this.input.current.value = ''
		}
	}

	onChange(e) {
		const value = e.currentTarget.value
		const isNumber = /^\d+$/.test(value)

		if (isNumber && value.length <= 2) {
			const newValue = value.length === 2 ? value.charAt(1) : value.charAt(0)

			this.setState({ value: newValue }, () => {
				this.props.onChange(newValue)
			})
		}
	}
	
	onKeyDown(e) {
		const { onBackspace, onChange } = this.props

    if (e.keyCode === 8) {
			this.setState({ value: '' }, () => {
				onChange('')
				onBackspace()
			})
    }
	}
	
	onFocus(e) {
    e.target.select();
    this.setState({ focused: true })
	}
	
	onBlur() {
    this.setState({ focused: false })
	}
	
	render () {
		const { onPaste } = this.props
		const { value, focused } = this.state

		return (
			<PinInput
				ref={this.input}
				placeholder=''
				value={value}
				pattern='[0-9]*'
				autoComplete="off"
				maxLength={2}
				onChange={this.onChange}
				onKeyDown={this.onKeyDown}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onPaste={onPaste}
				focused={focused}
			/>
		)
	}
}