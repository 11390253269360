import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 16px;
	background: #E4E7EB;
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	border: 1px solid rgba(29,30,27,.25);
`

const Item = styled.div`
	height: 16px;
`

interface IReportProgressBarItem {
	value: number
	color: string
	tooltipLabel?: (percentage: number) => string
}

interface IProps {
	items?: IReportProgressBarItem[]
}

const ReportProgressbar = (props: IProps) => {
	const { items, } = props

	const totalValue = items.reduce((total, item) => total + item.value, 0)

	React.useEffect(() => {
		ReactTooltip.rebuild()
	}, [])

	return (
		<Container>
			{items.map((item, index) => {
				const percentage = item.value / totalValue * 100
				const progressStyle = { width: `${percentage}%`, backgroundColor: item.color }
				return (
					<Item
						key={index}
						style={progressStyle}
						data-tip={item.tooltipLabel ? item.tooltipLabel(percentage) : ''}
					/>
				)
			})}
		</Container>
	)
}

export default ReportProgressbar