
import styled from 'styled-components'
import { Style } from '../../styles'

const BlockEditorSidebarSection = styled.div`
	padding: ${Style.spacing.x2};

	&:not(:last-child) {
		border-bottom: 1px solid ${Style.color.border};
	}
`

export default BlockEditorSidebarSection