import styled from "styled-components";
import { Style } from "../../styles";
import MenuSectionHeader from "./MenuSectionHeader";
import MenuSection from "./MenuSection";

const SettingsMenu = styled.div`
  display: flex;
  max-width: 280px;
  height: 100%;
  padding: 20px;
  width: 100%;
  flex-direction: column;
  background: white;
  border-right: 1px solid rgb(217, 226, 236);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    background-color: transparent;
    border-radius: 5px;
    max-width: 100%;
    margin: 0;
    flex-direction: row;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: ${Style.spacing.x2_5};
    margin-bottom: ${Style.spacing.x2_5};

    ${MenuSectionHeader} {
      display: none;
    }

    ${MenuSection} {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;

      > div {
        margin-right: ${Style.spacing.x1};
        margin-bottom: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      display: none;
    }
  }
`

export default SettingsMenu