import * as React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import Icon from '../components/Icons/Icon'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../store'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import MemberItem from '../components/Team/MemberItem'
import TeamController from '../controllers/TeamController'
import AccountantController from '../controllers/AccountantController'
import Notification from '../utilities/Notification'
import PageLoader from '../components/Page/PageLoader'
import { showAddAccountantModal, showConfirmModal, showUserWorkspaceSettingModal } from '../store/modals/actions'
import PageContent from '../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper'
import { UserWorkspaceSettingsController } from '../controllers'
import { Accountant, CurrentUser, UserWorkspaceSetting } from '../types'

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showConfirmModal: typeof showConfirmModal
  showUserWorkspaceSettingModal: typeof showUserWorkspaceSettingModal
  showAddAccountantModal: typeof showAddAccountantModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  isFetching: boolean
  team: UserWorkspaceSetting[]
  accountants: Accountant[]
}

class Team extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      didInitialLoad: false,
      isFetching: false,
      team: [],
      accountants: [],
    }

    this.onAddMemberClick = this.onAddMemberClick.bind(this)
    this.onAddAccountantClick = this.onAddAccountantClick.bind(this)
    this.onResendAccountantCredentialsClick = this.onResendAccountantCredentialsClick.bind(this)
    this.onDeleteAccountantClick = this.onDeleteAccountantClick.bind(this)
    this.onUserWorkspaceSettingModalSubmit = this.onUserWorkspaceSettingModalSubmit.bind(this)
  }

  onAddMemberClick() {
    this.props.showUserWorkspaceSettingModal({
      userWorkspaceSetting: {},
      onSubmit: this.onUserWorkspaceSettingModalSubmit
    })
  }

  onAddAccountantClick() {
    const { accountants } = this.state

    this.props.showAddAccountantModal({
      onSubmit: (accountant: Accountant) => {
        this.setState({
          accountants: [
            ...accountants,
            accountant,
          ]
        })
      }
    })
  }


  onUserWorkspaceSettingModalSubmit(userWorkspaceSetting: UserWorkspaceSetting) {
    const { team } = this.state
    const teamMemberIndex = team.findIndex(member => member.id === userWorkspaceSetting.id)

    if (teamMemberIndex !== -1) {
      team[teamMemberIndex] = userWorkspaceSetting

      this.setState({ team: [...team] })
    } else {
      this.setState({ team: [...team, userWorkspaceSetting] })
    }
  }

  onEditTeamMemberClick(userWorkspaceSetting: UserWorkspaceSetting) {
    this.props.showUserWorkspaceSettingModal({
      userWorkspaceSetting: { id: userWorkspaceSetting.id },
      onSubmit: this.onUserWorkspaceSettingModalSubmit,
    })
  }

  onDeleteTeamMemberClick(member: UserWorkspaceSetting) {
    const { showConfirmModal, t } = this.props
    const { team } = this.state

    showConfirmModal({
      title: t('Team::Remove team member'),
      description: t('Team::You are about to remove team member <b>{{email}}</b>. Are you sure?', { email: member.email }),
      action: { label: t('Team::Confirm') },
      onConfirm: async () => {
        try {
          const response = await UserWorkspaceSettingsController.delete(member.id)

          const { success } = response

          if (success) {
            Notification.notifySuccess(t('Team::Team member succesfully removed'))

            const teamMemberIndex = team.findIndex(m => m.id === member.id)

            if (teamMemberIndex !== -1) {
              // Remove list from lists
              team.splice(teamMemberIndex, 1);

              this.setState({ team: [...team] })
            }
          } else {
            Notification.notifyError(t('Team::Oops something went wrong!'))
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onResendAccountantCredentialsClick(accountant: Accountant) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Team::New login details'),
      description: t('Team::You are about to send new login to details to <b>{{email}}</b>. Are you sure?', { email: accountant.email }),
      action: { label: t('Team::Confirm') },
      onConfirm: async () => {
        try {
          const response = await AccountantController.resendCredentials(accountant.id)

          const { success } = response

          if (success) {
            Notification.notifySuccess(t('Team::Login details successfully send'))
          } else {
            Notification.notifyError(t('Team::Oops something went wrong!'))
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onDeleteAccountantClick(accountant: Accountant) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Team::Edit'),
      description: t('Team::You are about to delete <b>{{email}}</b> from your team. Are you sure?', { email: accountant.email }),
      action: { label: t('Team::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await AccountantController.delete(accountant.id)
          const { success } = response

          if (success) {
            const { accountants } = this.state

            const accountantIndex = accountants.findIndex(a => a.id === accountant.id)

            accountants.splice(accountantIndex, 1);

            this.setState({
              accountants: [...accountants]
            });

            Notification.notifySuccess(t('Team::Team member successfully removed'))
          } else {
            Notification.notifyError(t('Team::Oops something went wrong'))
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  componentWillMount() {
    this.fetchTeam()
  }

  fetchTeam() {
    TeamController
      .getTeam()
      .then((response) => {
        const { team, accountants } = response

        this.setState({
          didInitialLoad: true,
          team: team,
          accountants: accountants
        })
      })
  }

  render() {
    const { t } = this.props
    const {
      didInitialLoad,
      team,
      accountants,
    } = this.state

    const { currentUser } = this.props
    const { workspace } = currentUser

    return (
      <>
        <Helmet>
          <title>{t('Team::{{__appName}} | Team')} </title>
        </Helmet>
        <ScrollToTopOnMount />

        <TopNavigation
          icon='team'
          title={t('Team::Team')}
        />

        <PageContent>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <>
            <h3 className='team-title'>{t('Team::Team')}</h3>

            <p className='team-description'>
              {(!workspace.business_name || (workspace.business_name && workspace.business_name.length === 0)) && t('Team::Manage who has access to this workspace or add new members to the team')}
              {workspace.business_name && workspace.business_name.length > 0 && t('Team::Manage who has access to {{name}} or add new members to the team', { name: workspace.business_name })}
            </p>

            <p>
              <a href='javascript://' className='team-action' onClick={this.onAddMemberClick}>
                <Icon icon='plus-circle' />
                {t('Team::Add member ')}
              </a>
            </p>

            <div className='team-members'>
              {team.map(member => {
                const { role } = member
                return (
                  <MemberItem
                    key={member.id}
                    name={member?.user?.name ? member?.user?.name : member.email}
                    subheading={member.email}
                    status={member.status}
                    role={UserWorkspaceSettingHelper.getRoleTranslation(role)}
                    onEditClick={(member.user_id === currentUser.id) || !member.admin ? () => this.onEditTeamMemberClick(member) : null}
                    onDeleteClick={!member.admin ? () => this.onDeleteTeamMemberClick(member) : null}
                  />
                )
              })}
            </div>

            <h3 className='team-title'>{t('Team::Accountants')}</h3>

            <p className='team-description'>
              {t('Team::Allow your accountant to manage your administration by inviting them to your workspace.')}
            </p>

            <p>
              <a href="javascript://" className='team-action' onClick={this.onAddAccountantClick}>
                <Icon icon='plus-circle' />
                {t('Team::Add accountant')}
              </a>
            </p>

            <div className='team-members'>
              {accountants.map(accountant => {
                const { email } = accountant
                return (
                  <MemberItem
                    key={accountant.id}
                    name={email}
                    role={t('Team::accountant')}
                    onCredentialsClick={() => this.onResendAccountantCredentialsClick(accountant)}
                    onDeleteClick={() => this.onDeleteAccountantClick(accountant)}
                  />
                )
              })}
            </div>
          </>}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showUserWorkspaceSettingModal: (options) => dispatch(showUserWorkspaceSettingModal(options)),
    showAddAccountantModal: (options) => dispatch(showAddAccountantModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Team))