import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { WithTranslation, withTranslation } from 'react-i18next'
import { GmailFormData, GmailIntegrationConfig } from '../../../types'
import ActionButton from '../ActionButton'
import Tooltip from '../../Tooltips/Tooltip'
import copy from 'copy-to-clipboard'
import Notification from '../../../utilities/Notification'

type IProps = {
	config: GmailIntegrationConfig
	formData: GmailFormData
	onConfigChange: (config: GmailIntegrationConfig) => void
} & WithTranslation

interface IState { }

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		color: ${props => props.theme.textColor};
		margin-bottom: ${Style.spacing.x2};
		font-weight: bold;
	}
	
	label {
		color: ${props => props.theme.textColor};
	}
`


class GmailForm extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {}

		this.onGoogleDomainWideDelegationClick = this.onGoogleDomainWideDelegationClick.bind(this)
	}

	onGoogleDomainWideDelegationClick() {
		window.open('https://admin.google.com/u/1/ac/owl/domainwidedelegation')
	}

	render() {
		const { t, formData } = this.props

		return (
			<Container>
				<h1>{t('GmailForm::Instructions')}</h1>

				<div className='form-item'>
					<p>
						{t('GmailForm::Click on the button below to navigate to the Google Admin Console and create a new API client with following settings:')}
					</p>
				</div>

				<div className='form-item'>
					<label>
						{t('GmailForm::Client ID')}
						<Tooltip
							content={t('GmailForm::{{__appName}} needs to be authorized to send emails on your behalf. Copy & paste this ID on the Google Admin Console.')}
							containerStyle={{ marginLeft: 8 }}
						/>
					</label>
					<input
						type='text'
						value={formData.client_id}
						readOnly
						onClick={() => {
							copy(formData.client_id)
							Notification.notifySuccess(t('GmailForm::Copied to clipboard'))
						}}
					/>
				</div>

				<div className='form-item'>
					<label>
						{t('GmailForm::Scopes')}
						<Tooltip
							content={t('GmailForm::Scopes are permissions that {{__appName}} needs to send emails on your behalf. Copy & paste these scopes on the Google Admin Console.')}
							containerStyle={{ marginLeft: 8 }}
						/>
					</label>
					<textarea
						value={formData.scopes.join('\n')}
						readOnly
						onClick={() => {
							copy(formData.scopes.join(','))
							Notification.notifySuccess(t('GmailForm::Copied to clipboard'))
						}}
					/>
				</div>

				<ActionButton onClick={this.onGoogleDomainWideDelegationClick}>
					{t('GmailForm::Navigate to admin console')}
				</ActionButton>
			</Container>
		)
	}
}

export default withTranslation()(GmailForm)