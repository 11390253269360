import * as React from 'react'
import { Translation, WithTranslation, withTranslation } from 'react-i18next'
import { SummaryController } from '../../controllers'
import { Settings } from '../../types'
import NumberFormatter from '../../utilities/NumberFormatter'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'

interface IProps {
  setting: Settings
}

interface IState {
  summary: {
    year: {
      income: string | number
      net_income: string | number
    }
    previous_month: {
      income: string | number
      net_income: string | number
    }
    month: {
      income: string | number
      net_income: string | number
    }
    outstanding: {
      income: string | number
      net_income: string | number
    }
  } | null
  isFetching: boolean
  didInitialLoad: boolean
}

class InvoiceSummary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      summary: null,
      isFetching: false,
      didInitialLoad: false
    };
  }

  componentDidMount() {
    this.fetchSummary();
  }

  fetchSummary() {
    this.setState({ isFetching: true })
    SummaryController
      .getInvoicesSummary()
      .then(summary => {
        this.setState({
          summary,
          didInitialLoad: true,
          isFetching: false
        });
      })
      .catch(console.error)
  }

  render() {
    const { setting } = this.props
    const { didInitialLoad, summary } = this.state

    let yearLabel = ''
    let yearCaption = ''
    let previousMonthLabel = ''
    let previousMonthCaption = ''
    let thisMonthLabel = ''
    let thisMonthCaption = ''
    let outstandingLabel = ''
    let outstandingCaption = ''



    return (
      <SummaryContainer>
        <Translation>
          {
            (t, { i18n }) => {
              if (summary) {
                const { year, previous_month, month, outstanding } = summary

                yearLabel = NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, year.income)
                yearCaption = `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, year.net_income)} ${t('InvoiceSummary::excl. VAT')}`

                previousMonthLabel = NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, previous_month.income)
                previousMonthCaption = `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, previous_month.net_income)} ${t('InvoiceSummary::excl. VAT')}`

                thisMonthLabel = NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, month.income)
                thisMonthCaption = `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, month.net_income)} ${t('InvoiceSummary::excl. VAT')}`

                outstandingLabel = NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, outstanding.income)
                outstandingCaption = `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, outstanding.net_income)} ${t('InvoiceSummary::excl. VAT')}`
              }

              return (
                <>
                  <SummaryItem
                    title={t('InvoiceSummary::This financial year')}
                    label={yearLabel}
                    captionLabel={yearCaption}
                    isLoading={!didInitialLoad}
                  />
                  <SummaryItem
                    title={t('InvoiceSummary::Previous month')}
                    label={previousMonthLabel}
                    captionLabel={previousMonthCaption}
                    isLoading={!didInitialLoad}
                  />
                  <SummaryItem
                    title={t('InvoiceSummary::This month')}
                    label={thisMonthLabel}
                    captionLabel={thisMonthCaption}
                    isLoading={!didInitialLoad}
                  />
                  <SummaryItem
                    title={t('InvoiceSummary::Outstanding')}
                    label={outstandingLabel}
                    captionLabel={outstandingCaption}
                    isLoading={!didInitialLoad}
                  />
                </>
              )
            }
          }
        </Translation>

      </SummaryContainer>
    );
  }
}

export default InvoiceSummary