import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TaskPopoverContainer from '../TaskModal/TaskPopoverContainer'
import TaskPopoverHeader from '../TaskModal/TaskPopoverHeader'
import TaskPopoverTitle from '../TaskModal/TaskPopoverTitle'
import TaskPopoverContent from '../TaskModal/TaskPopoverContent'
import TaskButton from '../TaskModal/TaskButton'
import TaskPopoverClose from '../TaskModal/TaskPopoverClose'
import { BoardList } from '../../types'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'

type IProps = {
	list: BoardList
	onMoveTasks: (list: BoardList) => void
	onClose: () => void
}

const MoveTasksPopover = (props: IProps) => {
	const { list, onClose } = props
	const { t } = useTranslation()
	const lists = useSelector((state: AppState) => state.board.lists || [])

	const onListClick = (list: BoardList) => {
		props.onMoveTasks(list)
	}

	return (
		<TaskPopoverContainer>
			<TaskPopoverHeader>
				<div />
				<TaskPopoverTitle>{t('TaskModal::Move all tasks in list')}</TaskPopoverTitle>
				<TaskPopoverClose onClick={onClose} />
			</TaskPopoverHeader>
			<TaskPopoverContent>
				{lists
					.filter(l => l.id !== list.id)
					.map((list, index) => (
						<TaskButton key={index} onClick={() => onListClick(list)}>
							{list.name}
						</TaskButton>)
					)}
			</TaskPopoverContent>
		</TaskPopoverContainer>
	)
}

export default MoveTasksPopover