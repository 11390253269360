import * as React from 'react'
import copy from 'copy-to-clipboard';
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Title } from '../../components/BlockEditor/RenderBlocks/InputBlock'
import { Style } from '../../styles'
import Notification from '../../utilities/Notification';
import { useTranslation } from 'react-i18next';
import { FormResponsesController } from '../../controllers';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import PageLoader from '../../components/Page/PageLoader';
import ConversationHeader from '../../components/Conversation/ConversationHeader';
import Icon from '../../components/Icons/Icon';
import ConversationHeaderAction from '../../components/Conversation/ConversationHeaderAction';
import ConversationAnnouncement from '../../components/Conversation/ConversationAnnouncement';
import moment from '../../utilities/Moment';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import ActionList, { IActionListItem } from '../../components/ActionList/ActionList';
import Popover from '../../components/Popover/Popover';
import { showConfirmModal } from '../../store/modals/actions';
import { Dispatch } from 'redux';
import ConversationWrapper from '../../components/Conversation/ConversationWrapper';
import ConversationMessages from '../../components/Conversation/ConversationMessages';
import { CurrentUser, FormResponse as FormResponseType } from '../../types';

const Form = styled.div`
  width: 100%;
	background: white;
	margin: 0 auto;
	border-radius: 5px;
	border: 2px solid rgb(234, 237, 245);
	padding: ${Style.spacing.x2};
	margin-bottom: ${Style.spacing.x2};

	div.form-item:last-child {
		margin-bottom: 0;
	}
`

const Copy = styled.span`
	margin-left: ${Style.spacing.x1};
	color: ${Style.color.brandPrimary};
	font-size: 12px;
	display: none;
	cursor: pointer;
`

const ValueContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;

	&:hover {
		${Copy} {
			display: initial;
		}
	}
`

const Value = styled.div``

const ConversationHeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ConversationHeaderActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-bottom: ${Style.spacing.x1};
	}
`

const ConversationHeaderBack = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 4px 4px 0;
	margin-right: ${Style.spacing.x1};
	cursor: pointer;

	svg {
		width: 20px;
		height: 20px;
	}

	i {
		font-size: 20px;
	}
`

const ConversationHeaderBackContainer = styled.div`
	display: none;
	flex-direction: row;
	align-items: center;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: flex;
	}
`

const ConversationHeaderBackTitle = styled.div`
	font-weight: 600;
	padding-right: 6px;
	font-size: 16px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

const ConversationSubjectContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

const ConversationSubject = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: bold;
	font-size: 16px;

	svg {
		width: 16px;
		height: 16px;
		margin-right: ${Style.spacing.x1};
	}

	i {
		margin-right: ${Style.spacing.x1};
	}
`

interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showConfirmModal: typeof showConfirmModal
}

type IProps = {
	onLeaveConversationClick: () => void
	onDeleteConversationClick: (formResponse: FormResponseType) => void
} & IStateToProps & IDispatchToProps & RouteComponentProps<{ id: string, responseId: string }>

interface IState {
	formResponse: FormResponseType | null
	didInitialLoad: boolean
}

const FormResponse = (props: IProps) => {
	const { id, responseId } = props.match.params
	const { currentUser, showConfirmModal } = props
	const { t } = useTranslation()
	const { workspace: { setting } } = currentUser
	const [state, setState] = React.useState<IState>({
		formResponse: null,
		didInitialLoad: false,
	})
	const [optionsPopoverActive, setOptionsPopoverActive] = React.useState(false)
	const { formResponse, didInitialLoad } = state

	const actions: IActionListItem[] = [
		{ key: 'delete', icon: 'trash-alt-solid', content: t('FormResponse::Delete'), destructive: true },
	]

	const fetchFormResponse = async () => {
		try {
			setState({ formResponse: null, didInitialLoad: false })
			const response = await FormResponsesController.getFormResponse(id, responseId)

			if (response) {
				setState({ formResponse: response, didInitialLoad: true })
			} else {
				props.history.replace(RouteHelper.process(ERoute.PATH_FORM_RESPONSES, { id: id }))
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	const onOptionsClick = () => {
		setOptionsPopoverActive(!optionsPopoverActive)
	}

	const onOptionsClose = () => {
		setOptionsPopoverActive(false)
	}

	const onOptionsActionClick = (key: string) => {
		onOptionsClose()

		switch (key) {
			case 'delete':
				onFormResponseDeleteClick()
				break
		}
	}

	const onFormResponseDeleteClick = () => {
		props.onDeleteConversationClick(formResponse)
	}

	React.useEffect(() => {
		fetchFormResponse().catch(console.error)
	}, [responseId])

	const onLeaveConversationClick = () => {
		props.onLeaveConversationClick()
	}

	const onCopyValueClick = (value: string) => {
		copy(value)
		Notification.notifySuccess(t('FornResponses::Copied to clipboard'))
	}

	return (
		<ConversationWrapper>
			{!didInitialLoad && <PageLoader />}
			{didInitialLoad && formResponse && <>
				<ConversationHeader>
					<ConversationHeaderWrapper>
						<ConversationHeaderActionsContainer>
							<ConversationHeaderBackContainer>
								<ConversationHeaderBack onClick={onLeaveConversationClick}>
									<Icon icon='arrow-left' />
								</ConversationHeaderBack>

								<ConversationHeaderBackTitle>
									{formResponse.form.name ? formResponse.form.name : 'Bizzey'}
								</ConversationHeaderBackTitle>
							</ConversationHeaderBackContainer>
						</ConversationHeaderActionsContainer>

						<ConversationSubjectContainer>
							<ConversationSubject>
								<Icon icon='envelope' />
								{t('FormResponse::New form submission')}
							</ConversationSubject>

							<Popover
								active={optionsPopoverActive}
								activator={
									<ConversationHeaderAction onClick={onOptionsClick}>
										<Icon icon='solaris-dots' />
									</ConversationHeaderAction>
								}
								onClose={onOptionsClose}
								placement='bottom-end'
							>
								<ActionList
									actions={actions}
									onClick={onOptionsActionClick}
									hasIndicator
								/>
							</Popover>
						</ConversationSubjectContainer>
					</ConversationHeaderWrapper>
				</ConversationHeader>

				<ConversationMessages>
					<ConversationAnnouncement
						dangerouslySetInnerHTML={{
							__html: t('FormResponse::Created by {{__appName}} on {{date}}', {
								date: moment(formResponse.created_at).format(`${setting.date_format} HH:mm:ss`)
							})
						}}
					/>

					<Form>
						{Object.entries(formResponse.data).map(([key, value], index) => {
							return (
								<div key={`${key}-${index}`} className='form-item'>
									<Title>{key}</Title>
									<ValueContainer>
										<Value>{value}</Value>
										{value && <Copy onClick={() => onCopyValueClick(value)}>{t('FormResponses::Copy')}</Copy>}
									</ValueContainer>
								</div>
							)
						})}
					</Form>
				</ConversationMessages>
			</>}
		</ConversationWrapper>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {
		showConfirmModal: (options) => dispatch(showConfirmModal(options))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FormResponse)