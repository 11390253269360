declare global {
	interface Window { dataLayer: any[]; }
}

interface DataLayerEvent {
	event: DataLayerEventType
	[key: string]: any
}

export const enum DataLayerEventType {
	SIGNUP = 'signup',
	SUBSCRIBED = 'subscribePlan',
}

export default class DataLayerHelper {
	static push(data: DataLayerEvent) {
		if (window.dataLayer) {
			window.dataLayer.push(data);
		} else {
			throw new Error('dataLayer is not defined');
		}
	}
}