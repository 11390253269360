import * as React from 'react'
import { RouteComponentProps, Switch, Redirect, Route } from 'react-router'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import styled, { css, ThemeProvider } from 'styled-components'
import Icon from '../components/Icons/Icon'
import { Style } from '../styles'
import Images from '../images'
import { IntegrationsController } from '../controllers'
import PageLoader from '../components/Page/PageLoader'
import ColorHelper from '../helpers/ColorHelper'
import { connect } from 'react-redux'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import TooltipError from '../components/Tooltips/ErrorTooltip'
import ERoute from '../ERoute'
import ActionButton from '../components/Integrations/ActionButton'
import Mollie from '../components/Integrations/form/MollieForm'
import YukiForm from '../components/Integrations/form/YukiForm'
import MailchimpForm from '../components/Integrations/form/MailchimpForm'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import ClearfactsForm from '../components/Integrations/form/ClearfactsForm'
import MailsyncForm from '../components/Integrations/form/MailsyncForm'
import { CurrentUser, Integration, IntegrationType, MarketplaceIntegration } from '../types'
import UnpaidForm from '../components/Integrations/form/UnpaidForm'
import GmailForm from '../components/Integrations/form/GmailForm'
import OctopusForm from '../components/Integrations/form/OctopusForm'
import ConvertkitForm from '../components/Integrations/form/ConvertkitForm'

const Container = styled.div<{ backgroundColor?: string }>`
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 65px;


	${(props) => props.backgroundColor && css`
		background-color: ${props.backgroundColor};
	`}
`

const CloseCross = styled.div`
	position: absolute;
	top: 26px;
	right: 24px;
	cursor: pointer;
	z-index: 1;
	font-size: 24px;

	i {
        color: ${props => props.theme.textColor};
	}
`

const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	padding: ${Style.spacing.x3};

	form {
		min-width: 500px;
    max-width: 500px;

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			min-width: initial;
			width: 100%;
      max-width: initial;
		}
	}
`

const Logo = styled.img`
	position: absolute;
	top: 26px;
	left: 24px;
	font-size: 16px;
	z-index: 1;
	max-width: 140px;
	max-height: 40px;
`

const ConnectContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const ConnectTitle = styled.div`
	text-align: center;
	font-size: 28px;
	font-weight: 600;
	margin-bottom: ${Style.spacing.x1};
  color: ${props => props.theme.textColor};
`

const ConnectDescription = styled.div`
	text-align: center !important;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: ${Style.spacing.x3};
	text-align: center;
  max-width: 500px;
  color: ${props => props.theme.textColor};
  align-self: center;
`

const ConnectOauthContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 32px;
	position: relative;
	min-width: 240px;
`

const ConnectOauthLogo = styled.div<{ isBrand?: boolean }>`
	position: relative;

	img {
		&:first-child {
			width: 50px;
			height: 56px;
		}

		&:last-child {
			position: absolute;
			max-height: 40px;
      max-width: 40px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`

const ConnectOauthSync = styled.div`
	flex: 0 0 60px;
	font-size: 30px;
	display: flex;
	align-items: center;
    justify-content: center;
    color: ${props => props.theme.textColor};
`

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: ${Style.spacing.x3};
`

const FooterActions = styled.div`
	display: flex;
	align-items: center;

	> div:not(:last-child) {
		margin-right: ${Style.spacing.x2};
	}
`
const FooterBackButton = styled.div`
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

    color: ${props => props.theme.textColor};
    font-weight: bold;
`

export interface IntegrationFormSubmitOptions {
  returnOnSubmit?: boolean,
  refetchOnSubmit?: boolean
}

export interface IntegrationFormConfigChangeOptions extends IntegrationFormSubmitOptions {
  triggerSubmit?: boolean
}

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps { }

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ type: IntegrationType }> & WithTranslation

interface IState {
  didInitialLoad: boolean
  integration?: Integration
  marketplace_integration?: MarketplaceIntegration
  formData?: any
  showForm?: boolean
  errors: any
}

class IntegrationPage extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      didInitialLoad: false,
      integration: null,
      formData: null,
      marketplace_integration: null,
      showForm: false,
      errors: {},
    }

    this.onBackClick = this.onBackClick.bind(this)
    this.onConnectClick = this.onConnectClick.bind(this)
    this.onDisconnectClick = this.onDisconnectClick.bind(this)
    this.onConfigChange = this.onConfigChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

    this.renderTypeContent = this.renderTypeContent.bind(this)
    this.renderFooterActions = this.renderFooterActions.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  getType(): IntegrationType {
    const {
      match: {
        params: {
          type: paramType
        }
      }
    } = this.props

    const type: IntegrationType = paramType
    return type
  }

  async fetchForm() {
    try {
      const response = await IntegrationsController.getForm(this.getType())
      const { type, integration, marketplace_integration, form_data } = response

      this.setState({
        didInitialLoad: true,
        marketplace_integration: marketplace_integration,
        integration: integration,
        formData: form_data,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  getTitle() {
    const {
      match: {
        params: {
          type: paramType
        }
      }
    } = this.props

    const type: IntegrationType = paramType

    switch (type) {
      case IntegrationType.MOLLIE: return 'Mollie'
      case IntegrationType.STRIPE: return 'Stripe'
      case IntegrationType.YUKI: return 'Yuki'
      case IntegrationType.CLEARFACTS: return 'Clearfacts'
      case IntegrationType.PONTO: return 'Ponto'
      case IntegrationType.PQR: return 'Payment QR'
      case IntegrationType.MAILCHIMP: return 'Mailchimp'
      case IntegrationType.MAILSYNC: return 'Mailsync'
      case IntegrationType.UNPAID: return 'Unpaid'
      case IntegrationType.OUTLOOK: return 'Outlook'
      case IntegrationType.GMAIL: return 'Gmail'
      case IntegrationType.OCTOPUS: return 'Octopus'
      case IntegrationType.CONVERTKIT: return 'Convertkit'
      default:
        throw `[getTitle] No valid integration type ${type}`
    }
  }

  onBackClick() {
    this.props.history.push(ERoute.PATH_INTEGRATIONS)
  }

  onConnectClick() {
    const {
      currentUser,
      match: {
        params: {
          type: paramType
        }
      }
    } = this.props
    const { formData } = this.state

    const type: IntegrationType = paramType

    switch (type) {
      case IntegrationType.MOLLIE: window.location.assign('/auth/mollie');
        break
      case IntegrationType.STRIPE: window.location.assign(`/auth/stripe_connect?response_type=code&scope=read_write&stripe_landing=login&$stripe_user[email]=${currentUser.workspace.email}`);
        break
      case IntegrationType.PONTO:
        window.location.assign(`/auth/ponto?onboarding_details_id=${formData.onboarding_details_id}`);
        break
      case IntegrationType.PQR: this.onFormSubmit()
        break
      case IntegrationType.YUKI: this.onFormSubmit({ returnOnSubmit: false })
        break
      case IntegrationType.CLEARFACTS: window.location.assign('/auth/clearfacts')
        break
      case IntegrationType.MAILCHIMP: window.location.assign('/auth/mailchimp')
        break
      case IntegrationType.CONVERTKIT: this.onFormSubmit({ returnOnSubmit: false })
        break
      case IntegrationType.MAILSYNC: this.onFormSubmit({ returnOnSubmit: false })
        break
      case IntegrationType.UNPAID: this.onFormSubmit({ returnOnSubmit: false })
        break
      case IntegrationType.OUTLOOK:
        window.location.href = formData.admin_consent_url
        break
      case IntegrationType.GMAIL: this.onFormSubmit({ returnOnSubmit: false })
        break
      case IntegrationType.OCTOPUS: this.onFormSubmit({ returnOnSubmit: false })
        break
      default:
        throw `[onConnectClick] No valid integration type ${type}`
    }
  }

  async onDisconnectClick() {
    try {
      await IntegrationsController.destroy(this.getType())
      this.props.history.replace(ERoute.PATH_INTEGRATIONS)
    } catch (ex) {
      console.error(ex)
    }
  }

  onConfigChange(config: object, options?: IntegrationFormConfigChangeOptions) {
    const { integration } = this.state

    this.setState({
      integration: {
        ...integration,
        config: config,
      }
    }, () => {
      if (options && options.triggerSubmit) {
        this.onFormSubmit({ ...options })
      }
    })
  }

  async onFormSubmit(options: IntegrationFormSubmitOptions = { returnOnSubmit: true, refetchOnSubmit: false }) {
    const { integration } = this.state

    try {
      let response = null

      if (integration.id) {
        response = await IntegrationsController.update(integration)
      } else {
        response = await IntegrationsController.create(integration)
      }

      if (response.errors) {
        this.setState({ errors: response.errors })
        return
      } else {
        this.setState({ integration: response })
      }

      if (options) {
        if (options.returnOnSubmit) this.props.history.replace(ERoute.PATH_INTEGRATIONS)
        if (options.refetchOnSubmit) this.fetchForm()
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderTypeContent() {
    const { t } = this.props
    const { formData, integration, marketplace_integration } = this.state
    const { config } = integration
    const type = this.getType()
    const themeColor = ColorHelper.getColorTheme(marketplace_integration.theme.cover)
    const isDark = themeColor === 'dark'

    switch (type) {
      case IntegrationType.MOLLIE:
        return (
          <Mollie
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.MAILCHIMP:
        return (
          <MailchimpForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.YUKI:
        return (
          <YukiForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.CLEARFACTS:
        return (
          <ClearfactsForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.MAILSYNC:
        return (
          <MailsyncForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.UNPAID:
        return (
          <UnpaidForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.GMAIL:
        return (
          <GmailForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.OCTOPUS:
        return (
          <OctopusForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.CONVERTKIT:
        return (
          <ConvertkitForm
            config={integration.config}
            formData={formData}
            onConfigChange={this.onConfigChange}
          />
        )
      case IntegrationType.STRIPE:
      case IntegrationType.PQR:
      case IntegrationType.CLEARFACTS:
      case IntegrationType.OUTLOOK:
      case IntegrationType.CONVERTKIT:
      case IntegrationType.PONTO:
        return (
          <ConnectContainer>
            <ConnectTitle>{marketplace_integration.title}</ConnectTitle>
            <ConnectDescription>{marketplace_integration.description}</ConnectDescription>
            <ConnectContainer>
              <ConnectOauthContainer>
                <ConnectOauthLogo isBrand={true}>
                  <img src={isDark ? Images.INTEGRATION_HEXAGON_DARK : Images.INTEGRATION_HEXAGON_LIGHT} />
                  <img src={Images.INTEGRATION_BIZZEY_ICON} style={{ maxWidth: 33 }} />
                </ConnectOauthLogo>

                <ConnectOauthSync>
                  <Icon icon='magic' />
                </ConnectOauthSync>

                <ConnectOauthLogo>
                  <img src={isDark ? Images.INTEGRATION_HEXAGON_DARK : Images.INTEGRATION_HEXAGON_LIGHT} />
                  <img src={marketplace_integration.icon} />
                </ConnectOauthLogo>
              </ConnectOauthContainer>
            </ConnectContainer>
          </ConnectContainer>
        )
      case IntegrationType.PONTO:
    }
  }

  renderDisconnectButton() {
    const type = this.getType()
    const { t } = this.props
    const { integration } = this.state

    if (integration && integration.id) {
      return (
        <div className='form-item'>
          <ActionButton onClick={this.onDisconnectClick} style={{ marginTop: 50 }}>
            <Icon icon='unlink' />
            {t('UnpaidForm::Disconnect')}
          </ActionButton>
        </div>
      )
    }

    return null
  }

  renderFooterActions() {
    const { t } = this.props
    const { integration, marketplace_integration, errors } = this.state
    const type = this.getType()
    const themeColor = ColorHelper.getColorTheme(marketplace_integration.theme.cover)

    const actions = [
      <FooterBackButton key='footer-back' onClick={this.onBackClick}>{t('Integration::Back')}</FooterBackButton>,
    ]

    switch (type) {
      case IntegrationType.MOLLIE:
      case IntegrationType.PONTO:
      case IntegrationType.YUKI:
      case IntegrationType.CLEARFACTS:
      case IntegrationType.MAILCHIMP:
      case IntegrationType.MAILSYNC:
      case IntegrationType.UNPAID:
      case IntegrationType.OCTOPUS:
      case IntegrationType.CONVERTKIT:
        if (integration.id) actions.push(
          <div key='submit' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={this.onErrorsDismiss}
            />
            <ActionButton
              onClick={() => this.onFormSubmit()}>
              {t('Integration::Save')}
            </ActionButton>
          </div>)
        break
      case IntegrationType.STRIPE:
      case IntegrationType.MAILCHIMP:
    }

    return actions
  }

  render() {
    const { didInitialLoad, errors, showForm } = this.state
    const { t } = this.props

    if (!didInitialLoad) {
      const color = '#f4f6f8'
      const themeColor = ColorHelper.getColorTheme(color)
      const isDark = themeColor === 'dark'

      return (
        <ThemeProvider theme={{ dark: isDark }}>
          <Container backgroundColor={color}>
            <Content>
              <PageLoader />
            </Content>
          </Container>
        </ThemeProvider>
      )
    }

    const { marketplace_integration, integration } = this.state

    const themeColor = ColorHelper.getColorTheme(marketplace_integration.theme.cover)
    const isDark = themeColor === 'dark'
    const connected = Boolean(integration.id)

    return (
      <ThemeProvider theme={{
        dark: isDark,
        cover: marketplace_integration.theme.cover,
        textColor: marketplace_integration.theme.text,
        buttonText: marketplace_integration.theme.button_text,
        buttonBackground: marketplace_integration.theme.button_background,
      }}>
        <Helmet>
          <title>{`Bizzey | ${this.getTitle()}`}</title>
        </Helmet>
        <Container backgroundColor={marketplace_integration.theme.cover}>
          <CloseCross onClick={this.onBackClick}>
            <Icon icon='close' />
          </CloseCross>
          <ScrollToTopOnMount />

          <Content>
            <Logo src={marketplace_integration.image} />

            {!connected && <ConnectContainer>
              <ConnectTitle>{marketplace_integration.title}</ConnectTitle>
              <ConnectDescription>{marketplace_integration.description}</ConnectDescription>

              <ConnectOauthContainer>
                <ConnectOauthLogo isBrand={true}>
                  <img src={isDark ? Images.INTEGRATION_HEXAGON_DARK : Images.INTEGRATION_HEXAGON_LIGHT} />
                  <img src={Images.INTEGRATION_BIZZEY_ICON} style={{ maxWidth: 33 }} />
                </ConnectOauthLogo>

                <ConnectOauthSync>
                  <Icon icon='exchange-alt' />
                </ConnectOauthSync>

                <ConnectOauthLogo>
                  <img src={isDark ? Images.INTEGRATION_HEXAGON_DARK : Images.INTEGRATION_HEXAGON_LIGHT} />
                  <img src={marketplace_integration.icon} />
                </ConnectOauthLogo>
              </ConnectOauthContainer>

              <ActionButton onClick={this.onConnectClick}>
                {t('Integration::Connect')}
              </ActionButton>
            </ConnectContainer>}

            {(connected || showForm) && <form onSubmit={(e) => {
              e.preventDefault()
              this.onFormSubmit()
            }}>
              {this.renderTypeContent()}
              {this.renderDisconnectButton()}
              <input type='submit' style={{ display: 'none' }} />
            </form>}
          </Content>

          <Footer>
            <FooterActions>
              {this.renderFooterActions()}
            </FooterActions>
          </Footer>
        </Container>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IntegrationPage))