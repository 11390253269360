import styled, { css } from "styled-components";
import { Style } from "../../styles";

const FullPageContent = styled.div<{ background?: string }>`
	padding-top: 54px;
	background: white;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	${props => props.background && css`
		background: ${props.background};
	`}

	.has-banner & {
		padding-top: 94px;
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		padding-top: 103px !important;
	}
`

export default FullPageContent