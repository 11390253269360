import styled from 'styled-components'
import { Style } from '../../styles'

const AddActivityButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen  and (max-width: ${Style.breakpoints.SMALL}) {
    svg, i {
      margin-right: 0 !important;
    }

    span {
      display: none;
    }
  }
`

export default AddActivityButton