import { Moment } from 'moment'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ButtonPanel from '../components/Button/ButtonPanel'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PowerSelect from '../components/Form/PowerSelect'
import Icon from '../components/Icons/Icon'
import PageContent from '../components/Page/PageContent'
import ReportProgressbar from '../components/ProgressBars/ReportProgressBar'
import ReportSummary from '../components/Reporting/ReportSummary'
import ReportSummaryItem from '../components/Reporting/ReportSummaryItem'
import ReportSummaryPercent from '../components/Reporting/ReportSummaryPercent'
import ReportSummaryPercentIndicator from '../components/Reporting/ReportSummaryPercentIndicator'
import ReportSummaryPercentIndicatorInfo from '../components/Reporting/ReportSummaryPercentIndicatorInfo'
import ReportSummaryPercentIndicatorInfoColor from '../components/Reporting/ReportSummaryPercentIndicatorInfoColor'
import ReportSummaryPercentIndicatorInfoItem from '../components/Reporting/ReportSummaryPercentIndicatorInfoItem'
import ReportSummaryPercentIndicatorInfoItemWrapper from '../components/Reporting/ReportSummaryPercentIndicatorInfoItemWrapper'
import ReportSummaryPercentIndicatorInfoLabel from '../components/Reporting/ReportSummaryPercentIndicatorInfoLabel'
import ReportSummaryPercentIndicatorInfoValue from '../components/Reporting/ReportSummaryPercentIndicatorInfoValue'
import ReportSummaryTitle from '../components/Reporting/ReportSummaryTitle'
import ReportSummaryValue from '../components/Reporting/ReportSummaryValue'
import ResourceTable, { ResourceTableHeader } from '../components/Resource/ResourceTable'
import ResourceTableRow from '../components/Resource/ResourceTableRow'
import ResourceTableRowData from '../components/Resource/ResourceTableRowData'
import CircularProgressBar from '../components/ProgressBars/CircularProgressBar'
import { TimeEntriesController } from '../controllers'
import { AppState } from '../store'
import { Style } from '../styles'
import moment from '../utilities/Moment'
import NumberFormatter from '../utilities/NumberFormatter'
import { saveAs } from 'file-saver'
import Popover from '../components/Popover/Popover'
import ActionList from '../components/ActionList/ActionList'
import TabItem from '../components/Navigation/TabItem'
import ReportNavigation from '../components/Reporting/ReportNavigation'
import ReportTabNavigation from '../components/Reporting/ReportTabNavigation'
import RouteHelper from '../helpers/RouteHelper'
import ERoute from '../ERoute'
import ResourceTableRowDataLink from '../components/Resource/ResourceTableRowDataLink'
import { useDebouncedCallback } from 'use-debounce'
import { ContactTimeTrackingReportDataRow, ProjectTeamTimeTrackingReportDataRow, ProjectTimeTrackingReportData, ProjectTimeTrackingReportDataRow, ResourceListFilterType, Settings, SpreadsheetExportType, TeamTimeTrackingReportDataRow, TimeTrackingReportData, TimeTrackingReportDataRow, TimeTrackingViewReportMode, TimeTrackingViewReportType, WorkTypeTimeTrackingReportDataRow } from '../types'
import PageLoader from '../components/Page/PageLoader'
import ResourceFilterComponent from '../components/Resource/ResourceFilter'

const ReportNavigatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-right: ${Style.spacing.x2};
`

const ReportNavigator = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${Style.spacing.x1};
`

const ReportNavigatorButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 16px;
	height: 36px;
	min-width: 36px;
	background: white;
	border: 1px solid ${Style.color.border};
	cursor: pointer;
	flex: 1;
	font-weight: 500;

	&:hover {
		background: #f6f6f7;
	}

	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;

		&:not(:only-child) {
			border-right: 0;
		}
	}

	&:last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`

const ReportTitle = styled.div`
  font-size: 26px;
  font-weight: 500;
`

const PercentSlice = styled.span`
  color: gray;
  display: inline-block;
  width: 40px;
`

const ReportRowHours = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > strong {
    margin-right: ${Style.spacing.x1};
    min-width: 60px;
  }
`

interface IState {
  didInitialLoad: boolean,
  start: string
  end: string
  viewMode: TimeTrackingViewReportMode,
  type: TimeTrackingViewReportType
  data?: TimeTrackingReportData
  filters: any,
}

type IProps = RouteComponentProps<{ id: string }>

const TimeTrackingReport = (props: IProps) => {
  const { t } = useTranslation()
  const setting = useSelector<AppState, Settings>(state => state.authentication.currentUser.workspace.setting)

  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    start: moment().startOf('week').toISOString(),
    end: moment().endOf('week').toISOString(),
    viewMode: TimeTrackingViewReportMode.WEEK,
    type: TimeTrackingViewReportType.CLIENT,
    data: null,
    filters: {}
  })
  const [exportPopoverActive, setExportPopoverActive] = React.useState(false)
  const debouncedFetchReport = useDebouncedCallback(
    () => fetchReport(), 500
  );

  const {
    didInitialLoad,
    start,
    end,
    viewMode,
    type,
    data,
    filters
  } = state
  const startMoment = moment(start)
  const endMoment = moment(end)

  const viewModeOptions = [
    { label: t(`TimeTrackingViewReportMode::${TimeTrackingViewReportMode.WEEK}`), value: TimeTrackingViewReportMode.WEEK },
    { label: t(`TimeTrackingViewReportMode::${TimeTrackingViewReportMode.MONTH}`), value: TimeTrackingViewReportMode.MONTH },
    { label: t(`TimeTrackingViewReportMode::${TimeTrackingViewReportMode.QUARTER}`), value: TimeTrackingViewReportMode.QUARTER },
    { label: t(`TimeTrackingViewReportMode::${TimeTrackingViewReportMode.YEAR}`), value: TimeTrackingViewReportMode.YEAR },
  ]
  const selectedViewModeOption = viewModeOptions.find(option => option.value === viewMode)

  React.useEffect(() => {
    debouncedFetchReport()
  }, [viewMode, type, start, end, filters])

  const fetchReport = async () => {
    try {
      setState({ ...state, didInitialLoad: false })

      const response = await TimeEntriesController.getTimeEntriesReport({
        ...filters,
        start: startMoment.toISOString(),
        end: endMoment.toISOString(),
        type: type,
      })

      setState({
        ...state,
        didInitialLoad: true,
        data: response
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onNavigateBack = () => {
    let newStartMoment: Moment = null
    let newEndMoment: Moment = null

    switch (viewMode) {
      case TimeTrackingViewReportMode.WEEK:
        newStartMoment = moment(startMoment).subtract(1, 'week').startOf('week')
        newEndMoment = moment(newStartMoment).endOf('week')
        break
      case TimeTrackingViewReportMode.MONTH:
        newStartMoment = moment(startMoment).subtract(1, 'month').startOf('month')
        newEndMoment = moment(newStartMoment).endOf('month')
        break
      case TimeTrackingViewReportMode.QUARTER:
        newStartMoment = moment(startMoment).subtract(1, 'quarter').startOf('quarter')
        newEndMoment = moment(newStartMoment).endOf('quarter')
        break
      case TimeTrackingViewReportMode.YEAR:
        newStartMoment = moment(startMoment).subtract(1, 'year').startOf('year')
        newEndMoment = moment(newStartMoment).endOf('year')
        break
      default:
        throw Error('Unknown view mode')
    }

    setState({
      ...state,
      start: newStartMoment.toISOString(),
      end: newEndMoment.toISOString()
    })
  }

  const onNavigateForward = () => {
    let newStartMoment = null
    let newEndMoment = null

    switch (viewMode) {
      case TimeTrackingViewReportMode.WEEK:
        newStartMoment = moment(startMoment).add(1, 'week').startOf('week')
        newEndMoment = moment(newStartMoment).endOf('week')
        break
      case TimeTrackingViewReportMode.MONTH:
        newStartMoment = moment(startMoment).add(1, 'month').startOf('month')
        newEndMoment = moment(newStartMoment).endOf('month')
        break
      case TimeTrackingViewReportMode.QUARTER:
        newStartMoment = moment(startMoment).add(1, 'quarter').startOf('quarter')
        newEndMoment = moment(newStartMoment).endOf('quarter')
        break
      case TimeTrackingViewReportMode.YEAR:
        newStartMoment = moment(startMoment).add(1, 'year').startOf('year')
        newEndMoment = moment(newStartMoment).endOf('year')
        break
      default:
        throw Error('[onNavigateForward] Unknown view mode')
    }

    setState({
      ...state,
      start: newStartMoment.toISOString(),
      end: newEndMoment.toISOString()
    })
  }

  const onReportTypeChange = (type: TimeTrackingViewReportType) => {
    setState({ ...state, type: type, filters: {} })
  }

  const onExportPopoverClick = () => {
    setExportPopoverActive(!exportPopoverActive)
  }

  const onExportPopoverClose = () => {
    setExportPopoverActive(false)
  }

  const onExportClick = async (spreadsheetType: SpreadsheetExportType) => {
    try {
      // Get blob result
      const result: any = await TimeEntriesController.reportExport({
        ...filters,
        start: startMoment.toISOString(),
        end: endMoment.toISOString(),
        type: type,
        spreadsheet_type: spreadsheetType,
      })

      // Blog destructering
      const { blob, filename } = result

      // Use save as library
      saveAs(blob, filename)
    } catch (ex) {
      console.error(ex)
    }
  }

  const isCurrentPeriod = () => {
    switch (viewMode) {
      case TimeTrackingViewReportMode.WEEK:
        return moment().isSame(endMoment, 'week')
      case TimeTrackingViewReportMode.MONTH:
        return moment().isSame(endMoment, 'month')
      case TimeTrackingViewReportMode.QUARTER:
        return moment().isSame(endMoment, 'quarter')
      case TimeTrackingViewReportMode.YEAR:
        return moment().isSame(endMoment, 'year')
      default:
        throw Error('[isCurrentPeriod] Unknown view mode')
    }
  }

  const viewModeDescription = () => {
    switch (viewMode) {
      case TimeTrackingViewReportMode.WEEK:
        return `<strong>${isCurrentPeriod ? t('TimeTrackingReport::This week') : t('TimeTrackingReport::Week')}</strong>: ${startMoment.format('DD')} - ${endMoment.format('DD MMM YYYY')}`
      case TimeTrackingViewReportMode.MONTH:
        return `<strong>${isCurrentPeriod ? t('TimeTrackingReport::This month') : t('TimeTrackingReport::Month')}</strong>: ${startMoment.format('DD')} - ${endMoment.format('DD MMM YYYY')}`
      case TimeTrackingViewReportMode.QUARTER:
        return `<strong>${isCurrentPeriod ? t('TimeTrackingReport::This quarter') : t('TimeTrackingReport::Quarter')}</strong>: ${startMoment.format('DD MMM')} - ${endMoment.format('DD MMM YYYY')}`
      case TimeTrackingViewReportMode.YEAR:
        return `<strong>${isCurrentPeriod ? t('TimeTrackingReport::This year') : t('TimeTrackingReport::Year')}</strong>: ${endMoment.format('YYYY')}`
      default:
        throw Error('[viewModeDescription] Unknown view mode')
    }
  }

  const onViewModeChange = (option: any) => {
    const newViewMode = option.value

    let newStartMoment = null
    let newEndMoment = null

    switch (newViewMode) {
      case TimeTrackingViewReportMode.WEEK:
        newStartMoment = moment(startMoment).startOf('week')
        newEndMoment = moment(newStartMoment).endOf('week')
        break
      case TimeTrackingViewReportMode.MONTH:
        newStartMoment = moment(startMoment).startOf('month')
        newEndMoment = moment(newStartMoment).endOf('month')
        break
      case TimeTrackingViewReportMode.QUARTER:
        newStartMoment = moment(startMoment).startOf('quarter')
        newEndMoment = moment(newStartMoment).endOf('quarter')
        break
      case TimeTrackingViewReportMode.YEAR:
        newStartMoment = moment(startMoment).startOf('year')
        newEndMoment = moment(newStartMoment).endOf('year')
        break
      default:
        throw Error('[onViewModeChange] Unknown view mode')
    }

    setState({
      ...state,
      start: newStartMoment.toISOString(),
      end: newEndMoment.toISOString(),
      viewMode: newViewMode
    })
  }

  const onContactClick = (contactId: string) => {
    if (contactId) props.history.push(RouteHelper.process(ERoute.PATH_CONTACT, { id: contactId }))
  }

  const onProjectClick = (projectId: string) => {
    if (projectId) props.history.push(RouteHelper.process(ERoute.PATH_PROJECT, { id: projectId }))
  }

  const onFiltersChange = (filters: any) => {
    setState({ ...state, filters: filters })
  }

  let billableHours = 0
  let unbillableHours = 0
  let hours = 0
  let costAmount = 0
  let billableAmount = 0
  let uninvoicedAmount = 0
  let netAmount = 0
  let totalBudget = null
  let entries: TimeTrackingReportDataRow[] = []

  if (didInitialLoad && data) {
    billableHours = data.billable_hours
    unbillableHours = data.unbillable_hours
    hours = data.hours
    billableAmount = data.billable_amount
    uninvoicedAmount = data.uninvoiced_amount
    costAmount = Number(data?.billable_cost_amount || 0) + Number(data?.unbillable_cost_amount || 0)
    netAmount = data.net_amount
    entries = data.entries

    if (type === TimeTrackingViewReportType.PROJECT) {
      totalBudget = (data as ProjectTimeTrackingReportData).budget
    }
  }

  const getTypeHeaders = (): ResourceTableHeader[] => {
    switch (type) {
      case TimeTrackingViewReportType.CLIENT:
        return [
          { title: t('TimeTrackingReport::Name') },
          { title: t('TimeTrackingReport::Hours') },
          { title: t('TimeTrackingReport::Unbillable hours') },
          { title: t('TimeTrackingReport::Billable hours') },
          { title: t('TimeTrackingReport::Billable amount'), align: 'right' },
          { title: t('TimeTrackingReport::Costs'), align: 'right' },
          { title: t('TimeTrackingReport::Net amount'), align: 'right' },
        ]
      case TimeTrackingViewReportType.PROJECT:
        return [
          { title: t('TimeTrackingReport::Name') },
          { title: t('TimeTrackingReport::Contact') },
          { title: t('TimeTrackingReport::Hours') },
          { title: t('TimeTrackingReport::Unbillable hours') },
          { title: t('TimeTrackingReport::Billable hours') },
          { title: t('TimeTrackingReport::Billable amount'), align: 'right' },
          { title: t('TimeTrackingReport::Costs'), align: 'right' },
          { title: t('TimeTrackingReport::Net amount'), align: 'right' },
          { title: t('TimeTrackingReport::Budget'), align: 'right' },
        ]
      case TimeTrackingViewReportType.WORK_TYPE:
        return [
          { title: t('TimeTrackingReport::Name') },
          { title: t('TimeTrackingReport::Hours') },
          { title: t('TimeTrackingReport::Unbillable hours') },
          { title: t('TimeTrackingReport::Billable hours') },
          { title: t('TimeTrackingReport::Billable amount'), align: 'right' },
          { title: t('TimeTrackingReport::Costs'), align: 'right' },
          { title: t('TimeTrackingReport::Net amount'), align: 'right' },
        ]
      case TimeTrackingViewReportType.PROJECT_TEAM:
        return [
          { title: t('TimeTrackingReport::Name') },
          { title: t('TimeTrackingReport::Employee') },
          { title: t('TimeTrackingReport::Hours') },
          { title: t('TimeTrackingReport::Unbillable hours') },
          { title: t('TimeTrackingReport::Billable hours') },
          { title: t('TimeTrackingReport::Billable amount'), align: 'right' },
          { title: t('TimeTrackingReport::Costs'), align: 'right' },
          { title: t('TimeTrackingReport::Net amount'), align: 'right' },
        ]
      case TimeTrackingViewReportType.TEAM:
        return [
          { title: t('TimeTrackingReport::Name') },
          { title: t('TimeTrackingReport::Hours') },
          { title: t('TimeTrackingReport::Unbillable hours') },
          { title: t('TimeTrackingReport::Billable hours') },
          { title: t('TimeTrackingReport::Billable amount'), align: 'right' },
          { title: t('TimeTrackingReport::Costs'), align: 'right' },
          { title: t('TimeTrackingReport::Net amount'), align: 'right' },
        ]
    }
  }

  const renderContactRow = (dataRow: ContactTimeTrackingReportDataRow, index: number) => {
    const lastRow = entries.length - 1 === index
    const contactId = dataRow.contact_id

    return (
      <>
        <ResourceTableRow key={index}>
          <ResourceTableRowData >
            {dataRow.contact_id && <ResourceTableRowDataLink onClick={() => onContactClick(contactId)}>
              {dataRow.name}
            </ResourceTableRowDataLink>}
            {!dataRow.contact_id && t('TimeTrackingReport::No contact')}
          </ResourceTableRowData>
          <ResourceTableRowData>
            <ReportRowHours>
              <strong>{NumberFormatter.formatNumber(setting.number_format, dataRow.hours)}</strong>
              <div style={{ minWidth: 100 }}>
                <ReportProgressbar
                  items={[
                    { value: Number(dataRow.billable_hours), color: Style.color.budgetColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::billable')}` },
                    { value: Number(dataRow.unbillable_hours), color: Style.color.budgetRemainingColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::unbillable')}` },
                  ]}
                />
              </div>
            </ReportRowHours>
          </ResourceTableRowData>

          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.unbillable_hours)} <PercentSlice>({`${(dataRow.unbillable_hours / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.billable_hours)} <PercentSlice>({`${(dataRow.billable_hours / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.billable_amount)}
            </strong>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.cost_amount)}
            </strong>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.net_amount)}
            </strong>
          </ResourceTableRowData>
        </ResourceTableRow>
        {lastRow &&
          <ResourceTableRow key='total'>
            <ResourceTableRowData  >
              <strong>{t('TimeTrackingReport::Total')}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, hours)}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, unbillableHours)} </strong>
              <PercentSlice>({`${(unbillableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, billableHours)} </strong>
              <PercentSlice>({`${(billableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, billableAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, costAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, netAmount)}
              </strong>
            </ResourceTableRowData>
          </ResourceTableRow>}
      </>
    )
  }

  const renderProjectRow = (dataRow: ProjectTimeTrackingReportDataRow, index: number) => {
    const lastRow = entries.length - 1 === index
    const projectId = dataRow.project_id
    const contactId = dataRow.contact_id
    const budget = dataRow.budget

    return (
      <>
        <ResourceTableRow key={index}>
          <ResourceTableRowData onClick={() => onProjectClick(projectId)}  >
            {dataRow.project_id && <ResourceTableRowDataLink onClick={() => onProjectClick(dataRow.project_id)}>
              {dataRow.name}
            </ResourceTableRowDataLink>}
            {!dataRow.project_id && t('TimeTrackingReport::No project')}
          </ResourceTableRowData>
          <ResourceTableRowData onClick={() => onContactClick(contactId)}>
            {dataRow.contact_id && <ResourceTableRowDataLink onClick={() => onContactClick(contactId)}>
              {dataRow.contact_name}
            </ResourceTableRowDataLink>}
            {!dataRow.contact_id && t('TimeTrackingReport::No contact')}
          </ResourceTableRowData>
          <ResourceTableRowData>
            <ReportRowHours>
              <strong>{NumberFormatter.formatNumber(setting.number_format, dataRow.hours)}</strong>
              <div style={{ minWidth: 100 }}>
                <ReportProgressbar
                  items={[
                    { value: Number(dataRow.billable_hours), color: Style.color.budgetColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::billable')}` },
                    { value: Number(dataRow.unbillable_hours), color: Style.color.budgetRemainingColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::unbillable')}` },
                  ]}
                />
              </div>
            </ReportRowHours>
          </ResourceTableRowData>

          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.unbillable_hours)} <PercentSlice>({`${(Number(dataRow.unbillable_hours) / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.billable_hours)} <PercentSlice>({`${(Number(dataRow.billable_hours) / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.billable_amount)}
            </strong>
          </ResourceTableRowData>
          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.cost_amount)}
            </strong>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.net_amount)}
            </strong>
          </ResourceTableRowData>
          <ResourceTableRowData textAlign='right'>
            <strong>
              {budget ? NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, budget) : t('TimeTrackingReport::N/A')}
            </strong>
          </ResourceTableRowData>
        </ResourceTableRow>
        {lastRow &&
          <ResourceTableRow key='total'>
            <ResourceTableRowData  >
              <strong>{t('TimeTrackingReport::Total')}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData />
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, hours)}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, unbillableHours)} </strong>
              <PercentSlice>({`${(unbillableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, billableHours)} </strong>
              <PercentSlice>({`${(billableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, billableAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, costAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, netAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, totalBudget)}
              </strong>
            </ResourceTableRowData>
          </ResourceTableRow>}
      </>
    )
  }

  const renderWorkType = (dataRow: WorkTypeTimeTrackingReportDataRow, index: number) => {
    const lastRow = entries.length - 1 === index
    const workType = dataRow.work_type_id

    return (
      <>
        <ResourceTableRow key={index}>
          <ResourceTableRowData >
            {workType && <ResourceTableRowDataLink style={{ cursor: 'inherit' }}>
              {dataRow.name}
            </ResourceTableRowDataLink>}
            {!workType && t('TimeTrackingReport::No work type')}
          </ResourceTableRowData>
          <ResourceTableRowData>
            <ReportRowHours>
              <strong>{NumberFormatter.formatNumber(setting.number_format, dataRow.hours)}</strong>
              <div style={{ minWidth: 100 }}>
                <ReportProgressbar
                  items={[
                    { value: Number(dataRow.billable_hours), color: Style.color.budgetColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::billable')}` },
                    { value: Number(dataRow.unbillable_hours), color: Style.color.budgetRemainingColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::unbillable')}` },
                  ]}
                />
              </div>
            </ReportRowHours>
          </ResourceTableRowData>

          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.unbillable_hours)} <PercentSlice>({`${(dataRow.unbillable_hours / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.billable_hours)} <PercentSlice>({`${(dataRow.billable_hours / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.billable_amount)}
            </strong>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.cost_amount)}
            </strong>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.net_amount)}
            </strong>
          </ResourceTableRowData>
        </ResourceTableRow>
        {lastRow &&
          <ResourceTableRow key='total'>
            <ResourceTableRowData  >
              <strong>{t('TimeTrackingReport::Total')}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, hours)}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, unbillableHours)} </strong>
              <PercentSlice>({`${(unbillableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, billableHours)} </strong>
              <PercentSlice>({`${(billableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, billableAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, costAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, netAmount)}
              </strong>
            </ResourceTableRowData>
          </ResourceTableRow>}
      </>
    )
  }

  const renderProjectTeamRow = (dataRow: any, index: number) => {
    const {
      project_id: projectId,
      name: projectName,
      billable_hours,
      unbillable_hours,
      hours,
      cost_amount,
      billable_amount,
      net_amount
    }: {
      project_id: string,
      name: string
      billable_hours: string,
      unbillable_hours: string,
      hours: string,
      cost_amount: string
      billable_amount: string
      net_amount: string
    } = dataRow[0]
    const entries: ProjectTeamTimeTrackingReportDataRow[] = dataRow[1]

    return (
      <>
        <ResourceTableRow key={index}>
          <ResourceTableRowData onClick={() => onProjectClick(projectId)}  >
            {projectId && <ResourceTableRowDataLink onClick={() => onProjectClick(projectId)}>
              {projectName}
            </ResourceTableRowDataLink>}
            {!projectId && t('TimeTrackingReport::No project')}
          </ResourceTableRowData>
          <ResourceTableRowData />
          <ResourceTableRowData />
          <ResourceTableRowData />
          <ResourceTableRowData />
          <ResourceTableRowData />
          <ResourceTableRowData />
          <ResourceTableRowData />
        </ResourceTableRow>

        {entries.map((entry, index) => {
          return (
            <ResourceTableRow key={`${projectId}-${index}-${entry.user_id}`}>
              <ResourceTableRowData  >
                <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" >
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group" stroke="#D8D8D8">
                      <rect id="Rectangle" fill="#D8D8D8" x="0.5" y="0.5" width="1" height="3"></rect>
                      <rect id="Rectangle" fill="#D8D8D8" x="0.5" y="7.5" width="1" height="4"></rect>
                      <rect id="Rectangle" fill="#D8D8D8" x="4.5" y="10.5" width="3" height="1"></rect>
                    </g>
                  </g>
                </svg>
              </ResourceTableRowData>
              <ResourceTableRowData>
                {entry.user}
              </ResourceTableRowData>

              <ResourceTableRowData>
                {NumberFormatter.formatNumber(setting.number_format, entry.hours)}
              </ResourceTableRowData>
              <ResourceTableRowData>
                {NumberFormatter.formatNumber(setting.number_format, entry.unbillable_hours)}
              </ResourceTableRowData>
              <ResourceTableRowData>
                {NumberFormatter.formatNumber(setting.number_format, entry.billable_hours)}
              </ResourceTableRowData>
              <ResourceTableRowData textAlign='right'>
                <strong>
                  {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, entry.billable_amount)}
                </strong>
              </ResourceTableRowData>
              <ResourceTableRowData textAlign='right'>
                <strong>
                  {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, entry.cost_amount)}
                </strong>
              </ResourceTableRowData>
              <ResourceTableRowData textAlign='right'>
                <strong>
                  {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, entry.net_amount)}
                </strong>
              </ResourceTableRowData>
            </ResourceTableRow>
          )
        })}

        <ResourceTableRow key={`${projectId}-total`}>
          <ResourceTableRowData  >
            <strong>{t('TimeTrackingReport::Total')}</strong>
          </ResourceTableRowData>
          <ResourceTableRowData />
          <ResourceTableRowData>
            <strong>{NumberFormatter.formatNumber(setting.number_format, hours)}</strong>
          </ResourceTableRowData>
          <ResourceTableRowData>
            <strong>{NumberFormatter.formatNumber(setting.number_format, unbillable_hours)} </strong>
          </ResourceTableRowData>
          <ResourceTableRowData>
            <strong>{NumberFormatter.formatNumber(setting.number_format, billable_hours)} </strong>
          </ResourceTableRowData>
          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, billable_amount)}
            </strong>
          </ResourceTableRowData>
          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, cost_amount)}
            </strong>
          </ResourceTableRowData>
          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, net_amount)}
            </strong>
          </ResourceTableRowData>
        </ResourceTableRow>
      </>
    )
  }

  const renderTeamRow = (dataRow: TeamTimeTrackingReportDataRow, index: number) => {
    const lastRow = entries.length - 1 === index

    return (
      <>
        <ResourceTableRow key={index}>
          <ResourceTableRowData  >
            {dataRow.name}
          </ResourceTableRowData>
          <ResourceTableRowData>
            <ReportRowHours>
              <strong>{NumberFormatter.formatNumber(setting.number_format, dataRow.hours)}</strong>
              <div style={{ minWidth: 100 }}>
                <ReportProgressbar
                  items={[
                    { value: Number(dataRow.billable_hours), color: Style.color.budgetColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::billable')}` },
                    { value: Number(dataRow.unbillable_hours), color: Style.color.budgetRemainingColor, tooltipLabel: (percentage) => `${percentage.toFixed(2)}% ${t('TimeTrackingReport::unbillable')}` },
                  ]}
                />
              </div>
            </ReportRowHours>
          </ResourceTableRowData>
          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.unbillable_hours)} <PercentSlice>({`${(dataRow.unbillable_hours / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData>
            {NumberFormatter.formatNumber(setting.number_format, dataRow.billable_hours)} <PercentSlice>({`${(dataRow.billable_hours / dataRow.hours * 100).toFixed(2)}%`})</PercentSlice>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.billable_amount)}
            </strong>
          </ResourceTableRowData>
          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.cost_amount)}
            </strong>
          </ResourceTableRowData>

          <ResourceTableRowData textAlign='right'>
            <strong>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, dataRow.net_amount)}
            </strong>
          </ResourceTableRowData>
        </ResourceTableRow>
        {lastRow &&
          <ResourceTableRow key='total'>
            <ResourceTableRowData  >
              <strong>{t('TimeTrackingReport::Total')}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, hours)}</strong>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, unbillableHours)} </strong>
              <PercentSlice>({`${(unbillableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData>
              <strong>{NumberFormatter.formatNumber(setting.number_format, billableHours)} </strong>
              <PercentSlice>({`${(billableHours / hours * 100).toFixed(2)}%`})</PercentSlice>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, billableAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, costAmount)}
              </strong>
            </ResourceTableRowData>
            <ResourceTableRowData textAlign='right'>
              <strong>
                {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, netAmount)}
              </strong>
            </ResourceTableRowData>
          </ResourceTableRow>}
      </>
    )
  }

  const renderActionLeft = () => {
    switch (type) {
      case TimeTrackingViewReportType.PROJECT_TEAM:
      case TimeTrackingViewReportType.TEAM:
        return [
          <ResourceFilterComponent
            filters={[
              { name: 'user_id', label: t('TimeTrackingReport::Employee'), type: ResourceListFilterType.MULTI_RESOURCE, resourceType: 'user', isValidNewOption: () => false },
            ]}
            onSubmit={onFiltersChange}
          />
        ]
      default: []
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('Track::{{__appName}} | Time tracking report')}</title>
      </Helmet>

      <ScrollToTopOnMount />

      <PageContent className='is-track'>
        <ReportNavigation>
          <ReportNavigatorContainer>
            <ReportNavigator>
              <ReportNavigatorButton onClick={onNavigateBack}>
                <Icon icon='chevron-left' />
              </ReportNavigatorButton>
              <ReportNavigatorButton onClick={onNavigateForward}>
                <Icon icon='chevron-right' />
              </ReportNavigatorButton>
            </ReportNavigator>

            <ReportTitle
              dangerouslySetInnerHTML={{ __html: viewModeDescription() }}
            />
          </ReportNavigatorContainer>

          <div style={{ minWidth: 160, zIndex: 4 }}>
            <PowerSelect
              options={viewModeOptions}
              value={selectedViewModeOption}
              onChange={onViewModeChange}
            />
          </div>
        </ReportNavigation>

        <ReportSummary>
          <ReportSummaryItem>
            <ReportSummaryTitle>
              {t('TimeTrackingReport::Total hours')}
            </ReportSummaryTitle>
            <ReportSummaryValue>
              {NumberFormatter.formatNumber(setting.number_format, hours)}
            </ReportSummaryValue>
          </ReportSummaryItem>

          <ReportSummaryItem>
            <ReportSummaryPercent>
              <ReportSummaryPercentIndicator>
                <div style={{ width: 80, height: 80 }}>
                  <CircularProgressBar
                    value={Number(hours > 0 ? (billableHours / hours * 100).toFixed(0) : 0)}
                    text={`${hours > 0 ? (billableHours / hours * 100).toFixed(0) : 0}%`}
                    strokeWidth={10}
                    styles={{
                      path: {
                        stroke: Style.color.budgetColor,
                      },
                      trail: {
                        stroke: Style.color.budgetRemainingColor,
                      },
                      text: {
                        // Text color
                        fill: 'black',
                        // Text size
                        fontSize: '28px',
                        fontWeight: 600,
                      },
                    }}
                    counterClockwise={true}
                  />
                </div>
              </ReportSummaryPercentIndicator>
              <ReportSummaryPercentIndicatorInfo>
                <ReportSummaryPercentIndicatorInfoItem>
                  <ReportSummaryPercentIndicatorInfoItemWrapper>
                    <ReportSummaryPercentIndicatorInfoColor color='#0379c9' />
                    <ReportSummaryPercentIndicatorInfoLabel>
                      {t('TimeTrackingReport::Billable')}
                    </ReportSummaryPercentIndicatorInfoLabel>
                  </ReportSummaryPercentIndicatorInfoItemWrapper>
                  <ReportSummaryPercentIndicatorInfoValue>
                    {NumberFormatter.formatNumber(setting.number_format, billableHours)}
                  </ReportSummaryPercentIndicatorInfoValue>
                </ReportSummaryPercentIndicatorInfoItem>
                <ReportSummaryPercentIndicatorInfoItem>
                  <ReportSummaryPercentIndicatorInfoItemWrapper>
                    <ReportSummaryPercentIndicatorInfoColor color={Style.color.budgetRemainingColor} />
                    <ReportSummaryPercentIndicatorInfoLabel>
                      {t('TimeTrackingReport::Unbillable')}
                    </ReportSummaryPercentIndicatorInfoLabel>
                  </ReportSummaryPercentIndicatorInfoItemWrapper>
                  <ReportSummaryPercentIndicatorInfoValue>
                    {NumberFormatter.formatNumber(setting.number_format, unbillableHours)}
                  </ReportSummaryPercentIndicatorInfoValue>
                </ReportSummaryPercentIndicatorInfoItem>
              </ReportSummaryPercentIndicatorInfo>
            </ReportSummaryPercent>
          </ReportSummaryItem>

          <ReportSummaryItem>
            <ReportSummaryTitle>
              {t('TimeTrackingReport::Billable amount')}
            </ReportSummaryTitle>
            <ReportSummaryValue>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, billableAmount)}
            </ReportSummaryValue>
          </ReportSummaryItem>

          <ReportSummaryItem>
            <ReportSummaryTitle>
              {t('TimeTrackingReport::Uninvoiced amount')}
            </ReportSummaryTitle>
            <ReportSummaryValue>
              {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, uninvoicedAmount)}
            </ReportSummaryValue>
          </ReportSummaryItem>
        </ReportSummary>

        <ReportTabNavigation>
          <TabItem active={type === TimeTrackingViewReportType.CLIENT} onClick={() => onReportTypeChange(TimeTrackingViewReportType.CLIENT)}>
            {t('TimeTrackingReport::Contacts')}
          </TabItem>
          <TabItem active={type === TimeTrackingViewReportType.PROJECT} onClick={() => onReportTypeChange(TimeTrackingViewReportType.PROJECT)}>
            {t('TimeTrackingReport::Projects')}
          </TabItem>
          <TabItem active={type === TimeTrackingViewReportType.WORK_TYPE} onClick={() => onReportTypeChange(TimeTrackingViewReportType.WORK_TYPE)}>
            {t('TimeTrackingReport::Work type')}
          </TabItem>
          <TabItem active={type === TimeTrackingViewReportType.PROJECT_TEAM} onClick={() => onReportTypeChange(TimeTrackingViewReportType.PROJECT_TEAM)}>
            {t('TimeTrackingReport::Projects / Team')}
          </TabItem>
          <TabItem active={type === TimeTrackingViewReportType.TEAM} onClick={() => onReportTypeChange(TimeTrackingViewReportType.TEAM)}>
            {t('TimeTrackingReport::Team')}
          </TabItem>
        </ReportTabNavigation>

        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={entries}
          headers={getTypeHeaders()}
          actionsLeft={renderActionLeft()}
          actionsRight={[
            <Popover
              active={exportPopoverActive}
              activator={
                <div style={{ position: 'relative' }}>
                  <ButtonPanel text={t('TimeTrackingReport::Export')} onClick={onExportPopoverClick} />
                </div>
              }
              onClose={onExportPopoverClose}
              placement='bottom-end'
              strategy='fixed'
            >
              <ActionList
                actions={[
                  { key: SpreadsheetExportType.EXCEL, content: t(`SpreadsheetExportType::${SpreadsheetExportType.EXCEL}`) },
                  { key: SpreadsheetExportType.CSV, content: t(`SpreadsheetExportType::${SpreadsheetExportType.CSV}`) },
                ]}
                onClick={(key: string) => {
                  onExportPopoverClose()
                  onExportClick(key as SpreadsheetExportType)
                }}
                hasIndicator
              />
            </Popover>

          ]}
          renderRow={(dataRow: TimeTrackingReportDataRow, index) => {
            switch (data.type) {
              case TimeTrackingViewReportType.CLIENT:
                return renderContactRow(dataRow as ContactTimeTrackingReportDataRow, index)
              case TimeTrackingViewReportType.PROJECT:
                return renderProjectRow(dataRow as ProjectTimeTrackingReportDataRow, index)
              case TimeTrackingViewReportType.WORK_TYPE:
                return renderWorkType(dataRow as WorkTypeTimeTrackingReportDataRow, index)
              case TimeTrackingViewReportType.PROJECT_TEAM:
                return renderProjectTeamRow(dataRow as ProjectTeamTimeTrackingReportDataRow, index)
              case TimeTrackingViewReportType.TEAM:
                return renderTeamRow(dataRow as TeamTimeTrackingReportDataRow, index)
              default: return null
            }
          }}
          renderEmpty={<CardEmptyInfo
            icon='stopwatch'
            description={t('TimeTrackingReport::No entries found')}
          />}
          isLoading={!didInitialLoad}
          stickyHeader={true}
          maxHeight='65vh'
        />}
      </PageContent>
    </>
  )
}

export default TimeTrackingReport