import * as React from 'react'
import styled from 'styled-components'

const PopoverItem = styled.a`
	display: flex;
	flex-direction: row;
	border-radius: 3px;
	display: block;
	min-height: 38px;
	line-height: 30px;
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
	text-decoration: none;
	white-space: nowrap;
	padding: 4px 12px 4px 12px;
	align-items: center;
	border-radius: 4px;
	cursor: pointer;
	
	&:hover {
		background-color: rgba(9,30,66,.04);
	}
`

export default PopoverItem