import * as React from 'react'
import Utils from './Utils'

export default class Highlighter {
	static highlight(text: string = '', highlightValue: string) {
    if (text === null) {
      text = ''
    }

    const wrap = (t, c?) => {
      return (
        <span key={Utils.uuid()} className={c}>{t}</span>
      );
		}

    const chop = (v, i) => {
      let regexp = new RegExp(i, 'i'),
        mark = v.search(regexp),
        len = i.length;
      if (mark === -1) {
        return [wrap(v)];
      } else {
        return [].concat(
          wrap(v.substr(0, mark)),
          wrap(v.substr(mark, len), 'highlight'),
          chop(v.substr(mark + len), i)
        );
      }
    }

    if (text.length && highlightValue.length) {
      return chop(text, highlightValue);
    }
    else {
      return text;
    }
  }
}