import * as React from 'react'
import Icon, { IconTypes } from '../Icons/Icon'
import ERoute from '../../ERoute'
import Fuse from 'fuse.js'
import SearchController from '../../controllers/SearchController'
import Highlighter from '../../utilities/Highlighter'
import SearchItem from './SearchItem'
import { AppState } from '../../store'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import RouteHelper from '../../helpers/RouteHelper'
import ProjectHelper from '../../helpers/ProjectHelper'
import SearchLoader from './SearchLoader'
import styled from 'styled-components'
import { Style } from '../../styles'
import ShortcutIcon from './Parts/SearchIcon'
import EnterIcon from './Parts/EnterIcon'
import ItemContainer from './Parts/ItemContainer'
import { withTranslation, WithTranslation } from 'react-i18next'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import { CurrentUser, Task, UserWorkspaceSettingRole, UserWorkspaceSettingScope } from '../../types'
import Utils from '../../utilities/Utils'
import { showTaskModal } from '../../store/modals/actions'
import { Dispatch } from 'redux'

const Container = styled.form`
	position: relative;
	display: inline-block;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 100%;
	}
`

const SearchIcon = styled.div`
	position: absolute;
	top: 50%;
	left: 11px;
	font-size: 14px;
	transform: translateY(-50%);
	z-index: 1;
	pointer-events: none;

	i {
		color: #36619a;
	}
`

const SearchInput = styled.input`
	color: #4e566d;
	width: 100% !important;
	height: 38px;
	padding: 4px 28px 5px 32px !important;
	font-size: 15px !important;
	line-height: 18px !important;
	border: 1px solid ${Style.color.border};
	border-radius: ${Style.variables.baseBorderRadius} !important;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 260px;

	&::placeholder {
		color: #656a74;
	}

	&:focus {
		border: 1px solid ${Style.color.border} !important;
		box-shadow: none !important;
	}
`

const SearchResults = styled.div`
	position: absolute;
	right: 0;
	width: 100%;
	border: 1px solid ${Style.color.border};
	background: white;
	min-width: 400px;
	max-height: 400px;
	overflow-y: auto;
	top: 38px;
	border-radius: ${Style.variables.baseBorderRadius};
	z-index: 9;

	&::-webkit-scrollbar { 
    display: none; 
	}

	&.is-centered {
		right: initial;
		left: 50%;
		transform: translateX(-50%);
	}
	
	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		top: 28px;
		border-radius: 0;
		left: 0;
		right: 0;
		transform: none;
		min-width: initial;
	}
`

const SearchCategory = styled.div`
	color: #2C3146;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	border-bottom: 1px solid #EAEEF2;
	padding: 8px 20px;
	font-weight: 700;
`

interface IDispatchToProps {
  showTaskModal: typeof showTaskModal
}

type IProps = RouteComponentProps<any> & {
  currentUser: CurrentUser
  centered?: boolean
} & IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  value: string,
  didFetch: boolean,
  isLoading: boolean,
  results: any[] | null
  shortcuts: IShortcut[]
  selectedIndex: number
}

interface IShortcut {
  label: string
  object: string
  link: string
  icon: IconTypes
  visible: boolean
}

class Search extends React.PureComponent<IProps, IState> {
  private container = React.createRef<HTMLFormElement>()
  private shortcutFuzzySearch: any

  static defaultProps = {
    centered: false
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      value: '',
      didFetch: false,
      isLoading: false,
      results: null,
      shortcuts: [],
      selectedIndex: 0,
    };

    this.shortcutFuzzySearch = new Fuse(this.getShortCuts(), {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'label',
        'link',
        'object',
      ]
    });

    this.getSearchResults = Utils.debounce(this.getSearchResults, 150, false)
    this.resetForm = this.resetForm.bind(this)
    this.incrementSelectedIndex = this.incrementSelectedIndex.bind(this)
    this.decrementSelectedIndex = this.decrementSelectedIndex.bind(this)
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onSearchInputKeyDown = this.onSearchInputKeyDown.bind(this);
    this.onSearchInputKeyUp = this.onSearchInputKeyUp.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.onClickOutsideComponent = this.onClickOutsideComponent.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutsideComponent);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutsideComponent);
  }

  onClickOutsideComponent(e) {
    if (this.container.current && !this.container.current.contains(e.target)) {
      this.resetForm();
    }
  }

  getShortCuts() {
    const { t, currentUser: { workspace: { setting } } } = this.props

    const SHORTCUTS: IShortcut[] = [
      { label: t('Search::Dashboard'), object: 'shortcut', link: ERoute.PATH_DASHBOARD, icon: 'dashboard-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DASHBOARD) },
      { label: t('Search::Calendar'), object: 'shortcut', link: ERoute.PATH_CALENDAR, icon: 'calendar-day', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CALENDAR) },
      { label: t('Search::Tasks'), object: 'shortcut', link: ERoute.PATH_TASKS_OVERVIEW, icon: 'tasks', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) },
      { label: t('Search::Tasks › My tasks'), object: 'shortcut', link: ERoute.PATH_TASKS_MY_TASKS, icon: 'tasks', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) },
      { label: t('Search::Tasks › Today'), object: 'shortcut', link: ERoute.PATH_TASKS_TODAY, icon: 'tasks', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) },
      { label: t('Search::Tasks › Upcoming'), object: 'shortcut', link: ERoute.PATH_TASKS_UPCOMING, icon: 'tasks', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) },
      { label: t('Search::Tasks › Planner'), object: 'shortcut', link: ERoute.PATH_TASKS_PLANNER, icon: 'tasks', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) },
      { label: t('Search::Contacts'), object: 'shortcut', link: ERoute.PATH_CONTACTS, icon: 'user', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTACT) },
      { label: t('Search::Deals'), object: 'shortcut', link: ERoute.PATH_DEALS, icon: 'check-circle', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL) },
      { label: t('Search::Sequences'), object: 'shortcut', link: ERoute.PATH_SEQUENCES, icon: 'paper-plane', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SEQUENCE) },
      { label: t('Search::Projects'), object: 'shortcut', link: ERoute.PATH_PROJECTS, icon: 'project-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) },
      { label: t('Search::Projects › Active'), object: 'shortcut', link: ERoute.PATH_PROJECTS_ACTIVE, icon: 'project-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) },
      { label: t('Search::Projects › Proposal'), object: 'shortcut', link: ERoute.PATH_PROJECTS_PROPOSAL, icon: 'project-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) },
      { label: t('Search::Projects › Finished'), object: 'shortcut', link: ERoute.PATH_PROJECTS_COMPLETED, icon: 'project-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) },
      { label: t('Search::Projects › Cancelled'), object: 'shortcut', link: ERoute.PATH_PROJECTS_CANCELLED, icon: 'project-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) },
      { label: t('Search::Timesheets'), object: 'shortcut', link: ERoute.PATH_TIMESHEETS, icon: 'stopwatch', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING) },
      { label: t('Search::Proposals'), object: 'shortcut', link: ERoute.PATH_PROPOSALS, icon: 'proposal', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROPOSAL) },
      { label: t('Search::Playbooks'), object: 'shortcut', link: ERoute.PATH_PLAYBOOKS, icon: 'book', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK) },
      { label: t('Search::Contracts'), object: 'shortcut', link: ERoute.PATH_CONTRACTS, icon: 'file-contract', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTRACT) },
      { label: t('Search::Documents › Order forms'), object: 'shortcut', link: ERoute.PATH_ORDER_FORMS, icon: 'invoice', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.order_forms_enabled },
      { label: t('Search::Documents › Delivery notes'), object: 'shortcut', link: ERoute.PATH_DELIVERY_NOTES, icon: 'invoice', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.delivery_notes_enabled },
      { label: t('Search::Documents › Quotations'), object: 'shortcut', link: ERoute.PATH_QUOTATIONS, icon: 'invoice', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      { label: t('Search::Documents › Proforma invoices'), object: 'shortcut', link: ERoute.PATH_PRO_FORMA_INVOICES, icon: 'invoice', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.pro_forma_invoices_enabled },
      { label: t('Search::Documents › Invoices'), object: 'shortcut', link: ERoute.PATH_INVOICES, icon: 'invoice', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      { label: t('Search::Documents › Creditnotes'), object: 'shortcut', link: ERoute.PATH_CREDIT_NOTES, icon: 'invoice', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      { label: t('Search::Documents › Recurring Invoices'), object: 'shortcut', link: ERoute.PATH_RECURRING_INVOICES, icon: 'invoice', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      { label: t('Search::Documents › Invoice conditions'), object: 'shortcut', link: ERoute.PATH_LEDGER_CONDITIONS, icon: 'expense', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      { label: t('Search::Expenses'), object: 'shortcut', link: ERoute.PATH_EXPENSES, icon: 'receipt', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) },
      { label: t('Search::Bank'), object: 'shortcut', link: ERoute.PATH_BANK, icon: 'bank', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK) },
      { label: t('Search::Bank › Accounts'), object: 'shortcut', link: ERoute.PATH_BANK_ACCOUNTS, icon: 'sack-dollar', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK) },
      { label: t('Search::Bank › Transactions'), object: 'shortcut', link: ERoute.PATH_BANK_TRANSACTIONS, icon: 'arrow-right-arrow-left', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK) },
      { label: t('Search::Bank › Payments'), object: 'shortcut', link: ERoute.PATH_BANK_PAYMENTS, icon: 'money-bill', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK_PAYMENT) },
      { label: t('Search::Products'), object: 'shortcut', link: ERoute.PATH_PRODUCTS, icon: 'product-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PRODUCT) },
      { label: t('Search::Reporting'), object: 'shortcut', link: ERoute.PATH_REPORTING, icon: 'reporting-navigation', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.REPORTING) },
      { label: t('Search::Integrations'), object: 'shortcut', link: ERoute.PATH_INTEGRATIONS, icon: 'bolt', visible: UserWorkspaceSettingHelper.hasRole(UserWorkspaceSettingRole.OWNER) },
      { label: t('Search::Account › Profile'), object: 'shortcut', link: ERoute.PATH_ACCOUNT_PROFILE, icon: 'user', visible: true },
      { label: t('Search::Account › Subscription'), object: 'shortcut', link: ERoute.PATH_ACCOUNT_SUBSCRIPTION, icon: 'creditcard', visible: UserWorkspaceSettingHelper.hasRole(UserWorkspaceSettingRole.OWNER) },
      { label: t('Search::Settings'), object: 'shortcut', link: ERoute.PATH_SETTINGS, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › General'), object: 'shortcut', link: ERoute.PATH_SETTINGS_GENERAL, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Company details'), object: 'shortcut', link: ERoute.PATH_SETTINGS_COMPANY, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Company branding'), object: 'shortcut', link: ERoute.PATH_SETTINGS_COMPANY_BRANDING, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Time settings'), object: 'shortcut', link: ERoute.PATH_SETTINGS_DISPLAY_PREFERENCES, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Calendar'), object: 'shortcut', link: ERoute.PATH_SETTINGS_CALENDAR, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Order forms'), object: 'shortcut', link: ERoute.PATH_SETTINGS_ORDER_FORMS, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Delivery notes'), object: 'shortcut', link: ERoute.PATH_SETTINGS_DELIVERY_NOTES, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Quotations'), object: 'shortcut', link: ERoute.PATH_SETTINGS_QUOTATIONS, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Proforma invoices'), object: 'shortcut', link: ERoute.PATH_SETTINGS_PRO_FORMA_INVOICES, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Invoices'), object: 'shortcut', link: ERoute.PATH_SETTINGS_INVOICES, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Creditnotes'), object: 'shortcut', link: ERoute.PATH_SETTINGS_CREDIT_NOTES, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Email'), object: 'shortcut', link: ERoute.PATH_SETTINGS_EMAIL, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Menu editor'), object: 'shortcut', link: ERoute.PATH_SETTINGS_MENU_EDITOR, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Custom SMTP'), object: 'shortcut', link: ERoute.PATH_SETTINGS_SMTP, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Backup'), object: 'shortcut', link: ERoute.PATH_SETTINGS_DATA, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Settings › Work types'), object: 'shortcut', link: ERoute.PATH_SETTINGS_WORK_TYPES, icon: 'setting', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SETTING) },
      { label: t('Search::Team'), object: 'shortcut', link: ERoute.PATH_TEAM, icon: 'team', visible: UserWorkspaceSettingHelper.hasRole(UserWorkspaceSettingRole.OWNER) },
      { label: t('Search::Forms'), object: 'shortcut', link: ERoute.PATH_FORMS, icon: 'input-pipe', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FORM) },
      { label: t('Search::Files'), object: 'shortcut', link: ERoute.PATH_FILES, icon: 'folder', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) },
      { label: t('Search::Accountant › Send'), object: 'shortcut', link: ERoute.PATH_ACCOUNTANT_SEND, icon: 'accountant', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.ACCOUNTANT) },
      { label: t('Search::Accountant › Documents'), object: 'shortcut', link: ERoute.PATH_ACCOUNTANT_DOCUMENTS, icon: 'copy', visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DOCUMENT) },
      { label: t('Search::Referral program'), object: 'shortcut', link: ERoute.PATH_REFER_A_FRIEND, icon: 'check-circle', visible: true },
    ]

    return SHORTCUTS.filter(shortcut => shortcut.visible)
  }

  getSearchResults(value) {
    const { results, didFetch } = this.state;

    this.setState({
      isLoading: results && !didFetch
    }, () => {
      SearchController
        .search(value)
        .then(response => {
          this.setState({
            didFetch: true,
            isLoading: false,
            results: response
          });
        })
        .catch(error => {
          console.error(error)
          this.setState({
            didFetch: true,
            isLoading: false,
            results: []
          })
        })
    });
  }

  getAllResults() {
    const { shortcuts, results } = this.state
    let allResults = []

    if (shortcuts.length > 0) {
      allResults = [...allResults, ...shortcuts]
    }

    if (results && results.length) {

      allResults = [...allResults, ...results]
    }

    return allResults
  }

  getRealIndex(index) {
    const { shortcuts } = this.state
    return shortcuts.length > 0 ? (shortcuts.length - 1 + index + 1) : index
  }

  isSelectedItem(index) {
    const { shortcuts, selectedIndex } = this.state
    return shortcuts.length > 0 ? (shortcuts.length - 1 + index + 1) === selectedIndex : selectedIndex === index
  }

  resetForm() {
    this.setState({
      results: null,
      didFetch: false,
      isLoading: false,
      value: '',
      shortcuts: [],
      selectedIndex: null
    });
  }

  decrementSelectedIndex() {
    const { selectedIndex } = this.state
    const allResults = this.getAllResults()

    if (selectedIndex === null || selectedIndex === 0) {
      if (allResults.length > 0) {
        const newIndex = allResults.length - 1
        this.setState({ selectedIndex: newIndex }, () => {
          this[`searchItem${newIndex}`].scrollIntoView(false)
        })
      }
    } else if (selectedIndex >= 1) {
      const newIndex = selectedIndex - 1
      this.setState({ selectedIndex: newIndex }, () => {
        this[`searchItem${newIndex}`].scrollIntoView(false)
      })
    }
  }

  incrementSelectedIndex() {
    const { selectedIndex } = this.state
    const allResults = this.getAllResults()

    if (selectedIndex === null || selectedIndex === allResults.length - 1) {
      if (allResults.length > 0) {
        const newIndex = 0
        this.setState({ selectedIndex: newIndex }, () => {
          this[`searchItem${newIndex}`].scrollIntoView(false)
        })
      }
    } else if (selectedIndex <= allResults.length - 1) {
      const newIndex = selectedIndex + 1
      this.setState({ selectedIndex: newIndex }, () => {
        this[`searchItem${newIndex}`].scrollIntoView(false)
      })
    }
  }

  onSearchInputChange(e) {
    const value = e.currentTarget.value;

    if (value.length === 0) {
      this.resetForm()
      return;
    }
    else if (value.length > 1) {
      const results = this.shortcutFuzzySearch.search(value).map(result => result.item)
      this.setState({
        shortcuts: results
      })

      this.getSearchResults(value);
    }

    this.setState({
      value: value,
      // Reset selected index on input change
      selectedIndex: null
    });
  }

  onSearchInputKeyDown(e) {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        e.stopPropagation()
        break
      case 'ArrowDown':
        e.preventDefault()
        e.stopPropagation()
        break
    }
  }

  onSearchInputKeyUp(e) {
    switch (e.key) {
      case 'Escape': this.resetForm()
        break
      case 'ArrowUp':
        e.stopPropagation()
        this.decrementSelectedIndex()
        break
      case 'ArrowDown':
        e.stopPropagation()
        this.incrementSelectedIndex()
        break
    }
    if (e.key == 'Escape') {
      this.resetForm()
    }
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { selectedIndex } = this.state

    if (selectedIndex !== null) {
      const results = this.getAllResults()

      const selectedItem = results[selectedIndex]

      if (selectedItem) {
        this.onItemClick(e, selectedItem)
      }
    }
  }

  onItemClick(e, item) {
    const { history } = this.props
    e.preventDefault();

    switch (item.object) {
      case 'shortcut':
        history.push(item.link)
        break
      case 'person':
      case 'company':
      case 'contact':
        history.push(RouteHelper.process(ERoute.PATH_CONTACT, { id: item.id }))
        break
      case 'project':
        history.push(RouteHelper.process(ERoute.PATH_PROJECT, { id: item.id }))
        break
      case 'order_form':
      case 'delivery_note':
      case 'quotation':
      case 'invoice':
      case 'credit_note':
        history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: item.id }))
        break
      case 'expense':
        history.push(RouteHelper.process(ERoute.PATH_EXPENSES, { id: item.id }))
        break
      case 'product':
        history.push(RouteHelper.process(ERoute.PATH_PRODUCTS, { id: item.id }))
        break
      case 'transaction':
        history.push(RouteHelper.process(ERoute.PATH_TRANSACTION, { id: item.id }))
        break
      case 'payment':
        history.push(RouteHelper.process(ERoute.PATH_BANK_PAYMENTS, { id: item.id }))
        break
      case 'deal':
        history.push(RouteHelper.process(ERoute.PATH_DEAL, { id: item.id }))
        break
      case 'form':
        history.push(RouteHelper.process(ERoute.PATH_FORM, { id: item.id }))
        break
      case 'proposal':
        history.push(RouteHelper.process(ERoute.PATH_PROPOSAL, { id: item.id }))
        break
      case 'contract':
        history.push(RouteHelper.process(ERoute.PATH_CONTRACT, { id: item.id }))
        break
      case 'task':
        this.props.showTaskModal({ task: { id: item.id } })
        break
      case 'sequence':
        history.push(RouteHelper.process(ERoute.PATH_SEQUENCE, { id: item.id }))
        break
      default:
        console.warn(`Unknown item click of object -> ${item.object}`);
    }

    this.resetForm()
  }

  renderResults() {
    const { results } = this.state

    return (
      <>
        {results.map((result, index) => {
          switch (result.object) {
            case 'order_form':
            case 'delivery_note':
            case 'quotation':
            case 'invoice':
            case 'credit_note':
              return this.renderInvoice(result, index)
            case 'recurring_invoice':
              return this.renderRecurringInvoice(result, index)
            case 'company':
            case 'person':
            case 'contact': return this.renderContact(result, index)
            case 'project': return this.renderProject(result, index)
            case 'expense': return this.renderExpense(result, index)
            case 'product': return this.renderProduct(result, index)
            case 'transaction': return this.renderTransaction(result, index)
            case 'payment': return this.renderPayment(result, index)
            case 'deal': return this.renderDeal(result, index)
            case 'form': return this.renderForm(result, index)
            case 'proposal': return this.renderProposal(result, index)
            case 'contract': return this.renderContract(result, index)
            case 'task': return this.renderTask(result, index)
            case 'sequence': return this.renderSequence(result, index)
            default:
              return null
              console.error(`Unknown search object: ${result.object}`, result)
          }
        })}
      </>
    )
  }

  renderInvoice(search_invoice, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_invoice.id}
        type={search_invoice.object}
        title={Highlighter.highlight(search_invoice.number, value)}
        onClick={(e) => this.onItemClick(e, search_invoice)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderRecurringInvoice(search_invoice, index) {
    const { t } = this.props

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_invoice.id}
        type={search_invoice.object}
        title={t('Search::Recurring invoice')}
        onClick={(e) => this.onItemClick(e, search_invoice)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderContact(search_contact, index) {
    const { t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_contact.id}
        type={search_contact.object}
        title={Highlighter.highlight(`${search_contact.name} ${search_contact.alias ? `(${search_contact.alias})` : ''}`, value)}
        onClick={(e) => this.onItemClick(e, search_contact)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderProject(search_project, index) {
    const { t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_project.id}
        type={search_project.object}
        title={Highlighter.highlight(search_project.name, value)}
        onClick={(e) => this.onItemClick(e, search_project)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderExpense(search_expense, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_expense.id}
        type={search_expense.object}
        title={Highlighter.highlight(search_expense.name, value)}
        onClick={(e) => this.onItemClick(e, search_expense)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderProduct(search_product, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_product.id}
        type={search_product.object}
        title={Highlighter.highlight(search_product.name, value)}
        onClick={(e) => this.onItemClick(e, search_product)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderTransaction(search_transaction, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_transaction.id}
        type={search_transaction.object}
        title={Highlighter.highlight(search_transaction.counterpart_name, value)}
        onClick={(e) => this.onItemClick(e, search_transaction)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderPayment(search_payment, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_payment.id}
        type={search_payment.object}
        title={Highlighter.highlight(search_payment.name, value)}
        onClick={(e) => this.onItemClick(e, search_payment)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderDeal(search_deal, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_deal.id}
        type={search_deal.object}
        title={Highlighter.highlight(search_deal.name, value)}
        onClick={(e) => this.onItemClick(e, search_deal)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderForm(search_form, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_form.id}
        type={search_form.object}
        title={Highlighter.highlight(search_form.name, value)}
        onClick={(e) => this.onItemClick(e, search_form)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderProposal(search_proposal, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_proposal.id}
        type={search_proposal.object}
        title={Highlighter.highlight(search_proposal.name, value)}
        onClick={(e) => this.onItemClick(e, search_proposal)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderContract(search_contract, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_contract.id}
        type={search_contract.object}
        title={Highlighter.highlight(search_contract.name, value)}
        onClick={(e) => this.onItemClick(e, search_contract)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderTask(search_task, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={search_task.id}
        type={search_task.object}
        title={Highlighter.highlight(search_task.name, value)}
        onClick={(e) => this.onItemClick(e, search_task)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderSequence(searchSequence, index) {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { value } = this.state

    return (
      <SearchItem
        ref={searchItem => this[`searchItem${this.getRealIndex(index)}`] = searchItem}
        key={searchSequence.id}
        type={searchSequence.object}
        title={Highlighter.highlight(searchSequence.name, value)}
        onClick={(e) => this.onItemClick(e, searchSequence)}
        isSelected={this.isSelectedItem(index)}
      />
    )
  }

  renderProjectListBadge(list) {
    let type = 'primary'

    switch (list) {
      case 'active':
        type = 'primary'
        break
      case 'completed':
        type = 'success'
        break
      case 'potential':
        type = 'warning'
        break
      case 'cancelled':
        type = 'danger'
        break
    }

    return (
      <span className={`badge badge-${type}`}>{ProjectHelper.getStatusTitle(list)}</span>
    )
  }

  render() {
    const { centered, t } = this.props
    const { value, shortcuts, results, isLoading, selectedIndex } = this.state

    return (
      <Container ref={this.container} onSubmit={this.onFormSubmit}>
        <SearchIcon>
          <Icon icon='search' />
        </SearchIcon>

        <SearchInput
          autoCapitalize='off'
          value={value}
          placeholder={t('Search::Search...')}
          onChange={this.onSearchInputChange}
          onKeyDown={this.onSearchInputKeyDown}
          onKeyUp={this.onSearchInputKeyUp}
        />

        {isLoading && <SearchLoader />}

        {(shortcuts.length > 0 || (results && results.length > 0)) && <SearchResults className={`${centered ? 'is-centered' : ''}`}>
          {shortcuts.length > 0 && <>
            <SearchCategory>
              {t('Search::Shortcuts')}
            </SearchCategory>

            {shortcuts.map((shortcut, index) => {
              return (
                <ItemContainer
                  key={`${shortcut.link}-${index}`}
                  ref={searchItem => this[`searchItem${index}`] = searchItem}
                  className={selectedIndex === index ? 'is-selected' : ''}
                  onClick={(e) => this.onItemClick(e, shortcut)}
                >
                  <ShortcutIcon>
                    <Icon icon={shortcut.icon} />
                  </ShortcutIcon>

                  <span>{Highlighter.highlight(shortcut.label, value)}</span>

                  <EnterIcon>
                    <Icon icon='enter-key' />
                  </EnterIcon>
                </ItemContainer>
              )
            })}
          </>}

          {results && this.renderResults()}
        </SearchResults>}
      </Container>
    )
  }
}

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showTaskModal: typeof showTaskModal
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showTaskModal: (options) => dispatch(showTaskModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Search)))