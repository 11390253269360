import * as React from 'react'
import { IconTypes } from '../Icons/Icon'
import ActionList, { IActionListItem } from '../ActionList/ActionList'
import Button from '../Button/Button'
import styled from 'styled-components'
import { Style } from '../../styles'

interface IProps {
	icon: IconTypes
	text: string
	actions: IActionListItem[]
	onActionClick: (key: string) => void
}

interface IState {
	actionMenuActive: boolean,
}

const Container = styled.div`
	position: relative;
`

const ActionListContainer = styled.div`
	position: absolute;
	min-width: 185px;
	top: 40px;
	right: 0;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		top: initial;
		position: fixed;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 16px;
	}
`

export default class TopNavigationButtonWithOptions extends React.Component<IProps, IState> {
	private container = React.createRef<HTMLDivElement>()

	constructor(props) {
		super(props)

		this.state = {
			actionMenuActive: false,
		}

		this.onToggleActionMenuClick = this.onToggleActionMenuClick.bind(this)
		this.onOutsideActionMenuClick = this.onOutsideActionMenuClick.bind(this)
		this.onActionItemClick = this.onActionItemClick.bind(this)
	}

	componentDidMount () {
		document.addEventListener('mousedown', this.onOutsideActionMenuClick);
	}

	componentWillUnmount () {
		document.removeEventListener('mousedown', this.onOutsideActionMenuClick);
	}

	onToggleActionMenuClick () {
		const { actionMenuActive } = this.state

		this.setState({
			actionMenuActive: !actionMenuActive
		})
	}

	onOutsideActionMenuClick (e) {
		const { actionMenuActive } = this.state

		if (actionMenuActive && this.container && !this.container.current.contains(e.target)) {
			this.setState({ actionMenuActive: false })
    }
	}

	onActionItemClick (key: string) {
		const { onActionClick } = this.props

		this.setState({ actionMenuActive: false })

		if (onActionClick) {
			onActionClick(key)
		}
	}

	render () {
		const { actionMenuActive } = this.state
		const { icon, text, actions } = this.props

		return (
			<Container ref={this.container}>
				<Button
					type='primary'
					icon={icon}
					text={text}
					onClick={this.onToggleActionMenuClick}
					extraClasses='page-action'
				/>

				{actionMenuActive && <ActionListContainer>
					<ActionList
						actions={actions}
						hasIndicator={true}
						onClick={this.onActionItemClick}
					/>
				</ActionListContainer>}
			</Container>
		)
	}
}