import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'
import Card from './Card'
import { Draggable, Droppable } from "react-beautiful-dnd";
import BoardListTitle from './BoardListTitle'
import AddCard from './AddCard'
import { BoardLabel, BoardList as BoardListType, Task } from '../../types';


const ContainerWrapper = styled.div`
  height: 100%;

  &:last-child {
    padding-right: ${Style.spacing.x2};
  }
`
const Container = styled.div`
    width: 272px;
		min-width: 272px;
    margin: 0 4px;
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
		white-space: nowrap;
    border-radius: ${Style.variables.baseBorderRadius};
		
		&:first-child {
			margin-left: 8px;
		}
`

const Wrapper = styled.div`
    background-color: #ebecf0;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
    white-space: normal;
    border: 1px solid ${Style.color.border};
`

const ListCards = styled.div`
	flex: 1 1 auto;
	margin-bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0 4px;
	padding: 0 4px;
	min-height: 0;

  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }
`

const Footer = styled.div`	
	display: flex;
	flex-direction: row;
	align-items: center;
`

interface IProps {
  index: number
  list: BoardListType
  labels: BoardLabel[]
  labelsExpanded: boolean
  tasks: Task[]
  onChangeListName: (listId: string, name: string) => void
  onAddTaskClick: (list: BoardListType) => void
  onMoveTasksClick: (sourceList: BoardListType, destinationList: BoardListType) => void
  onDeleteTasksClick: (list: BoardListType) => void
  onDeleteList: (listId: string) => void
  onTaskClick: (task: Task) => void
  onTaskCompletedToggle: (task: Task) => void
  onAddTaskSubmit: (listId: string, name: string) => void
  onAddTaskFilesDropped: (listId: string, files: any[]) => void
  onTaskFilesDropped: (taskId: string, files: any[]) => void
  onToggleLabels: () => void
}

const BoardList = (props: IProps) => {
  const { index, list, tasks, labels, labelsExpanded, onTaskClick, onTaskCompletedToggle, onToggleLabels } = props
  const [dragBlocking, setDragBlocking] = React.useState(false);

  const onAddTaskSubmit = (name: string) => {
    props.onAddTaskSubmit(list.id, name)
  }

  const onAddTaskFilesDropped = (files: File[]) => {
    props.onAddTaskFilesDropped(list.id, files)
  }

  const onTaskFilesDropped = (taskId: string, files: File[]) => {
    props.onTaskFilesDropped(taskId, files)
  }

  const onListNameChange = (name: string) => {
    props.onChangeListName(list.id, name)
  }

  const onAddTaskClick = () => {
    props.onAddTaskClick(list)
  }

  const onMoveTasksClick = (newList: BoardListType) => {
    props.onMoveTasksClick(list, newList)
  }

  const onDeleteTasksClick = () => {
    props.onDeleteTasksClick(list)
  }

  const onListDeleteClick = () => {
    props.onDeleteList(list.id)
  }

  return (
    <Draggable
      key={list.id}
      disableInteractiveElementBlocking={!dragBlocking}
      draggableId={list.id}
      index={index}
    >
      {provided => (
        <ContainerWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Container>
            <Wrapper>
              <BoardListTitle
                list={list}
                dragHandleProps={provided.dragHandleProps}
                name={list.name}
                onNameChange={onListNameChange}
                onAddTaskClick={onAddTaskClick}
                onMoveTasksClick={onMoveTasksClick}
                onDeleteTasksClick={onDeleteTasksClick}
                onDeleteClick={onListDeleteClick}
                setDragBlocking={setDragBlocking}
              />

              <Droppable key={list.id} droppableId={list.id}>
                {(droppableProvided, droppableSnapshot) => {
                  return (
                    <ListCards ref={droppableProvided.innerRef}>
                      {tasks.map((task, index) => {
                        return (
                          <Card
                            key={task.id}
                            index={index}
                            task={task}
                            labels={labels}
                            labelsExpanded={labelsExpanded}
                            onClick={onTaskClick}
                            onCompletedToggle={onTaskCompletedToggle}
                            onToggleLabels={onToggleLabels}
                            onFilesDropped={onTaskFilesDropped}
                          />
                        )
                      })}
                      <div style={{ height: 1 }} />
                      {droppableProvided.placeholder}
                    </ListCards>
                  )
                }}
              </Droppable>


              <Footer>
                <AddCard
                  onSubmit={onAddTaskSubmit}
                  onFilesDropped={onAddTaskFilesDropped}
                />
              </Footer>
            </Wrapper>
          </Container>
        </ContainerWrapper>
      )
      }
    </Draggable >
  )
}

export default BoardList