import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import PlannerMeter from './PlannerMeter'
import { PlannerController } from '../../controllers'
import { PlannedTask } from '../../types'
import moment from '../../utilities/Moment'
import { useTranslation } from 'react-i18next'
import Loader from '../Loaders/Loader'
import Icon from '../Icons/Icon'
import TaskButton from '../TaskModal/TaskButton'
import DurationInput from '../Form/DurationInput'

const Container = styled.div`
  margin: 0 5px;
  padding: 8px;
  background: white;
  border-radius: ${Style.variables.baseBorderRadius};
  border: 1px solid ${Style.color.border};
  min-width: 225px;

  form {
    .form-item {
      margin-bottom: 0px;
    }
  }
`

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 0;
  min-height: 100px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
`

const PlannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PlannerIndicators = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  gap: 12px;
`

const ItemIcon = styled.div<{ big?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;

  svg {
    width: 16px;
    height: 16px;
    color: #1d3451;
    fill: #1d3451;
  }

  i {
    font-size: 16px;
    color: #1d3451;
  }

  svg, i {
    margin-top: 8px;
  }

  ${(props) => props.big && css`
    i {
      font-size: 18px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  `}
`

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Actions = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`

interface IProps {
  taskId: string
  dateFormat: string
  onUpdateClick: (plannedTask: PlannedTask) => void
  onRemoveClick: () => void
}

interface IState {
  didInitialLoad: boolean
  plannedTask: PlannedTask
  formPlannedTask: PlannedTask
  editing: boolean
}

const PlannerItemTaskDetails = (props: IProps) => {
  const { t } = useTranslation()
  const { taskId, dateFormat, onUpdateClick, onRemoveClick } = props
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    plannedTask: null,
    formPlannedTask: null,
    editing: false,
  })
  const { didInitialLoad, plannedTask, formPlannedTask, editing } = state

  const fetchPlannedTask = async () => {
    try {
      const plannedTask = await PlannerController.get(taskId)

      setState({ ...state, didInitialLoad: true, plannedTask: plannedTask })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onEditClick = () => {
    setState({
      ...state,
      editing: true,
      formPlannedTask: plannedTask
    })
  }

  const onSaveClick = (e) => {
    if (e) e.preventDefault()

    if (formPlannedTask?.duration === null || formPlannedTask?.duration === 0) {
      onRemoveClick()
    } else if (formPlannedTask?.duration <= (plannedTask?.task.time_remaining + plannedTask.duration)) {
      onUpdateClick(formPlannedTask)
    }
    setState({ ...state, editing: false, formPlannedTask: null })
  }

  const onCancelEditClick = () => {
    setState({
      ...state,
      editing: false,
      formPlannedTask: null
    })
  }

  const onDurationChange = (duration: number | null) => {
    setState({
      ...state,
      formPlannedTask: {
        ...formPlannedTask,
        duration: duration,
        end_at: duration && duration > 0 ? moment(formPlannedTask.start_at).add(duration, 'seconds').toISOString() : formPlannedTask.end_at
      }
    })
  }

  React.useEffect(() => {
    fetchPlannedTask().catch(ex => console.error(ex))
  }, [])

  let plannedDuration = 0
  let taskTimeEstimate = 0

  if (plannedTask) {
    plannedDuration = plannedTask.task.time_planned
    taskTimeEstimate = plannedTask.task.time_estimate
  }

  return (
    <Container>
      {!didInitialLoad && <LoaderContainer>
        <Loader />
      </LoaderContainer>}
      {didInitialLoad && <>
        <PlannerContainer>
          {!editing && <>
            <Item>
              <ItemIcon big>
                <Icon icon='tasks' />
              </ItemIcon>

              <ItemContent>
                <Title>{plannedTask?.task?.name} </Title>
              </ItemContent>
            </Item>

            <Item>
              <ItemIcon>
                <Icon icon='user' />
              </ItemIcon>

              <ItemContent>
                {plannedTask?.task?.contact ? plannedTask?.task?.contact?.name : t('PlannerItemTaskDetails::No contact')}
              </ItemContent>
            </Item>

            <Item>
              <ItemIcon>
                <Icon icon='project' />
              </ItemIcon>

              <ItemContent>
                {plannedTask?.task?.project ? plannedTask?.task?.project?.name : t('PlannerItemTaskDetails::No project')}
              </ItemContent>
            </Item>

            <Item>
              <ItemIcon>
                <Icon icon='calendar-check' />
              </ItemIcon>

              <ItemContent>
                {moment(plannedTask.start_at).format(dateFormat)}
              </ItemContent>
            </Item>

            <Item>
              <ItemIcon>
                <Icon icon='calendar-clock' />
              </ItemIcon>

              <ItemContent>
                <PlannerIndicators>
                  <span>
                    {moment.duration(plannedDuration, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small' })}
                  </span>

                  <span>
                    / {moment.duration(taskTimeEstimate, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small' })}
                  </span>
                </PlannerIndicators>
                <PlannerMeter
                  scheduledTime={plannedDuration}
                  availableTime={taskTimeEstimate}
                />
              </ItemContent>
            </Item>

            <Actions>
              <TaskButton
                onClick={onEditClick}
                center
              >
                {t('PlannerItemTaskDetails::Edit')}
              </TaskButton>
              <TaskButton
                onClick={onRemoveClick}
                center
                destructive
              >
                {t('PlannerItemTaskDetails::Remove')}
              </TaskButton>
            </Actions>
          </>}

          {editing && <>
            <form onSubmit={onSaveClick}>
              <div className='form-item'>
                <label>{t('PlannerItemTaskDetails::Duration')}</label>
                <DurationInput
                  value={formPlannedTask.duration}
                  onChange={onDurationChange}
                />
              </div>
            </form>
            <Actions>
              <TaskButton
                onClick={onSaveClick}
                center
              >
                {t('PlannerItemTaskDetails::Save')}
              </TaskButton>

              <TaskButton
                onClick={onCancelEditClick}
                center
              >
                {t('PlannerItemTaskDetails::Cancel')}
              </TaskButton>
            </Actions>
          </>}
        </PlannerContainer>
      </>}
    </Container>
  )
}

export default PlannerItemTaskDetails