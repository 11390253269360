import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  transform: translateY(-50%);

  justify-content: center;
  align-items: center;
  display: none;
  /* @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: flex;
  } */
`

const SidebarNavigationItemDropdownIndicator = () => {
  return (
    <Container>
      <svg width="16" height="16"><path d="M 4 5 L 12 5 L 8 11 Z" fill="currentColor"></path></svg>
    </Container>
  )
}

export default SidebarNavigationItemDropdownIndicator