import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { ResourceContentBlocksController, ResourceContentBlockTemplatesController } from '../../controllers'
import ContentBlockHelper from '../../helpers/ContentBlockHelper'
import { showConfirmModal, showContentBlockTemplateModal, showImportProductsModal, showItemsBlockSettingModal, showQuestionAndAnswerBlockSettingModal } from '../../store/modals/actions'
import { createContentblock, deleteContentBlock, updateContentBlock } from '../../store/content-blocks/actions'
import { Style } from '../../styles'
import ButtonPanel from '../Button/ButtonPanel'
import AddBlock from './AddBlock'
import Block from './Block'
import BlockCanvas from './BlockCanvas'
import BlocksRenderer, { BlockRenderMode } from './BlocksRenderer'
import BlocksWrapper from './BlocksWrapper'
import DroppableHelper from '../../helpers/DroppableHelper'
import { ContentBlock, ContentBlockResource, ContentBlockTemplate, ContentBlockType, ContentBlockVariables, ItemsBlock, NumberFormat, QuestionAndAnswerBlock, Signature, WorkspaceTax } from '../../types'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	overflow-x: hidden;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column-reverse;
	}
`

const PreviewHeader = styled.div`
	position: sticky;
	top: 20px;
	right: ${Style.spacing.x2};
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: ${Style.spacing.x1};
	margin-right: ${Style.spacing.x2};
	z-index: 1;
`

interface IDispatchToProps {
	createContentblock: typeof createContentblock
	updateContentBlock: typeof updateContentBlock
	deleteContentBlock: typeof deleteContentBlock
	showItemsBlockSettingModal: typeof showItemsBlockSettingModal
	showImportProductsModal: typeof showImportProductsModal
	showContentBlockTemplateModal: typeof showContentBlockTemplateModal
	showConfirmModal: typeof showConfirmModal
	showQuestionAndAnswerBlockSettingModal: typeof showQuestionAndAnswerBlockSettingModal
}

export const enum BlockEditorDroppableType {
	BLOCK = 'block',
	BLOCK_ITEM = 'block-item',
}

type IProps = {
	resource: ContentBlockResource
	resourceId: string
	variables: ContentBlockVariables
	taxes: WorkspaceTax[]
	currency: string
	numberFormat: NumberFormat
	signatures?: Signature[]
	blocks: ContentBlock[]
	blockTypes: ContentBlockType[]
	previewModeEnabled: boolean
	editable?: boolean
	onTogglePreviewClick: () => void
	onCreateBlock: (block: ContentBlock) => void
	onUpdateBlock: (block: ContentBlock) => void
	onDeleteBlock: (block: ContentBlock) => void
} & IDispatchToProps

const BlockEditor = (props: IProps) => {
	const {
		resource,
		resourceId,
		variables,
		taxes,
		currency = 'EUR',
		numberFormat = NumberFormat.SPACE_COMMA,
		blocks,
		blockTypes,
		previewModeEnabled,
		editable,
		signatures,
	} = props
	const { t } = useTranslation()
	const [state, setState] = React.useState<{ blockTemplates: ContentBlockTemplate[] }>({
		blockTemplates: []
	})
	const { blockTemplates } = state
	const itemBlocks: ItemsBlock[] = blocks.filter(block => block.type === ContentBlockType.ITEMS) as ItemsBlock[]

	React.useEffect(() => {
		ReactTooltip.rebuild();
	})

	React.useEffect(() => {
		fetchContentBlockTemplates().catch(console.error)
	}, [])

	const fetchContentBlockTemplates = async () => {
		try {
			const blockTemplates = await ResourceContentBlockTemplatesController.getContentBlockTemplates({ resource_type: resource })

			setState({ ...state, blockTemplates: blockTemplates })
		} catch (ex) {
			console.error(ex)
		}
	}

	const onTogglePreviewClick = () => {
		props.onTogglePreviewClick()
	}

	const createBlock = async (contentBlock: ContentBlock) => {
		const { resource, resourceId } = props

		try {
			const response = await ResourceContentBlocksController.create(resource, resourceId, contentBlock)
		} catch (ex) {
			console.error(ex)
		}
	}

	const updateBlock = async (contentBlock: ContentBlock) => {
		const { resourceId } = props
		try {
			const response = await ResourceContentBlocksController.update(resource, resourceId, contentBlock)
		} catch (ex) {
			console.error(ex)
		}
	}

	const deleteBlock = async (contentBlock: ContentBlock) => {
		try {
			const response = await ResourceContentBlocksController.delete(resource, resourceId, contentBlock.id)
		} catch (ex) {
			console.error(ex)
		}
	}

	const duplicateBlock = async (contentBlock: ContentBlock, position: number) => {
		const { resourceId } = props
		try {
			const response = await ResourceContentBlocksController.duplicate(resource, resourceId, contentBlock.id, position)
		} catch (ex) {
			console.error(ex)
		}
	}

	const onAddBlockClick = (block: ContentBlock) => {
		createBlock(block)
	}

	const onDeleteBlockClick = (block: ContentBlock) => {
		deleteBlock(block)
	}

	const onSaveTemplateBlockClick = (block: ContentBlock) => {
		props.showContentBlockTemplateModal({
			contentBlockTemplate: {},
			contentBlockId: block.id,
			onSubmit: (contentBlockTemplate) => {
				setState({
					...state,
					blockTemplates: [
						contentBlockTemplate,
						...blockTemplates,
					]
				})
			}
		})
	}

	const onTemplateDeleteClick = async (contentBlockTemplate: ContentBlockTemplate, onSuccess?: () => void, onFailure?: () => void) => {
		props.showConfirmModal({
			title: t('BlockEditor::Delete template'),
			description: t('BlockEditor::You are about to delete this template. This template will be permanently deleted. Are you sure?'),
			action: { label: t('BlockEditor::Delete'), isDestructive: true },
			onConfirm: async () => {
				try {
					const response = await ResourceContentBlockTemplatesController.delete(contentBlockTemplate.id)

					const templateIndex = blockTemplates.findIndex(bt => bt.id === contentBlockTemplate.id)

					if (templateIndex !== -1) {
						blockTemplates.splice(templateIndex, 1);
						setState({ ...state, blockTemplates: [...blockTemplates] })

						if (onSuccess) onSuccess()
					}
				} catch (ex) {
					console.error(ex)
				}
			}
		})
	}

	const onTemplateFormSubmit = async (contentBlockTemplate: ContentBlockTemplate, onSuccess?: () => void, onFailure?: () => void) => {
		try {
			const contentBlockResponse = await ResourceContentBlocksController.update(ContentBlockResource.CONTENT_BLOCK_TEMPLATE, contentBlockTemplate.id, contentBlockTemplate.content_block)
			const contentBlockTemplateResponse = await ResourceContentBlockTemplatesController.update(contentBlockTemplate)

			const templateIndex = blockTemplates.findIndex(bt => bt.id === contentBlockTemplateResponse.id)

			if (templateIndex !== -1) {
				blockTemplates[templateIndex] = contentBlockTemplate

				setState({ ...state, blockTemplates: [...blockTemplates] })
				if (onSuccess) onSuccess()
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	const onDuplicateBlockClick = (block: ContentBlock, position: number) => {
		duplicateBlock(block, position)
	}

	const onSettingsBlockClick = (block: ContentBlock) => {
		switch (block.type) {
			case ContentBlockType.ITEMS:
				requestAnimationFrame(() => {
					props.showItemsBlockSettingModal({
						block: block,
						onSubmit: (itemsBlock: ItemsBlock) => {
							// Ensures the state of the items gets reset according to the newly selected settings
							if (
								block.selection_requirement_type !== itemsBlock.selection_requirement_type ||
								block.selection_type !== itemsBlock.selection_type &&
								itemsBlock.items.length > 0
							) {
								// Options have changed we need to reset the state so we aren't in an invalid state
								let items = itemsBlock.items.map(item => ({ ...item, selected: false }))

								const updatedItems = ContentBlockHelper.getSelectionChangeItems({ ...block, items: items }, 0)
								itemsBlock = { ...itemsBlock, items: updatedItems }
							}

							updateBlock(itemsBlock).catch(console.error)
						}
					})
				})
				break
			case ContentBlockType.QUESTION_AND_ANSWER:
				requestAnimationFrame(() => {
					props.showQuestionAndAnswerBlockSettingModal({
						block: block,
						onSubmit: (questionAndAnswerBlock: QuestionAndAnswerBlock) => {
							// Ensures the state of the items gets reset according to the newly selected settings
							// if (
							// 	block.selection_requirement_type !== itemsBlock.selection_requirement_type ||
							// 	block.selection_type !== itemsBlock.selection_type &&
							// 	itemsBlock.items.length > 0
							// ) {
							// 	// Options have changed we need to reset the state so we aren't in an invalid state
							// 	let items = itemsBlock.items.map(item => ({ ...item, selected: false }))

							// 	const updatedItems = ContentBlockHelper.getSelectionChangeItems({ ...block, items: items }, 0)
							// 	itemsBlock = { ...itemsBlock, items: updatedItems }
							// }

							updateBlock(questionAndAnswerBlock).catch(console.error)
						}
					})
				})
				break
			default: throw Error(`Unsupported settings block click given for block: ${block.type}`)
		}
	}

	const onImportProductsClick = (block: ContentBlock) => {
		if (block.type !== ContentBlockType.ITEMS) return

		requestAnimationFrame(() => {
			props.showImportProductsModal((products) => {
				products.forEach((importedProduct) => {
					const { quantity, product } = importedProduct
					block.items.push({
						quantity: quantity,
						amount: Number(product.price),
						title: product.name,
						description: product.description,
						tax_rate: Number(product.tax_rate),
						tax_code: product.tax_code,
					})
				})
				onBlockChange(block)
			})
		})
	}

	const onBlockChange = (block: ContentBlock, onChangeComplete?: () => void) => {
		try {
			// Update remote
			updateBlock(block)
			// Send onChangeComplete event
			if (onChangeComplete) onChangeComplete()
		} catch (ex) {
			console.error(ex)
		}
	}

	const onBeforeDragStart = () => { }
	const onDragStart = () => { }

	const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
		if (!result.destination) return;

		const source = result.source;
		const destination = result.destination;

		// did not move anywhere - can bail early
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		if (result.type === BlockEditorDroppableType.BLOCK) { // Reordering a block
			reorderBlock(result)
		}
	}

	const reorderBlock = (result: DropResult) => {
		const { source, destination } = result
		// Get moved blockId
		const movedBlock = blocks[source.index]
		const movedBlockId = movedBlock.id

		// Calculate the new block position based on the blocks inside the list
		const newBlockPosition = DroppableHelper.getNewPositionBasedOnDropResult(blocks, result)
		const blockIndex = blocks.findIndex(block => block.id === movedBlockId)

		if (blockIndex !== -1) {
			blocks[blockIndex].position = newBlockPosition

			// Get updated block
			const updatedBlock: ContentBlock = blocks[blockIndex]

			// Updated content block async
			ResourceContentBlocksController.update(resource, resourceId, updatedBlock).catch(console.error)

			// Set local tasks
			props.updateContentBlock(updatedBlock)
		}
	}

	const onRenderBlocksItemSelectionChange = (block: ItemsBlock, itemIndex: number) => {
		const updatedItemsBlock = { ...block, items: ContentBlockHelper.getSelectionChangeItems(block, itemIndex) }
		// Updated content block async
		ResourceContentBlocksController.update(resource, resourceId, updatedItemsBlock).catch(console.error)

		// Set local tasks
		props.updateContentBlock(updatedItemsBlock)
	}

	return (
		<Container>
			<BlockCanvas>
				{editable && <PreviewHeader>
					<ButtonPanel
						icon={previewModeEnabled ? 'eye-closed' : 'eye'}
						text={previewModeEnabled ? t('BlockEditor::Cancel preview') : t('BlockEditor::Preview')}
						onClick={onTogglePreviewClick}
					/>
				</PreviewHeader>}

				<BlocksWrapper className={(previewModeEnabled || !editable) ? 'preview' : ''}>
					{(previewModeEnabled || !editable) && <BlocksRenderer
						renderMode={BlockRenderMode.EDITOR}
						blocks={blocks}
						variables={variables}
						currency={currency}
						numberFormat={numberFormat}
						signatures={signatures}
						onItemsBlockItemSelectionChange={onRenderBlocksItemSelectionChange}
					/>}

					{!previewModeEnabled && editable && <>
						<DragDropContext
							onBeforeDragStart={onBeforeDragStart}
							onDragStart={onDragStart}
							onDragEnd={onDragEnd}
						>
							<Droppable droppableId='blocks' direction='vertical' type={BlockEditorDroppableType.BLOCK}>
								{(droppableProvided, droppableSnapshot) => {
									return (
										<div ref={droppableProvided.innerRef}>
											<AddBlock
												key='add-block-top'
												types={blockTypes}
												blocks={blocks}
												blockTemplates={blockTemplates}
												variables={variables}
												taxes={taxes}
												currency={currency}
												numberFormat={numberFormat}
												onAddBlockClick={(block) => onAddBlockClick({ ...block, position: ContentBlockHelper.getCreatePosition(blocks) })}
												onTemplateDeleteClick={onTemplateDeleteClick}
												onTemplateFormSubmit={onTemplateFormSubmit}
											/>

											{blocks.map((block, index) => {
												const newBlockPosition = ContentBlockHelper.getCreatePositionFromIndex(blocks, index)

												return (
													<Block
														key={block.id}
														index={index}
														block={block}
														variables={variables}
														currency={currency}
														taxes={taxes}
														numberFormat={numberFormat}
														blocks={blocks}
														blockTemplates={blockTemplates}
														items={itemBlocks}
														types={blockTypes}
														onBlockChange={onBlockChange}
														onAddBlockClick={(block) => onAddBlockClick({ ...block, position: newBlockPosition })}
														onDeleteBlockClick={onDeleteBlockClick}
														onSaveTemplateBlockClick={onSaveTemplateBlockClick}
														onDuplicateBlockClick={() => onDuplicateBlockClick(block, newBlockPosition)}
														onSettingsBlockClick={() => onSettingsBlockClick(block)}
														onImportProductsClick={onImportProductsClick}
														onTemplateDeleteClick={onTemplateDeleteClick}
														onTemplateFormSubmit={onTemplateFormSubmit}
													/>
												)
											})}
											{droppableProvided.placeholder}
										</div>
									)
								}}
							</Droppable>
						</DragDropContext>
					</>}
				</BlocksWrapper>
			</BlockCanvas >
		</Container>
	)
}

const mapDispatchToProps: IDispatchToProps = {
	createContentblock,
	updateContentBlock,
	deleteContentBlock,
	showItemsBlockSettingModal,
	showContentBlockTemplateModal,
	showImportProductsModal,
	showConfirmModal,
	showQuestionAndAnswerBlockSettingModal
}

export default connect(null, mapDispatchToProps)(BlockEditor)