import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import PageContent from '../../components/Page/PageContent';
import PageLoader from '../../components/Page/PageLoader';
import ActionCableConsumer from '../../consumers/ActionCableConsumer';
import SequencesController from '../../controllers/SequencesController';
import { AppState } from '../../store';

import {
	createSequenceStep,
	deleteSequenceStep,
	setInitialSequenceStepEditorState,
	updateSequenceStep
} from '../../store/sequence-steps/actions';
import Utils from '../../utilities/Utils';
import { showConfirmModal, showSequenceStepModal } from '../../store/modals/actions';
import ReactTooltip from 'react-tooltip';
import { SequenceStep, CurrencyOption, CurrentUser, DisplayableError, Sequence, SequenceStepTypeInfo, SequenceChannelEvent, SequenceChannelEventType, SequenceStepType, SequenceMetadata } from '../../types';
import AddSequenceStep from '../../components/SequenceEditor/AddSequenceStep';
import SequenceAnalytics from '../../components/SequenceEditor/SequenceAnalytics';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import DroppableHelper from '../../helpers/DroppableHelper';
import SequenceStepsController from '../../controllers/SequenceStepsController';
import Step from '../../components/SequenceEditor/Step';
import ERoute from '../../ERoute';
import Notification from '../../utilities/Notification';
import SequenceHelper from '../../helpers/SequenceHelper';
import SequenceStepEditor from './SequenceStepEditor';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-x: hidden;
	padding: 20px;
`


interface IStateToProps {
	currentUser: CurrentUser
	steps: SequenceStep[]
}

interface IDispatchToProps {
	setInitialSequenceStepEditorState: typeof setInitialSequenceStepEditorState
	createSequenceStep: typeof createSequenceStep
	updateSequenceStep: typeof updateSequenceStep
	deleteSequenceStep: typeof deleteSequenceStep
	showConfirmModal: typeof showConfirmModal
	showSequenceStepModal: typeof showSequenceStepModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation

interface IState {
	didInitialLoad: boolean
	sequence: Sequence | null
	metadata: SequenceMetadata
	currencies: CurrencyOption[]
	actionPopoverActive: boolean
	isSubmitting: boolean
	errors: DisplayableError[],
}

class SequenceEditor extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			didInitialLoad: false,
			sequence: null,
			metadata: null,
			currencies: [],
			actionPopoverActive: false,
			isSubmitting: false,
			errors: [],
		}

		this.onNameChange = this.onNameChange.bind(this)
		this.onActiveChange = this.onActiveChange.bind(this)
		this.onSequenceSubmit = this.onSequenceSubmit.bind(this)
		this.debouncedSequenceSubmit = Utils.debounce(this.onSequenceSubmit, 500, false)
		this.onAddStepClick = this.onAddStepClick.bind(this)
		this.onEditStepClick = this.onEditStepClick.bind(this)
		this.onDeleteStepClick = this.onDeleteStepClick.bind(this)
		this.onStepChange = this.onStepChange.bind(this)

		// Action cable
		this.onActionCableConnected = this.onActionCableConnected.bind(this)
		this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
		this.onActionCableReceived = this.onActionCableReceived.bind(this)

		this.onCreateSequenceStep = this.onCreateSequenceStep.bind(this)
		this.onUpdateSequenceStep = this.onUpdateSequenceStep.bind(this)
		this.onDeleteSequenceStep = this.onDeleteSequenceStep.bind(this)

		this.onBeforeDragStart = this.onBeforeDragStart.bind(this)
		this.onDragStart = this.onDragStart.bind(this)
		this.onDragEnd = this.onDragEnd.bind(this)
		this.reorderSequenceStep = this.reorderSequenceStep.bind(this)
	}

	componentDidMount() {
		this.fetchSequence().catch(console.error)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.fetchSequence().catch(console.error)
		}

		ReactTooltip.rebuild();
	}

	onActionCableConnected() {
		console.log('[SequenceChannel] connected')
	}

	onActionCableDisconnected() {
		console.log('[SequenceChannel] Disconnected')
	}

	onActionCableReceived(event: SequenceChannelEvent) {
		const { t } = this.props

		console.log('[SequenceChannel] received event', event)

		switch (event.type) {
			case SequenceChannelEventType.SEQUENCE_CREATE:
				// Not really fired since you can't subscribe on a contract that hasn't been created yet
				break
			case SequenceChannelEventType.SEQUENCE_UPDATE:
				this.setState({ sequence: event.data.sequence })
				break
			case SequenceChannelEventType.SEQUENCE_DELETE:
				Notification.notifySuccess(t('Sequence::Sequence has been deleted'))
				this.props.history.replace(ERoute.PATH_SEQUENCES)
				break
			case SequenceChannelEventType.SEQUENCE_STEP_CREATE:
				this.props.createSequenceStep(event.data.sequence_step)
				break
			case SequenceChannelEventType.SEQUENCE_STEP_UPDATE:
				this.props.updateSequenceStep(event.data.sequence_step)
				break
			case SequenceChannelEventType.SEQUENCE_STEP_DELETE:
				this.props.deleteSequenceStep(event.data.sequence_step_id)
				break
			default:
				throw Error('Invalid event type')
		}
	}

	onCreateSequenceStep(step: SequenceStep) {
		this.props.createSequenceStep(step)
	}

	onUpdateSequenceStep(step: SequenceStep) {
		this.props.updateSequenceStep(step)
	}

	onDeleteSequenceStep(step: SequenceStep) {
		this.props.deleteSequenceStep(step.id)
	}

	onAddStepClick(step: SequenceStep) {
		const { sequence } = this.state
		this.createStep({ ...step, sequence_id: sequence.id, delay: sequence?.steps?.length === 0 ? 0 : 86400 })
	}

	onEditStepClick(step: SequenceStep, itemIndex?: number) {
		this.props.showSequenceStepModal({
			sequenceStep: step,
			index: itemIndex,
		})
	}

	onEditEmailStepClick(step: SequenceStep, emailIndex: number) {
		this.props.showSequenceStepModal({
			sequenceStep: step,
		})
	}

	onDeleteStepClick(step: SequenceStep) {
		this.deleteStep(step)
	}

	onStepChange(step: SequenceStep) {
		this.updateStep(step)
	}

	async fetchSequence() {
		try {
			const { sequence, metadata } = await SequencesController.getSequenceForm({ id: this.props.match.params.id })

			this.setState({
				didInitialLoad: true,
				sequence: sequence,
				metadata: metadata
			})

			this.props.setInitialSequenceStepEditorState({
				steps: sequence.steps,
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	async createStep(step: SequenceStep) {
		const { sequence } = this.state
		try {
			const response = await SequenceStepsController.create(sequence.id, step)

			this.props.showSequenceStepModal({
				sequenceStep: { ...step, id: response.id },
				index: step.type === SequenceStepType.AUTOMATED_EMAIL ? 0 : undefined,
			})

		} catch (ex) {
			console.error(ex)
		}
	}

	async updateStep(step: SequenceStep) {
		const { sequence } = this.state

		try {
			const response = await SequenceStepsController.update(sequence.id, step)
		} catch (ex) {
			console.error(ex)
		}
	}

	async deleteStep(step: SequenceStep) {
		const { sequence } = this.state
		try {
			const response = await SequenceStepsController.delete(sequence.id, step.id)
		} catch (ex) {
			console.error(ex)
		}
	}

	onNameChange(e) {
		const name = e.currentTarget.value

		this.setState({
			sequence: {
				...this.state.sequence,
				name: name,
			}
		}, this.debouncedSequenceSubmit)
	}

	onActiveChange() {
		const { sequence } = this.state

		this.setState({
			sequence: {
				...this.state.sequence,
				active: !sequence.active,
			}
		}, this.debouncedSequenceSubmit)
	}

	debouncedSequenceSubmit() {
		this.onSequenceSubmit().catch(console.error)
	}

	async onSequenceSubmit(e?: any) {
		const { isSubmitting } = this.state
		if (e) e.preventDefault()

		if (!isSubmitting) {
			try {
				this.setState({ isSubmitting: true })

				const response = await SequencesController.update(this.state.sequence)

				if (response.errors) {
					this.setState({ errors: response.errors })
				} else {
					this.setState({ errors: [] })
				}

				await this.fetchSequence()
			} catch (ex) {
				console.error(ex)
			} finally {
				this.setState({ isSubmitting: false })
			}
		}
	}

	onBeforeDragStart() { }
	onDragStart() { }
	onDragEnd(result: DropResult, provided: ResponderProvided) {
		if (!result.destination) return;

		const source = result.source;
		const destination = result.destination;

		// did not move anywhere - can bail early
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		this.reorderSequenceStep(result)
	}

	reorderSequenceStep(result: DropResult) {
		const { sequence } = this.state
		const { steps } = sequence
		const { source, destination } = result
		// Get moved stepId
		const movedBlock = steps[source.index]
		const movedBlockId = movedBlock.id

		// Calculate the new step position based on the steps inside the list
		const newStepPosition = DroppableHelper.getNewPositionBasedOnDropResult(steps, result)
		const stepIndex = steps.findIndex(step => step.id === movedBlockId)

		if (stepIndex !== -1) {
			steps[stepIndex].position = newStepPosition

			// Get updated step
			const updatedSequenceStep: SequenceStep = steps[stepIndex]

			// Updated content step async
			SequenceStepsController.update(sequence.id, updatedSequenceStep).catch(console.error)

			// Set local tasks
			this.props.updateSequenceStep(updatedSequenceStep)
		}
	}


	render() {
		const { currentUser: { workspace: { setting } }, steps } = this.props
		const { didInitialLoad, sequence, metadata } = this.state

		return (
			<PageContent className='is-sequence'>
				{!didInitialLoad && <PageContent><PageLoader /></PageContent>}

				{didInitialLoad && <Wrapper>
					<ActionCableConsumer
						channel={{ channel: 'SequenceChannel', id: sequence.id }}
						onConnected={this.onActionCableConnected}
						onDisconnected={this.onActionCableDisconnected}
						onReceived={this.onActionCableReceived}
					>
						<SequenceAnalytics data={metadata} />

						<SequenceStepEditor
							sequenceId={sequence.id}
							steps={steps}
							editable={true}
						/>
					</ActionCableConsumer>
				</Wrapper>}
			</PageContent>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
		sequenceStepEditor: {
			steps
		}
	} = state

	return {
		currentUser: currentUser,
		steps: steps,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	setInitialSequenceStepEditorState,
	createSequenceStep,
	updateSequenceStep,
	deleteSequenceStep,
	showConfirmModal,
	showSequenceStepModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SequenceEditor))