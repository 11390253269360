import * as React from 'react'
import Icon from '../Icons/Icon'
import ActionList, { IActionListItem } from '../ActionList/ActionList'
import { Style } from '../../styles'
import Popover from '../Popover/Popover'

interface IProps {
  className?: string
  text: string | JSX.Element | JSX.Element[]
  actions: IActionListItem[]
  onActionClick: (key: string) => void
}

interface IState {
  actionMenuActive: boolean,
}

export default class TableOptions extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()

  static defaultProps = {
    className: '',
    text: <Icon icon='ellipsis' />,
    onActionClick: (key: string) => console.log(`[TableOptions] key: ${key} pressed`),
  }

  constructor(props) {
    super(props)

    this.state = {
      actionMenuActive: false,
    }

    this.onToggleActionMenuClick = this.onToggleActionMenuClick.bind(this)
    this.onPopoverCloseClick = this.onPopoverCloseClick.bind(this)
    this.onActionItemClick = this.onActionItemClick.bind(this)
  }

  onToggleActionMenuClick() {
    const { actionMenuActive } = this.state

    this.setState({
      actionMenuActive: !actionMenuActive
    })
  }

  onPopoverCloseClick() {
    this.setState({ actionMenuActive: false })
  }

  onActionItemClick(key: string) {
    const { onActionClick } = this.props

    this.setState({ actionMenuActive: false })

    if (onActionClick) {
      onActionClick(key)
    }
  }

  render() {
    const { actionMenuActive } = this.state
    const { text, actions, className } = this.props

    return (
      <div ref={this.container}>
        <Popover
          activator={
            <span className={`table-options ${className} ${actionMenuActive ? 'is-active' : ''}`} onClick={this.onToggleActionMenuClick}>
              {text}
            </span>}
          placement='bottom-end'
          onClose={this.onPopoverCloseClick}
          active={actionMenuActive}
        >
          <div style={{ marginTop: Style.spacing.x1, marginBottom: Style.spacing.x1 }}>
            <ActionList
              actions={actions}
              hasIndicator={true}
              onClick={this.onActionItemClick}
            />
          </div>
        </Popover>
      </div>
    )
  }
}