import * as React from 'react'
import copy from "copy-to-clipboard";
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import TopNavigation from '../../components/Navigation/TopNavigation'
import { showProjectModal, showContactModal, showConfirmModal, showCalendarEventModal, showTaskModal, showPlaybookSubmissionModal, showFileUploadModal, showSendEmailModal, showContactEnrollmentModal, showCallModal } from '../../store/modals/actions'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { ContactsController } from '../../controllers'
import Notification from '../../utilities/Notification'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import { Link, NavLink } from 'react-router-dom'
import PageHeader from '../../components/Page/PageHeader'
import Panel from '../../components/Panel/Panel'
import NumberFormatter from '../../utilities/NumberFormatter'
import SummaryWithContent from '../../components/Contact/SummaryWithContent'
import SummaryInvoiceTable from '../../components/Contact/SummaryInvoiceTable'
import ContactLedgerItems from '../../components/Contact/ContactLedgerItems'
import ProfileDetailItem from '../../components/Profile/ProfileDetailItem'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import PageLoader from '../../components/Page/PageLoader'
import PageContent from '../../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import FileBrowser from '../../components/FileBrowser/FileBrowser'
import { WithTranslation, withTranslation } from 'react-i18next'
import ContactTimesheets from '../../components/Contact/ContactTimesheets'
import ContactExpenses from '../../components/Contact/ContactExpenses'
import Icon from '../../components/Icons/Icon'
import ContactProjects from '../../components/Contact/ContactProjects'
import BoardsTable from '../../components/Contact/BoardsTable'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import ContactProposals from '../../components/Contact/ContactProposals'
import ContactContracts from '../../components/Contact/ContactContracts'
import { ActivityTrackableType, BizzeyFileResourceType, CalendarEvent, CalendarEventType, Contact, ContactType, CurrentUser, LedgerItem, LedgerItemType, NavigationMenuItemPage, Playbook, PlaybookableType, Task, UserWorkspaceSettingScope } from '../../types'
import DetailsList from '../../components/Profile/DetailsList'
import PanelAction from '../../components/Panel/PanelAction'
import DealsTable from '../../components/Deals/DealsTable'
import Tooltip from '../../components/Tooltips/Tooltip'
import Map from '../../components/Map/Map'
import ContactCards from '../../components/Contact/ContactCards'
import SettingHelper from '../../helpers/SettingHelper'
import ButtonGroup from '../../components/Button/ButtonGroup'
import styled from 'styled-components'
import { Style } from '../../styles'
import PlaybooksTable from '../../components/Playbooks/PlaybooksTable'
import ActivityFeed, { ActivityFeed as ActivityFeedClass } from '../../components/Activity/ActivityFeed'
import ActivityFeedContainer from '../../components/Activity/ActivityFeedContainer'
import Badge from '../../components/Badge/Badge'
import ContactHelper from '../../helpers/ContactHelper'
import ContactTypeAvatar from '../../components/Avatar/ContactTypeAvatar';
import ContactPurchaseInvoices from '../../components/Contact/ContactPurchaseInvoices';
import moment from '../../utilities/Moment';
import AttachItem from '../../components/Contact/AttachItem';
import ResourceFiles, { ResourceFiles as ResourceFilesClass } from '../../components/ResourceFiles/ResourceFiles';
import ReactTooltip from 'react-tooltip';
import TaskView, { TaskViewMode } from '../../components/Tasks/TaskView';
import AddActivityButton from '../../components/Activity/AddActivityButton';

const DetailItems = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ${Style.spacing.x1};
    }
  }
`

const CopyAction = styled.div`
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border-color: ${Style.color.border};
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #82828c;
  }

  i {
    color: #82828c;
  }
`;

const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    &:first-child {
      width: 100%;
      margin-right: ${Style.spacing.x1};
    }
  }

  ${CopyAction} {
    display: none;
  }

  &:hover {
    ${CopyAction} {
      display: flex;
    }

    > * {
      &:last-child {
        display: none;
      }
    }
  }
`

const HeaderContactDetails = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    fill: #595959;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      display: none;
    }
  }

  i {
    font-size: 15px;
    color: #595959;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      display: none;
    }
  }


  a, span {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #595959;

    &:hover {
      text-decoration: underline;
    }
  }
`

const WorksAtContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  color: #333333;

  @media screen  and (max-width: ${Style.breakpoints.SMALL}) {
    justify-content: center;
    align-items: center;
  }

  a {
    margin-left: 4px;
    color: #595959;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Groups = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 4px;
  margin-top: 4px;
`

const HeaderDetailIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HeaderDetailsIcon = styled.div<{ starred: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.starred ? '#f4a230' : 'black'};
  margin-left: 8px;

  svg {
    width: 25px;
    height: 25px;
    fill: currentColor;
  }
`

const AttachItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showConfirmModal: typeof showConfirmModal
  showCalendarEventModal: typeof showCalendarEventModal
  showTaskModal: typeof showTaskModal
  showPlaybookSubmissionModal: typeof showPlaybookSubmissionModal
  showFileUploadModal: typeof showFileUploadModal
  showSendEmailModal: typeof showSendEmailModal
  showContactEnrollmentModal: typeof showContactEnrollmentModal
  showCallModal: typeof showCallModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id: string }> & WithTranslation

interface IState {
  didInitialLoad: boolean
  contact: Contact | null
  invoice_summary: {
    outstanding: number,
    overdue: number,
    pending: number,
    paid: number
  } | null
  activeInvoiceSummaryKey: string
  ledgerItems: LedgerItem[]
  didInitialLoadLedgerItems: boolean
  currentPageLedgerItems: number
  endReachedLedgerItems: boolean
  fetchingLedgerItems: boolean
}

class ContactShow extends React.Component<IProps, IState> {
  private activityFeed = React.createRef<ActivityFeedClass>()
  private resourceFiles = React.createRef<ResourceFilesClass>()
  constructor(props) {
    super(props);

    this.state = {
      didInitialLoad: false,
      contact: null,
      invoice_summary: null,
      ledgerItems: [],
      didInitialLoadLedgerItems: false,
      currentPageLedgerItems: 1,
      endReachedLedgerItems: false,
      fetchingLedgerItems: false,
      activeInvoiceSummaryKey: '',
    }

    this.onBreadCrumbBackPress = this.onBreadCrumbBackPress.bind(this)
    this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)
    this.onArchiveContactClick = this.onArchiveContactClick.bind(this)
    this.onEditContactClick = this.onEditContactClick.bind(this)
    this.onToggleStarredClick = this.onToggleStarredClick.bind(this)
    this.onEditCustomFieldClick = this.onEditCustomFieldClick.bind(this)
    this.onEditContactsClick = this.onEditContactsClick.bind(this)
    this.onEditInvoicesClick = this.onEditInvoicesClick.bind(this)
    this.onDeleteContactClick = this.onDeleteContactClick.bind(this)
    this.onModalFormSubmitEditContact = this.onModalFormSubmitEditContact.bind(this)
    this.onNewOrderFormClick = this.onNewOrderFormClick.bind(this)
    this.onNewDeliveryNoteClick = this.onNewDeliveryNoteClick.bind(this)
    this.onNewQuotationClick = this.onNewQuotationClick.bind(this)
    this.onNewInvoiceClick = this.onNewInvoiceClick.bind(this)
    this.onNewCreditNoteClick = this.onNewCreditNoteClick.bind(this)
    this.onEditNotesClick = this.onEditNotesClick.bind(this)
    this.onInvoiceSummaryItemClick = this.onInvoiceSummaryItemClick.bind(this)
    this.onNewCalendarEventClick = this.onNewCalendarEventClick.bind(this)
    this.onEmailClick = this.onEmailClick.bind(this)
    this.onScheduleAppointmentClick = this.onScheduleAppointmentClick.bind(this)
    this.onLogCallClick = this.onLogCallClick.bind(this)
    this.onLogTaskClick = this.onLogTaskClick.bind(this)
    this.onSendEmailSubmit = this.onSendEmailSubmit.bind(this)
    this.onCalendarEventSubmit = this.onCalendarEventSubmit.bind(this)
    this.onCalendarEventDelete = this.onCalendarEventDelete.bind(this)
    this.onCalendarEventClick = this.onCalendarEventClick.bind(this)
    this.onNewTaskClick = this.onNewTaskClick.bind(this)
    this.onAddAttachmentClick = this.onAddAttachmentClick.bind(this)
    this.onAddSequenceClick = this.onAddSequenceClick.bind(this)
    this.onTaskClick = this.onTaskClick.bind(this)
    this.onTaskSubmit = this.onTaskSubmit.bind(this)
    this.onTaskDelete = this.onTaskDelete.bind(this)
    this.onPlaybookClick = this.onPlaybookClick.bind(this)
    this.renderContactView = this.renderContactView.bind(this)
  }

  componentWillMount() {
    this.fetchView()
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    ReactTooltip.rebuild()

    const { match: { params: previousParams } } = prevProps
    const { match: { params: currentParams } } = this.props

    if (previousParams.id !== currentParams.id) {
      this.fetchView()
    }
  }

  getTitle() {
    const { t } = this.props
    const { contact } = this.state

    if (contact) {
      return `Bizzey | ${contact.name}`
    } else {
      return t('Contact::Bizzey | Contact')
    }
  }

  async fetchView() {
    const { match: { params: { id } } } = this.props

    try {
      const response = await ContactsController.contactView(id)
      const {
        contact,
        invoice_summary,
      } = response

      this.setState({
        contact: contact,
        didInitialLoad: true,
        invoice_summary: invoice_summary,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onBreadCrumbBackPress(e) {
    e.preventDefault()

    this.props.history.goBack()
  }

  onPageHeaderActionClick(key: string) {
    switch (key) {
      case 'archive': this.onArchiveContactClick()
        break
      case 'edit': this.onEditContactClick()
        break
      case 'delete': this.onDeleteContactClick()
        break
    }
  }

  async onArchiveContactClick() {
    const { t } = this.props
    const { contact } = this.state

    try {
      const updatedContact: Contact = await ContactsController.update({ ...contact, archived: !contact.archived })

      this.setState({ contact: updatedContact })

      Notification.notifySuccess(updatedContact.archived ? t('Contact::Contact successfully archived') : t('Contact::Contact successfully unarchived'))
    } catch (ex) {
      console.error(ex)
    }
  }

  onEditContactClick() {
    const { contact } = this.state

    if (contact) this.props.showContactModal({
      contact: { id: contact.id },
      onSubmit: this.onModalFormSubmitEditContact
    })
  }

  async onToggleStarredClick() {
    const { contact } = this.state

    try {
      const updatedContact: Contact = { ...contact, starred: !contact.starred }
      await ContactsController.update(updatedContact)

      this.setState({ contact: updatedContact })
    } catch (ex) {
      console.error(ex)
    }
  }

  onEditCustomFieldClick() {
    const { contact } = this.state

    if (contact) this.props.showContactModal({
      contact: { id: contact.id },
      onSubmit: this.onModalFormSubmitEditContact
    })
  }


  onEditContactsClick() {
    const { contact } = this.state

    if (contact) this.props.showContactModal({
      contact: { id: contact.id },
      onSubmit: this.onModalFormSubmitEditContact
    })
  }

  onNewCalendarEventClick() {
    const { contact } = this.state

    this.props.showCalendarEventModal({
      calendarEvent: {
        type: CalendarEventType.EVENT,
        contact_id: contact.id,
        contact: contact,
      },
      contactDisabled: true,
      onSubmit: this.onCalendarEventSubmit,
      onDelete: this.onCalendarEventDelete
    })
  }

  onEmailClick() {
    const { contact } = this.state

    this.props.showSendEmailModal({
      email: {
        to: [contact.id],
      },
      onSubmit: this.onSendEmailSubmit,
    })
  }

  onScheduleAppointmentClick() {
    const { contact } = this.state

    this.props.showCalendarEventModal({
      calendarEvent: {
        type: CalendarEventType.APPOINTMENT,
        contact_id: contact.id,
        contact: contact,
      },
      contactDisabled: true,
      onSubmit: this.onCalendarEventSubmit,
      onDelete: this.onCalendarEventDelete
    })
  }

  onLogCallClick() {
    const { contact } = this.state

    this.props.showCallModal({
      call: {
        contact_id: contact.id,
        datetime: moment().toISOString(),
      },
      onSubmit: (call) => {
        if (this.activityFeed?.current) this.activityFeed.current.refetch()
      },
    })
  }

  onLogTaskClick() {
    const { contact } = this.state

    this.props.showTaskModal({
      task: {
        contact_id: contact.id,
        contact: contact,
      },
      contactDisabled: true,
      projectDisabled: false,
      onSubmit: this.onTaskSubmit,
      onDelete: this.onTaskDelete
    })
  }

  onSendEmailSubmit() {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onCalendarEventSubmit(calendarEvent: CalendarEvent) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onCalendarEventDelete(calendarEventId: string) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onCalendarEventClick(calendarEvent: CalendarEvent) {
    this.props.showCalendarEventModal({
      calendarEvent: {
        id: calendarEvent.id
      },
      contactDisabled: true,
      onSubmit: this.onCalendarEventSubmit,
      onDelete: this.onCalendarEventDelete
    })
  }

  onNewTaskClick() {
    const { contact } = this.state

    this.props.showTaskModal({
      task: {
        contact_id: contact.id,
        contact: contact,
      },
      contactDisabled: true,
      onSubmit: this.onTaskSubmit,
      onDelete: this.onTaskDelete
    })
  }

  onAddAttachmentClick() {
    const { contact } = this.state

    this.props.showFileUploadModal({
      resourceId: contact.id,
      resourceType: BizzeyFileResourceType.CONTACT,
      onSubmit: (files) => {
        if (this.resourceFiles?.current) {
          this.resourceFiles?.current?.refetch()
        }
      },
    })
  }

  onAddSequenceClick() {
    const { contact } = this.state

    requestAnimationFrame(() => {
      this.props.showContactEnrollmentModal({
        contactId: contact.id,
      })
    })
  }

  onTaskClick(task: Task) {
    const { contact } = this.state

    this.props.showTaskModal({
      task: {
        id: task.id,
        contact_id: contact.id,
        contact: contact,
      },
      contactDisabled: true,
      onSubmit: this.onTaskSubmit,
      onDelete: this.onTaskDelete
    })
  }

  onTaskSubmit(task: Task) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onTaskDelete(task: Task) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onEditInvoicesClick() {
    const { contact } = this.state

    if (contact) this.props.showContactModal({
      contact: { id: contact.id },
      onSubmit: this.onModalFormSubmitEditContact
    })
  }

  onDeleteContactClick() {
    const { contact } = this.state;
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Contact::Delete contact'),
      description: t('Contact::You are about to delete <b>{{name}}</b>. By deleting this contact you are deleting all associated data with it. Are you sure?', { name: contact.name }),
      action: { label: t('Contact::Delete'), isDestructive: true },
      onConfirm: () => {
        ContactsController
          .delete(contact.id)
          .then(response => {
            if (response.errors) {
            } else {
              Notification.notifySuccess(t('Contact::Contact {{name}} removed successfully', { name: contact.name }));
              this.props.history.push(ERoute.PATH_CONTACTS)
            }
          })
          .catch(console.error)
      }
    })
  }

  onModalFormSubmitEditContact(contact) {
    this.setState({ contact: contact });
  }

  onNewOrderFormClick() {
    const { contact } = this.state;

    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { contact_id: contact.id, type: LedgerItemType.ORDER_FORM }))
  }

  onNewDeliveryNoteClick() {
    const { contact } = this.state;

    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { contact_id: contact.id, type: LedgerItemType.DELIVERY_NOTE }))
  }

  onNewQuotationClick() {
    const { contact } = this.state;

    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { contact_id: contact.id, type: LedgerItemType.QUOTATION }))
  }

  onNewInvoiceClick() {
    const { contact } = this.state;

    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { contact_id: contact.id, type: LedgerItemType.INVOICE }))
  }

  onNewCreditNoteClick() {
    const { contact } = this.state;

    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { contact_id: contact.id, type: LedgerItemType.CREDIT_NOTE }))
  }

  onEditNotesClick() {
    const { contact } = this.state

    this.props.showContactModal({
      contact: { id: contact.id },
      activeTab: 'notes',
      onSubmit: this.onModalFormSubmitEditContact,
    })
  }

  onInvoiceSummaryItemClick(key: string) {
    const { activeInvoiceSummaryKey } = this.state

    if (activeInvoiceSummaryKey === key) {
      this.setState({ activeInvoiceSummaryKey: '' })
    } else {
      this.setState({ activeInvoiceSummaryKey: key })
    }
  }

  renderEmailDetails() {
    const { t } = this.props
    const { contact } = this.state

    if (contact?.emails.length === 0) return <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add email')}</a>

    return (
      <DetailItems>
        {contact?.emails?.map((email, index) => {
          return (
            <DetailItem key={index}>
              <a key={index} href={`mailto:${email.value}`}>
                {email.value}
              </a>
              <CopyAction
                onClick={() => {
                  copy(email.value)
                  Notification.notifySuccess(t('Contact::Copied to clipboard'))
                }}
                data-tip={t('Contact::Copy to clipboard')}
              >
                <Icon icon='copy' />
              </CopyAction>
              <Badge type='grey' text={ContactHelper.getEmailTypeLabel(email.type)} />
            </DetailItem>
          )
        })}
      </DetailItems>
    )
  }

  renderPhoneNumberDetails() {
    const { t } = this.props
    const { contact } = this.state

    if (contact?.telephone_numbers.length === 0) return <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add telephone number')}</a>

    return (
      <DetailItems>
        {contact?.telephone_numbers?.map((phoneNumber, index) => {
          return (
            <DetailItem key={index}>
              <a href={`tel:${phoneNumber.value}`}>
                {phoneNumber.value}
              </a>
              <CopyAction
                onClick={() => {
                  copy(phoneNumber.value)
                  Notification.notifySuccess(t('Contact::Copied to clipboard'))
                }}
                data-tip={t('Contact::Copy to clipboard')}
              >
                <Icon icon='copy' />
              </CopyAction>
              <Badge type='grey' text={ContactHelper.getPhonenumberTypeLabel(phoneNumber.type)} />
            </DetailItem>
          )
        })}
      </DetailItems>
    )
  }

  renderAccountNumberDetails() {
    const { t } = this.props
    const { contact } = this.state

    if (contact?.account_numbers.length === 0) return <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add account number')}</a>

    return (
      <DetailItems>
        {contact?.account_numbers?.map((accountNumber, index) => {
          return (
            <DetailItem key={index}>
              <a key={index} href={'javascript://'} onClick={this.onEditContactClick}>
                {accountNumber.value}
              </a>
              <CopyAction
                onClick={() => {
                  copy(accountNumber.value)
                  Notification.notifySuccess(t('Contact::Copied to clipboard'))
                }}
                data-tip={t('Contact::Copy to clipboard')}
              >
                <Icon icon='copy' />
              </CopyAction>
            </DetailItem>
          )
        })}
      </DetailItems>
    )
  }

  renderWebsiteDetails() {
    const { t } = this.props
    const { contact } = this.state

    if (contact?.websites?.length === 0) return <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add website')}</a>

    return (
      <DetailItems>
        {contact?.websites?.map((website, index) => {
          return (
            <DetailItem key={index}>
              <a key={index} href={website.url} target='_blank'>
                {website.url}
              </a>
              <CopyAction
                onClick={() => {
                  copy(website.url)
                  Notification.notifySuccess(t('Contact::Copied to clipboard'))
                }}
                data-tip={t('Contact::Copy to clipboard')}
              >
                <Icon icon='copy' />
              </CopyAction>
              <div />

            </DetailItem>
          )
        })}
      </DetailItems>
    )
  }

  renderContactView() {
    const { t } = this.props
    const {
      contact,
      invoice_summary,
      activeInvoiceSummaryKey,
    } = this.state

    const { currentUser } = this.props
    const { currentUser: { workspace: { setting } } } = this.props

    return (
      <div className='grid'>
        <div className='grid-cell with-4col'>
          <Panel
            title={t('Contact::Details')}
            headerRight={<PanelAction icon='pen' onClick={this.onEditContactClick} />}
          >
            <DetailsList>
              {contact?.type === ContactType.PERSON && <>
                <ProfileDetailItem
                  label={t('Contact::First name')}
                  value={contact?.first_name ? <a href='javascript://' onClick={this.onEditContactClick}>{contact.first_name}</a> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add first name')}</a>}
                />
                <ProfileDetailItem
                  label={t('Contact::Last name')}
                  value={contact?.last_name ? <a href='javascript://' onClick={this.onEditContactClick}>{contact.last_name}</a> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add last name')}</a>}
                />
              </>}
              {contact?.type === ContactType.COMPANY && <>
                <ProfileDetailItem
                  label={t('Contact::Name')}
                  value={contact.name ? <a href='javascript://' onClick={this.onEditContactClick}>{contact.name}</a> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add name')}</a>}
                />
              </>}
              {contact.type === ContactType.PERSON && <>
                <ProfileDetailItem
                  label={t('Contact::Job title')}
                  value={contact.job_title ? <a href='javascript://' onClick={this.onEditContactClick}>{contact.job_title}</a> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add job title')}</a>}
                />
                <ProfileDetailItem
                  label={t('Contact::Background information')}
                  value={contact.background_info ? <a href='javascript://' onClick={this.onEditContactClick}>{contact.background_info}</a> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add background information')}</a>}
                />
                <ProfileDetailItem
                  label={t('Contact::Birthdate')}
                  value={contact.birthdate ? <a href='javascript://' onClick={this.onEditContactClick}>{moment(contact.birthdate).format(setting.date_format)}</a> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add birthdate')}</a>}
                />
              </>}
              {contact.type === ContactType.COMPANY && <ProfileDetailItem
                label={t('Contact::VAT number')}
                value={contact.vat_number ? <a href='javascript://' onClick={this.onEditContactClick}>{contact.vat_number}</a> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add VAT number')}</a>}
              />}

              <ProfileDetailItem
                label={t('Contact::Emails')}
                value={this.renderEmailDetails()}
              />
              <ProfileDetailItem
                label={t('Contact::Telephone numbers')}
                value={this.renderPhoneNumberDetails()}
              />
              <ProfileDetailItem
                label={t('Contact::Account numbers')}
                value={this.renderAccountNumberDetails()}
              />
              <ProfileDetailItem
                label={t('Contact::Websites')}
                value={this.renderWebsiteDetails()}
              />
              <ProfileDetailItem
                label={t('Contact::Billing address')}
                value={contact?.billing_address?.street ?
                  <DetailItem>
                    <a href='javascript://' onClick={this.onEditContactClick}>{ContactHelper.getFullAddress(contact.billing_address)}</a>
                    <CopyAction
                      onClick={() => {
                        copy(ContactHelper.getFullAddress(contact.billing_address))
                        Notification.notifySuccess(t('Contact::Copied to clipboard'))
                      }}
                    >
                      <Icon icon='copy' />
                    </CopyAction>
                    <div />
                  </DetailItem> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add billing address')}</a>}
              />
              <ProfileDetailItem
                label={t('Contact::Delivery address')}
                value={(contact.delivery_same_as_invoice_address ? contact?.billing_address?.street : contact?.delivery_address?.street) ?
                  <DetailItem>
                    <a href='javascript://' onClick={this.onEditContactClick}>{ContactHelper.getFullAddress(contact.delivery_same_as_invoice_address ? contact.billing_address : contact.delivery_address)}</a>
                    <CopyAction
                      onClick={() => {
                        copy(ContactHelper.getFullAddress(contact.delivery_same_as_invoice_address ? contact.billing_address : contact.delivery_address))
                        Notification.notifySuccess(t('Contact::Copied to clipboard'))
                      }}
                    >
                      <Icon icon='copy' />
                    </CopyAction>
                    <div />
                  </DetailItem> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add delivery address')}</a>}
              />
              <ProfileDetailItem
                label={t('Contact::Referral source')}
                value={contact.referral_source ? <Badge text={contact.referral_source.name} type='grey' /> : <a href='javascript://' onClick={this.onEditContactClick}>{t('Contact::Add source')}</a>}
              />

              <ProfileDetailItem
                label={t('Contact::Notes')}
                value={contact.notes?.length > 0 ? <FroalaEditorView model={contact.notes} /> : <a href='javascript://' onClick={this.onEditNotesClick}>{t('Contact::Add notes')}</a>}
              />

              {contact?.peppol_id && <ProfileDetailItem
                label={<>
                  {t('Contact::Peppol ID')}
                  <Tooltip
                    content={t('ContactModal::Address of the contact on the Peppol network.')}
                    containerStyle={{ marginLeft: 8 }}
                  />
                </>}
                value={<a href='javascript://' onClick={this.onEditInvoicesClick}>{contact?.peppol_id}</a>}
              />}
            </DetailsList>
          </Panel>
        </div>

        <div className='grid-cell with-8col'>
          <div className='grid'>
            {contact.type === ContactType.COMPANY && <div className='grid-cell with-12col'>
              <ContactCards contactId={contact.id} />
            </div>}

            <div className='grid-cell with-12col'>
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <SummaryWithContent
                activeKey={activeInvoiceSummaryKey}
                items={[
                  {
                    key: 'pending',
                    label: t('Contact::Draft'),
                    value: NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, invoice_summary.pending),
                    valueClass: invoice_summary.pending > 0 ? 'is-primary' : '',
                    content: (
                      <SummaryInvoiceTable
                        contactId={contact.id}
                        type='pending'
                        currentUser={currentUser}
                      />
                    )
                  },
                  {
                    key: 'outstanding',
                    label: t('Contact::Outstanding'),
                    value: NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, invoice_summary.outstanding),
                    valueClass: invoice_summary.outstanding > 0 ? 'is-warning' : '',
                    content: (
                      <SummaryInvoiceTable
                        contactId={contact.id}
                        type='outstanding'
                        currentUser={currentUser}
                      />
                    )
                  },
                  {
                    key: 'overdue',
                    label: t('Contact::Overdue'),
                    value: NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, invoice_summary.overdue),
                    valueClass: invoice_summary.overdue > 0 ? 'is-danger' : '',
                    content: (
                      <SummaryInvoiceTable
                        contactId={contact.id}
                        type='overdue'
                        currentUser={currentUser}
                      />
                    )
                  },
                  {
                    key: 'paid',
                    label: t('Contact::Paid'),
                    value: NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, invoice_summary.paid),
                    valueClass: invoice_summary.paid > 0 ? 'is-success' : '',
                    content: (
                      <SummaryInvoiceTable
                        contactId={contact.id}
                        type='paid'
                        currentUser={currentUser}
                      />
                    )
                  },
                ]}
                onItemClick={this.onInvoiceSummaryItemClick}
              />}
            </div>

            <div className='grid-cell with-12col'>
              <Panel title={t('Contact::Activity')}>
                <ButtonGroup
                  items={[
                    { element: <AddActivityButton><Icon icon='envelope' /><span>{t('Contact::Email')}</span></AddActivityButton>, onClick: this.onEmailClick, visible: true },
                    { element: <AddActivityButton><Icon icon='calendar-day' /><span>{t('Contact::Appointment')}</span></AddActivityButton>, onClick: this.onScheduleAppointmentClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CALENDAR) },
                    { element: <AddActivityButton><Icon icon='phone' /><span>{t('Contact::Call')}</span></AddActivityButton>, onClick: this.onLogCallClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CALENDAR) },
                    { element: <AddActivityButton><Icon icon='tasks' /><span>{t('Contact::Task')}</span></AddActivityButton>, onClick: this.onLogTaskClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) }
                  ]}
                />

                <ActivityFeedContainer>
                  <ActivityFeed
                    ref={this.activityFeed}
                    trackableType={ActivityTrackableType.CLIENT}
                    trackableId={contact.id}
                    history={this.props.history}
                  />
                </ActivityFeedContainer>
              </Panel>
            </div>

            {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) && <div className='grid-cell with-12col'>
              <Panel title={t('Contact::Files')} contentClasses='no-padding'>
                <ResourceFiles
                  ref={this.resourceFiles}
                  resourceId={contact.id}
                  resourceType={BizzeyFileResourceType.CONTACT}
                />
              </Panel>
            </div>}

            {contact?.billing_address?.street?.length > 0 && <div className='grid-cell with-12col'>
              <Panel
                title={t('Contact::Address')}
                headerRight={<PanelAction icon='pen' onClick={this.onEditContactClick} />}
                contentClasses='no-padding'
              >
                <Map location={ContactHelper.getFullAddress(contact.billing_address)} />
              </Panel>
            </div>}

            {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK) && <div className='grid-cell with-12col'>
              <Panel
                title={t('Contact::Playbooks')}
                contentClasses='no-padding'
              >
                <PlaybooksTable
                  playbookableId={contact.id}
                  playbookableType={PlaybookableType.CONTACT}
                  onPlaybookClick={this.onPlaybookClick}
                  primaryActionMode='manage'
                  primaryActionEnabled
                  maxHeight='25vh'
                />
              </Panel>
            </div>}
          </div>
        </div >
      </div >
    )
  }

  onPlaybookClick(playbook: Playbook) {
    const { contact } = this.state

    this.props.showPlaybookSubmissionModal({
      playbookSubmission: {
        playbook_id: playbook.id,
        playbookable_id: contact.id,
        playbookable_type: PlaybookableType.CONTACT,
        answers: []
      },
      onSubmit: (playbookSubmission, call, task) => {
        if (this.activityFeed?.current && (call || task)) this.activityFeed.current.refetch()
      },
    })
  }

  render() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { didInitialLoad, contact } = this.state

    const hasContactDetails = contact?.emails?.length > 0 || contact?.telephone_numbers?.length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        <TopNavigation
          icon='user'
          title={t('Contact::Contact')}
        />
        <ScrollToTopOnMount />

        <PageContent>
          {!didInitialLoad && <PageLoader />}

          {didInitialLoad && <>
            <PageHeader
              title={t('Contact::Contact')}
              breadcrumbs={[
                { content: t('Contact::Back'), url: 'javascript://', onClick: this.onBreadCrumbBackPress }
              ]}
              mainActions={[
                { key: 'archive', icon: 'archive', content: contact.archived ? t('Contact::Unarchive') : t('Contact::Archive') },
                { key: 'edit', icon: 'edit-solid', content: t('Contact::Edit') },
                { key: 'delete', icon: 'trash', content: t('Contact::Delete'), destructive: true }
              ]}
              onMainActionClick={this.onPageHeaderActionClick}
            />

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='panel'>
                  <div className='contact-header'>
                    <div className='contact-header-avatar'>
                      <ContactTypeAvatar
                        contact={contact}
                        width={80}
                        onClick={() => { }}
                      />
                    </div>

                    <div className='contact-header-details'>
                      <h2>
                        <a href='javascript://' onClick={this.onEditContactClick}>
                          {contact.name}
                        </a>

                        <HeaderDetailIcons>
                          <HeaderDetailsIcon onClick={this.onToggleStarredClick} starred={contact.starred} data-tip={contact.starred ? t('Contact::Remove contact from quick select') : t('Contact::Add contact to quick select')}>
                            {contact.starred ? <Icon icon='star' /> : <Icon icon='star-empty' />}
                          </HeaderDetailsIcon>

                          {contact.archived && <HeaderDetailsIcon onClick={this.onArchiveContactClick} starred={false} data-tip={t('Contact::Unarchive contact')}>
                            <Icon icon='archive' />
                          </HeaderDetailsIcon>}
                        </HeaderDetailIcons>
                      </h2>
                      {contact?.type === ContactType.PERSON && contact?.contact_id && <WorksAtContainer>
                        {t('Contact::Works at')}
                        <Link to={RouteHelper.process(ERoute.PATH_CONTACT, { id: contact.contact_id })}>
                          {contact?.contact?.name}
                        </Link>
                        {contact?.job_title?.length > 0 && <span style={{ marginLeft: 4 }}> - {contact?.job_title}</span>}
                      </WorksAtContainer>}
                      {!hasContactDetails && <a href='javascript://' onClick={this.onEditContactsClick}>{t('Contact::Add contact details')}</a>}
                      {hasContactDetails && <HeaderContactDetails>
                        {contact?.emails?.length > 0 && <a href={`mailto:${contact.emails[0].value}`}>
                          <Icon icon='email' />
                          {contact.emails[0].value}
                        </a>}
                        {contact?.telephone_numbers?.length > 0 && <a href={`tel:${contact.telephone_numbers[0].value}`}>
                          <Icon icon='phone' />
                          {contact.telephone_numbers[0].value}
                        </a>}
                      </HeaderContactDetails>}
                      {contact?.groups?.length > 0 && <Groups style={{ marginTop: 8, marginBottom: 8 }}>
                        {contact?.groups?.sort((g1, g2) => (g1.name.localeCompare(g2.name))).map((group, index) => {
                          return (
                            <Badge
                              key={group.id}
                              type='grey'
                              text={group.name}
                            />
                          )
                        })}
                      </Groups>}
                    </div>

                    <AttachItemsContainer>
                      <AttachItem
                        items={[
                          { icon: 'calendar-day', iconBackground: '#FACDCD', iconColor: '#BA2525', label: t('Contact::Event'), onClick: this.onNewCalendarEventClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CALENDAR) },
                          { icon: 'tasks', iconBackground: '#C6F7E9', iconColor: '#17B897', label: t('Contact::Task'), onClick: this.onNewTaskClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) },
                          { icon: 'tag', iconBackground: '#FFD3BA', iconColor: '#C65D21', label: t('Contact::Group'), onClick: this.onEditContactClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTACT) },
                          { icon: 'attachment', iconBackground: '#FAB8D9', iconColor: '#C42D78', label: t('Contact::File'), onClick: this.onAddAttachmentClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) },
                          { icon: 'paper-plane', iconBackground: '#e9e7fb', iconColor: '#3d1db2', label: t('Contact::Sequence'), onClick: this.onAddSequenceClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.SEQUENCE) },
                        ]}
                      />
                    </AttachItemsContainer>
                  </div>

                  <div className='contact-header-navigation'>
                    <div className='navigation-tabs'>
                      <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Details')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.TASKS) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_TASKS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Work overview')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.DEALS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_DEALS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Deals')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.PROJECTS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_PROJECTS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Projects')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.TIME_TRACKING) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_TIMESHEETS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Timesheets')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.order_forms_enabled && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_ORDER_FORMS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Order forms')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.delivery_notes_enabled && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_DELIVERY_NOTES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Delivery notes')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_QUOTATIONS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Quotations')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.pro_forma_invoices_enabled && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_PRO_FORMA_INVOICES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Proforma invoices')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_INVOICES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Invoices')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.EXPENSES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_PURCHASE_INVOICES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Purchase invoices')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_CREDIT_NOTES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Credit note\'s')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_RECURRING_INVOICES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Recurring invoices')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.EXPENSES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_EXPENSES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Expenses')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.PROPOSALS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROPOSAL) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_PROPOSALS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Proposals')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.CONTRACTS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTRACT) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_CONTRACTS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Contracts')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {false && SettingHelper.hasNavigationitem(NavigationMenuItemPage.TASKS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_BOARDS, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Boards')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.FILES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_CONTACT_FILES, { id: contact.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Contact::Files')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Switch>
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_TASKS)} component={(props) => <TaskView {...props} contactId={contact?.id} mode={TaskViewMode.WORK_OVERVIEW} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_BOARDS)} component={(props) => <BoardsTable {...props} contactId={contact.id} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_DEALS)} component={(props) => <DealsTable {...props} contactId={contact.id} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_PROJECTS)} component={(props) => <ContactProjects {...props} contactId={contact.id} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_TIMESHEETS)} component={(props) => <ContactTimesheets contactId={contact.id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_ORDER_FORMS)} component={(props) => <ContactLedgerItems {...props} type={LedgerItemType.ORDER_FORM} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_DELIVERY_NOTES)} component={(props) => <ContactLedgerItems {...props} type={LedgerItemType.DELIVERY_NOTE} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROPOSAL) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_PROPOSALS)} component={(props) => <ContactProposals contactId={contact.id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_QUOTATIONS)} component={(props) => <ContactLedgerItems {...props} type={LedgerItemType.QUOTATION} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_PRO_FORMA_INVOICES)} component={(props) => <ContactLedgerItems {...props} type={LedgerItemType.PRO_FORMA_INVOICE} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_INVOICES)} component={(props) => <ContactLedgerItems {...props} type={LedgerItemType.INVOICE} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_PURCHASE_INVOICES)} component={(props) => <ContactPurchaseInvoices supplierId={contact.id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_CREDIT_NOTES)} component={(props) => <ContactLedgerItems {...props} type={LedgerItemType.CREDIT_NOTE} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_RECURRING_INVOICES)} component={(props) => <ContactLedgerItems {...props} type={LedgerItemType.RECURRING_INVOICE} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTRACT) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_CONTRACTS)} component={(props) => <ContactContracts contactId={contact.id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_EXPENSES)} component={(props) => <ContactExpenses contactId={contact.id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) && <Route path={RouteHelper.process(ERoute.PATH_CONTACT_FILES)} component={(props) => <FileBrowser maxHeight='61vh' resourceType={BizzeyFileResourceType.CONTACT} resourceId={contact.id} />} />}
              <Route path={RouteHelper.process(ERoute.PATH_CONTACT)} component={this.renderContactView} />
            </Switch>
          </>}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showContactModal: (options) => dispatch(showContactModal(options)),
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showCalendarEventModal: (options) => dispatch(showCalendarEventModal(options)),
    showTaskModal: (options) => dispatch(showTaskModal(options)),
    showPlaybookSubmissionModal: (options) => dispatch(showPlaybookSubmissionModal(options)),
    showFileUploadModal: (options) => dispatch(showFileUploadModal(options)),
    showSendEmailModal: (options) => dispatch(showSendEmailModal(options)),
    showContactEnrollmentModal: (options) => dispatch(showContactEnrollmentModal(options)),
    showCallModal: (options) => dispatch(showCallModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactShow))