import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components'
import ContentBlockHelper from '../../helpers/ContentBlockHelper'
import { showImportProductsModal, showItemsBlockSettingModal, showQuestionAndAnswerBlockSettingModal } from '../../store/modals/actions'
import { Style } from '../../styles'
import { ContentBlock, ContentBlockTemplate, ContentBlockType, ContentBlockVariables, ItemBlock, ItemsBlock, ItemsSelectionRequirementType, ItemsSelectionType, ItemsView, NumberFormat, QuestionAndAnswerBlock, QuestionAndAnswerType, SearchableContentBlock, WorkspaceTax } from '../../types'
import Button from '../Button/Button'
import ButtonDefault from '../Button/ButtonDefault'
import Icon, { IconTypes } from '../Icons/Icon'
import ModalFooterActionIcon from '../Modals/Parts/ModalFooterAction'
import BlockInputs from './InputBlocks/BlockInputs'
import Utils from '../../utilities/Utils'

const Container = styled.div`
	margin-top: ${Style.spacing.x1};
	margin-bottom: ${Style.spacing.x1};
`

const ToggleButton = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${Style.spacing.x1};
	cursor: pointer;

	svg {
		width: 18px;
		height: 18px;
	}

	i {
		font-size: 18px;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`

const Actions = styled.div`
	display: flex;
	flex-direction: column;
`

const Action = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10.5px;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	background: white;
	cursor: pointer;
	min-width: 38px;
	min-height: 38px;
	width: fit-content;

	svg {
		width: 15px;
		height: 15px;
	}

	i {
		font-size: 15px;
	}

	&:not(:last-child) {
		margin-bottom: ${Style.spacing.x0_5};
	}

	&:hover {
		border-color: #b6c5d1;
	}
`

const SearchContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;

	input {
		min-width: 145px;
		padding-right: 30px !important;
	}
`

const SearchClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 7px;
	top: 50%;
	transform: translateY(-50%);
	color: rgba(0,0,0,0.6);
	cursor: pointer;
	z-index: 1;

	&:hover {
		color: black;
	}

	svg {
		width: 20px;
		color: currentColor;
	}
`

const BlockItems = styled.div`
	display: flex;
	flex-direction: row;
	overflow: auto;
	width: 100%;
	margin-left: ${Style.spacing.x1};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		padding-right: ${Style.spacing.x1};
		padding-bottom: ${Style.spacing.x1_25};
	}
`

const BlockItemContainer = styled.div`
	position: relative;

	&:not(:last-child) {
		margin-right: ${Style.spacing.x1};
	}
`

const BlockItem = styled.div<{ disabled: boolean }>`
	position: relative;
	display: flex;
	overflow: hidden;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	min-width: 86px;
	height: 80px;
	width: 80px;
	padding: ${Style.spacing.x1};
	transition: none;
	text-align: center;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	background-color: white;
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover {
		border-color: #b6c5d1;
	}

	${(props) => props.disabled && css`
		opacity: .6;
		cursor: not-allowed;
	`}
`

const BlockItemEdit = styled.div`
 	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2px;
	border-left: 1px solid ${Style.color.border};
	border-bottom: 1px solid ${Style.color.border};
	border-top-right-radius: 5px;
	width: 20px;
	height: 20px;
	border-bottom-left-radius: 5px;
	color: rgba(0,0,0,0.6);
	z-index: 1;
	cursor: pointer;

	&:hover {
		color: black;
	}

	svg {
		width: 11px;
		fill: currentColor;
		color: currentColor;
	}

	i {
		font-size: 11px;
	}
`

const BlockItemIcon = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;

	svg {
		width: 25px;
		height: 25px;
	}

	i {
		font-size: 25px;
	}
`

const BlockItemLabel = styled.div`
	width: 80px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: ${Style.spacing.x0_5};
`

const ActiveAddBlockContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const BlockTemplateForm = styled.form`
	position: relative;
	margin-top: ${Style.spacing.x2};
	margin-bottom: ${Style.spacing.x1};
	padding: ${Style.spacing.x1};
	background: white;
	border-radius: 4px;
	border: 1px solid ${Style.color.border};

	hr {
		margin-left: -${Style.spacing.x1};
		margin-right: -${Style.spacing.x1};
	}
`

const BlockTemplateFormBlockWrapper = styled.div`
	margin-left: -${Style.spacing.x1};
	margin-right: -${Style.spacing.x1};
`

const BlockTemplateFormActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: ${Style.spacing.x1};
`

interface IDispatchToProps {
	showItemsBlockSettingModal: typeof showItemsBlockSettingModal
	showImportProductsModal: typeof showImportProductsModal
	showQuestionAndAnswerBlockSettingModal: typeof showQuestionAndAnswerBlockSettingModal
}

type IProps = {
	types: ContentBlockType[]
	blocks: ContentBlock[]
	blockTemplates: ContentBlockTemplate[]
	variables: ContentBlockVariables
	currency: string
	numberFormat: NumberFormat
	taxes: WorkspaceTax[]
	onAddBlockClick: (block: ContentBlock) => void,
	onTemplateDeleteClick: (contentBlockTemplate: ContentBlockTemplate, success?: () => void) => void
	onTemplateFormSubmit: (contentBlockTemplate: ContentBlockTemplate, success?: () => void) => void
} & IDispatchToProps

interface IState {
	active: boolean
	searchActive: boolean
	searchValue: string
	editableContentBlockTemplate: ContentBlockTemplate
}
const DEFAULT_STATE: IState = {
	active: false,
	searchActive: false,
	searchValue: '',
	editableContentBlockTemplate: null,
}

const AddBlock = (props: IProps) => {
	const {
		types,
		blocks,
		blockTemplates,
		showItemsBlockSettingModal,
		showQuestionAndAnswerBlockSettingModal,
		currency,
		numberFormat,
		variables,
		taxes,
	} = props
	const { t } = useTranslation()
	const [state, setState] = React.useState<IState>(DEFAULT_STATE)
	const { active, searchActive, searchValue, editableContentBlockTemplate } = state

	React.useEffect(() => { ReactTooltip.rebuild() })

	const searchableContentBlocks: SearchableContentBlock[] = ContentBlockHelper.getContentBlocksFromSearch(searchValue, [
		...types.map(type => ({ name: t(`ContentBlockType::${type}`), contentBlock: { type: type } })),
		...blockTemplates.map(blockTemplate => ({ name: blockTemplate.name, contentBlock: blockTemplate.content_block, contentBlockTemplate: blockTemplate })),
	])

	const onActivateBlockClick = () => {
		setState({ ...state, active: true, searchValue: '' })
	}

	const onDeactivateBlockClick = () => {
		setState({ ...DEFAULT_STATE })
	}

	const onAddBlockClick = (type: ContentBlockType) => {
		setState({ ...DEFAULT_STATE })

		switch (type) {
			case ContentBlockType.TEXT:
				props.onAddBlockClick({
					type: ContentBlockType.TEXT,
					content: '',
				})
				break
			case ContentBlockType.IMAGE:
				props.onAddBlockClick({
					type: ContentBlockType.IMAGE,
				})
				break
			case ContentBlockType.PDF:
				props.onAddBlockClick({
					type: ContentBlockType.PDF,
				})
				break
			case ContentBlockType.INTRO:
				props.onAddBlockClick({
					type: ContentBlockType.INTRO,
				})
				break
			case ContentBlockType.ITEMS:
				requestAnimationFrame(() => {
					showItemsBlockSettingModal({
						block: {
							type: ContentBlockType.ITEMS,
							view: ItemsView.TABLE,
							contact_selection_enabled: true,
							selection_type: ItemsSelectionType.MULTI,
							selection_requirement_type: ItemsSelectionRequirementType.REQUIRED,
							items: [
								{ id: Utils.uuid(), quantity: 1, amount: 0, selected: true }
							]
						},
						onSubmit: (block: ItemsBlock) => {
							props.onAddBlockClick(block)
						}
					})
				})
				break
			case ContentBlockType.SUMMARY:
				props.onAddBlockClick({
					type: ContentBlockType.SUMMARY,
				})
				break
			case ContentBlockType.INPUT:
				props.onAddBlockClick({
					type: ContentBlockType.TEXT_INPUT,
				})
				break
			case ContentBlockType.QUESTION_AND_ANSWER:
				requestAnimationFrame(() => {
					showQuestionAndAnswerBlockSettingModal({
						block: {
							type: ContentBlockType.QUESTION_AND_ANSWER,
							title: '',
							required: false,
							answer_type: QuestionAndAnswerType.OPEN,
							options: [],
							allow_multiple_selection: false,
						},
						onSubmit: (block: QuestionAndAnswerBlock) => {
							props.onAddBlockClick(block)
						}
					})
				})
				break
			default: throw Error(`[onAddBlockClick] Unknown block type ${type}`)
		}
	}

	const onAddBlockFromTemplateClick = (block: ContentBlock) => {
		setState({ ...DEFAULT_STATE })
		props.onAddBlockClick({ ...block, id: null })
	}

	const onSearchClick = () => {
		setState({ ...state, searchActive: true, searchValue: '' })
	}

	const onSearchChange = (e) => {
		setState({ ...state, searchValue: e.currentTarget.value })
	}

	const onCloseSearchClick = () => {
		setState({ ...state, searchActive: false, searchValue: '' })
	}

	const getIconForType = (type: ContentBlockType): IconTypes => {
		switch (type) {
			case ContentBlockType.TEXT:
				return 'pencil'
			case ContentBlockType.IMAGE:
				return 'images'
			case ContentBlockType.INTRO:
				return 'handshake'
			case ContentBlockType.ITEMS:
				return 'tag'
			case ContentBlockType.SUMMARY:
				return 'calculator'
			case ContentBlockType.INPUT:
				return 'input'
			case ContentBlockType.PDF:
				return 'pdf'
			case ContentBlockType.SIGN:
				return 'file-contract'
			case ContentBlockType.QUESTION_AND_ANSWER:
				return 'question-circle'
			case undefined:
				return 'file-lines'
			default:
				return 'file-lines'
		}
	}

	const onTemplateNameChange = (e) => {
		const templateName = e.currentTarget.value

		setState({
			...state,
			editableContentBlockTemplate: {
				...editableContentBlockTemplate,
				name: templateName,
			}
		})
	}


	const onEditTemplateClick = (blockTemplate: ContentBlockTemplate) => {
		setState({ ...state, editableContentBlockTemplate: blockTemplate })
		// props.onEditTemplateClick(blockTemplate)
	}

	const onTemplateBlockChange = (block: ContentBlock) => {
		setState({
			...state,
			editableContentBlockTemplate: {
				...editableContentBlockTemplate,
				content_block: block
			}
		})
	}

	const onTemplateImportProductsClick = () => {
		requestAnimationFrame(() => {
			props.showImportProductsModal((products) => {
				products.forEach((importedProduct) => {
					const { quantity, product } = importedProduct
					if (editableContentBlockTemplate.content_block.type === ContentBlockType.ITEMS) {
						editableContentBlockTemplate.content_block.items.push({
							quantity: quantity,
							amount: Number(product.price),
							title: product.name,
							description: product.description,
							tax_rate: Number(product.tax_rate),
						})
					}
				})

				setState({
					...state,
					editableContentBlockTemplate: { ...editableContentBlockTemplate }
				})
			})
		})
	}

	const onTemplateSettingsBlockClick = () => {
		if (editableContentBlockTemplate.content_block.type === ContentBlockType.ITEMS) {
			requestAnimationFrame(() => {
				props.showItemsBlockSettingModal({
					block: editableContentBlockTemplate.content_block as ItemsBlock,
					onSubmit: (itemBlock: ItemsBlock) => {
						setState({
							...state,
							editableContentBlockTemplate: {
								...editableContentBlockTemplate,
								content_block: itemBlock
							}
						})
					}
				})
			})
		}
	}

	const onTemplateDeleteClick = () => {
		props.onTemplateDeleteClick(editableContentBlockTemplate, () => {
			setState({ ...state, editableContentBlockTemplate: null })
		})
	}

	const onTemplateFormCancelClick = () => {
		setState({ ...state, editableContentBlockTemplate: null })
	}
	const onTemplateFormSubmitClick = (e) => {
		if (e) e.preventDefault()
		// Submit form to parent

		props.onTemplateFormSubmit(editableContentBlockTemplate, () => {
			setState({ ...state, editableContentBlockTemplate: null })
		})
	}

	const renderBlockTemplateForm = () => {
		const contentBlock = editableContentBlockTemplate.content_block

		return (
			<BlockTemplateForm onSubmit={onTemplateFormSubmitClick}>
				<div className='form-item'>
					<label>{t('AddBlock::Template name')} <span>*</span></label>
					<input
						type='text'
						value={editableContentBlockTemplate.name}
						onChange={onTemplateNameChange}
						required
					/>
				</div>

				<hr />

				<BlockTemplateFormBlockWrapper>
					<BlockInputs
						block={contentBlock}
						currency={currency}
						numberFormat={numberFormat}
						variables={variables}
						taxes={taxes}
						items={contentBlock.type === ContentBlockType.ITEMS ? [contentBlock] : []}
						onBlockChange={onTemplateBlockChange}
						onImportProductsClick={onTemplateImportProductsClick}
						onSettingsBlockClick={onTemplateSettingsBlockClick}
					/>
				</BlockTemplateFormBlockWrapper>

				<hr />

				<BlockTemplateFormActions>
					<div>
						<ModalFooterActionIcon
							icon='trash'
							active={false}
							onClick={onTemplateDeleteClick}
							tooltip={t('ContentBlockTemplateModal::Delete template')}
						/>
					</div>

					<div>
						<ButtonDefault onClick={onTemplateFormCancelClick} style={{ marginRight: Style.spacing.x1 }}>
							{t('AddBlock::Cancel')}
						</ButtonDefault>
						<Button
							type='success'
							text={t('AddBlock::Save')}
							onClick={onTemplateFormSubmitClick}
						/>
					</div>
				</BlockTemplateFormActions>
				<input type='submit' style={{ display: 'none' }} />
			</BlockTemplateForm>
		)
	}

	return (
		<Container>
			{!active && <ToggleButton onClick={onActivateBlockClick}>
				<Icon icon='plus-circle' />
			</ToggleButton>}
			{active && <ActiveAddBlockContainer>
				<Content>
					<Actions>
						<Action onClick={onDeactivateBlockClick}>
							<Icon icon='close' />
						</Action>
						{!searchActive && <Action onClick={onSearchClick}>
							<Icon icon='search' />
						</Action>}
						{searchActive && <SearchContainer>
							<input
								type='text'
								placeholder={t('AddBlock::Search...')}
								value={searchValue}
								onChange={onSearchChange}
								autoFocus={true}
							/>
							<SearchClose onClick={onCloseSearchClick} style={{ marginLeft: 4 }}>
								<Icon icon='circle-xmark' />
							</SearchClose>
						</SearchContainer>}
					</Actions>

					<BlockItems>
						{searchableContentBlocks.map((searchableContentBlock, index) => {
							const { name, contentBlock, contentBlockTemplate } = searchableContentBlock
							let disabled = false
							let dataTip = ''
							let label = contentBlockTemplate ? name : t(`ContentBlockType::${contentBlock?.type}`)
							let onClick = undefined

							switch (contentBlock?.type) {
								case ContentBlockType.INTRO:
									const introBlockPresent = blocks.some(block => block.type === ContentBlockType.INTRO)
									if (introBlockPresent) disabled = true
									dataTip = t('AddBlock::The intro block can only be added once.')
									break
								case ContentBlockType.SUMMARY:
									const summaryBlockPresent = blocks.some(block => block.type === ContentBlockType.SUMMARY)
									if (summaryBlockPresent) disabled = true
									dataTip = t('AddBlock::The summary block can only be added once.')
									break
							}

							if (contentBlockTemplate) {
								onClick = disabled ? undefined : () => onAddBlockFromTemplateClick(contentBlock as ContentBlock)
							} else {
								onClick = disabled ? undefined : () => onAddBlockClick(contentBlock.type)
							}

							return (
								<BlockItemContainer key={index}>
									<BlockItem
										onClick={onClick}
										disabled={disabled}
										data-tip={dataTip}
										data-tip-disable={!disabled}
									>
										<BlockItemIcon>
											<Icon icon={getIconForType(contentBlock?.type)} />
										</BlockItemIcon>

										<BlockItemLabel>{label}</BlockItemLabel>
									</BlockItem>
									{contentBlockTemplate && <BlockItemEdit onClick={() => onEditTemplateClick(contentBlockTemplate)} data-tip={t('AddBlock::Edit')}>
										<Icon icon='edit' />
									</BlockItemEdit>}
								</BlockItemContainer>
							)
						})}
					</BlockItems>
				</Content>

				{editableContentBlockTemplate && renderBlockTemplateForm()}
			</ActiveAddBlockContainer>}
		</Container>
	)
}

const mapDispatchToProps: IDispatchToProps = {
	showItemsBlockSettingModal,
	showImportProductsModal,
	showQuestionAndAnswerBlockSettingModal,
}

export default connect(null, mapDispatchToProps)(AddBlock)