import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import { TimeEntry } from "../../types"

const Container = styled.div`
	width: 20px;
	height: 20px;
	background-color: ${Style.color.brandSuccess};
	border-radius: 50%;
`

interface IProps {
	timeEntry: TimeEntry
}


const TimeEntryBillableIndicator = (props: IProps) => {
	const { t } = useTranslation()
	const { timeEntry } = props

	let tooltip = ''
	let backgroundColor = 'grey'

	if (timeEntry.billable) {
		if (timeEntry.billed) {
			backgroundColor = Style.color.brandSuccess
			tooltip = t('TimeEntryBillableIndicator::Billed')
		} else {
			backgroundColor = Style.color.brandWarning
			tooltip = t('TimeEntryBillableIndicator::Unbilled')
		}
	} else {
		backgroundColor = 'grey'
		tooltip = t('TimeEntryBillableIndicator::Unbillable')
	}

	return (
		<Container
			style={{ backgroundColor: backgroundColor }}
			data-tip={tooltip}
		/>
	)
}

export default TimeEntryBillableIndicator