import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Style.spacing.x2};
  background: ${Style.color.white};
  border-radius: ${Style.variables.baseBorderRadius};
  border: 1px solid ${Style.color.border};
`

interface IProps {
  children: React.ReactNode | React.ReactNode[]
}

const StepContainer = (props: IProps) => {
  const { children } = props

  return (
    <Container>
      {children}
    </Container>
  )
}


export default StepContainer