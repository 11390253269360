import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'
import { Contact } from '../../types'
import ContactCard from './ContactCard'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { showContactModal } from '../../store/modals/actions'
import { AppState } from '../../store'
import { ContactsController } from '../../controllers'
import PageLoader from '../Page/PageLoader'
import ContactAddCard from './ContactAddCard'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  flex: 1;
  margin-bottom: ${Style.spacing.x1};
  padding-bottom: ${Style.spacing.x1};

  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }

  > div:not(:first-child) {
    width: calc(50% - ${Style.spacing.x1});
    min-width: calc(50% - ${Style.spacing.x1});
    max-width: calc(50% - ${Style.spacing.x1});
    margin-right: ${Style.spacing.x1};

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      width: calc(100%);
      min-width: calc(100%);
      max-width: calc(100%);
    }

    &:last-child {
      width: calc(50% - ${Style.spacing.x1});
      min-width: calc(50% - ${Style.spacing.x1});
      max-width: calc(50% - ${Style.spacing.x1});
      margin-right: 0;

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        width: calc(100%);
        min-width: calc(100%);
        max-width: calc(100%);
      }
    }
  }
`

const LoadContainer = styled.div`
  width: 100% !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface IStateToProps { }

interface IDispatchToProps {
  showContactModal: typeof showContactModal
}

type IProps = { contactId: string } & IStateToProps & IDispatchToProps
interface IState {
  contacts: Contact[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  reachedEnd: boolean
  isFetching: boolean
  searchValue: string
}

class ContactCards extends React.Component<IProps, IState> {
  private container = React.createRef<HTMLDivElement>()

  constructor(props) {
    super(props)

    this.state = {
      contacts: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      reachedEnd: false,
      isFetching: false,
      searchValue: ''
    }

    this.onAddContactClick = this.onAddContactClick.bind(this)
    this.onEditContactsClick = this.onEditContactsClick.bind(this)
    this.onListScroll = this.onListScroll.bind(this)
  }

  componentDidMount(): void {
    this.fetchContacts(1)
  }

  async fetchContacts(page: number) {
    const { contactId } = this.props
    const { searchValue, contacts: stateContacts } = this.state
    this.setState({
      isFetching: true
    }, async () => {
      try {
        const response = await ContactsController.getContacts({
          page: page,
          search: searchValue,
          contact_id: contactId,
        })
        const { contacts, current_page, total_pages, total_entries } = response;

        this.setState({
          contacts: [...stateContacts, ...contacts],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
          reachedEnd: current_page === total_pages
        });
      } catch (ex) {
        console.error(ex)
      }
    });
  }

  onListScroll(e) {
    const { reachedEnd, isFetching } = this.state
    const container = this.container.current
    const scrollLeft = container.scrollLeft
    const scrollWidth = container.scrollWidth
    const clientWidth = container.clientWidth
    const scrollEndReached = Math.abs(scrollLeft) === scrollWidth - clientWidth

    if (scrollEndReached && !reachedEnd && !isFetching) {
      const { currentPage } = this.state
      this.fetchContacts(currentPage + 1)
    }
  }

  onAddContactClick() {
    const { contactId } = this.props
    const { contacts } = this.state

    this.props.showContactModal({
      contact: {
        contact_id: contactId
      },
      onSubmit: (contact) => {
        if (contact.contact_id === contactId) {
          // Scroll back to beginning
          this.container.current.scrollLeft = 0
          this.setState({ contacts: [contact, ...contacts] })
        }
      }
    })
  }

  onEditContactsClick(index: number) {
    const { contactId } = this.props
    const { contacts } = this.state
    this.props.showContactModal({
      contact: { id: contacts[index].id },
      onSubmit: (contact) => {
        const updatedContacts = [...contacts]
        if (contact.contact_id === contactId) {
          updatedContacts[index] = contact
        } else {
          updatedContacts.splice(index, 1)
        }

        this.setState({ contacts: updatedContacts })
      }
    })
  }

  render() {
    const { contacts, didInitialLoad } = this.state
    return (
      <Container ref={this.container} onScroll={this.onListScroll}>
        {!didInitialLoad && <LoadContainer>
          <PageLoader />
        </LoadContainer>}
        {didInitialLoad && <>
          <ContactAddCard onAddContactClick={this.onAddContactClick} />
          {contacts.map((contact, index) => {
            return (
              <ContactCard
                key={contact.id}
                contact={contact}
                onEditClick={() => this.onEditContactsClick(index)}
              />
            )
          })}
        </>}
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showContactModal: (options) => dispatch(showContactModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactCards))