import * as React from 'react';
import Badge from "../components/Badge/Badge"
import i18n from "../translations"
import { Proposal, ProposalStatus } from '../types';
import moment from '../utilities/Moment';

export default class ProposalHelper {
  static getBadge(proposal: Proposal) {
    switch (proposal.status) {
      case ProposalStatus.DRAFT: return <Badge type='primary'>{i18n.t(`ProposalStatus::${ProposalStatus.DRAFT}`)}</Badge>
      case ProposalStatus.PENDING: return <Badge type='warning'>{i18n.t(`ProposalStatus::${ProposalStatus.PENDING}`)}</Badge>
      case ProposalStatus.ACCEPTED: return <Badge type='success'>{i18n.t(`ProposalStatus::${ProposalStatus.ACCEPTED}`)}</Badge>
      case ProposalStatus.DECLINED: return <Badge type='danger'>{i18n.t(`ProposalStatus::${ProposalStatus.DECLINED}`)}</Badge>
      case ProposalStatus.CANCELLED: return <Badge type='danger'>{i18n.t(`ProposalStatus::${ProposalStatus.CANCELLED}`)}</Badge>
    }
  }

  static expired(proposal: Proposal) {
    if (!proposal.expires_on) return false

    return moment().isAfter(moment(proposal.expires_on), 'day')
  }

  static signable(proposal: Proposal) {
    return proposal.status === ProposalStatus.PENDING && !this.expired(proposal)
  }

  static download(id: string) {
    window.location.assign(`/proposal/${id}/download`)
  }

  static sendable(proposal: Proposal) {
    return proposal.signee_ids.length > 0
  }
}