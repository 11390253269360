import styled from 'styled-components'

export default styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 8px 32px;
	border-radius: 20px;
	cursor: pointer;
	font-weight: 600;

	background: ${props => props.theme.buttonBackground};
	color: ${props => props.theme.buttonText};

	i {
		color: ${props => props.theme.buttonText};
	}

	i, svg {
		margin-right: 4px;
	}
`