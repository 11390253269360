import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { PlannedTask, PlannerDateItem } from '../../types'
import Icon from '../Icons/Icon'
import Popover from '../Popover/Popover'
import moment from '../../utilities/Moment'
import { useTranslation } from 'react-i18next'
import PlannerMeter from './PlannerMeter'
import PlannerItemTaskDetails from './PlannerItemTaskDetails'

const Container = styled.div<{ type: 'calendar_event' | 'planned_task' }>`
  display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
  flex-wrap: wrap;
  height: 30px;
  min-height: 30px;
  border: 1px solid black;
  border-radius: ${Style.variables.baseBorderRadius};
  padding: 2px 5px;
  cursor: pointer;

  ${props => props.type === 'calendar_event' && css`
      background: #EFF5FF !important;
      color: #1449D6 !important;
      border: 1px solid #1449D6 !important;
  `}

  ${props => props.type === 'planned_task' && css`
      background: rgb(254, 243, 199) !important;
      color: rgb(180, 83, 9) !important;
      border: 1px solid rgb(180, 83, 9) !important;
  `}

	svg {
		width: 14px;
		height: 14px;
		fill: currentColor;
	}
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

	svg {
		width: 14px;
		height: 14px;
		fill: currentColor;
	}
`

const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2px;
  flex: 1;
  overflow: hidden;
`

const IconContainer = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
  margin-top: 5px;
`

const Title = styled.div`
  flex: 1;
	max-width: 100%;
	text-overflow: ellipsis;
  white-space: nowrap;
	display: flex;
	align-items: center;
`

const Duration = styled.div``


interface IProps {
  item: PlannerDateItem
  height: number
  dateFormat: string
  onItemClick: (item: PlannerDateItem) => void
  onUpdateClick: (item: PlannerDateItem, plannedTask: PlannedTask) => void
  onRemoveClick: (item: PlannerDateItem) => void
}

interface IState {
  active: boolean
}

const PlannerItem = (props: IProps) => {
  const { t } = useTranslation()
  const [state, setState] = React.useState<IState>({
    active: false
  })
  const { item, height, dateFormat, onItemClick, onUpdateClick, onRemoveClick } = props
  const { active } = state
  const { type } = item

  const activatePopover = () => {
    if (type === 'planned_task') {
      setState({ active: true })
    }

    onItemClick(item)
  }

  const closePopover = () => {
    if (active) setState({ active: false })
  }

  return (
    <Popover
      activator={
        <Container type={item.type} onClick={activatePopover} style={{ height: height }}>
          <Content>
            <ContentTitle>
              <IconContainer>
                <Icon icon={item.type === 'calendar_event' ? 'calendar-day' : 'tasks'} />
              </IconContainer>
              <Title>{item.title}</Title>
            </ContentTitle>

            <Duration>
              {moment.duration(item.duration, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small' })}
            </Duration>
          </Content>
        </Container>
      }
      active={active}
      placement='right-start'
      onClose={closePopover}
      strategy='fixed'
    >
      {active && <PlannerItemTaskDetails
        taskId={item.id}
        dateFormat={dateFormat}
        onUpdateClick={(plannedTask) => {
          onUpdateClick(item, plannedTask)
          closePopover()
        }}
        onRemoveClick={() => {
          onRemoveClick(item)
          closePopover()
        }}
      />}
    </Popover>
  )

}

export default PlannerItem