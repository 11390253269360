import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const MenuEditorItemAction = styled.div<{ type?: 'success' | 'danger' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  width: 18px;
  height: 18px;
  color: rgb(214, 214, 214);
  fill: rgb(214, 214, 214);
  margin-top: ${Style.spacing.x0_5};

  svg {
    width: 18px;
    height: 18px;
  }

  ${props => props.type === 'success' && css`
      color: ${Style.color.brandSuccess};
      fill: ${Style.color.brandSuccess};
  `}

  ${props => props.type === 'danger' && css`
    color: ${Style.color.brandDanger};
    fill: ${Style.color.brandDanger};
  `}

  &:hover {
    ${props => props.type === 'success' && css`
      color: ${Style.color.brandSuccess};
      fill: ${Style.color.brandSuccess};
    `}
  }

  &:not(:last-child) {
    margin-right: ${Style.spacing.x1_5};
  }
`

export default MenuEditorItemAction