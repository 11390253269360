import * as React from 'react'
import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { showConfirmModal } from '../../store/modals/actions';
import { AppState } from '../../store';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import { SequencesController } from '../../controllers';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import Notification from '../../utilities/Notification';
import { IActionListItem } from '../ActionList/ActionList';
import PageLoader from '../Page/PageLoader';
import ResourceTable from '../Resource/ResourceTable';
import ResourceTableRow from '../Resource/ResourceTableRow';
import ResourceTableRowData from '../Resource/ResourceTableRowData';
import ResourceTableRowActions from '../Resource/ResourceTableRowActions';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import { CurrentUser, ResourceListFilterType, Sequence } from '../../types';
import { useDebouncedCallback } from 'use-debounce';
import Switch from '../Form/Switch';
import styled from 'styled-components';

interface IState {
	sequences: Sequence[],
	currentPage: number,
	totalPages: number
	didInitialLoad: boolean
	isFetching: boolean
	sortValue: string
	filters: any
	searchValue: string
}

const ActiveContainer = styled.div`
	display: flex;
	justify-content: right;
`


interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showConfirmModal: typeof showConfirmModal
}

type IProps = { contactId?: string, projectId?: string } & IStateToProps & IDispatchToProps & RouteComponentProps<any>

const SequencesTable = (props: IProps) => {
	const { showConfirmModal } = props
	const { t } = useTranslation()
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)

	const [state, setState] = React.useState<IState>({
		sequences: [],
		currentPage: 0,
		totalPages: 0,
		didInitialLoad: false,
		isFetching: false,
		sortValue: LocalStorage.get(LocalStorageKey.SEQUENCE_SORT_VALUE, 'created_at_desc'),
		filters: {},
		searchValue: '',
	})

	const {
		sequences,
		currentPage,
		totalPages,
		didInitialLoad,
		filters,
		isFetching,
		sortValue,
		searchValue,
	} = state

	const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

	React.useEffect(() => {
		debouncedFetchSequences(1)
	}, [filters, sortValue, searchValue])

	const fetchSequences = async (page: number) => {
		try {
			let params: any = {
				page: page,
				search: searchValue,
				order: `${sortValue}`,
				...filters
			}

			setState({ ...state, isFetching: true })
			const response = await SequencesController.getSequences(params)

			const { sequences: responseSequences, current_page, total_pages, total_entries } = response;

			setState({
				...state,
				sequences: [...responseSequences],
				currentPage: current_page,
				totalPages: total_pages,
				didInitialLoad: true,
				isFetching: false
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	const debouncedFetchSequences = useDebouncedCallback((page) => fetchSequences(page), 250);

	const onNewSequenceClick = async () => {
		props.history.push(RouteHelper.process(ERoute.PATH_SEQUENCES_CREATE))
	}

	const onFiltersChange = (newFilters: any) => {
		setState({ ...state, filters: newFilters })
	}

	const onSortChange = (newSortValue: string) => {
		LocalStorage.set(LocalStorageKey.SEQUENCE_SORT_VALUE, newSortValue)
		setState({ ...state, sortValue: newSortValue })
	}

	const onRowClick = (sequence: Sequence) => {
		props.history.push(RouteHelper.process(ERoute.PATH_SEQUENCE, { id: sequence.id }))
	}

	const onActiveToggle = (e, sequence: Sequence) => {
		e.preventDefault()
		e.stopPropagation()

		SequencesController.update({ ...sequence, active: !sequence.active }).catch(console.error)

		const index = sequences.findIndex(s => s.id === sequence.id)

		sequences[index] = { ...sequence, active: !sequence.active }

		setState({
			...state,
			sequences: [...sequences]
		})
	}

	const onRowActionClick = (key: string, sequence: Sequence) => {
		switch (key) {
			case 'edit':
				props.history.push(RouteHelper.process(ERoute.PATH_SEQUENCE, { id: sequence.id }))
				break
			case 'delete':
				showConfirmModal({
					title: t('Sequence::Delete sequence'),
					description: t('Sequence::You are about to delete sequence <b>{{name}}</b>. Are you sure?', { name: sequence.name }),
					action: { label: t('Sequence::Delete'), isDestructive: true },
					onConfirm: async () => {
						try {
							const response = await SequencesController.delete(sequence.id)

							if (response.errors) { }
							else {
								const sequenceIndex = sequences.findIndex(c => c.id === sequence.id);

								sequences.splice(sequenceIndex, 1);

								setState({ ...state, sequences: [...sequences] })

								Notification.notifySuccess(t('Sequence::Sequence successfully deleted'))
							}
						} catch (ex) {
							console.error(ex)
						}
					}
				})
		}
	}

	const onSearchChange = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onSearchSubmit = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onClearFilters = () => {
		setState({ ...state, searchValue: '', filters: {} })
	}


	return (
		<>
			{!didInitialLoad && <PageLoader />}
			{didInitialLoad && <ResourceTable
				style={{ marginBottom: 75 }}
				data={sequences}
				headers={[
					{ title: t('Sequences::Name') },
					{ title: t('Sequences::Active'), align: 'right' },
					{ title: '', stickyRight: '0px' },
				]}
				renderRow={(sequence: Sequence) => {
					let actions: IActionListItem[] = [
						{ key: 'edit', icon: 'edit-solid', content: t('Sequences::Edit') },
						{ key: 'delete', icon: 'trash-alt-solid', content: t('Sequences::Delete'), destructive: true },
					]

					return (
						<ResourceTableRow key={sequence.id}>
							<ResourceTableRowData onClick={(): void => onRowClick(sequence)} ellipse>
								<b>{sequence.name ? sequence.name : '-'}</b>
							</ResourceTableRowData>
							<ResourceTableRowData>
								<ActiveContainer>
									<Switch
										name='enabled'
										onClick={(e) => onActiveToggle(e, sequence)}
										checked={sequence.active}
									/>
								</ActiveContainer>
							</ResourceTableRowData>
							<ResourceTableRowActions
								actions={actions}
								onActionClick={(key) => onRowActionClick(key, sequence)}
								sticky={true}
								stickyRight='0px'
							/>
						</ResourceTableRow>
					)
				}}
				renderEmpty={<CardEmptyInfo
					icon={filtersActive ? 'search' : 'paper-plane'}
					description={filtersActive ? t('Sequences::No sequences found') : t('Sequences::No sequences have been created yet')}
					descriptionActionText={filtersActive ? t('Sequences::Clear filters') : t('Sequences::Add sequence')}
					onDescriptionActionClick={filtersActive ? onClearFilters : onNewSequenceClick}
				/>}
				filters={[
					{ name: 'name', label: t('Sequences::Name'), type: ResourceListFilterType.STRING },
					{ name: 'created_at', label: t('Sequences::Created date'), type: ResourceListFilterType.DATE },
				]}
				onFiltersChange={onFiltersChange}
				sortOptions={[
					{ label: '-', value: '-' },
					{ label: t('Sequences::Name (A-Z)'), value: 'name_asc' },
					{ label: t('Sequences::Name (Z-A)'), value: 'name_desc' },
					{ label: t('Sequences::Created at ↑'), value: 'created_at_asc' },
					{ label: t('Sequences::Created at ↓'), value: 'created_at_desc' },
				]}
				sortValue={sortValue}
				onSortChange={onSortChange}
				pagination={{ page: currentPage, pageCount: totalPages }}
				onPageChange={(page) => fetchSequences(page)}
				isLoading={isFetching}
				stickyHeader={true}
				searchValue={searchValue}
				onSearchChange={onSearchChange}
				onSearchSubmit={onSearchSubmit}
				maxHeight='65vh'
			/>}
		</>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showConfirmModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SequencesTable))