import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { useTranslation } from 'react-i18next'
import IntegrationFormTitle from '../IntegrationFormTitle'
import { UnpaidIntegrationConfig, UnpaidFormData } from '../../../types'
import IntegrationFormLabel from '../IntegrationFormLabel'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import Select from '../../Form/Select'

type IProps = {
	config: UnpaidIntegrationConfig
	formData: UnpaidFormData
	onConfigChange: (config: UnpaidIntegrationConfig) => void
}

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		margin-bottom: ${Style.spacing.x2};
		font-weight: bold;
	}
`

const UnpaidForm = (props: IProps) => {
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)

	const {
		config,
		onConfigChange,
		formData,
	} = props
	const { t } = useTranslation()
	const { countries } = formData

	const onCompanyNameChange = (e) => {
		onConfigChange({
			...config,
			company_name: e.currentTarget.value,
		})
	}

	const onAddressChange = (e) => {
		onConfigChange({
			...config,
			address: e.currentTarget.value,
		})
	}

	const onLocationChange = (e) => {
		onConfigChange({
			...config,
			location: e.currentTarget.value,
		})
	}

	const onZipCodeChange = (e) => {
		onConfigChange({
			...config,
			zip_code: e.currentTarget.value,
		})
	}

	const onCountryCodeChange = (e) => {
		onConfigChange({
			...config,
			country_code: e.currentTarget.value,
		})
	}

	const onVatNumberChange = (e) => {
		onConfigChange({
			...config,
			vat_number: e.currentTarget.value,
		})
	}

	const onIbanChange = (e) => {
		onConfigChange({
			...config,
			iban: e.currentTarget.value,
		})
	}

	const onContactNameChange = (e) => {
		onConfigChange({
			...config,
			contact_name: e.currentTarget.value,
		})
	}

	const onEmailChange = (e) => {
		onConfigChange({
			...config,
			email: e.currentTarget.value,
		})
	}

	const onPhoneNumberChange = (e) => {
		onConfigChange({
			...config,
			phone_number: e.currentTarget.value,
		})
	}

	return (
		<Container>
			<IntegrationFormTitle>
				{t('UnpaidForm::Settings')}
			</IntegrationFormTitle>

			<>
				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Company name')}
					</IntegrationFormLabel>
					<input
						type='text'
						placeholder={t('UnpaidForm::Company name')}
						onChange={onCompanyNameChange}
						value={config.company_name}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Address')}
					</IntegrationFormLabel>
					<input
						type='text'
						placeholder={t('UnpaidForm::Address')}
						onChange={onAddressChange}
						value={config.address}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Postal code')}
					</IntegrationFormLabel>
					<input
						type='text'
						placeholder={t('UnpaidForm::Postal code')}
						onChange={onZipCodeChange}
						value={config.zip_code}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Location')}
					</IntegrationFormLabel>
					<input
						type='text'
						placeholder={t('UnpaidForm::Location')}
						onChange={onLocationChange}
						value={config.location}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Country')}
					</IntegrationFormLabel>
					<Select
						name='country_code'
						value={config.country_code}
						options={countries.map(country => {
							return {
								key: country.value, label: country.label, value: country.value
							}
						})}
						onChange={onCountryCodeChange}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::VAT number')}
					</IntegrationFormLabel>
					<input
						type='text'
						placeholder={t('UnpaidForm::VAT number')}
						onChange={onVatNumberChange}
						value={config.vat_number}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::IBAN')}
					</IntegrationFormLabel>
					<input
						type='text'
						placeholder={t('UnpaidForm::IBAN')}
						onChange={onIbanChange}
						value={config.iban}
					/>
				</div>
			</>

			<>
				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Primary contact name')}
					</IntegrationFormLabel>
					<input
						type='text'
						placeholder={t('UnpaidForm::Primary contact name')}
						onChange={onContactNameChange}
						value={config.contact_name}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Email')}
					</IntegrationFormLabel>
					<input
						type='email'
						placeholder={t('UnpaidForm::Email')}
						onChange={onEmailChange}
						value={config.email}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('UnpaidForm::Phone number')}
					</IntegrationFormLabel>
					<input
						type='tel'
						placeholder={t('UnpaidForm::Phone number')}
						onChange={onPhoneNumberChange}
						value={config.phone_number}
					/>
				</div>
			</>
		</Container>
	)
}

export default UnpaidForm