import styled from "styled-components";

const TasksNavigationToggle = styled.div`
	position: absolute;
	top: 20px;
	left: 0;
	width: 11px;
	height: 38px;
	background: #36619a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	cursor: pointer;
	z-index: 6;

	&:before {
    content: '';
    width: 2px;
    background: white;
    height: 32px;
    position: absolute;
    top: 3px;
    left: 4px;
    border-radius: 4px;
	}
`

export default TasksNavigationToggle