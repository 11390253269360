import styled from 'styled-components'
import { Style } from '../../styles'

const SimplebarHeader = styled.div`
  padding: 12px 16px;
  font-weight: 500;
  border-bottom: 1px solid ${Style.color.border};
  overflow: hidden;
  text-overflow: ellipsis;
  background: #f7f7fa;

  svg, i {
    margin-right: 4px;
  }
`

export default SimplebarHeader