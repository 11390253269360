import styled from 'styled-components'

export default styled.div`
	border-radius: 8px;
	width: 64px;
	height: 64px;
	margin-top: -50px;

	.avatar {
		width: 64px;
		height: 64px;
		border-radius: 8px;

		img {
			border-radius: 8px;
		}
	}
`