import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux'
import { AppState } from '../store'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import { showConfirmModal } from '../store/modals/actions'
import { AccountsController, IntegrationsController } from '../controllers'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import LocalStorage, { LocalStorageKey } from '../LocalStorage';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import ResourceTable, { ResourceTableAction } from '../components/Resource/ResourceTable';
import ResourceTableRow from '../components/Resource/ResourceTableRow';
import ResourceTableRowData from '../components/Resource/ResourceTableRowData';
import { RouteComponentProps } from 'react-router';
import { Account, CurrentUser, IntegrationType, ResourceListFilterType, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../types';
import PageContent from '../components/Page/PageContent';
import TopNavigation from '../components/Navigation/TopNavigation';
import PageHeader from '../components/Page/PageHeader';
import Icon from '../components/Icons/Icon';
import NumberFormatter from '../utilities/NumberFormatter';
import moment from '../utilities/Moment';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Style } from '../styles';
import ResourceTableRowActions from '../components/Resource/ResourceTableRowActions';
import Notification from '../utilities/Notification';
import ActionCableConsumer from '../consumers/ActionCableConsumer';

const IconContainer = styled.span<{ type: 'warning' | 'danger' }>`
  margin-left: 6px;
  cursor: pointer;

  svg, i {
    fill: ${props => props.type === 'warning' ? Style.color.brandWarning : Style.color.brandDanger};
    color: ${props => props.type === 'warning' ? Style.color.brandWarning : Style.color.brandDanger};
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  accounts: Account[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
  filters: any
  selectedAccountIds: string[]
  searchValue: string
}

class Accounts extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      selectedAccountIds: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.BANK_ACCOUNT_SORT_VALUE, 'created_at_desc'),
      filters: {},
      searchValue: ''
    }

    this.onActionCableReceived = this.onActionCableReceived.bind(this)
    this.onTableActionClick = this.onTableActionClick.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onTableAccountSynchronizeClick = this.onTableAccountSynchronizeClick.bind(this)
    this.onTableAccountReauthorizeClick = this.onTableAccountReauthorizeClick.bind(this)
    this.onTableAccountDeleteClick = this.onTableAccountDeleteClick.bind(this)
    this.onAccountFiltersChange = this.onAccountFiltersChange.bind(this)
    this.onAccountSortValueChange = this.onAccountSortValueChange.bind(this)
    this.onAccountSearchChange = this.onAccountSearchChange.bind(this)
    this.onAccountSearchSubmit = this.onAccountSearchSubmit.bind(this)
    this.onAccountClearFilters = this.onAccountClearFilters.bind(this)
    this.onAddAccountClick = this.onAddAccountClick.bind(this)
    this.onResourceBulkSynchronizeActionClick = this.onResourceBulkSynchronizeActionClick.bind(this)
    this.onResourceBulkDeleteActionClick = this.onResourceBulkDeleteActionClick.bind(this)

  }

  componentWillMount() {
    this.fetchAccounts(1)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  fetchAccounts(page: number) {
    const { searchValue, sortValue, filters } = this.state
    this.setState({
      isFetching: true
    }, () => {
      AccountsController
        .getAccounts({ page: page, search: searchValue, order: `${sortValue}`, ...filters })
        .then(response => {
          const { accounts, current_page, total_pages, total_entries } = response;

          this.setState({
            accounts: [...accounts],
            currentPage: current_page,
            totalPages: total_pages,
            didInitialLoad: true,
            isFetching: false
          });
        })
        .catch(console.error)
    });
  }

  onActionCableReceived(event: WorkspaceChannelEvent) {
    const { accounts } = this.state
    console.log('[WorkspaceChannel] event received', event)

    switch (event.type) {
      case WorkspaceChannelEventType.ACCOUNT_CREATE:
        const createIndex = accounts.findIndex(c => c.id === event.data.account.id)

        if (createIndex === -1) this.setState({ accounts: [event.data.account, ...accounts] })
        break
      case WorkspaceChannelEventType.ACCOUNT_UPDATE:
        const updateIndex = accounts.findIndex(c => c.id === event.data.account.id)

        if (updateIndex === -1) {
          this.setState({ accounts: [event.data.account, ...accounts] })
        } else {
          accounts[updateIndex] = event.data.account
          this.setState({ accounts: [...accounts] })
        }
        break
      case WorkspaceChannelEventType.ACCOUNT_DELETE:
        this.setState({ accounts: accounts.filter(c => c.id !== event.data.account_id) })
        break
    }
  }

  onTableActionClick(key: string, account: Account) {
    switch (key) {
      case 'synchronize': this.onTableAccountSynchronizeClick(account)
        break
      case 'reauthorize': this.onTableAccountReauthorizeClick(account)
        break
      case 'delete': this.onTableAccountDeleteClick(account)
        break
      default:
        throw Error('[Accounts] Unimplemented onTableActionClick')
    }
  }

  onTableSelectionChange(selectedAccountIds: string[]) {
    this.setState({ selectedAccountIds: selectedAccountIds })
  }

  onTableRowSelectionChange(selected: boolean, accountId: string) {
    const { selectedAccountIds } = this.state

    if (selected) {
      this.setState({ selectedAccountIds: [...selectedAccountIds, accountId] })
    } else {
      this.setState({ selectedAccountIds: selectedAccountIds.filter(selectedAccountId => selectedAccountId !== accountId) })
    }
  }

  async onTableAccountSynchronizeClick(account: Account) {
    try {
      await AccountsController.synchronize(account.id)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onTableAccountReauthorizeClick(account: Account) {
    try {
      const response = await AccountsController.reauthorize(account.id)
      if (response.redirect_uri) {
        window.location.assign(response.redirect_uri)
      } else {
        this.onAddAccountClick()
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onTableAccountDeleteClick(account: Account) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Accounts::Delete account'),
      description: t('Accounts::You are about to delete this account. By deleting the account you are also deleting all its associated data. Are you sure?'),
      action: { label: t('Accounts::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await AccountsController.delete(account.id)
          if (response.errors) {
          } else {
            const { accounts, selectedAccountIds } = this.state;

            const contactIndex = accounts.findIndex(c => c.id === account.id);

            accounts.splice(contactIndex, 1);

            this.setState({
              accounts: accounts,
              selectedAccountIds: selectedAccountIds.filter(selectedAccountId => selectedAccountId !== account.id)
            });

            Notification.notifySuccess(t('Accounts::Account successfully deleted'))
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onAccountFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchAccounts(1)
    })
  }

  onAccountSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.TRANSACTION_SORT_VALUE, value)
    this.setState({
      sortValue: value
    }, () => {
      this.fetchAccounts(1)
    })
  }

  onAccountSearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onAccountSearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchAccounts(1))
  }

  onAccountClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchAccounts(1))
  }

  async onAddAccountClick() {
    try {
      const { form_data: formData } = await IntegrationsController.getForm(IntegrationType.PONTO)
      window.location.assign(`/auth/ponto?onboarding_details_id=${formData.onboarding_details_id}&action=accounts`);
    } catch (ex) {
      console.error(ex)
    }
  }

  onResourceBulkSynchronizeActionClick() {
    const { selectedAccountIds } = this.state

    selectedAccountIds.forEach(async (accountId) => {
      try {
        await AccountsController.synchronize(accountId)
      } catch (ex) {
        console.error(ex)
      }
    })

    this.setState({ selectedAccountIds: [] })
  }

  onResourceBulkDeleteActionClick() {
    const { t } = this.props
    const { selectedAccountIds, accounts } = this.state

    this.props.showConfirmModal({
      title: t('Accounts::Bulk account deletion'),
      description: t('Accounts::You are about to delete these account. By deleting the accounts you are also deleting all its associated data. Are you sure?'),
      action: { label: t('Accounts::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await AccountsController.deleteAll(selectedAccountIds)
          if (response.errors) { }
          else {
            const newAccounts = accounts.filter(p => !selectedAccountIds.includes(p.id));

            this.setState({ accounts: [...newAccounts], selectedAccountIds: [] });

            Notification.notifySuccess(t('Accounts::Accounts successfully deleted'))
          }

        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  render() {
    const { t, currentUser } = this.props
    const { setting } = currentUser.workspace
    const {
      accounts,
      selectedAccountIds,
      didInitialLoad,
      isFetching,
      filters,
      sortValue,
      searchValue,
      currentPage,
      totalPages
    } = this.state

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0
    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'sync-alt', content: t('Accounts::Synchronize'), onAction: this.onResourceBulkSynchronizeActionClick },
      { icon: 'trash-alt-solid', content: t('Accounts::Delete'), onAction: this.onResourceBulkDeleteActionClick }
    ]

    return (
      <>
        <Helmet>
          <title>{t('Accounts::{{__appName}} | Accounts')}</title>
        </Helmet>

        <TopNavigation
          icon='sack-dollar'
          title={t('Accounts::Accounts')}
          action={<a key='new-account' href='javascript://' className='button button-primary page-action' onClick={this.onAddAccountClick}>
            <Icon icon='plus' />
            {t('Accounts::Add account')}
          </a>}
        />

        <ScrollToTopOnMount />

        <PageContent className='is-accounts'>
          <PageHeader title={t('Accounts::Accounts')} />
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ActionCableConsumer
            channel={{ channel: 'WorkspaceChannel', id: currentUser.workspace.id }}
            onReceived={this.onActionCableReceived}
          >
            <ResourceTable
              data={accounts}
              headers={[
                { title: t('Accounts::Name') },
                { title: t('Accounts::Financial institution') },
                { title: t('Accounts::Reference') },
                { title: t('Accounts::Balance'), align: 'right' },
                { title: '', stickyRight: '0px' },
              ]}
              renderRow={(account: Account) => {
                let showReauthorization: boolean = false
                let reauthorizationType: 'warning' | 'danger' = 'warning'
                let reauthorizationTooltip: string = ''

                if (account.reauthorize_at) {
                  const reauthorizationMoment = moment(account.reauthorize_at)
                  showReauthorization = moment().add(14, 'days').isSameOrAfter(reauthorizationMoment)

                  if (moment().isSameOrAfter(reauthorizationMoment)) {
                    reauthorizationTooltip = t('Accounts::Reauthorization is overdue')
                    reauthorizationType = 'danger'
                  } else {
                    reauthorizationTooltip = t('Accounts::Reauthorization is due in {{count}} days', { count: reauthorizationMoment.diff(moment(), 'days') })
                    reauthorizationType = 'warning'
                  }
                }

                return (
                  <ResourceTableRow
                    key={account.id}
                    selected={selectedAccountIds.includes(account.id)}
                    onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, account.id)}
                  >
                    <ResourceTableRowData>
                      {account.name}
                      {showReauthorization && <IconContainer
                        type={reauthorizationType}
                        data-tip={reauthorizationTooltip}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()

                          this.onTableAccountReauthorizeClick(account)
                        }}>
                        <Icon icon='exclamation-triangle' />
                      </IconContainer>}
                    </ResourceTableRowData>
                    <ResourceTableRowData>
                      {account.financial_institution}
                    </ResourceTableRowData>
                    <ResourceTableRowData>
                      {account.reference}
                    </ResourceTableRowData>
                    <ResourceTableRowData textAlign='right'>
                      {NumberFormatter.formatCurrency(account.currency, setting.number_format, account.balance)}
                    </ResourceTableRowData>
                    <ResourceTableRowActions
                      actions={[
                        { key: 'synchronize', icon: 'sync-alt', content: t('Accounts::Synchronize') },
                        { key: 'reauthorize', icon: 'link', content: t('Accounts::Reauthorize') },
                        { key: 'delete', icon: 'trash-alt-solid', content: t('Accounts::Delete'), destructive: true }
                      ]}
                      onActionClick={(key) => this.onTableActionClick(key, account)}
                      sticky={true}
                      stickyRight='0px'
                    />
                  </ResourceTableRow>
                )
              }
              }
              renderEmpty={<CardEmptyInfo
                icon={filtersActive ? 'search' : 'sack-dollar'}
                description={filtersActive ? t('Accounts::No accounts found') : t('Accounts::No accounts have been added yet')}
                descriptionActionText={filtersActive ? t('Accounts::Clear filters') : t('Accounts::Add account')}
                onDescriptionActionClick={filtersActive ? this.onAccountClearFilters : this.onAddAccountClick}
              />}
              filters={[
                { name: 'name', label: t('Account::Name'), type: ResourceListFilterType.STRING },
                { name: 'financial_institution', label: t('Account::Financial institution'), type: ResourceListFilterType.STRING },
                { name: 'reference', label: t('Account::Reference'), type: ResourceListFilterType.STRING },
                { name: 'balance', label: t('Account::Balance'), type: ResourceListFilterType.NUMBER },
                { name: 'currency', label: t('Account::Currency'), type: ResourceListFilterType.STRING },
                { name: 'created_at', label: t('Account::Created date'), type: ResourceListFilterType.DATE },
              ]}
              onFiltersChange={this.onAccountFiltersChange}
              sortOptions={[
                { label: '-', value: '-' },
                { label: t('Accounts::Name (A-Z)'), value: 'name_asc' },
                { label: t('Accounts::Name (Z-A)'), value: 'name_desc' },
                { label: t('Accounts::Created at ↑'), value: 'created_at_asc' },
                { label: t('Accounts::Created at ↓'), value: 'created_at_desc' },
              ]}
              sortValue={sortValue}
              onSortChange={this.onAccountSortValueChange}
              pagination={{ page: currentPage, pageCount: totalPages }}
              onPageChange={(page) => this.fetchAccounts(page)}
              isLoading={isFetching}
              stickyHeader={true}
              selectedItems={selectedAccountIds}
              onSelectionChange={this.onTableSelectionChange}
              promotedBulkActions={promotedBulkActions}
              searchValue={searchValue}
              onSearchChange={this.onAccountSearchChange}
              onSearchSubmit={this.onAccountSearchSubmit}
              maxHeight='55vh'
            />
          </ActionCableConsumer>}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Accounts))