import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { useTranslation } from "react-i18next"
import { RouteComponentProps, matchPath } from 'react-router-dom'
import ScrollToTopOnMount from "../components/Effects/ScrollToTopOnMount"
import TopNavigation from "../components/Navigation/TopNavigation"
import TabItem from "../components/Navigation/TabItem"
import ERoute from "../ERoute"
import FullPageContent from '../components/Page/FullPageContent'
import TopSubnavigation from '../components/Navigation/TopSubnavigation'
import RouteHelper from '../helpers/RouteHelper'
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper'
import { UserWorkspaceSettingScope } from '../types'
import Payments from './Payments'
import Transactions from './Transactions'
import Accounts from './Accounts'

type IProps = & RouteComponentProps<any>

const Bank = (props: IProps) => {
  const { t } = useTranslation()
  const { history } = props
  const activePath = props.location.pathname

  const onNavigateItemClick = (path: string) => {
    history.replace(path)
  }

  return (
    <>
      <ScrollToTopOnMount />

      <TopNavigation
        icon='bank'
        title={t('Bank::Bank')}
      />

      <FullPageContent style={{ background: 'transparent' }}>
        <TopSubnavigation>
          <TabItem
            active={!!matchPath(activePath, { path: ERoute.PATH_BANK_ACCOUNTS })}
            onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_BANK_ACCOUNTS))}
          >
            {t('Bank::Accounts')}
          </TabItem>
          <TabItem
            active={!!matchPath(activePath, { path: ERoute.PATH_BANK_TRANSACTIONS })}
            onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_BANK_TRANSACTIONS))}
          >
            {t('Bank::Transactions')}
          </TabItem>
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK_PAYMENT) && <TabItem
            active={!!matchPath(activePath, { path: ERoute.PATH_BANK_PAYMENTS, exact: true })}
            onClick={() => onNavigateItemClick(RouteHelper.process(ERoute.PATH_BANK_PAYMENTS))}
          >
            {t('Bank::Payments')}
          </TabItem>}
        </TopSubnavigation>
        <Switch>
          <Route path={ERoute.PATH_BANK_ACCOUNTS} component={Accounts} />
          <Route path={ERoute.PATH_BANK_TRANSACTIONS} component={Transactions} />
          {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.BANK_PAYMENT) && <Route path={ERoute.PATH_BANK_PAYMENTS} component={Payments} />}
          <Redirect to={ERoute.PATH_BANK_ACCOUNTS} />
        </Switch>
      </FullPageContent>
    </>
  )
}

export default Bank