import styled from 'styled-components'

export default styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px 15px 0px 15px;
	cursor: auto;
	position: relative;
	color: #353f45;
`