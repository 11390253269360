import styled from 'styled-components'
import { Style } from '../../styles'

const MenuEditorItemActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${Style.spacing.x1};
`

export default MenuEditorItemActions