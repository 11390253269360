import styled from 'styled-components'
import TableCell from './TableCell'

const TableBody = styled.div`
	display: table-row-group;
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
	border-color: inherit;

	${TableCell}:first-child {
		z-index: 3;
    display: table-cell!important;
	}
`

export default TableBody