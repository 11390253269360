import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { Board } from '../../types'

const Container = styled.li<{ color?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 16px 16px 0;
    cursor: pointer;
    background-color: #97a0af;
    background-size: cover;
    background-position: 50%;
    position: relative;
    height: 80px;
	border-radius: 8px;
    width: calc(25% - 16px);
    ${props => props.color && css`
        background-color: ${props.color};
        text-align: center;
    `}
    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        width: calc(50% - 16px);
    }
    @media screen and (max-width: 450px) {
        width: 100%;
        margin-right: 0;
    }
`

const Wrapper = styled.div`
    padding: 8px;
    color: #fff;
`

const Name = styled.p`
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: bold;
`

interface IProps {
  board: Board
  onClick: () => void
}


class BoardListItem extends React.PureComponent<IProps> {
  render() {
    const { board, onClick } = this.props

    return (
      <Container
        color={board.color}
        onClick={onClick}
      >
        <Wrapper>
          <Name>{board.name}</Name>
        </Wrapper>
      </Container>
    )
  }
}

export default BoardListItem