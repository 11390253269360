import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from "react-i18next"
import styled from 'styled-components'
import { ActiveStorageController } from '../../../controllers'
import { Style } from '../../../styles'
import { PDFBlock as PDFBlockType, MimeTypes } from '../../../types'
import Icon from '../../Icons/Icon'
import { EditBlockProps } from '../Block'
import PageLoader from '../../Page/PageLoader'

const Container = styled.div`
	background: white;
	border-bottom-left-radius: ${Style.variables.baseBorderRadius};
	border-bottom-right-radius: ${Style.variables.baseBorderRadius};
`

const EmptyContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px;
	cursor: pointer;

	i {
		font-size: 49px;
		margin-bottom: ${Style.spacing.x1};
		color: #DEE3E0;
	}

	p {
		font-weight: 500;

		span {
			color: ${Style.color.brandPrimary};
		}
	}
`

const Actions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all 0.150s ease-in-out;
	cursor: pointer;
	opacity: 0;

	i {
		font-size: 20px;
		color: white;
	}

	&:hover {
		background: rgba(0, 0, 0, 0.25);
	}
`

const ImagesContainer = styled.div`
	position: relative;

	img {
		width: 100%;
	}

	&:hover {
		${Actions} {
			opacity: 1;
		}
	}
`

type IProps = EditBlockProps<PDFBlockType>

const PDFBlock = (props: IProps) => {
	const { block } = props
	const { t } = useTranslation()

	const onDrop = React.useCallback(acceptedFiles => {
		const file = acceptedFiles[0]

		ActiveStorageController.uploadPublic(file, async (error, blob) => {
			if (error) { console.error(error) }
			if (blob) {
				const { url } = await ActiveStorageController.getBlobUrl(blob)

				props.onChange({ ...block, pdf_url: url })
			}
		})
	}, [])

	const onDeleteClick = () => {
		props.onChange({ ...block, pdf_url: null })
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: onDrop,
		multiple: false,
		accept: [
			MimeTypes.PDF,
		],
	})


	return (
		<Container>
			{!block.pdf_url && <EmptyContainer {...getRootProps()}>
				<input {...getInputProps()} />
				<Icon icon='cloud-upload' />
				<p dangerouslySetInnerHTML={{ __html: t('PDFBlock::<span>Upload a PDF</span> or drag and drop') }}>{ }</p>
			</EmptyContainer>}

			{block.pdf_url && block.image_urls?.length === 0 && <PageLoader />}
			{block.pdf_url && block.image_urls?.length > 0 && <ImagesContainer>
				<Actions onClick={onDeleteClick}>
					<Icon icon='trash' />
				</Actions>

				{block?.image_urls?.length > 0 && block.image_urls.map((imageUrl, index) => {
					return (
						<img src={imageUrl} />
					)
				})}
			</ImagesContainer>
			}
		</Container>
	)
}

export default PDFBlock