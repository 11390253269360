import styled from "styled-components"
import { Style } from "../../styles"

const SettingsPage = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

export default SettingsPage