import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const Tooltip = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	visibility: hidden;
	opacity: 0;
	background: ${Style.color.brandSuccess}; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: 17;
	width: max-content;
`

const TooltipWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
`

const TooltipArrow = styled.div<{ color: string }>`
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-width: 10px;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

	${(props) => css`
			border-top-color: ${props.color};
	`}
`

const Title = styled.div<{ color?: string }>`
	font-weight: bold;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 300px;
	display: block;
	overflow: hidden;
	color: white;

	${(props) => Boolean(props.color) && css`
		color: ${props.color};
	`}
`

const Description = styled.div<{ color?: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;

	${(props) => Boolean(props.color) && css`
		color: ${props.color};
	`}
`

export const TIMELINE_TOOLTIP_MOUSEMOVE = 'timeline-tooltip-mousemove'
export const TIMELINE_TOOLTIP_MOUSELEAVE = 'timeline-tooltip-mouseleave'

export const enum TimelineTooltipEventType {
	MOUSEMOVE = 'timeline-tooltip-mousemove',
	MOUSELEAVE = 'timeline-tooltip-mouseleave',
}

interface TimelineTooltipData {
	title?: string
	description?: string | JSX.Element
	backgroundColor?: string
	textColor?: string
	x: number
	y: number
}

export class TimelineTooltipEvent extends CustomEvent<TimelineTooltipData> {
	constructor(type: TimelineTooltipEventType, eventInitDict?: CustomEventInit<TimelineTooltipData>) {
		super(type, eventInitDict)
	}
}

const TimelineTooltip = () => {
	const { t } = useTranslation()
	const tooltip = React.useRef<HTMLDivElement>()
	const [state, setState] = React.useState<TimelineTooltipData>(null)

	const setTooltip = (data) => {
		const { detail: { title, description, x, y } } = data

		if ((title || description) && x && y) {
			setState({ ...data.detail })
		} else {
			setState(null)
		}
	}

	React.useEffect(() => {
		document.addEventListener(TIMELINE_TOOLTIP_MOUSEMOVE, setTooltip)
		document.addEventListener(TIMELINE_TOOLTIP_MOUSELEAVE, setTooltip)

		return () => {
			document.removeEventListener(TIMELINE_TOOLTIP_MOUSEMOVE, setTooltip)
			document.removeEventListener(TIMELINE_TOOLTIP_MOUSELEAVE, setTooltip)
		}
	}, [state])

	if (!state) return null

	const { title, description, x, y, backgroundColor, textColor } = state

	let tooltipWidth = null
	let tooltipHeight = null

	if (tooltip.current) {
		tooltipWidth = tooltip.current.clientWidth
		tooltipHeight = tooltip.current.clientHeight
	}

	const show = (title || description) && tooltipWidth

	return (
		<Tooltip
			ref={tooltip}
			style={{
				left: `calc(${x}px - ${tooltipWidth / 2}px)`,
				top: `calc(${y}px - ${tooltipHeight + 16}px)`,
				opacity: show ? 1 : 0,
				visibility: show ? 'visible' : 'hidden',
				backgroundColor: backgroundColor || Style.color.brandSuccess,
			}}
		>
			<TooltipWrapper>
				{title && <Title style={textColor ? { color: textColor } : undefined}>{title}</Title>}
				{description && <Description style={textColor ? { color: textColor } : undefined}>
					{description}
				</Description>}

				<TooltipArrow color={backgroundColor || Style.color.brandSuccess} />
			</TooltipWrapper>
		</Tooltip>
	)
}

export default TimelineTooltip