import * as React from 'react'
import ExpensesTable from '../Expenses/ExpensesTable'

interface IProps {
  contactId: string
}

const ContactExpenses = (props: IProps) => {
  const { contactId } = props

  return (
    // @ts-ignore
    <ExpensesTable
      contactId={contactId}
      primaryActionEnabled
    />
  )
}

export default ContactExpenses