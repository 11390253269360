import * as React from 'react'
import ExpensesTable from '../Expenses/ExpensesTable'

type IProps = { projectId?: string, contactId?: string }

const ProjectExpenses = (props: IProps) => {
	const { contactId, projectId } = props

	return (
		// @ts-ignore
		<ExpensesTable
			contactId={contactId}
			projectId={projectId}
			primaryActionEnabled
		/>
	)
}

export default ProjectExpenses