import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import ScrollToTopOnMount from '../../../components/Effects/ScrollToTopOnMount';
import { AppState } from '../../../store';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumbs';
import ERoute from '../../../ERoute';
import RouteHelper from '../../../helpers/RouteHelper';
import { RouteComponentProps } from 'react-router';
import { CustomFieldsController } from '../../../controllers';
import styled from 'styled-components';
import PageLoader from '../../../components/Page/PageLoader';
import CustomFieldItem from '../../../components/CustomFields/CustomFieldItem';
import { showConfirmModal, showCustomFieldModal } from '../../../store/modals/actions';
import { Style } from '../../../styles';
import Icon from '../../../components/Icons/Icon';
import Notification from '../../../utilities/Notification';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import DroppableHelper from '../../../helpers/DroppableHelper';
import { CurrentUser, CustomField, CustomFieldModel, CustomFieldType, DisplayableError } from '../../../types';

const CustomFieldAddItem = styled.div`
  width: 100%;
  justify-content: center;
  background-color: transparent;
  border: 1px dashed ${Style.color.border};
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: 700;
  cursor: pointer;
  margin-top: ${Style.spacing.x3};
  margin-bottom: ${Style.spacing.x2};
  color: #637381;
`

const CustomFieldAddItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i, svg {
    margin-right: 8px;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showCustomFieldModal: typeof showCustomFieldModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<{ model: string }>
interface IState {
  didInitialLoad: boolean
  customFields: CustomField[]
  errors: DisplayableError[]
}

class CustomFieldModelComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      customFields: [],
      errors: [],
    }

    this.onAddCustomFieldClick = this.onAddCustomFieldClick.bind(this)
    this.onCustomFieldDragEnd = this.onCustomFieldDragEnd.bind(this)
    this.onCustomFieldClick = this.onCustomFieldClick.bind(this)
    this.onCustomFieldDeleteClick = this.onCustomFieldDeleteClick.bind(this)
    this.onDeleteCustomField = this.onDeleteCustomField.bind(this)
    this.onCustomFieldModalSubmit = this.onCustomFieldModalSubmit.bind(this)
  }

  componentDidMount() {
    this.fetchCustomFields().catch(console.error)
  }

  async fetchCustomFields() {
    const { match: { params: { model } } } = this.props

    try {
      const customFields = await CustomFieldsController.getCustomFields({ model: model, order: 'position_asc' })

      this.setState({
        didInitialLoad: true,
        customFields: customFields,
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onAddCustomFieldClick() {
    const { match: { params: { model } } } = this.props
    const { customFields } = this.state

    requestAnimationFrame(() => {
      this.props.showCustomFieldModal({
        customField: {
          model: model as CustomFieldModel,
          type: CustomFieldType.STRING,
          position: DroppableHelper.getCreatePosition(customFields)
        },
        modelDisabled: true,
        onSubmit: this.onCustomFieldModalSubmit
      })
    })
  }

  onCustomFieldDragEnd(dropResult: DropResult, provided: ResponderProvided) {
    const { customFields } = this.state
    const { destination, source, draggableId } = dropResult

    if (!destination) return

    if (
      destination.droppableId !== source.droppableId &&
      destination.index !== source.index
    ) {
      return
    }

    const updatedCustomFields = [...customFields]

    // Calculate new position
    const newPosition = DroppableHelper.getNewPositionBasedOnDropResult(customFields, dropResult)

    // Remove custom field from position
    updatedCustomFields[source.index].position = newPosition

    // Update custom field position on API (non blocking)
    CustomFieldsController.update(updatedCustomFields[source.index]).catch(console.error)

    // Update position
    this.setState({
      customFields: DroppableHelper.sort(updatedCustomFields, 'ascending')
    })
  }

  onCustomFieldClick(customField: CustomField) {
    const { match: { params: { model } } } = this.props

    requestAnimationFrame(() => {
      this.props.showCustomFieldModal({
        customField: { id: customField.id, model: model as CustomFieldModel, type: customField.type },
        modelDisabled: true,
        onSubmit: this.onCustomFieldModalSubmit
      })
    })
  }

  onCustomFieldDeleteClick(customField: CustomField) {
    const { t } = this.props

    requestAnimationFrame(() => {
      this.props.showConfirmModal({
        title: t('CustomFieldModel::Custom field deletion'),
        description: t('CustomFieldModel::You are about to delete custom field {{name}}. When deleting a custom field you are also deleting all historical data associated to the customfield. Are you sure?', { name: customField.name }),
        action: { label: t('FileBrowser::Delete'), isDestructive: true },
        onConfirm: () => this.onDeleteCustomField(customField)
      })
    })
  }

  async onDeleteCustomField(customField: CustomField) {
    const { t } = this.props
    const { customFields } = this.state

    try {
      const response = await CustomFieldsController.delete(customField.id)

      const customFieldIndex = customFields.findIndex(c => c.id === customField.id);

      customFields.splice(customFieldIndex, 1);

      this.setState({
        customFields: [...customFields]
      });

      Notification.notifySuccess(t('CustomFieldModel::Custom field deleted successfully deleted'))
    } catch (ex) {
      console.error(ex)

    }
  }

  onCustomFieldModalSubmit(customField: CustomField) {
    const { customFields } = this.state

    const customFieldIndex = customFields.findIndex(c => c.id === customField.id)

    if (customFieldIndex !== -1) {
      customFields[customFieldIndex] = customField

      this.setState({
        customFields: [
          ...DroppableHelper.sort(customFields, 'ascending'),
        ]
      })
    } else {
      this.setState({
        customFields: DroppableHelper.sort([customField, ...customFields], 'ascending'),
      })
    }
  }

  render() {
    const { t, match: { params: { model } } } = this.props
    const { didInitialLoad, customFields } = this.state

    return (
      <>
        <Helmet>
          <title>{t('CustomFields::{{__appName}} | Custom Fields')}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { content: t('CustomFields::Custom fields'), url: ERoute.PATH_SETTINGS_CUSTOM_FIELDS },
            { content: t('CustomFields::{{type}}', { type: t(`CustomFieldModel::${model}_plural`) }), url: RouteHelper.process(ERoute.PATH_SETTINGS_CUSTOM_FIELDS_MODEL, { model: model }), }
          ]}
        />

        {!didInitialLoad && <PageLoader />}

        {didInitialLoad && <>
          <CustomFieldAddItem onClick={this.onAddCustomFieldClick}>
            <CustomFieldAddItemContent>
              <CustomFieldAddItemContent>
                <Icon icon='plus-circle' />
                {t('CustomFields::Create custom field')}
              </CustomFieldAddItemContent>
            </CustomFieldAddItemContent>
          </CustomFieldAddItem>

          <DragDropContext
            onDragEnd={this.onCustomFieldDragEnd}
          >
            <Droppable droppableId='custom-fields'>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {customFields.length > 0 && customFields.map((customField, index) => {
                      return (
                        <CustomFieldItem
                          index={index}
                          key={customField.id}
                          customField={customField}
                          onClick={this.onCustomFieldClick}
                          onDeleteClick={this.onCustomFieldDeleteClick}
                        />
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>
        </>}
        <ScrollToTopOnMount />
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showCustomFieldModal: (options) => dispatch(showCustomFieldModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomFieldModelComponent))