import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { showSettingsEmailTemplateModal } from '../../store/modals/actions';
import { CurrentUser, DisplayableError, SettingsEmailTemplate, Settings } from '../../types';
import CheckboxInput from '../../components/Form/CheckboxInput';
import Alert from '../../components/Alert/Alert';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import MenuEditorItemAction from '../../components/MenuEditor/MenuEditorItemAction';
import Icon from '../../components/Icons/Icon';
import { Style } from '../../styles';
import MenuEditorItemActions from '../../components/MenuEditor/MenuEditorItemActions';
import ReactTooltip from 'react-tooltip';
import Title from '../../components/Settings/Title';


const EmailAddress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(last-child) {
    margin-bottom: ${Style.spacing.x1};
  }
`

const EmailAddresses = styled.div`
  display: flex;
  flex-direction: column;
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  showLedgerItemEmailTemplateModal: typeof showSettingsEmailTemplateModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class Email extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      form: null
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onToggleEnableAutoBccEmail = this.onToggleEnableAutoBccEmail.bind(this)
    this.onCopyLoggingEmailClick = this.onCopyLoggingEmailClick.bind(this)
    this.onBccEmailChange = this.onBccEmailChange.bind(this)
    this.onBccEmailAddressDeleteClick = this.onBccEmailAddressDeleteClick.bind(this)
    this.onBccNewEmailChange = this.onBccNewEmailChange.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onEmailAddressDeleteClick = this.onEmailAddressDeleteClick.bind(this)
    this.onNewEmailChange = this.onNewEmailChange.bind(this)
    this.onEditEmailTemplateClick = this.onEditEmailTemplateClick.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    try {
      const response = await SettingsController.update(settings)

      if (response.errors) {
        Notification.notifyError(t('SettingEmail::Oops something went wrong'));
        this.setState({ errors: response.errors })
      } else {
        // Cast to settings
        const settings = response as Settings
        // Update local
        this.setState({ settings: settings, errors: [] })
        // Update global
        updateSettings(settings)

        Notification.notifySuccess(t('SettingEmail::Settings successfully updated'));
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onToggleEnableAutoBccEmail() {
    const { settings } = this.state
    this.setState({
      settings: {
        ...settings,
        auto_email_bcc_enabled: !settings.auto_email_bcc_enabled
      }
    })
  }

  onCopyLoggingEmailClick() {
    const { currentUser: { workspace } } = this.props
    const { t } = this.props
    copy(workspace.bcc_email_address)
    Notification.notifySuccess(t('Email::Copied to clipboard'))
  }

  onBccEmailChange(email: string, index: number) {
    const { settings } = this.state

    const emails = [...settings.bcc_email_addresses]

    emails[index] = email

    this.setState({
      settings: {
        ...settings,
        bcc_email_addresses: emails
      }
    })
  }

  onBccEmailAddressDeleteClick(index: number) {
    const { settings } = this.state

    const emails = [...settings.bcc_email_addresses]

    emails.splice(index, 1)

    this.setState({
      settings: {
        ...settings,
        bcc_email_addresses: emails
      }
    })
  }

  onBccNewEmailChange(email: string) {
    const { settings } = this.state

    const emails = [...settings.bcc_email_addresses, email]

    this.setState({
      settings: {
        ...settings,
        bcc_email_addresses: emails
      }
    }, () => {
      const emailInput = this[this.getBccEmailRefKey(this.state.settings.bcc_email_addresses.length - 1)]

      if (emailInput) {
        emailInput.focus()
      }
    })
  }

  onEmailChange(email: string, index: number) {
    const { settings } = this.state

    const emails = [...settings.approved_email_addresses]

    emails[index] = email

    this.setState({
      settings: {
        ...settings,
        approved_email_addresses: emails
      }
    })
  }

  onEmailAddressDeleteClick(index: number) {
    const { settings } = this.state

    const emails = [...settings.approved_email_addresses]

    emails.splice(index, 1)

    this.setState({
      settings: {
        ...settings,
        approved_email_addresses: emails
      }
    })
  }

  onNewEmailChange(email: string) {
    const { settings } = this.state

    const emails = [...settings.approved_email_addresses, email]

    this.setState({
      settings: {
        ...settings,
        approved_email_addresses: emails
      }
    }, () => {
      const emailInput = this[this.getEmailRefKey(this.state.settings.approved_email_addresses.length - 1)]

      if (emailInput) {
        emailInput.focus()
      }
    })
  }

  onEditEmailTemplateClick(e, template: SettingsEmailTemplate) {
    this.props.showLedgerItemEmailTemplateModal({ template: template })
  }

  isValidOption(inputValue: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(inputValue).toLowerCase());
  }

  getEmailRefKey(index) {
    return `email-${index}`
  }

  getBccEmailRefKey(index) {
    return `bcc-email-${index}`
  }

  render() {
    const { t, currentUser } = this.props
    const { errors, settings } = this.state

    return (
      <>
        <Helmet>
          <title>{t('SettingEmail::{{__appName}} | Emails')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <form onSubmit={this.onFormSubmit}>
          <Title>{t('SettingEmail::Email')}</Title>
          <Panel>
            <div style={{ marginBottom: 10 }}>
              <Alert
                type='warning'
                text={
                  <Trans t={t}>
                    <div>Your outbound email logging address is: <br /><a onClick={this.onCopyLoggingEmailClick}>{currentUser.workspace.bcc_email_address}</a></div>
                  </Trans>
                }
              />
            </div>
            <div className='form-item'>
              <label>{t('SettingEmail::Auto BCC')}</label>
              <div style={{ marginBottom: 8 }}>
                {t('Email::To make it easier to log your outgoing emails, we can automatically BCC the email logging address whenever you send an email in {{ __appName }}.')}
              </div>

              <div className='grid'>
                <div className='grid-cell with-4col'>
                  <CheckboxInput
                    onChange={this.onToggleEnableAutoBccEmail}
                    checked={settings.auto_email_bcc_enabled}
                    label={t('SettingEmail::Enabled')}
                  />
                </div>
              </div>
            </div>

            <div className='form-item'>
              <hr />
            </div>

            <div className='form-item'>
              <label>{t('SettingEmail::BCC email addresses')}</label>
              <div>
                {t('Email::Add additional email addresses that you want to BCC on all outgoing emails.')}
              </div>
            </div>

            <EmailAddresses>
              {settings.bcc_email_addresses.map((email, index) => {
                return (
                  <EmailAddress key={index}>
                    <input
                      ref={(input) => this[this.getBccEmailRefKey(index)] = input}
                      type='text'
                      value={email}
                      onChange={(e) => this.onBccEmailChange(e.currentTarget.value, index)}
                    />
                    <MenuEditorItemActions>
                      <MenuEditorItemAction
                        type='danger'
                        data-tip={t('SettingEmail::Delete')}
                        data-place='top'
                        onClick={() => this.onBccEmailAddressDeleteClick(index)}
                      >
                        <Icon icon='circle-xmark' />
                      </MenuEditorItemAction>
                    </MenuEditorItemActions>
                  </EmailAddress>
                )
              })}
            </EmailAddresses>
            <EmailAddress>
              <input
                type='text'
                value=''
                onChange={(e) => this.onBccNewEmailChange(e.currentTarget.value)}
                placeholder={t('Email::Add additional email here')}
              />
            </EmailAddress>

            <div className='form-item'>
              <label>{t('SettingEmail::Approved email addresses')}</label>
              <div>
                {t('Email::We want to make sure that other people can\'t spam your email logging address. By default we only accept emails that come from the email address that you use to log into {{__appName}}, but you can add more approved addresses here.')}
              </div>
            </div>

            <EmailAddresses>
              <EmailAddress>
                <input type='text' value={currentUser.email} disabled />
              </EmailAddress>
              {settings.approved_email_addresses.map((email, index) => {
                return (
                  <EmailAddress key={index}>
                    <input
                      ref={(input) => this[this.getEmailRefKey(index)] = input}
                      type='text'
                      value={email}
                      onChange={(e) => this.onEmailChange(e.currentTarget.value, index)}
                    />
                    <MenuEditorItemActions>
                      <MenuEditorItemAction
                        type='danger'
                        data-tip={t('SettingEmail::Delete')}
                        data-place='top'
                        onClick={() => this.onEmailAddressDeleteClick(index)}
                      >
                        <Icon icon='circle-xmark' />
                      </MenuEditorItemAction>
                    </MenuEditorItemActions>
                  </EmailAddress>
                )
              })}
            </EmailAddresses>
            <EmailAddress>
              <input
                type='text'
                value=''
                onChange={(e) => this.onNewEmailChange(e.currentTarget.value)}
                placeholder={t('Email::Add additional email here')}
              />
            </EmailAddress>

            <div className='form-item'>
              <label>{t('SettingEmail::Email templates')}</label>
              <Button
                type='default'
                icon='pen'
                text={t('SettingEmail::Edit')}
                onClick={(e) => this.onEditEmailTemplateClick(e, SettingsEmailTemplate.INVOICE)}
              />
            </div>

            <div className='field-actions'>
              <input type='submit' style={{ display: 'none' }} />
              <div className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={() => this.setState({ errors: [] })}
                />
                <Button type='success' text={t('SettingEmail::Save')} onClick={this.onFormSubmit} />
              </div>
            </div>
          </Panel>
        </form >

      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showLedgerItemEmailTemplateModal: (options) => dispatch(showSettingsEmailTemplateModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Email))