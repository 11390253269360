import styled from "styled-components";

const StepHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`

export default StepHeaderContent