import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import Option from '../../components/Settings/Option';
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { showSettingsEmailTemplateModal } from '../../store/modals/actions';
import { CurrentUser, DisplayableError, SettingsEmailTemplate, Settings } from '../../types';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  showLedgerItemEmailTemplateModal: typeof showSettingsEmailTemplateModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class Emails extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      form: null
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onEditEmailTemplateClick = this.onEditEmailTemplateClick.bind(this)
    this.onWeeklySummaryChange = this.onWeeklySummaryChange.bind(this)
    this.onInvoiceViewedChange = this.onInvoiceViewedChange.bind(this)
    this.onInvoicesDueTodayChange = this.onInvoicesDueTodayChange.bind(this)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    SettingsController
      .update(settings)
      .then(response => {
        if (response.errors) {
          Notification.notifyError(t('SettingEmail::Oops something went wrong'));
          this.setState({ errors: response.errors })
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update local
          this.setState({ settings: settings, errors: [] })
          // Update global
          updateSettings(settings)

          Notification.notifySuccess(t('SettingEmail::Settings successfully updated'));
        }
      })
      .catch(console.error)
  }

  onEditEmailTemplateClick(e, template: SettingsEmailTemplate) {
    this.props.showLedgerItemEmailTemplateModal({ template: template })
  }

  onWeeklySummaryChange(subscribed) {
    const { settings } = this.state;

    settings.weekly_summary_subscription = subscribed;

    this.setState({
      settings: settings
    });
  }

  onInvoiceViewedChange(subscribed) {
    const { settings } = this.state;

    settings.invoice_viewed_subscription = subscribed;

    this.setState({
      settings: settings
    });
  }

  onInvoicesDueTodayChange(subscribed) {
    const { settings } = this.state;

    settings.invoices_due_today_subscription = subscribed;

    this.setState({
      settings: settings
    });
  }

  isValidOption(inputValue: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(inputValue).toLowerCase());
  }

  render() {
    const { t } = this.props
    const { settings, errors } = this.state

    return (
      <Panel>
        <Helmet>
          <title>{t('SettingEmail::{{__appName}} | Emails')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <form onSubmit={this.onFormSubmit}>
          <Title>{t('SettingEmail::Emails')}</Title>

          <div className='form-item'>
            <label>{t('SettingEmail::Email templates')}</label>
            <Button
              type='default'
              icon='pen'
              text={t('SettingEmail::Edit templates')}
              onClick={(e) => this.onEditEmailTemplateClick(e, SettingsEmailTemplate.INVOICE)}
            />
          </div>
          <div className="form-item">
            <label>{t('SettingEmail::Summary emails')}</label>
          </div>
          <div className='setting-options'>
            <Option
              title={t('SettingEmail::Weekly summary')}
              description={t('SettingEmail::Receive a weekly summary email with the financial health records of your company.')}
              onChange={this.onWeeklySummaryChange}
              checked={settings.weekly_summary_subscription}
            />

            <Option
              title={t('SettingEmail::Invoice opened')}
              description={t('SettingEmail::Receive an email when your contact opens his invoice.')}
              onChange={this.onInvoiceViewedChange}
              checked={settings.invoice_viewed_subscription}
            />

            <Option
              title={t('SettingEmail::Invoices to be paid')}
              description={t('SettingEmail::Receive an email on the day an invoice should be paid by the contact.')}
              onChange={this.onInvoicesDueTodayChange}
              checked={settings.invoices_due_today_subscription}
            />
          </div>
          <div className='field-actions'>
            <input type='submit' style={{ display: 'none' }} />
            <div className='popover-wrapper'>
              <TooltipError
                errors={errors}
                onDismiss={() => this.setState({ errors: [] })}
              />
              <Button type='success' text={t('SettingEmail::Save')} onClick={this.onFormSubmit} />
            </div>
          </div>
        </form>
      </Panel>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showLedgerItemEmailTemplateModal: (options) => dispatch(showSettingsEmailTemplateModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Emails))