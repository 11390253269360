import * as React from 'react'
import { useTranslation } from "react-i18next"
import styled from 'styled-components'
import { Style } from '../../../styles'
import { QuestionAndAnswerBlock as QuestionAndAnswerBlockType } from '../../../types'
import { EditBlockProps } from '../Block'
import Icon from '../../Icons/Icon'

const Container = styled.div`
	background: white;
	border-bottom-left-radius: ${Style.variables.baseBorderRadius};
	border-bottom-right-radius: ${Style.variables.baseBorderRadius};
	padding: ${Style.spacing.x1};
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
`

const QuestionIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 33px;
	height: 33px;
	min-width: 33px;
	min-height: 33px;
	border-radius: 50%;
	background: rgb(0, 145, 174);
	color: white;

	i {
		font-size: 20px;
	}
`

const QuestionContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: ${Style.spacing.x1_5};
`

const QuestionTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	width: 100%;
	margin-bottom: ${Style.spacing.x1_5};
`

const QuestionAnswer = styled.textarea`
	width: 100%;

	&:disabled {
		border: none;
		cursor: not-allowed;
		background: rgb(234, 240, 246);
		color: black;
	}
`

type IProps = {} & EditBlockProps<QuestionAndAnswerBlockType>

const QuestionAndAnswerBlock = (props: IProps) => {
	const { t } = useTranslation()
	const { block } = props

	return (
		<Container>
			<Wrapper>
				<QuestionIconContainer>
					<Icon icon='question-circle' />
				</QuestionIconContainer>
				<QuestionContent>
					<QuestionTitle>
						{block.title}
					</QuestionTitle>
					<QuestionAnswer
						placeholder={t('QuestionAndAnswerBlock::Notes')}
						disabled
					/>
				</QuestionContent>
			</Wrapper>
		</Container>
	)
}

export default QuestionAndAnswerBlock