import * as React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router';
import ERoute from '../ERoute';
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper';
import { UserWorkspaceSettingRole } from '../types';
import Profile from './Account/Profile'
import Subscription from './Account/Subscription'

const Account = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('Account::{{__appName}} | Account')}</title>
      </Helmet>
      <Switch>
        <Route path={ERoute.PATH_ACCOUNT_PROFILE} component={Profile} />
        {UserWorkspaceSettingHelper.hasRole(UserWorkspaceSettingRole.OWNER) && <Route path={ERoute.PATH_ACCOUNT_SUBSCRIPTION} component={Subscription} />}}
        <Redirect from='/' to={ERoute.PATH_ACCOUNT_PROFILE} />
      </Switch>
    </>
  )
}

export default Account