import styled, { css } from 'styled-components'
import ConversationItemContent from './ConversationItemContent'

const ConversationItem = styled.div<{ active?: boolean }>`
	cursor: pointer;
	border-bottom: 2px solid rgb(234,237,245);

	${props => props.active && css`
		${ConversationItemContent} {
			background: #f1f1f1;
		}
	`}
`

export default ConversationItem