import * as React from 'react'

interface IProps {
	persist?: boolean
	onComponentClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export default class ButtonDropdownOption extends React.PureComponent<IProps> {
	static defaultProps = {
		persist: false
	}

	render() {
		const { onComponentClick, onClick, persist, children } = this.props

		const onLinkOptionClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
			onClick(e);
			if (!persist) onComponentClick(e);
		};

		return (
			<li className='dropdown-menu-item'>
				<a href='javascript://' className='dropdown-menu-link' onClick={onLinkOptionClick}>
					{children}
				</a>
			</li>
		);
	}
}