import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const TabItem = styled.div<{ active?: boolean }>`
  display: flex;
	font-weight: 700;
	display: block;
	padding: ${Style.spacing.x1};
	white-space: nowrap;
	color: inherit;
	border-color: inherit;
	cursor: pointer;

	span {
		margin-left: 4px;
		border: 1px solid ${Style.color.border};
		padding: 4px;
		font-size: 12px;
		border-radius: 4px;
	}

	${props => props.active && css`
		box-shadow: inset 0 -3px 0 ${Style.color.brandPrimary};
    font-weight: 700;

		svg {
			width: 22px;
		}
	`}

	${props => !props.active && css`
		&:hover {
			color: #212b36;
			background-color: rgba(223,227,232,.3);
		}
	`}
`

export default TabItem