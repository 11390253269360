import * as React from 'react'
import styled from "styled-components"
import Loader from "../Loaders/Loader"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 100%;
  padding: 16px;
`

const SimplebarLoader = () => {
  return (
    <Container>
      <Loader size='medium' />
    </Container>
  )
}

export default SimplebarLoader