import styled from "styled-components";
import { Style } from "../../styles";

export default styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: ${Style.spacing.x1};


	&:not(:last-child) {
		margin-right: ${Style.spacing.x1};

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			margin-right: 0;
		}
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}){
		flex-direction: column;
		align-items: flex-start;
		margin-right: 0;
		margin-bottom: ${Style.spacing.x1};
		width: 100%;
	}

	> span {
		white-space: nowrap;
		margin-right: ${Style.spacing.x1};

		@media screen and (max-width: ${Style.breakpoints.SMALL}){
			margin-bottom: ${Style.spacing.x0_5};
		}
	}
`