import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import FileBrowser from '../components/FileBrowser/FileBrowser'
import TopNavigation from '../components/Navigation/TopNavigation'
import { AppState } from '../store'
import { BizzeyFileResourceType, Workspace } from '../types'
import PageContent from '../components/Page/PageContent'

type IProps = & RouteComponentProps<any>

const Files = (props: IProps) => {
	const { t } = useTranslation()
	const workspace = useSelector<AppState, Workspace>(state => state.authentication.currentUser.workspace)

	return (
		<>
			<Helmet>
				<title>{t('Files::{{__appName}} | Files')}</title>
			</Helmet>
			<ScrollToTopOnMount />

			<TopNavigation
				icon='folder'
				title={t('Contacts::Files')}
			/>

			<PageContent>
				<FileBrowser
					resourceType={BizzeyFileResourceType.WORKSPACE}
					resourceId={workspace.id}
					maxHeight='61vh'
				/>
			</PageContent>
		</>
	)
}

export default Files