import * as React from 'react'
import { Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components';
import NavigationHelper from '../../helpers/NavigationHelper'
import { Style } from '../../styles'
import { NavigationMenuItem, NavigationMenuItemPage, NavigationMenuItemType } from '../../types';
import Icon from '../Icons/Icon'
import MenuEditorItemAction from './MenuEditorItemAction'
import MenuEditorItemActions from './MenuEditorItemActions'
import MenuEditorItemContainer from './MenuEditorItemContainer';
import MenuEditorItemDrag from './MenuEditorItemDrag'
import MenuEditorItemIcon from './MenuEditorItemIcon'
import MenuEditorItemLabel from './MenuEditorItemLabel'
import MenuEditorItemValue from './MenuEditorItemValue'

const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const GroupInfo = styled.div<{ hasItems?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${props => props.hasItems && css`
    margin-bottom: ${Style.spacing.x1};
  `}
`

const GroupItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MenuEditorItemContainer} {
    border: none;
    padding-left: 40px;
  }
`

const ChildItemContainer = styled.div`
	position: relative;
  display: flex;
  flex-direction: row;

  > svg {
    position: absolute;
    height: 30px;
    left: 17px;
    width: 10px;
  }
`

interface IProps {
  index: number
  childIndex?: number
  provided: DraggableProvided
  item: NavigationMenuItem
  onHomeClick: (item: NavigationMenuItem, index: number) => void
  onDeleteClick: (item: NavigationMenuItem, index: number, childIndex?: number) => void
  onAddGroupItemClick: (item: NavigationMenuItem, index: number) => void
  onPageChange: (page: NavigationMenuItemPage, index: number, childIndex?: number) => void
  onLabelChange: (item: NavigationMenuItem, label: string, index: number, childIndex?: number) => void
  onValueChange: (item: NavigationMenuItem, value: string, index: number, childIndex?: number) => void
}

const MenuEditorItem = (props: IProps) => {
  const { t } = useTranslation()
  const {
    item,
    index,
    childIndex,
    provided,
    onHomeClick,
    onDeleteClick,
    onAddGroupItemClick,
    onPageChange,
    onLabelChange,
    onValueChange,
  } = props

  return (
    <MenuEditorItemContainer key={index} {...provided.draggableProps} ref={provided.innerRef}>
      {childIndex === undefined && <MenuEditorItemDrag {...provided.dragHandleProps}>
        <Icon icon='grip-vertical' />
      </MenuEditorItemDrag>}

      {item.type === NavigationMenuItemType.PAGE && <>
        <MenuEditorItemIcon>
          <Icon icon='file-alt' />
        </MenuEditorItemIcon>

        <MenuEditorItemLabel>
          <select
            value={item.page}
            onChange={(e) => onPageChange(e.target.value as NavigationMenuItemPage, index)}
          >
            {NavigationHelper.getPages().map((page, index) => {
              return (
                <option key={index} value={page}>
                  {NavigationHelper.getNavigationItemPageLabel(page)}
                </option>
              )
            })}
          </select>
        </MenuEditorItemLabel>
      </>}

      {item.type === NavigationMenuItemType.LINK && <>
        <MenuEditorItemIcon>
          <Icon icon='link' />
        </MenuEditorItemIcon>

        <MenuEditorItemLabel>
          <input
            type='text'
            placeholder={t('MenuEditor::Label')}
            value={item.label}
            onChange={(e) => onLabelChange(item, e.currentTarget.value, index, childIndex)}
          />
        </MenuEditorItemLabel>

        <MenuEditorItemValue>
          <input
            type='text'
            placeholder={t('MenuEditor::https://')}
            value={item.value}
            onChange={(e) => onValueChange(item, e.currentTarget.value, index, childIndex)}
          />
        </MenuEditorItemValue>
      </>}

      {item.type === NavigationMenuItemType.DIVIDER && <>
        <MenuEditorItemIcon>
          <Icon icon='horizontal-rule' />
        </MenuEditorItemIcon>

        <MenuEditorItemLabel>
          {t('MenuEditor::Divider')}
        </MenuEditorItemLabel>
      </>}

      {item.type === NavigationMenuItemType.GROUP && <Group>
        <GroupInfo hasItems={item.items && item.items.length > 0}>
          <MenuEditorItemIcon>
            <Icon icon='layer-group' />
          </MenuEditorItemIcon>

          <MenuEditorItemLabel>
            <input
              type='text'
              placeholder={t('MenuEditor::Label')}
              value={item.label}
              onChange={(e) => onLabelChange(item, e.currentTarget.value, index, childIndex)}
            />
          </MenuEditorItemLabel>
        </GroupInfo>

        <GroupItems>
          {item.items && item.items.length > 0 && item.items.map((childNavigationMenuItem, childIndex) => {
            const key = `${childNavigationMenuItem.type}-${childIndex}`

            return (
              <Draggable key={key} draggableId={key} index={childIndex}>
                {(childDraggableProvided) => {
                  return (
                    <ChildItemContainer>
                      <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" >
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="Group" stroke="#D8D8D8">
                            <rect id="Rectangle" fill="#D8D8D8" x="0.5" y="0.5" width="1" height="3"></rect>
                            <rect id="Rectangle" fill="#D8D8D8" x="0.5" y="7.5" width="1" height="4"></rect>
                            <rect id="Rectangle" fill="#D8D8D8" x="4.5" y="10.5" width="3" height="1"></rect>
                          </g>
                        </g>
                      </svg>
                      <MenuEditorItem
                        key={index}
                        index={index}
                        childIndex={childIndex}
                        item={childNavigationMenuItem}
                        provided={childDraggableProvided}
                        onHomeClick={onHomeClick}
                        onDeleteClick={() => onDeleteClick(childNavigationMenuItem, index, childIndex)}
                        onAddGroupItemClick={onAddGroupItemClick}
                        onPageChange={onPageChange}
                        onLabelChange={onLabelChange}
                        onValueChange={onValueChange}
                      />
                    </ChildItemContainer>
                  )
                }}
              </Draggable>
            )
          })}
          {/* <div style={{ height: 1 }} />
          {childDroppableProvided.placeholder} */}
        </GroupItems>
      </Group>}

      <MenuEditorItemActions>
        {item.type === NavigationMenuItemType.GROUP && <MenuEditorItemAction
          type='success'
          data-tip={t('MenuEditor::Add item')}
          data-place='top'
          onClick={() => onAddGroupItemClick(item, index)}
        >
          <Icon icon='plus-circle' />
        </MenuEditorItemAction>}
        {item.type === NavigationMenuItemType.PAGE && <MenuEditorItemAction
          type={item.home ? 'success' : undefined}
          data-tip={item.home ? t('MenuEditor::This page is set as landing page') : t('MenuEditor::Set as landing page')}
          data-place='top'
          onClick={() => onHomeClick(item, index)}
        >
          <Icon icon='home' />
        </MenuEditorItemAction>}
        <MenuEditorItemAction
          type='danger'
          data-tip={t('MenuEditor::Remove option')}
          data-place='top'
          onClick={() => onDeleteClick(item, index)}
        >
          <Icon icon='circle-xmark' />
        </MenuEditorItemAction>
      </MenuEditorItemActions>
    </MenuEditorItemContainer>
  )
}


export default MenuEditorItem