import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { EditBlockProps } from '../Block'
import { useTranslation } from 'react-i18next'
import PowerSelect from '../../Form/PowerSelect'
import CheckboxInput from '../../Form/CheckboxInput'
import { useDebouncedCallback } from 'use-debounce'
import InputLimitSection from './InputSections/LimitSection'
import InputOptionsSection from './InputSections/OptionsSection'
import { AddressInputBlock, ContentBlockType, CountriesInputBlock, DateInputBlock, DropdownInputBlock, EmailInputBlock, FileUploadInputBlock, LinkInputBlock, LongTextInputBlock, NumberInputBlock, PhonenumberInputBlock, SliderInputBlock, TextInputBlock } from '../../../types'


const Container = styled.div`
	background: white;
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const Description = styled.div`
	display: flex;
	width: 100%;

	.form-item {
		width: 100%;
	}
`

type IProps = EditBlockProps<
	AddressInputBlock |
	CountriesInputBlock |
	DateInputBlock |
	DropdownInputBlock |
	EmailInputBlock |
	FileUploadInputBlock |
	LinkInputBlock |
	LongTextInputBlock |
	NumberInputBlock |
	PhonenumberInputBlock |
	TextInputBlock |
	SliderInputBlock
>

const InputBlock = (props: IProps) => {
	const { t } = useTranslation()
	const { block, onChange } = props
	const [type, setType] = React.useState(block.type)
	const [name, setName] = React.useState(block.name)
	const [required, setRequired] = React.useState(block.required)
	const [description, setDescription] = React.useState(block.description)

	const debouncedTitleChange = useDebouncedCallback(
		(newTitle) => onChange({ ...block, name: newTitle }), 500
	);

	const debouncedDescriptionChange = useDebouncedCallback(
		(newDescription) => onChange({ ...block, description: newDescription }), 500);


	React.useEffect(() => {
		setType(block.type)
		setName(block.name)
		setRequired(block.required)
		setDescription(block.description)
	}, [block.type, block.name, block.required, block.description])

	const typeOptions = [
		{ label: t(`ContentBlockType::${ContentBlockType.ADDRESS_INPUT}`), value: ContentBlockType.ADDRESS_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.COUNTRIES_INPUT}`), value: ContentBlockType.COUNTRIES_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.DATE_INPUT}`), value: ContentBlockType.DATE_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.DROPDOWN_INPUT}`), value: ContentBlockType.DROPDOWN_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.EMAIL_INPUT}`), value: ContentBlockType.EMAIL_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.FILE_UPLOAD_INPUT}`), value: ContentBlockType.FILE_UPLOAD_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.LINK_INPUT}`), value: ContentBlockType.LINK_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.LONG_TEXT_INPUT}`), value: ContentBlockType.LONG_TEXT_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.NUMBER_INPUT}`), value: ContentBlockType.NUMBER_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.PHONE_NUMBER_INPUT}`), value: ContentBlockType.PHONE_NUMBER_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.TEXT_INPUT}`), value: ContentBlockType.TEXT_INPUT },
		{ label: t(`ContentBlockType::${ContentBlockType.SLIDER_INPUT}`), value: ContentBlockType.SLIDER_INPUT },
	]
	const selectedType = typeOptions.find(typeOption => typeOption.value === type)

	const onTypeChange = (option) => {
		setType(option.value)

		props.onChange({
			...block,
			type: option.value,
		})
	}

	const onTitleChange = (e) => {
		const newTitle = e.currentTarget.value

		setName(newTitle)

		debouncedTitleChange(newTitle)
	}

	const onRequiredChanged = () => {
		setRequired(!block.required)

		props.onChange({
			...block,
			required: !block.required
		})
	}

	const onDescriptionChange = (e) => {
		const newDescription = e.currentTarget.value

		setDescription(newDescription)

		debouncedDescriptionChange(newDescription)
	}

	return (
		<Container>
			<div className='grid'>
				<div className='grid-cell with-12col'>
					<div className='form-item'>
						<label>
							{t('InputBlock::Title')}
						</label>
						<input
							type='text'
							value={name}
							onChange={onTitleChange}
							placeholder={t('InputBlock::Title')}
						/>
					</div>
				</div>


				<div className='grid-cell with-12col'>
					<Description>
						<div className='form-item'>
							<label>
								{t('InputBlock::Description')}
							</label>
							<input
								type='text'
								value={description}
								onChange={onDescriptionChange}
								placeholder={t('InputBlock::Description')}
							/>
						</div>
					</Description>
				</div>

				<div className='grid-cell with-6col'>
					<div className='form-item'>
						<label>
							{t('InputBlock::Type')}
						</label>
						<PowerSelect
							options={typeOptions}
							value={selectedType}
							onChange={onTypeChange}
						/>
					</div>
				</div>

				<div className='grid-cell with-6col'>
					<div className='form-item'>
						<label>
							{t('InputBlock::Required')}
						</label>
						<CheckboxInput
							label={t('InputBlock::Required')}
							checked={required}
							onChange={onRequiredChanged}
						/>
					</div>
				</div>
			</div>

			{block.type === ContentBlockType.DROPDOWN_INPUT && <InputOptionsSection block={block} onChange={props.onChange} />}
			{block.type === ContentBlockType.NUMBER_INPUT && <InputLimitSection block={block} onChange={props.onChange} />}
			{block.type === ContentBlockType.SLIDER_INPUT && <InputLimitSection block={block} onChange={props.onChange} />}
		</Container>
	)
}

export default InputBlock