import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import { DEFAULT_LOCALE, ORDER_FORM_VALIDITY_DURATIONS } from '../../Constants'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import Alert from '../../components/Alert/Alert'
import LedgerItemHelper from '../../helpers/LedgerItemHelper';
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import EditorContainer from '../../components/Editor/EditorContainer';
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../components/Editor/Editor';
import LocaleHelper from '../../helpers/LocaleHelper';
import PowerSelect from '../../components/Form/PowerSelect';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import MobilityHelper from '../../helpers/MobilityHelper';
import { CurrentUser, DisplayableError, Locale, Settings } from '../../types';
import CheckboxInput from '../../components/Form/CheckboxInput';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  activeTranslationsLocale: Locale
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class OrderForms extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      activeTranslationsLocale: DEFAULT_LOCALE,
      form: null
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onToggleEnableOrderForms = this.onToggleEnableOrderForms.bind(this)
    this.onDefaultLedgerItemLocaleChange = this.onDefaultLedgerItemLocaleChange.bind(this)
    this.onTranslationsLocaleChange = this.onTranslationsLocaleChange.bind(this)
    this.onOrderFormPrefixNumberChange = this.onOrderFormPrefixNumberChange.bind(this)
    this.onOrderFormNumberChange = this.onOrderFormNumberChange.bind(this)
    this.onOrderFormSuffixNumberChange = this.onOrderFormSuffixNumberChange.bind(this)
    this.onOrderFormValidationDurationChange = this.onOrderFormValidationDurationChange.bind(this)
    this.onOrderFormDetailsChange = this.onOrderFormDetailsChange.bind(this)
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    SettingsController
      .update(settings)
      .then(response => {
        if (response.errors) {
          Notification.notifyError(t('SettingsOrderForms::Oops something went wrong'));
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update local
          this.setState({ settings: settings })
          // Update global
          updateSettings(settings)

          Notification.notifySuccess(t('SettingsOrderForms::Settings successfully updated'));
        }
      })
      .catch(console.error)
  }

  onToggleEnableOrderForms() {
    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        order_forms_enabled: !settings.order_forms_enabled
      }
    })
  }

  onDefaultLedgerItemLocaleChange(e) {
    const newLocale = e.currentTarget.value

    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        default_ledger_item_locale: newLocale
      }
    })
  }

  onOrderFormPrefixNumberChange(e) {
    const prefixOrderFormNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        prefix_order_form_number: prefixOrderFormNumber
      },
    });
  }

  onOrderFormNumberChange(e) {
    const orderFormNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        order_form_number: orderFormNumber,
      },
    });
  }

  onOrderFormSuffixNumberChange(e) {
    const suffixOrderFormNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        suffix_order_form_number: suffixOrderFormNumber,
      },
    });
  }

  onOrderFormValidationDurationChange(e) {
    const validityDuration = e.currentTarget.value;

    const { settings } = this.state;

    settings.order_form_validity_duration = validityDuration;

    this.setState({
      settings: settings
    });
  }

  onTranslationsLocaleChange(option) {
    this.setState({ activeTranslationsLocale: option.value });
  }

  onOrderFormDetailsChange(details: string) {
    const { settings, activeTranslationsLocale: activeTranslationLocale } = this.state;

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationLocale, settings.translations, 'default_order_form_details', details)

    this.setState({
      settings: {
        ...settings,
        translations: { ...updatedTranslations }
      }
    });
  }


  render() {
    const { t } = this.props
    const { settings, errors, form, activeTranslationsLocale: activeTranslationLocale } = this.state

    if (form) {
      const localeOptions = LocaleHelper.getLocaleOptions()
      const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationLocale)

      return (
        <>
          <Helmet>
            <title>{t('SettingsOrderForms::{{__appName}} | Order forms')} </title>
          </Helmet>
          <ScrollToTopOnMount />
          <form onSubmit={this.onFormSubmit}>
            <Title>{t('SettingsOrderForms::Order forms')}</Title>

            <Panel>
              <div className='grid'>
                <div className='grid-cell with-4col'>
                  <div className='form-item'>
                    <CheckboxInput
                      onChange={this.onToggleEnableOrderForms}
                      checked={settings.order_forms_enabled}
                      label={t('SettingsOrderForms::Enabled')}
                    />
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsOrderForms::Default order form language')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.default_ledger_item_locale} onChange={this.onDefaultLedgerItemLocaleChange}>
                        {localeOptions.map(locale => {
                          return (
                            <option key={locale.value} value={locale.value}>{locale.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsOrderForms::Order form number format')}</label>
                    <div style={{ marginBottom: 8 }}>
                      <Alert
                        type='info'
                        text={
                          <Trans t={t}>
                            <div>The number of your next order form is "<b>{{ prefix_order_form_number: settings.prefix_order_form_number }}{{ next_order_form_number: LedgerItemHelper.getSuccessiveLedgerNumber(settings.order_form_number) }}{{ suffixed_order_form_number: settings.suffix_order_form_number }}</b>"</div>
                          </Trans>
                        }
                      />
                    </div>

                    <div className='settings-numbering-format'>
                      <input
                        type='text'
                        name='prefix'
                        placeholder={t('SettingsOrderForms::Prefix')}
                        value={settings.prefix_order_form_number}
                        onChange={this.onOrderFormPrefixNumberChange}
                      />
                      <input
                        type='number'
                        name='number'
                        placeholder='1'
                        value={settings.order_form_number}
                        onChange={this.onOrderFormNumberChange}
                        pattern='\d*'
                        min={0}
                        required
                      />
                      <input
                        type='text'
                        name='suffix'
                        placeholder={t('SettingsOrderForms::Suffix')}
                        value={settings.suffix_order_form_number}
                        onChange={this.onOrderFormSuffixNumberChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsOrderForms::Default expiry date (in days)')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.order_form_validity_duration} onChange={this.onOrderFormValidationDurationChange}>
                        {ORDER_FORM_VALIDITY_DURATIONS.map(duration => {
                          return (
                            <option key={duration} value={duration}>{t(`OrderFormValidityDurations::${duration}`)}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsOrderForms::Default order form footnote')}</label>

                    <PowerSelect
                      options={localeOptions}
                      value={selectedLocaleOption}
                      onChange={this.onTranslationsLocaleChange}
                      noOptionsMessage={(value) => t('SettingsOrderForms::No language found.')}
                      theme={ReactSelectTheme}
                    />

                    <EditorContainer style={{ marginTop: 8 }}>
                      <Editor
                        model={MobilityHelper.getTranslation(activeTranslationLocale, settings.translations, 'default_order_form_details')}
                        onModelChange={this.onOrderFormDetailsChange}
                        config={{
                          ...MINIMAL_EDITOR_CONFIG,
                          heightMin: 120,
                          heightMax: 120
                        }}
                      />
                    </EditorContainer>
                  </div>
                </div>
              </div>

              <div className='field-actions'>
                <input type='submit' style={{ display: 'none' }} />
                <div className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('SettingsOrderForms::Save')} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>
      )
    }
    else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderForms))