import styled from 'styled-components'

const ConversationListLoader = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
`

export default ConversationListLoader