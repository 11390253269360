import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import { SettingsController, WorkspaceController } from '../../controllers'
import { ISettingsForm } from '../../controllers/SettingsController'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import TimeFormatter from '../../utilities/TimeFormatter'
import Notification from '../../utilities/Notification'
import { updateSettings, updateWorkspace } from '../../store/authentication/actions'
import TimeHelper from '../../helpers/TimeHelper';
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import PowerSelect from '../../components/Form/PowerSelect';
import BillableRoundingHelper from '../../helpers/BillableRoundingHelper';
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import { CurrentUser, DisplayableError, Settings, Workspace } from '../../types';
import Title from '../../components/Settings/Title';
import NumberFormatter from '../../utilities/NumberFormatter';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  updateWorkspace: typeof updateWorkspace
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  workspace: Workspace
  setting: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class DisplayPreferences extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace } } = props
    const { setting } = workspace

    this.state = {
      workspace: workspace,
      setting: setting,
      errors: [],
      form: null
    }

    this.onTimezoneChange = this.onTimezoneChange.bind(this)
    this.onDateformatChange = this.onDateformatChange.bind(this)
    this.onTimeFormatChange = this.onTimeFormatChange.bind(this)
    this.onStartWeekOnChange = this.onStartWeekOnChange.bind(this)
    this.onBillableRoundingChange = this.onBillableRoundingChange.bind(this)
    this.onNumberFormatChange = this.onNumberFormatChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onTimezoneChange(option) {
    const { workspace } = this.state;

    this.setState({
      workspace: {
        ...workspace,
        timezone: option.value
      }
    });
  }

  onDateformatChange(option) {
    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        date_format: option.value
      }
    });
  }

  onTimeFormatChange(option) {
    const { setting } = this.state

    this.setState({
      setting: {
        ...setting,
        time_format: option.value,
      }
    });
  }

  onStartWeekOnChange(option) {
    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        start_week_on: option.value,
      }
    })
  }

  onBillableRoundingChange(option) {
    const { setting } = this.state;

    this.setState({
      setting: {
        ...setting,
        billable_rounding: option.value,
      }
    })
  }

  onNumberFormatChange(e) {
    const { setting } = this.state;
    const numberFormat = e.currentTarget.value;

    this.setState({
      setting: {
        ...setting,
        number_format: numberFormat
      }
    })
  }

  async onFormSubmit(e) {

    e.preventDefault()
    const { workspace, setting } = this.state
    const { updateWorkspace, updateSettings, t } = this.props

    try {
      let errors: DisplayableError[] = []
      const workspaceResponse = await WorkspaceController.update(workspace)

      if (workspaceResponse.errors) {
        errors = [...workspaceResponse.errors]
      }
      else {
        const workspace = workspaceResponse;

        updateWorkspace(workspace)

        this.setState({
          workspace: workspace
        });
      }

      const settingResponse = await SettingsController.update(setting)

      if (settingResponse.errors) {
        errors = [...settingResponse.errors]
      }
      else {
        const setting = settingResponse as Settings

        updateSettings(setting)

        this.setState({
          setting: setting
        });
      }

      if (errors.length > 0) {
        Notification.notifyError(t('DisplayPreferences::Oops something went wrong'));
        this.setState({ errors: errors })
      } else {
        Notification.notifySuccess(t('DisplayPreferences::Settings successfully updated'));
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const { t } = this.props
    const { workspace, setting, errors, form } = this.state

    if (form) {
      const {
        time_zones: timeZones,
        date_formats: dateFormats,
        time_formats: timeFormats,
        start_of_week: startOfWeeks,
        billable_roundings: billableRoundings,
        currency_formats: currencyFormats
      } = form

      const timezoneOptions = timeZones
      const selectedTimezoneOption = timeZones.find(option => option.value === workspace.timezone)
      const dateFormatOptions = dateFormats.map(format => ({ label: format, value: format }))
      const selectedDateFormatOption = dateFormatOptions.find(option => option.value === setting.date_format)
      const timeFormatOptions = timeFormats.map(format => ({ label: String(TimeFormatter.durationFormat(TimeHelper.getCurrentTimeInSeconds(), format)), value: format }))
      const selectedTimeFormatOption = timeFormatOptions.find(option => option.value === setting.time_format)
      const billableRoundingOptions = billableRoundings.map(billableRounding => ({ label: BillableRoundingHelper.labelForValue(billableRounding), value: billableRounding }))
      const selectedBillableRoundingOption = billableRoundingOptions.find(option => option.value === setting.billable_rounding)
      const startOfWeekOptions = startOfWeeks.map(startOfWeek => ({ label: startOfWeek === 'monday' ? t('DisplayPreferences::Monday') : t('DisplayPreferences::Sunday'), value: startOfWeek }))
      const selectedStartOfWeekOption = startOfWeekOptions.find(option => option.value === setting.start_week_on)

      return (
        <>
          <Helmet>
            <title>{t('DisplayPreferences::{{__appName}} | Display preferences')}</title>
          </Helmet>
          <ScrollToTopOnMount />
          <form onSubmit={this.onFormSubmit}>
            <Title>{t('DisplayPreferences::Display preferences')}</Title>
            <Panel>
              <div className="grid">
                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('DisplayPreferences::Timezone')}</label>
                    <PowerSelect
                      options={timezoneOptions}
                      value={selectedTimezoneOption}
                      onChange={this.onTimezoneChange}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('DisplayPreferences::Date format')}</label>
                    <PowerSelect
                      options={dateFormatOptions}
                      value={selectedDateFormatOption}
                      onChange={this.onDateformatChange}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('DisplayPreferences::Time format')}</label>
                    <PowerSelect
                      options={timeFormatOptions}
                      value={selectedTimeFormatOption}
                      onChange={this.onTimeFormatChange}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('DisplayPreferences::Number format')}</label>
                    <div className="select-wrapper">
                      <select value={setting.number_format} onChange={this.onNumberFormatChange}>
                        {currencyFormats.map((format, index) => {
                          return (
                            <option value={format} key={format}>{NumberFormatter.formatLabels[format]}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('DisplayPreferences::Time entry rounding')}</label>
                    <PowerSelect
                      options={billableRoundingOptions}
                      value={selectedBillableRoundingOption}
                      onChange={this.onBillableRoundingChange}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className="grid-cell with-12col">
                  <div className="form-item">
                    <label>{t('DisplayPreferences::Start of week')}</label>
                    <PowerSelect
                      options={startOfWeekOptions}
                      value={selectedStartOfWeekOption}
                      onChange={this.onStartWeekOnChange}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>
              </div>

              <div className="field-actions">
                <input type="submit" style={{ display: 'none' }} />
                <div className="popover-wrapper">
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('DisplayPreferences::Save')} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>
      )
    } else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    updateWorkspace: (workspace: Workspace) => dispatch(updateWorkspace(workspace)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DisplayPreferences))