import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { WithTranslation, withTranslation } from 'react-i18next'
import IntegrationFormTitle from '../IntegrationFormTitle'
import { OctopusFormData, OctopusIntegrationConfig } from '../../../types'
import IntegrationFormLabel from '../IntegrationFormLabel'
import PowerSelect from '../../Form/PowerSelect'
import ReactSelectTheme from '../../Form/ReactSelectTheme'
import Tooltip from '../../Tooltips/Tooltip'

type IProps = {
	config: OctopusIntegrationConfig
	formData: OctopusFormData
	onConfigChange: (config: OctopusIntegrationConfig) => void
} & WithTranslation

interface IState { }

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		color: white;
		margin-bottom: ${Style.spacing.x2};
		font-weight: bold;
	}
`

class OctopusForm extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.onInvoiceEmailAddressChange = this.onInvoiceEmailAddressChange.bind(this)
		this.onExpenseEmailAddressChange = this.onExpenseEmailAddressChange.bind(this)
		this.onDocumentEmailAddressChange = this.onDocumentEmailAddressChange.bind(this)
		this.onInfoClick = this.onInfoClick.bind(this)
	}

	onInvoiceEmailAddressChange(e) {
		const { config, onConfigChange } = this.props

		onConfigChange({ ...config, invoice_mailbox_address: e.currentTarget.value })
	}

	onExpenseEmailAddressChange(e) {
		const { config, onConfigChange } = this.props

		onConfigChange({ ...config, expense_mailbox_address: e.currentTarget.value })
	}

	onDocumentEmailAddressChange(e) {
		const { config, onConfigChange } = this.props

		onConfigChange({ ...config, document_mailbox_address: e.currentTarget.value })
	}

	onInfoClick() {
		window.open('https://login.octopus.be/manual/NL/index.html?management_files.htm', '_blank')
	}

	render() {
		const { config, t } = this.props

		return (
			<Container>
				<IntegrationFormTitle>
					{t('OctopusForm::Settings')}
				</IntegrationFormTitle>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('MailSyncForm::Invoice mailbox address')}

						<span onClick={this.onInfoClick}>
							<Tooltip
								content=''
								containerStyle={{ marginLeft: 8 }}

							/>
						</span>
					</IntegrationFormLabel>
					<input
						type='text'
						value={config.invoice_mailbox_address}
						placeholder='ocr-019546-82-V@import.octopus.be'
						onChange={this.onInvoiceEmailAddressChange}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('MailSyncForm::Expense mailbox address')}

						<span onClick={this.onInfoClick}>
							<Tooltip
								content=''
								containerStyle={{ marginLeft: 8 }}

							/>
						</span>
					</IntegrationFormLabel>
					<input
						type='text'
						value={config.expense_mailbox_address}
						placeholder='ocr-019546-82-A@import.octopus.be'
						onChange={this.onExpenseEmailAddressChange}
					/>
				</div>

				<div className='form-item'>
					<IntegrationFormLabel>
						{t('MailSyncForm::Document mailbox address')}

						<span onClick={this.onInfoClick}>
							<Tooltip
								content=''
								containerStyle={{ marginLeft: 8 }}

							/>
						</span>
					</IntegrationFormLabel>
					<input
						type='text'
						value={config.document_mailbox_address}
						placeholder='uid-11683-16@import.octopus.be'
						onChange={this.onDocumentEmailAddressChange}
					/>
				</div>
			</Container>
		)
	}
}

export default withTranslation()(OctopusForm)