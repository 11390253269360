import * as React from 'react'
import { InvoicesController } from '../../controllers'
import Notification from '../../utilities/Notification'
import moment from '../../utilities/Moment'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import { withRouter, RouteComponentProps } from 'react-router'
import RouteHelper from '../../helpers/RouteHelper'
import LedgerItemHelper from '../../helpers/LedgerItemHelper'
import ERoute from '../../ERoute';
import { IActionListItem } from '../ActionList/ActionList'
import LedgerItemAvatar from '../Avatar/LedgerItemAvatar'
import PageLoader from '../Page/PageLoader'
import { Dispatch } from 'redux'
import { showConfirmModal, showSendLedgerItemModal } from '../../store/modals/actions'
import { connect } from 'react-redux'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { Helmet } from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'
import ButtonPanel from '../Button/ButtonPanel'
import OrderFormAvatar from '../Avatar/OrderFormAvatar'
import DeliveryNoteAvatar from '../Avatar/DeliveryNoteAvatar'
import ResourceTable from '../Resource/ResourceTable'
import ResourceTableRow from '../Resource/ResourceTableRow'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import NumberFormatter from '../../utilities/NumberFormatter'
import ResourceTableRowActions from '../Resource/ResourceTableRowActions'
import RecurringScheduleHelper from '../../helpers/RecurringScheduleHelper'
import Badge from '../Badge/Badge'
import { CurrentUser, LedgerItem, LedgerItemStatus, LedgerItemType, ResourceListFilterType } from '../../types'
import { AppState } from '../../store'
import ReactTooltip from 'react-tooltip'

export interface IComponentProps {
  type: LedgerItemType
  contactId?: string
  projectId?: string
  dealId?: string
  primaryActionEnabled?: boolean
  onItemUpdated?: () => void,
  onItemDeleted?: () => void,
}

interface IStateToProps {
  currentUser: CurrentUser
}
interface IDispatchToProps {
  showSendLedgerItemModal: typeof showSendLedgerItemModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IComponentProps & IStateToProps & IDispatchToProps & RouteComponentProps<any> & WithTranslation

interface IState {
  ledgerItems: LedgerItem[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  sortValue: string
  isFetching: boolean
  filters: any
  searchValue: string
}

class LedgerItemsTable extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      ledgerItems: [],
      currentPage: 1,
      totalPages: 1,
      didInitialLoad: false,
      sortValue: props.type !== LedgerItemType.RECURRING_INVOICE ? LocalStorage.get(LocalStorageKey.LEDGER_ITEM_SORT_VALUE, 'number_desc') : LocalStorage.get(LocalStorageKey.RECURRING_INVOICE_SORT_VALUE, 'recurring_schedules.next_occurrence_on_asc'),
      isFetching: false,
      filters: {},
      searchValue: ''
    }

    this.onNewLedgerItemClick = this.onNewLedgerItemClick.bind(this)
    this.onTableLedgerItemSend = this.onTableLedgerItemSend.bind(this)
    this.onTableLedgerItemClick = this.onTableLedgerItemClick.bind(this);
    this.onTableLedgerItemApprovedClick = this.onTableLedgerItemApprovedClick.bind(this);
    this.onTableLedgerItemRejectClick = this.onTableLedgerItemRejectClick.bind(this);
    this.onTableLedgerItemPaidClick = this.onTableLedgerItemPaidClick.bind(this);
    this.onTableLedgerItemEditClick = this.onTableLedgerItemEditClick.bind(this);
    this.onTableLedgerItemDownloadClick = this.onTableLedgerItemDownloadClick.bind(this);
    this.onTableLedgerItemDeleteClick = this.onTableLedgerItemDeleteClick.bind(this);
    this.onTableLedgerItemActionClick = this.onTableLedgerItemActionClick.bind(this)
    this.onLedgerItemFiltersChange = this.onLedgerItemFiltersChange.bind(this)
    this.onLedgerItemSortValueChange = this.onLedgerItemSortValueChange.bind(this)
    this.onLedgerItemSearchChange = this.onLedgerItemSearchChange.bind(this)
    this.onLedgerItemSearchSubmit = this.onLedgerItemSearchSubmit.bind(this)
    this.onLedgerItemClearFilters = this.onLedgerItemClearFilters.bind(this)
  }

  getTitle() {
    const { type, t } = this.props

    switch (type) {
      case LedgerItemType.ORDER_FORM:
        return t('LedgerItemsTable::{{__appName}} | Order forms')
      case LedgerItemType.DELIVERY_NOTE:
        return t('LedgerItemsTable::{{__appName}} | Delivery notes')
      case LedgerItemType.QUOTATION:
        return t('LedgerItemsTable::{{__appName}} | Quotations')
      case LedgerItemType.INVOICE:
        return t('LedgerItemsTable::{{__appName}} | Invoices')
      case LedgerItemType.CREDIT_NOTE:
        return t('LedgerItemsTable::{{__appName}} | Credit notes')
      case LedgerItemType.RECURRING_INVOICE:
        return t('LedgerItemsTable::{{__appName}} | Recurring invoices')
    }
  }
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  componentDidMount() {
    this.fetchLedgerItems(1)
  }


  fetchLedgerItems(page = 1) {
    const { searchValue, sortValue, filters } = this.state
    const { type, contactId, projectId, dealId } = this.props
    let parameters: any = {
      type: type,
      search: searchValue,
      order: sortValue,
      ...filters,
    };

    if (contactId) {
      parameters = {
        ...parameters,
        contact_id: contactId,
      }
    }

    if (projectId) {
      parameters = {
        ...parameters,
        project_id: projectId,
      }
    }

    if (dealId) {
      parameters = {
        ...parameters,
        deal_id: dealId,
      }
    }

    this.setState({ isFetching: true })
    InvoicesController
      .getInvoices(page, parameters)
      .then(response => {
        const { current_page, total_pages, invoices: newLedgerItems } = response
        const { ledgerItems } = this.state;

        this.setState({
          ledgerItems: newLedgerItems,
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false,
        });
      })
      .catch(console.error)
      .finally(() => {
        this.setState({ isFetching: false })
      })
  }

  updateLedgerItem(ledgerItem, callback) {
    const { t } = this.props
    InvoicesController
      .update(ledgerItem)
      .then(response => {
        const { errors } = response;

        if (errors) {
          Notification.notifyError(t('LedgerItemsTable::Oops something went wrong!'))
        }
        else {
          Notification.notifySuccess(t('LedgerItemsTable::Updated successfully'))
          callback(response);
        }
      });
  }

  deleteLedgerItem(ledgerItem, callback) {
    const { t } = this.props

    InvoicesController
      .delete(ledgerItem.id)
      .then(response => {
        Notification.notifySuccess(t('LedgerItemsTable::Deleted successfully'))
        callback(response);
      })
  }

  getLedgerItemColor(ledgerItem) {
    if (ledgerItem.project) {
      return ledgerItem.project.color;
    }
    else if (ledgerItem.contact) {
      return ledgerItem.contact.color;
    }

    return '#ccc';
  }

  onNewLedgerItemClick() {
    const { history, contactId, projectId, dealId, type } = this.props

    let params = {
      type: type,
    }

    if (contactId) params['contact_id'] = contactId
    if (projectId) params['project_id'] = projectId
    if (dealId) params['deal_id'] = dealId

    history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, params))
  }

  onTableLedgerItemClick(ledgerItem) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: ledgerItem.id }))
  }

  async onTableLedgerItemSend(ledgerItem: LedgerItem) {
    this.props.showSendLedgerItemModal({
      ledgerItemId: ledgerItem.id,
      onSubmit: (ledgerItem) => {
        const { ledgerItems } = this.state

        const index = ledgerItems.findIndex(l => l.id === ledgerItem.id)

        if (index !== -1) {
          ledgerItems[index] = ledgerItem

          this.setState({ ledgerItems: [...ledgerItems] })
        }
      }
    })

  }

  async onTableLedgerItemDuplicate(ledgerItem: LedgerItem) {
    const { t } = this.props
    try {
      const response = await InvoicesController.duplicate(ledgerItem.id)

      if (response) {
        if (response.errors) {
          if (response.errors.quota) {
            Notification.notifyError(t('LedgerItemsTable::Limit reached for current plan'), () => this.props.history.push(ERoute.PATH_ACCOUNT_SUBSCRIPTION))
          }
        } else {
          this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: response.id }))
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onTableCreateCreditNoteClick(ledgerItem: LedgerItem) {
    const { t, showConfirmModal } = this.props

    try {
      showConfirmModal({
        title: t('LedgerItemsTable::Generate credit note'),
        description: t('LedgerItemsTable::You are about to generate a credit note for invoice <b>{{number}}</b>. Are you sure?', { number: ledgerItem.number }),
        action: { label: t('LedgerItemsTable::Generate') },
        onConfirm: async () => {
          const response = await InvoicesController.createCreditNote(ledgerItem.id)

          if (response) {
            if (response.errors) {
              if (response.errors.quota) {
                Notification.notifyError(t('LedgerItemsTable::Limit reached for current plan'), () => this.props.history.push(ERoute.PATH_ACCOUNT_SUBSCRIPTION))
              }
            } else {
              this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: response.id }))
            }
          }
        }
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onTableLedgerItemApprovedClick(ledgerItem) {
    const { onItemUpdated } = this.props

    const updatedLedgerItem = Object.assign({}, ledgerItem, {
      issued_on: ledgerItem.issued_on ? moment(ledgerItem.issued_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      due_on: ledgerItem.due_on ? moment(ledgerItem.due_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      approved_on: moment().format('YYYY-MM-DD')
    });

    this.updateLedgerItem(updatedLedgerItem, (updatedLedgerItem) => {
      const { ledgerItems } = this.state

      const ledgerItemIndex = ledgerItems.findIndex(i => i.id === ledgerItem.id);
      ledgerItems[ledgerItemIndex] = updatedLedgerItem

      this.setState({
        ledgerItems
      }, () => {
        if (onItemUpdated) onItemUpdated()
      });
    });
  }

  onTableLedgerItemRejectClick(ledgerItem) {
    const { onItemUpdated } = this.props

    const updatedLedgerItem: LedgerItem = {
      ...ledgerItem,
      issued_on: ledgerItem.issued_on ? moment(ledgerItem.issued_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      due_on: ledgerItem.due_on ? moment(ledgerItem.due_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      rejected_on: moment().format('YYYY-MM-DD')
    };

    this.updateLedgerItem(updatedLedgerItem, (updatedLedgerItem) => {
      const { ledgerItems } = this.state

      const ledgerItemIndex = ledgerItems.findIndex(i => i.id === ledgerItem.id);
      ledgerItems[ledgerItemIndex] = updatedLedgerItem

      this.setState({
        ledgerItems
      }, () => {
        if (onItemUpdated) onItemUpdated()
      });
    });
  }

  onTableLedgerItemPaidClick(ledgerItem) {
    const { onItemUpdated } = this.props

    const updatedLedgerItem = Object.assign({}, ledgerItem, {
      issued_on: ledgerItem.issued_on ? moment(ledgerItem.issued_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      due_on: ledgerItem.due_on ? moment(ledgerItem.due_on).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      paid_on: moment().format('YYYY-MM-DD')
    });

    this.updateLedgerItem(updatedLedgerItem, (updatedLedgerItem) => {
      const { ledgerItems } = this.state

      const ledgerItemIndex = ledgerItems.findIndex(i => i.id === ledgerItem.id);
      ledgerItems[ledgerItemIndex] = updatedLedgerItem

      this.setState({
        ledgerItems
      }, () => {
        if (onItemUpdated) onItemUpdated()
      });
    });
  }

  onTableLedgerItemEditClick(ledgerItem) {
    const { history } = this.props
    history.push(RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: ledgerItem.id }))
  }

  onTableLedgerItemDownloadClick(ledgerItem, type: 'pdf' | 'ubl' = 'pdf') {
    LedgerItemHelper.download(ledgerItem, type)
  }

  onTableLedgerItemDeleteClick(ledgerItem: LedgerItem) {
    const { onItemDeleted, showConfirmModal, t } = this.props

    const typeLabel = LedgerItemHelper.getTypeLabel(ledgerItem.type)

    showConfirmModal({
      title: t('LedgerItemsTable::Delete {{type}}', { type: typeLabel }),
      description: t('LedgerItemsTable::You are about to delete a {{type}}. Deletion is permanent and irreversible. Are you sure?', { type: typeLabel.toLowerCase() }),
      action: { label: t('LedgerItemsTable::Delete'), isDestructive: true },
      onConfirm: () => {
        this.deleteLedgerItem(ledgerItem, () => {
          const { ledgerItems } = this.state

          const ledgerItemIndex = ledgerItems.findIndex(i => i.id === ledgerItem.id);
          ledgerItems.splice(ledgerItemIndex, 1);

          this.setState({
            ledgerItems
          });

          if (onItemDeleted) onItemDeleted()
        })
      }
    })
  }

  onTableLedgerItemActionClick(key: string, ledgerItem: LedgerItem) {
    switch (key) {
      case 'send': this.onTableLedgerItemSend(ledgerItem)
        break
      case 'duplicate': this.onTableLedgerItemDuplicate(ledgerItem)
        break
      case 'create_credit_note': this.onTableCreateCreditNoteClick(ledgerItem)
        break
      case 'approve': this.onTableLedgerItemApprovedClick(ledgerItem)
        break
      case 'reject': this.onTableLedgerItemRejectClick(ledgerItem)
        break
      case 'view': this.onTableLedgerItemClick(ledgerItem)
        break
      case 'edit': this.onTableLedgerItemEditClick(ledgerItem)
        break
      case 'download': this.onTableLedgerItemDownloadClick(ledgerItem)
        break
      case 'download_ubl': this.onTableLedgerItemDownloadClick(ledgerItem, 'ubl')
        break
      case 'delete': this.onTableLedgerItemDeleteClick(ledgerItem)
        break
      case 'mark_paid': this.onTableLedgerItemPaidClick(ledgerItem)
        break
      default:
        throw Error('[LedgerItemsTable] Unimplemented onTableLedgerItemActionClick')
    }
  }

  onLedgerItemFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchLedgerItems(1)
    })
  }

  onLedgerItemSortValueChange(value: string) {
    const { type } = this.props

    if (type === LedgerItemType.RECURRING_INVOICE) {
      LocalStorage.set(LocalStorageKey.RECURRING_INVOICE_SORT_VALUE, value)
    } else {
      LocalStorage.set(LocalStorageKey.LEDGER_ITEM_SORT_VALUE, value)
    }

    this.setState({
      sortValue: value,
    }, () => {
      this.fetchLedgerItems(1)
    })
  }

  onLedgerItemSearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onLedgerItemSearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchLedgerItems(1))
  }

  onLedgerItemClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchLedgerItems(1))
  }

  renderOrderForms() {
    const { t } = this.props
    const { currentUser: { workspace: { setting } }, contactId, projectId, primaryActionEnabled } = this.props
    const { didInitialLoad, ledgerItems, currentPage, totalPages, sortValue, isFetching, filters, searchValue } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          headers={[
            { title: '' },
            { title: t('LedgerItemsTable::Number') },
            { title: t('LedgerItemsTable::Client') },
            { title: t('LedgerItemsTable::Issued on'), align: 'right' },
            { title: t('LedgerItemsTable::Delivered on'), align: 'right' },
            { title: t('LedgerItemsTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('LedgerItemsTable::New order form')}
              onClick={this.onNewLedgerItemClick}
            />
          ] : null}
          renderRow={(ledgerItem: LedgerItem) => {
            let actions: IActionListItem[] = [
              { key: 'send', icon: 'send', content: t('LedgerItemsTable::Send') },
              { key: 'view', icon: 'eye', content: t('LedgerItemsTable::View') },
              { key: 'duplicate', icon: 'duplicate', content: t('LedgerItemsTable::Duplicate') },
              { key: 'edit', icon: 'edit', content: t('LedgerItemsTable::Edit') },
              { key: 'download', icon: 'pdf', content: t('LedgerItemsTable::Download PDF'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemsTable::Delete'), destructive: true },
            ]

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='30px' maxWidthMobile='50px'>
                  <OrderFormAvatar ledgerItem={ledgerItem} width={30} rounded={true} onClick={() => this.onTableLedgerItemClick(ledgerItem)} />
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem.number}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem?.contact?.name || '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.delivered_on ? moment(ledgerItem.delivered_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {NumberFormatter.formatCurrency(ledgerItem.currency || setting.default_currency, setting.number_format, ledgerItem.amount)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableLedgerItemActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'invoice'}
            description={filtersActive ? t('LedgerItemsTable::No order forms found') : t('LedgerItemsTable::No order forms have been created yet')}
            descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add order form')}
            onDescriptionActionClick={filtersActive ? this.onLedgerItemClearFilters : this.onNewLedgerItemClick}
          />}
          filters={[
            { name: 'contact_id', label: t('LedgerItemsTable::Client'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
            { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(projectId) },
            { name: 'number', label: t('LedgerItemsTable::Number'), type: ResourceListFilterType.STRING },
            { name: 'identifier', label: t('LedgerItemsTable::Identifier'), type: ResourceListFilterType.STRING },
            { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
            { name: 'issued_on', label: t('LedgerItemsTable::Issued on'), type: ResourceListFilterType.DATE },
            { name: 'delivered_on', label: t('LedgerItemsTable::Delivered on'), type: ResourceListFilterType.DATE },
            { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onLedgerItemFiltersChange}
          sortOptions={[
            { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
            { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
            { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
            { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
            { label: t('LedgerItemsTable::Due on ↑'), value: 'due_on_asc' },
            { label: t('LedgerItemsTable::Due on ↓'), value: 'due_on_desc' },
            { label: t('LedgerItemsTable::Delivered on ↑'), value: 'delivered_on_asc' },
            { label: t('LedgerItemsTable::Delivered on ↓'), value: 'delivered_on_desc' },
            { label: t('LedgerItemsTable::Invoiced on ↑'), value: 'invoiced_on_asc' },
            { label: t('LedgerItemsTable::Invoiced on ↓'), value: 'invoiced_on_desc' },
            { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
            { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
            { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
            { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onLedgerItemSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          searchValue={searchValue}
          onSearchChange={this.onLedgerItemSearchChange}
          onSearchSubmit={this.onLedgerItemSearchSubmit}
          isLoading={isFetching}
          maxHeight='65vh'
          stickyHeader={true}
        />}
      </>
    );
  }

  renderDeliveryNotes() {
    const { t } = this.props
    const { currentUser: { workspace: { setting } }, contactId, projectId, primaryActionEnabled } = this.props
    const { didInitialLoad, ledgerItems, currentPage, totalPages, sortValue, isFetching, filters, searchValue } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          headers={[
            { title: '' },
            { title: t('LedgerItemsTable::Number') },
            { title: t('LedgerItemsTable::Client') },
            { title: t('LedgerItemsTable::Delivered on'), align: 'right' },
            { title: t('LedgerItemsTable::Invoiced on'), align: 'right' },
            { title: t('LedgerItemsTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('LedgerItemsTable::New delivery note')}
              onClick={this.onNewLedgerItemClick}
            />
          ] : null}
          renderRow={(ledgerItem: LedgerItem) => {
            let actions: IActionListItem[] = [
              { key: 'send', icon: 'send', content: t('LedgerItemsTable::Send') },
              { key: 'view', icon: 'eye', content: t('LedgerItemsTable::View') },
              { key: 'duplicate', icon: 'duplicate', content: t('LedgerItemsTable::Duplicate') },
              { key: 'edit', icon: 'edit', content: t('LedgerItemsTable::Edit') },
              { key: 'download', icon: 'pdf', content: t('LedgerItemsTable::Download PDF'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemsTable::Delete'), destructive: true },
            ]

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='30px' maxWidthMobile='50px'>
                  <DeliveryNoteAvatar ledgerItem={ledgerItem} width={30} rounded={true} onClick={() => this.onTableLedgerItemClick(ledgerItem)} />
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem.number}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem?.contact?.name || '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.invoiced_on ? moment(ledgerItem.invoiced_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {NumberFormatter.formatCurrency(ledgerItem.currency || setting.default_currency, setting.number_format, ledgerItem.amount)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableLedgerItemActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'invoice'}
            description={filtersActive ? t('LedgerItemsTable::No delivery notes found') : t('LedgerItemsTable::No delivery notes have been created yet')}
            descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add delivery note')}
            onDescriptionActionClick={filtersActive ? this.onLedgerItemClearFilters : this.onNewLedgerItemClick}
          />}
          filters={[
            { name: 'contact_id', label: t('LedgerItemsTable::Client'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
            { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(projectId) },
            { name: 'number', label: t('LedgerItemsTable::Number'), type: ResourceListFilterType.STRING },
            { name: 'identifier', label: t('LedgerItemsTable::Identifier'), type: ResourceListFilterType.STRING },
            { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
            { name: 'issued_on', label: t('LedgerItemsTable::Delivered on'), type: ResourceListFilterType.DATE },
            { name: 'invoiced_on', label: t('LedgerItemsTable::Invoiced on'), type: ResourceListFilterType.DATE },
            { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onLedgerItemFiltersChange}
          sortOptions={[
            { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
            { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
            { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
            { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
            { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
            { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
            { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
            { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onLedgerItemSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          searchValue={searchValue}
          onSearchChange={this.onLedgerItemSearchChange}
          onSearchSubmit={this.onLedgerItemSearchSubmit}
          isLoading={isFetching}
          maxHeight='65vh'
          stickyHeader={true}
        />}
      </>
    );
  }

  renderQuotations() {
    const { t } = this.props
    const { currentUser: { workspace: { setting } }, contactId, projectId, primaryActionEnabled } = this.props
    const { didInitialLoad, ledgerItems, currentPage, totalPages, sortValue, isFetching, filters, searchValue } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          headers={[
            { title: '' },
            { title: t('LedgerItemsTable::Number') },
            { title: t('LedgerItemsTable::Client') },
            { title: t('LedgerItemsTable::Issued on'), align: 'right' },
            { title: t('LedgerItemsTable::Due on'), align: 'right' },
            { title: t('LedgerItemsTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('LedgerItemsTable::New quote')}
              onClick={this.onNewLedgerItemClick}
            />
          ] : null}
          renderRow={(ledgerItem: LedgerItem) => {
            const isApproved = ledgerItem.approved_on !== null
            const isRejected = ledgerItem.rejected_on !== null

            let actions: IActionListItem[] = [
              { key: 'send', icon: 'send', content: t('LedgerItemsTable::Send') },
              { key: 'approve', icon: 'thumbs-up', content: t('LedgerItemsTable::Approved'), visible: !isApproved },
              { key: 'reject', icon: 'thumbs-down', content: t('LedgerItemsTable::Rejected'), visible: !isRejected },
              { key: 'view', icon: 'eye', content: t('LedgerItemsTable::View') },
              { key: 'duplicate', icon: 'duplicate', content: t('LedgerItemsTable::Duplicate') },
              { key: 'edit', icon: 'edit', content: t('LedgerItemsTable::Edit') },
              { key: 'download', icon: 'pdf', content: t('LedgerItemsTable::Download PDF'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemsTable::Delete'), destructive: true },
            ]

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='30px' maxWidthMobile='50px'>
                  <LedgerItemAvatar width={30} ledgerItem={ledgerItem} rounded={true} onClick={() => this.onTableLedgerItemClick(ledgerItem)} />
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem.number}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem?.contact?.name || '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.due_on ? moment(ledgerItem.due_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {NumberFormatter.formatCurrency(ledgerItem.currency || setting.default_currency, setting.number_format, ledgerItem.amount)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableLedgerItemActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'invoice'}
            description={filtersActive ? t('LedgerItemsTable::No quotes found') : t('LedgerItemsTable::No quotes have been created yet')}
            descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add quote')}
            onDescriptionActionClick={filtersActive ? this.onLedgerItemClearFilters : this.onNewLedgerItemClick}
          />}
          filters={[
            { name: 'contact_id', label: t('LedgerItemsTable::Client'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
            { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(projectId) },
            { name: 'number', label: t('LedgerItemsTable::Number'), type: ResourceListFilterType.STRING },
            { name: 'identifier', label: t('LedgerItemsTable::Identifier'), type: ResourceListFilterType.STRING },
            { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
            { name: 'issued_on', label: t('LedgerItemsTable::Issued on'), type: ResourceListFilterType.DATE },
            { name: 'due_on', label: t('LedgerItemsTable::Due on'), type: ResourceListFilterType.DATE },
            { name: 'approved_on', label: t('LedgerItemsTable::Approved on'), type: ResourceListFilterType.DATE },
            { name: 'rejected_on', label: t('LedgerItemsTable::Rejected on'), type: ResourceListFilterType.DATE },
            { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onLedgerItemFiltersChange}
          sortOptions={[
            { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
            { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
            { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
            { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
            { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
            { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
            { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
            { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onLedgerItemSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          isLoading={isFetching}
          searchValue={searchValue}
          onSearchChange={this.onLedgerItemSearchChange}
          onSearchSubmit={this.onLedgerItemSearchSubmit}
          maxHeight='65vh'
          stickyHeader={true}
        />}
      </>
    );
  }

  renderProFormaInvoices() {
    const { t } = this.props
    const { currentUser: { workspace: { setting } }, contactId, projectId, primaryActionEnabled } = this.props
    const { didInitialLoad, ledgerItems, totalPages, currentPage, isFetching, filters, sortValue, searchValue } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('LedgerItemsTable::New Proforma invoice')}
              onClick={this.onNewLedgerItemClick}
            />
          ] : null}
          headers={[
            { title: '' },
            { title: t('LedgerItemsTable::Number') },
            { title: t('LedgerItemsTable::Client') },
            { title: t('LedgerItemsTable::Issued on'), align: 'right' },
            { title: t('LedgerItemsTable::Due on'), align: 'right' },
            { title: t('LedgerItemsTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          renderRow={(ledgerItem: LedgerItem) => {
            let actions: IActionListItem[] = [
              { key: 'send', icon: 'send', content: t('LedgerItemsTable::Send') },
              { key: 'view', icon: 'eye', content: t('LedgerItemsTable::View') },
              { key: 'duplicate', icon: 'duplicate', content: t('LedgerItemsTable::Duplicate') },
              { key: 'edit', icon: 'edit', content: t('LedgerItemsTable::Edit') },
              { key: 'download', icon: 'pdf', content: t('LedgerItemsTable::Download PDF'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemsTable::Delete'), destructive: true },
            ]

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='30px' maxWidthMobile='50px'>
                  <LedgerItemAvatar width={30} ledgerItem={ledgerItem} rounded={true} onClick={() => this.onTableLedgerItemClick(ledgerItem)} />
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem.number}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem?.contact?.name || '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.due_on ? moment(ledgerItem.due_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {NumberFormatter.formatCurrency(ledgerItem.currency || setting.default_currency, setting.number_format, ledgerItem.amount)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableLedgerItemActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'invoice'}
            description={filtersActive ? t('LedgerItemsTable::No Proforma invoices found') : t('LedgerItemsTable::No Proforma invoices have been created yet')}
            descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add Proforma invoice')}
            onDescriptionActionClick={filtersActive ? this.onLedgerItemClearFilters : this.onNewLedgerItemClick}
          />}
          filters={[
            { name: 'contact_id', label: t('LedgerItemsTable::Client'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
            { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(projectId) },
            { name: 'number', label: t('LedgerItemsTable::Number'), type: ResourceListFilterType.STRING },
            { name: 'po_number', label: t('LedgerItemsTable::PO number'), type: ResourceListFilterType.STRING, visible: setting.po_number_enabled },
            { name: 'identifier', label: t('LedgerItemsTable::Identifier'), type: ResourceListFilterType.STRING },
            { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
            { name: 'issued_on', label: t('LedgerItemsTable::Issued on'), type: ResourceListFilterType.DATE },
            { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onLedgerItemFiltersChange}
          sortOptions={[
            { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
            { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
            { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
            { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
            { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
            { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
            { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
            { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onLedgerItemSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          isLoading={isFetching}
          searchValue={searchValue}
          onSearchChange={this.onLedgerItemSearchChange}
          onSearchSubmit={this.onLedgerItemSearchSubmit}
          maxHeight='65vh'
          stickyHeader={true}
        />}
      </>
    )
  }

  renderInvoices() {
    const { t } = this.props
    const { currentUser: { workspace: { setting } }, contactId, projectId, primaryActionEnabled } = this.props
    const { didInitialLoad, ledgerItems, totalPages, currentPage, isFetching, filters, sortValue, searchValue } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('LedgerItemsTable::New invoice')}
              onClick={this.onNewLedgerItemClick}
            />
          ] : null}
          headers={[
            { title: '' },
            { title: t('LedgerItemsTable::Number') },
            { title: t('LedgerItemsTable::Client') },
            { title: t('LedgerItemsTable::Issued on'), align: 'right' },
            { title: t('LedgerItemsTable::Due on'), align: 'right' },
            { title: t('LedgerItemsTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          renderRow={(ledgerItem: LedgerItem) => {
            const isPaid = ledgerItem.paid_on !== null

            let actions: IActionListItem[] = [
              { key: 'send', icon: 'send', content: t('LedgerItemsTable::Send') },
              { key: 'create_credit_note', icon: 'invoice', content: t('LedgerItemsTable::Create credit note') },
              { key: 'view', icon: 'eye', content: t('LedgerItemsTable::View') },
              { key: 'duplicate', icon: 'duplicate', content: t('LedgerItemsTable::Duplicate') },
              { key: 'edit', icon: 'edit', content: t('LedgerItemsTable::Edit') },
              { key: 'download', icon: 'pdf', content: t('LedgerItemsTable::Download PDF'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'download_ubl', icon: 'xml', content: t('LedgerItemsTable::Download UBL'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemsTable::Delete'), destructive: true },
            ]

            if (!isPaid) {
              actions = [
                { key: 'mark_paid', icon: 'money-bill-wave', content: t('LedgerItemsTable::Mark as paid') },
                ...actions
              ]
            }

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='30px' maxWidthMobile='50px'>
                  <LedgerItemAvatar width={30} ledgerItem={ledgerItem} rounded={true} onClick={() => this.onTableLedgerItemClick(ledgerItem)} />
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem.number}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem?.contact?.name || '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.due_on ? moment(ledgerItem.due_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {NumberFormatter.formatCurrency(ledgerItem.currency || setting.default_currency, setting.number_format, ledgerItem.amount)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableLedgerItemActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'invoice'}
            description={filtersActive ? t('LedgerItemsTable::No invoices found') : t('LedgerItemsTable::No invoices have been created yet')}
            descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add invoice')}
            onDescriptionActionClick={filtersActive ? this.onLedgerItemClearFilters : this.onNewLedgerItemClick}
          />}
          filters={[
            { name: 'contact_id', label: t('LedgerItemsTable::Client'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
            { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(projectId) },
            {
              name: 'status',
              label: t('LedgerItemsTable::Status'),
              type: ResourceListFilterType.SINGLE_OPTION,
              options: [
                { label: t('LedgerItemsTable::Draft'), value: LedgerItemStatus.DRAFT },
                { label: t('LedgerItemsTable::Outstanding'), value: LedgerItemStatus.OUTSTANDING },
                { label: t('LedgerItemsTable::Overdue'), value: LedgerItemStatus.OVERDUE },
                { label: t('LedgerItemsTable::Paid'), value: LedgerItemStatus.PAID },
              ]
            },
            { name: 'number', label: t('LedgerItemsTable::Number'), type: ResourceListFilterType.STRING },
            { name: 'po_number', label: t('LedgerItemsTable::PO number'), type: ResourceListFilterType.STRING, visible: setting.po_number_enabled },
            { name: 'identifier', label: t('LedgerItemsTable::Identifier'), type: ResourceListFilterType.STRING },
            { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
            { name: 'issued_on', label: t('LedgerItemsTable::Issued on'), type: ResourceListFilterType.DATE },
            { name: 'due_on', label: t('LedgerItemsTable::Due on'), type: ResourceListFilterType.DATE },
            { name: 'credited_on', label: t('LedgerItemsTable::Credited on'), type: ResourceListFilterType.DATE },
            { name: 'booked_on', label: t('LedgerItemsTable::Booked on'), type: ResourceListFilterType.DATE },
            { name: 'reminders_enabled', label: t('LedgerItemsTable::Reminders enabled'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('LedgerItemsTable::Yes'), value: String(true) }, { label: t('LedgerItemsTable::No'), value: String(false) }] },
            { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onLedgerItemFiltersChange}
          sortOptions={[
            { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
            { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
            { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
            { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
            { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
            { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
            { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
            { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onLedgerItemSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          isLoading={isFetching}
          searchValue={searchValue}
          onSearchChange={this.onLedgerItemSearchChange}
          onSearchSubmit={this.onLedgerItemSearchSubmit}
          maxHeight='65vh'
          stickyHeader={true}
        />}
      </>
    )
  }

  renderCreditNotes() {
    const { currentUser: { workspace: { setting } }, t, contactId, projectId, primaryActionEnabled } = this.props
    const { didInitialLoad, ledgerItems, currentPage, totalPages, isFetching, filters, sortValue, searchValue } = this.state;
    const hasLedgerItems = ledgerItems.length > 0;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}

        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          headers={[
            { title: '' },
            { title: t('LedgerItemsTable::Number') },
            { title: t('LedgerItemsTable::Client') },
            { title: t('LedgerItemsTable::Issued on'), align: 'right' },
            { title: t('LedgerItemsTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('LedgerItemsTable::New creditnote')}
              onClick={this.onNewLedgerItemClick}
            />
          ] : null}
          renderRow={(ledgerItem: LedgerItem) => {
            let actions: IActionListItem[] = [
              { key: 'send', icon: 'send', content: t('LedgerItemsTable::Send') },
              { key: 'view', icon: 'eye', content: t('LedgerItemsTable::View') },
              { key: 'duplicate', icon: 'duplicate', content: t('LedgerItemsTable::Duplicate') },
              { key: 'edit', icon: 'edit', content: t('LedgerItemsTable::Edit') },
              { key: 'download', icon: 'pdf', content: t('LedgerItemsTable::Download PDF'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'download_ubl', icon: 'xml', content: t('LedgerItemsTable::Download UBL'), visible: Boolean(ledgerItem.pdf_attachment_download_url) },
              { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemsTable::Delete'), destructive: true }
            ]

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='30px' maxWidthMobile='50px'>
                  <LedgerItemAvatar width={30} ledgerItem={ledgerItem} rounded={true} onClick={() => this.onTableLedgerItemClick(ledgerItem)} />
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem.number}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem?.contact?.name || '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {NumberFormatter.formatCurrency(ledgerItem.currency || setting.default_currency, setting.number_format, ledgerItem.amount)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableLedgerItemActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )

          }}
          renderEmpty={<CardEmptyInfo
            icon={filtersActive ? 'search' : 'invoice'}
            description={filtersActive ? t('LedgerItemsTable::No creditnotes found') : t('LedgerItemsTable::No creditnotes have been created yet')}
            descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add creditnote')}
            onDescriptionActionClick={filtersActive ? this.onLedgerItemClearFilters : this.onNewLedgerItemClick}
          />}
          filters={[
            { name: 'contact_id', label: t('LedgerItemsTable::Client'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
            { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(projectId) },
            { name: 'number', label: t('LedgerItemsTable::Number'), type: ResourceListFilterType.STRING },
            { name: 'identifier', label: t('LedgerItemsTable::Identifier'), type: ResourceListFilterType.STRING },
            { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
            { name: 'issued_on', label: t('LedgerItemsTable::Issued on'), type: ResourceListFilterType.DATE },
            { name: 'booked_on', label: t('LedgerItemsTable::Booked on'), type: ResourceListFilterType.DATE },
            { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
          ]}
          onFiltersChange={this.onLedgerItemFiltersChange}
          sortOptions={[
            { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
            { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
            { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
            { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
            { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
            { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
            { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
            { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
          ]}
          sortValue={sortValue}
          onSortChange={this.onLedgerItemSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          isLoading={isFetching}
          searchValue={searchValue}
          onSearchChange={this.onLedgerItemSearchChange}
          onSearchSubmit={this.onLedgerItemSearchSubmit}
          maxHeight='65vh'
          stickyHeader={true}
        />}
      </>
    )
  }

  renderRecurringInvoices() {
    const { currentUser: { workspace }, t, contactId, primaryActionEnabled } = this.props
    const { setting } = workspace
    const { didInitialLoad, ledgerItems, totalPages, currentPage, isFetching, filters, sortValue, searchValue } = this.state;

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          actionsLeft={primaryActionEnabled ? [
            <ButtonPanel
              icon='plus'
              text={t('LedgerItemsTable::New recurring invoice')}
              onClick={this.onNewLedgerItemClick}
            />
          ] : null}
          headers={[
            { title: t('LedgerItemsTable::Client') },
            { title: t('LedgerItemsTable::Schedule') },
            { title: t('LedgerItemsTable::Next invoice'), align: 'right' },
            { title: t('LedgerItemsTable::Finish'), align: 'right' },
            { title: t('LedgerItemsTable::Amount'), align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          renderRow={(ledgerItem: LedgerItem) => {
            const { recurring_schedule } = ledgerItem
            let actions: IActionListItem[] = [
              { key: 'view', icon: 'eye', content: t('LedgerItemsTable::View') },
              { key: 'edit', icon: 'edit', content: t('LedgerItemsTable::Edit') },
              { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemsTable::Delete'), destructive: true },
            ]

            const nextInvoiceOn = recurring_schedule.next_occurrence_on

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                  {ledgerItem.contact ? ledgerItem.contact.name : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} >
                  <Badge
                    text={RecurringScheduleHelper.recurrenceUnitLabel(ledgerItem.recurring_schedule.recurrence_unit)}
                    type='grey'
                  />
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {nextInvoiceOn ? moment(nextInvoiceOn).format(setting.date_format) : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {ledgerItem?.recurring_schedule?.end_date ? moment(ledgerItem.recurring_schedule.end_date).format(setting.date_format) : t('LedgerItemsTable::Never')}
                </ResourceTableRowData>
                <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                  {NumberFormatter.formatCurrency(ledgerItem.currency || setting.default_currency, setting.number_format, ledgerItem.amount)}
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onTableLedgerItemActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }
          }
          renderEmpty={< CardEmptyInfo
            icon={filtersActive ? 'search' : 'invoice'}
            description={filtersActive ? t('LedgerItemsTable::No recurring invoices found') : t('LedgerItemsTable::No recurring invoices have been created yet')}
            descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add recurring invoice')}
            onDescriptionActionClick={filtersActive ? this.onLedgerItemClearFilters : this.onNewLedgerItemClick}
          />}
          filters={
            [
              { name: 'contact_id', label: t('LedgerItemsTable::Client'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false, visible: !Boolean(contactId) },
              { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false, visible: !Boolean(contactId) },
              { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
              { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
            ]}
          onFiltersChange={this.onLedgerItemFiltersChange}
          sortOptions={
            [
              { label: t('LedgerItemsTable::Next invoice on ↑'), value: 'recurring_schedules.next_occurrence_on_asc' },
              { label: t('LedgerItemsTable::Next invoice on ↓'), value: 'recurring_schedules.next_occurrence_on_desc' },
              { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
              { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
              { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
              { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
            ]}
          sortValue={sortValue}
          onSortChange={this.onLedgerItemSortValueChange}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          isLoading={isFetching}
          searchValue={searchValue}
          onSearchChange={this.onLedgerItemSearchChange}
          onSearchSubmit={this.onLedgerItemSearchSubmit}
          maxHeight='65vh'
          stickyHeader={true}
        />}
      </>
    )
  }

  render() {
    const { type } = this.props

    switch (type) {
      case LedgerItemType.ORDER_FORM: return this.renderOrderForms()
      case LedgerItemType.DELIVERY_NOTE: return this.renderDeliveryNotes()
      case LedgerItemType.QUOTATION: return this.renderQuotations()
      case LedgerItemType.PRO_FORMA_INVOICE: return this.renderProFormaInvoices()
      case LedgerItemType.INVOICE: return this.renderInvoices()
      case LedgerItemType.CREDIT_NOTE: return this.renderCreditNotes()
      case LedgerItemType.RECURRING_INVOICE: return this.renderRecurringInvoices()
      default: return null
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showSendLedgerItemModal: (options) => dispatch(showSendLedgerItemModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LedgerItemsTable)))