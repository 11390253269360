import styled from 'styled-components'

export default styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
	flex-wrap: wrap;
	width: 100%;
`
