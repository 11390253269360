import styled, { css } from "styled-components";
import { Style } from "../../styles";

const Subtitle = styled.h2<{ type?: 'danger' }>`
  font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.28px;
  position: relative;
  padding-bottom: 4px;


  ${props => props.type === 'danger' && css`
    color: ${Style.color.brandDanger};
  `}
`

export default Subtitle 