import styled from 'styled-components'
import { Style } from '../../styles'


const FlyoutOldContent = styled.div`
	padding: 16px;

	.is-notification & {
		padding: 0;
	}

	&.is-help {
		h3 {
			font-size: 16px;
			color: #353f45;
			margin-bottom: 16px;
			font-weight: 700;
		}

		ul {
			li {
				&:not(:last-child) {
					margin-bottom: 24px;
				}

				a {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 14px;

					svg {
						width: 13px;
						height: 14px;
						fill: ${Style.color.brandPrimary};	
						margin-right: 10px;
					}

					&:hover, &.is-active {
						color: ${Style.color.brandPrimary};
					}
				}
			}
		}

	}
`

export default FlyoutOldContent