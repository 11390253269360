import * as React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import TopNavigation from '../../components/Navigation/TopNavigation'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { ProjectsController } from '../../controllers'
import ProjectSummary from '../../components/Project/ProjectSummary'
import NumberFormatter from '../../utilities/NumberFormatter'
import moment from '../../utilities/Moment'
import Notification from '../../utilities/Notification'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import { Link, NavLink } from 'react-router-dom'
import { showProjectModal, showSendLedgerItemModal, showConfirmModal, showTimeEntryExportModal } from '../../store/modals/actions'
import PageHeader from '../../components/Page/PageHeader'
import Avatar from '../../components/Avatar/Avatar'
import ProjectHelper from '../../helpers/ProjectHelper'
import Panel from '../../components/Panel/Panel'
import ProfileDetailItem from '../../components/Profile/ProfileDetailItem'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import ProjectLedgerItems from '../../components/Project/ProjectLedgerItems'
import { IActionListItem } from '../../components/ActionList/ActionList'
import PageLoader from '../../components/Page/PageLoader'
import { TimeEntryExportModalOptions } from '../../store/modals/types'
import PageContent from '../../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import FileBrowser from '../../components/FileBrowser/FileBrowser'
import { WithTranslation, withTranslation } from 'react-i18next'
import ProjectTimesheets from '../../components/Project/ProjectTimesheets'
import ProjectExpenses from '../../components/Project/ProjectExpenses'
import Badge from '../../components/Badge/Badge'
import BoardsTable from '../../components/Contact/BoardsTable'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import ProjectProposals from '../../components/Project/ProjectProposals'
import ProjectContracts from '../../components/Project/ProjectContracts'
import {
  BizzeyFileResourceType,
  BudgetData,
  CurrentUser,
  LedgerItemType,
  Project,
  ProjectBillableType,
  ProjectStatus,
  UserWorkspaceSettingScope,
  ProjectSummary as ProjectSummaryType,
  ProjectBudgetType,
  RateType,
  NavigationMenuItemPage
} from '../../types'
import DetailsList from '../../components/Profile/DetailsList'
import PanelAction from '../../components/Panel/PanelAction'
import DealsTable from '../../components/Deals/DealsTable'
import ProjectBudget from '../../components/Project/ProjectBudget'
import Tooltip from '../../components/Tooltips/Tooltip'
import SettingHelper from '../../helpers/SettingHelper'
import ResourceFiles from '../../components/ResourceFiles/ResourceFiles'
import TaskView, { TaskViewMode } from '../../components/Tasks/TaskView'


interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showProjectModal: typeof showProjectModal
  showSendLedgerItemModal: typeof showSendLedgerItemModal
  showConfirmModal: typeof showConfirmModal
  showTimeEntryExportModal: typeof showTimeEntryExportModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id: string }> & WithTranslation

interface IState {
  didInitialLoad: boolean
  project: Project | null
  summary: ProjectSummaryType | null
  budgetData: BudgetData | null
  invoiceSummary: {
    outstanding: number,
    overdue: number,
    pending: number,
    paid: number
  } | null
  activeInvoiceSummaryKey: string
}

class ProjectShow extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      didInitialLoad: false,
      project: null,
      summary: null,
      budgetData: null,
      invoiceSummary: null,
      activeInvoiceSummaryKey: '',
    }

    // API
    this.fetchView = this.fetchView.bind(this)
    this.updateProject = this.updateProject.bind(this);

    this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)
    this.onBreadCrumbBackPress = this.onBreadCrumbBackPress.bind(this)
    this.onCreateOrderFormClick = this.onCreateOrderFormClick.bind(this)
    this.onCreateDeliveryNoteClick = this.onCreateDeliveryNoteClick.bind(this)
    this.onCreateQuotationClick = this.onCreateQuotationClick.bind(this)
    this.onCreateInvoiceClick = this.onCreateInvoiceClick.bind(this)
    this.onCreateCreditNoteClick = this.onCreateCreditNoteClick.bind(this)
    this.onStartProjectClick = this.onStartProjectClick.bind(this)
    this.onContinueProjectClick = this.onContinueProjectClick.bind(this)
    this.onFinishProjectClick = this.onFinishProjectClick.bind(this)
    this.onCancelProjectClick = this.onCancelProjectClick.bind(this)
    this.onEditProjectClick = this.onEditProjectClick.bind(this)
    this.onDeleteProjectClick = this.onDeleteProjectClick.bind(this)
    this.onExportTimeEntriesClick = this.onExportTimeEntriesClick.bind(this)
    this.onModalFormSubmitEditProject = this.onModalFormSubmitEditProject.bind(this)
    this.onAddContactClick = this.onAddContactClick.bind(this)
    this.onEditNotesClick = this.onEditNotesClick.bind(this)
    this.onEditScheduleClick = this.onEditScheduleClick.bind(this)
    this.onEditCustomFieldClick = this.onEditCustomFieldClick.bind(this)
    this.onEditBillingClick = this.onEditBillingClick.bind(this)
    this.onEditBudgetClick = this.onEditBudgetClick.bind(this)
    this.onInvoiceSummaryItemClick = this.onInvoiceSummaryItemClick.bind(this)

    this.renderProjectView = this.renderProjectView.bind(this)
  }

  componentWillMount() {
    this.fetchView()
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { match: { params: previousParams } } = prevProps
    const { match: { params: currentParams } } = this.props

    if (previousParams.id !== currentParams.id) {
      this.fetchView()
    }
  }

  async fetchView() {
    const { match: { params: { id } }, t } = this.props

    try {
      const response = await ProjectsController.projectView(id)
      const { project, summary, budget_data, invoice_summary } = response

      this.setState({
        project: project,
        summary: summary,
        budgetData: budget_data,
        invoiceSummary: invoice_summary,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async updateProject(project, callback) {
    const { t } = this.props

    try {
      const response = await ProjectsController.update(project)

      if (response.errors) {
        Notification.notifyError(t('Project::Oops something went wrong!'))
      } else {
        this.setState({ project: project }, this.fetchView)
      }
      if (callback) callback()
    }
    catch (ex) {
      console.error(ex)
    }
  }

  getPageHeaderActions(): IActionListItem[] {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { project } = this.state

    const defaultActions: IActionListItem[] = [
      { key: 'export_time_entries', icon: 'download-circle', content: t('Project::Export time entries'), visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING_EXPORT) },
      { key: 'edit', icon: 'edit', content: t('Project::Edit') },
      { key: 'delete', icon: 'trash-alt-solid', content: t('Project::Delete'), destructive: true }
    ]

    const extraActions: IActionListItem[] = []

    if (!project.start_date) {
      extraActions.push({ key: 'start_project', icon: 'start', content: t('Project::Start project') })
    }

    if (project.cancel_date || project.end_date) {
      extraActions.push({ key: 'continue_project', icon: 'start', content: t('Project::Continue project') })
    }

    if (project.start_date && !project.end_date) {
      extraActions.push({ key: 'finish_project', icon: 'check', content: t('Project::Complete project') })
    }

    if (!project.cancel_date && !project.end_date) {
      extraActions.push({ key: 'cancel_project', icon: 'close', content: t('Project::Cancel project') })
    }

    return [
      { key: 'create_order_form', icon: 'invoice', content: t('Project::New order form'), visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.order_forms_enabled },
      { key: 'create_delivery_note', icon: 'invoice', content: t('Project::New delivery note'), visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.delivery_notes_enabled },
      { key: 'create_quotation', icon: 'invoice', content: t('Project::New quotation'), visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      { key: 'create_invoice', icon: 'invoice', content: t('Project::New invoice'), visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      { key: 'create_creditnote', icon: 'invoice', content: t('Project::New credit note'), visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) },
      ...extraActions,
      ...defaultActions,
    ]
  }

  onPageHeaderActionClick(key: string) {
    switch (key) {
      case 'create_order_form': this.onCreateOrderFormClick()
        break
      case 'create_delivery_note': this.onCreateDeliveryNoteClick()
        break
      case 'create_quotation': this.onCreateQuotationClick()
        break
      case 'create_invoice': this.onCreateInvoiceClick()
        break
      case 'create_creditnote': this.onCreateCreditNoteClick()
        break
      case 'start_project': this.onStartProjectClick()
        break
      case 'continue_project': this.onContinueProjectClick()
        break
      case 'finish_project': this.onFinishProjectClick()
        break
      case 'cancel_project': this.onCancelProjectClick()
        break
      case 'edit': this.onEditProjectClick()
        break
      case 'delete': this.onDeleteProjectClick()
        break
      case 'export_time_entries': this.onExportTimeEntriesClick()
        break
    }
  }

  onBreadCrumbBackPress(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  onCreateOrderFormClick() {
    const { project } = this.state
    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.ORDER_FORM, contact_id: project.contact_id, project_id: project.id }))
  }

  onCreateDeliveryNoteClick() {
    const { project } = this.state
    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.DELIVERY_NOTE, contact_id: project.contact_id, project_id: project.id }))
  }

  onCreateQuotationClick() {
    const { project } = this.state
    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.QUOTATION, contact_id: project.contact_id, project_id: project.id }))
  }

  onCreateInvoiceClick() {
    const { project } = this.state
    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.INVOICE, contact_id: project.contact_id, project_id: project.id }))
  }

  onCreateCreditNoteClick() {
    const { project } = this.state
    this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, { type: LedgerItemType.CREDIT_NOTE, contact_id: project.contact_id, project_id: project.id }))
  }

  onStartProjectClick() {
    const { t } = this.props
    const { project } = this.state;

    project.start_date = moment().utc().format();

    this.updateProject(project, () => {
      Notification.notifySuccess(t('Project::Project started'))
    });
  }

  onContinueProjectClick() {
    const { t } = this.props
    const { project } = this.state;

    project.end_date = null;
    project.cancel_date = null;
    project.status = ProjectStatus.ACTIVE;

    this.updateProject(project, () => {
      Notification.notifySuccess(t('Project::Project continued.'))
    });
  }

  onFinishProjectClick() {
    const { t } = this.props
    const { project } = this.state;

    project.end_date = moment().utc().format();
    project.status = ProjectStatus.COMPLETED;

    this.updateProject(project, () => {
      Notification.notifySuccess(t('Project::Project successfully completed.'))
    });
  }

  onCancelProjectClick() {
    const { t } = this.props
    const { project } = this.state;

    project.cancel_date = moment().utc().format();
    project.status = ProjectStatus.CANCELLED;

    this.updateProject(project, () => {
      Notification.notifySuccess(t('Project::Project successfully cancelled.'))
    });
  }

  onEditProjectClick() {
    const { project } = this.state
    const { showProjectModal } = this.props

    showProjectModal({
      project: { id: project.id },
      onSubmit: this.onModalFormSubmitEditProject,
    })
  }

  onDeleteProjectClick() {
    const { project } = this.state;
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Project::Delete project'),
      description: t('Project::You are about to delete project <b>{{name}}</b>. Deleting a project deletes all its associated data (quotations, invoices, credit notes, time entries). Are you sure you want to continue?', { name: project.name }),
      action: { label: t('Project::Delete'), isDestructive: true },
      onConfirm: () => {
        ProjectsController
          .delete(project.id)
          .then(_response => {
            Notification.notifySuccess(t('Project::Project successfully deleted'))
            this.props.history.goBack()
          })
          .catch(console.error)
      }
    })
  }

  onExportTimeEntriesClick(options: TimeEntryExportModalOptions = {}) {
    const { showTimeEntryExportModal } = this.props
    const { project } = this.state

    const start = moment().startOf('month')
    const end = moment().endOf('month')

    requestAnimationFrame(() => {
      showTimeEntryExportModal({
        contactId: project.contact_id,
        contactDisabled: true,
        projectId: project.id,
        projectDisabled: true,
        start: start,
        end: end,
        ...options,
      })
    })
  }

  onModalFormSubmitEditProject(project: Project) {
    this.updateProject(project, () => { })
  }

  onAddContactClick(e) {
    e.preventDefault()

    const { showProjectModal } = this.props
    const { project } = this.state

    showProjectModal({
      project: { id: project.id },
      onSubmit: this.onModalFormSubmitEditProject,
    })
  }

  onEditNotesClick() {
    const { showProjectModal } = this.props
    const { project } = this.state

    showProjectModal({
      project: { id: project.id, },
      activeTab: 'notes',
      onSubmit: this.onModalFormSubmitEditProject
    })
  }

  onEditScheduleClick(e) {
    e.preventDefault()
    const { showProjectModal } = this.props
    const { project } = this.state

    showProjectModal({
      project: { id: project.id, },
      activeTab: 'schedule',
      onSubmit: this.onModalFormSubmitEditProject
    })
  }

  onEditCustomFieldClick() {
    const { showProjectModal } = this.props
    const { project } = this.state

    showProjectModal({
      project: { id: project.id, },
      activeTab: 'custom_fields',
      onSubmit: this.onModalFormSubmitEditProject
    })
  }

  onEditBillingClick(e) {
    const { showProjectModal } = this.props
    const { project } = this.state

    showProjectModal({
      project: { id: project.id, },
      activeTab: 'billing',
      onSubmit: this.onModalFormSubmitEditProject
    })
  }

  onEditBudgetClick(e) {
    e.preventDefault()
    const { showProjectModal } = this.props
    const { project } = this.state

    showProjectModal({
      project: { id: project.id, },
      activeTab: 'budget',
      onSubmit: this.onModalFormSubmitEditProject
    })
  }

  onInvoiceSummaryItemClick(key: string) {
    const { activeInvoiceSummaryKey } = this.state

    if (activeInvoiceSummaryKey === key) {
      this.setState({ activeInvoiceSummaryKey: '' })
    } else {
      this.setState({ activeInvoiceSummaryKey: key })
    }
  }

  renderProjectView() {
    const { project, summary, budgetData, activeInvoiceSummaryKey, invoiceSummary } = this.state
    const { currentUser, t } = this.props
    const { workspace: { setting } } = currentUser

    return (
      <div className='grid'>
        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <div className='grid-cell with-12col'>
          <ProjectSummary
            summary={summary}
            currency={setting.default_currency}
            numberFormat={setting.number_format}
            timeFormat={setting.time_format}
          />
        </div>}

        <div className='grid-cell with-4col'>
          <Panel
            title={t('Project::Details')}
            headerRight={<PanelAction icon='pen' onClick={this.onEditProjectClick} />}
          >
            <DetailsList>
              {setting.po_number_enabled && <ProfileDetailItem
                label={t('Project::PO number')}
                value={<a href='javascript://' onClick={this.onAddContactClick}>
                  {project.po_number ? project.po_number : '-'}
                </a>}
              />}
              <ProfileDetailItem
                label={<span>
                  {t('Project::Billing')}
                  <Tooltip
                    content={t('ProjectModal::Determines how the project and all its associated resources will be billed. (Fixed price, hourly, etc.)')}
                    containerStyle={{ marginLeft: 8, maxWidth: 100 }}
                  />
                </span>}
                value={<a href='javascript://' onClick={this.onEditBillingClick}>
                  {ProjectHelper.getProjectBillableTypeLabel(project.billable_type)}
                </a>}
              />
              <ProfileDetailItem
                label={<>
                  {t('Project::Rate determined by')}
                  <Tooltip
                    content={t('ProjectModal::Determines what rate will be applied when logging time.')}
                    containerStyle={{ marginLeft: 8 }}
                  />
                </>}
                value={<a href='javascript://' onClick={this.onEditBillingClick}>
                  {SettingHelper.getRateTypeLabel(project.rate_type)}
                </a>}
              />
              {project.billable_type === ProjectBillableType.FIXED_BUDGET && <ProfileDetailItem
                label={t('Project::Budget')}
                value={<a href='javascript://' onClick={this.onEditBillingClick}>
                  {project.budget > 0 ? NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, project.budget) : '-'}
                </a>}
              />}
              {project.rate_type === RateType.FIXED && project.billable_type !== ProjectBillableType.NON_BILLABLE && <>
                <ProfileDetailItem
                  label={t('Project::Hourly rate')}
                  value={<a href='javascript://' onClick={this.onEditBillingClick}>
                    {project.hourly_rate > 0 ? NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, project.hourly_rate) : `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, setting.default_hourly_rate)} (standaard tarief)`}
                  </a>}
                />
                <ProfileDetailItem
                  label={t('Project::Day rate')}
                  value={<a href='javascript://' onClick={this.onEditBillingClick}>
                    {project.day_rate > 0 ? NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, project.day_rate) : `${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, setting.default_day_rate)} (standaard tarief)`}
                  </a>}
                />
              </>}

              {project.budget_type !== ProjectBudgetType.NONE && <>
                <ProfileDetailItem
                  label={t('Project::Budget by')}
                  value={<a href='javascript://' onClick={this.onEditBudgetClick}>
                    {ProjectHelper.getProjectBudgetTypeLabel(project.budget_type)}
                  </a>}
                />
              </>}

              <ProfileDetailItem
                label={t('Project::Estimated start on')}
                value={<a href='javascript://' onClick={this.onEditScheduleClick}>
                  {project.estimated_start_date ? moment(project.estimated_start_date).format('LL') : '-'}
                </a>}
              />
              <ProfileDetailItem
                label={t('Project::Estimated finish on')}
                value={<a href='javascript://' onClick={this.onEditScheduleClick}>
                  {project.estimated_end_date ? moment(project.estimated_end_date).format('LL') : '-'}
                </a>}
              />
              <ProfileDetailItem
                label={t('Project::Started on')}
                value={<a href='javascript://' onClick={this.onEditScheduleClick}>
                  {project.start_date ? moment(project.start_date).format('LL') : '-'}
                </a>}
              />
              <ProfileDetailItem
                label={t('Project::Finished on')}
                value={<a href='javascript://' onClick={this.onEditScheduleClick}>
                  {project.end_date ? moment(project.end_date).format('LL') : '-'}
                </a>}
              />

              <ProfileDetailItem
                label={t('Project::Notes')}
                value={project.notes?.length > 0 ? <FroalaEditorView model={project.notes} /> : <a href='javascript://' onClick={this.onEditNotesClick}>{t('Project::Add notes')}</a>}
              />

              {Object.keys(project.custom_fields).map(customFieldKey => {
                let value = project.custom_fields[customFieldKey] || ''

                if (Array.isArray(value)) {
                  value = value.map((v, index) => (<Badge key={index} type='primary' text={v} />))
                }

                return (
                  <ProfileDetailItem
                    label={customFieldKey}
                    value={<a href='javascript://' onClick={this.onEditCustomFieldClick}>
                      {value}
                    </a>}
                  />
                )
              })}
            </DetailsList>
          </Panel>
        </div>
        <div className='grid-cell with-8col'>
          <div className='grid'>
            {budgetData && <div className='grid-cell with-12col'>
              <Panel
                title={t('Project::Budget')}
              >
                <ProjectBudget
                  data={budgetData}
                  currency={project?.contact?.currency || setting.default_currency}
                  numberFormat={setting.number_format}
                />
              </Panel>
            </div>}

            {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) && <div className='grid-cell with-12col'>
              <Panel title={t('Project::Files')} contentClasses='no-padding'>
                <ResourceFiles
                  resourceId={project.id}
                  resourceType={BizzeyFileResourceType.PROJECT}
                />
              </Panel>
            </div>}
          </div>
        </div>
      </div>
    )

  }

  render() {
    const {
      didInitialLoad,
      project,
    } = this.state
    const { t, currentUser: { workspace: { setting } } } = this.props

    return (
      <>
        <Helmet>
          <title>{t('Project::{{__appName}} | Project')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='project-navigation'
          title={t('Project::Project')}
        />

        <PageContent>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <>
            <PageHeader
              title={t('Project::Project')}
              breadcrumbs={[
                { content: t('Project::Back'), url: 'javascript://', onClick: this.onBreadCrumbBackPress }
              ]}
              mainActions={this.getPageHeaderActions()}
              onMainActionClick={this.onPageHeaderActionClick}
            />

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='panel'>
                  <div className='project-header'>
                    <div className='project-header-avatar'>
                      <Avatar name={project.name} backgroundColor={project.color} width={80} />
                    </div>

                    <div className='project-header-details'>
                      {project.contact && <>
                        <h2>
                          <a href='javascript://' onClick={this.onEditProjectClick}>{project.name}</a>
                          {ProjectHelper.getStatusBadge(project)}
                        </h2>
                        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTACT) ? <Link className='name' to={RouteHelper.process(ERoute.PATH_CONTACT, { id: project.contact_id })}>{project.contact.name}</Link> : project.contact.name}
                      </>}
                      {!project.contact && <a href='javascript://' onClick={this.onAddContactClick}>Klant koppelen.</a>}
                    </div>
                  </div>

                  <div className='project-header-navigation'>
                    <div className='navigation-tabs'>
                      <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true} onClick={() => this.fetchView()}>
                        {t('Project::Details')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.TASKS) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_TASKS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Work overview')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.TASKS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_BOARDS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Boards')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.DEALS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_DEALS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Deals')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.TIME_TRACKING) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_TIMESHEETS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Timesheets')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.order_forms_enabled && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_ORDER_FORMS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Order forms')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.delivery_notes_enabled && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_DELIVERY_NOTES, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Delivery notes')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.PROPOSALS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROPOSAL) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_PROPOSALS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Proposals')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_QUOTATIONS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Quotations')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.pro_forma_invoices_enabled && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_PRO_FORMA_INVOICES, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Proforma invoices')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_INVOICES, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Invoices')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_CREDIT_NOTES, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Credit notes')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.INVOICES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_RECURRING_INVOICES, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Recurring invoices')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.CONTRACTS) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTRACT) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_CONTRACTS, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Contracts')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.EXPENSES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_EXPENSES, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Expenses')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                      {SettingHelper.hasNavigationitem(NavigationMenuItemPage.FILES) && UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROJECT) && <NavLink exact to={RouteHelper.process(ERoute.PATH_PROJECT_FILES, { id: project.id })} className='navigation-tab' activeClassName='is-active' replace={true}>
                        {t('Project::Files')}
                        <div className='navigation-tab-indicator' />
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Switch>
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_TASKS)} component={(props) => <TaskView {...props} projectId={project.id} contactId={project?.contact_id} mode={TaskViewMode.WORK_OVERVIEW} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TASK) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_BOARDS)} component={(props) => <BoardsTable {...props} projectId={project.id} contactId={project?.contact_id} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DEAL) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_DEALS)} component={(props) => <DealsTable {...props} projectId={project.id} contactId={project?.contact_id} primaryActionEnabled />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_TIMESHEETS)} component={(props) => <ProjectTimesheets projectId={project.id} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.order_forms_enabled && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_ORDER_FORMS)} component={(props) => <ProjectLedgerItems {...props} type={LedgerItemType.ORDER_FORM} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && setting.delivery_notes_enabled && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_DELIVERY_NOTES)} component={(props) => <ProjectLedgerItems {...props} type={LedgerItemType.DELIVERY_NOTE} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PROPOSAL) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_PROPOSALS)} component={(props) => <ProjectProposals {...props} contactId={project.contact_id} projectId={project.id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_QUOTATIONS)} component={(props) => <ProjectLedgerItems {...props} type={LedgerItemType.QUOTATION} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_PRO_FORMA_INVOICES)} component={(props) => <ProjectLedgerItems {...props} type={LedgerItemType.PRO_FORMA_INVOICE} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_INVOICES)} component={(props) => <ProjectLedgerItems {...props} type={LedgerItemType.INVOICE} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_CREDIT_NOTES)} component={(props) => <ProjectLedgerItems {...props} type={LedgerItemType.CREDIT_NOTE} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_RECURRING_INVOICES)} component={(props) => <ProjectLedgerItems {...props} type={LedgerItemType.RECURRING_INVOICE} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.CONTRACT) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_CONTRACTS)} component={(props) => <ProjectContracts {...props} contactId={project.contact_id} projectId={project.id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.EXPENSE) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_EXPENSES)} component={(props) => <ProjectExpenses projectId={project.id} contactId={project.contact_id} />} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) && <Route path={RouteHelper.process(ERoute.PATH_PROJECT_FILES)} component={(props) => <FileBrowser maxHeight='61vh' resourceType={BizzeyFileResourceType.PROJECT} resourceId={project.id} />} />}
              <Route path={RouteHelper.process(ERoute.PATH_PROJECT)} component={this.renderProjectView} />
            </Switch>
          </>
          }
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showSendLedgerItemModal: (options) => dispatch(showSendLedgerItemModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showTimeEntryExportModal: (options) => dispatch(showTimeEntryExportModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectShow))