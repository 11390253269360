import * as React from 'react'
import LedgerItemPreviewNavigation from '../components/LedgerItem/LedgerItemPreviewNavigation'
import { showImportProductsModal, showImportTimeEntriesModal, showTaxesModal, showContactModal, showProjectModal, showImportExpensesModal, showProjectStatusUpdateModal, showConfirmModal, showCalculateAmountModal, showDiscountModal } from '../store/modals/actions'
import { RouteComponentProps } from 'react-router'
import DocumentPreview from '../components/LedgerItem/DocumentPreview'
import Icon from '../components/Icons/Icon'
import Select, { ISelectOption } from '../components/Form/Select'
import LedgerItemHelper from '../helpers/LedgerItemHelper'
import { ContactsController, InvoicesController, SettingsController } from '../controllers'
import UrlHelper from '../helpers/UrlHelper'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Loader from '../components/Loaders/Loader'
import TimeHelper from '../helpers/TimeHelper'
import ButtonDropdown from '../components/Button/ButtonDropdown'
import ButtonDropdownOption from '../components/Button/ButtonDropdownOption'
import MoneyInput from '../components/Form/MoneyInput'
import PercentInput from '../components/Form/PercentInput'
import NumberFormatter from '../utilities/NumberFormatter'
import DateInput, { DateInput as DateInputComponent } from '../components/Form/DateInput'
import Switch from '../components/Form/Switch'
import moment from '../utilities/Moment'
import { INVOICE_PAYMENT_DURATIONS } from '../Constants'
import TooltipError from '../components/Tooltips/ErrorTooltip'
import Notification from '../utilities/Notification'
import Button from '../components/Button/Button'
import PowerSelect from '../components/Form/PowerSelect'
import { updateSettings } from '../store/authentication/actions'
import Sound, { Sounds } from '../sounds'
import DocumentWrapper from '../components/LedgerItem/DocumentWrapper'
import RouteHelper from '../helpers/RouteHelper'
import ERoute from '../ERoute'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import Alert from '../components/Alert/Alert'
import AutogrowTextarea from '../components/Form/AutogrowTextArea'
import MaskedInput from 'react-text-mask'
import Tooltip from '../components/Tooltips/Tooltip'
import PageLoader from '../components/Page/PageLoader'
import RecurringScheduleHelper from '../helpers/RecurringScheduleHelper'
import { Helmet } from 'react-helmet'
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import ProjectHelper from '../helpers/ProjectHelper'
import CustomFieldModalInputs from '../components/CustomFields/CustomFieldModalInputs'
import ResourceCreatablePowerSelect, { ResourceCreatablePowerSelectClass } from '../components/Form/ResourceCreatablePowerSelect'
import Editor, { LEDGER_ITEM_EDITOR_CONFIG, MINIMAL_EDITOR_CONFIG } from '../components/Editor/Editor'
import ReactSelectTheme from '../components/Form/ReactSelectTheme'
import EditorContainer from '../components/Editor/EditorContainer'
import MobilityHelper from '../helpers/MobilityHelper'
import { Contact, CurrentUser, CustomField, Expense, GroupedTimeEntries, RemittanceInformationType, ImportedProduct, InvoiceMetadata, LedgerItem, LedgerItemTemplate, LedgerItemType, LineItem, Project, ProjectStatus, RecurrenceUnit, RecurringSchedule, Settings, WorkspaceTax, VATLiability, WorkspaceChannelEventType, WorkspaceChannelEvent } from '../types'
import EditorHelper from '../helpers/EditorHelper'
import VATHelper from '../helpers/VatHelper'
import ActionCableConsumer from '../consumers/ActionCableConsumer'
import Badge from '../components/Badge/Badge'
import PaymentHelper from '../helpers/PaymentHelper'

const editorBreakpoint = '1370px';

const DeleteLineItemAction = styled.a`
  width: 38px !important;
  height: 38px !important;
  min-width: 38px !important;
  min-height: 38px !important;

  i {
    font-size: 17px !important;
  }
`

const LoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
`

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
`

const EditorForm = styled.div`
	width: 60%;
	min-height: 100vh;
	background: white;

	@media screen and (max-width: ${editorBreakpoint}) {
		width: 100%;
	}
`

const EditorFormWrapper = styled.div`
	position: relative;
	padding: 48px;
	overflow-y: auto;
	padding-bottom: 110px;;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		padding-left: 20px;
		padding-right: 20px;
	}
`

const EditorFormClose = styled.a`
	position: absolute;
	cursor: pointer;
	top: 22px;
	right: 16px;
	width: 32px;
	height: 32px;
	align-items: center;
	justify-content: center;
	color: white;
	background-color: rgb(207, 214, 230);
	display: none;
	z-index: 2;
	border-radius: 16px;
	transition: background 250ms ease-in-out;

	&:hover {
		background-color: rgb(152, 161, 179);
	}

	@media screen and (max-width: ${editorBreakpoint}) {
		display: flex;
	}

	svg, i {
		font-size: 17px;
		fill: currentColor;
		color: currentColor
	}
`

const EditorFormTitle = styled.input`
	font-size: 32px;
	line-height: 34px;
	text-align: center;
	position: relative;
	margin-bottom: 2px;
	padding: 0px 32px;
	border: none !important;
	font-weight: bold;

	&::after {
		content: "";
		background-color: ${Style.color.brandPrimary};
		height: 2px;
		width: 100%;
	}

	&:focus,
  &:active {
		outline: none !important;
		border: none !important;
		box-shadow: none !important;

		&::after {
			content: ""
		}
	}
	
	&:disabled {
		color: hsl(0,0%,50%) !important;
		background: transparent !important;
	}
`

const EditorFormSectionHeader = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	margin: 16px 0;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const EditorFormSectionTitle = styled.div`
	display: flex;
	font-size: 22px;
	line-height: 28px;
	font-weight: 600;
`

const EditorFormSectionActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-top: 10px;;
	}
`

const EditorPreviewContainer = styled.div`
	position: fixed;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(122, 133, 153);
	z-index: 3;
	flex: 1 1 0%;
	flex-flow: column nowrap;

	@media screen and (max-width: ${editorBreakpoint}) {
		display: none;
	}
`

const EditorPreviewClose = styled.a`
	position: absolute;
	cursor: pointer;
	top: 16px;
	right: 16px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	background-color: rgba(15, 20, 31, 0.3);
	border-radius: 20px;
	transition: background-color 0.1s ease 0s;

	&:hover {
		background-color: rgba(15, 20, 31, 0.5);
	}

	svg, i {
		fill: currentColor;
		font-size: 17px;
	}
`

const EditorPreviewWrapper = styled.div`
	position: absolute;
	display: flex;
	top: 40px;
	bottom: 74px;
	left: 74px;
	right: 74px;
`

const EditorPreviewExpand = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 6px;

	&:hover {
		> a {
			transform: scale(1);
		}
	}
`

const EditorPreviewExpandButton = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	background-color: ${Style.color.brandPrimary};
	color: white;
	transform: scale(0);
	border-radius: 24px;
	transition: transform 0.1s ease 0s;

	i {
		font-size: 24px;
		fill: currentColor;
	}
`

const EditorPreviewHeader = styled.div`
	position: absolute;
	top: -40px;
	left: 0px;
	right: 0px;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	flex-flow: row nowrap;
`

const EditorPreviewSaveState = styled.div`
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.3px;
	position: absolute;
	display: flex;
	align-items: center;
	left: 0px;
	color: white;
	flex-flow: row nowrap;
`

const EditorPreviewSaveStateIcon = styled.div`
	margin-right: 4px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	i {
		color: rgba(15, 20, 31, 0.15);
	}
`

const EditorPreviewFooter = styled.div`
	position: absolute;
	bottom: -60px;
	left: 0px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const EditorPreviewFooterTemplateSelector = styled.div`
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	color: rgb(255, 255, 255);
	cursor: pointer;
	background-color: rgba(15, 20, 31, 0);
	padding: 0px 16px 0px 8px;
	border-radius: 19px;
	transition: background-color 0.1s ease 0s;

	&:hover {
		background-color: rgba(15, 20, 31, 0.15);
	}
`

const EditorPreviewFooterTemplateSelectorIcon = styled.div`
	display: flex;
	width: 24px;
	height: 24px;
	justify-content: center;
	align-items: center;
	margin-right: 4px;
`

const EditorPreviewFooterTemplateSelectorText = styled.div`
	font-size: 16px;
	line-height: 24px;
`

const EditorPreviewFooterAction = styled.div<{ disabled?: boolean }>`
	font-size: 18px;
	line-height: 22px;
	font-weight: 600;
	display: inline-block;
	text-align: center;
	color: white;
	background-color: ${Style.color.brandPrimary};
	cursor: pointer;
	pointer-events: auto;
	outline: none;
	padding: 14px 24px;
	border-radius: 4px;
	transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s;
	border-width: 0px;

	&:hover {
		background: #2a79fc;
	}
`

const TemplateContainer = styled.div`
	width: 100%;
	min-height: 100vh;
`

const TemplateContainerNavigation = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 64px;
	background-color: rgb(15, 20, 31);
	color: white;
	flex-flow: row nowrap;
	padding: 0px 32px;


	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		padding: 0 20px;
	}
`

const TemplateNavigationBack = styled.a`
	color: white;
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 32px;
	padding-right: 14px;
	padding-left: 4px;
	margin-left: -10px;
	background-color: rgba(61, 64, 71, 0);
	border-radius: 16px;
	transition: background-color 0.15s ease 0s;

	&:hover {
		background-color: rgb(61, 64, 71);
	}

	i {
		margin-left: 4px;
		margin-right: 8px;
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: none;
	}
`

const TemplateNavigationMenuMobile = styled.a`
	display: none;
	color: rgb(255, 255, 255);
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	position: relative;
	align-items: center;
	justify-content: center;
	font-size: 20px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: flex;
	}
`

const TemplateContainerNavigationActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	a:not(:first-child) {
		margin-left: 8px;
	}
`

const TemplateContainerNavigationAction = styled.a`
	font-weight: 600;
	display: inline-block;
	text-align: center;
	color: white;
	background-color: ${Style.color.brandPrimary};
	cursor: pointer;
	pointer-events: auto;
	font-size: 16px;
	line-height: 20px;
	outline: none;
	border-radius: 4px;
	transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s;
	border-width: 0px;
	padding: 8px 12px;

	&:hover {
		background: #2a79fc;
	}
`

const EditorFormMainActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	> div {
		&:first-child {
			margin-right: ${Style.spacing.x1};
		}

		&:nth-child(2) {
			@media screen and (max-width: 500px) {
				display: none;
			}
		}
	}
`

const ReferenceContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  a {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 38px;

    &:hover {
      transform: none !important;
    }
  }
`

const ReferenceTypeSwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	line-height: 0;
  font-size: 12px;
  padding: 8px 0;
  color: #858C99;
`


const ScheduleContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
`

const ScheduleRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
		align-items: flex-start;
	}
`

const ScheduleRowLabel = styled.div`
	position: relative;
	text-align: right;
	margin-right: 14px;
	white-space: nowrap;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-right: 0;
		margin-bottom: 8px;
		text-align: left;
	}
`

const ScheduleRowMiddleLabel = styled.div`
	position: relative;
	text-align: right;
	margin-left: ${Style.spacing.x1};
	margin-right: ${Style.spacing.x1};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-bottom: ${Style.spacing.x1};
		margin-left: 0;
		margin-right: 0;
	}
`

const ScheduleRowContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
		align-items: flex-start;
	}
`

const ScheduleSelectContainer = styled.div<{ isTimezone?: boolean }>`
	display: block;
	min-width: 160px;
	max-width: 160px;

	${(props) => props.isTimezone && css`
		min-width: 200px;
		max-width: 200px;
	`}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		min-width: initial;
		max-width: initial;
		width: 100%;
		margin-bottom: ${Style.spacing.x1};
	}
`

const LineItemActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > * {
    margin: ${Style.spacing.x0_5};

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      margin: 2px 4px;
    }
  }


  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: row-reverse;
    align-items: center;
  }
`

const LineItemGrip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 10px;
    color: #d6d6d6;
  }
`

const LineItemToggleTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    font-size: 12px;
    color: #d6d6d6;
  }

  svg {
    width: 12px;
    color: #d6d6d6;
    fill: #d6d6d6;
  }
`

const LedgerItemMetadataContainer = styled.div`
  width: 100%;
`

const LedgerItemMetadata = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }

  li {
    flex: 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;
  }
`

const LineItemDiscount = styled.div`
  cursor: pointer;
  margin-top: ${Style.spacing.x0_5};
`

const LineItemActions = styled.div<{ titleEnabled?: boolean }>`
  position: absolute;
  left: -24px;
  top: 2px;

  ${props => props.titleEnabled && css`
    top: 44px;
  `}

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    transform: initial;
    left: initial;
    bottom: initial;
    top: 8px;
    right: 0;

    ${props => props.titleEnabled && css`
      top: 12px;
    `}
  }
`

const LineItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${Style.spacing.x1} 0;

  &:first-child {
    margin-top: 0;
  }
  
  &:not(:first-child) {
    border-top: 1px dotted ${Style.color.border};
    padding-top: ${Style.spacing.x1};

    ${LineItemActions} {
      top: 9px;

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        top: 8px;
      }
    }
  }
`

const LineItemTitleContainer = styled.div`
  margin-right: 44px;
`

const LineItemTitle = styled.input`
  border: none !important;
  font-size: 17px;
  color: black;
  font-weight: bold;
  text-decoration: underline;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
`

const EditorTable = styled.div`
  display: flex;
  flex-direction: column;
`

type EditorMode = 'editor' | 'preview'

enum InvoiceType {
  ADVANCE = 'advance',
  INTERIM = 'interim',
  FINAL = 'final'
}

interface IParams {
  type?: LedgerItemType
  id?: string
  contact_id?: string
  project_id?: string
  email_contact_id?: string
  quotation_id?: string
  deal_id?: string
  proposal_id?: string
  invoice_type?: InvoiceType
}

interface IDispatchToProps {
  showImportProductsModal: typeof showImportProductsModal
  showImportExpensesModal: typeof showImportExpensesModal
  showImportTimeEntriesModal: typeof showImportTimeEntriesModal
  showTaxesModal: typeof showTaxesModal
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showProjectStatusUpdateModal: typeof showProjectStatusUpdateModal
  showConfirmModal: typeof showConfirmModal
  showCalculateAmountModal: typeof showCalculateAmountModal
  showDiscountModal: typeof showDiscountModal
  updateSettings: typeof updateSettings
}

interface IStateToProps {
  currentUser: CurrentUser
}

interface IState {
  currentPage: number
  pageCount: number
  mode: EditorMode
  didInitialLoad: boolean
  isSaving: boolean
  ledgerItem: LedgerItem | null
  ledgerItemTypes: LedgerItemType[]
  ledgerItemCurrencies: { label: string, value: string }[]
  ledgerItemVariables: any[]
  ledgerItemTemplates: LedgerItemTemplate[]
  quotationValidationDurations: number[]
  taxes: WorkspaceTax[]
  customFields: CustomField[]
  errors: any
  mobileTemplatePickerActive: boolean
  didInitialPreviewLoad: boolean
  isPreviewReloading: boolean
  nextQuotationNumber: string | null
  nextInvoiceNumber: string | null
  timezones: { label: string, value: string }[]
  metadata: InvoiceMetadata | null
}

type IProps = IDispatchToProps & IStateToProps & RouteComponentProps<IParams> & WithTranslation


class LedgerItemEditor extends React.Component<IProps, IState> {
  private TITLE_LINE_ITEM_REF_KEY = 'title-input-'
  private DESCRIPTION_LINE_ITEM_REF_KEY = 'description-input-'
  private QUANTITY_LINE_ITEM_REF_KEY = 'quantity-input-'
  private RATE_LINE_ITEM_REF_KEY = 'rate-input-'
  private editorDocumentWrapper = React.createRef<DocumentWrapper>()
  private previewDocumentWrapper = React.createRef<DocumentWrapper>()
  private referenceInput = React.createRef<MaskedInput>()
  private projectSelect = React.createRef<ResourceCreatablePowerSelectClass>()
  private scheduleStartDateInput = React.createRef<DateInputComponent>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      currentPage: 1,
      pageCount: 1,
      mode: 'editor',
      didInitialLoad: false,
      isSaving: false,
      ledgerItem: null,
      ledgerItemTypes: [],
      ledgerItemCurrencies: [],
      ledgerItemVariables: [],
      ledgerItemTemplates: [],
      quotationValidationDurations: [],
      taxes: [],
      timezones: [],
      customFields: [],
      nextQuotationNumber: null,
      nextInvoiceNumber: null,
      mobileTemplatePickerActive: false,
      errors: {},
      didInitialPreviewLoad: false,
      isPreviewReloading: false,
      metadata: null,
    }

    this.onBackPress = this.onBackPress.bind(this)
    this.onToPreviewPress = this.onToPreviewPress.bind(this)
    this.onToEditorPress = this.onToEditorPress.bind(this)
    this.onDocumentNavigateBack = this.onDocumentNavigateBack.bind(this)
    this.onDocumentNavigateForward = this.onDocumentNavigateForward.bind(this)
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)

    this.fetchForm = this.fetchForm.bind(this)
    this.onLedgerItemNumberChange = this.onLedgerItemNumberChange.bind(this)
    this.onLedgerItemTypeChange = this.onLedgerItemTypeChange.bind(this)
    this.onLedgerItemCurrencyChange = this.onLedgerItemCurrencyChange.bind(this)
    this.onLedgerItemNotesChange = this.onLedgerItemNotesChange.bind(this)
    this.onLedgerItemCustomFieldValueChange = this.onLedgerItemCustomFieldValueChange.bind(this)
    this.onLedgerItemClientChange = this.onLedgerItemClientChange.bind(this)
    this.onLedgerItemProjectChange = this.onLedgerItemProjectChange.bind(this)
    this.onLedgerItemEmailContactChange = this.onLedgerItemEmailContactChange.bind(this)
    this.onLedgerItemPoNumberChange = this.onLedgerItemPoNumberChange.bind(this)
    this.onBillAdvancementClick = this.onBillAdvancementClick.bind(this)
    this.onBillInterimClick = this.onBillInterimClick.bind(this)
    this.onBillRemainingAmountClick = this.onBillRemainingAmountClick.bind(this)
    this.onImportTimeEntriesClick = this.onImportTimeEntriesClick.bind(this)
    this.onImportExpensesClick = this.onImportExpensesClick.bind(this)
    this.onImportProductsClick = this.onImportProductsClick.bind(this)
    this.onEditTaxesClick = this.onEditTaxesClick.bind(this)
    this.onEditTaxesModalSubmit = this.onEditTaxesModalSubmit.bind(this)
    this.onLineItemDragEnd = this.onLineItemDragEnd.bind(this)
    this.onLineItemTitleToggle = this.onLineItemTitleToggle.bind(this)
    this.onLineItemTitleChange = this.onLineItemTitleChange.bind(this)
    this.onLineItemDescriptionChange = this.onLineItemDescriptionChange.bind(this)
    this.onLineItemQuantityChange = this.onLineItemQuantityChange.bind(this)
    this.onLineItemRateChange = this.onLineItemRateChange.bind(this)
    this.onLineItemDiscountClick = this.onLineItemDiscountClick.bind(this)
    this.onLineItemTaxChange = this.onLineItemTaxChange.bind(this)
    this.onLineItemDeleteClick = this.onLineItemDeleteClick.bind(this)
    this.onNewLineItemPropertyChange = this.onNewLineItemPropertyChange.bind(this)
    this.onLedgerItemDiscountChange = this.onLedgerItemDiscountChange.bind(this)
    this.onLedgerItemIssuedOnChange = this.onLedgerItemIssuedOnChange.bind(this)
    this.onLedgerItemIdentifierChange = this.onLedgerItemIdentifierChange.bind(this)
    this.onLedgerItemIdentifierTypeChange = this.onLedgerItemIdentifierTypeChange.bind(this)
    this.onLedgerItemIdentifierGenerateClick = this.onLedgerItemIdentifierGenerateClick.bind(this)
    this.onLedgerItemDueOnChange = this.onLedgerItemDueOnChange.bind(this)
    this.onLedgerItemApprovedOnChange = this.onLedgerItemApprovedOnChange.bind(this)
    this.onLedgerItemRejectedOnChange = this.onLedgerItemRejectedOnChange.bind(this)
    this.onLedgerItemPaidChange = this.onLedgerItemPaidChange.bind(this)
    this.onLedgerItemBookedOnChange = this.onLedgerItemBookedOnChange.bind(this)
    this.onLedgerItemDeliveredOnChange = this.onLedgerItemDeliveredOnChange.bind(this)
    this.onLedgerItemInvoicedOnChange = this.onLedgerItemInvoicedOnChange.bind(this)
    this.onLedgerItemCancelledOnChange = this.onLedgerItemCancelledOnChange.bind(this)
    this.onLedgerItemCreditedOnChange = this.onLedgerItemCreditedOnChange.bind(this)
    this.onLedgerItemVatRulesChange = this.onLedgerItemVatRulesChange.bind(this)
    this.onLedgerItemDetailsChange = this.onLedgerItemDetailsChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.onImportTimeEntriesModalSubmit = this.onImportTimeEntriesModalSubmit.bind(this)
    this.onImportExpensesModalSubmit = this.onImportExpensesModalSubmit.bind(this)
    this.onImportProductsModalSubmit = this.onImportProductsModalSubmit.bind(this)
    this.synchronizeLineItemInputs = this.synchronizeLineItemInputs.bind(this)

    // Recurring invoice Schedule
    this.onScheduleUnitChange = this.onScheduleUnitChange.bind(this)
    this.onScheduleDayOfWeekChange = this.onScheduleDayOfWeekChange.bind(this)
    this.onScheduleDayOfMonthChange = this.onScheduleDayOfMonthChange.bind(this)
    this.onScheduleMonthOfYearChange = this.onScheduleMonthOfYearChange.bind(this)
    this.onScheduleStartDateChange = this.onScheduleStartDateChange.bind(this)
    this.onScheduleEndedOptionChange = this.onScheduleEndedOptionChange.bind(this)
    this.onScheduleEndDateChange = this.onScheduleEndDateChange.bind(this)
    this.onScheduleTimezoneChange = this.onScheduleTimezoneChange.bind(this)

    // Preview
    this.onTemplateClick = this.onTemplateClick.bind(this)
    this.onToggleMobileTemplatePicker = this.onToggleMobileTemplatePicker.bind(this)
    this.onMobileTemplatePick = this.onMobileTemplatePick.bind(this)

    // Action cable
    this.onActionCableConnected = this.onActionCableConnected.bind(this)
    this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
    this.onActionCableReceived = this.onActionCableReceived.bind(this)

    // Modals
    this.onAdvanceModalSubmit = this.onAdvanceModalSubmit.bind(this)
  }

  componentDidMount() {
    const { id } = this.props.match.params

    this.fetchForm(id, this.getType())
  }

  getType(): LedgerItemType {
    const { location } = this.props
    const { ledgerItem } = this.state
    const params = UrlHelper.getParams(location.search)

    if (ledgerItem) {
      return ledgerItem.type
    } else if (params.type) {
      return params.type
    } else {
      return LedgerItemType.QUOTATION
    }
  }

  getLedgerItemEstimatedNetOnOptions(): ISelectOption[] {
    const { t } = this.props

    return INVOICE_PAYMENT_DURATIONS.map((duration, index) => {
      return {
        key: String(duration),
        label: t(`InvoicePaymentDurations::${duration}`),
        value: duration
      }
    })
  }

  async fetchForm(id, type: LedgerItemType = LedgerItemType.QUOTATION) {
    const { t } = this.props
    const urlParams = UrlHelper.getParams(this.props.location.search)
    let params: any = {}

    if (id) {
      params.id = id
    } else {
      params.type = type
    }

    if (urlParams.proposal_id) {
      params.proposal_id = urlParams.proposal_id
    }

    try {
      const response = await InvoicesController.getForm(params)

      const {
        invoice,
        ledger_item_templates,
        ledger_item_types,
        ledger_item_currencies,
        contacts,
        taxes,
        ledger_item_variables,
        next_quotation_number,
        next_invoice_number,
        timezones,
        custom_fields,
      } = response

      this.setState({
        didInitialLoad: true,
        ledgerItem: invoice,
        ledgerItemTypes: ledger_item_types,
        ledgerItemCurrencies: ledger_item_currencies,
        ledgerItemTemplates: ledger_item_templates,
        ledgerItemVariables: ledger_item_variables,
        taxes: taxes,
        nextQuotationNumber: next_quotation_number,
        nextInvoiceNumber: next_invoice_number,
        timezones: timezones,
        customFields: custom_fields,
      }, () => {
        this.processUrlParameters()

        this.fetchMetadata()
          .then(() => {
            const { metadata } = this.state
            const params = UrlHelper.getParams(this.props.location.search)

            if (
              // Only new invoices
              !invoice.id &&
              // With a invoice_type url parameter set to advance, interim or final
              [InvoiceType.ADVANCE, InvoiceType.INTERIM, InvoiceType.FINAL].includes(params.invoice_type) &&
              // With metadata net amount > 0
              metadata &&
              metadata.advancement_invoices &&
              Number(metadata.advancement_invoices.remaining_net_amount) > 0
            ) {
              const invoiceType = params.invoice_type

              switch (invoiceType) {
                case InvoiceType.ADVANCE:
                  this.onBillAdvancementClick()
                  break
                case InvoiceType.INTERIM:
                  this.onBillInterimClick()
                  break
                case InvoiceType.FINAL:
                  this.onBillRemainingAmountClick()
                  break
              }
            }
          }).catch(console.error)
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  async fetchMetadata() {
    const urlParams = UrlHelper.getParams(this.props.location.search)
    const { ledgerItem } = this.state

    try {
      let params = {}

      if (ledgerItem.contact_id) params['contact_id'] = ledgerItem.contact_id
      if (ledgerItem.project_id) params['project_id'] = ledgerItem.project_id
      if (urlParams.quotation_id) params['quotation_id'] = urlParams.quotation_id

      const importMetadata = await InvoicesController.importMetadata(params)

      this.setState({ metadata: importMetadata, })
    } catch (ex) {
      console.error(ex)
    }
  }

  onLedgerItemNumberChange(e) {
    e.preventDefault()

    const number = e.currentTarget.value

    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        number: number
      }
    })
  }

  onLedgerItemTypeChange(option) {
    const { ledgerItem } = this.state
    const type = option.value

    const ledgerItemTypeDefaults = this.getLedgerItemTypeDefaults(type)

    // Changing type results in creating a new ledger item
    this.setState({
      ledgerItem: {
        ...ledgerItem,
        id: null,
        ...ledgerItemTypeDefaults,
      },
    }, () => {
      this.props.history.replace(ERoute.PATH_INVOICES_NEW)
    })
  }

  onLedgerItemCurrencyChange(option) {
    const { ledgerItem } = this.state
    const currency = option.value

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        currency: currency
      }
    })
  }

  onLedgerItemNotesChange(e) {
    const { ledgerItem } = this.state

    const notes = e.currentTarget.value

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        notes: notes,
      }
    })
  }

  onLedgerItemCustomFieldValueChange(key: string, value: any) {
    const { ledgerItem } = this.state

    ledgerItem.custom_fields[key] = value

    this.setState({
      ledgerItem: {
        ...ledgerItem,
      },
    })
  }

  async onLedgerItemClientChange(value?: string, contact?: Contact) {
    const { ledgerItem, taxes } = this.state
    const { currentUser: { workspace: { setting } } } = this.props

    let newLedgerItem: LedgerItem;
    let details = ledgerItem.details

    // If locale differs from current version override details
    const currentLocale = LedgerItemHelper.getLedgerItemLocale(ledgerItem)
    const newLocale = LedgerItemHelper.getLedgerItemLocale({ ...ledgerItem, contact: contact, contact_id: value })

    if (currentLocale !== newLocale) {
      details = LedgerItemHelper.getFooterDetailsForType(ledgerItem.type, newLocale)
    }

    try {
      if (value) {
        let contactId = ''

        if (contact) {
          // Check if contact has emails (use one of them if present)
          if (contact?.emails.length > 0) {
            contactId = contact.id
          } else {
            const { contacts: emailContacts } = await ContactsController.getContacts({ contact_id: contact.id })

            const contactWithEmailsIndex = emailContacts?.findIndex(c => c?.emails?.length > 0)

            if (contactWithEmailsIndex > -1) {
              contactId = emailContacts[contactWithEmailsIndex].id
            }
          }
        }

        newLedgerItem = {
          ...ledgerItem,
          contact: contact,
          contact_id: contact.id,
          currency: contact.currency,
          email_contact: null,
          email_contact_id: contactId,
          project_id: '',
          details: details,
        }

      }
      else {
        newLedgerItem = {
          ...ledgerItem,
          contact: null,
          contact_id: null,
          currency: setting.default_currency,
          email_contact: null,
          email_contact_id: null,
          project_id: '',
          details: details,
        }
      }

      // Add VAT rules text
      newLedgerItem.vat_rules = VATHelper.getTaxRulesText(taxes, newLedgerItem)

      this.setState({ ledgerItem: newLedgerItem }, () => {
        this.fetchMetadata()
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onLedgerItemProjectChange(value?: string, project?: Project) {
    const { ledgerItem } = this.state

    const updatedLedgerItem = { ...ledgerItem, project_id: value }

    if (
      ledgerItem.type === LedgerItemType.INVOICE &&
      project &&
      project.po_number
    ) {
      updatedLedgerItem.po_number = project.po_number
    }

    this.setState({
      ledgerItem: { ...updatedLedgerItem }
    }, () => {
      this.fetchMetadata()
    })
  }

  onLedgerItemEmailContactChange(value?: string, contact?: Contact) {
    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        email_contact: contact,
        email_contact_id: contact ? contact.id : null,
      }
    })
  }

  onLedgerItemPoNumberChange(e) {
    const { ledgerItem } = this.state
    const newValue = e.currentTarget.value

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        po_number: newValue
      }
    })
  }

  onBillAdvancementClick() {
    const { t } = this.props
    const { metadata } = this.state

    if (metadata &&
      metadata.advancement_invoices &&
      Number(metadata.advancement_invoices.remaining_net_amount) > 0
    ) {
      const remainingNetAmount = Number(metadata.advancement_invoices.remaining_net_amount)

      this.props.showCalculateAmountModal({
        title: t('LedgerItemEditor::Calculate advance amount'),
        amount: remainingNetAmount,
        onSubmit: this.onAdvanceModalSubmit,
      })
    }
  }

  onBillInterimClick() {
    const { t } = this.props
    const { metadata } = this.state

    if (metadata &&
      metadata.advancement_invoices &&
      Number(metadata.advancement_invoices.remaining_net_amount) > 0
    ) {
      const remainingNetAmount = Number(metadata.advancement_invoices.remaining_net_amount)

      this.props.showCalculateAmountModal({
        title: t('LedgerItemEditor::Calculate interim amount'),
        amount: remainingNetAmount,
        onSubmit: this.onAdvanceModalSubmit,
      })
    }
  }

  onBillRemainingAmountClick() {
    const { t } = this.props
    const { ledgerItem, metadata } = this.state

    if (metadata &&
      metadata.advancement_invoices &&
      Number(metadata.advancement_invoices.remaining_net_amount) > 0
    ) {
      const remainingNetAmount = Number(metadata.advancement_invoices.remaining_net_amount)
      const quoteNumber = metadata.advancement_invoices.quotation_number

      const standardTax = this.getStandardTax()

      const lineItem: LineItem = {
        description: t('LedgerItemEditor::Final invoice for quote {{quoteNumber}}', { quoteNumber: quoteNumber }),
        price: remainingNetAmount,
        quantity: 1,
        tax_rate: standardTax?.rate || 0,
        tax_code: standardTax?.code || '',
      }

      this.setState({
        ledgerItem: {
          ...ledgerItem,
          line_items: [
            ...ledgerItem.line_items,
            lineItem
          ]
        }
      }, () => this.onFormSubmit(null))
    }
  }

  onImportTimeEntriesClick(e) {
    e.preventDefault()

    const { ledgerItem } = this.state
    const { showImportTimeEntriesModal } = this.props

    showImportTimeEntriesModal({
      contactId: ledgerItem.contact_id,
      projectId: ledgerItem.project_id,
      onSubmit: this.onImportTimeEntriesModalSubmit,
    })
  }

  onImportExpensesClick(e) {
    e.preventDefault()

    const { ledgerItem } = this.state
    const { showImportExpensesModal } = this.props

    showImportExpensesModal(
      ledgerItem.contact_id,
      ledgerItem.project_id,
      this.onImportExpensesModalSubmit,
    )
  }

  onImportProductsClick(e) {
    e.preventDefault()

    const { showImportProductsModal } = this.props

    showImportProductsModal(this.onImportProductsModalSubmit)
  }

  onEditTaxesClick(e) {
    e.preventDefault()

    const { showTaxesModal } = this.props

    showTaxesModal(this.onEditTaxesModalSubmit)
  }

  onEditTaxesModalSubmit(taxes: WorkspaceTax[]) {
    this.setState({ taxes: taxes })
  }

  onLineItemDragEnd(result: DropResult, provided: ResponderProvided) {
    const { ledgerItem } = this.state
    const { destination, source, draggableId } = result

    if (!destination) return

    if (
      destination.droppableId !== source.droppableId &&
      destination.index !== source.index
    ) {
      return
    }


    const lineItems = [...ledgerItem.line_items]
    const movedLineItem = lineItems[source.index]

    lineItems.splice(source.index, 1)
    lineItems.splice(destination.index, 0, movedLineItem)

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        line_items: lineItems,
      }
    }, this.synchronizeLineItemInputs)
  }

  onLineItemTitleToggle(e, lineItemIndex) {
    const { ledgerItem } = this.state
    const newTitle = e.currentTarget.value

    // Get line items value
    const lineItems = ledgerItem.line_items

    // Update title at line item index
    const title = lineItems[lineItemIndex].title

    if (title === null) {
      // Assign a title string
      lineItems[lineItemIndex].title = ''
    } else {
      // Set title to null essentially hiding the title
      lineItems[lineItemIndex].title = null
    }

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        line_items: lineItems
      }
    }, () => {
      const titleInput: HTMLInputElement = this[`${this.TITLE_LINE_ITEM_REF_KEY}${lineItemIndex}`]

      if (titleInput) {
        titleInput.select()
        titleInput.focus()
      }
    })
  }

  onLineItemTitleChange(e, lineItemIndex) {
    const { ledgerItem } = this.state
    const newTitle = e.currentTarget.value

    // Get line items value
    const lineItems = ledgerItem.line_items

    // Update title at line item index
    lineItems[lineItemIndex].title = newTitle

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        line_items: lineItems
      }
    })
  }

  onLineItemDescriptionChange(newDescription, lineItemIndex) {
    const { ledgerItem } = this.state
    const { line_items } = ledgerItem

    line_items[lineItemIndex].description = newDescription

    ledgerItem.line_items = line_items

    this.setState({ ledgerItem: { ...ledgerItem } })
  }

  onLineItemQuantityChange(e, lineItemIndex) {
    const { ledgerItem } = this.state
    const { line_items } = ledgerItem

    const newQuantity = e.currentTarget.value

    line_items[lineItemIndex].quantity = newQuantity

    ledgerItem.line_items = line_items

    this.setState({
      ledgerItem
    })
  }

  onLineItemRateChange(lineItemIndex, value) {
    const { ledgerItem } = this.state
    const { line_items } = ledgerItem

    line_items[lineItemIndex].price = value

    ledgerItem.line_items = line_items

    this.setState({ ledgerItem: { ...ledgerItem } })
  }

  onLineItemDiscountClick(lineItemIndex) {
    const { ledgerItem } = this.state

    const lineItem = ledgerItem.line_items[lineItemIndex]

    const total = (Number(lineItem.quantity) * Number(lineItem.price))
    const discount = Number(lineItem.discount)

    this.props.showDiscountModal({
      currency: ledgerItem.currency,
      total: total,
      discount: discount,
      onSubmit: (discount: number) => {
        ledgerItem.line_items[lineItemIndex].discount = discount

        this.setState({
          ledgerItem: {
            ...ledgerItem,
          }
        })
      }
    })
  }

  onLineItemTaxChange(lineItemIndex, code) {
    const { ledgerItem, taxes } = this.state
    const { line_items } = ledgerItem

    const lineItem = line_items[lineItemIndex]

    if (lineItem) {
      const taxRate = VATHelper.getTaxRateFromCode(taxes, code)

      line_items[lineItemIndex].tax_code = code
      line_items[lineItemIndex].tax_rate = taxRate?.rate || null
    }

    let updatedLedgerItem = {
      ...ledgerItem,
      line_items: line_items,
    }

    // Add VAT rules text
    updatedLedgerItem.vat_rules = VATHelper.getTaxRulesText(taxes, updatedLedgerItem)

    this.setState({
      ledgerItem: {
        ...updatedLedgerItem,
      }
    })
  }

  onLineItemDeleteClick(e, lineItemIndex) {
    e.preventDefault()

    const { ledgerItem, taxes } = this.state

    // Remove line item from array
    ledgerItem.line_items.splice(lineItemIndex, 1)

    // Add VAT rules text
    ledgerItem.vat_rules = VATHelper.getTaxRulesText(taxes, ledgerItem)

    this.setState({ ledgerItem: { ...ledgerItem } }, this.synchronizeLineItemInputs)
  }

  synchronizeLineItemInputs() {
    const { ledgerItem } = this.state

    ledgerItem.line_items.forEach((lineItem, index) => {
      const rateInput: MoneyInput = this[`${this.RATE_LINE_ITEM_REF_KEY}${index}`]

      // Trigger re-render since these inputs have no one way binding
      if (rateInput) rateInput.value(lineItem.price)
    })
  }

  onNewLineItemPropertyChange(e, property) {
    const { ledgerItem, taxes } = this.state

    const newValue = e.currentTarget.value

    e.currentTarget.blur()

    const defaultTaxRate = this.getStandardTax()

    const lineItem: LineItem = {
      price: '',
      tax_rate: defaultTaxRate?.rate || 0,
      tax_code: defaultTaxRate?.code || '',
      discount: ledgerItem.discount || 0,
    }

    if (property === 'tax_rate') {
      const taxCode = newValue
      const tax = VATHelper.getTaxRateFromCode(taxes, taxCode)

      lineItem.tax_code = taxCode
      lineItem.tax_rate = tax.rate
    } else {
      lineItem[property] = newValue
    }

    let updatedLedgerItem: LedgerItem = {
      ...ledgerItem,
      line_items: [
        ...ledgerItem.line_items,
        lineItem
      ]
    }

    // Add VAT rules text
    updatedLedgerItem.vat_rules = VATHelper.getTaxRulesText(taxes, updatedLedgerItem)

    this.setState({ ledgerItem: { ...updatedLedgerItem } }, async () => {
      const lineItemInput = this[`${property}-input-${updatedLedgerItem.line_items.length - 1}`]

      if (lineItemInput) {
        const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

        // Wrapper components
        if (property === 'description') {
          let attempts = 0;
          const maxAttempts = 10; // Set a reasonable limit for the number of attempts
          let editorInitialized = lineItemInput?.editorInitialized;

          do {
            editorInitialized = lineItemInput?.editorInitialized;

            if (editorInitialized) {
              lineItemInput.editor.events.focus();
              lineItemInput.editor.selection.setAtEnd(lineItemInput.editor.$el.get(0));
              lineItemInput.editor.selection.restore();
            }

            attempts++
            await sleep(25)
          } while (attempts < maxAttempts && !editorInitialized)
        } else if (property === 'rate') {
          lineItemInput.focus()
          lineItemInput.input.current.value = ''
          lineItemInput.input.current.value = newValue
        } else {
          // Focus element
          lineItemInput.focus()
          lineItemInput.value = ''
          lineItemInput.value = newValue
        }
      }
    })
  }

  onLedgerItemDiscountChange(value) {
    const { ledgerItem } = this.state

    ledgerItem
      .line_items
      .forEach((lineItem, index) => {
        lineItem.discount = value
      })

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        discount: value,
      }
    })
  }

  onLedgerItemIssuedOnChange(value) {
    const issuedOn = value

    const { ledgerItem } = this.state

    if (value === '') {
      ledgerItem.issued_on = null
      ledgerItem.due_on = null
      ledgerItem.invoiced_on = null
      ledgerItem.invoice_id = null
      ledgerItem.sent_at = null
      ledgerItem.viewed_at = null
    } else {
      ledgerItem.issued_on = moment(issuedOn).format('YYYY-MM-DD')
      ledgerItem.due_on = moment(issuedOn).add(ledgerItem.estimated_net_on, 'days').format('YYYY-MM-DD')
    }

    this.setState({
      ledgerItem: ledgerItem
    })
  }

  onLedgerItemIdentifierChange(e) {
    const { ledgerItem } = this.state

    const value = PaymentHelper.unformatPaymentReference(ledgerItem.identifier_type, e.currentTarget.value)

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        identifier: value,
      }
    })
  }

  onLedgerItemIdentifierTypeChange(e) {
    e.preventDefault()
    const { ledgerItem } = this.state
    const { currentUser: { workspace: { setting: settings } }, updateSettings } = this.props

    if (ledgerItem.identifier_type === RemittanceInformationType.FREE) {
      const identifier = PaymentHelper.generatePaymentReference(RemittanceInformationType.STRUCTURED)

      this.setState({
        ledgerItem: {
          ...ledgerItem,
          identifier_type: RemittanceInformationType.STRUCTURED,
          identifier: identifier
        }
      })
    } else if (ledgerItem.identifier_type === RemittanceInformationType.STRUCTURED) {
      const identifier = PaymentHelper.generatePaymentReference(RemittanceInformationType.FREE)

      this.setState({
        ledgerItem: {
          ...ledgerItem,
          identifier_type: RemittanceInformationType.FREE,
          identifier: identifier
        }
      }, () => {
        if (this.referenceInput.current) {
          // @ts-ignore
          this.referenceInput.current.inputElement.value = identifier
        }
      })
    }

    SettingsController
      .update({
        ...settings,
        default_identifier_type: ledgerItem.identifier_type === RemittanceInformationType.FREE ? RemittanceInformationType.STRUCTURED : RemittanceInformationType.FREE,
      })
      .then(response => {
        // @ts-ignore
        if (response.errors) {
          Notification.notifyError(`Oeps! Er is iets verkeerd gelopen tijdens het opslaan.`);
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update global
          updateSettings(settings)
        }
      })
      .catch(console.error)
  }

  onLedgerItemIdentifierGenerateClick(e) {
    const { ledgerItem } = this.state

    const identifier = PaymentHelper.generatePaymentReference(ledgerItem.identifier_type)

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        identifier: identifier,
      }
    }, () => {
      if (this.referenceInput.current) {
        if (ledgerItem.identifier_type === RemittanceInformationType.FREE) {
          // @ts-ignore
          this.referenceInput.current.inputElement.value = identifier
        }
      }
    })
  }

  onLedgerItemDueOnChange(e) {
    e.preventDefault()

    const { workspace } = this.props.currentUser
    const { setting } = workspace

    const dueOnDuration = e.currentTarget.value

    const { ledgerItem } = this.state

    // Set estimated net on
    ledgerItem.estimated_net_on = dueOnDuration

    // Calculate due on if issued_on set
    if (ledgerItem.issued_on) {
      const { estimated_net_on } = ledgerItem

      const amountOfDays = estimated_net_on ? estimated_net_on : setting.invoice_payment_duration

      ledgerItem.due_on = moment(ledgerItem.issued_on).add(ledgerItem.estimated_net_on, 'days').format('YYYY-MM-DD')
    }

    this.setState({
      ledgerItem: ledgerItem
    })
  }

  onLedgerItemApprovedOnChange(approvedOn) {
    const { ledgerItem } = this.state

    ledgerItem.approved_on = moment(approvedOn).format('YYYY-MM-DD')

    this.setState({
      ledgerItem: ledgerItem
    })
  }

  onLedgerItemRejectedOnChange(rejectedOn) {
    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        rejected_on: moment(rejectedOn).format('YYYY-MM-DD')
      }
    })
  }

  onLedgerItemPaidChange(value) {
    const ledgerItemPaidDate = value

    const { ledgerItem } = this.state

    // Set estimated net on
    if (!ledgerItem.issued_on) ledgerItem.issued_on = moment(ledgerItemPaidDate).format('YYYY-MM-DD')
    if (!ledgerItem.due_on) ledgerItem.due_on = moment(ledgerItemPaidDate).format('YYYY-MM-DD')

    ledgerItem.paid_on = moment(ledgerItemPaidDate).format('YYYY-MM-DD')

    this.setState({
      ledgerItem: ledgerItem
    })
  }

  onLedgerItemBookedOnChange(value) {
    const { ledgerItem } = this.state

    const bookedOn = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        booked_on: bookedOn,
      }
    })
  }

  onLedgerItemDeliveredOnChange(value) {
    const { ledgerItem } = this.state

    const deliveredOn = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        delivered_on: deliveredOn,
      }
    })
  }

  onLedgerItemInvoicedOnChange(value) {
    const { ledgerItem } = this.state

    const invoicedOn = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        invoiced_on: invoicedOn,
      }
    })
  }

  onLedgerItemCancelledOnChange(value) {
    const { ledgerItem } = this.state

    const cancelledOn = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        cancelled_on: cancelledOn,
      }
    })
  }

  onLedgerItemCreditedOnChange(value) {
    const { ledgerItem } = this.state

    const creditedOn = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        credited_on: creditedOn,
      }
    })
  }

  onLedgerItemVatRulesChange(e) {
    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        vat_rules: e.currentTarget.value,
      },
    })
  }

  onLedgerItemDetailsChange(details: string) {
    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        details: details,
      },
    })
  }

  async onFormSubmit(e: any, closeOnSuccess = false) {
    if (e) e.preventDefault()
    const { t } = this.props
    const { ledgerItem, metadata } = this.state

    this.setState({ isSaving: true })

    try {
      if (ledgerItem.id) {
        const response = await InvoicesController.update(ledgerItem)

        const { errors } = response

        if (errors) {
          this.setState({
            errors: errors
          })

          Notification.notifyError(t('LedgerItemEditor::Oops something went wrong!'))
        }
        else {
          this.setState({ errors: {}, ledgerItem: response }, () => {
            if (closeOnSuccess) {
              this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: response.id }))
            } else {
              // Refetch metadata
              this.fetchMetadata()
            }
          })
        }
      } else {
        const { ledgerItem } = this.state

        const response = await InvoicesController.create(ledgerItem)
        const { errors } = response

        if (errors) {
          this.setState({
            errors: errors
          })

          Notification.notifyError(t('LedgerItemEditor::Oops something went wrong!'))
        }
        else {
          this.setState({ ledgerItem: response }, async () => {
            if (closeOnSuccess) {
              this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: response.id }))
            } else {
              this.props.history.replace(RouteHelper.process(ERoute.PATH_INVOICE_EDIT, { id: response.id }))

              // Refetch metadata
              this.fetchMetadata()
            }

            // Ask to mark the associated project as "completed"
            if (this.state.ledgerItem.type === LedgerItemType.INVOICE && this.state.ledgerItem.project && ProjectHelper.hasActiveStatus(this.state.ledgerItem.project)) {
              const project = this.state.ledgerItem.project
              const startDate = moment(project.start_date)

              this.props.showConfirmModal({
                title: t('InvoiceShow::Project status'),
                description: t('InvoiceShow::We noticed you just created an invoice for project <b>"{{project}}"</b> would you like to update the status of the project?', { project: project.name }),
                action: { label: t('InvoiceShow::Update status') },
                onConfirm: () => {
                  this.props.showProjectStatusUpdateModal({
                    project: {
                      id: this.state.ledgerItem.project.id,
                      status: ProjectStatus.COMPLETED,
                      start_date: startDate.isValid() ? startDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                      end_date: moment().format('YYYY-MM-DD')
                    },
                  })
                }
              })
            }
          })
        }
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSaving: false })
    }
  }

  onDownloadClick(e) {
    e.preventDefault()
    const { ledgerItem } = this.state

    LedgerItemHelper.download(ledgerItem)
  }


  onTemplateClick(template: string) {
    const { ledgerItem } = this.state
    const { currentUser: { workspace: { setting: settings } }, t } = this.props
    const { updateSettings } = this.props

    SettingsController
      .update({
        ...settings,
        ledger_item_template_id: template,
      })
      .then(response => {
        // @ts-ignore
        if (response.errors) {
          Notification.notifyError(t('LedgerItemEditor::Oops something went wrong!'));
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update global
          updateSettings(settings)
          // Play success
          Sound.play(Sounds.COMPLETE)

          // Remove pdf attachment download url so buttons are not present
          this.setState({ ledgerItem: { ...ledgerItem, pdf_attachment_download_url: null } })

          // Trigger re-generations
          InvoicesController.update({ ...ledgerItem })
        }
      })
      .catch(console.error)

  }

  onToggleMobileTemplatePicker() {
    const { mobileTemplatePickerActive } = this.state

    this.setState({
      mobileTemplatePickerActive: !mobileTemplatePickerActive
    })
  }

  onMobileTemplatePick(index) {
    const { ledgerItemTemplates } = this.state

    const selectedLedgerItemTemplate = ledgerItemTemplates[index]

    if (selectedLedgerItemTemplate) {
      this.onTemplateClick(selectedLedgerItemTemplate.id)
    }
  }

  onAdvanceModalSubmit(advanceAmount: number) {
    const { t } = this.props
    const { ledgerItem, metadata } = this.state
    const urlParams = UrlHelper.getParams(this.props.location.search)
    const quoteNumber = metadata.advancement_invoices.quotation_number

    const standardTax = this.getStandardTax()

    const invoiceType: InvoiceType = urlParams.invoice_type

    let description = ''

    switch (invoiceType) {
      case InvoiceType.INTERIM:
        description = t('LedgerItemEditor::Interim invoice for {{quoteNumber}}', { quoteNumber: quoteNumber })
        break
      case InvoiceType.ADVANCE:
      default:
        description = t('LedgerItemEditor::Advance invoice for quote {{quoteNumber}}', { quoteNumber: quoteNumber })
        break
    }

    const lineItem: LineItem = {
      description: description,
      price: advanceAmount,
      quantity: 1,
      tax_rate: standardTax?.rate || 0,
      tax_code: standardTax?.code || '',
    }

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        line_items: [
          ...ledgerItem.line_items,
          lineItem
        ]
      }
    }, () => this.onFormSubmit(null))
  }

  onImportTimeEntriesModalSubmit(groupedTimeEntries: GroupedTimeEntries[], travelDistance?: number) {
    const { t, currentUser } = this.props
    const { setting } = currentUser.workspace
    const { ledgerItem, taxes } = this.state

    const standardTax = this.getStandardTax()

    groupedTimeEntries.forEach((groupedTimeEntry, index) => {
      const rate = groupedTimeEntry.rate
      const totalDuration = groupedTimeEntry.entries.map(e => e.duration).reduce((total, number) => Number(total) + Number(number), 0)
      const entryIds = groupedTimeEntry.entries.map(e => e.id)
      const fixedRate = groupedTimeEntry.fixed_rate

      const lineItem: LineItem = {
        description: groupedTimeEntry.description,
        price: rate,
        quantity: fixedRate ? entryIds.length : TimeHelper.convertSecondsToHours(totalDuration),
        entry_ids: entryIds,
        tax_rate: standardTax?.rate || 0,
        tax_code: standardTax?.code || '',
      }

      ledgerItem.line_items.push(lineItem)
    })

    if (travelDistance && travelDistance > 0) {
      const lineItem: LineItem = {
        description: t('LedgerItemEditor::Relocation costs'),
        price: setting.travel_cost,
        quantity: travelDistance,
        tax_rate: standardTax?.rate || 0,
        tax_code: standardTax?.code || '',
      }

      ledgerItem.line_items.push(lineItem)
    }

    // Add VAT rules text
    ledgerItem.vat_rules = VATHelper.getTaxRulesText(taxes, ledgerItem)

    this.setState({ ledgerItem: { ...ledgerItem } })
  }

  onImportExpensesModalSubmit(expenses: Expense[], description?: string) {
    const { ledgerItem, taxes } = this.state
    const standardTax = this.getStandardTax()
    const expenseIds = expenses.map(expense => expense.id)

    if (description && description.length > 0) {
      const invoiceableAmount = expenses.reduce((currentValue, expense) => (currentValue + (Number(expense.billable_amount) || Number(expense.net_total))), 0)

      const lineItem: LineItem = {
        description: description,
        price: invoiceableAmount,
        quantity: 1,
        tax_rate: standardTax?.rate || 0,
        tax_code: standardTax?.code || '',
        expense_ids: expenseIds
      }

      ledgerItem.line_items.push(lineItem)
    } else {
      expenses.forEach(({ id, name, net_total, billable_amount }) => {

        const lineItem: LineItem = {
          description: name,
          price: billable_amount || net_total,
          quantity: 1,
          tax_rate: standardTax?.rate || 0,
          tax_code: standardTax?.code || '',
          expense_ids: [id]
        }

        ledgerItem.line_items.push(lineItem)
      })
    }

    // Add VAT rules text
    ledgerItem.vat_rules = VATHelper.getTaxRulesText(taxes, ledgerItem)

    this.setState({
      ledgerItem: {
        ...ledgerItem,
      }
    })
  }

  onImportProductsModalSubmit(importedProducts: ImportedProduct[]) {
    const { ledgerItem, taxes } = this.state

    // If the contact is outside the EU, we need to set the tax rate to 0
    importedProducts.forEach(({ quantity, product: { id, description, price, tax_rate, tax_code } }) => {
      const applicableTaxRate = this.getStandardTax()

      let taxRate = tax_rate
      let taxCode = tax_code

      if (
        applicableTaxRate && [
          VATLiability.OUTSIDE_EU_VAT_LIABILITY,
          VATLiability.INTRACOMMUNITY_VAT_LIABILITY,
          VATLiability.EXEMPTED_VAT_LIABILITY,
          VATLiability.CO_CONTRACTOR_VAT_LIABILITY
        ].includes(ledgerItem?.contact?.vat_liability)) {
        taxRate = applicableTaxRate?.rate
        taxCode = applicableTaxRate?.code
      }

      const lineItem: LineItem = {
        description: description,
        price: price,
        quantity: quantity,
        tax_rate: taxRate,
        tax_code: taxCode,
        product_ids: [id],
      }

      ledgerItem.line_items.push(lineItem)
    })

    // Add VAT rules text
    ledgerItem.vat_rules = VATHelper.getTaxRulesText(taxes, ledgerItem)

    this.setState({ ledgerItem: ledgerItem })
  }

  onScheduleUnitChange(option) {
    const { currentUser: { workspace } } = this.props
    const { ledgerItem } = this.state

    const recurrenceUnit: RecurrenceUnit = option.value

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        recurring_schedule: {
          ...RecurringScheduleHelper.getDefaultSettingsForType(recurrenceUnit, workspace),
        },
      },
    }, () => {
      // Sync input with real schedule date
      if (this.scheduleStartDateInput.current) this.scheduleStartDateInput.current.setSelectedDate(moment(this.state.ledgerItem.recurring_schedule.start_date))
    })
  }

  onScheduleDayOfWeekChange(option) {
    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        recurring_schedule: {
          ...ledgerItem.recurring_schedule,
          repeat_on_day_of_week: option ? [Number(option.value)] : [],
        },
      },
    })
  }

  onScheduleDayOfMonthChange(option) {
    const { ledgerItem } = this.state

    // If last repeat at end of month
    if (option.value === 'last') {
      this.setState({
        ledgerItem: {
          ...ledgerItem,
          recurring_schedule: {
            ...ledgerItem.recurring_schedule,
            // Clear day of month
            repeat_on_day_of_month: null,
            // Set boolean flag so server can calculate
            repeat_at_end_of_month: true
          },
        },
      })
    } else {
      this.setState({
        ledgerItem: {
          ...ledgerItem,
          recurring_schedule: {
            ...ledgerItem.recurring_schedule,
            repeat_on_day_of_month: Number(option.value),
            repeat_at_end_of_month: false,
          },
        },
      })
    }
  }

  onScheduleMonthOfYearChange(option) {
    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        recurring_schedule: {
          ...ledgerItem.recurring_schedule,
          repeat_on_month_of_year: Number(option.value),
        },
      },
    })
  }

  onScheduleStartDateChange(value) {
    const { ledgerItem } = this.state;

    const newStartDate = moment(value)

    if (newStartDate.isValid()) {
      this.setState({
        ledgerItem: {
          ...ledgerItem,
          recurring_schedule: {
            ...ledgerItem.recurring_schedule,
            start_date: newStartDate.format('YYYY-MM-DD'),
          },
        },
      })
    }
  }

  onScheduleEndedOptionChange(option) {
    const { ledgerItem } = this.state

    let scheduleSettings: RecurringSchedule = {}

    if (option.value === 'on') {
      scheduleSettings = {
        end_date: moment().add(1, 'month').format('YYYY-MM-DD')
      }
    } else if (option.value === 'never') {
      scheduleSettings = {
        end_date: null,
      }
    }

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        recurring_schedule: {
          ...ledgerItem.recurring_schedule,
          ...scheduleSettings,
        }
      }
    })
  }

  onScheduleEndDateChange(value) {
    const { ledgerItem } = this.state;

    const newEndDate = moment(value)

    if (newEndDate.isValid()) {
      this.setState({
        ledgerItem: {
          ...ledgerItem,
          recurring_schedule: {
            ...ledgerItem.recurring_schedule,
            end_date: newEndDate.format('YYYY-MM-DD'),
          },
        },
      })
    }
  }

  onScheduleTimezoneChange(option) {
    const { ledgerItem } = this.state

    this.setState({
      ledgerItem: {
        ...ledgerItem,
        recurring_schedule: {
          ...ledgerItem.recurring_schedule,
          timezone: option.value,
        },
      },
    })
  }

  getLedgerItemTypeDefaults(type: LedgerItemType): LedgerItem {
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace
    const { ledgerItem } = this.state

    const locale = LedgerItemHelper.getLedgerItemLocale(ledgerItem)

    switch (type) {
      case LedgerItemType.ORDER_FORM: return {
        number: setting.next_order_form_number,
        identifier: PaymentHelper.generatePaymentReference(setting.default_identifier_type),
        identifier_type: setting.default_identifier_type,
        type: type,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_order_form_details'),
        estimated_net_on: setting.order_form_validity_duration,
        paid_on: null,
        ledger_condition_id: setting.default_ledger_condition_id,
        recurring_schedule: null,
        po_number: null
      }
      case LedgerItemType.DELIVERY_NOTE: return {
        number: setting.next_delivery_note_number,
        identifier: PaymentHelper.generatePaymentReference(setting.default_identifier_type),
        identifier_type: setting.default_identifier_type,
        type: type,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_delivery_note_details'),
        estimated_net_on: 0,
        ledger_condition_id: setting.default_ledger_condition_id,
        recurring_schedule: null,
        po_number: null
      }
      case LedgerItemType.QUOTATION: return {
        number: setting.next_quotation_number,
        identifier: PaymentHelper.generatePaymentReference(setting.default_identifier_type),
        identifier_type: setting.default_identifier_type,
        type: type,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_quotation_details'),
        estimated_net_on: setting.quotation_validity_duration,
        paid_on: null,
        ledger_condition_id: setting.default_ledger_condition_id,
        recurring_schedule: null,
        po_number: null
      }
      case LedgerItemType.PRO_FORMA_INVOICE: return {
        number: setting.next_pro_forma_invoice_number,
        identifier: PaymentHelper.generatePaymentReference(setting.default_identifier_type),
        identifier_type: setting.default_identifier_type,
        type: type,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_pro_forma_invoice_details'),
        estimated_net_on: setting.invoice_payment_duration,
        ledger_condition_id: setting.default_ledger_condition_id,
        recurring_schedule: null,
        po_number: null
      }
      case LedgerItemType.INVOICE: return {
        number: setting.next_invoice_number,
        identifier: PaymentHelper.generatePaymentReference(setting.default_identifier_type),
        identifier_type: setting.default_identifier_type,
        type: type,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_invoice_details'),
        estimated_net_on: setting.invoice_payment_duration,
        ledger_condition_id: setting.default_ledger_condition_id,
        recurring_schedule: null,
        po_number: null
      }
      case LedgerItemType.CREDIT_NOTE: return {
        number: setting.next_invoice_number,
        identifier: PaymentHelper.generatePaymentReference(setting.default_identifier_type),
        identifier_type: setting.default_identifier_type,
        type: type,
        due_on: null,
        approved_on: null,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_credit_note_details'),
        ledger_condition_id: null,
        recurring_schedule: null,
        po_number: null
      }
      case LedgerItemType.RECURRING_INVOICE: return {
        number: '',
        identifier: '',
        type: type,
        details: MobilityHelper.getTranslation(locale, setting.translations, 'default_invoice_details'),
        estimated_net_on: setting.invoice_payment_duration,
        ledger_condition_id: setting.default_ledger_condition_id,
        issued_on: null,
        due_on: null,
        recurring_schedule: RecurringScheduleHelper.getDefaultSettingsForType(RecurrenceUnit.MONTHLY, workspace),
        po_number: null
      }
      default:
        console.warn(`${type} is not a supported type`)
    }
  }

  processUrlParameters() {
    const { ledgerItem } = this.state

    if (!ledgerItem.id) {
      const params = UrlHelper.getParams(this.props.location.search)

      const contact_id = params.contact_id
      const email_contact_id = params.email_contact_id
      const project_id = params.project_id
      const quotation_id = params.quotation_id
      const invoice_id = params.invoice_id
      const deal_id = params.deal_id

      if (contact_id) ledgerItem.contact_id = contact_id
      if (email_contact_id) ledgerItem.email_contact_id = email_contact_id
      if (project_id) ledgerItem.project_id = project_id

      // Scenario: Links an invoice to a quote
      if (quotation_id) ledgerItem.quotation_id = quotation_id
      // Scenario: Links a creditnote to an invoice
      if (invoice_id) ledgerItem.invoice_id = invoice_id
      // Scenario: Link a deal to a ledger item
      if (deal_id) ledgerItem.deal_id = deal_id

      this.setState({ ledgerItem: ledgerItem })
    }
  }

  getStandardTax(): WorkspaceTax | null {
    const { ledgerItem, taxes } = this.state

    return VATHelper.getDefaultTaxRate(taxes, ledgerItem?.contact?.vat_liability)
  }

  switchMode(mode: EditorMode) {
    this.setState({
      mode: mode,
      mobileTemplatePickerActive: false,
      currentPage: 1,
    })
  }

  onBackPress() {
    this.props.history.goBack()
  }

  onToPreviewPress() {
    this.switchMode('preview')
  }

  onToEditorPress() {
    this.switchMode('editor')
  }

  onDocumentNavigateBack() {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage - 1 })
  }

  onDocumentNavigateForward() {
    const { currentPage } = this.state
    this.setState({ currentPage: currentPage + 1 })
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({
      pageCount: numPages,
      isPreviewReloading: false,
      didInitialPreviewLoad: true,
    }, () => {
      if (this.editorDocumentWrapper.current) this.editorDocumentWrapper.current.calculateWidth()
      if (this.previewDocumentWrapper.current) this.previewDocumentWrapper.current.calculateWidth()
    })
  }

  renderScheduleUnitOptions() {
    const { t } = this.props
    const { ledgerItem } = this.state

    if (ledgerItem && ledgerItem.recurring_schedule) {
      const { recurring_schedule:
        {
          recurrence_unit,
          repeat_on_day_of_week,
          repeat_on_day_of_month,
          repeat_on_month_of_year,
          repeat_at_end_of_month,
        }
      } = ledgerItem

      // Daily has no options
      if (recurrence_unit === RecurrenceUnit.DAILY) return null

      if (recurrence_unit === RecurrenceUnit.WEEKLY) {
        const weeklyOptions = RecurringScheduleHelper.dayOfWeekOptions()
        const selectedWeeklyOption = weeklyOptions.find(weeklyOption => repeat_on_day_of_week.includes(Number(weeklyOption.value)))

        return (
          <>
            <ScheduleRowMiddleLabel>
              {t('LedgerItemEditor::every')}
            </ScheduleRowMiddleLabel>

            <ScheduleSelectContainer style={{ zIndex: 26 }}>
              <PowerSelect
                options={weeklyOptions}
                value={selectedWeeklyOption}
                onChange={this.onScheduleDayOfWeekChange}
                theme={ReactSelectTheme}
              />
            </ScheduleSelectContainer>
          </>
        )
      }

      if (
        recurrence_unit === RecurrenceUnit.MONTHLY ||
        recurrence_unit === RecurrenceUnit.BIMONTHLY ||
        recurrence_unit === RecurrenceUnit.QUARTERLY ||
        recurrence_unit === RecurrenceUnit.SEMESTERLY
      ) {
        const dayOfMonthOptions = RecurringScheduleHelper.dayOfMonthOptions()
        const selectedDayOfMonthOption = repeat_at_end_of_month ? dayOfMonthOptions.find(dayOfMonthOption => dayOfMonthOption.value === 'last') : dayOfMonthOptions.find(dayOfMonthOption => Number(dayOfMonthOption.value) === repeat_on_day_of_month)

        return (
          <>
            <ScheduleRowMiddleLabel>
              {t('LedgerItemEditor::every')}
            </ScheduleRowMiddleLabel>

            <ScheduleSelectContainer style={{ zIndex: 26 }}>
              <PowerSelect
                options={dayOfMonthOptions}
                value={selectedDayOfMonthOption}
                onChange={this.onScheduleDayOfMonthChange}
                theme={ReactSelectTheme}
              />
            </ScheduleSelectContainer>

            <ScheduleRowMiddleLabel>
              {t('LedgerItemEditor::day of the month')}
            </ScheduleRowMiddleLabel>
          </>
        )
      }

      if (recurrence_unit === RecurrenceUnit.YEARLY) {
        const monthOfYearOptions = RecurringScheduleHelper.monthOfYearOptions()
        const selectedMonthOption = monthOfYearOptions.find(monthOption => Number(monthOption.value) === repeat_on_month_of_year)

        const dayOfMonthOptions = RecurringScheduleHelper.dayOfMonthOptions()
        const selectedDayOfMonthOption = repeat_at_end_of_month ? dayOfMonthOptions.find(dayOfMonthOption => dayOfMonthOption.value === 'last') : dayOfMonthOptions.find(dayOfMonthOption => Number(dayOfMonthOption.value) === repeat_on_day_of_month)

        return (
          <>
            <ScheduleRowMiddleLabel >
              {t('LedgerItemEditor::every')}
            </ScheduleRowMiddleLabel>

            <ScheduleSelectContainer style={{ zIndex: 26 }}>
              <PowerSelect
                options={monthOfYearOptions}
                value={selectedMonthOption}
                onChange={this.onScheduleMonthOfYearChange}
                theme={ReactSelectTheme}
              />
            </ScheduleSelectContainer>

            <ScheduleRowMiddleLabel>
              {t('LedgerItemEditor::on the')}
            </ScheduleRowMiddleLabel>

            <ScheduleSelectContainer style={{ zIndex: 26 }}>
              <PowerSelect
                options={dayOfMonthOptions}
                value={selectedDayOfMonthOption}
                onChange={this.onScheduleDayOfMonthChange}
                theme={ReactSelectTheme}
              />
            </ScheduleSelectContainer>

            <ScheduleRowMiddleLabel>
              {t('LedgerItemEditor::day of the month')}
            </ScheduleRowMiddleLabel>
          </>
        )
      }
    }
    return null
  }

  renderScheduleEndOn() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { ledgerItem } = this.state

    if (ledgerItem.recurring_schedule) {
      const { end_date } = ledgerItem.recurring_schedule

      const endOptions = [{ label: t('LedgerItemEditor::On'), value: 'on' }, { label: t('LedgerItemEditor::Never'), value: 'never' }]
      const selectedEndOption = end_date ? endOptions[0] : endOptions[1]

      return (
        <>
          <ScheduleSelectContainer>
            <PowerSelect
              options={endOptions}
              value={selectedEndOption}
              onChange={this.onScheduleEndedOptionChange}
              theme={ReactSelectTheme}
            />
          </ScheduleSelectContainer>
          {end_date && <ScheduleSelectContainer style={{ marginLeft: 8 }}>
            <DateInput
              name='end_date'
              dateFormat={setting.date_format}
              timeFormat={false}
              initialValue={moment(end_date)}
              onChange={this.onScheduleEndDateChange}
              closeOnSelect
            />
          </ScheduleSelectContainer>}
        </>
      )
    }

    return null
  }

  renderIssuedOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    let labelText = t('LedgerItemEditor::Issued on')

    if (ledgerItem.type === LedgerItemType.DELIVERY_NOTE) {
      labelText = t('LedgerItemEditor::Delivered on')
    }

    return (
      <div className='form-item'>
        <label>{labelText}</label>
        <DateInput
          name='issued_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.issued_on)}
          onChange={this.onLedgerItemIssuedOnChange}
          inputProps={{ placeholder: setting.date_format }}
          closeOnSelect
        />
      </div>
    )
  }

  renderReference() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props

    return (
      <div className='form-item'>
        <label>
          {t('LedgerItemEditor::Reference')}
          <Tooltip
            content={t('LedgerItemEditor::Add a personal or structured communication ID')}
            containerStyle={{ marginLeft: 8 }}
          />
        </label>

        <ReferenceContainer>
          <MaskedInput
            ref={this.referenceInput}
            type='text'
            value={ledgerItem.identifier}
            guide={true}
            showMask={true}
            mask={PaymentHelper.getPaymentReferenceMask(ledgerItem.identifier_type)}
            placeholder={ledgerItem.identifier_type === 'free' ? t('LedgerItemEditor::Reference') : '+++ 085 / 1927 / 54115 +++'}
            placeholderChar='_'
            // onFocus={this.onManualEndedAtFocus}
            // onBlur={this.onManualEndedAtBlur}
            onChange={this.onLedgerItemIdentifierChange}
          />
          <a href='javascript://' className='button-icon button-primary' onClick={this.onLedgerItemIdentifierGenerateClick}>
            <Icon icon='random' />
          </a>
        </ReferenceContainer>
        <ReferenceTypeSwitchContainer>
          {t('LedgerItemEditor::Structured communication ID')}
          <Switch
            name='identifier_type'
            onClick={this.onLedgerItemIdentifierTypeChange}
            checked={ledgerItem.identifier_type === RemittanceInformationType.STRUCTURED}
          />
        </ReferenceTypeSwitchContainer>
      </div>
    )
  }

  renderEstimatedNetOn() {
    const { t } = this.props
    const { ledgerItem } = this.state

    let labelText = t('LedgerItemEditor::Due in')

    if (ledgerItem.type === LedgerItemType.QUOTATION) {
      labelText = t('LedgerItemEditor::Expires in')
    } else if (ledgerItem.type === LedgerItemType.INVOICE || ledgerItem.type === LedgerItemType.RECURRING_INVOICE) {
      labelText = t('LedgerItemEditor::Payment term')
    } else if (ledgerItem.type === LedgerItemType.ORDER_FORM) {
      labelText = t('LedgerItemEditor::Delivery in')
    }

    return (
      <div className='form-item'>
        <label>
          {labelText}
        </label>
        <Select
          name='estimated_net_on'
          value={ledgerItem.estimated_net_on}
          onChange={this.onLedgerItemDueOnChange}
          options={this.getLedgerItemEstimatedNetOnOptions()}
          allowEmpty={false}
        />
      </div>
    )
  }

  renderApprovedOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Approved on')}</label>
        <DateInput
          name='issued_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.approved_on)}
          onChange={this.onLedgerItemApprovedOnChange}
          inputProps={{ placeholder: setting.date_format }}
          closeOnSelect
        />
      </div>
    )
  }

  renderRejectedOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Rejected on')}</label>
        <DateInput
          name='rejected_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.rejected_on)}
          onChange={this.onLedgerItemRejectedOnChange}
          inputProps={{ placeholder: setting.date_format }}
          closeOnSelect
        />
      </div>
    )
  }

  renderPaidOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Paid on')}</label>
        <DateInput
          name='paid_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.paid_on)}
          inputProps={{ placeholder: setting.date_format }}
          onChange={this.onLedgerItemPaidChange}
          closeOnSelect
        />
      </div>
    )
  }

  renderBookedOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Processed in accounting')}</label>
        <DateInput
          name='booked_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.booked_on)}
          inputProps={{ placeholder: setting.date_format }}
          onChange={this.onLedgerItemBookedOnChange}
          closeOnSelect
        />
      </div>
    )
  }

  renderDeliveredOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Delivered on')}</label>
        <DateInput
          name='delivered_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.delivered_on)}
          inputProps={{ placeholder: setting.date_format }}
          onChange={this.onLedgerItemDeliveredOnChange}
          closeOnSelect
        />
      </div>
    )
  }

  renderInvoicedOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Invoiced on')}</label>
        <DateInput
          name='invoiced_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.invoiced_on)}
          inputProps={{ placeholder: setting.date_format }}
          onChange={this.onLedgerItemInvoicedOnChange}
          closeOnSelect
        />
      </div>
    )
  }

  renderCancelledOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Cancelled on')}</label>
        <DateInput
          name='invoiced_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.cancelled_on)}
          inputProps={{ placeholder: setting.date_format }}
          onChange={this.onLedgerItemCancelledOnChange}
          closeOnSelect
        />
      </div>
    )
  }

  renderCreditedOn() {
    const { t } = this.props
    const { ledgerItem } = this.state
    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    return (
      <div className='form-item'>
        <label>{t('LedgerItemEditor::Credited on')}</label>
        <DateInput
          name='credited_on'
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={moment(ledgerItem.credited_on)}
          inputProps={{ placeholder: setting.date_format }}
          onChange={this.onLedgerItemCreditedOnChange}
          closeOnSelect
        />
      </div>
    )
  }



  renderEditor() {
    const { t } = this.props
    const {
      currentPage,
      pageCount,
      ledgerItem,
      ledgerItemTypes,
      ledgerItemVariables,
      ledgerItemCurrencies,
      timezones,
      isSaving,
      isPreviewReloading,
      didInitialPreviewLoad,
      metadata,
      customFields,
      taxes,
    } = this.state

    const { currentUser } = this.props
    const { workspace } = currentUser
    const { setting } = workspace

    const standardTax = this.getStandardTax()
    const ledgerItemSubtotal = LedgerItemHelper.getLedgerItemSubtotal(ledgerItem)
    const ledgerItemNettotal = LedgerItemHelper.getLedgerItemNetTotal(ledgerItem)
    const ledgerItemDiscountTotal = LedgerItemHelper.getLedgerItemDiscountTotal(ledgerItem)
    const ledgerItemTaxTotalsList = LedgerItemHelper.getLedgerItemTaxTotalsList(ledgerItem)
    const ledgerItemTotal = LedgerItemHelper.getLedgerItemTotal(ledgerItem)

    const ledgerItemTypeOptions = ledgerItemTypes.map(type => ({ label: LedgerItemHelper.getTypeLabel(type), value: type }))
    const selectedLedgerItemTypeOption = ledgerItemTypeOptions.find(option => option.value === ledgerItem.type)

    const ledgerItemCurrencyOptions = ledgerItemCurrencies.map((ledgerItemCurrency) => ({ label: ledgerItemCurrency.label, value: ledgerItemCurrency.value }))
    const selectedCurrencyOption = ledgerItemCurrencyOptions.find(option => option.value === ledgerItem.currency)

    // Schedule options
    const scheduleUnitOptions = RecurringScheduleHelper.unitOptions()
    const selectedScheduleUnitOption = ledgerItem.recurring_schedule ? scheduleUnitOptions.find(option => option.value === ledgerItem.recurring_schedule.recurrence_unit) : null

    const timezoneOptions = timezones
    const selectedTimezoneOption = timezones.find(option => {
      let timezone = workspace.timezone

      if (ledgerItem.recurring_schedule && ledgerItem.recurring_schedule.timezone) {
        timezone = ledgerItem.recurring_schedule.timezone
      }

      return option.value === timezone
    })

    return (
      <Container>
        <Helmet>
          <title>{ledgerItem ? t('LedgerItemEditor::{{__appName}} | {{number}}', { number: ledgerItem.number }) : t('LedgerItemEditor::{{__appName}} | Editor')}</title>
        </Helmet>

        <EditorForm>
          <EditorFormWrapper>
            <EditorFormClose onClick={this.onBackPress}>
              <Icon icon='close' />
            </EditorFormClose>

            {ledgerItem.type === LedgerItemType.RECURRING_INVOICE && <EditorFormTitle
              type='text'
              value={String(t('LedgerItemEditor::Automatic'))}
              onChange={this.onLedgerItemNumberChange}
              disabled={true}
            />}
            {![LedgerItemType.RECURRING_INVOICE].includes(ledgerItem.type) && <EditorFormTitle
              type='text'
              value={ledgerItem.number}
              onChange={this.onLedgerItemNumberChange}
              disabled={!ledgerItem.id}
            />}

            <div style={{ margin: '10px auto', maxWidth: 150 }}>
              <PowerSelect
                options={ledgerItemTypeOptions}
                value={selectedLedgerItemTypeOption}
                onChange={this.onLedgerItemTypeChange}
                noOptionsMessage={() => t('LedgerItemEditor::No options found')}
                styles={{
                  valueContainer: (base) => {
                    return {
                      ...base,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  },
                  option: (base) => {
                    return {
                      ...base,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }
                  },
                  singleValue: (base) => {
                    return {
                      ...base,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }
                  },
                }}
                components={{
                  DropdownIndicator: null
                }}
                theme={ReactSelectTheme}
              />
            </div>

            <form onSubmit={this.onFormSubmit}>
              <EditorFormSectionHeader>
                <EditorFormSectionTitle>
                  {t('LedgerItemEditor::Details')}
                </EditorFormSectionTitle>
              </EditorFormSectionHeader>

              <div className='form-item'>
                {ledgerItem.type === LedgerItemType.RECURRING_INVOICE && ledgerItem.contact_id && (ledgerItem.email_contact_id === '' || ledgerItem.email_contact_id === null) && <Alert
                  type='warning'
                  text={<Trans t={t}>
                    <p>
                      Select a contact to <b>automatically send the invoice</b>
                    </p>
                  </Trans>
                  }
                />}
              </div>

              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('LedgerItemEditor::Client')}</label>
                    <ResourceCreatablePowerSelect
                      type='contact'
                      value={ledgerItem.contact_id}
                      onChange={this.onLedgerItemClientChange}
                      params={{ archived: false }}
                      placeholder={t('LedgerItemEditor::Select a client...')}
                      isClearable={true}
                    />
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('LedgerItemEditor::Project')}</label>

                    <ResourceCreatablePowerSelect
                      ref={this.projectSelect}
                      type='project'
                      value={ledgerItem.project_id}
                      onChange={this.onLedgerItemProjectChange}
                      isDisabled={!ledgerItem.contact_id}
                      isClearable={true}
                      params={{ 'contact_id': ledgerItem.contact_id, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
                      createParams={{ contact_id: ledgerItem.contact_id, status: ProjectStatus.ACTIVE }}
                      placeholder={t('LedgerItemEditor::Select a project...')}
                    />
                  </div>

                  <div className='form-item'>
                    {metadata && metadata.project_budget > 0 && <Alert
                      type='warning'
                      text={<Trans t={t} >
                        <p>
                          Project budget <b>{NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, metadata.project_budget)}</b>
                        </p>
                      </Trans>}
                    />}
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('LedgerItemEditor::Contact')}

                      <Tooltip
                        content={t('LedgerItemEditor::The default contact to receive the document')}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </label>

                    <ResourceCreatablePowerSelect
                      type='contact'
                      onChange={this.onLedgerItemEmailContactChange}
                      value={ledgerItem.email_contact_id}
                      isClearable={true}
                      isDisabled={!ledgerItem.contact_id}
                      params={{ contact_id: ledgerItem.contact_id, archived: false }}
                      createParams={{ contact_id: ledgerItem.contact_id }}
                      placeholder={t('LedgerItemEditor::Select an email contact...')}
                    />

                    {ledgerItem?.email_contact?.emails?.length === 0 && <div style={{ marginTop: 8 }}>
                      <Alert
                        type='warning'
                        text={<Trans t={t}
                        >
                          <p>
                            Your selected contact has no email configured.
                          </p>
                        </Trans>
                        }
                      />
                    </div>}
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('LedgerItemEditor::Currency')}</label>

                    <PowerSelect
                      options={ledgerItemCurrencyOptions}
                      value={selectedCurrencyOption}
                      onChange={this.onLedgerItemCurrencyChange}
                      noOptionsMessage={() => t('LedgerItemEditor::No currency found')}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                {(ledgerItem.type === LedgerItemType.INVOICE || ledgerItem.type === LedgerItemType.CREDIT_NOTE) && setting.po_number_enabled && <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('LedgerItemEditor::PO number')}
                    </label>

                    <input
                      type='text'
                      name='po_number'
                      value={ledgerItem.po_number}
                      placeholder={t('LedgerItemEditor::PO number')}
                      onChange={this.onLedgerItemPoNumberChange}
                    />
                  </div>
                </div>}
              </div>

              <EditorFormSectionHeader>
                <EditorFormSectionTitle>
                  {t('LedgerItemEditor::Notes')}
                </EditorFormSectionTitle>
              </EditorFormSectionHeader>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <textarea
                    value={ledgerItem.notes}
                    placeholder={t('LedgerItemEditor::Add notes (only visible to you)')}
                    onChange={this.onLedgerItemNotesChange}
                    style={{ minHeight: 110 }}
                  />
                </div>
              </div>

              {customFields.length > 0 && <>
                <EditorFormSectionHeader>
                  <EditorFormSectionTitle>
                    {t('LedgerItemEditor::Custom fields')}
                  </EditorFormSectionTitle>
                </EditorFormSectionHeader>

                <CustomFieldModalInputs
                  customFields={customFields}
                  modelCustomFields={ledgerItem.custom_fields}
                  onCustomFieldValueChange={this.onLedgerItemCustomFieldValueChange}
                  onCustomFieldsChange={(customFields: CustomField[]) => this.setState({ customFields: customFields })}
                />
              </>}

              <EditorFormSectionHeader>
                <EditorFormSectionTitle>
                  {t('LedgerItemEditor::Items')}
                </EditorFormSectionTitle>

                <EditorFormSectionActions>
                  <ButtonDropdown
                    text={t('LedgerItemEditor::Import')}
                    buttonClasses='button button-warning'
                    icon='download'
                    style={{ marginRight: '10px' }}
                  >
                    <ButtonDropdownOption key={'import-time-entry'} onClick={this.onImportTimeEntriesClick}>
                      <Icon icon='stopwatch' />
                      {t('LedgerItemEditor::Import time entries')}
                    </ButtonDropdownOption>
                    <ButtonDropdownOption key={'import-expenses'} onClick={this.onImportExpensesClick}>
                      <span style={{ marginLeft: 1 }}>
                        <Icon icon='receipt' />
                      </span>
                      {t('LedgerItemEditor::Import expenses')}
                    </ButtonDropdownOption>
                    <ButtonDropdownOption key={'import-products'} onClick={this.onImportProductsClick}>
                      <Icon icon='box' />
                      {t('LedgerItemEditor::Import products')}
                    </ButtonDropdownOption>
                  </ButtonDropdown>

                  <Button
                    type='default'
                    icon='pen'
                    text={t('LedgerItemEditor::Edit VAT')}
                    onClick={this.onEditTaxesClick}
                  />
                </EditorFormSectionActions>
              </EditorFormSectionHeader>

              <EditorTable>
                <div className='editor-form-line-items-table'>
                  {metadata && ((Number(metadata.advancement_invoices.remaining_net_amount) > 0 && !Boolean(ledgerItem.id)) || metadata.unbilled_expenses.count > 0 || metadata.unbilled_time_entries.count > 0) && <LedgerItemMetadataContainer>
                    <Alert
                      type='warning'
                      text={<LedgerItemMetadata>
                        {!ledgerItem.id && ledgerItem.type === LedgerItemType.INVOICE && Number(metadata.advancement_invoices.remaining_net_amount) > 0 && <li>
                          {t('LedgerItemEditor::Unbilled quote amount')}
                          <b>{NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, metadata.advancement_invoices.remaining_net_amount)}</b>
                          <a href='javascript://' onClick={this.onBillAdvancementClick}>
                            {t('LedgerItemEditor::Request advance payment')}
                          </a>
                          <a href='javascript://' onClick={this.onBillInterimClick}>
                            {t('LedgerItemEditor::Request interim payment')}
                          </a>
                          <a href='javascript://' onClick={this.onBillRemainingAmountClick}>
                            {t('LedgerItemEditor::Request remaining amount')}
                          </a>
                        </li>}
                        {metadata.unbilled_time_entries.count > 0 && <li>
                          {t('LedgerItemEditor::Unbilled entries')} <b>{metadata.unbilled_time_entries.count}</b>
                          <a href='javascript://' onClick={this.onImportTimeEntriesClick}>{t('LedgerItemEditor::Import')}</a>
                        </li>}
                        {metadata.unbilled_expenses.count > 0 && <li>
                          {t('LedgerItemEditor::Unbilled expenses')} <b>{metadata.unbilled_expenses.count}</b>
                          <a href='javascript://' onClick={this.onImportExpensesClick}>{t('LedgerItemEditor::Import')}</a>
                        </li>}
                      </LedgerItemMetadata>}
                    />
                  </LedgerItemMetadataContainer>}
                  <div className='editor-form-line-items-table-header'>
                    <div className='editor-form-line-items-table-column is-description'>
                      <label>
                        {t('LedgerItemEditor::Description')}
                      </label>
                    </div>
                    <div className='editor-form-line-items-table-column is-quantity'>
                      <label>
                        {t('LedgerItemEditor::Quantity')}
                      </label>
                    </div>
                    <div className='editor-form-line-items-table-column is-rate'>
                      <label>
                        {t('LedgerItemEditor::Price')}
                      </label>
                    </div>
                    <div className='editor-form-line-items-table-column is-tax'>
                      <label>
                        {t('LedgerItemEditor::VAT')}
                      </label>
                    </div>
                    <div className='editor-form-line-items-table-column is-subtotal'>
                      <label>
                        {t('LedgerItemEditor::Total')}
                      </label>
                    </div>
                    <div className='editor-form-line-items-table-column is-action'></div>
                  </div>

                  <DragDropContext
                    onDragEnd={this.onLineItemDragEnd}
                  >
                    <Droppable droppableId='line-items'>
                      {(provided) => {
                        return (
                          <div className='table-body'
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >

                            {ledgerItem.line_items.map((lineItem, lineItemIndex) => {
                              const lineItemDiscount = Number(lineItem.discount)
                              return (
                                <Draggable key={String(lineItemIndex)} draggableId={String(lineItemIndex)} index={lineItemIndex}>
                                  {(provided) => {
                                    return (
                                      <LineItemContainer
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                      >
                                        {typeof lineItem?.title === 'string' && <LineItemTitleContainer>
                                          <LineItemTitle
                                            ref={input => this[`${this.TITLE_LINE_ITEM_REF_KEY}${lineItemIndex}`] = input}
                                            type='text'
                                            placeholder={t('LedgerItemEditor::Title')}
                                            onChange={(e) => this.onLineItemTitleChange(e, lineItemIndex)}
                                            value={lineItem.title}
                                          />
                                        </LineItemTitleContainer>}
                                        <div className='editor-form-line-items-table-row is-form'>
                                          <div className='editor-form-line-items-table-column is-description'>
                                            <label>{t('LedgerItemEditor::Description')}</label>
                                            <Editor
                                              tag='textarea'
                                              ref={input => this[`${this.DESCRIPTION_LINE_ITEM_REF_KEY}${lineItemIndex}`] = input}
                                              model={lineItem.description}
                                              onModelChange={(description) => this.onLineItemDescriptionChange(description, lineItemIndex)}
                                              config={{
                                                ...LEDGER_ITEM_EDITOR_CONFIG,
                                                placeholderText: t('LedgerItemEditor::Description'),
                                              }}
                                            />
                                          </div>
                                          <div className='editor-form-line-items-table-column is-quantity'>
                                            <label>{t('LedgerItemEditor::Quantity')}</label>
                                            <input
                                              ref={input => this[`${this.QUANTITY_LINE_ITEM_REF_KEY}${lineItemIndex}`] = input}
                                              type='number'
                                              min={0}
                                              step={1}
                                              placeholder='-'
                                              onChange={(e) => this.onLineItemQuantityChange(e, lineItemIndex)}
                                              value={lineItem.quantity}
                                              required
                                            />
                                          </div>

                                          <div className='editor-form-line-items-table-column is-rate'>
                                            <label>{t('LedgerItemEditor::Price')}</label>
                                            <MoneyInput
                                              ref={input => this[`${this.RATE_LINE_ITEM_REF_KEY}${lineItemIndex}`] = input}
                                              name='rate'
                                              currency={ledgerItem.currency}
                                              numberFormat={setting.number_format}
                                              placeholderValue={0}
                                              value={lineItem.price}
                                              onBlur={(value) => this.onLineItemRateChange(lineItemIndex, value)}
                                            />

                                            {Number(lineItem.price) > 0 && <LineItemDiscount onClick={() => this.onLineItemDiscountClick(lineItemIndex)}>
                                              <Badge type='grey'>
                                                {lineItemDiscount > 0 && <>
                                                  {t('LedgerItemEditor::{{discount}} discount', { discount: `${lineItemDiscount * 100}%` })}
                                                </>}
                                                {lineItemDiscount === 0 && <>
                                                  <Icon icon='plus' style={{ marginRight: 4 }} />
                                                  {t('LedgerItemEditor::Add discount')}
                                                </>}
                                              </Badge>
                                            </LineItemDiscount>}
                                          </div>

                                          <div className='editor-form-line-items-table-column is-tax'>
                                            <label>{t('LedgerItemEditor::VAT')}</label>
                                            <select
                                              name='vat'
                                              onChange={(e) => this.onLineItemTaxChange(lineItemIndex, e.currentTarget.value)}
                                              value={lineItem.tax_code}
                                            >
                                              <option value="">-</option>
                                              {taxes.map(tax => (<option key={tax.name} value={tax.code}>{tax.name}</option>))}
                                            </select>
                                          </div>

                                          <div className='editor-form-line-items-table-column is-subtotal'>
                                            <label>{t('LedgerItemEditor::Total')}</label>
                                            <input
                                              type='text'
                                              name='total'
                                              style={{ fontWeight: 600 }}
                                              placeholder={NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, LedgerItemHelper.getLineItemSubTotal(lineItem), { forcePrecision: 2 })}
                                              disabled
                                            />
                                          </div>

                                          <div className='editor-form-line-items-table-column is-action'>
                                            <DeleteLineItemAction href='javascript://' className='button-icon button-danger' onClick={(e) => this.onLineItemDeleteClick(e, lineItemIndex)}>
                                              <Icon icon='close' />
                                            </DeleteLineItemAction>
                                          </div>
                                        </div>
                                        <LineItemActions
                                          titleEnabled={typeof lineItem?.title === 'string'}
                                        >
                                          <LineItemActionsWrapper>
                                            <LineItemGrip
                                              {...provided.dragHandleProps}
                                              data-tip={t('LedgerItemEditor::Move item')}
                                            >
                                              <Icon icon='grip-vertical' />
                                            </LineItemGrip>

                                            <LineItemToggleTitle
                                              data-tip={typeof lineItem?.title === 'string' ? t('LedgerItemEditor::Remove heading') : t('LedgerItemEditor::Add heading')}
                                              onClick={(e) => this.onLineItemTitleToggle(e, lineItemIndex)}
                                            >
                                              <Icon icon='heading' />
                                            </LineItemToggleTitle>
                                          </LineItemActionsWrapper>
                                        </LineItemActions>
                                      </LineItemContainer>
                                    )
                                  }}

                                </Draggable>
                              )
                            })}
                            {provided.placeholder}

                            <div key={'new-item'} className='editor-form-line-items-table-row is-form'>
                              <div className='editor-form-line-items-table-column is-description'>
                                <label>{t('LedgerItemEditor::Description')}</label>
                                <AutogrowTextarea
                                  minRows={1}
                                  maxRows={5}
                                  name='description'
                                  onChange={e => this.onNewLineItemPropertyChange(e, 'description')}
                                  value={''}
                                  placeholder={t('LedgerItemEditor::Description')}
                                />

                              </div>
                              <div className='editor-form-line-items-table-column is-quantity'>
                                <label>{t('LedgerItemEditor::Quantity')}</label>
                                <input type='text' placeholder='-' value='' onChange={e => this.onNewLineItemPropertyChange(e, 'quantity')} />
                              </div>

                              <div className='editor-form-line-items-table-column is-rate'>
                                <label>{t('LedgerItemEditor::Price')}</label>
                                <input
                                  type='text'
                                  name='rate'
                                  value=''
                                  placeholder={NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, 0)}
                                  onChange={e => this.onNewLineItemPropertyChange(e, 'rate')} />
                              </div>

                              <div className='editor-form-line-items-table-column is-tax'>
                                <label>{t('LedgerItemEditor::VAT')}</label>
                                <select
                                  name='vat'
                                  onChange={e => this.onNewLineItemPropertyChange(e, 'tax_rate')}
                                  value={standardTax ? standardTax.code : ''}
                                  placeholder={`${standardTax ? `${(Number(standardTax.rate) * 100)}%` : '-'}`}
                                >
                                  <option value="">-</option>
                                  {taxes.map(tax => (<option key={tax.name} value={tax.code}>{tax.name}</option>))}
                                </select>
                              </div>

                              <div className='editor-form-line-items-table-column is-subtotal'>
                                <label>{t('LedgerItemEditor::Total')}</label>
                                <input type='text' name='subtotal' placeholder={NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, 0)} disabled />
                              </div>

                              <div className='editor-form-line-items-table-column is-action'>
                                <DeleteLineItemAction href='javascript://' className='button-icon button-danger'>
                                  <Icon icon='close' />
                                </DeleteLineItemAction>
                              </div>
                            </div>
                          </div>
                        )

                      }}
                    </Droppable>
                  </DragDropContext>

                  <div className='editor-form-line-items-table-footer is-right'>
                    {ledgerItemSubtotal !== 0 && <div className='editor-form-line-items-table-footer-column'>
                      <label className='editor-form-line-items-table-footer-header'>
                        {t('LedgerItemEditor::Discount')}
                      </label>

                      <div className='editor-form-line-items-table-footer-value align-right'>
                        <PercentInput
                          name='discount'
                          defaultValue={ledgerItem.discount}
                          placeholder={'0 %'}
                          onChange={this.onLedgerItemDiscountChange}
                          onBlur={this.onLedgerItemDiscountChange}
                          style={{ maxWidth: `80px` }}
                        />
                      </div>
                    </div>}

                    {ledgerItemSubtotal !== 0 && <div className='editor-form-line-items-table-footer-column'>
                      <label className='editor-form-line-items-table-footer-header'>
                        {t('LedgerItemEditor::Subtotal')}
                      </label>

                      <div className='editor-form-line-items-table-footer-value'>
                        <strong>
                          {NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, ledgerItemSubtotal, { forcePrecision: 2 })}
                        </strong>
                      </div>
                    </div>}

                    {ledgerItemDiscountTotal !== 0 && <div className='editor-form-line-items-table-footer-column'>
                      <label className='editor-form-line-items-table-footer-header'>
                        {t('LedgerItemEditor::Discount total')}
                      </label>

                      <div className='editor-form-line-items-table-footer-value'>
                        <strong>{NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, -ledgerItemDiscountTotal, { forcePrecision: 2 })}</strong>
                      </div>
                    </div>}

                    {ledgerItemDiscountTotal !== 0 && ledgerItemNettotal !== 0 && <div className='editor-form-line-items-table-footer-column'>
                      <label className='editor-form-line-items-table-footer-header'>
                        {t('LedgerItemEditor::Net total')}
                      </label>

                      <div className='editor-form-line-items-table-footer-value'>
                        <strong>
                          {NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, ledgerItemNettotal, { forcePrecision: 2 })}
                        </strong>
                      </div>
                    </div>}

                    {ledgerItemTaxTotalsList.length > 0 && ledgerItemTaxTotalsList.map(({ rate, total }, index) => {
                      return (
                        <div key={index} className='editor-form-line-items-table-footer-column'>
                          <label className='editor-form-line-items-table-footer-header'>
                            {`${t('LedgerItemEditor::VAT')} (${rate * 100}%)`}
                          </label>

                          <div className='editor-form-line-items-table-footer-value'>
                            <strong>{NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, total, { forcePrecision: 2 })}</strong>
                          </div>
                        </div>
                      )
                    })}

                    <div className='editor-form-line-items-table-footer-column'>
                      <label className='editor-form-line-items-table-footer-header'>
                        {t('LedgerItemEditor::Total')}
                      </label>

                      <div className='editor-form-line-items-table-footer-value'>
                        <strong>{NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, ledgerItemTotal, { forcePrecision: 2 })}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </EditorTable>

              {ledgerItem?.vat_rules?.length > 0 && <>
                <EditorFormSectionHeader>
                  <EditorFormSectionTitle style={{ fontSize: 17 }}>
                    {t('LedgerItemEditor::VAT rules')}
                    <Tooltip
                      content={t('LedgerItemEditor::We\'ve detected that you\'ve used VAT rates that need a description on this document.')}
                      containerStyle={{ marginLeft: 8 }}
                    />
                  </EditorFormSectionTitle>
                </EditorFormSectionHeader>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <textarea
                      value={ledgerItem.vat_rules}
                      onChange={this.onLedgerItemVatRulesChange}
                    />
                  </div>
                </div>
              </>}

              <EditorFormSectionHeader>
                <EditorFormSectionTitle>
                  {t('LedgerItemEditor::Planning')}
                </EditorFormSectionTitle>
              </EditorFormSectionHeader>

              {ledgerItem.type === LedgerItemType.ORDER_FORM && <>
                <div className='grid'>
                  <div className='grid-cell with-4col'>
                    {this.renderIssuedOn()}
                    {this.renderCancelledOn()}
                  </div>

                  <div className='grid-cell with-4col'>
                    {this.renderEstimatedNetOn()}
                  </div>

                  <div className='grid-cell with-4col'>
                    {this.renderDeliveredOn()}
                  </div>
                </div>
              </>}

              {ledgerItem.type === LedgerItemType.DELIVERY_NOTE && <>
                <div className='grid'>
                  <div className='grid-cell with-4col'>
                    {this.renderIssuedOn()}
                  </div>

                  <div className='grid-cell with-4col'>
                    {this.renderInvoicedOn()}
                  </div>
                </div>
              </>}

              {ledgerItem.type === LedgerItemType.QUOTATION && <>
                <div className='grid'>
                  <div className='grid-cell with-4col'>
                    {this.renderIssuedOn()}
                    {this.renderReference()}
                  </div>

                  <div className='grid-cell with-4col'>
                    {this.renderEstimatedNetOn()}
                  </div>

                  <div className='grid-cell with-4col'>
                    {this.renderApprovedOn()}
                    {this.renderRejectedOn()}
                  </div>
                </div>
              </>}

              {[LedgerItemType.PRO_FORMA_INVOICE].includes(ledgerItem.type) && <>
                <div className='grid'>
                  <div className='grid-cell with-4col'>
                    {this.renderIssuedOn()}
                  </div>
                  <div className='grid-cell with-4col'>
                    {this.renderEstimatedNetOn()}
                  </div>
                </div>
              </>}

              {[LedgerItemType.INVOICE, LedgerItemType.CREDIT_NOTE].includes(ledgerItem.type) && <>
                <div className='grid'>
                  <div className='grid-cell with-4col'>
                    {this.renderIssuedOn()}
                    {this.renderReference()}
                  </div>

                  <div className='grid-cell with-4col'>
                    {this.renderEstimatedNetOn()}
                    {this.renderBookedOn()}
                  </div>

                  <div className='grid-cell with-4col'>
                    {this.renderPaidOn()}
                    {ledgerItem.type === LedgerItemType.INVOICE && this.renderCreditedOn()}
                  </div>
                </div>
              </>}

              {ledgerItem.type === LedgerItemType.RECURRING_INVOICE && <>
                <div className='grid'>
                  <div className='grid-cell with-4col'>
                    {this.renderEstimatedNetOn()}
                  </div>
                </div>
                <ScheduleContainer>
                  <ScheduleRow>
                    <ScheduleRowLabel>
                      {t('LedgerItemEditor::Repeat this invoice')}
                    </ScheduleRowLabel>

                    <ScheduleRowContent>
                      <ScheduleSelectContainer style={{ zIndex: 26 }}>
                        <PowerSelect
                          options={scheduleUnitOptions}
                          value={selectedScheduleUnitOption}
                          onChange={this.onScheduleUnitChange}
                          theme={ReactSelectTheme}
                        />
                      </ScheduleSelectContainer>

                      {this.renderScheduleUnitOptions()}
                    </ScheduleRowContent>
                  </ScheduleRow>

                  <ScheduleRow>
                    <ScheduleRowLabel>
                      {t('LedgerItemEditor::Create first invoice on')}
                    </ScheduleRowLabel>

                    <ScheduleRowContent>
                      <ScheduleSelectContainer>
                        <DateInput
                          ref={this.scheduleStartDateInput}
                          name='start_date'
                          dateFormat={setting.date_format}
                          timeFormat={false}
                          initialValue={moment(ledgerItem.recurring_schedule.start_date)}
                          inputProps={{ placeholder: setting.date_format }}
                          onChange={this.onScheduleStartDateChange}
                          closeOnSelect
                        />
                      </ScheduleSelectContainer>

                      <ScheduleRowMiddleLabel>
                        {t('LedgerItemEditor::and finish')}
                      </ScheduleRowMiddleLabel>

                      {this.renderScheduleEndOn()}
                    </ScheduleRowContent>
                  </ScheduleRow>

                  <ScheduleRow>
                    <ScheduleRowLabel>
                      {t('LedgerItemEditor::Timezone')}
                    </ScheduleRowLabel>

                    <ScheduleRowContent>
                      <ScheduleSelectContainer isTimezone={true} style={{ zIndex: 25 }}>
                        <PowerSelect
                          options={timezoneOptions}
                          value={selectedTimezoneOption}
                          onChange={this.onScheduleTimezoneChange}
                          theme={ReactSelectTheme}
                        />
                      </ScheduleSelectContainer>
                    </ScheduleRowContent>
                  </ScheduleRow>
                </ScheduleContainer>
              </>}

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('LedgerItemEditor::Footnote')}</label>
                    <EditorContainer>
                      <Editor
                        model={ledgerItem.details}
                        onModelChange={this.onLedgerItemDetailsChange}
                        config={{
                          ...MINIMAL_EDITOR_CONFIG,
                          heightMin: 120,
                          heightMax: 120,
                          variableOptions: EditorHelper.getLedgerItemVariableOptions(ledgerItemVariables),
                        }}
                      />
                    </EditorContainer>
                  </div>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />

              <div className='editor-form-actions'>
                <Button
                  type='default'
                  text={t('LedgerItemEditor::Preview')}
                  onClick={this.onToPreviewPress}
                />
                <div className='popover-wrapper'>
                  <EditorFormMainActions>
                    <div>
                      <Button
                        type='success'
                        text={t('LedgerItemEditor::Save')}
                        isLoading={(isSaving) || (didInitialPreviewLoad && isPreviewReloading)}
                        onClick={this.onFormSubmit}
                      />
                    </div>

                    <div>
                      <Button
                        type='success'
                        text={t('LedgerItemEditor::Save & close')}
                        isLoading={(isSaving) || (didInitialPreviewLoad && isPreviewReloading)}
                        onClick={(e) => this.onFormSubmit(e, true)}
                      />
                    </div>
                    <TooltipError
                      errors={this.state.errors}
                      onDismiss={() => this.setState({ errors: {} })}
                    />
                  </EditorFormMainActions>
                </div>
              </div>
            </form>
          </EditorFormWrapper>
        </EditorForm>

        <EditorPreviewContainer>
          <EditorPreviewClose onClick={this.onBackPress}>
            <Icon icon='close' />
          </EditorPreviewClose>

          <EditorPreviewWrapper>
            <DocumentWrapper ref={this.editorDocumentWrapper}>
              {({ width, height }) => {
                return (
                  <div className='editor-preview-canvas-container'>
                    <div className='editor-preview-canvas'>
                      <div className='editor-preview-canvas-wrapper'>
                        <EditorPreviewExpand onClick={this.onToPreviewPress}>
                          <EditorPreviewExpandButton>
                            <Icon icon='expand' />
                          </EditorPreviewExpandButton>
                        </EditorPreviewExpand>

                        <EditorPreviewHeader>
                          <EditorPreviewSaveState>
                            <EditorPreviewSaveStateIcon>
                              {((!didInitialPreviewLoad) || (!isSaving && !isPreviewReloading)) && <Icon icon='cloud-saved' />}
                              {(didInitialPreviewLoad && (isSaving || isPreviewReloading)) && <Loader size='small' color='white' />}
                            </EditorPreviewSaveStateIcon>

                            {didInitialPreviewLoad && (isSaving || isPreviewReloading) ? t('LedgerItemEditor::Saving...') : t('LedgerItemEditor::Saved')}
                          </EditorPreviewSaveState>

                          <LedgerItemPreviewNavigation
                            currentPage={currentPage}
                            totalPageCount={pageCount}
                            onNavigateBack={this.onDocumentNavigateBack}
                            onNavigateForward={this.onDocumentNavigateForward}
                          />
                        </EditorPreviewHeader>

                        <DocumentPreview
                          key={ledgerItem?.pdf_attachment_url || 'ledger-item-preview'}
                          file={ledgerItem.pdf_attachment_url}
                          currentPage={currentPage}
                          width={width}
                          height={height}
                          onLoadSuccess={this.onDocumentLoadSuccess}
                        />

                        <EditorPreviewFooter>
                          <EditorPreviewFooterTemplateSelector onClick={this.onToPreviewPress}>
                            <EditorPreviewFooterTemplateSelectorIcon>
                              <i className='fas fa-th-large'></i>
                            </EditorPreviewFooterTemplateSelectorIcon>

                            <EditorPreviewFooterTemplateSelectorText>
                              {t('LedgerItemEditor::Select template')}
                            </EditorPreviewFooterTemplateSelectorText>
                          </EditorPreviewFooterTemplateSelector>

                          {ledgerItem?.pdf_attachment_download_url && <EditorPreviewFooterAction onClick={this.onDownloadClick}>
                            {t('LedgerItemEditor::Download PDF')}
                          </EditorPreviewFooterAction>}
                        </EditorPreviewFooter>
                      </div>
                    </div>
                  </div>
                )
              }}
            </DocumentWrapper>
          </EditorPreviewWrapper>
        </EditorPreviewContainer>
      </Container>
    )
  }

  renderPreview() {
    const {
      currentPage,
      pageCount,
      ledgerItem,
      ledgerItemTemplates,
      mobileTemplatePickerActive,
    } = this.state

    const {
      match: {
        params: {
          id
        }
      },
      currentUser: {
        workspace: {
          setting,
        }
      },
      t
    } = this.props

    const initialSlide = ledgerItemTemplates.findIndex(template => template.id === setting.ledger_item_template_id)

    return (
      <TemplateContainer>
        <TemplateContainerNavigation>
          <TemplateNavigationBack href='javascript://' onClick={this.onToEditorPress}>
            <Icon icon='chevron-left' />
            {t('LedgerItemEditor::Back to editor')}
          </TemplateNavigationBack>

          <TemplateNavigationMenuMobile href='javascript://' onClick={this.onToggleMobileTemplatePicker}>
            <i className="fas fa-sliders-h"></i>
          </TemplateNavigationMenuMobile>

          <TemplateContainerNavigationActions>
            {ledgerItem?.pdf_attachment_download_url && <TemplateContainerNavigationAction href='javascript://' onClick={this.onDownloadClick}>
              {t('LedgerItemEditor::Download PDF')}
            </TemplateContainerNavigationAction>}
          </TemplateContainerNavigationActions>

          <div className='template-navigation-back-mobile' onClick={this.onToEditorPress}>
            <Icon icon='close' />
          </div>
        </TemplateContainerNavigation>

        <div className='template-container-wrapper'>
          <div className='template-picker-container'>
            <div className='template-picker'>
              <div className='template-picker-list'>
                {ledgerItemTemplates.filter(template => template.enabled).map(template => {
                  const isSelected = template.id === setting.ledger_item_template_id

                  return (
                    <div
                      key={template.id}
                      className={`template-picker-list-item ${isSelected ? 'is-selected' : ''}`}
                      style={{ backgroundImage: `url('${template.cover_url}')` }}
                      onClick={() => this.onTemplateClick(template.id)}
                    >
                      <div><Icon icon='check' /></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className='template-picker-preview-container'>
            <DocumentWrapper ref={this.previewDocumentWrapper}>
              {({ width, height }) => {
                return (
                  <DocumentPreview
                    key={ledgerItem?.pdf_attachment_url || 'ledger-item-preview'}
                    file={ledgerItem.pdf_attachment_url}
                    width={width}
                    currentPage={currentPage}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  />
                )
              }}
            </DocumentWrapper>

            <div className='template-picker-page-selector'>
              <LedgerItemPreviewNavigation
                currentPage={currentPage}
                totalPageCount={pageCount}
                onNavigateBack={this.onDocumentNavigateBack}
                onNavigateForward={this.onDocumentNavigateForward}
              />
            </div>
          </div>

          <div className={`template-picker-mobile ${mobileTemplatePickerActive ? 'is-active' : ''}`}>
            <div className='template-picker-mobile-title'>{t('LedgerItemEditor::TEMPLATE')}</div>
            <Slider
              className='template-picker-mobile-list'
              slidesToScroll={1}
              slidesToShow={1}
              autoplaySpeed={500}
              centerMode={true}
              variableWidth={true}
              arrows={false}
              infinite={false}
              initialSlide={initialSlide}
              focusOnSelect={true}
              swipeToSlide={true}
              swipe={true}
              afterChange={this.onMobileTemplatePick}
            >
              {ledgerItemTemplates.filter(template => template.enabled).map(template => {
                const isSelected = template.id === setting.ledger_item_template_id
                return (
                  <label className={`template-picker-mobile-list-item ${isSelected ? 'is-active' : ''}`}>
                    {template.name}
                  </label>
                )
              })}
            </Slider>
          </div>
        </div>
      </TemplateContainer>
    )
  }

  onActionCableConnected() {
    console.log('[WorkspaceChannel] connected')
  }

  onActionCableDisconnected() {
    console.log('[WorkspaceChannel] Disconnected')
  }

  onActionCableReceived(event: WorkspaceChannelEvent) {
    const { t } = this.props
    console.log('[WorkspaceChannel] received event', event)

    switch (event.type) {
      case WorkspaceChannelEventType.LEDGER_ITEM_ATTACHMENTS_READY:
        const {
          id,
          pdf_attachment_content_type,
          pdf_attachment_download_url,
          pdf_attachment_file_name,
          pdf_attachment_file_size,
          pdf_attachment_url,
        } = event.data.ledger_item

        if (id === this.state?.ledgerItem?.id) {
          this.setState({
            ledgerItem: {
              ...this.state.ledgerItem,
              pdf_attachment_content_type,
              pdf_attachment_download_url,
              pdf_attachment_file_name,
              pdf_attachment_file_size,
              pdf_attachment_url,
            }
          })
        }
        break
    }
  }

  render() {
    const { currentUser } = this.props
    const {
      mode,
      didInitialLoad,
    } = this.state

    if (!didInitialLoad) {
      return (
        <LoadingContainer>
          <PageLoader />
        </LoadingContainer>
      )
    }

    return (
      <ActionCableConsumer
        channel={{ channel: 'WorkspaceChannel', id: currentUser.workspace.id }}
        onConnected={this.onActionCableConnected}
        onDisconnected={this.onActionCableDisconnected}
        onReceived={this.onActionCableReceived}
      >
        {mode === 'editor' ? this.renderEditor() : this.renderPreview()}
      </ActionCableConsumer>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showImportProductsModal: (onSubmit?: (importedProducts: ImportedProduct[]) => void) => dispatch(showImportProductsModal(onSubmit)),
    showImportExpensesModal: (contactId?: string, projectId?: string, onSubmit?: (expenses: Expense[]) => void) => dispatch(showImportExpensesModal(contactId, projectId, onSubmit)),
    showImportTimeEntriesModal: (options) => dispatch(showImportTimeEntriesModal(options)),
    showTaxesModal: (onSubmit?: (taxes: WorkspaceTax[]) => void) => dispatch(showTaxesModal(onSubmit)),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showProjectStatusUpdateModal: (options) => dispatch(showProjectStatusUpdateModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showCalculateAmountModal: (options) => dispatch(showCalculateAmountModal(options)),
    showDiscountModal: (options) => dispatch(showDiscountModal(options)),
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LedgerItemEditor))
