import * as React from 'react'
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from 'react-router-dom'
import ScrollToTopOnMount from "../components/Effects/ScrollToTopOnMount"
import TopNavigation from "../components/Navigation/TopNavigation"
import PageContent from '../components/Page/PageContent'
import Panel from '../components/Panel/Panel'
import DateInput from '../components/Form/DateInput'
import PowerSelect from '../components/Form/PowerSelect'
import { ExportTimePeriod, Settings } from '../types'
import { useSelector } from 'react-redux'
import { AppState } from '../store'
import moment from '../utilities/Moment'
import { Moment } from 'moment'
import Button from '../components/Button/Button'
import { DocumentsController } from '../controllers'
import ExportHelper from '../helpers/ExportHelper'
import ReactSelectTheme from '../components/Form/ReactSelectTheme'
import Notification from '../utilities/Notification'

type IProps = & RouteComponentProps<any>

interface IState {
  exportPeriodType: ExportTimePeriod
  start: string | Moment
  end: string | Moment
  email: string
  notes: string
}

const Accountant = (props: IProps) => {
  const settings: Settings = useSelector<AppState>(state => (state.authentication.currentUser.workspace.setting as Settings)) as Settings
  const { t } = useTranslation()
  const { history } = props
  const [state, setState] = React.useState<IState>({
    exportPeriodType: ExportTimePeriod.CURRENT_QUARTER,
    start: ExportHelper.getPeriodRange(ExportTimePeriod.CURRENT_QUARTER).start,
    end: ExportHelper.getPeriodRange(ExportTimePeriod.CURRENT_QUARTER).end,
    email: '',
    notes: ''
  })
  const { exportPeriodType, start, end, email, notes } = state
  const exportPeriodOptions = ExportHelper.getPeriodOptions()
  const selectedExportPeriodType = exportPeriodOptions.find(option => option.value === exportPeriodType)

  const onExportPeriodTypeSelect = (option) => {
    const exportPeriodType = option.value

    const { start, end } = ExportHelper.getPeriodRange(exportPeriodType)

    setState({
      ...state,
      exportPeriodType: option.value,
      start: start,
      end: end
    })
  }


  const onStartDateChange = (value) => {
    const newDateMoment = moment(value)
    setState({
      ...state,
      start: newDateMoment.isValid() ? newDateMoment.format('YYYY-MM-DD') : null
    })
  }

  const onEndDateChange = (value) => {
    const newDateMoment = moment(value)
    setState({
      ...state,
      end: newDateMoment.isValid() ? newDateMoment.format('YYYY-MM-DD') : null
    })
  }

  const onEmailChange = (e) => {
    setState({ ...state, email: e.currentTarget.value })
  }

  const onNotesChange = (e) => {
    setState({ ...state, notes: e.currentTarget.value })
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    try {
      await DocumentsController.sendFinancialDocuments({
        start: moment(start).toISOString(),
        end: moment(end).toISOString(),
        email: email,
        notes: notes
      })

      setState({
        exportPeriodType: ExportTimePeriod.CURRENT_QUARTER,
        start: ExportHelper.getPeriodRange(ExportTimePeriod.CURRENT_QUARTER).start,
        end: ExportHelper.getPeriodRange(ExportTimePeriod.CURRENT_QUARTER).end,
        email: '',
        notes: ''
      })

      Notification.notifySuccess(t('Accountant::Financial documents have been sent to your accountant'))
    } catch (ex) {
      console.error(ex)
    }
  }

  return (
    <>
      <ScrollToTopOnMount />

      <TopNavigation
        icon='accountant'
        title={t('Accountant::Accountant')}
      />

      <PageContent>
        <div className='grid'>

          <div className='grid-cell with-6col'>
            <Panel
              title={t('Accountant::Send documents to your accountant for processing.')}
            >
              <form onSubmit={onFormSubmit} autoComplete='off'>
                <div className='form-item'>
                  <label>
                    {t('Accountant::Period')}
                  </label>

                  <PowerSelect
                    options={exportPeriodOptions}
                    value={selectedExportPeriodType}
                    onChange={onExportPeriodTypeSelect}
                    noOptionsMessage={() => t('Accountant::No option found')}
                    isClearable={false}
                    theme={ReactSelectTheme}
                  />
                </div>
                {exportPeriodType === ExportTimePeriod.CUSTOM && <div className='grid'>
                  <div className='grid-cell with-6col'>
                    <div className='form-item'>
                      <label>
                        {t('Accountant::Start date')}
                      </label>

                      <DateInput
                        name='start_date'
                        dateFormat={settings.date_format}
                        timeFormat={false}
                        value={moment(start)}
                        inputProps={{ placeholder: settings.date_format }}
                        onChange={onStartDateChange}
                        closeOnSelect
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-6col'>
                    <div className='form-item'>
                      <label>
                        {t('Accountant::End date')}
                      </label>
                      <DateInput
                        name='end_date'
                        dateFormat={settings.date_format}
                        timeFormat={false}
                        value={moment(end)}
                        inputProps={{ placeholder: settings.date_format }}
                        onChange={onEndDateChange}
                        closeOnSelect
                      />
                    </div>
                  </div>
                </div>}
                <div className='form-item'>
                  <label>{t('Accountant::E-mail address accountant')}</label>
                  <input
                    type='email'
                    onChange={onEmailChange}
                    value={email}
                    placeholder={t('Accountant::Enter the e-mail address of your accountant')}
                  />
                </div>
                <div className='form-item'>
                  <label>{t('Accountant::Notes')}</label>
                  <textarea
                    placeholder={t('Accountant::Add some additional information for your accountant (optional)')}
                    onChange={onNotesChange}
                    value={notes}
                  />
                </div>

                <div className='form-item'>
                  <Button
                    type='success'
                    text={t('Accountant::Send')}
                    onClick={onFormSubmit}
                    disabled={Boolean(start && end && email?.length === 0)}
                  />
                  <input type='submit' style={{ display: 'none' }} />
                </div>
              </form>
            </Panel>
          </div>
        </div>

      </PageContent>
    </>
  )
}

export default Accountant