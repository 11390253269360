import styled from 'styled-components'
import sidebarNavigationItemStyles from './sidebarNavigationItemStyles'

const SidebarNavigationSubItemLink = styled.a`
	${sidebarNavigationItemStyles}
	padding-left: 56px;
	padding-right: 10px;
	font-size: 14px;
	font-weight: 500;

	&.is-active {
		color: white;
		font-weight: 600;
	}
`

export default SidebarNavigationSubItemLink