import styled from 'styled-components'
import TableCell from './TableCell'

const TableRow = styled.div`
	display: table-row;
	border-radius: inherit;
	border-color: inherit;
	background-color: white;
	page-break-inside: avoid;

	&:hover {
		background: #f6f6f7;

		${TableCell} {
			background: #f6f6f7;
		}
	}

	${TableCell}:first-child {
		position: sticky;
		left: 0;
	}

	&:last-child {
		${TableCell} {
			border-bottom: 0;
		}
	}

`

export default TableRow