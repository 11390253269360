import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings } from '../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../store'
import TooltipError from '../../components/Tooltips/ErrorTooltip'
import Button from '../../components/Button/Button'
import Notification from '../../utilities/Notification'
import { DEFAULT_LOCALE, QUOTATION_VALIDITY_DURATIONS } from '../../Constants'
import Panel from '../../components/Panel/Panel';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import Alert from '../../components/Alert/Alert'
import LedgerItemHelper from '../../helpers/LedgerItemHelper';
import ListLoader from '../../components/Loaders/ListLoader';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import EditorContainer from '../../components/Editor/EditorContainer';
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../components/Editor/Editor';
import LocaleHelper from '../../helpers/LocaleHelper';
import PowerSelect from '../../components/Form/PowerSelect';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import MobilityHelper from '../../helpers/MobilityHelper';
import { CurrentUser, DisplayableError, Locale, Settings } from '../../types';
import Title from '../../components/Settings/Title';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  settings: Settings
  activeTranslationsLocale: Locale
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class Quotations extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace: { setting } } } = props

    this.state = {
      settings: setting,
      errors: [],
      activeTranslationsLocale: DEFAULT_LOCALE,
      form: null
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onDefaultLedgerItemLocaleChange = this.onDefaultLedgerItemLocaleChange.bind(this)
    this.onTranslationsLocaleChange = this.onTranslationsLocaleChange.bind(this)
    this.onQuotationPrefixNumberChange = this.onQuotationPrefixNumberChange.bind(this)
    this.onQuotationNumberChange = this.onQuotationNumberChange.bind(this)
    this.onQuotationSuffixNumberChange = this.onQuotationSuffixNumberChange.bind(this)
    this.onQuotationValidationDurationChange = this.onQuotationValidationDurationChange.bind(this)
    this.onQuotationDetailsChange = this.onQuotationDetailsChange.bind(this)
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const { settings } = this.state
    const { updateSettings, t } = this.props

    SettingsController
      .update(settings)
      .then(response => {
        if (response.errors) {
          Notification.notifyError(t('SettingsQuotations::Oops something went wrong'));
        } else {
          // Cast to settings
          const settings = response as Settings
          // Update local
          this.setState({ settings: settings })
          // Update global
          updateSettings(settings)

          Notification.notifySuccess(t('SettingsQuotations::Settings successfully updated'));
        }
      })
      .catch(console.error)
  }

  onDefaultLedgerItemLocaleChange(e) {
    const newLocale = e.currentTarget.value

    const { settings } = this.state

    this.setState({
      settings: {
        ...settings,
        default_ledger_item_locale: newLocale
      }
    })
  }

  onQuotationPrefixNumberChange(e) {
    const prefixQuotationNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        prefix_quotation_number: prefixQuotationNumber
      },
    });
  }

  onQuotationNumberChange(e) {
    const quotationNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        quotation_number: quotationNumber,
      },
    });
  }

  onQuotationSuffixNumberChange(e) {
    const suffixQuotationNumber = e.currentTarget.value;

    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        suffix_quotation_number: suffixQuotationNumber,
      },
    });
  }

  onQuotationValidationDurationChange(e) {
    const validityDuration = e.currentTarget.value;

    const { settings } = this.state;

    settings.quotation_validity_duration = validityDuration;

    this.setState({
      settings: settings
    });
  }

  onTranslationsLocaleChange(option) {
    this.setState({ activeTranslationsLocale: option.value });
  }

  onQuotationDetailsChange(details: string) {
    const { settings, activeTranslationsLocale } = this.state;

    const updatedTranslations = MobilityHelper.updateTranslation(activeTranslationsLocale, settings.translations, 'default_quotation_details', details)

    this.setState({
      settings: {
        ...settings,
        translations: { ...updatedTranslations }
      }
    });
  }


  render() {
    const { t } = this.props
    const { settings, errors, form, activeTranslationsLocale } = this.state

    if (form) {
      const localeOptions = LocaleHelper.getLocaleOptions()
      const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

      return (
        <>
          <Helmet>
            <title>{t('SettingsQuotations::{{__appName}} | Quotations')} </title>
          </Helmet>
          <ScrollToTopOnMount />
          <form onSubmit={this.onFormSubmit}>
            <Title>{t('SettingsQuotations::Quotations')}</Title>

            <Panel>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsQuotations::Default quotation language')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.default_ledger_item_locale} onChange={this.onDefaultLedgerItemLocaleChange}>
                        {localeOptions.map(locale => {
                          return (
                            <option key={locale.value} value={locale.value}>{locale.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsQuotations::Quotation number format')}</label>
                    <div style={{ marginBottom: 8 }}>
                      <Alert
                        type='info'
                        text={
                          <Trans t={t}>
                            <div>The number of your next quotation is "<b>{{ prefix_quotation_number: settings.prefix_quotation_number }}{{ next_quotation_number: LedgerItemHelper.getSuccessiveLedgerNumber(settings.quotation_number) }}{{ suffixed_quotation_number: settings.suffix_quotation_number }}</b>"</div>
                          </Trans>
                        }
                      />
                    </div>

                    <div className='settings-numbering-format'>
                      <input
                        type='text'
                        name='prefix'
                        placeholder={t('SettingsQuotations::Prefix')}
                        value={settings.prefix_quotation_number}
                        onChange={this.onQuotationPrefixNumberChange}
                      />
                      <input
                        type='number'
                        name='number'
                        placeholder='1'
                        value={settings.quotation_number}
                        onChange={this.onQuotationNumberChange}
                        pattern='\d*'
                        min={0}
                        required
                      />
                      <input
                        type='text'
                        name='suffix'
                        placeholder={t('SettingsQuotations::Suffix')}
                        value={settings.suffix_quotation_number}
                        onChange={this.onQuotationSuffixNumberChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsQuotations::Default expiry date (in days)')}</label>
                    <div className='select-wrapper'>
                      <select value={settings.quotation_validity_duration} onChange={this.onQuotationValidationDurationChange}>
                        {QUOTATION_VALIDITY_DURATIONS.map(duration => {
                          return (
                            <option key={duration} value={duration}>{t(`QuotationValidityDurations::${duration}`)}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('SettingsQuotations::Default quotation footnote')}</label>
                    <PowerSelect
                      options={localeOptions}
                      value={selectedLocaleOption}
                      onChange={this.onTranslationsLocaleChange}
                      noOptionsMessage={(value) => t('SettingsQuotations::No language found.')}
                      theme={ReactSelectTheme}
                    />
                    <EditorContainer style={{ marginTop: 8 }}>
                      <Editor
                        model={MobilityHelper.getTranslation(activeTranslationsLocale, settings.translations, 'default_quotation_details')}
                        onModelChange={this.onQuotationDetailsChange}
                        config={{
                          ...MINIMAL_EDITOR_CONFIG,
                          heightMin: 120,
                          heightMax: 120
                        }}
                      />
                    </EditorContainer>
                  </div>
                </div>
              </div>
              <div className='field-actions'>
                <input type='submit' style={{ display: 'none' }} />
                <div className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={() => this.setState({ errors: [] })}
                  />
                  <Button type='success' text={t('SettingsQuotations::Save')} onClick={this.onFormSubmit} />
                </div>
              </div>
            </Panel>
          </form>
        </>
      )
    }
    else {
      return (
        <Panel>
          <ListLoader />
        </Panel>
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Quotations))