import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import FixedSidebarContainer from '../Sidebar/FixedSidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import SidebarSection from '../Sidebar/SidebarSection'
import SidebarSectionItem from '../Sidebar/SidebarSectionItem'
import SidebarSectionItemCheck from '../Sidebar/SidebarSectionItemCheck'
import { CalendarViewEventType } from '../../types'

const Caption = styled.div`
	font-size: 14px;
`

export interface ICalendarFilter {
	searchValue: string
	eventTypes: CalendarViewEventType[]
}

interface IProps {
	active: boolean
	filter: ICalendarFilter
	onFilterChange: (filter: ICalendarFilter) => void
	onCloseClick: () => void
}

const CalendarSidebarFilter = (props: IProps) => {
	const { active, filter } = props
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>()
	const input = React.useRef<HTMLInputElement>()
	const { searchValue, eventTypes } = filter;

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideClick);

		// Temporarily disable because on mobile it opens keyboard which annoying
		if (active && input.current) input.current.select()

		return () => {
			document.removeEventListener('mousedown', onOutsideClick);
		}
	}, [active])

	const onOutsideClick = (e) => {
		if (active && container && !container.current.contains(e.target)) {
			props.onCloseClick()
		}
	}

	const onCloseClick = () => {
		props.onCloseClick()
	}

	const onSearchChange = (e) => {
		props.onFilterChange({ ...filter, searchValue: e.currentTarget.value })
	}

	const onEventTypeToggle = (eventType: CalendarViewEventType) => {
		let updatedActiveEventTypes = filter.eventTypes

		if (updatedActiveEventTypes.includes(eventType)) {
			updatedActiveEventTypes = updatedActiveEventTypes.filter(type => type !== eventType)
		} else {
			updatedActiveEventTypes.push(eventType)
		}

		props.onFilterChange({
			...filter,
			eventTypes: [...updatedActiveEventTypes],
		})
	}

	const clearFilters = () => {
		props.onFilterChange({
			searchValue: '',
			eventTypes: [],
		})
	}

	return (
		<FixedSidebarContainer ref={container} active={active} style={{ zIndex: 8 }}>
			<SidebarHeader>
				{t('CalendarSidebarFilter::Filter')}

				<SidebarClose onClick={onCloseClick} />
			</SidebarHeader>

			<SidebarDivider />

			<SidebarContent>
				<input
					ref={input}
					type='text'
					value={searchValue}
					onChange={onSearchChange}
					style={{ marginBottom: Style.spacing.x1 }}
				/>

				<Caption>{t('CalendarSidebarFilter::Search by title, description, guests')}</Caption>

				<SidebarDivider />

				<SidebarSection>
					{[
						CalendarViewEventType.CALENDAR_EVENT,
						CalendarViewEventType.PROJECT,
						CalendarViewEventType.QUOTATION,
						CalendarViewEventType.INVOICE,
						CalendarViewEventType.RECURRING_INVOICE,
						CalendarViewEventType.TASK,
					].map(eventType => {
						return (
							<SidebarSectionItem key={eventType} onClick={() => onEventTypeToggle(eventType)}>
								{t(`CalendarViewEventType::${eventType}_plural`)}
								{eventTypes.includes(eventType) && <SidebarSectionItemCheck />}
							</SidebarSectionItem>
						)
					})}
				</SidebarSection>

				<SidebarDivider />

				<SidebarSectionItem onClick={clearFilters}>
					{t('CalendarSidebarFilter::Clear filters')}
				</SidebarSectionItem>
			</SidebarContent>
		</FixedSidebarContainer>
	)
}

export default CalendarSidebarFilter