import * as React from 'react'
import Icon from '../Icons/Icon'

interface IProps {
	type: 'twitter' | 'facebook'
	shareableContent?: string
	url?: string
	onClick?: () => void
}

export default class SocialButton extends React.PureComponent<IProps> {
	static defaultProps = {
		onClick: () => {}
	}

	componentDidMount () {
		const { type } = this.props

		switch (type) {
			case 'twitter':
				this.initializeTwitterSnipper()
				break
		}
	}

	initializeTwitterSnipper () {
		(function(d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0],
				// @ts-ignore
				t = window.twttr || {};
			if (d.getElementById(id)) return t;
			js = d.createElement(s);
			js.id = id;
			js.src = "https://platform.twitter.com/widgets.js";
			fjs.parentNode.insertBefore(js, fjs);
		
			t._e = [];
			t.ready = function(f) {
				t._e.push(f);
			};
		
			return t;
		}(document, "script", "twitter-wjs"))
	}

	getUrl () {
		const { type, shareableContent, url } = this.props

		switch (type) {
			case 'twitter':
				return encodeURI(`https://twitter.com/intent/tweet?text=${shareableContent}&url=${url}`)
			case 'facebook':
				return encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}`)
			default:
				return 'javascript://'
		}
	}

	render () {
		const { type, onClick } = this.props

		return (
			<>
				<a href={this.getUrl()} className={`button button-social button-${type}`} onClick={onClick}>
					<Icon icon={type} />
				</a>
			</>
		)
	}
}