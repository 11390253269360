import * as React from 'react'
import { BizzeyFile, BizzeyFileResourceType, BizzeyFileType, PreviewAttachment } from '../../types'
import { FilesController } from '../../controllers'
import styled, { css } from 'styled-components'
import Dropzone from '../Dropzone/Dropzone'
import { DropEvent, FileRejection } from 'react-dropzone'
import { MAX_FILE_SIZE } from '../../Constants'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Style } from '../../styles'
import SearchIcon from '../Search/Parts/SearchIcon'
import Icon from '../Icons/Icon'
import prettyBytes from 'pretty-bytes'
import FileHelper from '../../helpers/FileHelper'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { showAttachmentsViewerModal, showConfirmModal, showFileModal } from '../../store/modals/actions'
import ReactTooltip from 'react-tooltip'
import { saveAs } from 'file-saver'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import PageLoader from '../Page/PageLoader'

const Container = styled.div``

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 18px;
  margin-bottom: ${Style.spacing.x1};
`

const Search = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${Style.spacing.x1};

  ${SearchIcon} {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input {
		padding-left: 28px !important;
		padding-right: 32px !important;
		min-width: 160px;
	}
`

const Files = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Style.spacing.x1};
  gap: 8px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 300px;
`

const File = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Style.color.border};
  padding: ${Style.spacing.x1};
  border-radius: ${Style.variables.baseBorderRadius};
  gap: ${Style.spacing.x1};
  cursor: pointer;
  flex: 1;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const FileIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};
  font-weight: bold;

  i {
    font-size: 20px;
  }
`

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  @media screen and (max-width: ${Style.breakpoints.SMALL}){
    justify-content: center;
    align-items: center;
  }
`
const FileName = styled.div`
  font-size: 16px;
  font-weight: 500;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    text-align: center;
  }
`
const FileSize = styled.div`
  font-size: 12px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    text-align: center;
  }
`

const FileActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const FileAction = styled.div<{ destructive?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};
  background: #f4f4f8;
  color: black;

  svg {
    width: 20px;
    height: 20px;
  }

  ${props => props.destructive && css`
    background-color: ${Style.color.brandDanger};
    color: white;
  `}
`

interface IDispatchToProps {
  showFileModal: typeof showFileModal
  showConfirmModal: typeof showConfirmModal
  showAttachmentsViewerModal: typeof showAttachmentsViewerModal
}

type IProps = {
  resourceType: BizzeyFileResourceType
  resourceId: string
} & WithTranslation & IDispatchToProps

interface IState {
  files: BizzeyFile[]
  currentPage: number,
  endReached: boolean | null
  didInitialLoad: boolean
  isFetching: boolean
  search: string
}

class ResourceFiles extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      files: [],
      currentPage: 1,
      endReached: false,
      didInitialLoad: false,
      isFetching: false,
      search: '',
    }

    this.fetchFiles = this.fetchFiles.bind(this)
    this.refetch = this.refetch.bind(this)
    this.onFileDrop = this.onFileDrop.bind(this)
    this.onFileClick = this.onFileClick.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onClearSearch = this.onClearSearch.bind(this)
    this.onEditClick = this.onEditClick.bind(this)
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.onDeleteClick = this.onDeleteClick.bind(this)
    this.onScrollChange = this.onScrollChange.bind(this)
  }

  componentDidMount(): void {
    this.fetchFiles(1)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchFiles(page: number) {
    const { resourceId, resourceType } = this.props
    const { isFetching, search } = this.state

    if (!isFetching) {
      try {
        this.setState({ isFetching: true })

        const params = {
          page: page,
          resource_type: resourceType ? resourceType : null,
          resource_id: resourceId ? resourceId : null,
          type: BizzeyFileType.FILE,
          order: 'created_at_desc',
        }

        if (search?.length > 0) {
          params['name[cont]'] = search
        }

        const response = await FilesController.getFiles(params)
        const { files, current_page, total_entries, total_pages, per_page } = response;

        this.setState({
          files: page === 1 ? files : [...this.state.files, ...files],
          currentPage: current_page,
          endReached: current_page >= total_pages,
          didInitialLoad: true,
          isFetching: false
        });
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  refetch() {
    this.fetchFiles(1)
  }

  onFileDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { resourceType, resourceId } = this.props

    acceptedFiles.forEach(async (file: any) => {
      try {
        const newFile: BizzeyFile = {
          type: BizzeyFileType.FILE,
          name: file.name,
          parent_file_id: null,
          resource_id: resourceId,
          resource_type: resourceType,
          attachment: file,
          attachment_content_type: file.type,
        }

        const responseFile = await FilesController.create(newFile)

        if (responseFile.errors) return

        this.setState({ files: [responseFile, ...this.state.files] })
      } catch (ex) {
        console.error(ex)
      }
    })
  }

  onSearchChange(e) {
    this.setState({ search: e.target.value }, () => {
      this.fetchFiles(1)
    })
  }

  onClearSearch(e) {
    this.setState({ search: '' }, () => {
      this.fetchFiles(1)
    })
  }

  onFileClick(file: BizzeyFile) {
    const { t } = this.props
    const attachments: PreviewAttachment[] = [
      {
        id: file.id,
        name: file.attachment_file_name,
        url: file.attachment_url,
        file_size: file.attachment_file_size,
        content_type: file.attachment_content_type,
        created_at: file.created_at
      }
    ]

    this.props.showAttachmentsViewerModal({
      attachments: attachments,
      actions: [{
        icon: 'external-link', text: t('ResourceFiles::Open in new tab'), onClick: (selectedIndex: number) => {
          const selectedAttachment = attachments[selectedIndex]

          if (selectedAttachment) window.open(selectedAttachment.url, "_blank");
        }
      }]
    })
  }

  onEditClick(e, file: BizzeyFile) {
    e.stopPropagation()

    this.props.showFileModal({
      file: { id: file.id },
      onSubmit: (file) => {
        const { files } = this.state
        const index = files.findIndex((f) => f.id === file.id)

        files[index] = file

        this.setState({ files: [...files] })
      }
    })
  }

  onDownloadClick(e, file: BizzeyFile) {
    e.stopPropagation()

    if (file.attachment_download_url) saveAs(file.attachment_download_url, file.attachment_file_name)
  }

  async onDeleteClick(e, file: BizzeyFile) {
    const { t, showConfirmModal } = this.props
    e.stopPropagation()

    requestAnimationFrame(() => {
      showConfirmModal({
        title: t('ResourceFiles::Delete file'),
        description: t('ResourceFiles::You are about to delete this file. Are you sure?'),
        action: { label: t('ResourceFiles::Delete'), isDestructive: true },
        onConfirm: async () => {
          try {
            await FilesController.delete(file.id)

            this.setState({ files: this.state.files.filter((f) => f.id !== file.id) })
          } catch (ex) {
            console.error(ex)
          }
        }
      })
    })
  }

  onScrollChange(event) {
    const { currentPage, endReached, isFetching } = this.state
    const node = event.target;
    const scrollListEndReached = (node.scrollHeight - node.scrollTop - 50) <= node.clientHeight;

    if (scrollListEndReached && !endReached && !isFetching) {
      this.fetchFiles(currentPage + 1)
    }
  }

  render() {
    const { t } = this.props
    const { files, search, didInitialLoad } = this.state

    return (
      <Container>
        <Dropzone
          dropzoneProps={{
            onDrop: this.onFileDrop,
            multiple: true,
            maxSize: MAX_FILE_SIZE
          }}
        />

        {!didInitialLoad && <>
          <PageLoader />
        </>}

        {(files.length > 0 || search.length > 0) && <FilesContainer>
          <Search>
            <SearchIcon>
              <Icon icon='search' />
            </SearchIcon>
            <input
              type='text'
              placeholder={t('ResourceFiles::Search...')}
              value={search}
              onChange={this.onSearchChange}
            />
          </Search>

          <Files onScroll={this.onScrollChange}>
            {search?.length > 0 && files.length === 0 && (
              <CardEmptyInfo
                icon='search'
                description={t('ResourceFiles::No files found')}
                descriptionActionText={t('ResourceFiles::Clear search')}
                onDescriptionActionClick={this.onClearSearch}
              />
            )}
            {files.map((file: BizzeyFile) => (
              <File key={file.id} onClick={() => this.onFileClick(file)} title={file.name}>
                <FileIcon>
                  {FileHelper.getExtension(file.attachment_file_name)}
                </FileIcon>
                <FileDetails>
                  <FileName>{file.name}</FileName>
                  <FileSize>{prettyBytes(file.attachment_file_size)}</FileSize>
                </FileDetails>

                <FileActions>
                  <FileAction data-tip={t('ResourceFiles::Edit')} onClick={(e) => this.onEditClick(e, file)}>
                    <Icon icon='edit' />
                  </FileAction>
                  <FileAction data-tip={t('ResourceFiles::Download')} onClick={(e) => this.onDownloadClick(e, file)}>
                    <Icon icon='download-circle' />
                  </FileAction>
                  <FileAction data-tip={t('ResourceFiles::Delete')} onClick={(e) => this.onDeleteClick(e, file)} destructive>
                    <Icon icon='trash' />
                  </FileAction>
                </FileActions>
              </File>
            ))}
          </Files>
        </FilesContainer>}
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showFileModal: (options) => dispatch(showFileModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showAttachmentsViewerModal: (options) => dispatch(showAttachmentsViewerModal(options)),
  }
}

export { ResourceFiles }
export default connect(null, mapDispatchToProps, null, { forwardRef: true })(withTranslation(null, { withRef: true })(ResourceFiles))