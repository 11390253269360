import styled from 'styled-components'

const MenuEditorItemIcon = styled.div`
  margin-right: 8px;
  background: #f7f7fa;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`

export default MenuEditorItemIcon