import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import TrackItem from './TrackItem'
import { Moment } from 'moment'
import moment from '../../../utilities/Moment'
import TimeHelper from '../../../helpers/TimeHelper'
import NumberFormatter from '../../../utilities/NumberFormatter'
import { CalendarEvent, Settings, TimeEntry } from '../../../types'
import TimeFormatter from '../../../utilities/TimeFormatter'

const ResponsiveContainer = styled.div`
	overflow-x: auto;

  &::-webkit-scrollbar {
    background: transparent;
		height: 8px;
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 4px;
	}
`
const Container = styled.div`
	position: relative;
	height: 240px;
	width: 100%;
	background: white;
	margin: 50px 0;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 1100px;
	}
`

const GraphContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

interface IProps {
  entries: TimeEntry[]
  leave: CalendarEvent[]
  start: Moment
  end: Moment
  onTimerStartClick: (startDate: Moment) => void
  onAddTimeClick: (startDate: Moment) => void
  activeTimeEntry?: TimeEntry
  setting: Settings
}

export default class TrackGraph extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props)

    this.onTimerStartClick = this.onTimerStartClick.bind(this)
  }

  onTimerStartClick(start: Moment) {
    const { onTimerStartClick } = this.props

    const now = moment()

    // Start current hour and minute else it starts at 00:00:00
    // which makes no sense for the user
    const startDate = moment(start).set({
      hour: now.get('hour'),
      minute: now.get('minute'),
      second: now.get('second'),
    })

    onTimerStartClick(startDate)
  }

  onAddTimeClick(start: Moment) {
    const { onAddTimeClick } = this.props

    const now = moment()

    // Start current hour and minute else it starts at 00:00:00
    // which makes no sense for the user
    const startDate = moment(start).set({
      hour: now.get('hour'),
      minute: now.get('minute'),
      second: now.get('second'),
    })

    onAddTimeClick(startDate)
  }

  getGraphData() {
    const {
      start,
      end,
      entries,
      leave,
    } = this.props

    const dateRange = TimeHelper.getDateRange(start, end)

    const labels: string[] = dateRange.map(dateString => moment(dateString, 'YYYY-MM-DD').format('dd, DD MMM'))

    const data: { date: Moment, revenue: string, duration: number, leaveDuration: number }[] = dateRange.map(dateMoment => {
      const filteredEntries = entries.filter(entry => dateMoment.isSame(moment(entry.started_at), 'day'))

      // Get all billable time entries and calculate revenue
      const revenue = filteredEntries
        .filter(timeEntry => timeEntry.billable)
        .reduce((previousValue: any, timeEntry: TimeEntry) => previousValue + Number(timeEntry.amount), 0)

      const duration = filteredEntries
        .reduce((previousValue: any, timeEntry: TimeEntry) => previousValue + timeEntry.duration, 0)

      const leaveDuration = leave
        .filter(leave => {
          const leaveStartMoment = moment(leave.start)
          const leaveEndMoment = moment(leave.end)

          return (
            // Check if leave is on the same day
            leaveStartMoment.isSame(dateMoment, 'day') ||
            // Check if date is between start and end date of leave
            dateMoment.isBetween(leaveStartMoment, leaveEndMoment, 'day') ||
            // Check if leave ends on the same day
            leaveEndMoment.isSame(dateMoment, 'day')
          )
        })
        .reduce((previousValue: any, leave: CalendarEvent) => {
          const leaveStartMoment = moment(leave.start)
          const leaveEndMoment = moment(leave.end)

          // If leave is all day, add a maxium of 8 hours
          if (leave.all_day) {
            return previousValue + 3600 * 8
          } else {
            const differenceInSeconds = leaveEndMoment.diff(leaveStartMoment, 'second')

            // If leave is longer than 8 hours, add a maxium of 8 hours
            return previousValue + Math.min(differenceInSeconds, 3600 * 8)
          }
        }, 0)

      return {
        date: dateMoment,
        revenue: revenue,
        duration: duration,
        leaveDuration: leaveDuration,
      }
    })

    return data.map((data, index) => {
      return {
        date: data.date,
        revenue: data.revenue,
        duration: data.duration,
        leaveDuration: data.leaveDuration,
        label: labels[index],
      }
    })
  }

  render() {
    const {
      activeTimeEntry,
      setting,
    } = this.props

    const data = this.getGraphData()

    let maxValue = Math.max(
      ...data.map(d => d.duration + d.leaveDuration),
    )

    maxValue += maxValue * 20 / 100

    return (
      <ResponsiveContainer>
        <Container style={{ minWidth: data.length * 130 }}>
          <GraphContainer>
            {data.map((dataItem, index) => {
              const revenue = Number(dataItem.revenue)
              const topTick = moment.duration({ second: dataItem.duration + dataItem.leaveDuration }).format(`h[${'u'}] m[${'m'}]`)
              const timeHeight = dataItem.duration > 0 ? dataItem.duration / maxValue * 100 : 0
              const timeTooltip = timeHeight > 0 ? NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, revenue) : null
              const leaveHeight = dataItem.leaveDuration > 0 ? dataItem.leaveDuration / maxValue * 100 : 0
              const leaveTooltip = leaveHeight > 0 ? TimeFormatter.durationFormat(dataItem.leaveDuration, setting.time_format) : null
              const bottomTick = dataItem.label

              const dataItemDate = moment(dataItem.date)
              const showTimerButton = !Boolean(activeTimeEntry) && dataItemDate.isSame(moment(), 'day')

              return (
                <TrackItem
                  key={index}
                  topTick={topTick}
                  timeHeight={`${timeHeight}%`}
                  timeTooltip={timeTooltip}
                  leaveHeight={`${leaveHeight}%`}
                  leaveTooltip={leaveTooltip}
                  bottomTick={bottomTick}
                  onTimerStartClick={showTimerButton ? () => this.onTimerStartClick(dataItemDate) : null}
                  onAddTimeClick={() => this.onAddTimeClick(dataItemDate)}
                />
              )
            })}
          </GraphContainer>
        </Container>
      </ResponsiveContainer>
    )
  }
}