import styled from 'styled-components'
import { Style } from '../../styles'

const DealsContainer = styled.div`
  position: relative;
  width: calc(100% + 64px);;
  height: calc(100% - 61px);
  margin-left: -32px;
  overflow: hidden;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    height: calc(100% - 95px);
    width: 100%;
    margin-left: 0;
  }
`

export default DealsContainer