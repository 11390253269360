import * as React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../../Icons/Icon'
import NumberFormatter from '../../../utilities/NumberFormatter'
import { useTranslation } from 'react-i18next'
import { Settings } from '../../../types'

const GraphItemTickContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	color: #CCCCCC;
	font-weight: 600;
`


const TopActionsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	position: absolute;
	right: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	top: 0;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		height: 100%;
		cursor: pointer;
		font-size: 18px;

		&:first-child {
			background: #E6E6E6;
			color: #999999;
			border-top-left-radius: 5px;
		}

		&:last-child {
			border-top-right-radius: 5px;
			background: #6BBD2E;
			color: white;
		}
	}
`

const TooltipContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	top: 50%;
	left: 50%;
	height: 55px;
	width: calc(100% - 48px);
	transform: translate(-50%, -50%);
	background: #6BBD2E; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
`

const Container = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	height: 100%;
	padding: 0 8px;
	align-items: flex-end;
	
	&:not(:last-child) {
		border-right: 2px dotted #F4F6F8;
	}

	&:hover {
		background: #EAF4FB;

		${GraphItemTickContainer} {
			font-weight: bold;
			color: #999999;
		}

		${TopActionsContainer} {
			top: -40px;
			opacity: 1;
			visibility: visible;
		}
	}
`

const TopTickContainer = styled(GraphItemTickContainer)`
	position: absolute;
	top: -40px;
	right: 0;
	left: 0;

	&::after {
		width: 2px;
    height: 10px;
    background-color: #E6E6E6;
    content: "";
    position: absolute;
    bottom: 0;
	}
`

const BottomTickContainer = styled(GraphItemTickContainer)`
	position: absolute;
	bottom: -40px;
	right: 0;
	left: 0;
	border-right: 2px dotted #F4F6F8;

	&::after {
		width: 2px;
    height: 10px;
    background-color: #E6E6E6;
    content: "";
    position: absolute;
    top: 0;
	}
`

const Item = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	transition: max-height 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
`

const CategoryTooltip = styled.div<{ backgroundColor: string }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	left: 50%;
	top: -75px;
	transform: translateX(-50%);
	padding: 8px 16px;
	background: #6BBD2E; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: 17;
	width: max-content;

	${(props) => css`
		background: ${props.backgroundColor};
	`}

	span {
		text-align: center;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			font-weight: normal;
		}
	}

	&:after {
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-width: 10px;

		${(props) => css`
			border-top-color: ${props.backgroundColor};
		`}
	}
`

const CategoryItem = styled.div`
	position: relative;
	height: 100%;
	transition: max-height 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

	&:hover {
		${CategoryTooltip} {
			opacity: 1;
			visibility: visible;
		}
	}
`



interface IProps {
	topTick?: string
	height?: string
	bottomTick?: string
	total: number
	data: { type: 'actual' | 'estimated', category: string, amount: number, color: string }[]
	setting: Settings
	onAddExpenseClick: () => void
}

const ExpenseMonthlyItem = (props: IProps) => {
	const { t } = useTranslation()
	const {
		topTick,
		height,
		bottomTick,
		setting,
		data,
		total,
		onAddExpenseClick,
	} = props

	const estimatedData = data.filter((item) => item.type === 'estimated')
	const actualData = data.filter((item) => item.type === 'actual')

	return (
		<Container>
			{topTick && <TopTickContainer>
				{topTick}
			</TopTickContainer>}

			<TopActionsContainer>
				<div onClick={onAddExpenseClick}>
					<Icon icon='plus' />
				</div>
			</TopActionsContainer>

			<Item style={{ maxHeight: height || '0%' }}>
				{estimatedData.map((dataItem, index) => {
					const { category, amount, color } = dataItem
					const height = amount / total * 100
					const heightPercentage = `${height}%`

					let backgroundProperties: any = {
						backgroundImage: `linear-gradient(45deg, ${color} 25%, white 25%, white 50%, ${color} 50%, ${color} 75%, white 75%, white 100%)`,
						backgroundSize: '2.97px 2.97px'
					}

					return (
						<CategoryItem key={`${dataItem.category}-estimated-${index}`}
							style={{
								...backgroundProperties,
								color: color,
								maxHeight: height ? heightPercentage : '0%',
							}}>
							<CategoryTooltip backgroundColor={color}>
								<span>{`${t('ExpenseMonthly::Estimated')} - ${category}`}</span>
								<span>{NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, amount)}</span>
							</CategoryTooltip>
						</CategoryItem>
					)
				})}

				{actualData.map((dataItem, index) => {
					const { category, amount, color } = dataItem
					const height = amount / total * 100
					const heightPercentage = `${height}%`

					return (
						<CategoryItem key={`${dataItem.category}-actual-${index}`}
							style={{
								backgroundColor: color,
								color: color,
								maxHeight: height ? heightPercentage : '0%',
							}}>
							<CategoryTooltip backgroundColor={color}>
								<span>{category}</span>
								<span>{NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, amount)}</span>
							</CategoryTooltip>
						</CategoryItem>
					)
				})}
			</Item>

			{bottomTick && <BottomTickContainer>
				{bottomTick}
			</BottomTickContainer>}
		</Container>
	)
}

export default ExpenseMonthlyItem