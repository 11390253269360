import * as React from 'react'
import { InvoicesController } from '../../controllers'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import { withRouter, RouteComponentProps } from 'react-router'
import RouteHelper from '../../helpers/RouteHelper'
import ERoute from '../../ERoute'
import i18n from '../../translations'
import { Translation } from 'react-i18next'
import LedgerItemAvatar from '../Avatar/LedgerItemAvatar'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { CurrentUser, LedgerItem, LedgerItemType, ResourceListFilterType } from '../../types'
import ButtonPanel from '../Button/ButtonPanel'
import LedgerItemHelper from '../../helpers/LedgerItemHelper'
import ResourceTable from '../Resource/ResourceTable'
import ResourceTableRow from '../Resource/ResourceTableRow'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import NumberFormatter from '../../utilities/NumberFormatter'
import moment from '../../utilities/Moment'

interface IProps extends RouteComponentProps<any> {
  contactId?: string
  projectId?: string
  type: 'pending' | 'outstanding' | 'overdue' | 'paid'
  currentUser: CurrentUser
}

interface IState {
  ledgerItems: LedgerItem[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  sortValue: string
  isFetching: boolean
  filters: any
}

class SummaryInvoiceTable extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      ledgerItems: [],
      currentPage: 1,
      totalPages: 1,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.LEDGER_ITEM_SORT_VALUE, 'number_desc'),
      filters: {}
    }

    this.onNewLedgerItemClick = this.onNewLedgerItemClick.bind(this)
    this.onLedgerItemFiltersChange = this.onLedgerItemFiltersChange.bind(this)
    this.onLedgerItemSortValueChange = this.onLedgerItemSortValueChange.bind(this)
    this.onTableLedgerItemClick = this.onTableLedgerItemClick.bind(this);
  }

  componentDidMount() {
    this.fetchLedgerItems(1)
  }

  fetchLedgerItems(page = 1) {
    const { type, contactId, projectId } = this.props
    const { filters, sortValue } = this.state

    let parameters: Object = {
      type: LedgerItemType.INVOICE,
      contact_id: contactId,
      order: sortValue,
      ...filters,
    };

    if (contactId) parameters['contact_id'] = contactId
    if (projectId) parameters['project_id'] = projectId

    if (type === 'pending') {
      parameters = {
        ...parameters,
        pending: true,
      }
    } else if (type === 'outstanding') {
      parameters = {
        ...parameters,
        outstanding: true,
      }
    } else if (type === 'overdue') {
      parameters = {
        ...parameters,
        overdue: true,
      }
    } else if (type === 'paid') {
      parameters = {
        ...parameters,
        paid: true,
      }
    }

    this.setState({ isFetching: true })

    InvoicesController
      .getInvoices(page, parameters)
      .then(response => {
        const { current_page, total_pages, invoices: ledgerItems } = response

        this.setState({
          ledgerItems: [...ledgerItems],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false
        });
      })
      .catch(console.error)
  }

  onNewLedgerItemClick() {
    const { history, contactId, projectId, type } = this.props

    let params = {
      type: LedgerItemType.INVOICE,
    }

    if (contactId) params['contact_id'] = contactId
    if (projectId) params['project_id'] = projectId

    history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, params))
  }

  onTableLedgerItemClick(ledgerItem) {
    const { history } = this.props

    history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: ledgerItem.id }))
  }

  getEmptyTypeDescription() {
    const { type } = this.props

    if (type === 'pending') {
      return i18n.t('SummaryInvoiceTable::No draft invoices')
    } else if (type === 'outstanding') {
      return i18n.t('SummaryInvoiceTable::No outstanding invoices')
    } else if (type === 'overdue') {
      return i18n.t('SummaryInvoiceTable::No overdue invoices')
    } else if (type === 'paid') {
      return i18n.t('SummaryInvoiceTable::No paid invoices')
    }
  }

  onLedgerItemFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchLedgerItems(1)
    })
  }

  onLedgerItemSortValueChange(value: string) {
    const { type } = this.props

    LocalStorage.set(LocalStorageKey.LEDGER_ITEM_SORT_VALUE, value)

    this.setState({ sortValue: value, }, () => {
      this.fetchLedgerItems(1)
    })
  }

  render() {
    const { currentUser: { workspace: { setting } }, type } = this.props
    const { didInitialLoad, ledgerItems, currentPage, filters, sortValue, totalPages, isFetching } = this.state;

    const filtersActive = Object.keys(filters).length > 0

    return (
      <Translation>
        {
          (t, { i18n }) => {
            return (
              <div className='card-content is-centered no-padding'>
                <ResourceTable
                  actionsLeft={[
                    <ButtonPanel
                      icon='plus'
                      text={t('LedgerItemsTable::New {{type}}', { type: LedgerItemHelper.getTypeLabel(LedgerItemType.INVOICE).toLowerCase() })}
                      onClick={this.onNewLedgerItemClick}
                    />]}
                  data={ledgerItems}
                  headers={[
                    { title: '' },
                    { title: t('LedgerItemsTable::Number') },
                    { title: t('LedgerItemsTable::Contact') },
                    { title: t('LedgerItemsTable::Issued on'), align: 'right' },
                    { title: t('LedgerItemsTable::Due on'), align: 'right' },
                    { title: t('LedgerItemsTable::Amount'), align: 'right' },
                  ]}
                  renderRow={(ledgerItem: LedgerItem) => {
                    return (
                      <ResourceTableRow key={ledgerItem.id}>
                        <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='30px' maxWidthMobile='50px'>
                          <LedgerItemAvatar width={30} ledgerItem={ledgerItem} rounded={true} onClick={() => this.onTableLedgerItemClick(ledgerItem)} />
                        </ResourceTableRowData>
                        <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                          {ledgerItem.number}
                        </ResourceTableRowData>
                        <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} maxWidth='150px' ellipse>
                          {ledgerItem?.contact?.name || '-'}
                        </ResourceTableRowData>
                        <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                          {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                        </ResourceTableRowData>
                        <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                          {ledgerItem?.due_on ? moment(ledgerItem.due_on).format(setting.date_format) : '-'}
                        </ResourceTableRowData>
                        <ResourceTableRowData onClick={(): void => this.onTableLedgerItemClick(ledgerItem)} textAlign='right'>
                          {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, ledgerItem.amount)}
                        </ResourceTableRowData>
                      </ResourceTableRow>
                    )
                  }}
                  renderEmpty={<CardEmptyInfo
                    icon={filtersActive ? 'search' : 'invoice'}
                    description={filtersActive ? t('LedgerItemsTable::No invoices found') : this.getEmptyTypeDescription()}
                    descriptionActionText={filtersActive ? t('LedgerItemsTable::Clear filters') : t('LedgerItemsTable::Add invoice')}
                    onDescriptionActionClick={filtersActive ? () => this.onLedgerItemFiltersChange({}) : this.onNewLedgerItemClick}
                  />}
                  filters={[
                    { name: 'project_id', label: t('LedgerItemsTable::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
                    { name: 'number', label: t('LedgerItemsTable::Number'), type: ResourceListFilterType.STRING },
                    { name: 'identifier', label: t('LedgerItemsTable::Identifier'), type: ResourceListFilterType.STRING },
                    { name: 'amount', label: t('LedgerItemsTable::Amount'), type: ResourceListFilterType.NUMBER },
                    { name: 'issued_on', label: t('LedgerItemsTable::Issued on'), type: ResourceListFilterType.DATE },
                    { name: 'due_on', label: t('LedgerItemsTable::Due on'), type: ResourceListFilterType.DATE },
                    { name: 'reminders_enabled', label: t('LedgerItemsTable::Reminders enabled'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('LedgerItemsTable::Yes'), value: String(true) }, { label: t('LedgerItemsTable::No'), value: String(false) }] },
                    { name: 'created_at', label: t('LedgerItemsTable::Created date'), type: ResourceListFilterType.DATE },
                  ]}
                  onFiltersChange={this.onLedgerItemFiltersChange}
                  sortOptions={[
                    { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
                    { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
                    { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
                    { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
                    { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
                    { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
                    { label: t('LedgerItemsTable::Created at ↑'), value: 'created_at_asc' },
                    { label: t('LedgerItemsTable::Created at ↓'), value: 'created_at_desc' },
                  ]}
                  sortValue={sortValue}
                  onSortChange={this.onLedgerItemSortValueChange}
                  pagination={{ page: currentPage, pageCount: totalPages }}
                  onPageChange={(page) => this.fetchLedgerItems(page)}
                  isLoading={isFetching}
                  maxHeight='65vh'
                  stickyHeader={true}
                />
              </div>
            )
          }
        }
      </Translation>

    );

  }
}

export default withRouter(SummaryInvoiceTable)