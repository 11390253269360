import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import PopoverDivider from '../Popover/PopoverDivider'
import PopoverItem from '../Popover/PopoverItem'
import TaskPopoverClose from '../TaskModal/TaskPopoverClose'
import TaskPopoverContainer from '../TaskModal/TaskPopoverContainer'
import TaskPopoverContent from '../TaskModal/TaskPopoverContent'
import TaskPopoverHeader from '../TaskModal/TaskPopoverHeader'
import TaskPopoverTitle from '../TaskModal/TaskPopoverTitle'

interface IProps {
	activator: JSX.Element
	onAddTaskClick: () => void
	onMoveTasksClick: () => void
	onDeleteTasksClick: () => void
	onDeleteListClick: () => void
}

const BoardListActionsPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator } = props


	const [popoverActive, setPopoverActive] = React.useState(false)

	const onTogglePopover = () => {
		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onAddTaskClick = () => {
		props.onAddTaskClick()
		setPopoverActive(false)
	}

	const onMoveTasksClick = () => {
		props.onMoveTasksClick()
		setPopoverActive(false)
	}

	const onDeleteTasksClick = () => {
		props.onDeleteTasksClick()
		setPopoverActive(false)
	}

	const onDeleteClick = () => {
		setPopoverActive(false)

		props.onDeleteListClick()
	}


	return (
		<Popover
			active={popoverActive}
			activator={<div onClick={onTogglePopover}>
				{activator}
			</div>}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<div />
					<TaskPopoverTitle>{t('BoardListActionsPopover::Actions')}</TaskPopoverTitle>
					<TaskPopoverClose onClick={onPopoverClose} />
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<PopoverItem onClick={onAddTaskClick}>
						{t('BoardListActionsPopover::Add task...')}
					</PopoverItem>
					{false && <PopoverItem>
						Copy list...
					</PopoverItem>}
					{false && <PopoverItem>
						Move list...
					</PopoverItem>}

					<PopoverDivider />

					<PopoverItem onClick={onMoveTasksClick}>
						{t('BoardListActionsPopover::Move all tasks in this list')}
					</PopoverItem>

					<PopoverItem onClick={onDeleteTasksClick}>
						{t('BoardListActionsPopover::Delete all tasks in this list')}
					</PopoverItem>

					<PopoverDivider />

					<PopoverItem onClick={onDeleteClick}>
						{t('BoardListActionsPopover::Delete this list')}
					</PopoverItem>
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover >
	)
}

export default BoardListActionsPopover