import styled from 'styled-components'
import { Style } from '../../styles'

const ReportNavigation = styled.div`
  display: flex;
  flex-direction : row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: ${Style.spacing.x2};
  border-bottom: 1px solid ${Style.color.border};
  margin-bottom: ${Style.spacing.x2};

  > div {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`

export default ReportNavigation