import styled from 'styled-components'
import { Style } from '../../styles'

const ConversationWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
    background: white;
		height: 8px;
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 4px;
	}
`

export default ConversationWrapper 