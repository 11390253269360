import * as React from 'react'
import NumberFormatter from '../../utilities/NumberFormatter';

interface IProps {
	name: string,
	total: string
	color: string
	percentage: string
	currency: string
	numberFormat: string
}

export default class ExpenseLegendItem extends React.PureComponent<IProps> {
	render () {
		const { name, total, color, percentage, currency, numberFormat } = this.props

		return (
			<div className='legend-item is-expense'>
				<div className='legend-item-circle' style={{ backgroundColor: color }} />
				<div className='legend-item-content'>
					<span className='legend-item-title'>{name}</span>
					<div>
						<span style={{ fontSize: '12px', color: '#424955' }}>
							{NumberFormatter.formatCurrency(currency, numberFormat, total)}
						</span>
						<span style={{ fontSize: '11px', color: '#858C99', marginLeft: '4px' }}>
							{`${percentage}%`}
						</span>
					</div>
				</div>
			</div>
		)
	}
}