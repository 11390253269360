import * as React from 'react'
import { PermissionRole } from '../../types'

interface IProps {
	role: PermissionRole
	title: string
	description: string
	active: boolean
	onClick: (role: PermissionRole) => void
}

export default class PermissionItem extends React.PureComponent<IProps> {
	render() {
		const {
			role,
			title,
			description,
			active,
			onClick,
		} = this.props

		return (
			<div className={`permission-item ${active ? 'is-active' : ''}`} onClick={() => onClick(role)}>
				<div className='permission-item-radio'>
					<div className='setting-option-button'>
						<input type='radio' value='radio' checked={active} readOnly />
					</div>
				</div>

				<div className='permission-item-heading'>
					<span className='permission-item-heading-name'>{title}</span>
					<span className='permission-item-heading-description'>
						{description}
					</span>
				</div>
			</div>
		)
	}
}