import { Workspace } from "../../types";

export default class AppController {
  static getForm(): Promise<{
    remaining_workspaces_count: number;
    workspace: Workspace;
    countries: { label: string; value: string }[];
  }> {
    return fetch("/admin/peppol/form", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static approve(workspaceId: string, peppolId: string): Promise<void> {
    return fetch(`/admin/peppol/${workspaceId}/approve`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ peppol_id: peppolId }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }

  static reject(workspaceId: string, message: string): Promise<void> {
    return fetch(`/admin/peppol/${workspaceId}/reject`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: message }),
      credentials: "same-origin",
    }).then((res) => res.json());
  }
}
