import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Icon from '../Icons/Icon'
import AddBlock from './AddBlock'
import BlockHeader from './BlockHeader'
import BlockContainer from './BlockContainer'
import BlockWrapper from './BlockWrapper'
import { Draggable } from 'react-beautiful-dnd'
import ReactTooltip from 'react-tooltip'
import { ContentBlock, ContentBlockTemplate, ContentBlockType, ContentBlockVariables, ItemsBlock as ItemsBlockType, NumberFormat, WorkspaceTax } from '../../types'
import BlockHeaderActions from './BlockHeaderActions'
import BlockHeaderAction from './BlockHeaderAction'
import BlockInputs from './InputBlocks/BlockInputs'

export interface EditBlockProps<T> {
	block: T
	onChange: (block: T) => void
}

const BlockContainerWrapper = styled.div`	`

const BlockContent = styled.div``

interface IProps {
	index: number
	variables: ContentBlockVariables
	taxes: WorkspaceTax[]
	currency: string
	numberFormat: NumberFormat
	items: ItemsBlockType[]
	block: ContentBlock
	blocks: ContentBlock[]
	blockTemplates: ContentBlockTemplate[]
	types: ContentBlockType[]
	onBlockChange: (block: ContentBlock, onBlockChangeComplete: () => void) => void
	onAddBlockClick: (block: ContentBlock) => void
	onDeleteBlockClick: (block: ContentBlock) => void
	onSaveTemplateBlockClick: (block: ContentBlock) => void
	onDuplicateBlockClick: (block: ContentBlock) => void
	onSettingsBlockClick: (block: ContentBlock) => void
	onImportProductsClick: (block: ContentBlock) => void
	onTemplateDeleteClick: (contentBlockTemplate: ContentBlockTemplate, success?: () => void) => void
	onTemplateFormSubmit: (contentBlockTemplate: ContentBlockTemplate, success?: () => void) => void
}

const Block = (props: IProps) => {
	const {
		index,
		block,
		types,
		variables,
		taxes,
		currency,
		numberFormat,
		items,
		blocks,
		blockTemplates,
		onAddBlockClick,
		onTemplateDeleteClick,
		onTemplateFormSubmit
	} = props
	const [isSaving, setIsSaving] = React.useState(false)
	const { t } = useTranslation()
	const showHeader = ![ContentBlockType.SUBMIT].includes(block.type)
	const showBottomAddBlock = ![ContentBlockType.SUBMIT].includes(block.type)

	const onDeleteClick = () => {
		ReactTooltip.hide()
		props.onDeleteBlockClick(block)
	}

	const onImportProductsClick = () => {
		props.onImportProductsClick(block)
	}

	const onSaveAsTemplateClick = () => {
		props.onSaveTemplateBlockClick(block)
	}

	const onDuplicateClick = () => {
		props.onDuplicateBlockClick(block)
	}

	const onSettingsClick = () => {
		props.onSettingsBlockClick(block)
	}

	const isDeleteEnabled = (): boolean => {
		if (block.type === ContentBlockType.SIGN) return false

		return true
	}

	const isImportProductsEnabled = (): boolean => {
		return block.type === ContentBlockType.ITEMS
	}

	const isSaveToTemplateEnabled = (): boolean => {
		return true
	}

	const isDuplicateEnabled = (): boolean => {
		if ([
			ContentBlockType.SIGN,
			ContentBlockType.INTRO,
			ContentBlockType.SUMMARY
		].includes(block.type)) return false

		return true
	}

	const isSettingsEnabled = (): boolean => {
		return block.type === ContentBlockType.ITEMS || block.type === ContentBlockType.QUESTION_AND_ANSWER
	}

	const onBlockChange = async (block: ContentBlock) => {
		setIsSaving(true)
		props.onBlockChange(block, () => {
			setIsSaving(false)
		})
	}

	return (
		<Draggable
			key={block.id}
			draggableId={block.id}
			index={index}
		>
			{(draggableProvided, draggableSnapshot) => (
				<BlockContainerWrapper
					ref={draggableProvided.innerRef}
					{...draggableProvided.draggableProps}
				>
					<BlockContainer>
						<BlockWrapper className={!showHeader ? 'pt-0' : ''}>
							{showHeader && <BlockHeader>
								<BlockHeaderActions>
									{isImportProductsEnabled() && <BlockHeaderAction onClick={onImportProductsClick} data-tip={t('Block::Import products')}>
										<Icon icon='product' />
									</BlockHeaderAction>}
									{isSaveToTemplateEnabled() && <BlockHeaderAction onClick={onSaveAsTemplateClick} data-tip={t('Block::Save to templates')}>
										<Icon icon='layer-plus' />
									</BlockHeaderAction>}
									{isDuplicateEnabled() && <BlockHeaderAction onClick={onDuplicateClick} data-tip={t('Block::Duplicate')}>
										<Icon icon='duplicate' />
									</BlockHeaderAction>}
									{isSettingsEnabled() && <BlockHeaderAction onClick={onSettingsClick} data-tip={t('Block::Settings')}>
										<Icon icon='setting' />
									</BlockHeaderAction>}
									{isDeleteEnabled() && <BlockHeaderAction onClick={onDeleteClick} data-tip={t('Block::Delete')}>
										<Icon icon='trash' />
									</BlockHeaderAction>}
									<BlockHeaderAction {...draggableProvided.dragHandleProps} data-tip={t('Block::Reorder')}>
										<Icon icon='sort' />
									</BlockHeaderAction>
								</BlockHeaderActions>
							</BlockHeader>}

							<BlockContent>
								<BlockInputs
									block={block}
									currency={currency}
									items={items}
									taxes={taxes}
									numberFormat={numberFormat}
									onBlockChange={onBlockChange}
									onImportProductsClick={onImportProductsClick}
									onSettingsBlockClick={onSettingsClick}
									variables={variables}
								/>
							</BlockContent>
						</BlockWrapper>
					</BlockContainer>
					{showBottomAddBlock && <AddBlock
						types={types}
						blocks={blocks}
						blockTemplates={blockTemplates}
						variables={variables}
						taxes={taxes}
						currency={currency}
						numberFormat={numberFormat}
						onAddBlockClick={onAddBlockClick}
						onTemplateDeleteClick={onTemplateDeleteClick}
						onTemplateFormSubmit={onTemplateFormSubmit}
					/>}
				</BlockContainerWrapper>
			)}
		</Draggable>
	)
}

export default Block