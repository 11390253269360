import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { SequenceStep, SequenceStepType, SequenceStepTypeInfo } from '../../types'
import Icon from '../Icons/Icon'
import StepContainer from './StepContainer'
import ButtonPanel from '../Button/ButtonPanel'
import SequenceHelper from '../../helpers/SequenceHelper'
import DroppableHelper from '../../helpers/DroppableHelper'
import Utils from '../../utilities/Utils'

const Container = styled.div`
	margin-top: ${Style.spacing.x1};
	margin-bottom: ${Style.spacing.x1};
`

const IconContainer = styled.div<{ color: string, backgroundColor: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${Style.color.border};
	width: 48px;
	height: 48px;
	min-width: 48px;
	min-height: 48px;
	border-radius: 50%;

	${props => props.color && css`
		color: ${props.color};
		border-color: ${props.color};
	`}

	${props => props.backgroundColor && css`
		background-color: ${props.backgroundColor};
	`}

	svg {
		width: 18px;
		height: 18px;
		min-width: 18px;
		min-height: 18px;
		fill: currentColor;
	}

	i {
		font-size: 18px;
		color: currentColor;
	}
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: ${Style.spacing.x2};
`

const HeaderInfo = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`

const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const HeaderDescription = styled.div``

const HeaderActions = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const ToggleButton = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${Style.spacing.x1};
	cursor: pointer;

	svg {
		width: 18px;
		height: 18px;
	}

	i {
		font-size: 18px;
	}
`

const Items = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: ${Style.spacing.x2};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: flex;
		flex-direction: column;
		
	}
`

const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	border: 1px solid ${Style.color.border};

	padding: 14px;
	border-radius: ${Style.variables.baseBorderRadius};
	transition: all .2s ease;
	cursor: pointer;
	
	&:hover {
		border-color: ${Style.color.brandPrimary};
		background-color: #f6f6f7;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const Title = styled.div`
	font-size: 16px;
	font-weight: bold;
`

const Description = styled.div`
`

type IProps = {
	index: number
	steps: SequenceStep[]
	onAddClick: (step: SequenceStep) => void
}

interface IState {
	active: boolean,
}

const DEFAULT_STATE: IState = {
	active: false
}

const AddSequenceStep = (props: IProps) => {
	const { index, steps } = props
	const { t } = useTranslation()

	const [state, setState] = React.useState<IState>(DEFAULT_STATE)
	const { active } = state

	const sequenceStepTypeInfo = SequenceHelper.getSequenceStepTypeInfos()

	const onAddStepClick = () => {
		setState(({
			...state,
			active: true
		}))
	}

	const onAddSequenceStepClick = (stepInfo: SequenceStepTypeInfo) => {
		setState({ ...state, active: false })

		let step: SequenceStep = {
			type: stepInfo.type,
			position: DroppableHelper.getCreatePositionFromIndex(steps, index),
		}

		if (step.type === SequenceStepType.AUTOMATED_EMAIL) {
			step = {
				...step,
				emails: [
					{ id: Utils.uuid(), active: true, subject: "", body: "" }
				]
			}
		}

		props.onAddClick(step)
	}

	const onRemoveStepclick = () => {
		setState(({ ...state, active: false }))
	}

	return (
		<Container>
			{!active && <ToggleButton onClick={onAddStepClick}>
				<Icon icon='plus-circle' />
			</ToggleButton>}

			{active && <StepContainer>
				<Header>
					<HeaderInfo>
						<HeaderTitle>{t('AddSequenceStep::Step {{index}}', { index: index + 1 })}</HeaderTitle>
						<HeaderDescription>
							{t('AddSequenceStep::Choose the next action that the sequence will perform')}
						</HeaderDescription>
					</HeaderInfo>

					<HeaderActions>
						<ButtonPanel
							text={t('AddSequenceStep::Remove step')}
							onClick={onRemoveStepclick}
						/>
					</HeaderActions>
				</Header>

				<Items>
					{sequenceStepTypeInfo.map((sequenceStep, index) => {
						return (
							<Item key={index} onClick={() => onAddSequenceStepClick(sequenceStep)}>
								<IconContainer color={sequenceStep.color} backgroundColor={sequenceStep.backgroundColor}>
									<Icon icon={sequenceStep.icon} />
								</IconContainer>
								<Content>
									<Title>{sequenceStep.title}</Title>
									<Description>{sequenceStep.description}</Description>
								</Content>
							</Item>
						)
					})}
				</Items>
			</StepContainer>}
		</Container>
	)
}

export default AddSequenceStep