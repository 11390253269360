import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux'
import { AppState } from '../store'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import { showConfirmModal, showDocumentModal, showExportDocumentsModal, showShareLinksModal, showUploadDocumentsModal } from '../store/modals/actions'
import { DocumentsController } from '../controllers'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import LocalStorage, { LocalStorageKey } from '../LocalStorage';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import ResourceTable, { ResourceTableAction } from '../components/Resource/ResourceTable';
import ResourceTableRow from '../components/Resource/ResourceTableRow';
import ResourceTableRowData from '../components/Resource/ResourceTableRowData';
import { RouteComponentProps } from 'react-router';
import { Document, CurrentUser, ResourceListFilterType, UserWorkspaceSettingScope } from '../types';
import PageContent from '../components/Page/PageContent';
import TopNavigation from '../components/Navigation/TopNavigation';
import PageHeader from '../components/Page/PageHeader';
import moment from '../utilities/Moment';
import Icon from '../components/Icons/Icon';
import ResourceTableRowActions from '../components/Resource/ResourceTableRowActions';
import Notification from '../utilities/Notification';
import styled from 'styled-components';
import Badge from '../components/Badge/Badge';
import DocumentHelper from '../helpers/DocumentHelper';
import { saveAs } from 'file-saver';
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper';
import ModalFooterActionIcon from '../components/Modals/Parts/ModalFooterAction';

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
`

const MetadataIcons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showConfirmModal: typeof showConfirmModal
  showDocumentModal: typeof showDocumentModal
  showShareLinksModal: typeof showShareLinksModal
  showExportDocumentsModal: typeof showExportDocumentsModal
  showUploadDocumentsModal: typeof showUploadDocumentsModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  documents: Document[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
  filters: any
  selectedDocumentIds: string[]
  searchValue: string
}

class Documents extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      selectedDocumentIds: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.DOCUMENTS_SORT_VALUE, 'created_at_desc'),
      filters: {},
      searchValue: ''
    }

    this.onNewDocumentsClick = this.onNewDocumentsClick.bind(this)
    this.onTableDocumentClick = this.onTableDocumentClick.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onDocumentFormSubmit = this.onDocumentFormSubmit.bind(this)
    this.onDocumentUpdateFormSubmit = this.onDocumentUpdateFormSubmit.bind(this)
    this.onDocumentFiltersChange = this.onDocumentFiltersChange.bind(this)
    this.onDocumentSortValueChange = this.onDocumentSortValueChange.bind(this)
    this.onDocumentSearchChange = this.onDocumentSearchChange.bind(this)
    this.onDocumentSearchSubmit = this.onDocumentSearchSubmit.bind(this)
    this.onDocumentClearFilters = this.onDocumentClearFilters.bind(this)
    this.onDocumentBulkDownloadActionClick = this.onDocumentBulkDownloadActionClick.bind(this)
    this.onDocumentBulkDeleteActionClick = this.onDocumentBulkDeleteActionClick.bind(this)
    this.onExportDocumentsClick = this.onExportDocumentsClick.bind(this)
    this.onDeliverByEmailClick = this.onDeliverByEmailClick.bind(this)
  }

  componentWillMount() {
    this.fetchDocuments(1)
  }

  fetchDocuments(page: number) {
    const { searchValue, sortValue, filters } = this.state
    this.setState({
      isFetching: true
    }, () => {
      DocumentsController
        .getDocuments({ page: page, search: searchValue, order: `${sortValue}`, ...filters })
        .then(response => {
          const { documents, current_page, total_pages, total_entries } = response;

          this.setState({
            documents: [...documents],
            currentPage: current_page,
            totalPages: total_pages,
            didInitialLoad: true,
            isFetching: false
          });
        })
        .catch(console.error)
    });
  }


  onNewDocumentsClick() {
    const { documents } = this.state

    this.props.showUploadDocumentsModal({
      onSubmit: (newDocuments) => {
        this.setState({ documents: [...newDocuments, ...documents] })
      }
    })
  }

  onTableDocumentClick(document: Document) {
    this.props.showDocumentModal({
      document: { id: document.id },
      onSubmit: this.onDocumentUpdateFormSubmit
    })
  }

  onTableDocumentActionClick(key: string, document: Document) {
    switch (key) {
      case 'edit': this.onTableDocumentClick(document)
        break
      case 'delete': this.onTableDocumentDeleteClick(document)
        break
      default:
        throw Error('[Track] Unimplemented onTableDocumentActionClick')
    }
  }

  onTableDocumentDeleteClick(document: Document) {
    const { documents } = this.state
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Documents::Delete document'),
      description: t('Documents::You are about to delete a document. This document will be permanently deleted. Are you sure?'),
      action: { label: t('Documents::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          await DocumentsController.delete(document.id)

          this.setState({
            documents: documents.filter(c => c.id !== document.id)
          })
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onTableSelectionChange(selectedDocumentIds: string[]) {
    this.setState({ selectedDocumentIds: selectedDocumentIds })
  }

  onTableRowSelectionChange(selected: boolean, documentId: string) {
    const { selectedDocumentIds } = this.state

    if (selected) {
      this.setState({ selectedDocumentIds: [...selectedDocumentIds, documentId] })
    } else {
      this.setState({ selectedDocumentIds: selectedDocumentIds.filter(selectedDocumentId => selectedDocumentId !== documentId) })
    }
  }

  onDocumentFormSubmit(document: Document) {
    const { documents } = this.state;

    const newDocuments = [document, ...documents];

    this.setState({ documents: newDocuments });
  }

  onDocumentUpdateFormSubmit(document: Document) {
    const { documents } = this.state

    const documentIndex = documents.findIndex(c => c.id === document.id);

    if (documentIndex !== -1) {
      documents[documentIndex] = document
    }

    this.setState({
      documents: [
        ...documents,
      ]
    })
  }

  onDocumentFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchDocuments(1)
    })
  }

  onDocumentSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.DOCUMENTS_SORT_VALUE, value)
    this.setState({ sortValue: value }, () => {
      this.fetchDocuments(1)
    })
  }

  onDocumentSearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onDocumentSearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchDocuments(1))
  }

  onDocumentClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchDocuments(1))
  }

  onDocumentBulkDownloadActionClick() {
    const { selectedDocumentIds } = this.state
    const { documents } = this.state

    documents
      .filter(p => selectedDocumentIds.includes(p.id))
      .map(document => {
        if (document.attachment_download_url) saveAs(document.attachment_download_url, document.attachment_file_name)
      })

    this.setState({ selectedDocumentIds: [] })
  }

  onDocumentBulkDeleteActionClick() {
    const { t } = this.props
    const { selectedDocumentIds, documents } = this.state

    this.props.showConfirmModal({
      title: t('Documents::Bulk document deletion'),
      description: t('Documents::You are about to delete these documents. By deleting these documents you are also deleting all its associated data. Are you sure?'),
      action: { label: t('Documents::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await DocumentsController.deleteAll(selectedDocumentIds)
          if (response.errors) { }
          else {
            const newDocuments = documents.filter(p => !selectedDocumentIds.includes(p.id));

            this.setState({ documents: [...newDocuments], selectedDocumentIds: [] });

            Notification.notifySuccess(t('Documents::Documents successfully deleted'))
          }

        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onExportDocumentsClick() {
    this.props.showExportDocumentsModal({})
  }

  onDeliverByEmailClick() {
    const { t } = this.props
    const email = DocumentHelper.getDocumentMailboxEmail()

    this.props.showShareLinksModal({
      title: t('Documents::Deliver by email'),
      shareableLinks: [
        { content: email, url: email }
      ]
    })
  }

  render() {
    const { t, currentUser } = this.props
    const { setting } = currentUser.workspace
    const {
      documents,
      selectedDocumentIds,
      didInitialLoad,
      isFetching,
      filters,
      sortValue,
      searchValue,
      currentPage,
      totalPages
    } = this.state

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0
    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'download-circle', content: t('Documents::Download'), onAction: this.onDocumentBulkDownloadActionClick },
      { icon: 'trash-alt-solid', content: t('Documents::Delete'), onAction: this.onDocumentBulkDeleteActionClick, destructive: true }
    ]

    return (
      <>
        <Helmet>
          <title>{t('Documents::{{__appName}} | Documents')}</title>
        </Helmet>

        <TopNavigation
          icon='copy'
          title={t('Documents::Documents')}
          action={<a key='new-contact' href='javascript://' className='button button-primary page-action' onClick={this.onNewDocumentsClick}>
            <Icon icon='plus' />
            {t('Documents::New document')}
          </a>}
        />

        <ScrollToTopOnMount />

        <PageContent className='is-documents'>
          <PageHeader
            title={t('Documents::Documents')}
            mainActions={[
              { key: 'export', icon: 'download-circle', content: t('Documents::Export documents'), onClick: this.onExportDocumentsClick, visible: UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.DOCUMENT_EXPORT) },
              { key: 'deliver_by_email', icon: 'email', content: t('Documents::Deliver by email'), onClick: this.onDeliverByEmailClick },
            ]}
          />
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ResourceTable
            data={documents}
            headers={[
              { title: t('Documents::Name') },
              { title: '' },
              { title: t('Documents::Tags') },
              { title: t('Documents::Date'), align: 'right' },
              { title: t('Documents::Created on'), align: 'right' },
              { title: '', stickyRight: '0px' },
            ]}
            onSelectionChange={this.onTableSelectionChange}
            promotedBulkActions={promotedBulkActions}
            selectedItems={selectedDocumentIds}
            renderRow={(document: Document) => {
              return (
                <ResourceTableRow
                  key={document.id}
                  selected={selectedDocumentIds.includes(document.id)}
                  onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, document.id)}
                >
                  <ResourceTableRowData onClick={() => this.onTableDocumentClick(document)} maxWidth='150px' ellipse title={document.name}>
                    {document?.name}
                  </ResourceTableRowData>

                  <ResourceTableRowData onClick={() => this.onTableDocumentClick(document)} maxWidth='150px' ellipse>
                    <MetadataIcons>
                      {document.description?.length > 0 && <ModalFooterActionIcon
                        icon='notes'
                        tooltip={document.description}
                        active={false}
                      />}
                      {Boolean(document.booked_on) && <ModalFooterActionIcon
                        icon='book'
                        tooltip={t('Documents::Booked')}
                        active={true}
                      />}
                    </MetadataIcons>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableDocumentClick(document)}>
                    {document.tags?.length > 0 && <Tags>
                      {document?.tags.map(tag => {
                        return (
                          <Badge
                            key={tag.id}
                            type='grey'
                            text={tag.name}
                          />
                        )
                      })}
                    </Tags>}
                    {document?.tags?.length === 0 && '-'}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableDocumentClick(document)} textAlign='right'>
                    {document.date ? moment(document.date).format(setting.date_format) : '-'}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableDocumentClick(document)} textAlign='right'>
                    {moment(document.created_at).format(setting.date_format)}
                  </ResourceTableRowData>
                  <ResourceTableRowActions
                    actions={[
                      { key: 'edit', icon: 'edit-solid', content: t('Documents::Edit') },
                      { key: 'delete', icon: 'trash-alt-solid', content: t('Documents::Delete'), destructive: true }
                    ]}
                    onActionClick={(key) => this.onTableDocumentActionClick(key, document)}
                    sticky={true}
                    stickyRight='0px'
                  />
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'copy'}
              description={filtersActive ? t('Documents::No documents found') : t('Documents::No documents have been added yet')}
              descriptionActionText={filtersActive ? t('Documents::Clear filters') : t('Documents::Add document')}
              onDescriptionActionClick={filtersActive ? this.onDocumentClearFilters : this.onNewDocumentsClick}
            />}
            filters={[
              { name: 'name', label: t('Documents::Name'), type: ResourceListFilterType.STRING },
              { name: 'tag_ids', label: t('Documents::Tags'), type: ResourceListFilterType.MULTI_RESOURCE, resourceType: 'document_tag' },
              { name: 'date', label: t('Documents::Date'), type: ResourceListFilterType.STRING },
              { name: 'created_at', label: t('Documents::Created date'), type: ResourceListFilterType.DATE },
            ]}
            onFiltersChange={this.onDocumentFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('Documents::Name (A-Z)'), value: 'counterpart_name_asc' },
              { label: t('Documents::Name (Z-A)'), value: 'counterpart_name_desc' },
              { label: t('Documents::Document date ↑'), value: 'date_asc' },
              { label: t('Documents::Document date ↓'), value: 'date_desc' },
              { label: t('Documents::Created at ↑'), value: 'created_at_asc' },
              { label: t('Documents::Created at ↓'), value: 'created_at_desc' },
            ]}
            sortValue={sortValue}
            onSortChange={this.onDocumentSortValueChange}
            pagination={{ page: currentPage, pageCount: totalPages }}
            onPageChange={(page) => this.fetchDocuments(page)}
            isLoading={isFetching}
            stickyHeader={true}
            searchValue={searchValue}
            onSearchChange={this.onDocumentSearchChange}
            onSearchSubmit={this.onDocumentSearchSubmit}
            maxHeight='55vh'
          />}
        </PageContent >
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showUploadDocumentsModal: (options) => dispatch(showUploadDocumentsModal(options)),
    showDocumentModal: (options) => dispatch(showDocumentModal(options)),
    showShareLinksModal: (options) => dispatch(showShareLinksModal(options)),
    showExportDocumentsModal: (options) => dispatch(showExportDocumentsModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Documents))