import * as React from 'react'
import copy from 'copy-to-clipboard';
import { saveAs } from 'file-saver'
import { withTranslation, WithTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { FilesController } from '../../controllers'
import { showAttachmentsViewerModal, showConfirmModal, showFileModal, showMoveFilesModal } from '../../store/modals/actions'
import { Style } from '../../styles'
import Notification from '../../utilities/Notification'
import { IActionListItem } from '../ActionList/ActionList'
import PageLoader from '../Page/PageLoader'
import ReactDropzone, { DropEvent, FileRejection } from 'react-dropzone'
import ButtonPanel from '../Button/ButtonPanel'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { BizzeyFile, BizzeyFileResourceType, BizzeyFileType, CurrentUser, ResourceListFilterType } from '../../types';
import Icon from '../Icons/Icon';
import ResourceTable, { ResourceTableAction } from '../Resource/ResourceTable';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import ResourceTableRow from '../Resource/ResourceTableRow';
import ResourceTableRowData from '../Resource/ResourceTableRowData';
import moment from '../../utilities/Moment';
import ResourceTableRowActions from '../Resource/ResourceTableRowActions';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import prettyBytes from 'pretty-bytes';

const Header = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

const Container = styled.div`
	position: relative;
  flex-direction: column;
  display: flex;
	flex: 1;
`

const FilebrowserBreadcrumbs = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${Style.spacing.x1};
  width: 100%;
  flex-wrap: wrap;
  flex: 1;
  margin-top: 12px;
  grid-gap: 4px;
`

const FilebrowserBreadcrumbSeperator = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #9EA5B1;
  }

  i {
    font-size: 12px;
    color: #9EA5B1;
  }
`

const FilebrowserBreadcrumb = styled.li<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #838993;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  margin-right: 4px;

  &:first-child {
    padding-left: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`

const FileWithName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const FileIcon = styled.div <{ folder: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  margin-right: 4px;

  i {
    font-size: 12px;
  }

  svg {
    width: 12px;
  }

  ${props => props.folder && css`
    svg {
      width: 18px;
    }
  `}
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showFileModal: typeof showFileModal
  showMoveFilesModal: typeof showMoveFilesModal
  showConfirmModal: typeof showConfirmModal
  showAttachmentsViewerModal: typeof showAttachmentsViewerModal
}

type IProps = {
  resourceType: BizzeyFileResourceType
  resourceId: string
  maxHeight?: string
  disableActions?: boolean
} & IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  selectedFileIds: string[]
  files: BizzeyFile[]
  breadCrumbs: BizzeyFile[]
  resourceType?: BizzeyFileResourceType
  resourceId?: string
  currentPage: number,
  totalPages: number
  filters: any
  searchValue: string
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
}

class FileBrowser extends React.Component<IProps, IState> {
  private triggerFileUpload = () => { }

  constructor(props: IProps) {
    super(props)

    this.state = {
      files: [],
      selectedFileIds: [],
      breadCrumbs: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      resourceType: props.resourceType,
      resourceId: props.resourceId,
      filters: {},
      searchValue: '',
      sortValue: '-'
    }


    this.getCurrentFolder = this.getCurrentFolder.bind(this)
    this.getParentFileId = this.getParentFileId.bind(this)
    this.fetchFiles = this.fetchFiles.bind(this)
    this.copyFileLink = this.copyFileLink.bind(this)
    this.onRootBreadCrumbClick = this.onRootBreadCrumbClick.bind(this)
    this.onBreadCrumbClick = this.onBreadCrumbClick.bind(this)
    this.onToggleAllFiles = this.onToggleAllFiles.bind(this)
    this.onToggleSelectFile = this.onToggleSelectFile.bind(this)
    this.onFileDrop = this.onFileDrop.bind(this)
    this.onFolderFileDrop = this.onFolderFileDrop.bind(this)
    this.onNewFolderClick = this.onNewFolderClick.bind(this)
    this.onNewFileClick = this.onNewFileClick.bind(this)
    this.onMoveFilesClick = this.onMoveFilesClick.bind(this)
    this.onItemClick = this.onItemClick.bind(this)
    this.onEditFileClick = this.onEditFileClick.bind(this)
    this.onFileFormSubmit = this.onFileFormSubmit.bind(this)
    this.onItemActionClick = this.onItemActionClick.bind(this)
    this.onFileClearFiltersChange = this.onFileClearFiltersChange.bind(this)
    this.onFileFiltersChange = this.onFileFiltersChange.bind(this)
    this.onFileSearchValueChange = this.onFileSearchValueChange.bind(this)
    this.onFileSearchSubmit = this.onFileSearchSubmit.bind(this)
    this.onSortValueChange = this.onSortValueChange.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onResourceBulkDownloadActionClick = this.onResourceBulkDownloadActionClick.bind(this)
    this.onResourceBulkDeleteActionClick = this.onResourceBulkDeleteActionClick.bind(this)
  }

  componentDidMount(): void {
    this.fetchFiles(1)
  }


  getCurrentFolder() {
    const { breadCrumbs } = this.state

    return breadCrumbs.length > 0 ? breadCrumbs[breadCrumbs.length - 1] : null
  }

  getParentFileId() {
    const currentFolder = this.getCurrentFolder()

    return currentFolder ? currentFolder.id : null
  }

  async fetchFiles(page: number) {
    const {
      isFetching,
      resourceId,
      resourceType,
      selectedFileIds,
      filters,
      sortValue,
      searchValue
    } = this.state
    const parentFileId = this.getParentFileId()

    if (!isFetching) {
      try {
        this.setState({ isFetching: true })

        const params = {
          page: page,
          resource_type: resourceType ? resourceType : null,
          resource_id: resourceId ? resourceId : null,
          search: searchValue,
          order: `${sortValue}`,
          ...filters
        }

        if (parentFileId) {
          params['parent_file_id'] = parentFileId
        } else if (searchValue?.length === 0) {
          params['parent_file_id[present]'] = false
        } else {
          // If not in a folder and searching with search value
          // |> Search in all of the files with given search value
        }

        const response = await FilesController.getFiles(params)
        const { files, current_page, total_pages } = response;

        this.setState({
          selectedFileIds: selectedFileIds,
          files: [...files],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false
        });
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  downloadFile(file: BizzeyFile) {
    saveAs(file.attachment_download_url, file.attachment_file_name)
  }

  copyFileLink(file: BizzeyFile) {
    const { t } = this.props

    copy(file.attachment_url)
    Notification.notifySuccess(t('FileBrowserItem::Copied to clipboard'))
  }

  onRootBreadCrumbClick() {
    this.setState({ breadCrumbs: [] }, () => this.fetchFiles(1))
  }

  onBreadCrumbClick(file: BizzeyFile) {
    const { breadCrumbs } = this.state

    if (breadCrumbs.some(breadcrumb => breadcrumb.id === file.id)) {
      const breadcrumbIndex = breadCrumbs.findIndex(breadcrumb => breadcrumb.id === file.id)

      if (breadcrumbIndex !== -1) {
        this.setState({
          breadCrumbs: breadCrumbs.slice(0, breadcrumbIndex + 1),
          selectedFileIds: []
        }, () => this.fetchFiles(1))
      }
    } else {
      this.setState({
        breadCrumbs: [...breadCrumbs, file]
      }, () => this.fetchFiles(1))
    }
  }

  onToggleAllFiles() {
    const { files, selectedFileIds } = this.state
    const selectableFiles = files
    const selectedFileCount = selectedFileIds.length

    if (selectedFileCount > 0 && selectedFileCount === selectedFileCount) {
      this.setState({ selectedFileIds: [] })
    } else {
      this.setState({ selectedFileIds: selectableFiles.map(file => file.id) })
    }
  }

  onToggleSelectFile(file: BizzeyFile) {
    const { selectedFileIds } = this.state
    const fileIndex = selectedFileIds.findIndex(selectedFileId => selectedFileId === file.id)

    if (fileIndex !== -1) {
      selectedFileIds.splice(fileIndex, 1);
      this.setState({ selectedFileIds: [...selectedFileIds] })
    } else {
      this.setState({ selectedFileIds: [...selectedFileIds, file.id] })
    }
  }

  onFileDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { files, resourceType, resourceId } = this.state
    const parentFileId = this.getParentFileId()

    acceptedFiles.forEach(async (file: any) => {
      try {
        const newFile: BizzeyFile = {
          type: BizzeyFileType.FILE,
          name: file.name,
          parent_file_id: parentFileId,
          resource_id: resourceId,
          resource_type: resourceType,
          attachment: file,
          attachment_content_type: file.type,
        }

        const responseFile = await FilesController.create(newFile)

        if (responseFile.errors) return

        if (responseFile.parent_file_id === parentFileId) {
          this.setState({ files: [responseFile, ...this.state.files] })
        }
      } catch (ex) {
        console.error(ex)
      }
    })
  }

  onFolderFileDrop(folder: BizzeyFile, acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { resourceType, resourceId } = this.state

    acceptedFiles.forEach(async (file: any) => {
      try {
        const newFile: BizzeyFile = {
          type: BizzeyFileType.FILE,
          name: file.name,
          parent_file_id: folder.id,
          resource_id: resourceId,
          resource_type: resourceType,
          attachment: file,
          attachment_content_type: file.type,
        }

        await FilesController.create(newFile)
      } catch (ex) {
        console.error(ex)
      }
    })
  }

  onNewFolderClick() {
    const { resourceId, resourceType } = this.state
    const parentFileId = this.getParentFileId()

    this.props.showFileModal({
      file: {
        name: '',
        type: BizzeyFileType.DIRECTORY,
        parent_file_id: parentFileId,
        resource_id: resourceId,
        resource_type: resourceType
      },
      onSubmit: this.onFileFormSubmit
    })
  }

  onNewFileClick() {
    if (this.triggerFileUpload) this.triggerFileUpload()
  }

  onMoveFilesClick() {
    const { selectedFileIds } = this.state

    this.props.showMoveFilesModal({
      fileIds: selectedFileIds,
    })
  }

  onItemClick(file: BizzeyFile) {
    const { t } = this.props
    const { breadCrumbs } = this.state

    if (file.type === BizzeyFileType.DIRECTORY) {
      this.setState({
        breadCrumbs: [...breadCrumbs, file],
        selectedFileIds: [],
        searchValue: '',
      }, () => this.fetchFiles(1))
    } else {
      this.props.showAttachmentsViewerModal({
        attachments: [
          {
            id: file.id,
            name: file.attachment_file_name,
            url: file.attachment_url,
            file_size: file.attachment_file_size,
            content_type: file.attachment_content_type,
            created_at: file.created_at
          }
        ],
        actions: [
          {
            icon: 'download-circle',
            text: t('FileBrowser::Download'),
            onClick: (index) => {
              this.downloadFile(file)
            }
          },
          {
            icon: 'link',
            text: t('FileBrowser::Copy link'),
            onClick: (index) => {
              this.copyFileLink(file)
            }
          },
        ]
      })
    }
  }

  onEditFileClick(file: BizzeyFile) {
    this.props.showFileModal({
      file: { id: file.id, },
      onSubmit: this.onFileFormSubmit,
    })
  }

  onFileFormSubmit(file: BizzeyFile) {
    const { breadCrumbs, files } = this.state
    const breadCrumbIndex = breadCrumbs.findIndex(breadCrumb => breadCrumb.id === file.id)
    const fileIndex = files.findIndex(stateFile => stateFile.id === file.id)

    if (fileIndex !== -1) {
      files[fileIndex] = file

      if (breadCrumbIndex !== -1) {
        breadCrumbs[breadCrumbIndex] = file
      }

      this.setState({
        files: [...files],
        breadCrumbs: [...breadCrumbs],
      })
    } else {
      this.setState({
        files: [file, ...files]
      })
    }
  }

  onItemActionClick(key: string, clickedFile: BizzeyFile) {
    const { t } = this.props
    const { files, selectedFileIds } = this.state

    switch (key) {
      case 'copy-link':
        this.copyFileLink(clickedFile)
        break
      case 'download': this.downloadFile(clickedFile)
        break
      case 'edit': this.onEditFileClick(clickedFile)
        break
      case 'delete':
        this.props.showConfirmModal({
          title: t('FileBrowser::File deletion'),
          description: t('FileBrowser::You are about to delete some <b>files</b>. When deleting a file you are also deleting all associated files to that file. Are you sure?'),
          action: { label: t('FileBrowser::Delete'), isDestructive: true },
          onConfirm: () => {
            FilesController.delete(clickedFile.id).catch(console.error)

            if (clickedFile.type === BizzeyFileType.DIRECTORY) {
              Notification.notifySuccess(t('FileBrowser::Folder successfully deleted'))
            } else {
              Notification.notifySuccess(t('FileBrowser::File successfully deleted'))
            }

            this.setState({
              selectedFileIds: selectedFileIds.filter(selectedFile => selectedFile !== clickedFile.id),
              files: files.filter(file => file.id !== clickedFile.id)
            })
          }
        })
        break
    }
  }

  onFileClearFiltersChange() {
    this.setState({
      filters: {},
      searchValue: ''
    }, () => this.fetchFiles(1))
  }

  onFileFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchFiles(1)
    })
  }

  onFileSearchValueChange(value: string) {
    this.setState({ searchValue: value })
  }

  onFileSearchSubmit(value: string) {
    this.setState({ searchValue: value }, () => this.fetchFiles(1))
  }

  onSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.FILE_SORT_VALUE, value)
    this.setState({
      sortValue: value
    }, () => {
      this.fetchFiles(1)
    })
  }

  onTableSelectionChange(selectedFileIds: string[]) {
    this.setState({ selectedFileIds: selectedFileIds })
  }

  onTableRowSelectionChange(selected: boolean, fileId: string) {
    const { selectedFileIds } = this.state

    if (selected) {
      this.setState({
        selectedFileIds: [
          ...selectedFileIds,
          fileId
        ]
      })
    } else {
      this.setState({
        selectedFileIds: selectedFileIds.filter(selectedProjectId => selectedProjectId !== fileId)
      })
    }
  }

  onResourceBulkDownloadActionClick() {
    const { selectedFileIds } = this.state
    const { files } = this.state

    files
      .filter(p => selectedFileIds.includes(p.id))
      .forEach(file => {
        if (file.attachment_download_url) saveAs(file.attachment_download_url, file.attachment_file_name)
      })
  }

  onResourceBulkDeleteActionClick() {
    const { t } = this.props
    const { selectedFileIds, files } = this.state

    this.props.showConfirmModal({
      title: t('FileBrowser::Bulk file deletion'),
      description: t('FileBrowser::You are about to delete these files. By deleting these files you are also deleting all its associated data. Are you sure?'),
      action: { label: t('FileBrowser::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await FilesController.deleteAll(selectedFileIds)
          if (response.errors) { }
          else {
            const newFiles = files.filter(p => !selectedFileIds.includes(p.id));

            this.setState({ files: [...newFiles], selectedFileIds: [] });

            Notification.notifySuccess(t('FileBrowser::Files successfully deleted'))
          }

        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  render() {
    const { maxHeight, t, currentUser: { workspace } } = this.props
    const { setting } = workspace
    const {
      files,
      selectedFileIds,
      breadCrumbs,
      didInitialLoad,
      currentPage,
      isFetching,
      searchValue,
      totalPages,
      filters,
      sortValue
    } = this.state
    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0
    const currentFolder = this.getCurrentFolder()

    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'download-circle', content: t('FileBrowser::Download'), onAction: this.onResourceBulkDownloadActionClick },
      { icon: 'trash-alt-solid', content: t('FileBrowser::Delete'), onAction: this.onResourceBulkDeleteActionClick, destructive: true }
    ]

    return (
      <Container>
        {!didInitialLoad && <PageLoader />}
        {didInitialLoad && <>
          <Header>
            {breadCrumbs.length > 0 && <FilebrowserBreadcrumbs>
              <FilebrowserBreadcrumb key='bizzey' onClick={breadCrumbs.length > 0 ? this.onRootBreadCrumbClick : undefined} active={breadCrumbs.length === 0}>
                {t('FileBrowser::Home')}
              </FilebrowserBreadcrumb>
              <FilebrowserBreadcrumbSeperator>
                <Icon icon='chevron-right' />
              </FilebrowserBreadcrumbSeperator>
              {breadCrumbs.map((breadCrumb, index) => {
                const active = currentFolder.id === breadCrumb.id
                const isLast = breadCrumbs.length - 1 === index
                return (
                  <>
                    <FilebrowserBreadcrumb key={breadCrumb.id} onClick={() => this.onBreadCrumbClick(breadCrumb)} active={active}>
                      {breadCrumb.name}
                    </FilebrowserBreadcrumb>
                    {!isLast && <FilebrowserBreadcrumbSeperator key={index}>
                      <Icon icon='chevron-right' />
                    </FilebrowserBreadcrumbSeperator>}
                  </>
                )
              })}
            </FilebrowserBreadcrumbs>}
          </Header>

          <ResourceTable
            headers={[
              { title: t('FileBrowser::Name') },
              { title: t('FileBrowser::Size'), align: 'right' },
              { title: t('FileBrowser::Last modified'), align: 'right' },
              { title: '', stickyRight: '0px' },
            ]}
            promotedBulkActions={promotedBulkActions}
            onSelectionChange={this.onTableSelectionChange}
            selectedItems={selectedFileIds}
            actionsLeft={[
              <ReactDropzone
                onDrop={this.onFileDrop}
                multiple={true}
              >
                {({ getInputProps, open, }) => {
                  this.triggerFileUpload = open

                  return (<>
                    <input {...getInputProps()} />
                    <ButtonPanel
                      icon='plus'
                      text={t('FileBrowser::Upload file')}
                      onClick={open}
                    />
                  </>)

                }}
              </ReactDropzone>,
              <ButtonPanel
                icon='folder'
                text={t('FileBrowser::Add folder')}
                onClick={this.onNewFolderClick}
              />
            ]}
            data={files}
            renderRow={(file: BizzeyFile, index: number) => {
              let actions: IActionListItem[] = [
                { key: 'copy-link', icon: 'link', content: t('FileBrowser::Copy link'), visible: file.type === BizzeyFileType.FILE },
                { key: 'download', icon: 'download-circle', content: t('FileBrowser::Download'), visible: file.type === BizzeyFileType.FILE },
                { key: 'edit', icon: 'edit-solid', content: t('FileBrowser::Edit') },
                { key: 'delete', icon: 'trash-alt-solid', content: t('FileBrowser::Delete'), destructive: true }
              ]

              return (
                <ResourceTableRow
                  key={file.id}
                  selected={selectedFileIds.includes(file.id)}
                  onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, file.id)}
                >
                  <ResourceTableRowData onClick={() => this.onItemClick(file)} ellipse>
                    <FileWithName title={file.name}>
                      <FileIcon folder={file.type === BizzeyFileType.DIRECTORY}>
                        <Icon icon={file.type === BizzeyFileType.DIRECTORY ? 'folder' : 'file-alt'} />
                      </FileIcon>
                      {file.name}
                    </FileWithName>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onItemClick(file)} textAlign='right'>
                    {file?.attachment_file_size ? prettyBytes(file.attachment_file_size) : '-'}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onItemClick(file)} textAlign='right'>
                    {moment(file.updated_at).format(setting.date_format)}
                  </ResourceTableRowData>

                  <ResourceTableRowActions
                    actions={actions}
                    onActionClick={(key) => this.onItemActionClick(key, file)}
                    sticky={true}
                    stickyRight='0px'
                  />
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : currentFolder ? 'folder' : 'files'}
              description={filtersActive ? t('FileBrowser::No files found') : (currentFolder ? t('FileBrowser::This folder is empty') : t('FileBrowser::No files have been uploaded yet'))}
              descriptionActionText={filtersActive ? t('FileBrowser::Clear filters') : t('FileBrowser::Upload file')}
              onDescriptionActionClick={filtersActive ? this.onFileClearFiltersChange : this.onNewFileClick}
            />}
            filters={[
              { name: 'name', label: t('FileBrowser::Name'), type: ResourceListFilterType.STRING },
              { name: 'type', label: t('FileBrowser::Type'), type: ResourceListFilterType.MULTIPLE_OPTION, options: [{ label: t('FileBrowser::File'), value: BizzeyFileType.FILE }, { label: t('FileBrowser::Folder'), value: BizzeyFileType.DIRECTORY }] },
              { name: 'updated_at', label: t('FileBrowser::Last modified'), type: ResourceListFilterType.DATE },
              { name: 'created_at', label: t('FileBrowser::Created'), type: ResourceListFilterType.DATE },
            ]}
            onFiltersChange={this.onFileFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('FileBrowser::Name (A-Z)'), value: 'name_asc' },
              { label: t('FileBrowser::Name (Z-A)'), value: 'name_desc' },
              { label: t('FileBrowser::Created at ↑'), value: 'created_at_asc' },
              { label: t('FileBrowser::Created at ↓'), value: 'created_at_desc' },
            ]}
            pagination={{ page: currentPage, pageCount: totalPages }}
            onPageChange={(page) => this.fetchFiles(page)}
            searchValue={searchValue}
            onSearchChange={this.onFileSearchValueChange}
            onSearchSubmit={this.onFileSearchSubmit}
            sortValue={sortValue}
            onSortChange={this.onSortValueChange}
            isLoading={isFetching}
            stickyHeader={true}
            maxHeight={maxHeight}
          />
        </>}
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showFileModal: (options) => dispatch(showFileModal(options)),
    showMoveFilesModal: (options) => dispatch(showMoveFilesModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showAttachmentsViewerModal: (options) => dispatch(showAttachmentsViewerModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBrowser))