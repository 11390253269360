import * as React from "react";
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Style } from "../../styles";
import Icon from "../Icons/Icon";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};
  background: white;
  flex: 1;
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  margin-right: ${Style.spacing.x1};
  cursor: pointer;
  overflow: hidden;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Style.spacing.x1};
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${Style.color.border};
  flex: 1;
`;

const AvatarContainer = styled.div`
  cursor: pointer;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  flex: 1;
  cursor: pointer;

  &:not(:last-child) {
    border-right: 1px solid ${Style.color.border};
  }

  &:hover {
    background: #f6f6f7;
  }

  i,
  svg {
    margin-right: 8px;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #82828c;
  }

  i {
    color: #82828c;
  }
`;

const Avatar = styled.div`
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid transparent;
	transition: border-color .3s;
	border-radius: 7px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
	overflow: hidden;
	color: white;
  background-color: #63778b;

  svg, i {
    color: white;
    fill: white;
    font-size: 28px;
  }
`

type IProps = { onAddContactClick?: () => void } & RouteComponentProps
const ContactAddCard = (props: IProps) => {
  const { t } = useTranslation();
  const { onAddContactClick } = props;

  return (
    <Container onClick={onAddContactClick}>
      <ContactInfo>
        <AvatarContainer>
          <Avatar>
            <Icon icon='plus' />
          </Avatar>
        </AvatarContainer>
      </ContactInfo>

      <Actions>
        <Action>
          {t("Contact::Add contact")}
        </Action>
      </Actions>
    </Container>
  );
};

export default withRouter(ContactAddCard);
