import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import PageHeaderNavigation from '../components/Page/PageHeaderNavigation'
import PageHeaderNavigationLeft from '../components/Page/PageHeaderNavigationLeft'
import ButtonPanel from '../components/Button/ButtonPanel'
import { WithTranslation, withTranslation } from 'react-i18next'
import Icon from '../components/Icons/Icon'
import { Moment } from 'moment'
import moment from '../utilities/Moment'
import PlannerMeter from '../components/Planner/PlannerMeter'
import PlannerAvatar from '../components/Planner/PlannerAvatar'
import { PlannerController } from '../controllers'
import { PlannedTask, PlannerData, PlannerDate, PlannerDateItem, Task, User } from '../types'
import PageLoader from '../components/Page/PageLoader'
import PageHeaderNavigationRight from '../components/Page/PageHeaderNavigationRight'
import ButtonGroup, { ButtonGroupItem } from '../components/Button/ButtonGroup'
import PlannerSidebar from '../components/Planner/PlannerSidebar'
import { DragDropContext, DragStart, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd'
import PlannerHelper from '../helpers/PlannerHelper'
import PlannerItem from '../components/Planner/PlannerItem'
import { connect } from 'react-redux'
import { AppState } from '../store'
import { showCalendarEventModal, showConfirmModal, showTaskModal } from '../store/modals/actions'
import { t } from 'i18next'

const TaskViewContainer = styled.div`
	margin: ${Style.spacing.x2} ${Style.spacing.x2} 0 ${Style.spacing.x2};
`

const TasksNavigationContainer = styled(PageHeaderNavigation)`
	margin-bottom: ${Style.spacing.x1_25};

	> div {
		> * {
			margin: 4px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
`

const PlannerContainer = styled.div`
  width: 100%;
  overflow: auto;
  border: 1px solid ${Style.color.border};
  padding-bottom: 2px;
  max-height: 77vh;

  @media screen and (max-width: 768px) {
    max-height: 72vh;
  }
`

const PlannerWrapper = styled.div`
  min-width: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
`

const HeaderNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 72px;
  min-width: 240px;
  width: 240px;
  background: rgba(238,238,238,1);
  padding: 8px;
  border-right: 1px solid ${Style.color.border};
  border-bottom: 1px solid ${Style.color.border};
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 2;
`

const HeaderNavigationLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: ${Style.variables.baseBorderRadius};
  cursor: pointer;

  &:hover {
    background: #82828c2e;
  }
`

const HeaderNavigationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  span {
    &:first-child {
      color: black;
    }
    
    &:last-child {
      font-size: 12px;
      color: rgb(130, 130, 140);
    }
  }
`
const HeaderNavigationRight = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: ${Style.variables.baseBorderRadius};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #82828c2e;
  }
`

const HeaderSchedule = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeaderMonths = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`

const HeaderMonth = styled.div<{ border: boolean, first?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  font-weight: 700;
  letter-spacing: .6px;
  padding: 8px;
  background-color: white;
  color: black;
  width: 110px;
  min-width: 110px;
  flex: 1;

  ${props => props.border && css`
    border-right: 1px solid ${Style.color.border};
  `}

  ${props => props.first && css`
    position: sticky;
    left: 240px;
    z-index: 1;
  `}
`

const HeaderDays = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 100%;
`

const HeaderDay = styled.div`
  width: 110px;
  min-width: 110px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(29, 30, 28, 0.7);
  font-size: 12px;
  font-weight: 500;
  border-top: 1px solid ${Style.color.border};
  border-bottom: 1px solid ${Style.color.border};
  background: rgba(238,238,238,1);
`

const UserRows = styled.div`
  display: flex;
  flex-direction: column;
`

const UserPlanning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-width: 240px;
  width: 240px;
  padding: 8px;
  border-right: 1px solid ${Style.color.border};
  background: white;
  position: sticky;
  cursor: pointer;
  left: 0;
  border-bottom: 1px solid ${Style.color.border};
`

const UserPlanningContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
`

const UserPlanningName = styled.div`
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const UserPlanningTime = styled.div<{ overbooked?: boolean }>`
  font-size: 12px;
  font-weight: 500;

  ${props => props.overbooked && css`
    color: ${Style.color.brandDanger};
    font-weight: bold;
  `}
`

const UserPlanningToggle = styled.div`
  margin-right: 4px;

  svg {
    width: 14px;
    height: 14px;
  }

  i {
    font-size: 14px;
  }
`

const UserDayPlanningHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 72px;
  padding: 0 8px;
  cursor: pointer;

  > span {
    font-size: 12px;
    font-weight: 500;
  }
`


const UserDayPlanningContent = styled.div<{ active?: boolean }>`
  display: none;
  flex-direction: column;
  gap: 2px;
  height: 286px;
  max-height: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2px 2px;
  transition: max-height 0.3s ease-in-out;

  ${props => props.active && css`
    max-height: 286px;
  `}
`

const UserDayPlanning = styled.div<{ timeOff?: boolean, isDraggingOver?: boolean }>`
  width: 110px;
  min-width: 110px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${Style.color.border};
  border-bottom: 1px solid ${Style.color.border};
  background: white;
  
  &:last-child {
    border-right: none;
  }


  ${props => props.timeOff && css`
    background: repeating-linear-gradient(-45deg, white, #e4e4e6 1px, white 1px, white 9px);
  `}

  ${props => props.isDraggingOver && css`
    box-shadow: 0 0 0 2px #0079bf;
    z-index: 1;
  `}
`

const UserRow = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;

  > div {
    &:first-child {
      z-index: 1;
    }
  }
  
  ${props => props.active && css`
    ${UserPlanning} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    ${UserDayPlanningHeader} {
      border-bottom: 1px solid ${Style.color.border};
    }

    ${UserDayPlanningContent} {
      display: flex;
    }

    ${UserPlanningContent} {
      align-items: center;
    }

    ${UserPlanningToggle} {
      position: absolute;
      top: 12px;
      right: 8px;

      svg {
        rotate: 180deg;
      }
    }
  `}

  &:last-child {
    ${UserPlanning} {
      border-bottom: none;
    }

    ${UserDayPlanning} {
      border-bottom: none;
    }
  }
`

export enum PlannerViewMode {
  WEEK = 'week',
  MONTH = 'month'
}

interface IStateToProps {
  currentUser: User
}

interface IDispatchToProps {
  showTaskModal: typeof showTaskModal
  showConfirmModal: typeof showConfirmModal
  showCalendarEventModal: typeof showCalendarEventModal
}

type IProps = {} & WithTranslation & IStateToProps & IDispatchToProps
interface IState {
  start: Moment
  end: Moment
  viewMode: PlannerViewMode
  didInitialLoad: boolean
  planning: PlannerData
  activeUserIds: string[]
  plannerSidebarActive: boolean
  activeDraggingState: {
    task: Task
    user: User
    date: PlannerDate
  },
  tasks: Task[]
}

class Planner extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      start: moment().startOf('week'),
      end: moment().endOf('week'),
      viewMode: PlannerViewMode.WEEK,
      didInitialLoad: false,
      planning: null,
      activeUserIds: [],
      plannerSidebarActive: false,
      activeDraggingState: null,
      tasks: []
    }

    this.onPlanTasksClick = this.onPlanTasksClick.bind(this)
    this.onCloseSidebarClick = this.onCloseSidebarClick.bind(this)
    this.onTasksChange = this.onTasksChange.bind(this)
    this.onCreateTaskClick = this.onCreateTaskClick.bind(this)
    this.onRangeChange = this.onRangeChange.bind(this)
    this.onPreviousPeriodClick = this.onPreviousPeriodClick.bind(this)
    this.onNextPeriodClick = this.onNextPeriodClick.bind(this)
    this.onToggleActiveUser = this.onToggleActiveUser.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onUserDayPlanningMouseOver = this.onUserDayPlanningMouseOver.bind(this)
    this.onUserDayPlanningMouseLeave = this.onUserDayPlanningMouseLeave.bind(this)
    this.onRemovePlannedTaskClick = this.onRemovePlannedTaskClick.bind(this)
    this.onItemClick = this.onItemClick.bind(this)
    this.onCreatePlannedTask = this.onCreatePlannedTask.bind(this)
    this.onUpdatePlannedTaskClick = this.onUpdatePlannedTaskClick.bind(this)
  }

  componentDidMount(): void {
    this.fetchPlanning()
  }

  async fetchPlanning() {
    const { start, end } = this.state

    try {
      const response = await PlannerController.getPlanning({
        start: moment(start).utc().toISOString(),
        end: moment(end).utc().toISOString(),
      })

      this.setState({
        planning: response,
        didInitialLoad: true
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  getWeekRangeLabel() {
    const { t } = this.props
    const { start, end } = this.state

    const timeDifferenceInWeeks = end.diff(start, 'week')

    if (timeDifferenceInWeeks >= 1) {
      return `${t('Planner::Week')} ${start.format('W')} - ${end.format('W')}`
    } else {
      return `${t('Planner::Week')} ${start.format('W')}`
    }
  }

  onPlanTasksClick() {
    this.setState({ plannerSidebarActive: true })
  }

  onCloseSidebarClick() {
    this.setState({ plannerSidebarActive: false })
  }

  onTasksChange(tasks: Task[]) {
    this.setState({ tasks: tasks })
  }

  onCreateTaskClick() {
    this.props.showTaskModal({
      task: {
        time_estimate: 3600
      },
      onSubmit: () => {


      },
      onDelete: () => {

      }
    })
  }

  onRangeChange(viewMode: PlannerViewMode) {
    const { start } = this.state

    this.setState({
      start: moment(start).startOf(viewMode),
      end: moment(start).endOf(viewMode),
      viewMode: viewMode,
    }, this.fetchPlanning)
  }

  onPreviousPeriodClick() {
    const { start, end, viewMode } = this.state

    this.setState({
      start: moment(start).subtract(1, viewMode),
      end: moment(end).subtract(1, viewMode),
    }, this.fetchPlanning)
  }

  onNextPeriodClick() {
    const { start, end, viewMode } = this.state

    this.setState({
      start: moment(start).add(1, viewMode),
      end: moment(end).add(1, viewMode),
    }, this.fetchPlanning)
  }

  onToggleActiveUser(userId: string) {
    const { activeUserIds } = this.state

    if (activeUserIds.includes(userId)) {
      this.setState({
        activeUserIds: activeUserIds.filter(id => id !== userId)
      })
    } else {
      this.setState({
        activeUserIds: [...activeUserIds, userId]
      })
    }
  }

  onDragStart(initial: DragStart, provided: ResponderProvided) {
    const { plannerSidebarActive, tasks } = this.state

    if (plannerSidebarActive) this.setState({ plannerSidebarActive: false })

    if (initial?.draggableId) {
      const task = tasks.find(task => task.id === initial.draggableId)

      if (task) {
        this.setState({
          activeDraggingState: {
            task: task,
            user: null,
            date: null
          }
        })
      }
    }
  }

  async onDragEnd(result: DropResult, provided: ResponderProvided) {
    const { activeDraggingState, planning } = this.state

    this.setState({ activeDraggingState: null })

    if (
      activeDraggingState &&
      activeDraggingState.task &&
      activeDraggingState.user &&
      activeDraggingState.date
    ) {
      const { activeDraggingState: { task, user, date } } = this.state
      const remainingTimeInDay = PlannerHelper.getRemainingTime(date)

      const userRowIndex = planning.findIndex(p => p.user.id === user.id)
      const userRowDateIndex = planning[userRowIndex].dates.findIndex(d => d.day === date.day)

      // If there is time left in the day, schedule the task
      if (remainingTimeInDay > 0) {
        const plannedTasks = PlannerHelper.planTaskForUser(task, user, date)

        // Plan task for user can return multiple tasks if the task overlaps lunch time for example
        plannedTasks.forEach(async plannedTask => {
          await this.onCreatePlannedTask(userRowIndex, userRowDateIndex, plannedTask.id, plannedTask)
        })
      } else { // No time left but allow the user the overschedule with a warning
        requestAnimationFrame(() => {
          this.props.showConfirmModal({
            title: t('Planner::Overschedule'),
            description: t('Planner::There is no time left in the day to schedule this task. Would you like to overschedule?'),
            action: {
              label: t('Planner::Overschedule'),
            },
            onConfirm: async () => {
              const plannedTasks = PlannerHelper.planTaskForUser(task, user, date)

              plannedTasks.forEach(async plannedTask => {
                await this.onCreatePlannedTask(userRowIndex, userRowDateIndex, plannedTask.id, plannedTask)
              })
            },
          })
        })
      }

      return
    }
  }

  onUserDayPlanningMouseOver(user: User, date: PlannerDate) {
    const { activeDraggingState } = this.state

    // If we are dragging a task and it's available in the state, update the active dragging state
    if (activeDraggingState?.task && activeDraggingState?.user === null && activeDraggingState?.date === null) {
      this.setState({
        activeDraggingState: {
          ...activeDraggingState,
          user: user,
          date: date,
        }
      })
    }
  }

  onUserDayPlanningMouseLeave() {
    const { activeDraggingState } = this.state

    if (activeDraggingState && activeDraggingState.user && activeDraggingState.date) {
      this.setState({
        activeDraggingState: {
          ...activeDraggingState,
          user: null,
          date: null,
        }
      })
    }
  }

  async onCreatePlannedTask(userRowIndex: number, userRowDateIndex: number, tempId: string, plannedTask: PlannedTask) {
    try {
      const { planning } = this.state
      // Add the planned task to the planning with a temp id
      planning[userRowIndex].dates[userRowDateIndex].items = [
        ...planning[userRowIndex].dates[userRowDateIndex].items,
        {
          type: 'planned_task',
          id: plannedTask.id,
          title: plannedTask?.task?.name,
          start_at: plannedTask?.start_at,
          end_at: plannedTask?.end_at,
          duration: plannedTask.duration,
          task_id: plannedTask?.task_id,
        }
      ]

      this.setState({ planning: [...planning] })

      const result = await PlannerController.create(plannedTask)

      if (!result.errors) {
        const responsePlannedTask = result

        const itemIndex = planning[userRowIndex].dates[userRowDateIndex].items.findIndex(i => i.id === tempId)

        if (itemIndex !== -1) {
          planning[userRowIndex].dates[userRowDateIndex].items[itemIndex] = {
            type: 'planned_task',
            id: responsePlannedTask.id,
            title: responsePlannedTask?.task?.name,
            start_at: responsePlannedTask?.start_at,
            end_at: responsePlannedTask?.end_at,
            duration: responsePlannedTask.duration,
            task_id: responsePlannedTask?.task_id,
          }

          this.setState({
            plannerSidebarActive: true,
            planning: [...planning]
          })
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onItemClick(item: PlannerDateItem) {
    switch (item.type) {
      case 'calendar_event':
        this.props.showCalendarEventModal({
          calendarEvent: { id: item.id },
          readOnly: true
        })
        break
    }
  }

  async onUpdatePlannedTaskClick(rowIndex: number, columnIndex: number, itemIndex: number, item: PlannerDateItem, plannedTask: PlannedTask) {
    try {
      const { planning } = this.state

      planning[rowIndex].dates[columnIndex].items[itemIndex] = {
        ...item,
        duration: plannedTask.duration,
        end_at: plannedTask.end_at,
      }

      this.setState({ planning: [...planning] })

      const response = await PlannerController.update(plannedTask)

      planning[rowIndex].dates[columnIndex].items[itemIndex] = {
        ...item,
        ...response,
      }

      this.setState({ planning: [...planning] })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onRemovePlannedTaskClick(rowIndex: number, columnIndex: number, itemIndex: number, item: PlannerDateItem) {
    const { id } = item
    try {
      const { planning } = this.state

      delete planning[rowIndex].dates[columnIndex].items[itemIndex]

      planning[rowIndex].dates[columnIndex].items = planning[rowIndex].dates[columnIndex].items.filter(i => i !== null)

      this.setState({
        planning: [...planning]
      })

      const response = await PlannerController.delete(id)
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const { t, currentUser } = this.props
    const { workspace: { setting: { date_format } } } = currentUser
    const {
      start,
      end,
      didInitialLoad,
      planning,
      viewMode,
      activeUserIds,
      plannerSidebarActive,
      activeDraggingState
    } = this.state

    const dates = planning ? planning[0].dates : []

    const rangeOptions: ButtonGroupItem[] = [
      { element: t('Planner::Week'), onClick: () => this.onRangeChange(PlannerViewMode.WEEK), active: viewMode === PlannerViewMode.WEEK },
      { element: t('Planner::Month'), onClick: () => this.onRangeChange(PlannerViewMode.MONTH), active: viewMode === PlannerViewMode.MONTH },
    ]

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
        <TaskViewContainer>
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <>
            <TasksNavigationContainer>
              <PageHeaderNavigationLeft>
                <ButtonPanel
                  icon='plus'
                  text={t('TaskView::Plan tasks')}
                  onClick={this.onPlanTasksClick}
                />
              </PageHeaderNavigationLeft>

              <PageHeaderNavigationRight>
                <ButtonGroup
                  items={rangeOptions}
                />
              </PageHeaderNavigationRight>
            </TasksNavigationContainer>

            <PlannerContainer>
              <PlannerWrapper style={{ width: dates.length * 110 }}>
                <Header>
                  <HeaderNavigation>
                    <HeaderNavigationLeft onClick={this.onPreviousPeriodClick}>
                      <Icon icon='chevron-left' />
                    </HeaderNavigationLeft>

                    <HeaderNavigationContent>
                      <span>
                        {`${start.format('DD')} - ${end.format('DD MMM YYYY')}`}
                      </span>

                      <span>
                        {this.getWeekRangeLabel()}
                      </span>
                    </HeaderNavigationContent>

                    <HeaderNavigationRight onClick={this.onNextPeriodClick}>
                      <Icon icon='chevron-right' />
                    </HeaderNavigationRight>
                  </HeaderNavigation>

                  <HeaderSchedule>
                    <HeaderMonths>
                      {dates.map(({ day }, index) => {
                        const dayMoment = moment(day)
                        const firstDayOfMonth = dayMoment.date() === 1
                        const lastDayOfMonth = dayMoment.date() === dayMoment.daysInMonth()
                        const lastEntry = dates.length - 1 === index

                        return (
                          <HeaderMonth key={index} border={lastDayOfMonth && !lastEntry} first={index === 0 || firstDayOfMonth}>
                            {(index === 0 || firstDayOfMonth) ? dayMoment.format('MMMM YYYY') : ''}
                          </HeaderMonth>
                        )
                      })}
                    </HeaderMonths>
                    <HeaderDays>
                      {dates.map(({ day }, index) => (
                        <HeaderDay
                          key={index}>
                          {moment(day).format('ddd DD')}
                        </HeaderDay>
                      ))}
                    </HeaderDays>
                  </HeaderSchedule>
                </Header>

                <UserRows>
                  {planning.map((planningItem, rowIndex) => {
                    const { user, dates } = planningItem
                    const active = activeUserIds.includes(user.id)

                    let totalScheduled = dates.reduce((total, date) => total + PlannerHelper.getScheduledTime(date), 0)
                    const totalAvailability = dates.reduce((availability, date) => availability + PlannerHelper.getAvailableTime(date), 0)

                    if (activeDraggingState?.user?.id === user.id && activeDraggingState?.date && activeDraggingState?.task) {
                      const { date, task } = activeDraggingState
                      const taskSchedulingTime = PlannerHelper.getTaskSchedulingTime(date, task)

                      // Attempt to schedule a task partially for the day
                      if (PlannerHelper.isOverbooked(date)) {
                        totalScheduled += PlannerHelper.getTaskOverschedulingTime(task)
                      } else if (totalAvailability > totalScheduled) {
                        totalScheduled += taskSchedulingTime
                      }
                    }

                    const overbooked = totalAvailability < totalScheduled

                    return (
                      <UserRow key={user.id} active={active}>
                        <UserPlanning onClick={() => this.onToggleActiveUser(user.id)}>
                          <PlannerAvatar
                            active={active}
                            name={user.name}
                            availableTime={totalAvailability}
                            scheduledTime={totalScheduled}
                          />
                          <UserPlanningContent>
                            <UserPlanningName>{user.name}</UserPlanningName>
                            <UserPlanningTime overbooked={overbooked}>
                              {moment.duration(totalScheduled, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small' })}/
                              {moment.duration(totalAvailability, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small' })}
                            </UserPlanningTime>
                          </UserPlanningContent>

                          <UserPlanningToggle>
                            <Icon icon='chevron-down' />
                          </UserPlanningToggle>
                        </UserPlanning>
                        {dates.map((date, index) => {
                          const { availability, items } = date
                          const columnIndex = index

                          const isDraggingOver = (
                            activeDraggingState &&
                            activeDraggingState.task &&
                            activeDraggingState?.user?.id === user.id &&
                            activeDraggingState.date.day === date.day
                          )

                          let scheduled = PlannerHelper.getScheduledTime(date)

                          if (isDraggingOver && activeDraggingState?.date && activeDraggingState?.task) {
                            const { date, task } = activeDraggingState

                            const taskSchedulingTime = PlannerHelper.getTaskSchedulingTime(date, task)

                            // Attempt to schedule a task partially for the day
                            if (availability > scheduled) {
                              scheduled += taskSchedulingTime
                            } else { // When available time is fully planned show overbooked state
                              scheduled += PlannerHelper.getTaskOverschedulingTime(task)
                            }
                          }

                          const baseAvailability = Math.max(availability, scheduled)
                          const baseHeight = 286

                          return (
                            <Droppable key={index} droppableId={`${user.id}:${date.day}`}>
                              {(provided, droppableSnapshot) => {
                                return (
                                  <UserDayPlanning
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    timeOff={availability === 0}
                                    isDraggingOver={isDraggingOver}
                                    onMouseOver={() => this.onUserDayPlanningMouseOver(user, date)}
                                    onMouseLeave={() => this.onUserDayPlanningMouseLeave()}
                                  >
                                    <UserDayPlanningHeader onClick={() => this.onToggleActiveUser(user.id)}>
                                      <span>
                                        {moment.duration(scheduled, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small' })}/
                                        {moment.duration(availability, 'seconds').format(`h[${t('Planner::h')}]mm`, { trim: 'small', })}
                                      </span>
                                      <PlannerMeter
                                        availableTime={availability}
                                        scheduledTime={scheduled}
                                      />
                                    </UserDayPlanningHeader>
                                    <UserDayPlanningContent active={active}>
                                      {items.map((item, itemIndex) => {
                                        const itemDuration = item.duration
                                        const itemHeight = (itemDuration / baseAvailability) * baseHeight

                                        return (
                                          <PlannerItem
                                            key={`${item.id}:${itemIndex}`}
                                            height={itemHeight}
                                            item={item}
                                            dateFormat={date_format}
                                            onItemClick={this.onItemClick}
                                            onUpdateClick={(plannerDateItem, plannedTask) => this.onUpdatePlannedTaskClick(rowIndex, columnIndex, itemIndex, plannerDateItem, plannedTask)}
                                            onRemoveClick={(plannerDateItem) => this.onRemovePlannedTaskClick(rowIndex, columnIndex, itemIndex, plannerDateItem)}
                                          />
                                        )
                                      })}
                                      {provided.placeholder}
                                    </UserDayPlanningContent>
                                  </UserDayPlanning>
                                )
                              }}
                            </Droppable>
                          )
                        })}
                      </UserRow>
                    )
                  })}
                </UserRows>
              </PlannerWrapper>
            </PlannerContainer>
          </>}
        </TaskViewContainer>
        <PlannerSidebar
          active={plannerSidebarActive}
          onCloseClick={this.onCloseSidebarClick}
          onTasksChange={this.onTasksChange}
          onCreateTaskClick={this.onCreateTaskClick}
        />
      </DragDropContext>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const { authentication: { currentUser } } = state
  return {
    currentUser: currentUser
  }
}

const mapDispatchToProps: IDispatchToProps = {
  showTaskModal,
  showConfirmModal,
  showCalendarEventModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Planner))