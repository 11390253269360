import '../translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { saveAs } from 'file-saver'
import { DEFAULT_LOCALE, SENTRY_DSN } from '../Constants'
import { Helmet } from 'react-helmet'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import BlocksRenderer, { BlockRenderMode } from '../components/BlockEditor/BlocksRenderer'
import BlocksWrapper from '../components/BlockEditor/BlocksWrapper'
import styled from 'styled-components'
import { SignAction } from '../components/BlockEditor/RenderBlocks/SignBlock'
import { useTranslation } from 'react-i18next'
import { Style } from '../styles'
import IconButtonGroup from '../components/Button/IconButtonGroup'
import ReactTooltip from 'react-tooltip'
import AwardSuccess from '../components/Awards/AwardSuccess'
import AppLoader from '../components/Loaders/AppLoader'
import ProposalHelper from '../helpers/ProposalHelper'
import SignatureModal from '../components/Modals/SignatureModal'
import { ProposalsController } from '../controllers'
import ContentBlockHelper from '../helpers/ContentBlockHelper'
import i18next from 'i18next'
import { ContentBlockVariables, ItemsBlock, Locale, NumberFormat, Proposal, ProposalStatus, Signature, SignatureResult, Workspace } from '../types'
import ContactPortal from '../components/ContactPortal/ContactPortal'
import ContactBlocksContainer from '../components/ContactPortal/ContactBlocksContainer'

Sentry.init({ dsn: SENTRY_DSN })

const ActionsBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  color: black;
  border-bottom: 1px solid ${Style.color.border};
  background-color: white;
  box-shadow: 0 0 16px 0 #13304213;
  padding: ${Style.spacing.x0_5};

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    ${SignAction} {
      width: 100%;
      text-align: center;
    }
  }

  @media print {
    display: none;
  }
`

interface IProps {
  workspace: Workspace
  proposal: Proposal
  variables: ContentBlockVariables
  numberFormat: NumberFormat
  currency: string
  locale: Locale
}

const Application = (props: IProps) => {
  const { workspace } = props
  const [proposal, setProposal] = React.useState(props.proposal)
  const { t } = useTranslation()
  const award = React.useRef<AwardSuccess>(null)
  const [showSignatureModal, setShowSignatureModal] = React.useState(false)
  const proposalSignable = ProposalHelper.signable(proposal)
  const urlSearchParams = new URLSearchParams(window.location.search);
  // Digital key that identifies the user
  const signKey = urlSearchParams.get('key')
  let unsignedUserSignature: Signature | null = ContentBlockHelper.getUnsignedUserSignature(proposal.signatures, signKey)

  const userSignEnabled = proposalSignable && unsignedUserSignature
  const selectionAndQuantityEditable = ContentBlockHelper.getSignedSignatures(proposal.signatures).length === 0

  React.useEffect(() => {
    i18next.changeLanguage(proposal?.contact?.locale || DEFAULT_LOCALE, (err, t) => { });
  }, [])

  const onDownloadClick = () => {
    saveAs(`/p/${proposal.id}/download`)
  }

  const onSignClick = () => {
    setShowSignatureModal(true)
  }

  const onSignatureModalClose = () => {
    setShowSignatureModal(false)
  }

  const onSignatureModalSubmit = async (signature: SignatureResult) => {
    try {

      const response = await ProposalsController.sign(proposal.id, signature, signKey)
      setProposal(response)

      if (award && award.current) award.current.show()
    } catch (ex) {
      console.error(ex)
    } finally {
      onSignatureModalClose()
    }
  }

  const onItemsBlockItemSelectionChange = async (itemsBlock: ItemsBlock, itemIndex: number) => {
    try {
      const updatedItemsBlock: ItemsBlock = {
        ...itemsBlock,
        items: ContentBlockHelper.getSelectionChangeItems(itemsBlock, itemIndex)
      }
      const response = await ProposalsController.contactItemsBlockUpdate(proposal.id, signKey, updatedItemsBlock)

      setProposal(response)
    } catch (ex) {
      console.error(ex)
    }
  }

  const renderActionbar = () => {
    if (ProposalHelper.expired(proposal)) {
      return (
        <ActionsBar>
          <p style={{ textAlign: 'center' }}>
            {t('Proposal::This proposal is expired.')}
          </p>
        </ActionsBar>
      )
    }
    else if (proposal.status === ProposalStatus.DRAFT) {
      return (
        <ActionsBar>
          <p style={{ textAlign: 'center' }}>
            {t('Proposal::This proposal is a draft')}
          </p>
        </ActionsBar>
      )
    }

    return null
  }

  const getActions = () => {
    let actions: React.ReactNode[] = []

    actions.push(
      <IconButtonGroup
        buttons={[
          {
            icon: <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm-32-316v116h-67c-10.7 0-16 12.9-8.5 20.5l99 99c4.7 4.7 12.3 4.7 17 0l99-99c7.6-7.6 2.2-20.5-8.5-20.5h-67V140c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12z"></path></svg>,
            tooltip: t('Proposal::Download'),
            onClick: onDownloadClick
          }
        ]}
      />)

    if (userSignEnabled) {
      actions.push(
        <SignAction onClick={onSignClick}>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="signature" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z"></path></svg>
          {t('Proposal::Approve')}
        </SignAction>
      )
    }

    return actions
  }

  return (
    <>
      <ContactPortal
        companyName={workspace?.business_name}
        title={proposal?.name || t('Proposal::Proposal')}
        actions={getActions()}
      >
        <Helmet>
          <title>{proposal?.name || t('Proposal::Proposal')}</title>
        </Helmet>

        <ScrollToTopOnMount />

        {renderActionbar()}

        <ContactBlocksContainer>
          <BlocksWrapper className='preview'>
            <BlocksRenderer
              renderMode={BlockRenderMode.EDITOR}
              blocks={proposal.content_blocks}
              variables={props.variables}
              currency={props.currency}
              numberFormat={props.numberFormat}
              signKey={signKey}
              signatures={proposal.signatures}
              onSignClick={userSignEnabled ? onSignClick : undefined}
              onItemsBlockItemSelectionChange={selectionAndQuantityEditable ? (itemsBlock, itemIndex) => onItemsBlockItemSelectionChange(itemsBlock, itemIndex) : undefined}
            />
          </BlocksWrapper>
        </ContactBlocksContainer>
      </ContactPortal>

      <ReactTooltip effect='solid' />
      <SignatureModal
        show={showSignatureModal}
        onSubmit={onSignatureModalSubmit}
        onClose={onSignatureModalClose}
      />
      <AwardSuccess ref={award} />
    </>
  );
}


const ApplicationProvider = (props: IProps) => {
  return (
    <React.Suspense fallback={<AppLoader />}>
      <Application {...props} />
    </React.Suspense>
  )
}

export default ApplicationProvider