import styled from "styled-components";

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 15px 0;
  color: black;

  &:first-child {
    margin-top: 0;
  }
`

export default Title