import * as React from 'react'
import styled from 'styled-components'
import Loader from '../Loaders/Loader'

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	z-index: 1;
	padding: 2px;
	padding-right: 10px;
	padding-left: 10px;
`

export default () => (<Container><Loader size='small' /></Container>)