import * as React from 'react'
import Icon, { IconTypes } from '../Icons/Icon'

interface IButtonOption {

}

interface IProps {
	text: string
	icon: IconTypes
	buttonClasses?: string
	style?: any
	disabled?: boolean
	dataTip?: string
}

interface IState {
	isOpen: boolean
}

export default class DropdownButton extends React.Component<IProps, IState> {
	static defaultProps = {
		text: '',
		buttonClasses: 'button button-default',
		style: {}
	}

	private wrapper = React.createRef<HTMLDivElement>()

	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: false
		};

		this.onClickOutsideComponent = this.onClickOutsideComponent.bind(this);
		this.onDropdownButtonClick = this.onDropdownButtonClick.bind(this);
		this.onChildClick = this.onChildClick.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onClickOutsideComponent);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onClickOutsideComponent);
	}

	onClickOutsideComponent(e) {
		if (this.wrapper.current && !this.wrapper.current.contains(e.target)) {
			this.closeDropdown();
		}
	}

	closeDropdown() {
		this.setState({
			isOpen: false
		});
	}

	onDropdownButtonClick(e) {
		e.preventDefault();

		const { isOpen } = this.state;

		this.setState({
			isOpen: !isOpen
		});
	}

	onChildClick(e) {
		e.preventDefault();

		this.setState({
			isOpen: false
		});
	}

	render() {
		const { isOpen } = this.state;
		const { text, icon, buttonClasses, style, disabled, dataTip } = this.props;

		const childrenWithProps = React.Children.map(this.props.children, (child) => {
			// @ts-ignore
			return React.cloneElement(child, { onComponentClick: this.onChildClick });
		});

		return (
			<div ref={this.wrapper} className={`dropdown-button is-up ${isOpen ? 'is-active' : ''}`} data-tip={dataTip}>
				<a href="javascript://" className={`${buttonClasses} ${disabled ? 'is-disabled' : ''}`} style={style} onClick={this.onDropdownButtonClick}>
					{icon && <Icon icon={icon} />}
					{text}
				</a>

				{!Boolean(disabled) && <div className="dropdown-menu">
					<div className="dropdown-menu-arrow">
						<Icon icon='chrevron-down' width="20" height="11" />
					</div>
					<ul className="dropdown-menu-list">
						{childrenWithProps}
					</ul>
				</div>}
			</div>
		);
	}
}