import * as React from 'react'
import { Manager, Popper, Reference, usePopper } from 'react-popper'
import styled from 'styled-components'
import { Style } from '../../styles'
import { Locale } from '../../types'

const Container = styled.div`
	position: relative;
`

const Button = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	font-weight: 700;
	color: #555a64;
	text-align: center;
	vertical-align: middle;
	background-color: transparent;
	border: 2px solid transparent;
	padding: 0.625rem 1.5rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 100px;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	color: #2c3038;
	padding: 0.34375rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 100px;
	border-color: rgba(44,48,56,.25);
	cursor: pointer;

	&:hover {
		background-color: rgba(44,48,56,.2);
    border-color: rgba(0,0,0,0);
    color: #2c3038;
	}
`

const Flag = styled.img`
	border-radius: 50%;
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	margin-right: 8px;

	svg {
		overflow: hidden;
	}
`

const Dropdown = styled.div`
	z-index: 1000;
	min-width: 10rem;
	padding: 1rem 0;
	margin: 0.125rem 0 0;
	font-size: 1.25rem;
	color: #555a64;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #eaedf2;
	border-radius: 0.3125rem;
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 9%);
`

const DropdownHeader = styled.div`
	display: block;
	padding: 1rem 1.5rem;
	margin-bottom: 0;
	font-size: 1.09375rem;
	color: #2c3038;
	white-space: nowrap;
	padding-top: 0;
	padding-bottom: 0.5rem;
	font-weight: 700;
	line-height: 1.2;
	color: #2c3038;
`

const DropdownItem = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: rgba(85,90,100,.6);
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	font-weight: 700;
	transition: all .2s ease-in-out;
	cursor: pointer;

	&:hover {
		color: ${Style.color.brandPrimary};
	}
`

interface IProps {
	locale: Locale
}

const LanguagePicker = (props: IProps) => {
	const [locale, setLocale] = React.useState<Locale>(props.locale)
	const [dropdownActive, setDropdownActive] = React.useState(false)
	const [referenceElement, setReferenceElement] = React.useState(null);
	const [popperElement, setPopperElement] = React.useState(null);
	const [arrowElement, setArrowElement] = React.useState(null);

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom',
		modifiers: [
			{ name: 'arrow', options: { element: arrowElement } },
			{ name: 'offset', options: { offset: [0, 4] } },
		],
	});

	const onToggleDropdown = () => {
		setDropdownActive(!dropdownActive)
	}

	const getLanguage = (locale: Locale) => {
		switch (locale) {
			case Locale.EN: return 'English'
			case Locale.NL: return 'Dutch'
			case Locale.DE: return 'German'
			case Locale.FR: return 'French'
		}
	}

	const getFlag = (locale: Locale): string => {
		switch (locale) {
			case Locale.EN: return 'en.svg'
			case Locale.NL: return 'nl.svg'
			case Locale.DE: return 'de.svg'
			case Locale.FR: return 'fr.svg'
		}
	}

	const onItemClick = (locale: Locale) => {
		setDropdownActive(false)

		const url = new URL(window.location.toString());
		url.searchParams.set('locale', locale);

		window.location.replace(url.toString());
	}

	return (
		<Container>
			<Button ref={setReferenceElement} onClick={onToggleDropdown}>
				<Flag src={`/images/flags/${getFlag(locale)}`} />
				{getLanguage(locale)}
			</Button>

			{dropdownActive && <Dropdown ref={setPopperElement} style={styles.popper} {...attributes.popper}>
				<DropdownHeader>
					Language
				</DropdownHeader>

				<DropdownItem onClick={() => onItemClick(Locale.EN)}>
					<Flag src={`/images/flags/${getFlag(Locale.EN)}`} />
					{getLanguage(Locale.EN)}
				</DropdownItem>

				<DropdownItem onClick={() => onItemClick(Locale.NL)}>
					<Flag src={`/images/flags/${getFlag(Locale.NL)}`} />
					{getLanguage(Locale.NL)}
				</DropdownItem>

				<DropdownItem onClick={() => onItemClick(Locale.FR)}>
					<Flag src={`/images/flags/${getFlag(Locale.FR)}`} />
					{getLanguage(Locale.FR)}
				</DropdownItem>

				<DropdownItem onClick={() => onItemClick(Locale.DE)}>
					<Flag src={`/images/flags/${getFlag(Locale.DE)}`} />
					{getLanguage(Locale.DE)}
				</DropdownItem>

				<div ref={setArrowElement} style={styles.arrow} />
			</Dropdown>}
		</Container >
	)
}

export default LanguagePicker