import * as React from 'react'
import styled from 'styled-components'
import FileHelper from '../../helpers/FileHelper'
import { Style } from '../../styles'

const Cover = styled.div`
  border-radius: 3px 3px 0 0;
  height: 172px;
  background-color: #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-top: -6px;
  margin-left: -${Style.spacing.x1};
  margin-right: -${Style.spacing.x1};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
`

interface IProps {
  cover: string
}

const CardCover = (props: IProps) => {
  const [cover, setCover] = React.useState(null)

  React.useEffect(() => {
    if (cover === null) setCover(props.cover)

    if (cover && props.cover && FileHelper.getFilename(cover) !== FileHelper.getFilename(props.cover)) {
      setCover(props.cover)
    }
  }, [props.cover])

  return <Cover style={{ backgroundImage: `url(${cover})` }} />
}

export default CardCover