import * as React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import TopNavigationButtonWithOptions from '../components/Navigation/TopNavigationButtonWithOptions'
import { AppState } from '../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ERoute from '../ERoute'
import { RouteComponentProps } from 'react-router'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import { showBulkContactGroupModal, showConfirmModal, showContactModal, showExportContactsModal } from '../store/modals/actions'
import PageContent from '../components/Page/PageContent'
import PageHeader from '../components/Page/PageHeader'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Contact, ContactType, CurrentUser, ResourceListFilterType } from '../types'
import LocalStorage, { LocalStorageKey } from '../LocalStorage'
import ResourceTableRow from '../components/Resource/ResourceTableRow'
import ResourceTableRowData from '../components/Resource/ResourceTableRowData'
import ResourceTableRowActions from '../components/Resource/ResourceTableRowActions'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import ResourceTable, { ResourceTableAction } from '../components/Resource/ResourceTable'
import PageLoader from '../components/Page/PageLoader'
import { ContactsController } from '../controllers'
import RouteHelper from '../helpers/RouteHelper'
import Notification from '../utilities/Notification'
import { LOCALES } from '../Constants'
import Icon from '../components/Icons/Icon'
import ContactHelper from '../helpers/ContactHelper'
import styled from 'styled-components'
import ContactTypeAvatar from '../components/Avatar/ContactTypeAvatar'
import { Link } from 'react-router-dom'
import UrlHelper from '../helpers/UrlHelper'
import Badge from '../components/Badge/Badge'

const ContactAvatarAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  >:first-child {
    margin-right: 8px;
  }
`

const ContactNameAndMetadata = styled.div`
  display: flex;
  flex-direction: column;
`

const ContactName = styled(Link)`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  color: black;

  &:hover {
    text-decoration: underline;
  }
`

const ContactMetadata = styled.div`
  display: flex;
  margin-top: -4px;
  color: #333333 !important;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4px;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 4px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

const ContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: #333333 !important;
  align-items: flex-end;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      fill: #829AB1;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

const ContactGroups = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 4px;
`

const StarContact = styled.div<{ starred: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.starred ? '#f4a230' : 'black'};
  margin-left: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
`

interface IStateProps extends RouteComponentProps<{ groupId?: string }> {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showContactModal: typeof showContactModal
  showConfirmModal: typeof showConfirmModal
  showBulkContactGroupModal: typeof showBulkContactGroupModal
  showExportContactsModal: typeof showExportContactsModal
}

interface IState {
  contacts: Contact[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
  filters: any,
  selectedContactIds: string[]
  searchValue: string
}

type IProps = IStateProps & IDispatchToProps & WithTranslation

class Contacts extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { groupId } = UrlHelper.getParams(this.props.location.search)

    let filters = {}

    if (groupId) {
      filters = { group_ids: [groupId] }
    }

    this.state = {
      contacts: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.CONTACT_SORT_VALUE, '-'),
      filters: filters,
      selectedContactIds: [],
      searchValue: ''
    }

    this.onTopNavigationActionClick = this.onTopNavigationActionClick.bind(this)
    this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)
    this.onExportContactsClick = this.onExportContactsClick.bind(this)
    this.onNewContactClick = this.onNewContactClick.bind(this);
    this.onTableContactClick = this.onTableContactClick.bind(this)
    this.onTableActionClick = this.onTableActionClick.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onTableContactArchiveClick = this.onTableContactArchiveClick.bind(this)
    this.onTableContactUpdateClick = this.onTableContactUpdateClick.bind(this)
    this.onTableContactDeleteClick = this.onTableContactDeleteClick.bind(this);
    this.onContactFormSubmit = this.onContactFormSubmit.bind(this)
    this.onContactUpdateFormSubmit = this.onContactUpdateFormSubmit.bind(this)
    this.onContactCreate = this.onContactCreate.bind(this)
    this.onContactFiltersChange = this.onContactFiltersChange.bind(this)
    this.onContactSortValueChange = this.onContactSortValueChange.bind(this)
    this.onContactSearchChange = this.onContactSearchChange.bind(this)
    this.onContactSearchSubmit = this.onContactSearchSubmit.bind(this)
    this.onContactClearFilters = this.onContactClearFilters.bind(this)
    this.onAddGroupsClick = this.onAddGroupsClick.bind(this)
    this.onRemoveGroupsClick = this.onRemoveGroupsClick.bind(this)
    this.onBulkDeleteClick = this.onBulkDeleteClick.bind(this)
  }

  componentWillMount() {
    this.fetchContacts(1)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { groupId: currentGroupId } = UrlHelper.getParams(this.props.location.search)
    const { groupId: previousGroupId } = UrlHelper.getParams(prevProps.location.search)

    if (previousGroupId !== currentGroupId && currentGroupId) {
      this.setState({ filters: { group_ids: [currentGroupId] } }, () => {
        this.fetchContacts(1)
      })
    }
  }

  onTopNavigationActionClick(key: string) {
    const { showContactModal } = this.props
    switch (key) {
      case 'person':
      case 'company':
        showContactModal({
          contact: { type: key as ContactType },
          onSubmit: this.onContactFormSubmit,
        })
        break
      default:
        throw new Error(`[Contacts] action not implemented for key: ${key} `)
    }
  }

  onPageHeaderActionClick(key: string) {
    switch (key) {
      case 'export_contacts': this.onExportContactsClick()
        break
    }
  }

  async onExportContactsClick() {
    this.props.showExportContactsModal({})
  }

  async fetchContacts(page: number) {
    const { searchValue, sortValue, filters } = this.state
    this.setState({
      isFetching: true
    }, async () => {
      try {
        const response = await ContactsController.getContacts({
          page: page,
          search: searchValue,
          order: `${sortValue}`,
          ...filters
        })
        const { contacts, current_page, total_pages, total_entries } = response;

        this.setState({
          contacts: [...contacts],
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
          isFetching: false
        });
      } catch (ex) {
        console.error(ex)
      }
    });
  }

  onNewContactClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    const { showContactModal } = this.props

    showContactModal({
      contact: { type: ContactType.PERSON },
      onSubmit: this.onContactFormSubmit,
    })
  }

  onTableContactClick(contact: Contact) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_CONTACT, { id: contact.id }))
  }

  onTableActionClick(key: string, contact: Contact) {
    switch (key) {
      case 'archive': this.onTableContactArchiveClick(contact)
        break
      case 'update': this.onTableContactUpdateClick(contact)
        break
      case 'delete': this.onTableContactDeleteClick(contact)
        break
      default:
        throw Error('[Contacts] Unimplemented onTableActionClick')
    }
  }

  onTableSelectionChange(selectedContactIds: string[]) {
    this.setState({ selectedContactIds: selectedContactIds })
  }

  onTableRowSelectionChange(selected: boolean, transactionId: string) {
    const { selectedContactIds } = this.state

    if (selected) {
      this.setState({ selectedContactIds: [...selectedContactIds, transactionId] })
    } else {
      this.setState({ selectedContactIds: selectedContactIds.filter(selectedTransactionId => selectedTransactionId !== transactionId) })
    }
  }

  async onTableContactArchiveClick(contact: Contact) {
    try {
      const updatedContact = await ContactsController.update({ ...contact, archived: !contact.archived })

      this.onContactUpdateFormSubmit(updatedContact)
    } catch (ex) {
      console.error(ex)
    }
  }

  onTableContactUpdateClick(contact: Contact) {
    const { showContactModal } = this.props

    showContactModal({
      contact: { id: contact.id, type: contact.type },
      onSubmit: this.onContactUpdateFormSubmit,
    })
  }

  onTableContactDeleteClick(contact: Contact) {
    const { showConfirmModal, t } = this.props

    showConfirmModal({
      title: t('Contact::Delete contact'),
      description: t('Contact::You are about to delete {{name}}. By deleting this contact you are also deleting all its associated data. Are you sure?', { name: contact.name }),
      action: { label: t('Contact::Delete'), isDestructive: true },
      onConfirm: () => {
        ContactsController
          .delete(contact.id)
          .then((response) => {
            if (response.errors) {

            } else {
              const { contacts } = this.state;

              const contactIndex = contacts.findIndex(c => c.id === contact.id);

              contacts.splice(contactIndex, 1);

              this.setState({
                contacts: contacts
              });

              Notification.notifySuccess(t('Contact::Contact successfully deleted'))
            }
          })
          .catch(console.error)
      }
    })
  }

  onContactFormSubmit(contact: Contact) {
    const { contacts } = this.state;

    const newContacts = [contact, ...contacts];

    this.setState({ contacts: newContacts });
  }

  onContactUpdateFormSubmit(contact: Contact) {
    const { contacts } = this.state

    const contactIndex = contacts.findIndex(c => c.id === contact.id);

    if (contactIndex !== -1) {
      contacts[contactIndex] = contact
    }

    this.setState({
      contacts: [
        ...contacts,
      ]
    })
  }

  onContactCreate(data) {
    const { contacts } = this.state
    const { detail: { contact } } = data

    this.setState({
      contacts: [
        contact,
        ...contacts,
      ]
    })
  }

  onContactFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchContacts(1)
    })
  }

  onContactSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.CONTACT_SORT_VALUE, value)
    this.setState({
      sortValue: value
    }, () => {
      this.fetchContacts(1)
    })
  }

  onContactSearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onContactSearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchContacts(1))
  }

  onContactClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchContacts(1))
  }

  onAddGroupsClick() {
    const { selectedContactIds } = this.state

    requestAnimationFrame(() => {
      this.props.showBulkContactGroupModal({
        action: 'add',
        contactIds: selectedContactIds,
        onSubmit: (_contactGroups) => {
          this.setState({ selectedContactIds: [] })
          this.fetchContacts(this.state.currentPage)
        }
      })
    })
  }

  onRemoveGroupsClick() {
    const { selectedContactIds } = this.state

    requestAnimationFrame(() => {
      this.props.showBulkContactGroupModal({
        action: 'remove',
        contactIds: selectedContactIds,
        onSubmit: (_contactGroups) => {
          this.setState({ selectedContactIds: [] })
          this.fetchContacts(this.state.currentPage)
        }
      })
    })
  }

  onBulkDeleteClick() {
    const { showConfirmModal, t } = this.props
    const { contacts, selectedContactIds } = this.state

    showConfirmModal({
      title: t('Contact::Delete contacts'),
      description: t('Contact::You are about to delete {{count}} contacts. By deleting these contacts you are also deleting all their associated data. Are you sure?', { count: selectedContactIds.length }),
      action: { label: t('Contact::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          await ContactsController.bulkDelete(selectedContactIds)

          this.setState({
            selectedContactIds: [],
            contacts: contacts.filter(contact => !selectedContactIds.includes(contact.id))
          })
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  render() {
    const { currentUser, t } = this.props
    const { contacts, didInitialLoad, isFetching, filters, sortValue, searchValue, currentPage, totalPages, selectedContactIds } = this.state
    const { workspace: { setting } } = currentUser

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0
    const promotedBulkActions: ResourceTableAction[] = [
      { icon: 'tag', content: t('Contacts::Add to group'), onAction: this.onAddGroupsClick },
      { icon: 'circle-xmark', content: t('Contacts::Remove from group'), onAction: this.onRemoveGroupsClick },
      { icon: 'trash-alt-solid', content: t('Contacts::Delete'), onAction: this.onBulkDeleteClick, destructive: true }
    ]

    const bulkActions: ResourceTableAction[] = []

    return (
      <>
        <Helmet>
          <title>{t('Contacts::{{__appName}} | Contacts')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <TopNavigation
          icon='user'
          title={t('Contacts::Contacts')}
          action={
            <TopNavigationButtonWithOptions
              icon='plus'
              text={t('Contacts::New contact')}
              actions={[
                { key: 'person', content: t('Contacts::Person'), icon: 'user' },
                { key: 'company', content: t('Contacts::Company'), icon: 'company' },
              ]}
              onActionClick={this.onTopNavigationActionClick}
            />
          }
        />

        <PageContent>
          <PageHeader
            title={t('Contacts::Contacts')}
            mainActions={[
              { key: 'export_contacts', content: t('Contacts::Export contacts'), icon: 'download-circle' },
            ]}
            onMainActionClick={this.onPageHeaderActionClick}
          />

          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ResourceTable
            data={contacts}
            headers={[
              { title: t('Contacts::Name') },
              { title: t('Contacts::Groups') },
              { title: t('Contacts::Email') },
              { title: t('Contacts::Phone number') },
              { title: t('Contacts::Website') },
              { title: '', stickyRight: '0px' },
            ]}
            renderRow={(contact: Contact) => {
              const primaryEmail = ContactHelper.getPrimaryEmail(contact)
              const primaryTelephone = ContactHelper.getPrimaryTelephonenumber(contact)
              const primaryWebsite = ContactHelper.getPrimaryWebsite(contact)

              return (
                <ResourceTableRow
                  key={contact.id}
                  selected={selectedContactIds.includes(contact.id)}
                  onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, contact.id)}
                >
                  <ResourceTableRowData onClick={() => this.onTableContactClick(contact)} maxWidth='500px' ellipse>
                    <ContactAvatarAndName>
                      <ContactTypeAvatar
                        onClick={() => { }}
                        contact={contact}
                        rounded={true}
                        width={40}
                      />
                      <ContactNameAndMetadata>
                        <ContactName
                          to={RouteHelper.process(ERoute.PATH_CONTACT, { id: contact?.id })}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {contact.name}

                          {contact.starred && <StarContact starred={contact.starred}>
                            <Icon icon='star' />
                          </StarContact>}
                        </ContactName>
                        <ContactMetadata>
                          {contact.type === ContactType.PERSON && contact.contact_id && <>
                            {t('Contacts::Works at')} <Link to={RouteHelper.process(ERoute.PATH_CONTACT, { id: contact?.contact.id })} onClick={(e) => e.stopPropagation()}>{contact?.contact?.name}</Link>
                          </>}
                        </ContactMetadata>
                      </ContactNameAndMetadata>
                    </ContactAvatarAndName>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableContactClick(contact)} ellipse>
                    {contact?.groups?.length === 0 && <>-</>}
                    {contact?.groups?.length > 0 && <ContactGroups>
                      {contact.groups.sort((g1, g2) => (g1.name.localeCompare(g2.name))).map(group => (<Badge type='grey' text={group.name} />))}
                    </ContactGroups>}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableContactClick(contact)} ellipse>
                    <ContactDetailsContainer>
                      <ContactDetails>
                        {primaryEmail && <>
                          <a href={`mailto:${primaryEmail.value}`} onClick={(e) => e.stopPropagation()}>
                            <Icon icon='email' />
                            {primaryEmail.value}
                          </a>
                        </>}
                        {!primaryEmail && <a onClick={(e) => {
                          e.stopPropagation()
                          this.onTableContactUpdateClick(contact)
                        }}>
                          -
                        </a>}
                      </ContactDetails>
                    </ContactDetailsContainer>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableContactClick(contact)} ellipse>
                    <ContactDetailsContainer>
                      <ContactDetails>
                        {primaryTelephone && <>
                          <a href={`tel:${primaryTelephone.value}`} onClick={(e) => e.stopPropagation()}>
                            <Icon icon='phone' />
                            {primaryTelephone.value}
                          </a>
                        </>}
                        {!primaryTelephone && <a onClick={(e) => {
                          e.stopPropagation()
                          this.onTableContactUpdateClick(contact)
                        }}>
                          -
                        </a>}
                      </ContactDetails>
                    </ContactDetailsContainer>
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableContactClick(contact)} ellipse>
                    <ContactDetailsContainer>
                      <ContactDetails>
                        {primaryWebsite && <>
                          <a href={primaryWebsite.url} onClick={(e) => e.stopPropagation()}>
                            <Icon icon='globe' />
                            {new URL(primaryWebsite.url).host}
                          </a>
                        </>}
                        {!primaryWebsite && <a onClick={(e) => {
                          e.stopPropagation()
                          this.onTableContactUpdateClick(contact)
                        }}>
                          -
                        </a>}
                      </ContactDetails>
                    </ContactDetailsContainer>
                  </ResourceTableRowData>
                  <ResourceTableRowActions
                    actions={[
                      { key: 'archive', icon: 'archive', content: contact.archived ? t('Contact::Unarchive') : t('Contact::Archive') },
                      { key: 'update', icon: 'edit-solid', content: t('Contact::Edit') },
                      { key: 'delete', icon: 'trash-alt-solid', content: t('Contact::Delete'), destructive: true }
                    ]}
                    onActionClick={(key) => this.onTableActionClick(key, contact)}
                    sticky={true}
                    stickyRight='0px'
                  />
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'user'}
              description={filtersActive ? t('Contact::No contacts found') : t('Contact::No contacts have been created yet')}
              descriptionActionText={filtersActive ? t('Contact::Clear filters') : t('Contact::Add new contact')}
              onDescriptionActionClick={filtersActive ? this.onContactClearFilters : this.onNewContactClick}
            />}
            filters={[
              { name: 'name', label: t('Contact::Name'), type: ResourceListFilterType.STRING },
              { name: 'alias', label: t('Contact::Alias'), type: ResourceListFilterType.STRING },
              { name: 'type', label: t('Contact::Record type'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: ContactHelper.getContactLabelType(ContactType.COMPANY), value: ContactType.COMPANY }, { label: ContactHelper.getContactLabelType(ContactType.PERSON), value: ContactType.PERSON }] },
              { name: 'group_ids', label: t('Contact::Groups'), type: ResourceListFilterType.MULTI_RESOURCE, resourceType: 'contact_group' },
              { name: 'starred', label: t('Contact::Starred'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Contacts::Yes'), value: String(true) }, { label: t('Contacts::No'), value: String(false) }] },
              { name: 'archived', label: t('Contact::Archived'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Contacts::Yes'), value: String(true) }, { label: t('Contacts::No'), value: String(false) }] },
              { name: 'locale', label: t('Contact::Locale'), type: ResourceListFilterType.SINGLE_OPTION, options: LOCALES.map(locale => ({ label: t(`Locales::${locale}`), value: locale })) },
              { name: 'vat_number', label: t('Contact::VAT'), type: ResourceListFilterType.STRING },
              { name: 'hourly_rate', label: t('Contact::Hourly rate'), type: ResourceListFilterType.NUMBER },
              { name: 'day_rate', label: t('Contact::Day rate'), type: ResourceListFilterType.NUMBER },
              { name: 'marketing_consent', label: t('Contact::Marketing consent'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('Contacts::Yes'), value: String(true) }, { label: t('Contacts::No'), value: String(false) }] },
              { name: 'created_at', label: t('Contact::Created date'), type: ResourceListFilterType.DATE },
            ]}
            onFiltersChange={this.onContactFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('Contact::Name (A-Z)'), value: 'name_asc' },
              { label: t('Contact::Name (Z-A)'), value: 'name_desc' },
              { label: t('Contact::Alias (A-Z)'), value: 'alias_asc' },
              { label: t('Contact::Alias (Z-A)'), value: 'alias_desc' },
              { label: t('Contact::Created at ↑'), value: 'created_at_asc' },
              { label: t('Contact::Created at ↓'), value: 'created_at_desc' },
            ]}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            sortValue={sortValue}
            onSortChange={this.onContactSortValueChange}
            pagination={{ page: currentPage, pageCount: totalPages }}
            onPageChange={(page) => this.fetchContacts(page)}
            isLoading={isFetching}
            stickyHeader={true}
            selectedItems={selectedContactIds}
            onSelectionChange={this.onTableSelectionChange}
            searchValue={searchValue}
            onSearchChange={this.onContactSearchChange}
            onSearchSubmit={this.onContactSearchSubmit}
            maxHeight='60vh'
          />}
        </PageContent>
      </>
    )
  }
}

interface IStateToProps {
  currentUser: CurrentUser
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showContactModal: (options) => dispatch(showContactModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showBulkContactGroupModal: (options) => dispatch(showBulkContactGroupModal(options)),
    showExportContactsModal: (options) => dispatch(showExportContactsModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Contacts))