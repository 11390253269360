import * as React from 'react'
import styled from 'styled-components'
import PageLoader from '../components/Page/PageLoader';
import { AppController } from '../controllers/peppol';
import { SubscriptionStatus, Workspace } from '../types';
import Select from '../components/Form/Select';
import Button from '../components/Button/Button';
import Alert from '../components/Alert/Alert';
import Icon from '../components/Icons/Icon';

const AccountNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input:first-child {
    max-width: 100px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  input:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
  }
`

const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100vh;
	overflow: hidden;
	background: white;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px 16px;
	height: 64px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	text-align: center;
	font-size: 20px;
	font-weight: bold;
`

const PreviewContainer = styled.div`
	flex: 1;
  overflow: auto;
`

const PreviewContent = styled.div`
	height: 100%;

  padding: 20px;

  form {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`

const InfoContainer = styled.div`
  flex: 1;
`

const PeppolContainer = styled.div`
  flex: 1;
`

interface IProps { }
interface IState {
  didInitialLoad: boolean
  remainingWorkspacesCount?: number
  workspace: Workspace
  message?: string
  countries: { label: string, value: string }[]
}

export default class Application extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      didInitialLoad: false,
      remainingWorkspacesCount: null,
      workspace: null,
      countries: [],
      message: ''
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onPeppolIdChange = this.onPeppolIdChange.bind(this)
    this.onMessageChange = this.onMessageChange.bind(this)
    this.onApproveClick = this.onApproveClick.bind(this)
    this.onRejectClick = this.onRejectClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    try {
      // Reset data for loading indicator
      this.setState({ workspace: null, remainingWorkspacesCount: null })

      const {
        remaining_workspaces_count,
        workspace,
        countries
      } = await AppController.getForm()

      this.setState({
        remainingWorkspacesCount: remaining_workspaces_count,
        workspace: workspace,
        countries: countries,
        didInitialLoad: true
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onPeppolIdChange(e) {
    const { workspace } = this.state
    const peppolId = e.currentTarget.value

    this.setState({
      workspace: {
        ...workspace,
        peppol_id: peppolId
      }
    })
  }

  onMessageChange(e) {
    const { workspace } = this.state
    const message = e.currentTarget.value

    this.setState({
      message: message
    })
  }

  async onApproveClick() {
    try {
      const { workspace } = this.state
      await AppController.approve(workspace.id, workspace.peppol_id)
      this.fetchForm()
    } catch (ex) {
      console.error(ex)
    }
  }

  async onRejectClick() {
    try {
      try {
        const { workspace, message } = this.state
        await AppController.reject(workspace.id, message)
        this.fetchForm()
      } catch (ex) {
        console.error(ex)
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const {
      remainingWorkspacesCount,
      workspace,
      countries,
      didInitialLoad
    } = this.state

    return (
      <Container>
        <PreviewContainer>
          <Header>
            <>
              <span>
                Peppol approval
              </span>
              {remainingWorkspacesCount && <span>
                {`Remaining: ${remainingWorkspacesCount}`}
              </span>}
            </>
          </Header>

          <PreviewContent>
            {!didInitialLoad && <PageLoader />}
            {didInitialLoad && <>
              {!workspace && <div>No pending peppol requests</div>}
              {workspace && <form>
                <InfoContainer>
                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>Business name <span>*</span></label>
                        <input type='text' name='business_name' value={workspace.business_name} readOnly />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>VAT number <span>*</span></label>
                        <input
                          type='text'
                          name='vat_number'
                          value={workspace.vat_number}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>Account number <span>*</span></label>
                        <AccountNumberContainer>
                          <input
                            type='text'
                            name='bic_code'
                            value={workspace.bic_code}
                            placeholder='GKCCBEBB'
                            readOnly
                          />

                          <input
                            type='text'
                            name='vat_number'
                            value={workspace.account_number}
                            readOnly
                          />
                        </AccountNumberContainer>
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>Email</label>
                        <input
                          type='text'
                          name='email'
                          value={workspace.email}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>Phone number</label>
                        <input
                          type='text'
                          name='phone_number'
                          value={workspace.phone_number}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>Website</label>
                        <input
                          type='text'
                          name='website'
                          value={workspace.website}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>Contact link</label>
                        <input
                          type='text'
                          name='website'
                          value={workspace.appointment_url}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>Street</label>
                        <input
                          type='text'
                          name='street'
                          value={workspace.street}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className='grid'>
                      <div className='grid-cell with-12col'>
                        <div className='grid-cell with-4col'>
                          <div className='form-item'>
                            <label>City</label>
                            <input
                              type='text'
                              name='city'
                              value={workspace.city}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className='grid-cell with-4col'>
                          <div className='form-item'>
                            <label>State / Province</label>
                            <input
                              type='text'
                              name='city'
                              value={workspace.province}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className='grid-cell with-4col'>
                          <div className='form-item'>
                            <label>Postal</label>
                            <input
                              type='text'
                              name='city'
                              value={workspace.zip_code}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className='grid-cell with-12col'>
                          <div className='form-item'>
                            <label>Country</label>
                            <Select
                              name='country_code'
                              value={workspace.country_code}
                              options={countries.map(country => {
                                return {
                                  key: country.value, label: country.label, value: country.value
                                }
                              })}
                              onChange={() => { }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </InfoContainer>

                <PeppolContainer>

                  {!workspace.card && <div className='form-item'>
                    <Alert
                      type='danger'
                      text={<>
                        <span style={{ marginRight: 8 }}>
                          <Icon icon='money-bill' />
                        </span>
                        This workspace has no payment details associated
                      </>}
                    />
                  </div>}

                  <div className='form-item'>
                    <Alert
                      type='danger'
                      text={<>
                        This workspace doesn't have all business details filled in.

                        <br />
                        Missing fields:
                        <br />


                        {workspace.business_name?.length === 0 && <span>Business name</span>} <br />
                        {workspace.vat_number?.length === 0 && <span>VAT number</span>} <br />
                        {workspace.bic_code?.length === 0 && <span>BIC Code</span>} <br />
                        {workspace.account_number?.length === 0 && <span>BIC Code</span>} <br />
                        {workspace.street?.length === 0 && <span>Street</span>} <br />
                        {workspace.city?.length === 0 && <span>City</span>} <br />
                      </>}
                    />
                  </div>

                  {workspace?.subscription?.status === SubscriptionStatus.UNPAID && <div className='form-item'>
                    <Alert
                      type='danger'
                      text={'This workspace has outstanding invoices'}
                    />
                  </div>}

                  <div className='form-item'>
                    <label>Peppol ID</label>
                    <input
                      type='text'
                      placeholder='9925:BE0832920390'
                      value={workspace.peppol_id}
                      onChange={this.onPeppolIdChange}
                    />
                  </div>

                  <div className='form-item'>
                    <label>Rejection message</label>
                    <textarea
                      placeholder='A message send to the user along with the rejection mail.'
                      onChange={this.onMessageChange}
                      value={this.state.message}
                    ></textarea>
                  </div>

                  <div className='form-item'>
                    <Button type='success' onClick={this.onApproveClick} text='Approve' />
                    <Button type='danger' onClick={this.onRejectClick} text='Reject' style={{ marginLeft: 10 }} />
                  </div>

                </PeppolContainer>
              </form>}
            </>}

          </PreviewContent>
        </PreviewContainer>
      </Container >
    )
  }
}