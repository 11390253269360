import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`

const Value = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  line-height: 16px;
  display: block;
  color: black;
`

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #919191;
  white-space: nowrap;
  line-height: 13px;
`

interface IProps {
  label: string
  value?: string
}

const StepHeaderMedataItem = (props: IProps) => {
  const { label, value } = props

  return (
    <Container>
      <Value>{value || '-'}</Value>
      <Label>{label}</Label>
    </Container>

  )

}

export default StepHeaderMedataItem