import styled from 'styled-components'

const SettingButton = styled.button`
  color: white;
  text-decoration: none;
  padding: 12px 35px;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  z-index: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #2680C2;
  color: #ffffff;
  border: none;

  &:hover {
    background: #195480;
  }
`

export default SettingButton