import * as React from 'react'

const BlockBody = (props) => {
	return (
		<div className='block-body'>
			{props.children}
		</div>
	)
}

export default BlockBody