import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	flex-flow: column-reverse;
	justify-content: space-between;
	align-items: flex-start;

	h2 {
		color: #000;
    font-weight: 700;
		margin-bottom: 15px;
		font-size: 22px;
	}
`

interface IProps {
	title: string
}
export default class BalanceListHeader extends React.PureComponent<IProps> {
	render () {
		const {
			title,
		} = this.props

		return (
			<Container>
				<h2>{title}</h2>
			</Container>
		)
	}
}