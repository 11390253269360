import styled from 'styled-components'

const SettingsContent = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  padding: 20px;
`

export default SettingsContent