import * as React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'
import Panel from '../../components/Panel/Panel'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import Button from '../../components/Button/Button'
import { AppState } from '../../store'
import { CurrentUser } from '../../types'
import Title from '../../components/Settings/Title'

interface IStateToProps {
  currentUser: CurrentUser
}

type IProps = IStateToProps & WithTranslation

class Data extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)

    this.onDownloadBackupClick = this.onDownloadBackupClick.bind(this)
  }

  onDownloadBackupClick() {
    window.location.assign('/account/backup')
  }

  render() {
    const { t } = this.props
    return (
      <>
        <Helmet>
          <title>{t('ProfileData::{{__appName}} | Data')}</title>
        </Helmet>
        <ScrollToTopOnMount />
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <Title>{t('ProfileData::Data')}</Title>
            <Panel>
              <div className='alert alert-info' style={{ marginBottom: 20 }}>
                {t('ProfileData::{{__appName}} keeps your data secure so you don\'t have to. If you like a copy of your data you can download it here.')}
              </div>
              <div className='form-item'>
                <Button type='default' text={t('ProfileData::Backup downloaden')} onClick={this.onDownloadBackupClick} />
              </div>
            </Panel>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


export default connect(mapStateToProps)(withTranslation()(Data))