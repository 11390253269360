import * as React from 'react'
import { TimeEntriesController } from '../../controllers'
import { AppState } from '../../store'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { startTimer, stopTimer, pauseTimer, resetTimer } from '../../store/timer/actions'
import Icon from '../Icons/Icon';
import { CSSTransition } from 'react-transition-group'
import moment from '../../utilities/Moment'
import WorkspaceChannelHelper from '../../helpers/WorkspaceChannelHelper'
import TimeHelper from '../../helpers/TimeHelper'
import styled from 'styled-components'
import { Style } from '../../styles'
import { CurrentUser, TimeEntry, WorkspaceCableEventType, WorkspaceEvents } from '../../types'

const Container = styled.div`
	position: fixed;
	top: 54px;
	left: calc(50% + 125px);
	display: flex;
	border-radius: 0 0 4px 4px;
	border: solid 1px ${Style.color.border};
	border-top: none;
	overflow: hidden;
	transform: translate(-50%, 0%);
	z-index: 3;
	transition: 0.5s transform;
	max-width: 500px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		top: 106px;
		left: 50%;
	}

	.has-banner & {
		top: calc(52px + 42px);

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			top: initial;
		}
	}

	&.is-entering {
		transform: translate(-50%, -100%);
	}

	&.is-entering-active {
		transform: translate(-50%, 0%);
	}

	&.is-leaving {
		transform: translate(-50%, -150%);
	}
`

const Clock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	flex: 1 0 auto;
	min-width: 98px;
	height: 28px;
	padding: 0 12px 0 10px;
	border-radius: 0 0 0 4px;
	background-color: #6BBD2E;
	color: white;
	white-space: nowrap;
	transition: 0.35s background-color;

	i {
		font-size: 14px;
		color: white;
		margin-right: 5px;
	}
`

const DescriptionContainer = styled.div`
	flex: 0 1 auto;
	position: relative;
	background-color: white;
	overflow: hidden;
`

const Description = styled.div`
	padding: 0 12px;
	font-size: 14px;
	line-height: 28px;
	transition: 0.35s opacity;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 500;
`

const Actions = styled.div`
	display: flex;
	flex: 0 0 auto;
`

const Action = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	border-left: solid 1px ${Style.color.border};
	background-color: white;
	cursor: pointer;
	
	svg {
		width: 10px;
		height: 10px;
	}

	&.is-pause {
		&:hover {
			background-color: ${Style.color.brandWarning};

			svg, i {
				fill: ${Style.color.white};
				color: ${Style.color.white};
			}
		}

		svg, i {
			fill: ${Style.color.brandWarning};
			color: ${Style.color.brandWarning};
		}
	}

	&.is-start {
		&:hover {
			background-color: ${Style.color.brandSuccess};

			svg, i {
				fill: ${Style.color.white};
				color: ${Style.color.white};
			}
		}

		svg, i {
			fill: ${Style.color.brandSuccess};
			color: ${Style.color.brandSuccess};
		}
	}

	&.is-stop, &.is-trash {
		svg, i {
			fill: ${Style.color.brandDanger};
			color: ${Style.color.brandDanger};
		}

		&:hover {
			background-color: ${Style.color.brandDanger};

			svg, i {
				fill: ${Style.color.white};
				color: ${Style.color.white};
			}
		}
	}
`

interface IStateToProps {
	currentUser: CurrentUser
	show: boolean
	entry: TimeEntry | null
	workspaceChannel: any
}

interface IDispatchToProps {
	startTimer: typeof startTimer
	pauseTimer: typeof pauseTimer
	stopTimer: typeof stopTimer
	resetTimer: typeof resetTimer
}

type IProps = IStateToProps & IDispatchToProps

class Timer extends React.Component<IProps> {
	private updateTimerInterval: number

	constructor(props) {
		super(props);

		this.updateTimer = this.updateTimer.bind(this)
		this.onStartPress = this.onStartPress.bind(this)
		this.onTrashPress = this.onTrashPress.bind(this)
		this.onPausePress = this.onPausePress.bind(this)
		this.onStopPress = this.onStopPress.bind(this)

		this.onTimerPauseEvent = this.onTimerPauseEvent.bind(this)
	}

	componentDidMount() {
		document.addEventListener(WorkspaceCableEventType.PAUSE_TIMER, this.onTimerPauseEvent)
	}

	componentWillUnmount() {
		if (this.updateTimerInterval) {
			this.clearTimerInterval();
		}
	}

	startTimerInterval() {
		if (!this.updateTimerInterval) {
			this.updateTimerInterval = window.setInterval(this.updateTimer, 1000)
		}
	}

	clearTimerInterval() {
		if (this.updateTimerInterval) {
			clearInterval(this.updateTimerInterval);
			this.updateTimerInterval = null;
		}
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextProps.entry && nextProps.entry.active) {
			this.startTimerInterval();
		}
		else if (nextProps.entry === null) {
			this.clearTimerInterval();
		}
	}

	updateEntry(entry, isCreate, callback) {
		if (!isCreate) { // Do update
			TimeEntriesController
				.update(entry)
				.then(response => {
					if (callback) callback(response);
				})
				.catch(console.error)
		}
		else {
			TimeEntriesController
				.create(entry)
				.then(response => {
					if (callback) {
						callback(response);
					}
				})
				.catch(console.error)
		}
	}

	updateTimer() {
		this.forceUpdate()
		TimeHelper.setDocumentTimerTitle()

		// Send document event TIMER
		const customEvent = new CustomEvent(WorkspaceEvents.UPDATE_TIMER, {})
		document.dispatchEvent(customEvent)
	}

	onStartPress() {
		const { entry, startTimer } = this.props;

		if (this.updateTimerInterval) {
			clearInterval(this.updateTimerInterval);
		}

		const newEntry: TimeEntry = {
			...entry,
			started_at: moment().utc().toString(),
			ended_at: null,
			active: true
		}

		delete newEntry['id'];

		this.updateEntry(newEntry, true, (responseEntry) => {
			// Send event
			WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.START_TIMER, data: { entry: responseEntry } })

			// Local
			startTimer(responseEntry)
		})
	}

	onTrashPress() {
		const { entry, resetTimer } = this.props

		WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.RESET_TIMER, data: { entry: entry } })

		// Remove timer from local cache
		resetTimer()
	}

	onPausePress() {
		const { entry, pauseTimer } = this.props;

		this.clearTimerInterval()

		entry.active = false

		this.updateEntry(entry, false, (responseEntry) => {
			WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.PAUSE_TIMER, data: { entry: responseEntry } })

			// Local
			pauseTimer(responseEntry)
		});
	}

	onStopPress(e) {
		e.preventDefault();
		const { entry, stopTimer } = this.props;

		this.clearTimerInterval();

		entry.active = false;

		this.updateEntry(entry, false, (responseEntry) => {
			// Stop timer workspace event
			WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.STOP_TIMER, data: { entry: responseEntry } })

			// Local
			stopTimer()
		});
	}

	onTimerPauseEvent(e) {
		const { currentUser } = this.props

		if (e.detail && e.detail.data.entry.user_id === currentUser.id) {
			this.clearTimerInterval()
		}
	}

	render() {
		const { show, entry } = this.props;

		const duration = TimeHelper.getTimerDuration()
		const description = TimeHelper.getTimerDescription()

		return (
			<>
				<CSSTransition
					in={show}
					timeout={500}
					classNames={{
						appear: 'is-entering',
						appearDone: 'is-entering-active',
						enter: 'is-entering',
						enterActive: 'is-entering-active',
						enterDone: 'is-entering-active',
						exit: 'is-leaving',
						exitDone: 'is-leaving',
					}}
					mountOnEnter
					unmountOnExit
					enter={true}
					appear={true}
					exit={true}
				>
					<Container>
						<Clock style={{ backgroundColor: TimeHelper.getTimeEntryColor(entry) }}>
							<Icon icon='stopwatch' className='is-white' />
							{duration}
						</Clock>

						{description.length > 0 && <DescriptionContainer>
							<Description>
								{description}
							</Description>
						</DescriptionContainer>}

						<Actions>
							{entry && !entry.active && <>
								<Action
									href='javascript://'
									className='is-start'
									onClick={this.onStartPress}
								>
									<Icon icon='start' />
								</Action>
								<Action
									href='javascript://'
									className='is-trash'
									onClick={this.onTrashPress}
								>
									<Icon icon='trash' />
								</Action>
							</>}

							{entry && entry.active && <>
								<Action
									href='javascript://'
									className='is-pause'
									onClick={this.onPausePress}
								>
									<Icon icon='pause' />
								</Action>
								<Action
									href='javascript://'
									className='is-stop'
									onClick={this.onStopPress}
								>
									<Icon icon='stop' className='is-warning' />
								</Action>
							</>
							}
						</Actions>
					</Container>
				</CSSTransition>
			</>
		);
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
		timer,
		channels: {
			workspaceChannel,
		}
	} = state

	return {
		currentUser: currentUser,
		...timer,
		workspaceChannel,
	}
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {
		startTimer: (entry) => dispatch(startTimer(entry)),
		pauseTimer: (entry) => dispatch(pauseTimer(entry)),
		stopTimer: () => dispatch(stopTimer()),
		resetTimer: () => dispatch(resetTimer())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)