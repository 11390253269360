import * as React from 'react'
import ContractsTable from '../Contracts/ContractsTable'

type IProps = {
	contactId?: string
	projectId: string
}

const ProjectContracts = (props: IProps) => {
	const { contactId, projectId } = props

	return (
		// @ts-ignore
		<ContractsTable
			contactId={contactId}
			projectId={projectId}
			primaryActionEnabled
		/>
	)
}

export default ProjectContracts