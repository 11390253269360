import { IconTypes } from '../components/Icons/Icon'
import ERoute from '../ERoute'
import store from '../store'
import i18n from '../translations'
import { NavigationMenuItemPage, NavigationMenuItemType, PageNavigationMenuItem } from '../types'

export default class NavigationHelper {
  static getHomePath(): ERoute {
    const setting = store.getState().authentication.currentUser.workspace.setting

    const homeItem = setting.default_menu.find(item => item.type === NavigationMenuItemType.PAGE && item.home)

    if (homeItem) {
      return this.getNavigationMenuItemRoute(homeItem as PageNavigationMenuItem)
    } else {
      return ERoute.PATH_DASHBOARD
    }
  }

  static getPages(): NavigationMenuItemPage[] {
    return [
      NavigationMenuItemPage.DASHBOARD,
      NavigationMenuItemPage.CALENDAR,
      NavigationMenuItemPage.SEQUENCES,
      NavigationMenuItemPage.TASKS,
      NavigationMenuItemPage.CONTACTS,
      NavigationMenuItemPage.DEALS,
      NavigationMenuItemPage.PROJECTS,
      NavigationMenuItemPage.TIME_TRACKING,
      NavigationMenuItemPage.PROPOSALS,
      NavigationMenuItemPage.INVOICES,
      NavigationMenuItemPage.CONTRACTS,
      NavigationMenuItemPage.EXPENSES,
      NavigationMenuItemPage.BANK,
      NavigationMenuItemPage.ACCOUNTANT,
      NavigationMenuItemPage.PRODUCTS,
      NavigationMenuItemPage.FORMS,
      NavigationMenuItemPage.FILES,
      NavigationMenuItemPage.REPORTING,
    ]
  }

  static getNavigationMenuItemRoute(item: PageNavigationMenuItem): ERoute {
    switch (item.page) {
      case NavigationMenuItemPage.DASHBOARD:
        return ERoute.PATH_DASHBOARD
      case NavigationMenuItemPage.CALENDAR:
        return ERoute.PATH_CALENDAR
      case NavigationMenuItemPage.DEALS:
        return ERoute.PATH_DEALS
      case NavigationMenuItemPage.SEQUENCES:
        return ERoute.PATH_SEQUENCES
      case NavigationMenuItemPage.TASKS:
        return ERoute.PATH_TASKS
      case NavigationMenuItemPage.CONTACTS:
        return ERoute.PATH_CONTACTS
      case NavigationMenuItemPage.PROJECTS:
        return ERoute.PATH_PROJECTS
      case NavigationMenuItemPage.TIME_TRACKING:
        return ERoute.PATH_TIMESHEETS
      case NavigationMenuItemPage.PROPOSALS:
        return ERoute.PATH_PROPOSALS
      case NavigationMenuItemPage.INVOICES:
        return ERoute.PATH_INVOICES
      case NavigationMenuItemPage.CONTRACTS:
        return ERoute.PATH_CONTRACTS
      case NavigationMenuItemPage.EXPENSES:
        return ERoute.PATH_EXPENSES
      case NavigationMenuItemPage.BANK:
        return ERoute.PATH_BANK
      case NavigationMenuItemPage.PRODUCTS:
        return ERoute.PATH_PRODUCTS
      case NavigationMenuItemPage.FORMS:
        return ERoute.PATH_FORMS
      case NavigationMenuItemPage.FILES:
        return ERoute.PATH_FILES
      case NavigationMenuItemPage.REPORTING:
        return ERoute.PATH_REPORTING
      case NavigationMenuItemPage.ACCOUNTANT:
        return ERoute.PATH_ACCOUNTANT
      default:
        throw new Error(`Unknown page: ${item.page}`)
    }
  }

  static getNavigationItemPageLabel(page: NavigationMenuItemPage) {
    return i18n.t(`NavigationMenuItemPage::${page}`)
  }
}