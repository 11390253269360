import { SequenceStep } from "../../types";
import {
  SET_INITIAL_SEQUENCE_STEP_EDITOR_STATE,
  SEQUENCE_STEP_CREATE,
  SEQUENCE_STEP_UPDATE,
  SEQUENCE_STEP_DELETE,
  SequenceStepEditorState,
  SequenceStepsActionTypes,
} from "./types";

export function setInitialSequenceStepEditorState(
  state: SequenceStepEditorState
): SequenceStepsActionTypes {
  return {
    type: SET_INITIAL_SEQUENCE_STEP_EDITOR_STATE,
    state: state,
  };
}

export function createSequenceStep(
  sequenceStep: SequenceStep
): SequenceStepsActionTypes {
  return {
    type: SEQUENCE_STEP_CREATE,
    sequenceStep: sequenceStep,
  };
}

export function updateSequenceStep(
  sequenceStep: SequenceStep
): SequenceStepsActionTypes {
  return {
    type: SEQUENCE_STEP_UPDATE,
    sequenceStep: sequenceStep,
  };
}

export function deleteSequenceStep(id: string): SequenceStepsActionTypes {
  return {
    type: SEQUENCE_STEP_DELETE,
    id: id,
  };
}
