import { ContentBlock } from '../../types'
import {
	SET_INITIAL_CONTENT_BLOCK_EDITOR_STATE,
	CONTENT_BLOCK_CREATE,
	CONTENT_BLOCK_UPDATE,
	CONTENT_BLOCK_DELETE,
	ContentBlockEditorState,
	ContentBlocksActionTypes
} from './types'

export function setInitialContentBlockEditorState(state: ContentBlockEditorState): ContentBlocksActionTypes {
	return {
		type: SET_INITIAL_CONTENT_BLOCK_EDITOR_STATE,
		state: state,
	}
}

export function createContentblock(contentBlock: ContentBlock): ContentBlocksActionTypes {
	return {
		type: CONTENT_BLOCK_CREATE,
		contentBlock: contentBlock
	}
}

export function updateContentBlock(contentBlock: ContentBlock): ContentBlocksActionTypes {
	return {
		type: CONTENT_BLOCK_UPDATE,
		contentBlock: contentBlock
	}
}

export function deleteContentBlock(id: string): ContentBlocksActionTypes {
	return {
		type: CONTENT_BLOCK_DELETE,
		id: id,
	}
}