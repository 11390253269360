import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import Button from '../components/Button/Button'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import FullPageContent from '../components/Page/FullPageContent'
import PageContent from '../components/Page/PageContent'
import PageHeader from '../components/Page/PageHeader'
import ERoute from '../ERoute'
import RouteHelper from '../helpers/RouteHelper'
import PlaybooksTable from '../components/Playbooks/PlaybooksTable'

type IProps = & RouteComponentProps<any>

const Playbooks = (props: IProps) => {
	const { t } = useTranslation()
	const { history } = props

	const onNewPlaybookClick = async () => {
		history.push(RouteHelper.process(ERoute.PATH_PLAYBOOKS_CREATE))
	}

	return (
		<>
			<Helmet>
				<title>{t('Playbooks::{{__appName}} | Playbooks')}</title>
			</Helmet>
			<ScrollToTopOnMount />

			<TopNavigation
				icon='book'
				title={t('Playbooks::Playbooks')}
				action={
					<Button
						type='primary'
						icon='plus'
						text={t('Playbooks::New Playbook')}
						onClick={onNewPlaybookClick}
						extraClasses='page-action'
					/>
				}
			/>

			<PageContent>
				<PageHeader title={t('Playbooks::Playbooks')} />
				<PlaybooksTable />
			</PageContent>
		</>
	)
}

export default Playbooks