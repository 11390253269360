import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	pointer-events: none;
	flex-direction: column;
	width: 0;

	.is-up & {
		bottom: 2px;
		flex-direction: column-reverse;
	}

	.is-bottom & {
		top: 0;
		flex-direction: column;
	}
`

const TickLine = styled.div`
	height: 10px;
	width: 2px;
  background-color: #a3bddf;
`

const Label = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	font-weight: 500;

	.is-up & {
		bottom: 2px;
	}

	.is-down & {
		top: 2px;
	}
`

const LabelLine = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: #5886c4;
	white-space: nowrap;

	.is-strong & {
		font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #5886c4;
	}
`

interface IProps {
	left: number
	label: string | JSX.Element
}

export default class TimelineTickpoint extends React.PureComponent<IProps> {
	render() {
		const { left, label } = this.props;

		return (
			<Container style={{ left: `${left}%` }}>
				<TickLine />
				<Label>
					<LabelLine>
						{label}
					</LabelLine>
				</Label>
			</Container>
		);
	}
}