import * as React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { saveAs } from 'file-saver'
import { connect } from 'react-redux';
import { RouteComponentProps, Route, Switch } from 'react-router';
import styled from 'styled-components';
import ConversationItem from '../../components/Conversation/ConversationItem';
import ConversationItemContent from '../../components/Conversation/ConversationItemContent';
import ConversationItemTime from '../../components/Conversation/ConversationItemTime';
import ConversationItemTitle from '../../components/Conversation/ConversationItemTitle';
import ConversationList from '../../components/Conversation/ConversationList';
import { AppState } from '../../store';
import { showConfirmModal } from '../../store/modals/actions';
import ConversationItemAvatar from '../../components/Conversation/ConversationItemAvatar';
import ConversationItemMetadata from '../../components/Conversation/ConversationItemMetadata';
import ConversationContainer from '../../components/Conversation/ConversationContainer';
import ERoute from '../../ERoute';
import RouteHelper from '../../helpers/RouteHelper';
import FormResponse from './FormResponse';
import { FormResponsesController } from '../../controllers';
import Loader from '../../components/Loaders/Loader';
import ConversationListLoader from '../../components/Conversation/ConversationListLoader';
import moment from '../../utilities/Moment';
import { useDebouncedCallback } from 'use-debounce';
import EmptyFormPlaceholder from './EmptyFormPlaceholder';
import Icon from '../../components/Icons/Icon';
import ConversationListEmpty from '../../components/Conversation/ConversationListEmpty';
import ConversationListHeader from '../../components/Conversation/ConversationListHeader';
import ConversationItemIndicator from '../../components/Conversation/ConversationItemIndicator';
import ConversationItemSubject from '../../components/Conversation/ConversationItemSubject';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import { CurrentUser, FormResponse as FormResponseType, SpreadsheetExportType } from '../../types';

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;
`

interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }>

interface IState {
	formResponses: FormResponseType[]
	currentPage: number
	endReached: boolean
	didInitialLoad: boolean
	isFetching: boolean
	activeIndex: number | null
}

const FormResponses = (props: IProps) => {
	const scrollContainer = React.useRef<HTMLDivElement>()
	const { t } = useTranslation()
	const { match } = props
	const formId = match.params.id
	const debouncedOnListScroll = useDebouncedCallback(() => { onListScroll() }, 150);

	const [state, setState] = React.useState<IState>({
		formResponses: [],
		currentPage: 1,
		endReached: false,
		didInitialLoad: false,
		isFetching: false,
		activeIndex: null,
	})
	const {
		formResponses,
		currentPage,
		endReached,
		didInitialLoad,
		isFetching,
		activeIndex,
	} = state

	const fetchResponses = async (page: number) => {
		try {
			if (!isFetching) {
				setState({ ...state, isFetching: true })

				const response = await FormResponsesController.getFormResponses(formId, { page: page })
				const { form_responses: responses, current_page, total_pages, total_entries } = response;

				setState({
					formResponses: page === 1 ? [...responses] : [...formResponses, ...responses],
					currentPage: page,
					endReached: page >= total_pages,
					didInitialLoad: true,
					isFetching: false,
					activeIndex: null,
				})
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	React.useEffect(() => {
		fetchResponses(1).catch(console.error)
	}, [])

	const onHeaderActionDownloadClick = async () => {
		try {
			const { blob, filename } = await FormResponsesController.export(formId, {
				spreadsheet_type: LocalStorage.get(
					LocalStorageKey.PREFERRED_EXPORT_FORMAT,
					SpreadsheetExportType.CSV
				),
			})
			saveAs(blob, filename)
		} catch (ex) {
			console.error(ex)
		}
	}

	const onListScroll = () => {
		const element = scrollContainer.current
		const scrolledHeight = element.scrollHeight - element.scrollTop - 400
		const containerHeight = element.clientHeight

		if (element && scrolledHeight <= containerHeight) {
			if (!endReached) {
				fetchResponses(currentPage + 1)
			}
		}
	}

	const onResponseClick = async (index: number) => {
		try {
			const formResponse = formResponses[index]

			if (!formResponse.viewed_at) {
				const updatedFormResponse = await FormResponsesController.update({
					...formResponse,
					viewed_at: moment().toISOString()
				})

				if (updatedFormResponse) formResponses[index] = updatedFormResponse
			}

			if (activeIndex === index) {
				setState({ ...state, activeIndex: null, formResponses: [...formResponses] })
				props.history.replace(RouteHelper.process(ERoute.PATH_FORM_RESPONSES, { id: formId }))
			} else {
				setState({ ...state, activeIndex: index, formResponses: [...formResponses] })
				props.history.replace(RouteHelper.process(ERoute.PATH_FORM_RESPONSE, { id: formId, responseId: formResponse.id }))
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	const onLeaveConversationClick = () => {
		// Reset active index
		setState({ ...state, activeIndex: null })

		// Navigate to responses with response id
		props.history.replace(RouteHelper.process(ERoute.PATH_FORM_RESPONSES, { id: formId }))
	}

	const onDeleteConversationClick = async (formResponse: FormResponseType) => {
		try {
			requestAnimationFrame(() => {
				props.showConfirmModal({
					title: t('FormResponse::Delete response'),
					description: t('FormResponse::You are about to delete this form response. Are you sure?'),
					action: { label: t('FormResponse::Delete'), isDestructive: true },
					onConfirm: async () => {
						try {
							const response = await FormResponsesController.delete(formResponse)
							const responseIndex = formResponses.findIndex(f => f.id === formResponse.id);

							if (responseIndex !== -1) {
								formResponses.splice(responseIndex, 1);

								setState({
									...state,
									formResponses: [...formResponses]
								})
								onLeaveConversationClick()
							}
						} catch (ex) {
							console.error(ex)
						}
					}
				})
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	return (
		<Container>
			<ConversationList active={activeIndex === null} ref={scrollContainer} onScroll={debouncedOnListScroll}>
				{!didInitialLoad && <ConversationListLoader>
					<Loader size='large' />
				</ConversationListLoader>}
				{didInitialLoad && <>
					{formResponses.length === 0 && <ConversationListEmpty>
						<Icon icon='inbox-illustration' />
					</ConversationListEmpty>}
					{formResponses.length > 0 && <>
						<ConversationListHeader
							title={t('FormResponses::Responses')}
							action={{
								icon: 'download-circle',
								onClick: onHeaderActionDownloadClick
							}}
						/>
						{formResponses.map((formResponse, index) => {
							const { form } = formResponse

							return (
								<ConversationItem key={formResponse.id} active={activeIndex === index} onClick={() => onResponseClick(index)}>
									<ConversationItemContent>
										<ConversationItemAvatar>
											{form.name.length > 0 ? form.name.substring(0, 1).toUpperCase() : 'B'}
										</ConversationItemAvatar>

										<ConversationItemMetadata>
											<ConversationItemTitle>{form.name ? form.name : 'Bizzey'}</ConversationItemTitle>
											<ConversationItemSubject>{t('FormResponses::New form submission')}</ConversationItemSubject>
											{/* <ConversationItemDescription>Hi asdsad, We appreciate you signing up!d aslkdas;lkd;alskd;salkd;askd;askld;lask d;lak ;lks;lakd</ConversationItemDescription> */}
											<ConversationItemTime>{moment(formResponse.created_at).fromNow()}</ConversationItemTime>
										</ConversationItemMetadata>
										{!formResponse.viewed_at && <ConversationItemIndicator />}
									</ConversationItemContent>
								</ConversationItem>)
						})}
					</>}

				</>}
			</ConversationList>
			<ConversationContainer active={activeIndex !== null}>
				<Switch>
					<Route
						path={ERoute.PATH_FORM_RESPONSE}
						// @ts-ignore
						render={(routerProps) => <FormResponse
							{...routerProps}
							onLeaveConversationClick={onLeaveConversationClick}
							onDeleteConversationClick={onDeleteConversationClick}
						/>}
					/>
					<Route path='/' component={EmptyFormPlaceholder} />
				</Switch>
			</ConversationContainer>
		</Container>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showConfirmModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormResponses))