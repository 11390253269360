import * as React from 'react'
import styled from 'styled-components'


const PreviewPageSelector = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const PreviewPageSelectorNavigation = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	cursor: pointer;
	color: rgb(255, 255, 255);
	background-color: rgba(15, 20, 31, 0);
	border-radius: 14px;
	transition: background-color 0.1s ease 0s;

	i {
		color: currentColor;
	}

	&.is-disabled {
		cursor: not-allowed;
		opacity: 0.3;
		pointer-events: none;
	}

	&.is-active {
		color: white;
		cursor: pointer;

		&:hover {
			background-color: rgba(15, 20, 31, 0.15);
		}
	}
`

const PreviewPageSelectorValue = styled.div`
	text-align: center;
	font-size: 14px;
	line-height: 20px;
	color: rgb(255, 255, 255);
	font-variant-numeric: tabular-nums;
	margin: 0px 6px;
`

interface IProps {
	currentPage: number
	totalPageCount: number
	onNavigateBack: () => void
	onNavigateForward: () => void
}

export default class LedgerItemPreviewNavigation extends React.PureComponent<IProps> {
	constructor(props: IProps) {
		super(props)

		this.onNavigateBack = this.onNavigateBack.bind(this)
		this.onNavigateForward = this.onNavigateForward.bind(this)
	}

	onNavigateBack() {
		const { currentPage, onNavigateBack } = this.props

		if (currentPage > 1) {
			onNavigateBack()
		}
	}

	onNavigateForward() {
		const { currentPage, totalPageCount, onNavigateForward } = this.props

		if (currentPage < totalPageCount) {
			onNavigateForward()
		}
	}

	render() {
		const {
			currentPage,
			totalPageCount
		} = this.props

		return (
			<PreviewPageSelector>
				<PreviewPageSelectorNavigation href='javascript://'
					className={`${currentPage > 1 ? 'is-active' : 'is-disabled'}`}
					onClick={this.onNavigateBack}
				>
					<i className='fas fa-chevron-left'></i>
				</PreviewPageSelectorNavigation>
				<PreviewPageSelectorValue>
					{currentPage} / {totalPageCount}
				</PreviewPageSelectorValue>

				<PreviewPageSelectorNavigation href='javascript://'
					className={`${currentPage < totalPageCount ? 'is-active' : 'is-disabled'}`}
					onClick={this.onNavigateForward}
				>
					<i className='fas fa-chevron-right'></i>
				</PreviewPageSelectorNavigation>
			</PreviewPageSelector>
		)
	}
}