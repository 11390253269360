import * as React from 'react'
import Avatar from "../Avatar/Avatar"
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  position: absolute;
`

interface IProps {
  name: string
  active: boolean
  availableTime: number
  scheduledTime: number
}

const PlannerAvatar = (props: IProps) => {
  const { name, active, availableTime, scheduledTime } = props

  const baseStrokeDashArray = 113.097
  let strokeDashOffset = 113.097
  let strokeColor = Style.color.brandSuccess

  if (availableTime === 0 && scheduledTime > 0) {
    strokeColor = Style.color.brandDanger
    strokeDashOffset = 0
  }
  else if (scheduledTime > availableTime) {
    strokeColor = Style.color.brandDanger
    strokeDashOffset = 0
  } else {
    strokeDashOffset = baseStrokeDashArray - (baseStrokeDashArray * (scheduledTime / availableTime))
  }

  return (
    <Container>
      <svg width={active ? 57 : 40} height={active ? 57 : 40} viewBox="0 0 40 40">
        <circle
          cx="20"
          cy="20"
          r="18"
          strokeWidth="4px"
          style={{ stroke: '#e4e4e6', fill: 'none' }}
        />
        <circle
          cx="20"
          cy="20"
          r="18"
          strokeWidth="4px"
          style={{
            strokeDasharray: baseStrokeDashArray,
            strokeDashoffset: strokeDashOffset,
            stroke: strokeColor,
            strokeLinecap: 'round',
            fill: 'none',
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
            transition: 'stroke-dashoffset 250ms',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)'
          }}
        />
      </svg>
      <Wrapper>
        <Avatar
          name={name}
          rounded
          width={active ? 40 : 30}
        />
      </Wrapper>
    </Container >
  )
}

export default PlannerAvatar