import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { useTranslation } from 'react-i18next'
import { ConvertkitFormData, ConvertkitIntegrationConfig } from '../../../types'
import IntegrationFormTitle from '../IntegrationFormTitle'
import PowerSelect from '../../Form/PowerSelect'
import { IntegrationFormConfigChangeOptions } from '../../../containers/Integration'
import Tooltip from '../../Tooltips/Tooltip'

type IProps = {
  config: ConvertkitIntegrationConfig
  formData: ConvertkitFormData
  onConfigChange: (config: ConvertkitIntegrationConfig, options?: IntegrationFormConfigChangeOptions) => void
}

interface IState { }

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		color: black;
		margin-bottom: ${Style.spacing.x2};
    font-weight: bold;
	}
	
	label {
		color: black;
	}
`

const ConvertkitForm = (props: IProps) => {
  const { t } = useTranslation()
  const { config, formData, onConfigChange } = props
  const { forms } = formData
  const { api_key, api_secret } = config

  const onApiKeyChange = (e) => {
    onConfigChange({
      ...config,
      api_key: e.currentTarget.value,
    }, {
      triggerSubmit: true,
      returnOnSubmit: false,
      refetchOnSubmit: true,
    })
  }

  const onApiSecretChange = (e) => {
    onConfigChange({
      ...config,
      api_secret: e.currentTarget.value,
    }, {
      triggerSubmit: true,
      returnOnSubmit: false,
      refetchOnSubmit: true,
    })
  }

  const onFormChange = (option) => {
    onConfigChange({
      ...config,
      form_id: option?.value || null,
    })
  }

  const onGetApiKeyTooltipClick = () => {
    window.open('https://app.convertkit.com/account_settings/advanced_settings', '_blank')
  }

  const formOptions = forms?.map((form) => ({
    label: form.name,
    value: form.id
  })) || []

  const selectedFormOption = formOptions.find((option) => String(option.value) === String(config?.form_id))

  return (
    <Container>
      <IntegrationFormTitle>
        {t('ConvertkitForm::Settings')}
      </IntegrationFormTitle>

      <div className='form-item'>
        <label>
          {t('ConvertkitForm::API key')} <span>*</span>
          <span onClick={onGetApiKeyTooltipClick}>
            <Tooltip
              content={t('ConvertkitForm::Get your API key from your ConvertKit account settings')}
              containerStyle={{ marginLeft: 8 }}
            />
          </span>
        </label>
        <input
          name='api_key'
          type='text'
          value={api_key}
          onChange={onApiKeyChange}
          placeholder='1S23hGy-O2UvCD3Gvx3sUQ'
        />
      </div>

      <div className='form-item'>
        <label>
          {t('ConvertkitForm::API secret')} <span>*</span>
          <span onClick={onGetApiKeyTooltipClick}>
            <Tooltip
              content={t('ConvertkitForm::Get your API secret from your ConvertKit account settings')}
              containerStyle={{ marginLeft: 8 }}
            />
          </span>
        </label>
        <input
          name='api_secret'
          type='text'
          value={api_secret}
          onChange={onApiSecretChange}
          placeholder='subGGc6TcbsPTuY23r717Y_wyKsJ3LdwciJL-kUksE0E'
        />
      </div>

      {api_key && api_secret && <div className='form-item'>
        <label>{t('ConvertkitForm::Form')} <span>*</span></label>

        <PowerSelect
          options={formOptions}
          value={selectedFormOption}
          onChange={onFormChange}
          placeholder={t('ConvertkitForm::Select a form...')}
        />
      </div>}
    </Container>
  )
}

export default ConvertkitForm